import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import "./style.css";

import
{
    Modal, Upload, Progress, message
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

class Upload_ extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            isCropImg: false,
            progress: 0,
            fileList: [

            ],

            previewVisible: false,
            previewImage: "",
            previewTitle: ""
        }

    }
    setPreviewVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                previewVisible: type
            }
        })
    }
    setPreviewTitle = (type) =>
    {
        this.setState(state =>
        {
            return {
                previewTitle: type
            }
        })
    }
    setPreviewImage = (type) =>
    {
        this.setState(state =>
        {
            return {
                previewImage: type
            }
        })
    }
    setProgress = (type) =>
    {
        this.setState(state =>
        {
            return {
                process: type
            }
        })
    }
    handleCancel = () => this.setPreviewVisible(false);
    handlePreview = async (file) =>
    {

        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setPreviewImage(file.url || file.preview);
        this.setPreviewVisible(true);
        this.setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    handleChange = ({ fileList: newFileList }, type_f) =>
    {
        // if (type_f === "avata" || type_f === "video") {
        //     this.props.setimgesInfoRemove(this.props.type_, this.state.fileList[0])
        // }
        console.log('====================================');
        console.log("this.state.fileList", { fileList: newFileList }, type_f);
        console.log('====================================');
        let { max } = this.props;
        if (max === 1) {

            newFileList = [newFileList[newFileList.length - 1]];

        }
        else {
            if (max < newFileList.length) {
                newFileList = [newFileList.slice(-max, newFileList.length)];
            }

        }

        this.setState(state =>
        {
            return {
                fileList: newFileList,

            }
        }, () =>
        {
            this.props.setimgesInfo(type_f, this.props.type_, newFileList)



        })

        // this.setFileList(newFileList);


    }

    setFileList = (type) =>
    {
        this.setState(state =>
        {
            return {
                fileList: type
            }
        })
    }
    uploadButton = (focus) =>
    {
        let { title, icon } = this.props;
        return <div className="upload-icon-w" style={focus ? {
            border: "dashed 1px red",
            backgroundColor: "#fb929266"
        } : {}} >
            {icon ? icon : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {title}
            </div>
        </div>
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.fileList && nextProps.fileList !== prevState.fileList) {
            return {
                fileList: nextProps.fileList
            }
        }
        return null;

    }
    componentDidMount = () =>
    {


    }
    getBase64 = (file) =>
        new Promise((resolve, reject) =>
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);

            reader.onerror = (error) => reject(error);
        });
    // upload = (e) =>
    // {

    //     let value = e.file;

    //     let form_data = new FormData();
    //     form_data.append('folder_post', "products");
    //     form_data.append('files[]', value);

    //     this.props.UploadFile(form_data);
    //     // this.handlePreview(value);
    // }
    removeFile = (file) =>
    {
        console.log('====================================');
        console.log("removeFileremoveFile", file);
        console.log('====================================');
        this.props.setimgesInfoRemove(this.props.type_f, this.props.type_, file)

    }
    uploadImage = async options =>
    {

        const { onSuccess, onError, file, onProgress } = options;

        this.props.setimgesInfo(this.props.type_f, this.props.type_, this.state.fileList)
        onSuccess();
    }
  

    onSuccess = (res) =>
    {

    }
    render()
    {
        let { progress } = this.state;
        let { isCropImg, accept, multiple, max, type_f, focus } = this.props;
        return (
            <>

                {isCropImg && <ImgCrop rotate>
                    <Upload
                        accept={accept}
                        customRequest={this.uploadImage}
                        onRemove={multiple ? this.removeFile : false}
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        // action={`http://127.0.0.1:8000/api/v1/uploadfile`}
                        onChange={(e) => this.handleChange(e, type_f)}
                        multiple={multiple}

                    >
                        {this.state.fileList.length >= 8 ? null : this.uploadButton(focus)}
                    </Upload>
                </ImgCrop>}
                {!isCropImg && <>
                    <Upload
                        accept={accept}
                        customRequest={this.uploadImage}
                        onRemove={multiple ? this.removeFile : false}
                        // action={`http://127.0.0.1:8000/api/v1/uploadfile`}
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        onChange={(e) => this.handleChange(e, type_f)}
                        multiple={multiple}

                    >
                        {this.state.fileList.length >= 8 ? null : this.uploadButton(focus)}
                    </Upload>
                    {progress > 0 ? <Progress percent={progress} /> : null}
                </>}
                <Modal visible={this.state.previewVisible} title={this.state.previewTitle} footer={null} onCancel={this.handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={this.state.previewImage}
                    />
                </Modal>
            </>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload_);