import React, { useRef } from 'react'
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import * as Colors from '../../constants/Colors';
import { linkWeb } from '../../constants/LinkApi';
import * as LINK from "./../../constants/Links";
import
{
  CaretLeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
  ShopOutlined,
  UserOutlined,
  PieChartOutlined,
  DesktopOutlined, LogoutOutlined,
  MailOutlined,
  AppstoreAddOutlined, SmileOutlined,
  LoadingOutlined, RetweetOutlined,SearchOutlined
} from '@ant-design/icons';
import { Menu, Spin,Input,Button } from 'antd';
import { useEffect } from 'react';
import { checkScreenSize, getCookie } from '../../service';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListMenusRequest } from '../../actions/indexWeb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from "./../../constants/ListIcons";
import { META } from "../../utils/constant";
import { OverlayLoading } from "./../overlayLoading";
const { Search } = Input;
export const Sidebar = ({ ListMenus }) =>
{

  const [settings, setsettings] = useState(null);
  const dispatch = useDispatch()
  const { Web, appReducer } = useSelector((state) => state);

  const [itemMenus, setitemMenus] = useState(null);
  const [itemMenusSearch, setitemMenusSearch] = useState(null);
  const [META_, setMETA] = useState({ ...META });
  const history = useHistory();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() =>
  {
    dispatch(ListMenusRequest({
      limit: META_.per_page * 1000,
      page: META_.current_page, column: META_.column, sort: META_.sort
    }));

    return () =>
    {

    };
  }, []);

  useEffect(() =>
  {
  
    if (Web.ListMenus){
      const menus = items_menus(Web.ListMenus);

      setitemMenus(menus);
      setitemMenusSearch(menus);
    }

    return () =>
    {

    };
  }, [Web.ListMenus]);
  const renderTree = (arr, result, isChildren = false, num = 0, level = 0) =>
  {
    arr.map((item, index) =>
    {

      // item.key = index + 1
      let child = []

      if (item.functs) {
        Object.values(item.functs).map((el, i) =>
        {
          let l = el.router;
          if (el.code.includes("add_")) {
            l += "/add"
          }

          child.push(getItem(<Link to={l}> {el.name}</Link>, el.code + uuidv4()))
        });

      }


      const Ic_ = Icons.filter(ic =>
      {
        return ic.name === item.icon || ic.name === item.funct_icon
      })

      const Ic = Ic_ && Ic_.length > 0 ? Ic_[0].icon : "";
      if (isChildren) {

        if (Object.values(item.functs).length > 1) {

          result.submenu.push(getItem(item.name, item.code + uuidv4(), Ic ? <Ic /> : <DesktopOutlined />, child))

        }
        else {

          let f = Object.values(item.functs)[0];

          if (f) {
            let l = f.router;
            if (f.code.includes("add_")) {
              l += "/add"
            }

            result.submenu.push(getItem(<Link to={l}> {item.name}</Link>, item.code + uuidv4(), Ic ? <Ic /> : <DesktopOutlined />))
          }

        }

      } else {

      }
      if (item.children) {

        level++
        renderTree(item.children, result, true, num, level, result.submenu);
        if (result.submenu.length > 0) {
          result.menu.push(getItem(item.name, item.code + uuidv4(), Ic ? <Ic /> : <DesktopOutlined />, result.submenu))
          result.submenu = []
        }


      } else {
        if (!isChildren) {

          if (Object.values(item.functs).length > 1) {
            result.menu.push(getItem(item.name, item.code + uuidv4(), Ic ? <Ic /> : <DesktopOutlined />, child))
          } else {
            let f = Object.values(item.functs)[0];
            if (f) {
              let l = f.router;
              if (f.code.includes("add_")) {
                l += "/add"
              }

              result.menu.push(getItem(<Link to={l}> {item.name}</Link>, item.code + uuidv4(), Ic ? <Ic /> : <DesktopOutlined />))
            }
          }

        }
      }

    });

  }

  const items_menus = (listmenus) =>
  {
    const result = { menu: [], submenu: [] }

    renderTree(listmenus, result);

    return result.menu;

  };
  const getItem = (label, key, icon, children, type) =>
  {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  useEffect(() =>
  {
    const { width, height } = checkScreenSize();
    setWindowSize({
      width: width,
      height: height,
    });
    // Handler to call on window resize
    function handleResize()
    {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
 
  const timer = useRef(null);
  const onchangeSearch=(e)=>{
    const value = e.target.value;
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      searchMenus(value)
    }, 100);
   
  }

  const searchMenus=(value)=>{
    console.log("valuevaluevalue ",value);
    if(value){
      const fildata=itemMenus.filter((item, index) => {

      
            if(typeof item.label =="string"){
              return item.label.toLowerCase().includes(value);
            }else{
              if(item?.label?.props?.children){
                return item?.label?.props?.children?.[1].toLowerCase().includes(value);
              }
             
            }
          
      })
      setitemMenusSearch(fildata);
    }else{
      setitemMenusSearch(itemMenus);
    }
   
     
  }
  return (
    <>
   
      {!Web.ListMenus || Web.ListMenus.length <= 0  && <OverlayLoading className={"bg_main"} size={{ with: "5px", height: "10px" }} scale={1} />}
      <div className={`sidebar ${appReducer.active ? "showMobile" : ""}`}>
        <div className='search-menu-w d-flex' >
          <Input  className=' search-menu' placeholder='Tìm kiếm' onChange={onchangeSearch}></Input>
          {/* <Button htlmType="button" className='p-0 d-flex justify-content-center align-items-center'> <SearchOutlined /></Button> */}
        </div>
       
        {(Web.ListMenus.length && Web.ListMenus.length > 0) && windowSize.width > 900 &&  <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        className="menu-sidebar  bg_main"
        inlineCollapsed={appReducer.active}
        items={itemMenusSearch}
        style={{
          backgroundColor: Colors.pop2[2],
          color: Colors.white
        }}
      />}
        {(Web.ListMenus.length && Web.ListMenus.length > 0) && windowSize.width < 900 && <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="dark"
          className="menu-sidebar"
          inlineCollapsed={false}
          items={itemMenusSearch}
          style={{
            backgroundColor: Colors.pop2[2],
            color: Colors.white
          }}
        />}
    </div>
    </>
  )
}
