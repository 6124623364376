import React, { Component, createRef, useEffect, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined, MinusCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, SaveOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, createSlug, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
const Index = ({ ListPaperType, setListPaperType,formPaperTypeRef }) => {


    // const [data, setData] = useState();

    const addSortStamp = (index) => {
        let lastItem = ListPaperType[ListPaperType.length - 1];
        let rankLast = 0;
        if (lastItem) {
            rankLast = lastItem.rank;
        }
        setListPaperType([...ListPaperType, {
            name: "",
            code: "",
            note: "",
            isNew: true,
            rank: rankLast + 1
        }])
    }
    const removeSortStamp = (index) => {
        if (index == 0) {
            return;
        }
        const newData = ListPaperType.filter((value, i) => {
            return i != index;
        })
        setListPaperType([...newData])
    }

    const sortDataByRank = (type, value, index) => {
        if (type == "up") {
            value.rank -= 1;
            if (value.rank <= 1) {
                value.rank = 1;
            }
        }
        if (type == "down") {
            value.rank += 1;
        }
        const sortedData = [];
        ListPaperType.map((item, i) => {
            if (type == "up") {

                if (i == (index - 1)) {
                    item.rank += 1;
                    item.isEdit = true;
                }
            }
            if (type == "down") {
                if (i == (index + 1)) {
                    item.rank -= 1;
                    if (item.rank <= 1) {
                        item.rank = 1;
                    }
                    item.isEdit = true;

                }
            }
            if (i == index) {
                item = value
                item.isEdit = true;
            }
            sortedData.push(item)
        })

        const sortedData_ = sortedData.sort((a, b) => a.rank - b.rank);

        setListPaperType(sortedData_);
    };
    return (
        <>
            {ListPaperType && ListPaperType.map((item, index) => {


                return <Input.Group compact>

                    <Form.Item name={`paper-type-name-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}   >
                        <Input className="font-size-12"
                            style={{
                                width: '40%',
                            }}
                            placeholder="name"
                            onChange={(e) => {
                                formPaperTypeRef.current.setFieldsValue({ [`paper-type-code-${index}`]: createSlug(e.target.value) })
                            }}
                        />
                    </Form.Item>
                    <div className="d-none" >
                    <Form.Item name={`paper-type-code-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input className="font-size-12"
                            style={{
                                width: '20%',
                            }}
                            placeholder="code"
                        />
                    </Form.Item>
                    </div>
                    <div className="d-none" >
                        <Form.Item name={`paper-type-rank-${index}`} noStyle>
                            <Input className="font-size-12"

                            />
                        </Form.Item>
                    </div>
               
                   

                    <Form.Item name={`paper-type-note-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input className="font-size-12"
                            addonAfter={<div className="d-flex">
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => addSortStamp(index)}><PlusCircleOutlined className="text-primary" /></button>
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => removeSortStamp(index)}><MinusCircleOutlined className="text-danger" /></button>
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => sortDataByRank("up", item, index)}><ArrowUpOutlined className="text-info" /></button>
                                <button type="button" className="reset_btn scale_btn " onClick={() => sortDataByRank("down", item, index)}><ArrowDownOutlined className="text-info" /></button>
                                {/* <button className="reset_btn scale_btn" onClick={()=>sortDataByRank("down",item,index)}><SaveOutlined className="text-info"/></button> */}
                            </div>}
                            style={{
                                width: '60%',
                            }}
                            placeholder="ghi chú"
                        />
                    </Form.Item>

                </Input.Group>
            })}

        </>

    );
}
export default Index;