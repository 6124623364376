import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, Switch as SwitchA, Select, Card } from 'antd';

import { replaceThous, createSlug, getParamsUrl, checkScreenSize } from "./../../../../service";

import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined, PlusCircleOutlined, AppstoreOutlined, ProfileOutlined,
    DeleteOutlined, SearchOutlined, UnorderedListOutlined,
    EditOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import IndexMobile from "./IndexMobile";


const { TextArea } = Input;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListStatusTransportType: [],
            meta: {
                ...META,
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Vùng',
                    dataIndex: 'regions',
                    sorter: (a, b) => a.code - b.code,
                    render: (text, record, index) =>
                    {
                        if (record.regions) {
                            return record.regions.name
                        }
                    }
                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreStatusTransportType: {},
            isloading: false,
            isLoadingSearch: false,
            ListStatusTransportTypeType: [],
            isShowList: false,
            isNewList: true,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreStatusTransportTypeRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }

            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };

    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        const urlParams = getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
        this.props.ListStatusTransportTypeRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });


        // this.props.ListStatusTransportTypetypeRequest({
        //     limit: this.state.meta.per_page,
        //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        // });
        // }
        // else {
        //     this.setState(state =>
        //     {
        //         return {
        //             ListStatusTransportType: this.props.Web.ListStatusTransportType
        //         }
        //     }, () => { this.data(); })
        // }
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListStatusTransportType && nextProps.Web.ListStatusTransportType !== prevState.ListStatusTransportType) {


            return {
                ListStatusTransportType: nextProps.Web.ListStatusTransportType,

            };

        }

        if (nextProps.Web.ListStatusTransportTypeType && nextProps.Web.ListStatusTransportTypeType.data !== prevState.ListStatusTransportTypeType) {

            return {
                ListStatusTransportTypeType: nextProps.Web.ListStatusTransportTypeType.data,

            };

        }
        if (nextProps.Web.StoreStatusTransportType && nextProps.Web.StoreStatusTransportType !== prevState.StoreStatusTransportType) {
            return {
                StoreStatusTransportType: nextProps.Web.StoreStatusTransportType,

            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreStatusTransportType !== prevState.StoreStatusTransportType) {
            let { StoreStatusTransportType } = this.state;
            if (StoreStatusTransportType.code === 200) {
                if (Array.isArray(StoreStatusTransportType.message)) {
                    StoreStatusTransportType.message.map((item, index) =>
                    {
                        message.success(StoreStatusTransportType.item)
                    });

                } else {
                    if (StoreStatusTransportType.message) {
                        message.success(StoreStatusTransportType.message)
                    }
                }
                this.setState(state =>
                {
                    return {
                        record: {
                            deleted: 0,
                            hidden: 0
                        }
                    }
                })
                if (this.formRef.current){
                    this.formRef.current.resetFields()
                }
               
                this.setVisible(false)
                const urlParams = getParamsUrl();
                this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
                this.props.ListStatusTransportTypeRequest({
                    limit: this.state.meta.per_page * 1000,
                    page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s ? urlParams.s : ""
                });
            }
            else {
                if (Array.isArray(StoreStatusTransportType.message)) {
                    StoreStatusTransportType.message.map((item, index) =>
                    {
                        message.error(StoreStatusTransportType.item)
                    });

                } else {
                    if (StoreStatusTransportType.message) {
                        message.error(StoreStatusTransportType.message)
                    }

                }

            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreStatusTransportType()
            this.SetisLoadingSeach(false)
        }
        if (this.state.ListStatusTransportType !== prevState.ListStatusTransportType) {
            let { ListStatusTransportType } = this.state
            this.SetisLoadingSeach(false)
            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',

                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Loại tình trạng',
                        dataIndex: 'status_transport_type_name',
                        sorter: (a, b) => a.status_transport_type_name - b.status_transport_type_name,

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    {/* <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button> */}

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListStatusTransportType } = this.state;

        if (ListStatusTransportType.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListStatusTransportType.current_page,
            first_page_url: ListStatusTransportType.first_page_url,
            from: ListStatusTransportType.from,
            last_page: ListStatusTransportType.last_page,
            last_page_url: ListStatusTransportType.last_page_url,
            links: ListStatusTransportType.links,
            next_page_url: ListStatusTransportType.next_page_url,
            path: ListStatusTransportType.path,
            per_page: ListStatusTransportType.per_page,
            prev_page_url: ListStatusTransportType.prev_page_url,
            to: ListStatusTransportType.to,
            total: ListStatusTransportType.total,
        };
        ListStatusTransportType.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = item.id
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }


    editRows = (record) =>
    {

        this.setState(state =>
        {
            return {
                record: record
            }
        })
        this.formRef.current.setFieldsValue({ ...record })
    }

    editRowsMobile = (record) =>
    {


        this.props.history.push({
            pathname: LINK.WEB_STATUS_TRANSPORT_TYPE_EDIT + "/" + record.id,
        });

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", deleted: 0, hidden: 0 }
            }
        })
        this.formRef.current.resetFields();


    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e ? 1 : 0 }

        this.SetisLoading(true)
        this.props.StoreStatusTransportTypeRequest(tmp, tmp.id);

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListStatusTransportTypeRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {

        this.SetisLoadingSeach(true)
        this.props.ListStatusTransportTypeRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });

        const searchParams = new URLSearchParams({ s: values.search ? values.search : "" }).toString();

        this.props.history.push(`${LINK.WEB_STATUS_TRANSPORT_TYPE}?${searchParams}`);

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }

    onFinishForm = (values) =>
    {

        const { record } = this.state;
        const record_ = { ...record, ...values }

        this.SetisLoading(true)
        this.props.StoreStatusTransportTypeRequest(record_, record_.id ? record_.id : "");
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content status_transport_type_" style={{ overflow: "unset" }}>
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset" }}>
                    {/* <h1 style={{ color: Colors.pop2[3] }}> Quản lý loại tình trạng</h1> */}
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Loại tình trạng đơn</span>
                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <Link to={LINK.WEB_STATUS_TRANSPORT_TYPE_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <div className="form__half row">
                            <div className={(checkScreenSize().width < 600 || !this.state.isShowList) ? "left_half col-lg-12 col-md-12 col-sm-12 " : "left_half col-lg-7 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_" style={{ display: "flex" }}>
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="Nhập tên danh mục" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>

                                </div>

                                {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                                    updateRecord={this.updateRecord}
                                    editRows={this.editRowsMobile}
                                    deleteModal={this.deleteModal}
                                    duplicateItem={this.duplicateItem}
                                    current_page={this.state.meta.current_page}
                                    per_page={this.state.meta.per_page}
                                    last_page={this.state.meta.last_page}
                                    OnPagination={this.OnPagination}
                                    isNewList={this.state.isNewList}
                                    setIsNewList={() =>
                                    {
                                        this.setState(state =>
                                        {
                                            return {
                                                isNewList: !state.isNewList
                                            }
                                        })
                                    }}
                                />}
                                {checkScreenSize().width >= 600 && this.state.isShowList && <>  <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                    rowSelection={{
                                        ...this.state.rowSelection,
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}

                                />
                                    <div className="pagination_">

                                    </div>
                                </>
                                }
                            </div>

                            {checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">

                                <Form
                                    name="formadd" onFinish={this.onFinishForm}
                                    ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}>

                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                        {

                                            this.formRef.current.setFieldsValue({ code: createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code" rules={[
                                        {
                                            required: true,
                                            message: "Code là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>
                                    <Form.Item label="Mô tả" name="description"  >
                                        <TextArea style={{ height: "100px" }} placeholder="Mô tả" />
                                    </Form.Item>
                                    <Form.Item label="Màu sắc" name="color"  >
                                        <input className="reset_btn" type="color" />
                                    </Form.Item>
                                    <Form.Item>

                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit" >Cập nhật</Button>
                                    </Form.Item>
                                </Form>

                            </div>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListStatusTransportTypeRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStatusTransportTypeRequest(body));
        },
        StoreStatusTransportTypeRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreStatusTransportTypeRequest(data, id));
        },
        ResetStoreStatusTransportType: () =>
        {
            dispatch(ActionsWeb.ResetStoreStatusTransportType());
        },
        // ListStatusTransportTypetypeRequest: (body) =>
        // {
        //     dispatch(ActionsWeb.ListStatusTransportTypetypeRequest(body));
        // },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);