import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../../../actions/indexWeb";

import * as ActionsApp from "../../../../../../actions/indexApp";
import "./style.css";
import * as LINK from "../../../../../../constants/Links";
// import type { MenuProps } from 'antd';
import { Divider, Radio, Table, Pagination, Input, Button, Modal, Image, Tag, Switch as SwitchA, message, DatePicker, Tooltip, Form, Select, TreeSelect, Checkbox, Avatar, Card, Row, Col, InputNumber, Collapse, Tabs, Popover, Alert, Dropdown, Menu, Space, List, Skeleton } from 'antd';
import moment from 'moment';

import * as Colors from "../../../../../../constants/Colors";
import { arrColors } from "../../../../../../constants/Colors";
import Draggable from 'react-draggable';
import
{
    FolderViewOutlined, UnorderedListOutlined,
    DeleteOutlined, AppstoreOutlined,
    EditOutlined, SearchOutlined, PlusCircleOutlined, SafetyOutlined, DiffOutlined, SaveOutlined, CheckOutlined, CarOutlined, GiftOutlined
} from '@ant-design/icons';
import { formatMN1, CutString, getParamsUrl, checkScreenSize, getCookie } from "../../../../../../service";
import BoxOrder from "./../box/index";
import { META } from "../../../../../../utils/constant";
import IndexMobile from "./indexMobile";

import * as caseSocket from "../../../../../../constants/CaseSocket";
const { Search } = Input;
const { RangePicker } = DatePicker;

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.draggleRef = new createRef(null);
        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            ListTiktokOrders: [],
            ListStore: [],
            ListUsers: [],
            ListProvinces: [],
            ListDistricts: [],
            ListWars: [],
            Province_id_: "",

            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.title.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',
                    sorter: (a, b) => a.slug - b.slug,

                },
                {
                    title: 'Giá',
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,

                },
                {
                    title: 'Giảm giá',
                    dataIndex: 'discount',
                    sorter: (a, b) => a.discount - b.discount,

                },
                {
                    title: 'Danh mục ',
                    dataIndex: 'category',
                    // render: (text, record, index) => (

                    //     <span>{text}</span>
                    // )

                },
                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                                    <DiffOutlined style={{ color: Colors.colorView }} /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            data: [],
            StoreTiktokOrders: {},
            visible: false,
            confirmLoading: false,
            modalText: "",
            isloading: false,
            ListCategory: [],
            treeData: [],
            search: {},
            ListTags: [],
            isLoadingSearch: false,
            statusSearch: "",
            visibledetail: false,

            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            record: "",
            disabled: true,
            isShowList: false,
            code: "",
            isNewList: true,
        }

    }

    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    setVisibleDetail = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = (e) =>
    {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
        return
        this.setState(state =>
        {
            return {
                record: e,
                isreset: false
            }
        }, () =>
        {
            this.setVisibleDetail(true);
        })


    };

    handleCancelDetail = () =>
    {
        this.setState(state =>
        {
            return {
                isreset: true
            }
        }, () =>
        {
            this.setVisibleDetail(false);
        })
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);
    };

    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const code = (params.code); // Lấy giá trị của tham số từ URL
        this.setState(state =>
        {
            return {
                code: code
            }
        })
        this.props.ListEcommerceConfigByCodeRequest({ code: code });
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        this.props.ListUsersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        const urlParams = getParamsUrl();
        if (urlParams.province_id) {
            this.props.ListDistrictsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: urlParams.province_id
            });

            this.setState(state =>
            {
                return {
                    Province_id_: urlParams.province_id,

                }
            })
        }
        if (urlParams.district_id) {
            this.props.ListWarsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
                provinceid: urlParams.province_id, districtid: urlParams.district_id
            });
        }
       
        this.formRef.current.setFieldsValue(urlParams);
        this.props.SearchTiktokOrdersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: "create_time", sort: this.state.meta.sort, code: code
        }, { data: urlParams });
        this.props.ListStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.SyncTiktokOrders && nextProps.Web.SyncTiktokOrders !== prevState.SyncTiktokOrders) {


            return {
                SyncTiktokOrders: nextProps.Web.SyncTiktokOrders,

            };

        }
        if (nextProps.appReducer && nextProps.appReducer.socket !== prevState.socket) {


            return {
                socket: nextProps.appReducer.socket,
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false

            };


        }
        if (nextProps.Web.StoreTiktokOrders && nextProps.Web.StoreTiktokOrders !== prevState.StoreTiktokOrders) {


            return {
                StoreTiktokOrders: nextProps.Web.StoreTiktokOrders,

            };

        }
        if (nextProps.Web && nextProps.Web.ListTiktokOrders !== prevState.ListTiktokOrders) {
            console.log('====================================');
            console.log(nextProps.Web.ListTiktokOrders);
            console.log('====================================');
            return {
                ListTiktokOrders: nextProps.Web.ListTiktokOrders,
                isLoadingSearch: false
            };


        }
        if (nextProps.Web && nextProps.Web.ListEcommerceConfigByCode !== prevState.ListEcommerceConfigByCode) {

            return {
                ListEcommerceConfigByCode: nextProps.Web.ListEcommerceConfigByCode,

            };


        }
        if (nextProps.Web && nextProps.Web.ListEcommerceConfig.data !== prevState.ListEcommerceConfig) {
            return {
                ListEcommerceConfig: nextProps.Web.ListEcommerceConfig.data,

            };


        }
        if (nextProps.Web && nextProps.Web.ListUsers !== prevState.ListUsers) {


            return {
                ListUsers: nextProps.Web.ListUsers,

            };


        }

        if (nextProps.Web && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {

            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };


        }


        if (nextProps.Web && nextProps.Web.ListWars.data !== prevState.ListWars) {


            return {
                ListWars: nextProps.Web.ListWars.data,

            };


        }


        if (nextProps.Web && nextProps.Web.ListDistricts.data !== prevState.ListDistricts) {


            return {
                ListDistricts: nextProps.Web.ListDistricts.data,

            };


        }
        if (nextProps.Web && nextProps.Web.ListStore !== prevState.ListStore) {


            return {
                ListStore: nextProps.Web.ListStore.data,

            };


        }

        return null;


    }
    modalInfo = (text, content) =>
    {
        Modal.info({
            title: text,
            content: content,
            onOk() { },
            onCancel() { },
        });
    };
    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.socket !== prevState.socket) {
            // let { ListCategory } = this.state

            this.connectSocket();

        } 
        if (this.state.SyncTiktokOrders !== prevState.SyncTiktokOrders) {
           
            this.modalInfo("Thông báo", "Đang xử lý dữ liệu! quá trình có thể mất nhiều thời gian");
            this.setState(state =>
            {
                return {
                    isLoadingSearch: false
                }
            })
            this.props.setIsync(true);

        }
        if (this.state.StoreTiktokOrders !== prevState.StoreTiktokOrders) {
            let { StoreTiktokOrders, isDuplicate } = this.state;

            try {
                if (StoreTiktokOrders.code === 200) {
                    if (StoreTiktokOrders.message && typeof StoreTiktokOrders.message === "string") {
                        message.success(StoreTiktokOrders.message)
                    } else {

                        message.error("Có lỗi!")
                    }
                    // console.log('====================================');
                    // console.log({
                    //     limit: this.state.meta.per_page,
                    //     page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    // });
                    // console.log('====================================');
                    this.props.ListTiktokOrdersRequest({
                        limit: this.state.meta.per_page,
                        page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    });
                }
                else {
                    if (StoreTiktokOrders.message && typeof StoreTiktokOrders.message === "string") {

                        message.error(StoreTiktokOrders.message)
                    } else {
                        for (let x in StoreTiktokOrders.message) {
                            let mess = StoreTiktokOrders.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setState(state =>
            {
                return {
                    StoreTiktokOrders: "",

                    isDuplicate: false
                }
            })
            this.props.ResetStoreTiktokOrders();

        }
        if (this.state.ListTiktokOrders !== prevState.ListTiktokOrders) {
           
            let { ListTiktokOrders } = this.state;

            if (ListTiktokOrders.code !== 200 && ListTiktokOrders.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
            }

            this.data()
        }


        if (this.state.ListProvinces !== prevState.ListProvinces) {

            const urlParams = getParamsUrl();

            if (urlParams.province_id) {
                this.formRef.current.setFieldsValue({ province_id: (1 * urlParams.province_id) });
            }

        }
        if (this.state.ListDistricts !== prevState.ListDistricts) {

            const urlParams = getParamsUrl();
            if (urlParams.district_id) {
                this.formRef.current.setFieldsValue({ district_id: (1 * urlParams.district_id) });
            }
        }
        if (this.state.ListWars !== prevState.ListWars) {

            const urlParams = getParamsUrl();
            if (urlParams.war_id) {
                this.formRef.current.setFieldsValue({ war_id: (1 * urlParams.war_id) });
            }


        }
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        width: "5%",
                        align: 'center',

                    },
                    {
                        title: 'Mã',
                        dataIndex: 'id_',
                        // filterMode: 'tree',
                        // width: "7%",
                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) =>
                        {

                            return record.title.startsWith(value)

                        },
                        sorter: (a, b) => a.title - b.title,
                        render: (text, record, index) =>
                        {
                            return <span style={{ color: Colors.pop2[2], fontWeight: "bold" }}>{text}</span>
                        }
                    },

                    {
                        title: 'Trạng thái',
                        dataIndex: 'status',
                        // width: "7%",
                        sorter: (a, b) => a.status - b.status,
                        filterSearch: true,
                        render: (text, record, index) =>
                        {
                            return <span style={{ fontWeight: "bold" }}>{text}</span>
                        }

                    },
                    {
                        title: 'Phone',
                        dataIndex: '',
                        align: 'center',
                        render: (text, record, index) =>
                        {
                            return record.recipient_address.phone_number
                        }


                    },
                    {
                        title: 'Địa chỉ',
                        dataIndex: 'address',
                        render: (text, record, index) =>
                        {
                            return <Popover content={<span>{record.recipient_address.full_address}</span>} title="Title">
                                {CutString(record.recipient_address.full_address, 20, false)}
                            </Popover>
                        }

                    },

                    {
                        title: 'Sản phẩm',
                        dataIndex: 'ttsp',
                        align: 'center',

                        render: (text, record, index) =>
                        {

                            return <button className="reset_btn" style={{ color: Colors.color1 }} onClick={() => this.handleOkDetail(record)}>xem</button>
                        }

                    },
                    {
                        title: 'Mã VD',
                        dataIndex: 'tracking_number',
                        align: 'left',

                        render: (text, record, index) =>
                        {

                            return CutString(text, 20, false)
                        }

                    },

                    {
                        title: 'Tổng tiền',
                        dataIndex: '',
                        align: 'right',
                        render: (text, record, index) =>
                        {

                            return <span>{formatMN1(record.payment.total_amount)}</span>
                        }

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'create_time',
                        // width: "7%",
                        filterSearch: true,
                        filters: filters.created_at,
                        onFilter: (value, record) =>
                        {

                            return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                        },
                        sorter: (a, b) => a.create_time - b.create_time,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    // {
                    //     title: 'Xác nhận',
                    //     dataIndex: 'status_text',
                    //     align: 'center',
                    //     width: "15%",
                    //     render: (text, record, index) =>
                    //     {

                    //         return <Popover placement="top" content={<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(1, record);
                    //                 }}
                    //             >
                    //                 <SafetyOutlined /> <span style={{ marginLeft: "10px" }}>Xác nhận đơn hàng</span>
                    //             </button>
                    //             <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(2, record);
                    //                 }}
                    //             >
                    //                 <GiftOutlined /> <span style={{ marginLeft: "10px" }}>Đang đóng hàng</span>
                    //             </button>
                    //             <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(3, record);
                    //                 }}
                    //             >
                    //                 <CheckOutlined /> <span style={{ marginLeft: "10px" }}>Chờ chuyển hàng</span>
                    //             </button>
                    //             <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    //             <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                    //                 onClick={() =>
                    //                 {
                    //                     this.update_(4, record);
                    //                 }}
                    //             >
                    //                 <CarOutlined /> <span style={{ marginLeft: "10px" }}>Gửi hàng di</span>
                    //             </button>

                    //         </div>} title="Lưu" >

                    //             <Tag color={arrColors[record.status]} style={{ cursor: "pointer" }}>{CutString(text, 10, false)}</Tag>
                    //         </Popover>
                    //     },
                    // },
                    // {
                    //     title: 'Hành động',
                    //     dataIndex: 'action',
                    //     width: "7%",
                    //     render: (text, record, index) => (
                    //         <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                    //             <span className="">
                    //                 <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                    //                     <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                    //                 <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                    //                 <button className="reset_btn btn_view btn__" onClick={() => { this.handleOkDetail(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                    //                 <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                    //                     <DiffOutlined style={{ color: Colors.colorView }} /></button>
                    //             </span>
                    //         </div>
                    //     ),
                    // },
                ]
            }
        })
    }
    duplicateItem = (record) =>
    {
        this.setState(state =>
        {
            return {
                isNewList: true,
                isDuplicate: true
            }
        })
        this.SetisLoading(true)
        this.props.duplicateTiktokOrdersRequest({ id: record.id })
    }
    update_ = (e, record) =>
    {
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })

        record.status = e
        this.SetisLoading(true)
        this.props.StoreTiktokOrdersRequest(record, record.id);

    }
    data = () =>
    {
        try {

            let { ListTiktokOrders } = this.state;

            if (ListTiktokOrders.length <= 0) {
                return;
            }
            console.log('====================================');
            console.log(ListTiktokOrders);
            console.log('====================================');
            let data = [];
            let listfil = {};
            let meta = {
                ...this.state.meta,
                current_page: ListTiktokOrders.current_page,
                first_page_url: ListTiktokOrders.first_page_url,
                from: ListTiktokOrders.from,
                last_page: ListTiktokOrders.last_page,
                last_page_url: ListTiktokOrders.last_page_url,
                links: ListTiktokOrders.links,
                next_page_url: ListTiktokOrders.next_page_url,
                path: ListTiktokOrders.path,
                per_page: ListTiktokOrders.per_page,
                prev_page_url: ListTiktokOrders.prev_page_url,
                to: ListTiktokOrders.to,
                total: ListTiktokOrders.total,
            };
            let filtilte = [];
            let filslug = [];
            let filprice = [];
            let fildiscount = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            ListTiktokOrders.data.map((item, index) =>
            {
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                fildiscount = [...fildiscount, {
                    text: item.discount,
                    value: item.discount,
                }]
                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = item.id
                data.push(item)

            });
          
            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]

            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, discount: fildiscount }
            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            }, () =>
            {
                this.setState(state =>
                {
                    return {
                        isNewList: false,
                    }
                });

            })
        } catch (error) {
            console.error(error);
        }
    }

    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_ORDERS_TIKTOK + "/" + record.id,
        });

    }

    onFinishForm = (values) =>
    {


        const { code } = this.state;
        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        // delete filteredvalues.created_at
        console.log('====================================');
        console.log("filteredvalues", filteredvalues);
        console.log('====================================');

        this.props.SearchTiktokOrdersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: code, page_size: this.state.meta.per_page
        }, { data: filteredvalues });
        const params_ = filteredvalues;
        delete params_.created_at
        const searchParams = new URLSearchParams(filteredvalues).toString();
        this.props.history.push(`${LINK.WEB_ORDERS_TIKTOK}/${this.state.code}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true,
                isLoadingSearch: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formRef.current.resetFields();
        this.formRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: false
            }
        })
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText(<span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>)
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    handleOkDelete = () =>
    {
        const { record } = this.state


        this.setConfirmLoading(true);
        this.props.StoreTiktokOrdersRequest(record, record.id)
        // setTimeout(() =>
        // {

        // }, 2000);
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    OnPagination = (page, pageSize) =>
    {
        // this.props.ListTiktokOrdersRequest({
        //     limit: pageSize,
        //     page: page, column: this.state.meta.column, sort: this.state.meta.sort
        // });
        const { code } = this.state;
        const urlParams = getParamsUrl();
        this.props.SearchTiktokOrdersRequest({
            limit: this.state.meta.per_page,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort, code: code, page_size: this.state.meta.per_page
        }, { data: urlParams });
    }
    updateRecord = (e, record) =>
    {
        record.status = e
        this.SetisLoading(true)
        this.props.StoreTiktokOrdersRequest(record, record.id);

    }

    onchangeProvinces = (e) =>
    {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
        this.setState(state =>
        {
            return {
                Province_id_: e
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });
    }
    onchangeDistricts = (e) =>
    {
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id_, districtid: e
        });
    }
    changeTabOrder = (type) =>
    {
        this.setState(state =>
        {
            return {
                tabOrder: type
            }
        })
    }

    getTiktokOrders = (value) =>
    {

        const formvalues = this.formRef.current.getFieldsValue();
        this.setState(state =>
        {
            return {
                isLoadingSearch: true
            }
        })
      
        // return;
        this.props.SyncTiktokOrdersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: "create_time", sort: this.state.meta.sort, code: this.state.code, page_size: value
        }, { data: formvalues });
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    connectSocket = () =>
    {

        // const newSocket = new WebSocket(linkSocket);

        // newSocket.addEventListener('open', () =>
        // {
        //     console.log('Connected to WebSocket server');
        // });
        if (this.state.socket) {
        
            this.state.socket.addEventListener('message', (event) =>
            {

                let json = JSON.parse(event.data);
                console.log('====================================');
                console.log("jsonjsonjson", json);
                console.log('====================================');
                switch (json.type) {
                    case caseSocket.PUSH_SYNC_DATA_ORDER_TIKTOK:
                        let user = getCookie("user");
                        user = JSON.parse(user);
                        this.props.setIsync(false);
                     
                        if (user.id === json.user_id) {
                            try {
                                let syncProducts = JSON.parse(json.data)

                                console.log('====================================');
                                console.log("json.msgjson.msg", syncProducts);
                                console.log('====================================');
                            } catch (error) {

                                console.error("errorerror", error);
                            }

                        }
                        break;

                    default:
                        break;
                }

            });
        }


    
    }
    processSyncPrData = (syncProducts) =>
    {
       
        let { code, isShowList, isSyncEdit } = this.state;
        if (isSyncEdit) {
            const syncId = Object.keys(syncProducts)[0];
            this.props.history.push({
                pathname: LINK.WEB_PRODUCTS_EDIT + "/" + syncId,

            });
            return;
        }
        if (isShowList) {
            this.props.SearchProductsTiktokRequest({
                limit: this.state.meta.per_page * 100,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: code, next_page_token: ""
            }, { data: {} });
        }

        this.setState(state =>
        {
            return {
                syncProductsSet: [...state.syncProductsSet, ...Object.values(syncProducts)]
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content order_ tiktok" style={{ overflow: "unset" }}>
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Chi tiết: <span style={{ color: Colors.colorblue }}>{this.state.record.v_number}</span>
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_">
                        <BoxOrder isView={true} record={this.state.record} idrecord={this.state.record.id} isreset={this.state.isreset} handleCancelDetail={this.handleCancelDetail} />
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Đơn hàng</span>
                            <Popover placement="bottom" content={
                                <List

                                    itemLayout="horizontal"

                                    dataSource={this.state.ListEcommerceConfig}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Link to={LINK.WEB_ORDERS_TIKTOK + "/" + item?.code}>{item?.name}</Link>
                                            </div>
                                        </List.Item>
                                    )}
                                />

                            } trigger="click">
                                <Tag icon={<i class="fab fa-tiktok"></i>} color="purple" className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active" : "tabs_ecommerce"} >Tiktok</Tag>
                            </Popover>
                            {/* <Tag  color="#f50" className={"tabs_ecommerce"} onClick={() => this.changeTabOrder("shopee")}>Shopee</Tag> */}
                        </div>
                        <Link to={LINK.WEB_ORDERS_EDIT_TIKTOK + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                            // maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px",

                        }}
                    >
                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="TiktokOrdersearch" onFinish={this.onFinishForm} ref={this.formRef}

                                    style={{ padding: 0 }}
                                >
                                    <Form.Item style={{ margin: 0 }}>
                                        <Input.Group compact>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="v_number" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Mã đơn" name="v_number" id="v_number" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="phone_number" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Số điện thoại" name="phone_number" id="phone_number" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="lading_code" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Mã vận đơn" name="lading_code" id="lading_code" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="customer" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="tên,phone,mã kh..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="note" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Ghi chú..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="products" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="tiêu đề,slug,mã sản phẩm..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="address" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Địa chỉ" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"store_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Cửa hàng"
                                                            treeDefaultExpandAll
                                                            options={(this.state.ListStore || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"user_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Người tạo"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"user_id_customer_care"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhân viên chăm sóc"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"user_id_marketer"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhân viên marketing"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"province_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            // treeData={this.state.treeData}

                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Tỉnh/thành"

                                                            options={(this.state.ListProvinces || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                            onChange={this.onchangeProvinces}
                                                        /></Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"district_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            // treeData={this.state.treeData}

                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Quận huyện"

                                                            options={(this.state.ListDistricts || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                            onChange={this.onchangeDistricts}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"war_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Phường/xã"

                                                            options={(this.state.ListWars || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))} /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"info_transport_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhà vận chuyển" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="created_at" noStyle style={{
                                                        width: "100%"
                                                    }} >
                                                        <RangePicker style={{
                                                            width: "100%",
                                                        }} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">
                                                    <Tooltip title="search">
                                                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                    </Tooltip>


                                                </div>
                                                <div className="col_ col-lg-1 col-md-3 col-sm-6">
                                                    <Tooltip title="Reset">
                                                        <Button style={{ width: "100%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                    </Tooltip>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Tooltip title="Đồng bộ">
                                                        <Popover placement="bottom" content={
                                                            <List

                                                                itemLayout="horizontal"

                                                                dataSource={[
                                                                    { value: "100", label: "100 đơn mới" },
                                                                    { value: "200", label: "200 đơn mới" },
                                                                    { value: "300", label: "300 đơn mới" },
                                                                    { value: "400", label: "400 đơn mới" },
                                                                    { value: "500", label: "500 đơn mới" },
                                                                    { value: "600", label: "600 đơn mới" },
                                                                    { value: "700", label: "700 đơn mới" },
                                                                    { value: "800", label: "800 đơn mới" },
                                                                    { value: "900", label: "900 đơn mới" },
                                                                    { value: "1000", label: "1000 đơn mới" },
                                                                    { value: "2000", label: "2000 đơn mới" },
                                                                    { value: "3000", label: "3000 đơn mới" },
                                                                    { value: "5000", label: "5000 đơn mới" }
                                                                ]}
                                                                renderItem={(item) => (
                                                                    <List.Item>
                                                                        <button className="reset_btn" type="button" onClick={() => { this.getTiktokOrders(item.value) }}>
                                                                            {item.label}
                                                                        </button>
                                                                    </List.Item>
                                                                )}
                                                            />

                                                        } trigger="click">
                                                            <Button style={{ width: "100%", backgroundColor: Colors.pop3[3], color: Colors.white }} loading={this.state.isLoadingSearch} htmlType="button">Đồng bộ</Button>
                                                        </Popover>

                                                    </Tooltip>

                                                </div>
                                            </div>

                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                        {this.state.data.length <= 0 && <Skeleton active />}
                        {this.state.data.length > 0 && <>    {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <>
                            <Table
                                rowClassName={(record, index) =>
                                {
                                    return "__row_";
                                }}
                                columns={this.state.columns}
                                dataSource={this.state.data}
                                pagination={false}
                                // scroll={{
                                //     y: "55vh",
                                // }}

                                style={{ paddingBottom: "30px" }}
                            />
                            <div className="pagination_">
                                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                            </div>
                        </>
                        }
                        </>
                        }
                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web,
        appReducer: state.appReducer
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListTiktokOrdersRequest: (data) =>
        {
            dispatch(ActionsWeb.ListTiktokOrdersRequest(data));
        },
        ListStoreRequest: (data) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(data));
        },
        ListUsersRequest: (data) =>
        {
            dispatch(ActionsWeb.ListUsersRequest(data));
        },
        ListProvincesRequest: (data) =>
        {
            dispatch(ActionsWeb.ListProvincesRequest(data));
        },
        ListDistrictsRequest: (data) =>
        {
            dispatch(ActionsWeb.ListDistrictsRequest(data));
        },
        ListWarsRequest: (data) =>
        {
            dispatch(ActionsWeb.ListWarsRequest(data));
        },
        SearchTiktokOrdersRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchTiktokOrdersRequest(body, data));
        },
        StoreTiktokOrdersRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreTiktokOrdersRequest(data, id));
        },
        ResetStoreTiktokOrders: () =>
        {
            dispatch(ActionsWeb.ResetStoreTiktokOrders());
        },
        duplicateTiktokOrdersRequest: (body) =>
        {
            dispatch(ActionsWeb.duplicateTiktokOrdersRequest(body));
        },
        ListEcommerceConfigByCodeRequest: (body) =>
        {
            dispatch(ActionsWeb.ListEcommerceConfigByCodeRequest(body));
        },
        SyncTiktokOrdersRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SyncTiktokOrdersRequest(body, data));
        },
        setIsync: (body) =>
        {
            dispatch(ActionsApp.setIsync(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);