import React, { useEffect, useRef, useState } from 'react';

import './style.css';
import { Divider,  Tag, Avatar,Popover, DatePicker, Image, Checkbox } from 'antd';

import * as Services from './../../service';
import * as Colors from './../../constants/Colors';
import * as LINK from './../../constants/Links';


import
{
    SkinOutlined, ExclamationCircleOutlined, ShoppingCartOutlined

} from '@ant-design/icons';

const Index = ({ item, isDivider, placement }) =>
{
    
    if (!item) {
        return
    }
    let stores = [];
   
    if (item.store_id_gen) {
        for (var y in item.store_id_gen) {
            const st = item.store_id_gen[y];
            // console.log(st);
            stores.push({ storeName: st.name, amount: st.amount })
        }
       
        // if (item.classifystmp.children && item.classifystmp.children.length > 0) {

        // } else {
        //     if (item.classifystmp.store_ids) {

        //         for (var y in item.classifystmp.store_ids) {
        //             const st = item.classifystmp.store_ids[y];
        //             stores.push({ storeName: st.store_name, amount: st.amount })
        //         }
        //     }
        // }


    }

    return <Popover trigger="click"  content={<div style={{ padding: "10px" }} >

        {stores && stores.length > 0 && stores.map((itemst, indexst) =>
        {
            return <div key={indexst}> <span>Kho:</span> <span style={{ color: Colors.colorAdd }}>{itemst.storeName ? itemst.storeName : ""}</span>  <span>Số lượng:</span>  <span style={{ color: Colors.colorEdit }}>{itemst.amount ? itemst.amount : 0} </span></div>
        })}


    </div>} title="Chi tiết kho" placement={placement ? placement:"left"}>

        <div className='item_product'>
            <div className='item_product_wrap' >
                <div className='item_product_left' >
                    <div className='item_product_left_tags' >
                        <Tag color={Colors.colortext} style={{ cursor: "pointer" }} >{Services.CutString(item?.code, 20, false)}</Tag>
                        <Tag color={item.status !== 3 ? Colors.colorAdd : Colors.colorpink} style={{ cursor: "pointer" }} >{Services.CutString(item.status === 3 ? "hết hàng" : item?.amount ? item?.amount : item.amount, 9, false)}</Tag>
                        <Tag color={Colors.colorblue} style={{ cursor: "pointer", color: Colors.color3 }} >{Services.CutString(item?.title, 30, false)}</Tag>
                    </div>
                    <div className='item_product_left_classy'>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
                            <ExclamationCircleOutlined /><span style={{ color: Colors.color2, marginLeft: "10px" }}>Phân loại: {item?.classifys_name ? item?.classifys_name : ""}</span></div>

                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                            <span style={{ color: Colors.color3, marginLeft: "10px" }}>{Services.formatMN1(item?.price ? item?.price : item?.price)}đ</span></div>
                    </div>
                    <div className='item_product_left_tags'>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
                            <ShoppingCartOutlined /><span style={{ color: "", marginLeft: "10px" }}>Kho hiện tại: {item?.store_name_c ? item?.store_name_c : ""}</span><span style={{ color: "", marginLeft: "10px" }}>Giảm giá tiền: {item?.discount ? Services.formatMN1(item?.discount) : Services.formatMN1(item?.discount)} </span> <span style={{ color: "", marginLeft: "10px" }}>Giảm giá %: {item?.discount_percent} </span></div>

                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                            {/* <span style={{ color: Colors.color3, marginLeft: "10px" }}>176,000đ</span> */}

                        </div>
                    </div>
                </div>
                <div className='item_product_right'>
                    {item?.thumbnail?.[0] ? <div className='img_pro_item'> <Image
                        style={{ objectFit: "contain" }}
                        src={LINK.myHostimgServerImg + "/" + item?.thumbnail?.[0]}
                    /></div> : <div className='img_pro_item'> <Avatar icon={<SkinOutlined />} /></div> }
                </div>

            </div>
            {isDivider && <Divider dashed={true} style={{ borderColor: Colors.colorAdd, margin: 0 }} />}
        </div>

    </Popover>
};

export default Index;
