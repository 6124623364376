import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";

import * as Colors from "../../../../constants/Colors";

import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, getCookie } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    InputNumber,
    Card, Comment, Avatar,

} from 'antd';
import
{
    UnorderedListOutlined, UserOutlined
} from '@ant-design/icons';
const { TextArea } = Input;

const { Option } = Select;
const ItemComment = ({ children }) => (
    <Comment
        actions={[<span key="comment-nested-reply-to">Reply to</span>]}
        author={<a>Han Solo</a>}
        avatar={<Avatar icon={<UserOutlined />} />}
        content={
            <p>
                We supply a series of design principles, practical patterns and high quality design
                resources (Sketch and Axure).
            </p>
        }
    >
        {children}
    </Comment>
);

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.parentIdRef = new createRef(null);
        this.formRef = new createRef(null);

        this.state = {
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                status: 0,
                hidden: 0,
                deleted: 0
            },
            StoreFeedback: {},
            ListFeedback: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.StoreFeedback && nextProps.Web.StoreFeedback !== prevState.StoreFeedback) {

            return {
                StoreFeedback: nextProps.Web.StoreFeedback,

            };

        }
        if (nextProps.Web.ListFeedback && nextProps.Web.ListFeedback !== prevState.ListFeedback) {

            return {

                ListFeedback: nextProps.Web.ListFeedback,

            };

        }

        return null;

    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreFeedback !== prevState.StoreFeedback) {
            let { StoreFeedback } = this.state;
            try {
                if (StoreFeedback.code === 200) {
                    if (StoreFeedback.message && typeof StoreFeedback.message === "string") {
                        message.success(StoreFeedback.message)

                    } else {

                        message.error("Có lỗi!")
                    }
                    const { match } = this.props;
                    const { params } = match;
                    const id = (1 * params.id); // Lấy giá trị của tham số từ URL

                    if (id && typeof id === "number") {
                        this.props.FeedbackByIdRequest({ id });
                        this.setState(state =>
                        {
                            return {
                                isloadingLoadData: true
                            }
                        })
                    }
                }
                else {
                    if (StoreFeedback.message && typeof StoreFeedback.message === "string") {

                        message.error(StoreFeedback.message)
                    } else {
                        for (let x in StoreFeedback.message) {
                            let mess = StoreFeedback.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.SetisLoading(false)
            this.setState(state =>
            {
                return {
                    StoreProducts: ""
                }
            })
            this.props.ResetStoreFeedback();
        }
        if (this.state.ListFeedback !== prevState.ListFeedback) {
            const { ListFeedback } = this.state;
            
            this.setState(state =>
            {
                return {
                    record: { ...state.record, product_id: ListFeedback?.[0]?.product_id, post_id: ListFeedback?.[0]?.post_id }
                }
            })
        }

    }
    componentDidMount = () =>
    {

        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.FeedbackByIdRequest({ id });
            this.setState(state =>
            {
                return {
                    isloadingLoadData: true
                }
            })
        }

    }
    data = () =>
    {
        let { ListFeedback } = this.state;


        if (ListFeedback.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListFeedback.current_page,
            first_page_url: ListFeedback.first_page_url,
            from: ListFeedback.from,
            last_page: ListFeedback.last_page,
            last_page_url: ListFeedback.last_page_url,
            links: ListFeedback.links,
            next_page_url: ListFeedback.next_page_url,
            path: ListFeedback.path,
            per_page: ListFeedback.per_page,
            prev_page_url: ListFeedback.prev_page_url,
            to: ListFeedback.to,
            total: ListFeedback.total,
        };
        ListFeedback.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {

        this.setState(state =>
        {
            return {
                [group]: { ...state[group], [type]: e }
            }
        })

    }

    onFinishForm = (values) =>
    {

        const { record } = this.state;
        let user = getCookie("user");
        user = JSON.parse(user);

        record.user_id = user.id;
        record.firstname = user.fullname;
        record.lastname = user.fullname;
        record.email = user.email;
        record.phone_number = user.phone_number;

        const record_ = { ...record, ...values }
        // console.log('====================================');
        // console.log(record_);
        // console.log('====================================');
        // return;
        this.SetisLoading(true)
        this.props.StoreFeedbackRequest(record_, record_.id ? record_.id : "")

    }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    renderComments = (comments, level = 0, parent_id="") =>
    {
        level++;
        
        if (comments && Array.isArray(comments) ) {
            return (comments || []).map((comment) =>
            {
                
                return <Comment
                    key={comment.id}
                    actions={[<span key={`comment-nested-reply-to-${comment.id}`} onClick={() =>
                    {
                        this.formRef.current.setFieldsValue({ parent_id: comment.id });
                        document.getElementById("comment-nested-reply-to-" + comment.id).style.color = Colors.colorpink
                    }} id={"comment-nested-reply-to-" + comment.id}>Phản hồi bình luận này</span>]}
                    author={<a>{comment.firstname} {comment.lastname}</a>}
                    avatar={<Avatar icon={<UserOutlined />} />}
                    content={<p>{comment.note}</p>}
                >
                    {comment.children && this.renderComments(comment.children, level)}
                </Comment>
            });
        }

    };
    CommentList = (data) =>
    {
        return (
            <div>
                {this.renderComments(data)}
            </div>
        );
    };
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w main_content" >
                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UnorderedListOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Phản hồi</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",

                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "35px",
                        borderRadius: "3px"
                    }}
                >


                    <div className="">
                        {/* <ItemComment>
                                <ItemComment>
                                    <ItemComment />
                                    <ItemComment />
                                </ItemComment>
                            </ItemComment> */}
                        {this.state.ListFeedback && this.CommentList(this.state.ListFeedback)}
                        <Comment
                            avatar={<Avatar icon={<UserOutlined />} />}
                            content={

                                <Form
                                    labelCol={{
                                        span: 4,
                                    }}
                                    wrapperCol={{
                                        // span: 14,
                                    }}
                                    layout="horizontal"
                                    onFinish={this.onFinishForm} ref={this.formRef}

                                >
                                    <Form.Item name={"parent_id"} rules={[
                                        {
                                            required: true,
                                            message: "Chưa chọn bình luận trả lời"
                                        },
                                    ]}>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item name={"note"} rules={[
                                        {
                                            required: true,
                                            message: "nội dung là bắt buộc"
                                        },
                                    ]}>
                                        <TextArea rows={4} placeholder="Phản hồi" />
                                    </Form.Item>

                                    <Form.Item >
                                        <div className="flex_">
                                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                            <Button type="primary" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                        </div>
                                    </Form.Item>

                                </Form>
                            }
                        />

                    </div>



                </Card>
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListFeedbackRequest: (body) =>
        {
            dispatch(ActionsWeb.ListFeedbackRequest(body));
        },
        FeedbackByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.FeedbackByIdRequest(body));
        },
        StoreFeedbackRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreFeedbackRequest(data, id));
        },
        ResetStoreFeedback: () =>
        {
            dispatch(ActionsWeb.ResetStoreFeedback());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);