import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";

// import { createSlug } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card,Modal

} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import Draggable from 'react-draggable';
import { Icons } from "../../../../constants/ListIcons";

import { replaceThous, getParamsUrl, createSlug, checkScreenSize } from "./../../../../service";


const { TextArea } = Input;

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = new createRef();

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                slug: "",
                hidden: false,
                deleted: 0
            },
            StoreStatusTransport: {},
            ListStatusTransport: [],
            data: [],
            isloading: false,
            visibledetail: false,

            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            disabled: true,
        }


    }
    setBounds = (bounds) => {
        this.setState(state => {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) => {
        this.setState(state => {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisibleDetail = (type) => {
        this.setState(state => {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = (e) => {
        this.setState(state => {
            return {
                isreset: false
            }
        }, () => {
            this.setVisibleDetail(true);
        })


    };

    handleCancelDetail = () => {
        this.setState(state => {
            return {
                isreset: true
            }
        }, () => {
            this.setVisibleDetail(false);
        })
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);
    };
    onChangetext = (e, type, group, isNumber) => {
        let target = e.target;
     
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListStatusTransport && nextProps.Web.ListStatusTransport.id && nextProps.Web.ListStatusTransport.id !== prevState.record.id) {
          
            return {
                record: nextProps.Web.ListStatusTransport,

            };

        }
        if (nextProps.Web.ListStatusTransportType && nextProps.Web.ListStatusTransportType.data !== prevState.ListStatusTransportType) {

            return {
                ListStatusTransportType: nextProps.Web.ListStatusTransportType.data,

            };

        }

        if (nextProps.Web.StoreStatusTransport && nextProps.Web.StoreStatusTransport !== prevState.StoreStatusTransport) {
            return {
                StoreStatusTransport: nextProps.Web.StoreStatusTransport,

            };

        } else {
            return null;
        }

    }
    
    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.record !== prevState.record) {
            console.log('====================================');
            console.log(this.state.record);
            console.log('====================================');
            this.formRef.current.setFieldsValue({ ...this.state.record })
        }

        if (this.state.StoreStatusTransport !== prevState.StoreStatusTransport) {
            let { StoreStatusTransport } = this.state;
            try {
                if (StoreStatusTransport.code === 200) {
                    if (StoreStatusTransport.message && typeof StoreStatusTransport.message === "string") {
                        message.success(StoreStatusTransport.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreStatusTransport.message && typeof StoreStatusTransport.message === "string") {

                        message.error(StoreStatusTransport.message)
                    } else {
                        for (let x in StoreStatusTransport.message) {
                            let mess = StoreStatusTransport.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)

            this.props.ResetStoreStatusTransport()

        }
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    isUpdate: true
                }
            })
            this.props.ListStatusTransportByIdRequest({ id });
        }

        this.props.ListStatusTransportTypeRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }


    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreStatusTransportRequest(record_, record_.id ? record_.id : "")

    }
    render()
    {

      

        return (

            <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>
                      <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Icons
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_">
                        {Icons && Icons.map((Icon, index) => {
                            const Ic = Icon.icon;
                            return <button type="button" key={index} className="icon_list reset_btn" onClick={() => { this.onChangetext(Icon.name, "icon", "record") }}>
                                <Ic style={{ fontSize: "40px", color: this.state.record.icon === Icon.name ? Colors.colormain : Colors.color2 }} />
                                {/* <span>{Icon.name}</span> */}
                            </button>
                        })}
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết tình trạng đơn</span>
                    </div>} bordered={false}
                        style={{ width: "100%" }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >

                            <Form.Item label="Loại" rules={[
                                {
                                    required: true,
                                    message: "Loại trạng thái là bắt buộc"
                                },
                            ]} name="type_status_id" >
                                <Select
                                    showSearch
                                    style={{
                                        // width: 200,
                                    }}

                                    placeholder="Loại"
                                    optionFilterProp="children"
                                    options={(this.state.ListStatusTransportType || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                />



                            </Form.Item>
                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên trạng thái là bắt buộc"
                                },
                            ]} >
                                <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                {

                                    this.formRef.current.setFieldsValue({ code: createSlug(e.target.value) })
                                }} />
                            </Form.Item>
                            <Form.Item label="Code" name="code" rules={[
                                {
                                    required: true,
                                    message: "Code là bắt buộc"
                                },
                            ]} >
                                <Input placeholder="code" showCount maxLength={120} />
                            </Form.Item>
                            <Form.Item label="Mô tả" name="description"  >
                                <TextArea style={{ height: "100px" }} placeholder="Mô tả" />
                            </Form.Item>
                            <Form.Item label="Màu sắc" name="color"  >
                                <input className="reset_btn" type="color" />
                            </Form.Item>
                            <Form.Item label="Icon" >
                                        <div className="flex_ align-items-center" >
                                            {Icons && Icons.map((Icon, index) => {

                                                if (this.state.record.icon === Icon.name) {
                                                    const Ic = Icon.icon;
                                                    return <Ic style={{ fontSize: "40px", marginLeft: "10px", color: this.state.record.icon === Icon.name ? Colors.colormain : Colors.color2 }} />
                                                }


                                            })}
                                            <Button type="primary" style={{ marginRight: "10px" }} htmlType="button" onClick={() => this.setVisibleDetail(true)}>Chọn icon</Button>
                                        </div>
                                    </Form.Item>
                            <Form.Item>
                                <div className="flex_">
                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                    <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreStatusTransportRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreStatusTransportRequest(data, id));
        },
        ResetStoreStatusTransport: () =>
        {
            dispatch(ActionsWeb.ResetStoreStatusTransport());
        },
        ListStatusTransportRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStatusTransportRequest(body));
        },
        ListStatusTransportByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStatusTransportByIdRequest(body));
        },
        ListStatusTransportTypeRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStatusTransportTypeRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);