
// export const GET_LIST_TABLE = "GET_LIST_TABLE";
export const SET_PRODUCTS_DATA = "SET_PRODUCTS_DATA";

export const RESET_STORE_CATEGORY = "RESET_STORE_CATEGORY";
export const STORE_CATEGORY = "STORE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const LIST_CATEGORY = "LIST_CATEGORY";



export const RESET_STORE_MENUHOME = "RESET_STORE_MENUHOME";
export const STORE_MENUHOME = "STORE_MENUHOME";
export const DELETE_MENUHOME = "DELETE_MENUHOME";

export const LIST_MENUHOME = "LIST_MENUHOME";

export const LIST_CLASSIFYS = "LIST_CLASSIFYS";
export const STORE_CLASSIFYS = "STORE_CLASSIFYS";


export const LIST_INFOTRANSPORT = "LIST_INFOTRANSPORT";
export const STORE_INFOTRANSPORT = "STORE_INFOTRANSPORT";

export const LIST_NUMBER_OF_PAGE = "LIST_NUMBER_OF_PAGE";
export const STORE_NUMBER_OF_PAGE = "STORE_NUMBER_OF_PAGE";


export const LIST_REGION = "LIST_REGION";
export const STORE_REGION = "STORE_REGION";
export const LIST_ROLE = "LIST_ROLE";
export const STORE_ROLE = "STORE_ROLE";
export const LIST_FUNCT = "LIST_FUNCT";
export const STORE_FUNCT = "STORE_FUNCT";
export const LIST_ROUTE = "LIST_ROUTE";
export const LIST_STORE = "LIST_STORE";
export const STORE_STORE = "STORE_STORE";


export const LIST_TAGS = "LIST_TAGS";
export const STORE_TAGS = "STORE_TAGS";

export const LIST_TRADEMARK = "LIST_TRADEMARK";
export const STORE_TRADEMARK = "STORE_TRADEMARK";
export const LIST_TYPE_PRODUCTS = "LIST_TYPE_PRODUCTS";
export const STORE_TYPE_PRODUCTS = "STORE_TYPE_PRODUCTS";
export const LIST_WAREHOUSE = "LIST_WAREHOUSE";
export const STORE_WAREHOUSE = "STORE_WAREHOUSE";

export const LIST_WARRANTY_TYPE = "LIST_WARRANTY_TYPE";
export const STORE_WARRANTY_TYPE = "STORE_WARRANTY_TYPE";


export const LIST_ORGANIZATION_PRODUCTION = "LIST_ORGANIZATION_PRODUCTION";
export const STORE_ORGANIZATION_PRODUCTION = "STORE_ORGANIZATION_PRODUCTION";


export const LIST_ORGANIZATION_PRODUCTION_ADDRESS = "LIST_ORGANIZATION_PRODUCTION_ADDRESS";
export const LIST_INFOPACKAGE = "LIST_INFOPACKAGE";
export const INFOPACKAGE_ACTIVE = "INFOPACKAGE_ACTIVE";
export const STORE_ORGANIZATION_PRODUCTION_ADDRESS = "STORE_ORGANIZATION_PRODUCTION_ADDRESS";
export const LIST_PAPER_TEXTURE = "LIST_PAPER_TEXTURE";
export const STORE_PAPER_TEXTURE = "STORE_PAPER_TEXTURE";


export const LIST_ATTRIBUTE_PRODUCTS = "LIST_ATTRIBUTE_PRODUCTS";
export const STORE_ATTRIBUTE_PRODUCTS = "STORE_ATTRIBUTE_PRODUCTS";
export const UPLOAD_FILE_LA = "UPLOAD_FILE_LA";
export const DELETE_FILE_LA = "DELETE_FILE_LA";
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const SYNC_PRODUCTS_TIKTOK = "SYNC_PRODUCTS_TIKTOK";
export const SYNC_PRODUCTS_TO_TIKTOK = "SYNC_PRODUCTS_TO_TIKTOK";
export const SEACRH_PRODUCTS = "SEACRH_PRODUCTS";
export const STORE_PRODUCTS = "STORE_PRODUCTS";
export const STORE_PRODUCTS_MENU_HOME = "STORE_PRODUCTS_MENU_HOME";

export const LIST_ATTRIBUTE_BY_PRODUCTS = "LIST_ATTRIBUTE_BY_PRODUCTS";


export const STORE_INFOPACKAGE = "STORE_INFOPACKAGE";
export const LOGIN_WEB = "LOGIN_WEB";

export const LOGOUT_WEB = "LOGOUT_WEB";

export const SET_USER = "SET_USER";


export const LIST_INVENTORY_IN = "LIST_INVENTORY_IN";
export const STORE_INVENTORY_IN = "STORE_INVENTORY_IN";

export const LIST_INVENTORY_OUT = "LIST_INVENTORY_OUT";
export const STORE_INVENTORY_OUT = "STORE_INVENTORY_OUT";



export const LIST_SUPPLIER = "LIST_SUPPLIER";
export const STORE_SUPPLIER = "STORE_SUPPLIER";


export const LIST_USERS = "LIST_USERS";
export const STORE_USERS = "STORE_USERS";


export const LIST_MENUS = "LIST_MENUS";
export const STORE_MENUS = "STORE_MENUS";
export const LIST_MENUS_SEARCH = "LIST_MENUS_SEARCH";

export const LIST_ORDERS = "LIST_ORDERS";
export const STORE_ORDERS = "STORE_ORDERS";
export const ORDER_BY_ID = "ORDER_BY_ID";


export const LIST_TIKTOK_CATEGORIES = "LIST_TIKTOK_CATEGORIES";
export const LIST_TIKTOK_BRANDS = "LIST_TIKTOK_BRANDS";
export const LIST_TIKTOK_ATTRIBUTES = "LIST_TIKTOK_ATTRIBUTES";
export const CREATE_CUSTOM_BRAND_TITOK = "CREATE_CUSTOM_BRAND_TITOK";

export const LIST_TIKTOK_ORDERS = "LIST_TIKTOK_ORDERS";
export const STORE_TIKTOK_ORDERS = "STORE_TIKTOK_ORDERS";
export const TIKTOK_ORDER_BY_ID = "TIKTOK_ORDER_BY_ID";
export const SYNC_TIKTOK_ORDERS = "SYNC_TIKTOK_ORDERS";
export const LIST_STATUS_TRANSPORT = "LIST_STATUS_TRANSPORT";
export const STORE_STATUS_TRANSPORT = "STORE_STATUS_TRANSPORT";

export const LIST_STATUS_TRANSPORT_TYPE = "LIST_STATUS_TRANSPORT_TYPE";
export const STORE_STATUS_TRANSPORT_TYPE = "STORE_STATUS_TRANSPORT_TYPE";

export const LIST_CUSTOMERS = "LIST_CUSTOMERS";
export const STORE_CUSTOMERS = "STORE_CUSTOMERS";
export const LIST_VOUCHERS_GROUP = "LIST_VOUCHERS_GROUP";
export const STORE_VOUCHERS_GROUP = "STORE_VOUCHERS_GROUP";
export const LIST_VOUCHERS = "LIST_VOUCHERS";
export const STORE_VOUCHERS = "STORE_VOUCHERS";
export const GET_CONDIS_CUS = "GET_CONDIS_CUS";
export const CHECK_VOUCHERS = "CHECK_VOUCHERS";
export const LIST_PROVINCES = "LIST_PROVINCES";
export const STORE_PROVINCES = "STORE_PROVINCES";

export const LIST_DISTRICTS = "LIST_DISTRICTS";
export const STORE_DISTRICTS = "STORE_DISTRICTS";


export const LIST_WARS = "LIST_WARS";
export const STORE_WARS = "STORE_WARS";

export const LIST_FEEDBACK = "LIST_FEEDBACK";
export const STORE_FEEDBACK = "STORE_FEEDBACK"

export const LIST_POSTS = "LIST_POSTS";
export const STORE_POSTS = "STORE_POSTS";

export const LIST_PRODUCTS_WAREHOUSE = "LIST_PRODUCTS_WAREHOUSE";
export const STORE_PRODUCTS_WAREHOUSE = "STORE_PRODUCTS_WAREHOUSE";


export const LIST_PAGES = "LIST_PAGES";
export const STORE_PAGES = "STORE_PAGES";
export const PAGES_GETVIEW = "PAGES_GETVIEW";

export const PAGES_GETVIEW_COMPONENT = "PAGES_GETVIEW_COMPONENT";
export const GET_VIEW_COLECTION = "GET_VIEW_COLECTION";
export const LIST_SETTINGS = "LIST_SETTINGS";
export const STORE_SETTINGS = "STORE_SETTINGS";
export const SETTINGS_ACTIVE = "SETTINGS_ACTIVE";

export const SETTINGS_BY_CODE = "SETTINGS_BY_CODE";
export const LIST_BANNERS = "LIST_BANNERS";
export const BANNERS_ACTIVE = "BANNERS_ACTIVE";
export const STORE_BANNERS = "STORE_BANNERS";


export const LIST_CONTACT = "LIST_CONTACT";
export const STORE_CONTACT = "STORE_CONTACT";


export const LIST_COLLECTIONS = "LIST_COLLECTIONS";
export const STORE_COLLECTIONS = "STORE_COLLECTIONS";
export const SEARCH_COLLECTIONS_PRODUCTS = "SEARCH_COLLECTIONS_PRODUCTS";

export const LIST_COLLECTIONS_PRODUCTS = "LIST_COLLECTIONS_PRODUCTS";

export const GET_PDF_DOWLOAD = "GET_PDF_DOWLOAD"; 
export const GET_PDF_STREAM = "GET_PDF_STREAM"; 
export const GET_PDF = "GET_PDF";


export const LIST_ECOMMERCE = "LIST_ECOMMERCE";
export const STORE_ECOMMERCE = "STORE_ECOMMERCE";
export const LIST_ECOMMERCE_BY_CODE = "LIST_ECOMMERCE_BY_CODE";
export const LIST_ECOMMERCE_BY_ID = "LIST_ECOMMERCE_BY_ID";
export const REFRESH_ECOMMERCE = "REFRESH_ECOMMERCE";


export const LIST_ECOMMERCE_TYPE = "LIST_ECOMMERCE_TYPE";
export const STORE_ECOMMERCE_TYPE = "STORE_ECOMMERCE_TYPE";
export const LIST_ECOMMERCE_TYPE_BY_CODE = "LIST_ECOMMERCE_TYPE_BY_CODE";
export const LIST_ECOMMERCE_TYPE_BY_ID = "LIST_ECOMMERCE_TYPE_BY_ID";

export const REPORTS = "REPORTS";


export const LIST_NETWORK_HOME = "LIST_NETWORK_HOME";
export const STORE_NETWORK_HOME = "STORE_NETWORK_HOME";
export const LIST_NETWORK_HOME_OPERATOR = "LIST_NETWORK_HOME_OPERATOR";
export const STORE_NETWORK_HOME_OPERATOR = "STORE_NETWORK_HOME_OPERATOR";

export const LIST_UNIT = "LIST_UNIT";
export const STORE_UNIT = "STORE_UNIT";

export const LIST_SORT_STAMPS = "LIST_SORT_STAMPS";
export const STORE_SORT_STAMPS = "STORE_SORT_STAMPS";
export const LIST_PAPER_TYPE = "LIST_PAPER_TYPE";
export const STORE_PAPER_TYPE = "STORE_PAPER_TYPE";

export const LIST_PAPER_PRICE_LIST = "LIST_PAPER_PRICE_LIST";
export const STORE_PAPER_PRICE_LIST = "STORE_PAPER_PRICE_LIST";
export const LIST_PAPER_AMOUT_AND_DISCOUNT = "LIST_PAPER_AMOUT_AND_DISCOUNT";
export const STORE_PAPER_AMOUT_AND_DISCOUNT = "STORE_PAPER_AMOUT_AND_DISCOUNT";

export const GET_CACHE_KEY = "GET_CACHE_KEY";
export const RESET_CACHE = "RESET_CACHE";
