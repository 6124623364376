import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover } from 'antd';
import
{
    DownOutlined,
    DeleteOutlined, PlusCircleOutlined, UnorderedListOutlined, GroupOutlined, UngroupOutlined,
    EditOutlined, SearchOutlined, StarOutlined, LikeOutlined, MessageOutlined, SnippetsOutlined, ArrowUpOutlined, ArrowDownOutlined
} from '@ant-design/icons';
import * as Services from './../../service';
import * as Colors from '../../constants/Colors';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from '../../constants/Links';
import DropdownRim from "./../dropdown/index";
const IconText = ({ icon, text }) => (
    <span>
        {React.createElement(icon, {
            style: {
                marginRight: 8,
            },
        })}
        {text}
    </span>
);
async function findElementById(arr, idToFind)
{
    for (let i = 0; i < arr.length; i++) {
        const currentElement = arr[i];

        if (currentElement.id === idToFind) {
            return currentElement; // Phần tử đã được tìm thấy
        }

        if (currentElement.children && currentElement.children.length > 0) {
            const foundInChildren = await findElementById(currentElement.children, idToFind);
            if (foundInChildren) {
                return foundInChildren; // Phần tử đã được tìm thấy trong các phần tử con
            }
        }
    }

    return null; // Không tìm thấy phần tử với ID đã cho
}
const h = 40;
const margin_ = 20;
const Index = ({ menus, getMenus, getContentDrawer, drawContent }) =>
{
    const [position_, setPosition_] = useState({ x: 0, y: 0 });
    const [renderedItems, setRenderedItems] = useState(null);
    const [draggingItem, setDraggingItem] = useState(null); // Biến lưu trữ phần tử đang được kéo
    const [itemDelete, setItemDelete] = useState(null); // Biến lưu trữ phần tử đang được kéo
    const [itemEdit, setItemEdit] = useState(null); // Biến lưu trữ phần tử đang được kéo
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [modalText, setModalText] = useState("Bạn có chắc muốn xóa?");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const formSearchRef = useRef(null)
    const [open, setOpen] = useState(false);
  
    // const [drawContent, setDrawContent] = useState(false);
    const [items, setItems] = useState([]);
    // [
    //     {
    //         id: 'item-1',
    //         parent_id: "",
    //         text: 'Item 1',
    //         position_: { x: 0, y: 0 },
    //         initialPosition_: { x: 0, y: 0 },
    //         rank: 1,
    //         level: 0, hidden: 0, height: h,
    //         children: [
    //             {
    //                 id: 'itemchild-3', parent_id: "item-1", text: 'itemchild3', position_: { x: 40, y: 0 }, initialPosition_: { x: 40, y: 0 }, rank: 1, level: 1, hidden: 0, height: h, children: []
    //             },
    //             {
    //                 id: 'itemchild-1', parent_id: "item-1", text: 'itemchild1 test1', position_: { x: 40, y: 0 }, initialPosition_: { x: 40, y: 0 }, rank: 2, level: 1, hidden: 0, height: h, children: [
    //                     {
    //                         id: 'itemchild-1-1', parent_id: "itemchild-1", text: 'itemchild1 test2', position_: { x: 80, y: 0 }, initialPosition_: { x: 80, y: 0 }, rank: 1, level: 2, hidden: 0, height: h,
    //                         children: [
    //                             { id: 'itemchild-1-1-1', parent_id: "itemchild-1-1", text: 'itemchild1 test5', position_: { x: 120, y: 0 }, initialPosition_: { x: 120, y: 0 }, rank: 1, level: 3, hidden: 0, height: h, children: [] },
    //                             { id: 'itemchild-2-1-2', parent_id: "itemchild-1-1", text: 'itemchild2 test6', position_: { x: 120, y: 0 }, initialPosition_: { x: 120, y: 0 }, rank: 2, level: 3, hidden: 0, height: h, children: [] },
    //                             { id: 'itemchild-3-1-3', parent_id: "itemchild-1-1", text: 'itemchild3 test7', position_: { x: 120, y: 0 }, initialPosition_: { x: 120, y: 0 }, rank: 3, level: 3, hidden: 0, height: h, children: [] },
    //                         ]
    //                     },
    //                     { id: 'itemchild-2-1', parent_id: "itemchild-1", text: 'itemchild2 test3', position_: { x: 80, y: 0 }, initialPosition_: { x: 80, y: 0 }, rank: 2, level: 2, hidden: 0, height: h, children: [] },
    //                     { id: 'itemchild-3-1', parent_id: "itemchild-1", text: 'itemchild3 test4', position_: { x: 80, y: 0 }, initialPosition_: { x: 80, y: 0 }, rank: 3, level: 2, hidden: 0, height: h, children: [] },
    //                 ]
    //             },
    //             {
    //                 id: 'itemchild-2', parent_id: "item-1", text: 'itemchild2', position_: { x: 40, y: 0 }, initialPosition_: { x: 40, y: 0 }, rank: 3, level: 1, hidden: 0, height: h, children: [
    //                     { id: 'itemchild-1-2', parent_id: "itemchild-2", text: 'itemchild1', position_: { x: 80, y: 0 }, initialPosition_: { x: 80, y: 0 }, rank: 1, level: 2, hidden: 0, height: h, children: [] },
    //                     { id: 'itemchild-2-2', parent_id: "itemchild-2", text: 'itemchild2', position_: { x: 80, y: 0 }, initialPosition_: { x: 80, y: 0 }, rank: 2, level: 2, hidden: 0, height: h, children: [] },
    //                     { id: 'itemchild-3-2', parent_id: "itemchild-2", text: 'itemchild3', position_: { x: 80, y: 0 }, initialPosition_: { x: 80, y: 0 }, rank: 3, level: 2, hidden: 0, height: h, children: [] },
    //                 ]
    //             },

    //         ],
    //     },
    //     {
    //         id: 'item-2', parent_id: "", text: 'Item 2', position_: { x: 0, y: 0 }, initialPosition_: { x: 0, y: 0 }, rank: 2, level: 0, hidden: 0, height: h, children: [{
    //             id: 'itemchild-2-3', parent_id: "item-2", text: 'itemchild2 test23', position_: { x: 40, y: 0 }, initialPosition_: { x: 40, y: 0 }, rank: 1, level: 2, hidden: 0, height: h, children: []
    //         },]
    //     },
    //     { id: 'item-3', parent_id: "", text: 'Item 3', position_: { x: 0, y: 0 }, initialPosition_: { x: 0, y: 0 }, rank: 3, level: 0, hidden: 0, height: h, children: [] },
    // ]
    useEffect(() =>
    {
        const generateRenderedItems = async () =>
        {
            const itemsToRender = await renderNestedItemsAsync(items);
            console.log(itemsToRender);
            setRenderedItems(itemsToRender);
        };

        if (getMenus) {

            getMenus(items);
        }

        generateRenderedItems();
    }, [items]);

    useEffect(() =>
    {
    
        if (menus) {
            const fetchData = async () =>
            {
                if (menus) {
                    const newMenus = await InitItems(menus);
                    setItems([...newMenus]);
                    // console.log("menusmenusmenus",newMenus);
                    // setRerender(!rerender)
                }
            };

            fetchData(); // Gọi hàm fetchData ở đây
        }


        return () =>
        {

        };
    }, [menus]);

    const handleDrag = (e, data, item) =>
    {
     
        // if (!draggingItem){
        //     // setDraggingItem(item)
          
        // }
       
        item.position_ = { x: data.x, y: data.y }
        // setPosition_({ x: data.x, y: data.y })
      

        // // const updateItemsRecursively = (itemsToCheck) =>
        // // {
        // //     return itemsToCheck.map((parentItem) =>
        // //     {

        // //         // Tìm phần tử cha chứa phần tử đang kéo
        // //         if (parentItem.id === item.id) {

        // //             return {
        // //                 ...parentItem,
        // //                 position_: { x: data.x, y: data.y },
        // //             };
        // //         }
        // //         // Kiểm tra trong các phần tử con của phần tử cha
        // //         if (parentItem.children && parentItem.children.length > 0) {
        // //             const updatedChildren = updateItemsRecursively(parentItem.children);
        // //             return {
        // //                 ...parentItem,
        // //                 children: updatedChildren,
        // //             };
        // //         }
        // //         return parentItem;
        // //     });
        // // };

        // const updatedItems = updateItemsRecursively([...items]);
        // setItems(updatedItems);
    };

    const editRows = async (item) =>
    {
        setItemEdit(item)
        const updateItemValue = async (items, targetId) =>
        {
            return Promise.all(items.map(async item =>
            {
                if (item.id === targetId) {
                    // Nếu tìm thấy phần tử với id mong muốn, thay đổi giá trị của nó
                    return {
                        ...item,
                        isedit: true
                    };
                } else if (item.children && item.children.length > 0) {
                    // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                    const updatedChildren = await updateItemValue(item.children, targetId);
                    return {
                        ...item,
                        children: updatedChildren
                    };
                }
                return item;
            }));
        }
        const itemsToRender = await updateItemValue([...items], item.id);

        setItems(itemsToRender)
    }
    const deleteModal = (item) =>
    {
        setItemDelete(item)
        setVisible(true)
    }

    const setNewPositionitem = async (data,item)=>{
         const updateItemsRecursively = (itemsToCheck) =>
        {
            return itemsToCheck.map((parentItem) =>
            {

                // Tìm phần tử cha chứa phần tử đang kéo
                if (parentItem.id === item.id) {

                    return {
                        ...parentItem,
                        position_: { x: data.x, y: data.y },
                    };
                }
                // Kiểm tra trong các phần tử con của phần tử cha
                if (parentItem.children && parentItem.children.length > 0) {
                    const updatedChildren = updateItemsRecursively(parentItem.children);
                    return {
                        ...parentItem,
                        children: updatedChildren,
                    };
                }
                return parentItem;
            });
        };

        const updatedItems = await updateItemsRecursively([...items]);
        return updatedItems;
    }
    const handleStop = async (e, data, item) =>
    {
      return;
        // console.log('====================================');
        // console.log(position_);
        // console.log('====================================');
        // const draggingitemTmp = { ...draggingItem };
        // if (draggingItem) {
          
        //     // setDraggingItem(null)
        //     // draggingitemTmp.position_ = { x: data.x, y: data.y }
         
        // }
        // item.position_=position_
        // // console.log('====================================');
        // // console.log(draggingitemTmp);
        // // console.log('====================================');
        // // const updatedItems = await setNewPositionitem(data,draggingitemTmp);
        const updatedItems =[...items]
        if (item?.children?.length <= 0 && item.parent_id && item.position_.x < (item.initialPosition_.x - 20) && (item.position_.y <= 10 && item.position_.y >= -10)) {
           
            const parent = await findElementById(updatedItems, item.parent_id);
            const parent2 = await findElementById(updatedItems, parent.parent_id);

            if (!parent2) {

                var ri = 0;
                const newchild = [];
                parent.children.map((itemchild, index) =>
                {
                    if (itemchild.rank > item.rank) {
                        ri++;
                        itemchild.parent_id = item.id
                        itemchild.rank = ri;
                        item.children.push(itemchild)

                    } else {
                        if (itemchild.id !== item.id) {
                            newchild.push(itemchild)
                        }
                    }
                });


                var newrank = parent.rank;

                const newitem = { ...item, parent_id: parent.parent_id, position_: parent.initialPosition_, initialPosition_: parent.initialPosition_, rank: newrank + 1 }
                parent.children = newchild

                updatedItems.map((citem, cindex) =>
                {
                    if (citem.rank > newrank) {
                        newrank += 1;
                        citem.rank = newrank + 1

                    }

                });
                updatedItems.push(newitem)
            } else {

                var ri = 0;
                const newchild = [];
                parent.children.map((itemchild, index) =>
                {
                    if (itemchild.rank > item.rank) {
                        ri++;
                        itemchild.parent_id = item.id
                        itemchild.rank = ri;
                        item.children.push(itemchild)
                    } else {
                        if (itemchild.id !== item.id) {
                            newchild.push(itemchild)
                        }

                    }
                });

                parent.children = newchild;

                var newrank = parent.rank;

                const newitem = { ...item, parent_id: parent2.id, position_: parent.initialPosition_, initialPosition_: parent.initialPosition_, rank: newrank + 1 }

                parent2.children.map((citem, cindex) =>
                {
                    if (citem.rank > newrank) {
                        newrank += 1;
                        citem.rank = newrank + 1

                    }

                });

                parent2.children.push(newitem)
            }

            setItems([...updatedItems])


            setDraggingItem(null)

            return
        } else if (item.position_.x > (item.initialPosition_.x + 20) && (item.position_.y <= 10 && item.position_.y >= -10)) {

            if (item.parent_id) {

                const parent = await findElementById(updatedItems, item.parent_id);
                const parent2 = await findElementById(updatedItems, parent.parent_id);
                if (parent) {

                    if (item.rank === 1) {
                        resetPosition_(item);
                        return;
                    }
                    let itemrank = item.rank;
                    let newrank = 0;
                    const newchild = [];
                    let tmpchild = null;



                    parent.children.filter((itemchild) =>
                    {
                        if (itemchild.rank === (itemrank - 1)) {
                            tmpchild = itemchild;
                            newrank = itemchild.rank;

                        } else {

                            if (itemchild.rank !== itemrank) {
                                if (itemchild.rank > itemrank) {
                                    newrank += 1
                                    itemchild.rank = newrank


                                }
                                newchild.push(itemchild)

                            }

                        }



                    })
                    item.rank = 1;
                    item.parent_id = tmpchild.id
                    if (item.children.length > 0) {

                        item.position_ = item.children[0].initialPosition_
                        item.initialPosition_ = item.children[0].initialPosition_

                        const itemchildUpdatepos = await updatePosition_AllItems(item.children, item.position_.x);
                        item.children = itemchildUpdatepos
                    } else {

                        item.position_ = { ...tmpchild.initialPosition_, x: tmpchild.initialPosition_.x + margin_ }
                        item.initialPosition_ = { ...tmpchild.initialPosition_, x: tmpchild.initialPosition_.x + margin_ }

                    }
                    if (tmpchild.children.length <= 0) {
                        item.rank = 1;

                    } else {

                        item.rank = tmpchild.children[tmpchild.children.length - 1].rank + 1;
                    }
                    tmpchild.children.push(item)

                    newchild.push(tmpchild)
                    parent.children = newchild
                    setItems([...updatedItems])
                    setDraggingItem(null)
                } else {

                }
                return
            } else {
                // items.push(newitem)
                if (item.rank <= 1) {
                    resetPosition_(item);
                    return;
                }
                let itemrank = item.rank;
                let newrank = 0;
                const newchild = [];
                let tmpchild = null;



                updatedItems.filter((itemchild) =>
                {
                    if (itemchild.rank === (itemrank - 1)) {
                        tmpchild = itemchild;
                        newrank = itemchild.rank;

                    } else {

                        if (itemchild.rank !== itemrank) {
                            if (itemchild.rank > itemrank) {
                                newrank += 1
                                itemchild.rank = newrank
                            }
                            newchild.push(itemchild)
                        }
                    }

                })
                item.rank = 1;
                item.parent_id = tmpchild.id

                if (item.children.length > 0) {

                    item.position_ = item.children[0].initialPosition_
                    item.initialPosition_ = item.children[0].initialPosition_

                    const itemchildUpdatepos = await updatePosition_AllItems(item.children, item.position_.x);
                    item.children = itemchildUpdatepos
                } else {

                    item.position_ = { ...tmpchild.initialPosition_, x: tmpchild.initialPosition_.x + margin_ }
                    item.initialPosition_ = { ...tmpchild.initialPosition_, x: tmpchild.initialPosition_.x + margin_ }

                }
                if (tmpchild.children.length <= 0) {
                    item.rank = 1;

                } else {

                    item.rank = tmpchild.children[tmpchild.children.length - 1].rank + 1;
                }
                tmpchild.children.push(item)
                newchild.push(tmpchild)

                setItems([...newchild])
                setDraggingItem(null);
            }

        } else {
            
            if (item.position_.y < -10) {
                if (item.children.length > 0) {
                    resetPosition_(item);
                    return;
                }
                const parent = await findElementById(updatedItems, item.parent_id);
                if (parent) {
                    let numberItem = Math.abs(Math.round(item.position_.y / h));
                    const currentRank = item.rank;
                    if (numberItem > (currentRank - 1)) {
                        numberItem = (currentRank - 1)
                    }
                    const len = parent.children.length;

                    const curentIndex = parent.children.findIndex(el => el.id === item.id);
                    const newIndex = curentIndex - numberItem;
                    const childrenTmp = [...parent.children];

                    const rank_ = parent.children[newIndex].rank;
                    item.position_ = item.initialPosition_
                    parent.children[newIndex].rank = item.rank

                    item.rank = rank_

                    childrenTmp[curentIndex] = parent.children[newIndex]
                    childrenTmp[newIndex] = item

                    parent.children = [...childrenTmp]

                    setItems([...updatedItems])
                    setDraggingItem(null)
                    return;
                } else {
                    let numberItem = Math.abs(Math.round(item.position_.y / h));
                    const currentRank = item.rank;
                    if (numberItem > (currentRank - 1)) {
                        numberItem = (currentRank - 1)
                    }


                    const curentIndex = updatedItems.findIndex(el => el.id === item.id);
                    const newIndex = curentIndex - numberItem;
                    const childrenTmp = [...updatedItems];

                    const rank_ = updatedItems[newIndex].rank;
                    item.position_ = item.initialPosition_
                    updatedItems[newIndex].rank = item.rank

                    item.rank = rank_

                    childrenTmp[curentIndex] = updatedItems[newIndex]
                    childrenTmp[newIndex] = item



                    setItems([...childrenTmp])
                    setDraggingItem(null)
                    return;
                }

            }

            if (item.position_.y > 10) {
              
                if (item.children.length > 0) {
                    resetPosition_(item);
                    return;
                }
                const parent = await findElementById(updatedItems, item.parent_id);
                if (parent) {
                    let numberItem = Math.abs(Math.round(item.position_.y / h));
                    const currentRank = item.rank;
                    const len = parent.children.length;
                    if (numberItem > (len - 1)) {
                        numberItem = (len - 1)
                    }


                    const curentIndex = parent.children.findIndex(el => el.id === item.id);
                    const newIndex = curentIndex + numberItem;
                    const childrenTmp = [...parent.children];

                    const rank_ = parent.children[newIndex].rank;
                    item.position_ = item.initialPosition_
                    parent.children[newIndex].rank = item.rank

                    item.rank = rank_

                    childrenTmp[curentIndex] = parent.children[newIndex]
                    childrenTmp[newIndex] = item
                    parent.children = [...childrenTmp]

                    setItems([...updatedItems])
                    setDraggingItem(null)
                    return;
                } else {
                    let numberItem = Math.abs(Math.round(item.position_.y / h));
                    const currentRank = item.rank;
                    if (numberItem > (currentRank - 1)) {
                        numberItem = (currentRank - 1)
                    }


                    const curentIndex = updatedItems.findIndex(el => el.id === item.id);
                    const newIndex = curentIndex + numberItem;
                    const childrenTmp = [...updatedItems];

                    const rank_ = updatedItems[newIndex].rank;
                    item.position_ = item.initialPosition_
                    updatedItems[newIndex].rank = item.rank

                    item.rank = rank_

                    childrenTmp[curentIndex] = updatedItems[newIndex]
                    childrenTmp[newIndex] = item



                    setItems([...childrenTmp])
                    setDraggingItem(null)
                    return;
                }
            }
            resetPosition_(item);
            return;
        }


    };
    async function updatePosition_AllItems(items, value)
    {
        value += 40;

        // Sử dụng Promise.all để xử lý đồng thời tất cả các phần tử con của mảng
        const updatedItems = await Promise.all(items.map(async (item) =>
        {
            // Thay đổi giá trị của phần tử hiện tại
            const updatedItem = {
                ...item,
                position_: { ...item.position_, x: value },
                initialPosition_: { ...item.initialPosition_, x: value }
            };

            if (item.children && item.children.length > 0) {
                // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                updatedItem.children = await updatePosition_AllItems(item.children, value);
            }

            return updatedItem;
        }));

        return updatedItems;
    }
    async function InitItems(menus, x = 0, level = 0)
    {
        let rank = 0;
        if (level === 0) {
            x = 0;
        } else {
            x += margin_;
        }
        // Sử dụng Promise.all để đợi tất cả các đệ quy hoàn thành
        const updatedItems = await Promise.all(menus.map(async (item) =>
        {
            rank++;

            // Thay đổi giá trị của phần tử hiện tại
            const updatedItem = {
                ...item,
                position_: { x: x, y: 0 },
                initialPosition_: { x: x, y: 0 },
                rank: !item.rank ? rank : item.rank,
                height: h,
                text: item.name,
                children: item.children ? item.children : [],
                level: level
            };

            if (item.children && item.children.length > 0) {

                // Gọi đệ quy bằng cách sử dụng async/await và đợi đến khi hoàn thành
                updatedItem.children = await InitItems(item.children, x, level + 1);

            } else {

                item.children = []
            }

            return updatedItem;
        }));

        return updatedItems;
    }
    async function updateItemValue(items, targetId, key, value)
    {
        return Promise.all(items.map(async item =>
        {
            if (item.id === targetId) {
                // Nếu tìm thấy phần tử với id mong muốn, thay đổi giá trị của nó
                return {
                    ...item,
                    [key]: value // Thay đổi giá trị theo yêu cầu của bạn (ở đây là 'value')
                };
            } else if (item.children && item.children.length > 0) {
                // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                const updatedChildren = await updateItemValue(item.children, targetId, key, value);
                return {
                    ...item,
                    children: updatedChildren
                };
            }
            return item;
        }));
    }
    const resetPosition_ = (item) =>
    {
        const updateItemsRecursively = (itemsToCheck, parentItem = items, mainitems = items) =>
        {
            return itemsToCheck.map((prevItem) =>
            {
                // Kiểm tra xem phần tử có phải là phần tử đang kéo hay không
                if (prevItem.id === item.id) {

                    if (item.position_.x < 0) {
                        mainitems.unshift(prevItem)
                    }
                    return {
                        ...prevItem,
                        position_: { ...prevItem.initialPosition_ },
                    };
                }
                // Kiểm tra trong các phần tử con của phần tử cha
                if (prevItem.children && prevItem.children.length > 0) {
                    const updatedChildren = updateItemsRecursively(prevItem.children, prevItem, mainitems);
                    return {
                        ...prevItem,
                        children: updatedChildren,
                    };
                }
                return prevItem;
            });

        };
        const updatedItems = updateItemsRecursively([...items]);
        setItems(updatedItems);
    }

    const renderNestedItems = async (items, level = 0) =>
    {
        items.sort((a, b) => a.rank - b.rank);
        const totalDescendants = await getTotalDescendantsAsync({ children: items });

        return items.map((item, index) =>
        {
            if (item.hidden === 0) {

                return <>
                    <Draggable
                        onDrag={(e, data) => handleDrag(e, data, item)}
                        onStop={(e, data) => handleStop(e, data, item)}
                        onClick={() => setSelectedItemId(item.id)}
                        position_={item.position_}
                        className={selectedItemId === item.id ? "selected" : ""}
                    >
                        <div className={"box"} style={{ height: item.height + "px", width: `calc(100% - ${item.position_.x}px)` }}>

                            <div
                                key={item.id}
                                className="item"
                            >


                                {item.text} {JSON.stringify(item.position_)}

                                ------ {item.rank}

                            </div>
                        </div>

                    </Draggable>
                    {item?.children?.length > 0 && renderNestedItems(item.children, level + 1)}
                </>
            }

        });
    };

    const onsubmit = async (values, item) =>
    {

        const updateItemValue = async (items, targetId) =>
        {
            return Promise.all(items.map(async item =>
            {
                if (item.id === targetId) {
                    // Nếu tìm thấy phần tử với id mong muốn, thay đổi giá trị của nó
                    return {
                        ...item,
                        text: values.name,
                        title: values.name,
                        name: values.name,
                        link: values.link,
                        slug: Services.createSlug(values.name),
                        position: values.position,
                        isgroup: values.isgroup,
                        type: values.type,
                        isedit: false,
                    };
                } else if (item.children && item.children.length > 0) {
                    // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                    const updatedChildren = await updateItemValue(item.children, targetId);
                    return {
                        ...item,
                        children: updatedChildren
                    };
                }
                return item;
            }));
        }
        const itemsToRender = await updateItemValue(items, item.id);

        setItems(itemsToRender)
    }
    const cancelEdit = async (item) =>
    {
        const updateItemValue = async (items, targetId) =>
        {
            return Promise.all(items.map(async (item) =>
            {
                if (item.id === targetId) {
                    if (item.name === "") {
                        item.isdelete = true;
                        return {
                            ...item,
                            isedit: false,
                        };
                    }
                    return {
                        ...item,
                        isedit: false,
                    };
                } else if (item.children && item.children.length > 0) {
                    const updatedChildren = await updateItemValue([...item.children], targetId); // Tạo một bản sao của mảng children
                    const childtmp = updatedChildren.filter((childitem) => !childitem.isdelete);

                    return {
                        ...item,
                        children: childtmp,
                    };
                }
                return item;
            }));
        };

        const updatedItems = await updateItemValue([...items], item.id); // Tạo một bản sao của mảng items
        setItems(updatedItems);
    }
    const updateHidden = async (e, item) =>
    {
        const updateItemValue = async (items, targetId, parentId = null) =>
        {
            return Promise.all(items.map(async item =>
            {
                if (item.id === targetId || item.parent_id === parentId) {

                    if (item.children && item.children.length > 0) {
                        // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                        const updatedChildren = await updateItemValue(item.children, targetId, item.id);
                        return {
                            ...item,
                            hidden: e,
                            children: updatedChildren
                        };
                    }
                    return {
                        ...item,
                        hidden: e
                    };
                } else if (item.children && item.children.length > 0) {
                    // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                    const updatedChildren = await updateItemValue(item.children, targetId);
                    return {
                        ...item,
                        children: updatedChildren
                    };
                }

                return item;
            }));
        }
        const itemsToRender = await updateItemValue([...items], item.id);

        setItems(itemsToRender)
    }
    const addnew = async (item) =>
    {
        const k = uuidv4()
        const updateItemValue = async (items, targetId) =>
        {

            return Promise.all(items.map(async item =>
            {
                if (item.id === targetId) {
                    const itemClone = { ...item };
                    // Nếu tìm thấy phần tử với id mong muốn, thay đổi giá trị của nó
                    let childtmp = null;

                    if (itemClone.children[0]) {
                        itemClone.children.map((childitem, index) =>
                        {
                            childitem.rank += 1;
                        });
                        childtmp = {
                            ...itemClone.children[0],
                            id: k,
                            key: -itemClone.children[0].key,
                            rank: 1,
                            name: "",
                            text: "",
                            isedit: true,
                            isnew: true,
                            parent_id: itemClone.id
                        };
                    } else {

                        childtmp = {
                            ...itemClone,
                            id: k,
                            key: -itemClone.key,
                            position_: {
                                ...itemClone.position_,
                                x: itemClone.initialPosition_.x + margin_,
                            },
                            initialPosition_: {
                                ...itemClone.initialPosition_,
                                x: itemClone.initialPosition_.x + margin_,
                            },
                            name: "",
                            text: "",
                            isedit: true,
                            isnew: true,
                            parent_id: itemClone.id
                        };
                    }

                    return {
                        ...itemClone,
                        children: [...itemClone.children, { ...childtmp }],
                    };
                } else if (item.children && item.children.length > 0) {
                    // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                    const updatedChildren = await updateItemValue(item.children, targetId);
                    return {
                        ...item,
                        children: updatedChildren
                    };
                }
                return item;
            }));
        }
        const itemsToRender = await updateItemValue([...items], item.id);

        setItems(itemsToRender)
    }
    const onchangetype = (e) =>
    {
        if (e === 1) {
            setOpen(true)
            getContentDrawer();
        }

    }
    // Hàm để đảm bảo đệ quy hoàn toàn
    async function renderNestedItemsAsync(items, level = 0)
    {
        const sortedItems = items.sort((a, b) => a.rank - b.rank);
        const renderedItems = [];
        for (const item of sortedItems) {
            const totalDescendants = await getTotalDescendantsAsync(item);
            if (!item.deleted) {
                const draggableItem = (
                    <Draggable
                        onDrag={(e, data) => handleDrag(e, data, item)}
                        onStop={(e, data) => handleStop(e, data, item)}
                        onClick={() => setSelectedItemId(item.id)}
                        position={item.position_}
                        // className={selectedItemId === item.id ? "selected item_drag" : "item_drag"}
                        key={item.id}
                        disabled={item.isedit}
                    >
                        <div className={"box"} key={item.id} style={{ minHeight: item.height + "px", height: "fit-content", width: `calc(100% - ${item.position_.x}px)`, }}>
                            <div className='before' style={{ backgroundColor: `${Colors.pop3[2]}`, color: `${Colors.pop3[4]}` }}>
                                {item.type === 0 && <UnorderedListOutlined />}
                                {item.type === 1 && <SnippetsOutlined />}
                                {item.type === 2 && <i className="fa-solid fa-blog"></i>}

                            </div>
                            <div style={{ padding: `${item.isedit ? "0 0px 0 0" : "0 10px"}`, width: "100%" }}>
                                {totalDescendants > 0 &&
                                    Array.from({ length: totalDescendants }, (_, elin) => (
                                        <div key={elin} className='box_after' style={{ top: `${(elin + 1) * 100}%` }}></div>
                                    ))}

                                <div className="item">
                                    {item.isedit && <Form name="formedit" className='form-edit' ref={formSearchRef} onFinish={(e) => onsubmit(e, item)}
                                        layout="horizontal"
                                        style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex",

                                        }}>

                                        <div className='row' style={{ padding: 0,width:"100%"}}>
                                            <div className='col-lg-3 col-sm-3 col-md-3' style={{padding:0}}>
                                                <Form.Item name="name" className='item_form' style={{
                                                    width: "100%",
                                                    margin: 0
                                                }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Tên là bắt buộc"
                                                        },
                                                    ]}
                                                    initialValue={item.text}
                                                >
                                                    <Input placeholder="Nhập tên" style={{  width: "100%",height:"100%" }} />
                                                </Form.Item>
                                                
                                            </div>
                                            <div className='col-lg-1 col-sm-1 col-md-1' style={{ padding: 0 }}>
                                                <Form.Item name="link" className='item_form' style={{
                                                    width: "100%",
                                                    margin: 0
                                                }}
                                                    
                                                    initialValue={item.link}
                                                >
                                                    <Input placeholder="Link" style={{ width: "100%", height: "100%" }} />
                                                </Form.Item>
                                            </div>
                                            <div className='col-lg-2 col-sm-2 col-md-2' style={{ padding: 0 }}>
                                                <Form.Item
                                                    className='item_form'
                                                    name="position"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Vị trí là bắt buộc"
                                                        },
                                                    ]}
                                                    initialValue={item.position}
                                                    style={{
                                                        width: "100%",
                                                        margin: 0
                                                    }}
                                                >

                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%", height: "100%" }}
                                                        showArrow={true}
                                                        defaultActiveFirstOption={true}
                                                        options={[{ value: 0, label: "Đầu trang" }, { value: 1, label: "Chân trang" }]}


                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className='col-lg-2 col-sm-2 col-md-2' style={{ padding: 0 }}>
                                                <Form.Item className='item_form'
                                                    name="type"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Loại là bắt buộc"
                                                        },
                                                    ]}
                                                    initialValue={item.type}
                                                    style={{
                                                        width: "100%",
                                                        margin: 0
                                                    }}
                                                >

                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%", height: "100%" }}
                                                        showArrow={true}
                                                        defaultActiveFirstOption={true}
                                                        onChange={onchangetype}
                                                        options={[{ value: 0, label: "Sản phẩm" }, { value: 1, label: "Bài viết/Trang" }, { value: 2, label: "Trang blogs" }]}


                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className='col-lg-2 col-sm-2 col-md-2' style={{ padding: 0 }}>
                                                <Form.Item
                                                    className='item_form'
                                                    name="isgroup"
                                                    initialValue={item.isgroup}
                                                    style={{
                                                        width: "100%",
                                                        margin: 0
                                                    }}
                                                >

                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%", height: "100%" }}
                                                        showArrow={true}
                                                        defaultActiveFirstOption={true}
                                                        options={[{ value: 1, label: "Nhóm" }, { value: 0, label: "Bỏ nhóm" }]}


                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className='col-lg-2 col-sm-2 col-md-2 flex_' style={{ padding: "5px" }}>
                                               
                                                
                                                    {/* <div className='flex_' style={{ padding: 0 }}> */}
                                                    <Button type="primary" size="small" style={{ marginRight: "10px" }} htmlType="submit">Lưu</Button>
                                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={() => cancelEdit(item)} >Hủy</Button>

                                                    {/* </div> */}
                                               
                                            </div>
                                        </div>
                                    </Form>}
                                    {!item.isedit && <> <div>

                                        {Services.CutString(item.text, 30)}
                                        {/* {JSON.stringify(item.position_)} ------ {item.rank} */}

                                    </div>
                                        <div className='btn_action_'>
                                            {item.isgroup !== 0 && <GroupOutlined style={{ color: Colors.pop1[2], marginRight: "10px" }} />}
                                            <button style={{ cursor: "pointer" }} className="reset_btn btn_view btn__ scale_btn"
                                                onTouchStart={() => { sortedItemsClick(1, item) }} onClick={() => { sortedItemsClick(1, item) }}> <ArrowUpOutlined style={{ color: Colors.pop3[2] }} /></button>
                                            <button style={{ cursor: "pointer" }} className="reset_btn btn_view btn__ scale_btn" onTouchStart={() => { sortedItemsClick(2, item) }} onClick={() => { sortedItemsClick(2, item) }}> <ArrowDownOutlined style={{ color: Colors.pop3[3] }} /></button>

                                            {/* <Dropdown
                                                className='dropdown_mobile'
                                                trigger={['click', 'hover','touch']}
                                                overlay={<Menu>
                                                    <Menu.Item>
                                                        <SwitchA checked={item.hidden} size="small" onChange={(e) => { updateHidden(e, item) }} style={{ color: Colors.pop1[2], marginRight: "10px", }} />
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button className="reset_btn btn_edit btn__" onClick={() => editRows(item)} style={{ marginRight: `10px !important` }} >
                                                         <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <button className="reset_btn btn_delete btn__" onClick={() => { deleteModal(item) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                                    </Menu.Item>
                                                    <Menu.Item> <button className="reset_btn btn_view btn__" onClick={() => { addnew(item) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button></Menu.Item>
                                                </Menu>}
                                            >
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        <DownOutlined />
                                                    </Space>
                                                </a>
                                            </Dropdown> */}


                                            <div className='action_btn_wrap'>
                                                <SwitchA checked={item.hidden} size="small" onChange={(e) => { updateHidden(e, item) }} style={{ color: Colors.pop1[2], marginRight: "10px", }} />
                                                <button className="reset_btn btn_edit btn__" onTouchStart={() => editRows(item)} onClick={() => editRows(item)} style={{ marginRight: `10px !important` }} >
                                                    <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                                <button className="reset_btn btn_delete btn__" onTouchStart={() => { deleteModal(item) }} onClick={() => { deleteModal(item) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>

                                                <button className="reset_btn btn_view btn__" onTouchStart={() => { addnew(item) }} onClick={() => { addnew(item) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </Draggable>
                );

                renderedItems.push(draggableItem);

                if (item?.children?.length > 0) {
                    const nestedItems = await renderNestedItemsAsync(item.children, level + 1);
                    renderedItems.push(...nestedItems);
                }
            }
        }

        return renderedItems;
    }
    const sortedItemsClick = async (type, item) =>
    {
        // setItemEdit(item)
        try {
            const updateItemValue = async (items, targetId) =>
            {
                return Promise.all(items.map(async (item, indi) =>
                {
                    if (item.id === targetId) {
                        let tmprank = 0;
                        if (type === 1) {
                            if (items[indi - 1]) {
                                if(items[indi - 1].rank==item.rank){
                                    tmprank = items[indi - 1].rank;
                                    items[indi - 1].rank = item.rank+1;
                                }else{
                                    tmprank = items[indi - 1].rank;
                                    items[indi - 1].rank = item.rank
                                }
                               
                            }
    
                        } else {
                            if (items[indi + 1]) {
                                if(items[indi + 1].rank==item.rank){
                                    tmprank = items[indi + 1].rank+1;
                                    items[indi + 1].rank = item.rank;
                                }else{
                                    tmprank = items[indi + 1].rank;
                                    items[indi + 1].rank = item.rank;
                                }
                               
                            }
    
                        }
    
                        item.rank = tmprank;
                        // Nếu tìm thấy phần tử với id mong muốn, thay đổi giá trị của nó
                        return {
                            ...item,
                        };
                    } else if (item.children && item.children.length > 0) {
                        // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                        const updatedChildren = await updateItemValue(item.children, targetId);
    
                        return {
                            ...item,
                            children: updatedChildren
                        };
                    }
                    return item;
                }));
            }
        } catch (error) {
            
        }
       
        const itemsToRender = await updateItemValue([...items], item.id);

        setItems(itemsToRender)
    }
    const getTotalDescendantsAsync = async (item) =>
    {
        let total = 0;

        if (item?.children?.length > 0) {
            total += item.children.length;
            const childPromises = item.children.map(async (child) =>
            {
                const childTotal = await getTotalDescendantsAsync(child);
                total += childTotal;
            });

            await Promise.all(childPromises);
        }

        return total;
    };
    const deleteItem = async (type) =>
    {
        setConfirmLoading(false)

        const updateItemValue = async (items, targetId) =>
        {
            return Promise.all(items.map(async (item) =>
            {
                if (item.id === targetId) {
                    return {
                        ...item,
                        deleted: 1,
                    };
                } else if (item.children && item.children.length > 0) {
                    const updatedChildren = await updateItemValue([...item.children], targetId); // Tạo một bản sao của mảng children
                    return {
                        ...item,
                        children: updatedChildren,
                    };
                }
                return item;
            }));
        };

        const updatedItems = await updateItemValue([...items], itemDelete.id); // Tạo một bản sao của mảng items

        setItems(updatedItems);
        setVisible(false)
    }

    const setChosePost = async (post) =>
    {

        const updateItemValue = async (items, targetId) =>
        {
            return Promise.all(items.map(async item =>
            {
                if (item.id === targetId) {
                    const formvalues = formSearchRef.current.getFieldsValue();
                    formSearchRef.current.setFieldsValue({
                        ...item, ...formvalues,
                        name: post.title,
                        title: post.title,
                        text: post.title,
                        slug: post.slug,
                        link: "/" + post.slug
                    });
                    return {
                        ...item,
                        name: post.title,
                        title: post.title,
                        text: post.title,
                        slug: post.slug,
                        link: "/" + post.slug
                    };
                } else if (item.children && item.children.length > 0) {
                    // Nếu phần tử có phần tử con, gọi đệ quy để thay đổi giá trị trong các phần tử con
                    const updatedChildren = await updateItemValue(item.children, targetId);
                    return {
                        ...item,
                        children: updatedChildren
                    };
                }
                return item;
            }));
        }
        const itemsToRender = await updateItemValue([...items], itemEdit.id);

        setItems([...itemsToRender])
        setOpen(false)
    }
    return (
        <div className='dr_box_menu'>


            <Drawer title="Chọn bài viết hoặc trang" placement="right"
                onClose={() => setOpen(false)}
                visible={open} getContainer={false}
                style={{
                    position: 'absolute',

                }}
            >
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={drawContent}
                    renderItem={(item) => (
                        <List.Item
                            key={item.title}
                            actions={
                                !drawContent.length > 0
                                    ? [
                                        <IconText icon={StarOutlined} text={item.reviews} key="list-vertical-star-o" />,
                                        <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                                        <IconText icon={MessageOutlined} text={"100"} key="list-vertical-message" />,
                                    ]
                                    : undefined
                            }

                        >
                            <Skeleton loading={drawContent.length <= 0} active avatar>
                                <List.Item.Meta
                                    avatar={<Avatar src={LINK.myHostimgServerImg + "/" + item.thumbnail} />}
                                    title={<a href={"#"} onClick={() => { setChosePost(item) }}>{item.title}</a>}
                                    description={item.description}
                                />
                                {/* {item.description} */}
                            </Skeleton>
                        </List.Item>
                    )}
                />

            </Drawer>
            <Modal
                title="Thông báo"
                visible={visible}
                onOk={() => deleteItem(true)}
                confirmLoading={confirmLoading}
                onCancel={() => setVisible(false)}
            >
                <p>{modalText}</p>
            </Modal>
            {renderedItems}
        </div>
    );
};

export default Index;
