import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';
import { Divider, Radio, Table, Button, Drawer, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, InputNumber } from 'antd';
import * as Services from "./../../../../service";
import {
FolderViewOutlined, AppstoreOutlined,
DeleteOutlined, PlusCircleOutlined, UnorderedListOutlined,
EditOutlined, SearchOutlined, FileImageOutlined
} from '@ant-design/icons';
import { Scrollbar } from 'smooth-scrollbar-react';
import { META } from "../../../../utils/constant";
import DragDropmenu from "./../../../../components/dragdropmenu/index";
import { TYPE_CATE } from "../../../../constants/DefaultData";
import Upload from "./Upload";

const { Search } = Input;
class Index extends Component {

    constructor(props) {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListCategory: [],
            meta: {
                ...META,
            },
            isloading: false,
            isLoadingSearch: false,
            data: [
                { key: 1, name: "ok", hidden: false, updated_at: "2022-09-04T13:36:58.000000Z" }
            ],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                // getCheckboxProps: (record) => ({
                //     disabled: record.name === 'Disabled User',
                //     // Column configuration not to be checked
                //     name: record.name,
                // }),
                checkStrictly: true
            },
            columns: [

                {
                    title: 'Tên',
                    dataIndex: 'name_',
                    filterMode: 'tree',
                    filterSearch: true,
                    width: "40%",

                    onFilter: (value, record) => record.address.startsWith(value),
                    sorter: (a, b) => a.name_ - b.name_,
                },

                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',
                    width: "10%",
                    onFilter: (value, record) => {
                        console.log('====================================');
                        console.log(value);
                        console.log('====================================');
                        return record.hidden === value

                    },

                    sorter: (a, b) => a.hidden > b.hidden,
                    render: (text, record, index) => {

                        return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                            loading={this.state.isloading} />
                    },

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    filterSearch: true,

                    sorter: (a, b) => a.created_at - b.created_at,
                    render: (text, record, index) => {
                        return moment(text).format('DD/MM/YYYY h:m:s');;
                    }

                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    filterSearch: true,

                    sorter: (a, b) => a.updated_at - b.updated_at,
                    render: (text, record, index) => {
                        return moment(text).format('DD/MM/YYYY h:m:s');;
                    }
                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "15%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                    <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                {/* {record.parent_id === 0 && */}
                                <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>
                                {/* } */}
                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                parent_id: 0,
                hidden: 0,
                isgroup: 0,
                position: 0,
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreCategory: {},
            isloadingSavelist: false,
            ListPosts: [],
            isShowList: false,
            imgesInfoRemove: [],
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
        }

    }
    SetisShowList = (type) => {

        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state => {
            return {
                isShowList: type
            }
        })
    }
    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSavelist = (type) => {
        this.setState(state => {
            return {
                isloadingSavelist: type
            }
        })
    }

    SetisLoadingSeach = (type) => {
        this.setState(state => {
            return {
                isLoadingSearch: type
            }
        })
    }
    updateRecord = (e, record) => {
        const tmp = { ...record, hidden: !e }

        this.SetisLoading(true)
        this.props.StoreCategoryRequest(tmp, tmp.id);

    }
    updateRecordIsgroup = (e, record) => {
        const tmp = { ...record, isgroup: e }

        this.SetisLoading(true)
        this.props.StoreCategoryRequest(tmp, tmp.id);

    }
    setVisible = (type) => {
        this.setState(state => {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) => {
        this.setState(state => {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) => {
        this.setState(state => {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () => {
        this.setVisible(true);
    };

    handleOkDelete = () => {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreCategoryRequest(record, record.id ? record.id : "")

    };

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () => {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)


        const urlParams = Services.getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
        this.props.ListCategoryRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {

          
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        if (nextProps.Web.StoreCategory && nextProps.Web.StoreCategory !== prevState.StoreCategory) {
            return {
                StoreCategory: nextProps.Web.StoreCategory,

            };

        }
        if (nextProps.Web.ListCategory && nextProps.Web.ListCategory.data !== prevState.ListCategory) {


            return {
                ListCategory: nextProps.Web.ListCategory.data,
                // isLoadingSearch: false
            };

        }
        if (nextProps.Web.ListPosts && nextProps.Web.ListPosts.data !== prevState.ListPosts) {

            return {
                ListPosts: nextProps.Web.ListPosts.data,

            };

        }



        return null;


    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.StoreCategory !== prevState.StoreCategory) {
            let { StoreCategory } = this.state;

            try {
                if (StoreCategory.code === 200) {
                    if (StoreCategory.message && typeof StoreCategory.message === "string") {
                        message.success(StoreCategory.message)

                    } else {

                        message.error("Có lỗi!")
                    }
                    this.setState(state => {
                        return {
                            record: {
                                parent_id: 0,
                                hidden: 0,
                                deleted: 0
                            }
                        }
                    })
                    if (this.formRef.current) {
                        this.formRef.current.resetFields()
                    }

                    const urlParams = Services.getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
                    this.props.ListCategoryRequest({
                        limit: this.state.meta.per_page * 1000,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    });
                }
                else {
                    if (StoreCategory.message && typeof StoreCategory.message === "string") {

                        message.error(StoreCategory.message)
                    } else {
                        for (let x in StoreCategory.message) {
                            let mess = StoreCategory.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.SetisLoadingSavelist(false)
            this.setVisible(false);
            this.setConfirmLoading(false);
            this.props.ResetStoreCategory()


        }
        if (this.state.ListCategory !== prevState.ListCategory) {

            this.SetisLoadingSeach(false)
            this.data();
        }
        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;
            console.log("UploadFileLaUploadFileLaUploadFileLa", UploadFileLa);
            if (parseInt(UploadFileLa.type) === 1) {
                if (UploadFileLa.code !== 200) {

                    message.error("Cập nhật ảnh chính thất bại!");
                    // return;
                }

                this.setState(state => {
                    return {
                        record: { ...state.record, image: UploadFileLa.data[0] }
                    }
                }, () => {
                    if (this.state.isupload === 1) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }

            this.props.ResetUploadFileLa();
        }
    }
    setColumn_ = (filters) => {
        this.setState(state => {
            return {
                columns: [
                    // {
                    //     title: 'STT',
                    //     dataIndex: 'key',


                    // },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) => {

                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Vị trí',
                        dataIndex: 'position',
                        width: "10%",
                        render: (text, record, index) => {

                            return text === 0 ? <Tag color={Colors.colortext} style={{ cursor: "pointer" }} >Đầu trang</Tag> :
                                <Tag color={Colors.colorAdd} style={{ cursor: "pointer" }} >Chân trang</Tag>
                        },

                    },
                    {
                        title: 'Nhóm',
                        dataIndex: 'isgroup',
                        width: "10%",
                        align: "center",
                        render: (text, record, index) => {

                            return <SwitchA defaultChecked={record.isgroup} onChange={(e) => { this.updateRecordIsgroup(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },
                    {
                        title: 'Ẩn',
                        dataIndex: 'hidden',
                        width: "10%",
                        align: "center",
                        onFilter: (value, record) => {

                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) => {

                            return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "25%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    {/* {record.parent_id === 0 && */}
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>
                                    {/* } */}
                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () => {
        let { ListCategory } = this.state;


        // return;
        if (ListCategory.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];

        const result = { listfil: [], data: [] }

        ListCategory.map((item, index) => {

            listfil.push({
                text: item.name,
                value: item.name,
            })


        });
        this.setColumn_(listfil);
        this.setState(state => {
            return {
                data: ListCategory,

            }
        })
    }

    renderTableTree = (arr, result, isChildren = false, num = 0, level = 0) => {
        arr.map((item, index) => {
            num++
            item["name_"] = item.name;
            result.listfil.push({
                text: item["name_"],
                value: item["name_"],

            })
            if (isChildren) {
                let str_sub = "";
                if (level > 0) {

                    for (let i = 0; i < level; i++) {
                        str_sub += "------| ";

                    }
                }
                item["name_"] = str_sub + item.name;

            }
            // item.key = index + 1
            result.data.push({ key: num, id: item.id, name_: item.name_, name: item.name, hidden: item.hidden, parent_id: item.parent_id, slug: item.slug, updated_at: item.updated_at })
            if (item.children) {
                level++
                this.renderTableTree(Object.values(item.children), result, true, num, level);
            }
        });

    }

    renderListRecursive = (data, level = 0, list) => {
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.children) {
                level++;
                this.renderListRecursive(data, level, list)
            }
            if (level > 0) {
                let str = '';
                for (let j = 0; j <= level; j++) {
                    str += "--";

                }
                element.name = str + element.name;
            }
        }
    }

    onChangetext = (e, type, group) => {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            console.log('====================================');
            console.log(group, type, e);
            console.log('====================================');
            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })

        }

    }
    editRows = (record) =>
        {
            let avata = [{
                uid: -1,
                name: record.image,
                status: 'done',
                url: LINK.myHostimgServerImg + "/" + record.image,
            }];
            this.setState(state =>
            {
                return {
                    record: record,
                    imgesInfo: { avata: avata, detail: [], video: [] },
                }
            })
    
            this.formRef.current.setFieldsValue({ ...record })
        }
    // editRows = (record) => {

    //     this.setState(state => {
    //         return {
    //             record: record
    //         }
    //     })
    //     this.formRef.current.setFieldsValue({ ...record })
    // }
    addnew = (record = "") => {


        this.setState(state => {
            return {
                record: { name: "", deleted: 0, hidden: 0, isgroup: 0, position: 0, parent_id: record.id ? record.id : 0, parent: { name: record.name ? record.name : "Gốc" } }
            }
        })
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({ parent_id: record.id ? record.id : 0 })

    }

    onSearch = (values) => {
        this.SetisLoadingSeach(true)
        this.props.SearchCategoryRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: values });
        const searchParams = new URLSearchParams(values).toString();

        this.props.history.push(`${LINK.WEB_CATEGORY}?${searchParams}`);

    }
    deleteModal = (record) => {
        this.setState(state => {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () => {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })

    }
    OnPagination = (page, pageSize) => {
        const urlParams = Services.getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchCategoryRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        // this.props.ListProductsRequest({
        //     limit: pageSize,
        //     page: page, column: this.state.meta.column, sort: this.state.meta.sort
        // });
    }

    onFinishForm = (values) => {

        const { record } = this.state;
        const record_ = { ...record, ...values }

        this.SetisLoading(true)
        this.props.StoreCategoryRequest(record_, record_.id ? record_.id : "");
    }
    getMenus = (menus) => {
        if (menus) {
            this.setState(state => {
                return {
                    menus: menus
                }
            })
        }
    }
    saveMenusList = () => {
        const { menus } = this.state;
        this.SetisLoadingSavelist(true)

        this.props.StoreCategoryMultipleRequest({ data: menus });


    }

    onSearchPost = (values = "") => {
        // this.SetisLoadingSeach(true)
        this.props.SearchPostsRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });


    }

    uploadFile = (values) => {
        const { imgesInfo, } = this.state;


        this.setState(state => {
            return {
                required: {},
                values: values
            }
        })
        // if (!this.CheckDataRequired()) {
        //     return;
        // }

        this.SetisLoading(true)
        let isupload = 0;
        // this.SetisLoading(true)
        if (imgesInfo.avata.length > 0) {
            let arravata = imgesInfo.avata.filter(item => {
                return item.originFileObj;
            })
            if (arravata.length > 0) {
                this.props.UploadFileLaRequest(arravata, "img", 1);
                isupload = 1;
            }

        }

      //  console.log("nextProps.Web.UploadFileLanextProps.Web.UploadFileLa",nextProps.Web.UploadFileLa);
        this.setState(state => {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    setimgesInfo = (type, data) => {


        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state => {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],
                    // imgesInfo: {
                    //     ...state.imgesInfo, [type]: data
                    // },

                }
            }, () => {
                this.setState(state => {

                    return {
                        // imgesInfoRemove: [...state.imgesInfoRemove, ...state.imgesInfo[type]],
                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })


            // imageDelete["folder"] = type;

        } else {
            this.setState(state => {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) => {
        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item => {
                return item !== data.name;
            })
            imagesrecord = this.state.record.images.filter(item => {
                return item !== data.name;
            })
            this.setState(state => {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state => {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }
    render() {
        let { routers } = this.props;

        return (

            <div className="main_content category">

                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>

                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Danh mục</span>
                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <Link to={LINK.WEB_CATEGORY_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {(Services.checkScreenSize().width >= 600 && this.state.isShowList) && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                        {/* <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button> */}

                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                            // maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px",
                            padding: 0
                        }}
                    >
                        {/* <h1 style={{ color: Colors.pop2[3] }}>Danh mục</h1> */}
                        <div className="form__half row">
                            <div className="left_half col-lg-8 col-md-12 col-sm-12">
                                <div className="btn_action_list">

                                    <div className="left_" style={{ display: "flex" }}>
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{

                                            }}>
                                                <Input placeholder="Nhập tên danh mục" name="search" id="search" />
                                            </Form.Item>
                                            <Form.Item noStyle
                                                name="type"

                                                initialValue={0}
                                            >

                                                <Select
                                                    showSearch
                                                    // style={{ width: "100%", }}
                                                    showArrow={true}
                                                    defaultActiveFirstOption={true}
                                                    options={[{ value: "", label: "Tất cả" }, { value: 0, label: "Sản phẩm" }, { value: 1, label: "Bài viết" }, { value: 2, label: "Trang blogs" }]}


                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>


                                </div>
                                {!this.state.isShowList && <><div className="menu_drag">
                                    <DragDropmenu menus={this.state.ListCategory} getMenus={this.getMenus}
                                        getContentDrawer={this.onSearchPost}
                                        drawContent={this.state.ListPosts}
                                    />
                                </div>    <div className="btn_save_cate_tree">
                                        <Button type="primary" loading={this.state.isloadingSavelist} onClick={() => { this.saveMenusList() }}>Lưu</Button>
                                    </div></>}
                                {this.state.isShowList && <Table
                                    rowClassName={(record, index) => {
                                        return "__row_";
                                    }}

                                    columns={this.state.columns}

                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                    scroll={{

                                        y: "60vh",
                                    }}
                                />
                                }

                            </div>
                            {this.state.isShowList && <>

                                <div className="right_half col-lg-4 col-md-12 col-sm-12">

                                    {/* <h5 style={{ color: Colors.color1 }}>
                                    {this.state.record?.parent && "Danh mục gốc: " + this.state?.record?.parent?.name}
                                    {!this.state.record?.parent && "Danh mục gốc: Gốc"}
                                </h5> */}
                                    <Form name="formadd" onFinish={this.uploadFile} ref={this.formRef}
                                        labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Form.Item label="Danh mục" name={"parent_id"}

                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Danh mục là bắt buộc"
                                                },
                                            ]}>
                                            <TreeSelect
                                                showSearch
                                                style={{ width: '100%' }}
                                                treeData={this.state.ListCategory}

                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder="Danh mục"
                                                // allowClear
                                                // multiple
                                                treeDefaultExpandAll


                                            />

                                        </Form.Item>
                                        <Form.Item label="Tên"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Tên danh mục là bắt buộc"
                                                },
                                            ]}
                                        >

                                            <Input name="name" id="name" placeholder="Tên danh mục" onChange={(e) => {
                                                this.formRef.current.setFieldsValue({ slug: Services.createSlug(e.target.value) })
                                            }} />
                                        </Form.Item>
                                        <Form.Item label="Slug"
                                            name="slug"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Slug là bắt buộc"
                                                },
                                            ]}
                                        >

                                            <Input name="slug" id="slug" placeholder="slug" />
                                        </Form.Item>
                                        <Form.Item label="Vị trí hiển thị"
                                            name="position"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vị trí là bắt buộc"
                                                },
                                            ]}
                                        >

                                            <Select
                                                showSearch
                                                style={{ width: "100%", }}
                                                showArrow={true}
                                                defaultActiveFirstOption={true}
                                                options={[{ value: 0, label: "Đầu trang" }, { value: 1, label: "Chân trang" }]}


                                            />
                                        </Form.Item>

                                        <Form.Item label="Loại"
                                            name="type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Loại là bắt buộc"
                                                },
                                            ]}
                                        >

                                            <Select
                                                showSearch
                                                style={{ width: "100%", }}
                                                showArrow={true}
                                                defaultActiveFirstOption={true}
                                                options={TYPE_CATE}


                                            />
                                        </Form.Item>
                                        <Form.Item label="Sắp xếp"
                                            name="rank"
                                           
                                        >

                                            <InputNumber name="Sắp xếp" id="Sắp xếp" placeholder="Sắp xếp" />
                                        </Form.Item>
                                        <Form.Item label="Hình ảnh">
                                            <Upload isCropImg={false} multiple={false} max={1} type_={"avata"} type_f={1} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} fileList={this.state.imgesInfo.avata} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} />
                                        </Form.Item>
                                        <Form.Item >
                                            <div className="flex_">
                                                <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

                                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
                                            </div>
                                        </Form.Item>
                                    </Form>

                                </div></>}
                        </div>
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {
        SearchCategoryRequest: (body, data) => {
            dispatch(ActionsWeb.SearchCategoryRequest(body, data));
        },
        ListCategoryRequest: (body) => {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
        StoreCategoryRequest: (data, id) => {
            dispatch(ActionsWeb.StoreCategoryRequest(data, id));
        },
        ResetStoreCategory: () => {
            dispatch(ActionsWeb.ResetStoreCategory());
        },
        DeleteCategoryRequest: (body) => {
            dispatch(ActionsWeb.DeleteCategoryRequest(body));
        },
        ResetDeleteCategory: () => {
            dispatch(ActionsWeb.ResetDeleteCategory());
        },
        StoreCategoryMultipleRequest: (data) => {
            dispatch(ActionsWeb.StoreCategoryMultipleRequest(data));
        },
        SearchPostsRequest: (body, data) => {
            dispatch(ActionsWeb.SearchPostsRequest(body, data));
        },
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
            {
                dispatch(ActionsWeb.ResetUploadFileLa());
            }, 
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);