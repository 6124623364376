import React, { Component } from "react";
import * as LINK from "./../../constants/Links";

// import Livechat from "./../../components/";
import { connect } from "react-redux";
import { pageToken, getCookie } from "./../../service";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import * as ActionsLogin from "./../../actions/login";
import * as ActionsWeb from "./../../actions/indexWeb";
import * as Colors from "./../../constants/Colors";
import
{
  Spin
} from 'antd';
import
{
  UserOutlined, ShopOutlined,
  LogoutOutlined, LoadingOutlined
} from '@ant-design/icons';
// import "./style.css";
const antIcon = (size) =>
{
  return <LoadingOutlined
    style={{
      fontSize: size,
    }}
    spin
  />
};
class Home extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      AllActivePage: [],
      login: "",
      Logout: "",
      GetListPage: "",
      dataList: "",
      isShowMenuTop: "",
      user: {},
      isloading: false,
      settings: null
    };

  }
  SetisLoading = (type) =>
  {
    this.setState(state =>
    {
      return {
        isloading: type
      }
    })
  }
  getPageToken = () =>
  {

    let pagetoken = pageToken();

    this.setState(state =>
    {
      return {
        AllActivePage: pagetoken
      }
    }, () =>
    {

      let mangIDpage = this.CreateArrPageid()


      // if (mangIDpage) {
      //   this.props.GetConvertion(mangIDpage);
      //   this.props.GetComment(mangIDpage);
      // }

      // this.props.Getprovince(AllActivePage[0].idpage);
    })
  }

  CreateArrPageid = () =>
  {
    let { AllActivePage } = this.state;
    let result = [];
    for (let i = 0; i < AllActivePage.length; i++) {
      const element = AllActivePage[i];
      result.push(element.idpage);

    }
    return result;
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {


    
    if (nextProps.Web.Logout && nextProps.Web.Logout !== prevState.Logout) {
      return {
        Logout: nextProps.Web.Logout
      }

    }
    return null



  }

  componentDidUpdate(prevProps, prevState)
  {

    if (this.state.Logout !== prevState.Logout) {

      const { Logout } = this.state
      if (Logout.code === 200) {
        // window.location=
        window.location = LINK.LOGIN;
      }
    }
  
  }
  componentDidMount()
  {

    let user = getCookie("user");

    if (user) {
      user = JSON.parse(user);
      this.props.SetUserRequest(user);
      this.setState(state =>
      {
        return {
          user: user
        }
      })
    }
    let settings = localStorage.getItem("settings");
    if (settings) {
      settings = JSON.parse(settings);

      const set_ = settings.filter(item =>
      {
        return item.type === 2;
      })
      if (set_?.[0]?.data) {
        this.setState(state =>
        {
          return {
            settings: JSON.parse(set_[0].data)
          }
        })

      }
    }


  }

  logout_ = () =>
  {
    const { user } = this.state
    this.SetisLoading(true)
    this.props.LogoutRequest({ user_name: user.user_name });

  }
  render()
  {
    let { routers } = this.props;

    return (
      <div>
        {this.state.isloading && <div className="ovelay">
          <div className="float_left"></div>
          <div className="loading">
            <Spin indicator={() => antIcon(24)} />
          </div>
        </div>
        }
        <div className="col-md-12 br-page" style={{ backgroundColor: Colors.pop2[2], borderColor: Colors.pop2[0], borderBottom: "1px solid " + Colors.pop2[0] }}>
          <div className="header-chat">
            <div className="group-page">
              <div className="page-chat">
                <div className="page-live logo_top" >
                  <img src={this.state.settings?.logo ? `${LINK.myHostimgServerImg}/${this.state.settings?.logo?.value?.[0]}` : `${LINK.myHostimg}/logorim.png`} alt="" style={{ borderColor: Colors.pop2[0] }} />
                </div>
                <div className="page-live page-add btn_bar">
                  <button className="reset_btn" onClick={() => this.setState({ isShowMenuTop: !this.state.isShowMenuTop })}><i className="fas fa-bars"></i></button>
                </div>
             

              </div>
            </div>
            <div className="profile-noti">
              <ul>
                <li>
                  <div> <span className="icon" style={{ color: Colors.pop2[3] }}><ShopOutlined /></span><span className="text" style={{ color: Colors.pop2[0] }}>{this.state.user?.store?.name}</span></div>
                </li>
                <li>
                  <div> <span className="icon" style={{ color: Colors.pop2[3] }}><UserOutlined /></span><span className="text" style={{ color: Colors.pop2[0] }}>{this.state.user.name}</span></div>
                </li>
                <li>
                  <div><button className="reset_btn" onClick={this.logout_}> <span className="icon" style={{ color: Colors.pop2[0] }}><LogoutOutlined style={{ color: Colors.pop2[3] }} /></span></button></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="home" >

          <Switch>
            {routers &&
              routers.length > 0 &&
              routers.map((route, index) => (

                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  // component={route.main}
                  render={props => (
                    route.main({ ...props, routers: route.routers })
                  )}

                />

              ))

            }

          </Switch>

        </div>
      </div >
    );
  }
}
const mapStateToProps = (state) =>
{
  return {
    GetConv: state.GetChat,
    Page: state.Page,
    Web: state.Web
  };
};
const mapDispatchToProps = (dispatch, props) =>
{
  return {
  
    // login
    SetUserRequest: (data) =>
    {
      dispatch(ActionsLogin.SetUserRequest(data));
    },
    LogoutRequest: (data) =>
    {
      dispatch(ActionsLogin.LogoutRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
