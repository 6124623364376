import React from 'react';

const PDFPrinter = ({ file }) =>
{
    const print = () =>
    {
        const blob = new Blob([file], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        // console.log(url);
        const pdfFrame = document.createElement('iframe');
        pdfFrame.style.visibility = 'hidden';
        pdfFrame.src = url;

        document.body.appendChild(pdfFrame);

        pdfFrame.contentWindow.focus();
        pdfFrame.contentWindow.print();
        
    };
    // const printV2 = () =>
    //     {
    //         const canvas = document.querySelector('.react-pdf__Page__canvas');
    //         console.log("pdfFramepdfFrame",canvas);
    //         const dataUrl = canvas.toDataURL('image/png');
        
    //         // Tạo cửa sổ mới
    //         const printWindow = window.open('', '_blank');
            
    //         // Viết nội dung HTML cho cửa sổ mới
    //         printWindow.document.write(`
    //             <html>
    //                 <head>
    //                     <title>Print Canvas</title>
    //                 </head>
    //                 <body>
    //                     <img src="${dataUrl}" />
    //                 </body>
    //             </html>
    //         `);
    
    //         // Đợi cho nội dung được tải xong
    //         printWindow.document.close();
    //         printWindow.onload = () => {
    //             printWindow.print();
    //             printWindow.close();
    //         };
    //     };
    return (
        <i className="fas fa-print clickable" onClick={print} title="download" />
    );
};

export default PDFPrinter;
