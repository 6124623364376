import { Avatar, Button, Dropdown, Image, Switch as SwitchA, List, Menu, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import * as LINK from "../../../../constants/Links";
import * as ReportConstant from "../../../../constants/Reports";
import { CutString } from "../../../../service";
import
{
    DownOutlined, SmileOutlined, DiffOutlined, DeleteOutlined

} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';


const count = 3;

const App = ({ ...props }) =>
{
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);
    const history= useHistory();

    useEffect(() =>
    {

        
        
        if (!props.isNewList) {

            const newData = data.concat(props.lists);
            setData(newData);
            setList(newData);
            setLoading(false);
            setInitLoading(false);
        } else {
            setInitLoading(false);
            setLoading(false);
            setData(props.lists);
            setList(props.lists);
            props.setIsNewList();

        }
        window.dispatchEvent(new Event('resize'));
    }, [props.lists]);


    const onLoadMore = () =>
    {
        if (props.current_page < props.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            props.OnPagination(props.current_page + 1, props.per_page)

        }

    };
   const redirectReport=(key)=>{
        if(!key){
            if(props?.showNote){
                props.showNote();
            }
           
            return;
        }
        switch (key) {
            case ReportConstant.BESTSELLING:
                history.push(`${LINK.WEB_REPORT_BESTSELLING}`);
                return;
                case ReportConstant.REVENUE_EACH_PRODUCT:
                history.push(`${LINK.WEB_REPORT_REVENUE_PRODUCT}`);
                return;
                case ReportConstant.REVENUE_BY_CUSTOMER:
                    history.push(`${LINK.WEB_REPORT_REVENUE_CUSTOMER}`);
                    return 
            default:
                return
        }
        
    }
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <List
            className="mobile-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={list}
            renderItem={(item) => (
                <List.Item className='list_item_custom' style={{ backgroundColor:item.color,height:"100px" }}
                    actions={[ 
                    //     <a key="list-loadmore-edit" onClick={() => props.editRows(item)}>Sửa</a>,
                    //      <Dropdown overlay={<Menu>

                    //         <Menu.Item icon={<DiffOutlined />} onClick={() => props.duplicateItem(item)}>
                    //             Nhân bản
                    //         </Menu.Item>
                    //         <Menu.Item icon={<DeleteOutlined />} danger onClick={() => props.deleteModal(item)}>Xóa</Menu.Item>
                    //     </Menu>}>
                    //     <a onClick={(e) => e.preventDefault()}>
                    //         <Space>
                    //             more
                    //             <DownOutlined />
                    //         </Space>
                    //     </a>
                    // </Dropdown>
                    ]}
                    onClick={()=>{
                        redirectReport(item.key);
                    }}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            title={<div>{CutString(item.title, 20, true)}</div>}
                            description={<div>{CutString(item?.description, 30, true)}</div>}
                        />
                       
                    </Skeleton>
                </List.Item>
            )}
        />
    );
};
export default App;