import React, { Component, createRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as LINK from "../../../../constants/Links";
import { Input, Table, Pagination, Modal, message, Button, Form, Select, Switch as SwitchA, Card } from 'antd';
import * as Services from "./../../../../service";
import { replaceThous, getParamsUrl } from "./../../../../service";
import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined, AppstoreOutlined,
    DeleteOutlined, UnorderedListOutlined, SearchOutlined,
    EditOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";

import IndexMobile from "./IndexMobile";

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListStore: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Vùng',
                    dataIndex: 'regions',
                    sorter: (a, b) => a.code - b.code,
                    render: (text, record, index) =>
                    {
                        if (record.regions) {
                            return record.regions.name
                        }
                    }
                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0,
                hidden: 0,
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreStore: {},
            ListRegion: [],
            isloading: false,
            isLoadingSearch: false,
            isShowList: false,
            isNewList: false,
            isDuplicate:false
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.DeleteStoreRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();

        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        this.props.SearchStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        // this.props.ListStoreRequest({
        //     limit: this.state.meta.per_page,
        //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        // });


        this.props.ListRegionRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListStore && nextProps.Web.ListStore !== prevState.ListStore) {

            return {
                ListStore: nextProps.Web.ListStore,

            };

        }
        if (nextProps.Web.StoreStore && nextProps.Web.StoreStore !== prevState.StoreStore) {
            return {
                StoreStore: nextProps.Web.StoreStore,

            };

        }
        if (nextProps.Web.ListRegion && nextProps.Web.ListRegion.data !== prevState.ListRegion) {

            return {

                ListRegion: nextProps.Web.ListRegion.data,

            };

        }
        return null;


    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreStore !== prevState.StoreStore) {
            let { StoreStore, isDuplicate } = this.state;
            try {
                if (StoreStore.code === 200) {
                    if (StoreStore.message && typeof StoreStore.message === "string") {
                        message.success(StoreStore.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();

                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    if (filteredvalues.region_id) {
                        filteredvalues.region_id = (1 * filteredvalues.region_id)
                    }
                    // this.formSearchRef.current.setFieldsValue(filteredvalues)
                    // this.props.ListStoreRequest({
                    //     limit: this.state.meta.per_page,
                    //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    // });
                    this.props.SearchStoreRequest({
                        limit: this.state.meta.per_page,
                        page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreStore.message && typeof StoreStore.message === "string") {

                        message.error(StoreStore.message)
                    } else {
                        for (let x in StoreStore.message) {
                            let mess = StoreStore.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setVisible(false);

            this.setConfirmLoading(false);
            this.props.ResetStoreStore()
            this.setState(state =>
            {
                return {
                    StoreStore: "",
                    isDuplicate: false
                }
            })
        }
        if (this.state.ListRegion !== prevState.ListRegion) {

            const urlParams = getParamsUrl();

            const filteredvalues = Object.fromEntries(
                Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
            );
            if (filteredvalues.region_id) {
                filteredvalues.region_id = (1 * filteredvalues.region_id);
            }

            this.formSearchRef.current.setFieldsValue(filteredvalues)
        }

        if (this.state.ListStore !== prevState.ListStore) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }

    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Vùng',
                        dataIndex: 'regions',
                        sorter: (a, b) => a.code - b.code,
                        render: (text, record, index) =>
                        {

                            if (record.regions) {
                                return record.regions.name
                            }
                        }
                    },
                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        width: "10%",
                        onFilter: (value, record) =>
                        {
                            console.log('====================================');
                            console.log(value);
                            console.log('====================================');
                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },

                    // {
                    //     title: 'ngày tạo',
                    //     dataIndex: 'created_at',
                    //     sorter: (a, b) => a.created_at - b.created_at,
                    //     render: (text, record, index) =>
                    //     {
                    //         return moment(text).format('DD/MM/YYYY h:m:s');;
                    //     }

                    // },
                    // {
                    //     title: 'Ngày cập nhật',
                    //     dataIndex: 'updated_at',
                    //     sorter: (a, b) => a.updated_at - b.updated_at,
                    //     render: (text, record, index) =>
                    //     {
                    //         return moment(text).format('DD/MM/YYYY h:m:s');;
                    //     }
                    // },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>


                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListStore } = this.state;

        if (ListStore.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListStore.current_page,
            first_page_url: ListStore.first_page_url,
            from: ListStore.from,
            last_page: ListStore.last_page,
            last_page_url: ListStore.last_page_url,
            links: ListStore.links,
            next_page_url: ListStore.next_page_url,
            path: ListStore.path,
            per_page: ListStore.per_page,
            prev_page_url: ListStore.prev_page_url,
            to: ListStore.to,
            total: ListStore.total,
        };
        ListStore.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        }, () =>
        {
            this.setState(state =>
            {
                return {
                    isNewList: false,
                }
            });

        })
    }

    duplicateItem = (record) =>
    {
      
        this.SetisLoading(true)
        this.props.duplicateStoreRequest({ id: record.id })
        this.setState(state =>
        {
            return {
                isNewList: true,
                isDuplicate: true
            }
        })
    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", deleted: 0, hidden: 0 }
            }
        })
        this.formRef.current.resetFields();
    }

    editRows = (record) =>
    {


        this.setState(state =>
        {
            return {
                record: record,

            }
        }, () =>
        {

        })
        this.formRef.current.setFieldsValue({ ...record })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e ? 1 : 0 }

        this.SetisLoading(true)
        this.props.StoreStoreRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }

    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListStoreRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.SearchStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: values });

        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_STORE}?${searchParams}`);

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    // update_ = () =>
    // {
    //     const { record } = this.state;
    //     // console.log('====================================');
    //     // console.log(record);
    //     // console.log('====================================');
    //     // console.log('====================================');
    //     // console.log(checkedList);
    //     // console.log('====================================');
    //     // return;
    //     if (!record.name) {
    //         message.warn("Vui lòng nhập tên!");
    //         return;
    //     }

    //     record.hidden = 0
    //     this.SetisLoading(true)
    //     this.props.StoreStoreRequest(record, record.id ? record.id : "")

    // }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreStoreRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    editRowsMobile = (record) =>
    {


        this.props.history.push({
            pathname: LINK.WEB_STORE_EDIT + "/" + record.id,
        });

    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content store_">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Cửa hàng</span>
                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <Link to={LINK.WEB_STORE_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {Services.checkScreenSize().width >= 600 && this.state.isShowList && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",

                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="form__half row">
                            <div className={(Services.checkScreenSize().width < 600 || !this.state.isShowList) ? "left_half col-lg-12 col-md-12 col-sm-12 " : "left_half col-lg-7 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>

                                            <div className="row" style={{width:"100%"}}>

                                                <div className="col_ col-lg-4 col-md-4 col-sm-6">
                                                    <Form.Item name="region_id" noStyle style={{
                                                     
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{
                                                                width: "100%"
                                                            }}

                                                            placeholder="Vùng"


                                                            options={(this.state.ListRegion || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                        />

                                                    </Form.Item>
                                                </div>


                                                <div className="col_ col-lg-4 col-md-4 col-sm-6">
                                                    <Form.Item name="search" noStyle style={{
                                                       
                                                    }}>
                                                        <Input placeholder="Nhập tên danh mục" name="search" id="search"

                                                            style={{
                                                                width: "100%"
                                                            }}

                                                        />
                                                    </Form.Item>
                                                </div>


                                                <div className="col_ col-lg-4 col-md-4 col-sm-6 flex_">
                                                    <Form.Item noStyle style={{

                                                    }}>
                                                        <Button type="primary" htmlType="submit" style={{ width: "50%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                        <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>

                                </div>
                                {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                                    updateRecord={this.updateRecord}
                                    editRows={this.editRowsMobile}
                                    deleteModal={this.deleteModal}
                                    duplicateItem={this.duplicateItem}
                                    current_page={this.state.meta.current_page}
                                    per_page={this.state.meta.per_page}
                                    last_page={this.state.meta.last_page}
                                    OnPagination={this.OnPagination}
                                    isNewList={this.state.isNewList}
                                />}
                                {Services.checkScreenSize().width >= 600 && this.state.isShowList && <> <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                    rowSelection={{
                                        ...this.state.rowSelection,
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                    scroll={{

                                        y: "60vh",
                                    }}
                                    style={{
                                        paddingBottom: "100px"
                                    }}
                                />
                                    <div className="pagination_">
                                        <Pagination total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                    </div>

                                </>
                                }
                            </div>

                            {Services.checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">
                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Form.Item label="Vùng" name="region_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vùng là bắt buộc"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            style={{
                                                // width: 200,
                                            }}
                                            name="region_id" id="region_id"
                                            placeholder="Vùng"

                                            // value={this.state.record.region_id}
                                            // onChange={(e) => { this.onChangetext(e, "region_id", "record") }}
                                            options={(this.state.ListRegion || []).map((d) => ({
                                                value: d.id,
                                                label: d.name,
                                            }))}
                                        />


                                    </Form.Item>
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên cửa hàng là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tên cửa hàng" showCount maxLength={120} onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code" rules={[
                                        {
                                            required: true,
                                            message: "Code là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>
                                    <Form.Item >


                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </Form>

                            </div>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListStoreRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },
        StoreStoreRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreStoreRequest(data, id));
        },
        DeleteStoreRequest: (data, id) =>
            {
                dispatch(ActionsWeb.DeleteStoreRequest(data, id));
            },
        ResetStoreStore: () =>
        {
            dispatch(ActionsWeb.ResetStoreStore());
        },
        ListRegionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
        SearchStoreRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchStoreRequest(body, data));
        },
        duplicateStoreRequest: (data) =>
        {
            dispatch(ActionsWeb.duplicateStoreRequest(data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);