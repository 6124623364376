
const META = {
    current_page: 1,
    first_page_url: "",
    from: 1,
    last_page: 2,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: 100,
    prev_page_url: "",
    to: 1,
    total: 1,
    column: "id",
    sort: "DESC",
    limit: 1000,
};

const SEX = [{ value: 0, label: "Nữ" }, { value: 1, label: "Nam", }];
export
{
    META,
    SEX

}