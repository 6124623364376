import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import * as ReportsConstant from "../../../../constants/Reports";
import moment from 'moment';

import { Divider, Radio, Input, Table, Select,Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
DeleteOutlined,
EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { formatMN1, CutString, checkScreenSize, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
class Index extends Component {

    constructor(props) {
        super(props);
        this.formSearchRef = createRef(null)
        const currentYear = new Date().getFullYear();
        this.state = {

            Reports: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',

                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.slug - b.slug,
                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },

                {
                    title: 'Nôi dung',
                    dataIndex: 'description',
                    sorter: (a, b) => a.description - b.description,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StorePosts: {},
            search: {},
            isLoadingSearch: false,
            isloading: false,
            isShowList: false,
            isNewList: false,
            columnSort: "amount_sale",
            arrDaybirthday: Array.from({ length: 31 }, (_, i) => ({ id:  (i + 1).toString(), name: (i + 1).toString() })),
            arrMonthbirthday: Array.from({ length: 12 }, (_, i) => ({ id:  (i + 1).toString(), name: (i + 1).toString() })),
            arrYearbirthday: Array.from({ length: 100 }, (_, i) => ({ id: (currentYear - i).toString() , name: (currentYear - i).toString() }))
        }

    }
    SetisShowList = (type) => {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state => {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) => {
        this.setState(state => {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisible = (type) => {
        this.setState(state => {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) => {
        this.setState(state => {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) => {
        this.setState(state => {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () => {
        this.setVisible(true);
    };

    handleOkDelete = () => {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.DeletePostsRequest(record, record.id ? record.id : "")
        this.setState(state => {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        });
    };

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () => {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.ReportRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.columnSort, sort: this.state.meta.sort
        }, { ...filteredvalues, key: ReportsConstant.REVENUE_BY_CUSTOMER });


    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.Web.Reports && nextProps.Web.Reports !== prevState.Reports) {

            return {
                Reports: nextProps.Web.Reports,
                isLoadingSearch: false
            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState) {


        if (this.state.Reports !== prevState.Reports) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) => {
        this.setState(state => {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'stt',

                        align: "center"

                    },

                    {
                        title: 'Code',
                        dataIndex: 'code',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.code,
                        onFilter: (value, record) => {
                            return record.code.startsWith(value)

                        },
                        sorter: (a, b) => a.code - b.code,
                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) => {
                            return record.title.startsWith(value)

                        },
                        sorter: (a, b) => a.title - b.title,
                    },


                    {
                        title: 'phone',
                        dataIndex: 'phone',
                        render: (text, record, index) => {
                            return text;
                        }

                    },

                    {
                        title: 'email',
                        dataIndex: 'email',
                        render: (text, record, index) => {
                            return text;
                        }

                    },
                    {
                        title: 'Ngày Sinh',
                        dataIndex: 'birthday',
                        align: "right",

                        render: (text, record, index) => {
                            return moment(text).format('DD/MM/YYYY');
                        }

                    },
                    {
                        title: 'Số lượng SP bán',
                        dataIndex: 'amount_sale',
                        align: "right",

                        render: (text, record, index) => {
                            return text;
                        }

                    },
                    {
                        title: 'Số lượng đơn hàng',
                        dataIndex: 'amount_order',
                        align: "right",

                        render: (text, record, index) => {
                            return text;
                        }

                    },
                    {
                        title: 'Danh Thu',
                        dataIndex: 'total_order_money',
                        align: "right",

                        render: (text, record, index) => {
                            return formatMN1(text);
                        }

                    },
                    // {
                    //     title: 'ngày tạo',
                    //     dataIndex: 'created_at',
                    //     sorter: (a, b) => a.created_at - b.created_at,
                    //     render: (text, record, index) =>
                    //     {
                    //         return moment(text).format('DD/MM/YYYY h:m:s');
                    //     }

                    // },

                    // {
                    //     title: 'Hành động',
                    //     dataIndex: 'action',
                    //     width: "20%",
                    //     render: (text, record, index) => (
                    //         <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                    //             <span className="">
                    //                 <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                    //                 <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                    //                 <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                    //             </span>
                    //         </div>
                    //     ),
                    // },
                ]
            }
        })
    }
    data = () => {
        try {


            let { Reports } = this.state;

            if (Reports.length <= 0) {
                return;
            }
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: Reports.current_page,
                first_page_url: Reports.first_page_url,
                from: Reports.from,
                last_page: Reports.last_page,
                last_page_url: Reports.last_page_url,
                links: Reports.links,
                next_page_url: Reports.next_page_url,
                path: Reports.path,
                per_page: Reports.per_page,
                prev_page_url: Reports.prev_page_url,
                to: Reports.to,
                total: Reports.total,
            };
            listfil["title"] = [];


            Reports.data.map((item, index) => {
                listfil["title"].push({
                    text: item.title,
                    value: item.title,
                });

                item.key = item.id
                item.stt = index + 1
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state => {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) => {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }


    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }

    OnPagination = (page, pageSize) => {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.ReportRequest({
            limit: pageSize,
            page: page, column: this.state.columnSort, sort: this.state.meta.sort
        }, { ...filteredvalues, key: ReportsConstant.REVENUE_BY_CUSTOMER });

    }
    onSearch = (values) => {
        console.log("values", values);
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.ReportRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.columnSort, sort: this.state.meta.sort
        }, { ...filteredvalues, key: ReportsConstant.REVENUE_BY_CUSTOMER });
        console.log(filteredvalues);
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();
        this.props.history.push(`${LINK.WEB_REPORT_REVENUE_CUSTOMER}?${searchParams}`);
        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () => {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state => {
            return {
                isNewList: true,
                meta: { ...META }
            }
        })
    }
    render() {


        return (

            <div className="main_content post_" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Báo cáo doanh thu theo khách hàng</span>
                        </div>
                        {/* <Link to={LINK.WEB_POST_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link> */}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>

                                    <div className="row">
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="code" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tên" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="phone" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="phone" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="email" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="email" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="daybirthday" noStyle style={{ margin: 0 }}>
                                                        <Select
                                                           
                                                            style={{ width: "100%" }}
                                                            maxTagCount='responsive'
                                                            placeholder="Ngày sinh"
                                                            options={(this.state.arrDaybirthday || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                        />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="monthbirthday" noStyle style={{ margin: 0 }}>
                                                        <Select
                                                           
                                                            style={{ width: "100%" }}
                                                            maxTagCount='responsive'
                                                            placeholder="Tháng sinh"
                                                            options={(this.state.arrMonthbirthday || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                        />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="yearbirthday" noStyle style={{ margin: 0 }}>
                                                        <Select
                                                           
                                                            style={{ width: "100%" }}
                                                            maxTagCount='responsive'
                                                            placeholder="Năm sinh"
                                                            options={(this.state.arrYearbirthday || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                        />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                <RangePicker />
                                            </Form.Item>
                                        </div>


                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </div>
                                    </div>
                                </Form>
                            </div>

                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            setIsNewList={() => {
                                this.setState(state => {
                                    return {
                                        isNewList: !state.isNewList
                                    }
                                })
                            }}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <> <Table
                            rowClassName={(record, index) => {
                                return "__row_";
                            }}

                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}

                            style={{ paddingBottom: "30px" }}
                        />
                            <div className="pagination_">
                                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                            </div>

                        </>
                        }
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {

        ReportRequest: (body, data) => {
            dispatch(ActionsWeb.ReportRequest(body, data));
        },



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);