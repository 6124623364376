import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";
import * as ReportConstant from "../../../../constants/Reports";


import { Modal,  DatePicker, Card} from 'antd';

import
{
    AppstoreOutlined, UnorderedListOutlined,
   
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import IndexMobile from "./IndexMobile";

const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.state = {

            ListPosts: [],
            meta: {
                ...META
            },
            data: [
                {title:"Báo cáo hàng bán chạy",color:"#008DDA",key:ReportConstant.BESTSELLING},
                {title:"doanh thu theo sản phẩm ",color:"#008DDA",key:ReportConstant.REVENUE_EACH_PRODUCT},
                {title:"doanh thu theo khách hàng ",color:"#008DDA",key:ReportConstant.REVENUE_BY_CUSTOMER},
                {title:"Báo cáo tóm tắt doanh số ",color:"#008DDA",key:""},
                {title:"Báo cáo kho",color:"#008DDA",key:""},
                {title:"Báo cáo nhập xuất tồn kho",color:"#008DDA",key:""},
            ],
          
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "Chức năng sẽ sớm được cập nhật",
            StorePosts: {},
            search: {},
            isLoadingSearch: false,
            isloading:false,
            isShowList: false,
            isNewList: false,
        }

    }
    setVisible = (type) =>
        {
            this.setState(state =>
            {
                return {
                    visible: type
                }
            })
        }
    componentDidMount = () =>
    {
       

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {

       


    }

    componentDidUpdate(prevProps, prevState)
    {

     
    }
 
    render()
    {


        return (

            <div className="main_content report_" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={()=>{
                        this.setVisible(false);
                    }}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={()=>{
                        this.setVisible(false);
                    }}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%",}}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Báo cáo</span>
                        </div>
                       
                    </div>} bordered={false}
                        style={{ width: "100%",height:"100vh"  }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                  
                        <IndexMobile lists={this.state.data} loading={this.state.loading} showNote={()=>{
                        this.setVisible(true);
                    }}/>
                        
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListPostsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListPostsRequest(body));
        },
        StorePostsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StorePostsRequest(data, id));
        },
        ResetStorePosts: () =>
        {
            dispatch(ActionsWeb.ResetStorePosts());
        },
        SearchPostsRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchPostsRequest(body, data));
        },
        duplicatePostRequest: (body, data) =>
        {
            dispatch(ActionsWeb.duplicatePostRequest(body, data));
        },
        DeletePostsRequest: (body, id) =>
        {
            dispatch(ActionsWeb.DeletePostsRequest(body, id));
        },
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);