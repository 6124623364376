export const white = '#FFFFFF';
export const colormain = '#ffbcb7';
export const colorblue = '#C1EFFF';
export const coloryellow = '#FFE9AE';
export const coloryellows = '#FFDBA4';
export const colortext = '#ff8181';
export const colorgrey = '#fafafa';
export const colorgreyLight = '#fafafa';
export const colorWarning = '#ffb700';
export const colorSuccess = '#31c300';
export const colorError = '#d30000';
export const colorInfo = '#108ee9';
export const color1 = '#04879C';
export const color2 = '#0C3C78';

export const color3 = '#090030';
export const color4 = '#ba97a1';
export const color5 = '#50C4ED';

export const colorDelete = 'red';

export const colorEdit = '#0C3C78';
export const colorView = '#ff8181';
export const colorAdd = '#59CE8F';
export const colorpink = '#F30A49';
export const colorGreen = '#00df68';
export const colorPurple = '#85586F';

export const arrColors = ['#50C4ED', '#F30A49', '#090030', '#0C3C78', '#D61355', '#FC7300', '#EA8FEA', '#FF1E00', '#59CE8F', '#50C4ED', '#ffbcb7', '#BFDB38', '#FFE9AE', '#FFDBA4', '#ff8181', '#ff8181', '#04879C'];

export const pastel1 = [
    '#B2A4FF',
    '#FFB4B4',
    '#FFDEB4',
    '#FDF7C3',
]

export const pastel2 = [
    '#CCD5AE',
    '#E9EDC9',
    '#FEFAE0',
    '#FAEDCD',
]

export const pastel3 = [

    '#BBD6B8',
    '#AEC2B6',
    '#94AF9F',
    '#DBE4C6',
]

export const pastel4 = [
    '#F8EDE3',
    '#DFD3C3',
    '#D0B8A8',
    '#85586F',
]

export const pastel5 = [
    '#F7ECDE',
    '#E9DAC1',
    '#9ED2C6',
    '#54BAB9',
]


export const pop1 = [
   
    '#F9F5EB',
    '#E4DCCF',
    '#EA5455',
    '#002B5B',
    '#FFFFFF',
]

export const pop2 = [

    '#F7F3E9',
    '#A3D2CA',
    '#5EAAA8',
    '#F05945',
    '#FFFFFF',
    "#051e3c"
]
export const pop3 = [

    '#F7F3E9',
    '#A3D2CA',
    '#54BAB9',
    '#ff8181',
    '#FFFFFF',
]





