import React, { useEffect, useRef, useState } from 'react';

import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import {
    PlusOutlined,
    MinusOutlined, FileImageOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../../../utils/constant";
import * as ActionsWeb from "../../../../actions/indexWeb";
import Upload from "./Upload";
import * as Colors from "../../../../constants/Colors";
import * as LINK from "../../../../constants/Links";
const { TextArea } = Input;
const Index = (props) => {
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [imgesInfo, setimgesInfo] = useState({});
    const [imgesInfoRemove, setimgesInfoRemove] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [isupload, setisupload] = useState(0);
    const [values, setValues] = useState(null);
    const [record, setRecord] = useState({ deleted: 0, type: 2, active: 1 });
    const [required, setrequired] = useState({
        avata: false

    });
    const [formData, setFormData] = useState(null);

    const [typeResUpload, setypeResUpload] = useState(null);
    const [UploadFileLa, setUploadFileLa] = useState("");
    const [phones, setPhones] = useState(["123456789"]);
    const [emails, setEmails] = useState(["exmaple@gmail.com"]);
    const [address, setAddress] = useState(["123 abc"]);
    const formRef = useRef(null)
    const onFinishForm = (values) => {
        record.imgesInfoRemove = imgesInfoRemove.filter(item => {
            item.folder = '';
            return !item.originFileObj
        });

        if (imgesInfo.file) {
            for (let x in imgesInfo.file) {
                // const element = imgesInfo.file[x];

                values[x] = record[x] ? record[x] : values[x]
            }
        }
        if (imgesInfo.files) {
            for (let x in imgesInfo.files) {
                // const element = imgesInfo.file[x];
                values[x] = record[x] ? record[x] : values[x]
            }
        }
        if (imgesInfo.video) {
            for (let x in imgesInfo.video) {
                // const element = imgesInfo.file[x];
                values[x] = record[x] ? record[x] : values[x]
            }
        }
        const phone_ = [];
        const email_ = [];
        const address_ = []
        for (let x in values) {
            if (x.includes("phone")) {
                phone_.push(values[x])
                delete values[x];
            }
            if (x.includes("email")) {
                email_.push(values[x])
                delete values[x];
            }
            if (x.includes("address")) {
                address_.push(values[x])
                delete values[x];
            }
        }
        values.phone = phone_;
        values.email = email_;
        values.address = address_;

        const record_ = { ...record, code: "WEB", title: "Cài đặt website", type: 2, active: 1, deleted: 0, data: JSON.stringify(values) }



        // return;
        setIsloading(true)
        dispatch(ActionsWeb.StoreSettingsRequest(record_, record_.id ? record_.id : ""));

    }
    useEffect(() => {


        dispatch(ActionsWeb.SettingsShowByCodeRequest({ code: "WEB" }))
        return () => {

        };
    }, []);
    useEffect(() => {
        if (Web.StoreSettings?.code) {

            try {
                if (Web.StoreSettings.code === 200) {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {
                        message.success(Web.StoreSettings.message)
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {

                        message.error(Web.StoreSettings.message)
                    } else {
                        for (let x in Web.StoreSettings.message) {
                            let mess = Web.StoreSettings.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")

            }
            setIsloading(false)
            dispatch(ActionsWeb.ResetStoreSettings());
        }


        return () => {

        };
    }, [Web.StoreSettings]);


    useEffect(() => {
        if (phones && phones.length > 0) {
            for (let i = 0; i < phones.length; i++) {
                const element = phones[i];
                console.log(element);
                formRef.current.setFieldsValue({ ["phone" + (i + 1)]: element })
            }
        }
        if (emails && emails.length > 0) {
            for (let i = 0; i < emails.length; i++) {
                const element = emails[i];
                console.log(element);
                formRef.current.setFieldsValue({ ["email" + (i + 1)]: element })
            }
        }
        if (address && address.length > 0) {
            for (let i = 0; i < address.length; i++) {
                const element = address[i];
                console.log(element);
                formRef.current.setFieldsValue({ ["address" + (i + 1)]: element })
            }
        }
        // setrender(!render)
        console.log(phones, emails, address);
        return () => {

        };
    }, [phones, emails, address]);
    useEffect(() => {

        if (Web.SettingsShowByCode.data) {
            if (Web.SettingsShowByCode.data.code === "WEB") {
                setRecord(Web.SettingsShowByCode.data)

                if (Web.SettingsShowByCode.data.data) {


                    const formdata = JSON.parse(Web.SettingsShowByCode.data.data)
                    // console.log("formdataformdata",formdata);
                    setFormData(formdata);
                    setPhones(formdata.phone)
                    setEmails(formdata.email)
                    setAddress(formdata.address)
                    formRef.current.setFieldsValue(formdata)
                    const imgesInfo_ = {
                        file: {
                            icon: [{
                                uid: -1,
                                name: formdata.icon?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.icon?.[0],
                            }],
                            logo: [{
                                uid: -2,
                                name: formdata.logo?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.logo?.[0],
                            }],
                            icon_circel: [{
                                uid: -3,
                                name: formdata.icon_circel?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.icon_circel?.[0],
                            }],
                            thankyou: [{
                                uid: -4,
                                name: formdata.thankyou?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.thankyou?.[0],
                            }],
                            thankyouContact: [{
                                uid: -4,
                                name: formdata.thankyouContact?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.thankyouContact?.[0],
                            }],
                            modal_home: [{
                                uid: -4,
                                name: formdata.modal_home?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.modal_home?.[0],
                            }],
                            logoMoIt: [{
                                uid: -4,
                                name: formdata.logoMoIt?.[0],
                                status: 'done',
                                url: LINK.myHostimgServerImg + "/" + formdata.logoMoIt?.[0],
                            }]
                        }
                    }
                    setimgesInfo(imgesInfo_)
                }
            }

        }
        return () => {

        };
    }, [Web.SettingsShowByCode]);
    useEffect(() => {
        if (isupload === typeResUpload) {
            onFinishForm(values);
        }
        return () => {

        };
    }, [typeResUpload]);
    useEffect(() => {

        if (Web.UploadFileLa) {

            let type = Web.UploadFileLa.type;

            if (Web.UploadFileLa.code === 200) {
                setRecord({ ...record, [type]: Web.UploadFileLa.data })
                setypeResUpload(type)

            } else {
                message.warn("Upload file lỗi!")
            }


            dispatch(ActionsWeb.ResetUploadFileLa);

        }
        return () => {

        };
    }, [Web.UploadFileLa]);
    const setimgesInfo_ = (typef, type, data) => {


        if (typef === "file" || typef === "video") {
            const imageDelete = setimgesInfo?.[typef]?.[type];
            if (imageDelete?.[0]) {
                imageDelete[0]["folder"] = type;

            }
            setimgesInfoRemove(imageDelete ? [...imgesInfoRemove, ...imageDelete] : [...imgesInfoRemove])

            setimgesInfo({
                ...imgesInfo, [typef]: { ...imgesInfo[typef], [type]: data }
            })


        } else {
            setimgesInfo({
                ...imgesInfo, [typef]: { ...imgesInfo[typef], [type]: data }
            })

        }

    }

    const setimgesInfoRemove_ = (typef, type, data) => {

        data["folder"] = type;
        let images = "";
        if (typef === "files") {
            images = setimgesInfo?.[typef]?.[type]?.filter(item => {
                return item !== data.name;
            })
            setimgesInfoRemove([...imgesInfoRemove, data])

            setimgesInfo({
                ...imgesInfo, [typef]: { ...imgesInfo[typef], [type]: images }
            })

        } else {
            setimgesInfoRemove([...imgesInfoRemove, data])


        }


    }

    const uploadFile = (values) => {


        setValues(values)
        setrequired({})



        let isupload = 0;

        if (imgesInfo.file) {
            for (let x in imgesInfo.file) {
                const element = imgesInfo.file[x];
                let arravata = element.filter(item => {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    dispatch(ActionsWeb.UploadFileLaRequest(arravata, "settings", x));
                    isupload = x
                }
            }


        }
        if (imgesInfo.files) {
            for (let x in imgesInfo.files) {
                const element = imgesInfo.files[x];
                let arravata = element.filter(item => {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    dispatch(ActionsWeb.UploadFileLaRequest(arravata, "settings", x));

                    isupload = x
                }
            }

        }
        if (imgesInfo.video) {
            for (let x in imgesInfo.video) {
                const element = imgesInfo.video[x];
                let arravata = element.filter(item => {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    dispatch(ActionsWeb.UploadFileLaRequest(arravata, "video", x));
                    isupload = x
                }
            }

        }
        setisupload(isupload);

        if (isupload === 0) {
            onFinishForm(values);
        }
    }

    const onSwitch = (e, name) => {
        setFormData({ ...formData, [name]: e })

    }
    return (
        <div className='dropdown_rim'>
            <Form name="form" className="form" ref={formRef} onFinish={uploadFile}
                layout={"horizontal"}
                style={{
                    padding: "0px !important",
                    width: "100%",
                    display: "flex",
                    margin: 0
                }}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form.Item name="title" label="Tiêu đề web" layout={"horizontal"}>
                            <Input placeholder='Tiêu đề phần mềm' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="description" label="Mô tả" layout={"horizontal"}>
                            <TextArea placeholder='Mô tả' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="webName" label="Tên web" layout={"horizontal"}>
                            <Input placeholder='Tên website' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="company_name" label="Tên công ty" layout={"horizontal"}>
                            <Input placeholder='Tên công ty' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="company_code" label="Số GPKD" layout={"horizontal"}>
                            <Input placeholder='Số GPKD' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="domain" label="Link website" layout={"horizontal"}>
                            <Input placeholder='Link website' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>

                        {phones.length > 0 && phones.map((item, index) => {
                            return <Form.Item key={index} name={"phone" + (index + 1)} label={"Điện thoại " + (index + 1)} layout={"horizontal"} initialValue={item}>
                                <Input placeholder={'Điện thoại ' + (index + 1)} addonAfter={<><PlusOutlined onClick={() => { setPhones([...phones, ""]) }} style={{ marginRight: "10px" }} /><MinusOutlined onClick={() => {
                                    if (phones.length > 1) {
                                        setPhones(phones.filter(el => {
                                            return el !== item
                                        }))
                                    }

                                }
                                } /></>} />
                            </Form.Item>
                        })}

                    </div>

                    <div className='col-lg-12'>
                        {emails.length > 0 && emails.map((item, index) => {
                            return <Form.Item key={index} name={"email" + (index + 1)} label={"Email " + (index + 1)} layout={"horizontal"} initialValue={item}>
                                <Input placeholder={'Email ' + (index + 1)} addonAfter={<><PlusOutlined onClick={() => { setEmails([...emails, ""]) }} style={{ marginRight: "10px" }} /><MinusOutlined onClick={() => {
                                    if (emails.length > 1) {
                                        setEmails(emails.filter(el => {
                                            return el !== item
                                        }))
                                    }

                                }
                                } /></>} />
                            </Form.Item>
                        })}


                    </div>
                    <div className='col-lg-12'>
                        {address.length > 0 && address.map((item, index) => {
                            return <Form.Item key={index} name={"address" + (index + 1)} label={"Địa chỉ " + (index + 1)} layout={"horizontal"} initialValue={item}>
                                <Input placeholder={'Địa chỉ ' + (index + 1)} addonAfter={<><PlusOutlined onClick={() => { setAddress([...address, ""]) }} style={{ marginRight: "10px" }} /><MinusOutlined onClick={() => {
                                    if (address.length > 1) {
                                        setAddress(address.filter(el => {
                                            return el !== item
                                        }))
                                    }

                                }
                                } /></>} />
                            </Form.Item>
                        })}


                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="copyright" label="copyright" layout={"horizontal"}>
                            <Input placeholder='copyright' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="openhour" label="Giờ mở cửa" layout={"horizontal"}>
                            <Input placeholder='Giờ mở cửa' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="note_transport" label="Ghi chú vận chuyển" layout={"horizontal"}>
                            <Input placeholder='Ghi chú vận chuyển' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="map" label="Bản đồ" layout={"horizontal"}>
                            <TextArea placeholder='Bản đồ' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="icon" label="Icon" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"icon"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["icon"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Icon" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>

                    <div className='col-lg-12'>
                        <Form.Item name="logo" label="Logo" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"logo"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["logo"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Logo" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="icon_circel" label="Icon tròn" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"icon_circel"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["icon_circel"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Icon tròn" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="thankyou" label="Thank you" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"thankyou"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["thankyou"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Thank you" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="thankyouContact" label="Thank you Contact" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"thankyouContact"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["thankyouContact"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Thank you Contact" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="logoMoIt" label="Logo bộ công thương" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"logoMoIt"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["logoMoIt"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Thank you Contact" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="linkMoit" label="Link bộ công thương" layout={"horizontal"}>
                            <Input placeholder='Link bộ công thương' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <Form.Item name="modal_home" label="modal trang chủ" layout={"horizontal"}>
                                    <Upload isCropImg={false} multiple={false} max={1} type_={"modal_home"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["modal_home"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="modal trang chủ" icon={<FileImageOutlined />} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Item name="active_modal_home" label="kích hoạt" layout={"horizontal"}>
                                    <SwitchA checked={formData?.["active_modal_home"]} onChange={(e) => { onSwitch(e, "active_modal_home") }} />
                                </Form.Item>

                                <Form.Item name="display_period_modal_home" label="Khoảng thời gian" layout={"horizontal"}>
                                    <Input addonAfter={"Phút"} placeholder='Khoảng cách thời gian hiển thị modal' />
                                </Form.Item>

                            </div>
                            <div className='col-lg-3'>


                                <Form.Item name="active_modal_home_button" label="Nút hành động" layout={"horizontal"}>
                                    <SwitchA checked={formData?.["active_modal_home_button"]} onChange={(e) => { onSwitch(e, "active_modal_home_button") }} />
                                </Form.Item>


                                <Form.Item name="active_modal_home_button_position" label="Vị trí nút" layout={"horizontal"}>
                                        <Select 
                                           options={[
                                            {value:1,label:"Trên"},
                                            {value:2,label:"Phải"},
                                            {value:3,label:"Dưới"},
                                            {value:4,label:"Trái"},
                                            {value:5,label:"Giữa"},
                                           ]}
                                        />
                                </Form.Item>

                                <Form.Item name="active_modal_home_button_link" label="Đường dẫn" layout={"horizontal"}>
                                    <Input placeholder='Đường dẫn nút' />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default Index;
