import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";
import { replaceThous } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message, Card

} from 'antd';
import
{
    UnorderedListOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
const { TextArea } = Input;




class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            record: {

                active: false,
                deleted: 0
            },
            StoreInfoTransport: {},
            ListInfoTransport: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.StoreInfoTransport && nextProps.Web.StoreInfoTransport !== prevState.StoreInfoTransport) {

            return {
                StoreInfoTransport: nextProps.Web.StoreInfoTransport,

            };

        }
        if (nextProps.Web.ListInfoTransport && nextProps.Web.ListInfoTransport.id !== prevState.ListInfoTransport.id) {

            return {

                record: nextProps.Web.ListInfoTransport,

            };

        }



        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreInfoTransport !== prevState.StoreInfoTransport) {
            let { StoreInfoTransport } = this.state;

            try {
                if (StoreInfoTransport.code === 200) {
                    if (StoreInfoTransport.message && typeof StoreInfoTransport.message === "string") {
                        message.success(StoreInfoTransport.message)
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreInfoTransport.message && typeof StoreInfoTransport.message === "string") {

                        message.error(StoreInfoTransport.message)
                    } else {
                        for (let x in StoreInfoTransport.message) {
                            let mess = StoreInfoTransport.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.props.ResetStoreInfoTransport()
            this.SetisLoading(false)
        }

        if (this.state.record.id !== prevState.record.id) {
            let { record } = this.state
            this.formRef.current.setFieldsValue({ ...record });

        }
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.InfoTransportByIdRequest({ id });
        }



    }
    data = () =>
    {
        let { ListInfoTransport } = this.state;
        // if (!ListInfoTransport) {
        //     ListInfoTransport = this.props.Web.ListInfoTransport
        // }

        if (ListInfoTransport.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListInfoTransport.current_page,
            first_page_url: ListInfoTransport.first_page_url,
            from: ListInfoTransport.from,
            last_page: ListInfoTransport.last_page,
            last_page_url: ListInfoTransport.last_page_url,
            links: ListInfoTransport.links,
            next_page_url: ListInfoTransport.next_page_url,
            path: ListInfoTransport.path,
            per_page: ListInfoTransport.per_page,
            prev_page_url: ListInfoTransport.prev_page_url,
            to: ListInfoTransport.to,
            total: ListInfoTransport.total,
        };
        ListInfoTransport.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            }, () =>
            {
                console.log('====================================');
                console.log(this.state.record);
                console.log('====================================');
            })
        }

    }


    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values, deleted: 0 }
        console.log('====================================');
        console.log(record);
        console.log('====================================');
        this.SetisLoading(true)
        this.props.StoreInfoTransportRequest(record_, record_.id ? record_.id : "")

    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UnorderedListOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin vận chuyển</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                       
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "35px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                    >

                        <div className="section_ section_img">

                            <Form.Item label="Tên đơn vị vận chuyển" name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên đơn vị vận chuyển là bắt buộc"
                                    },
                                ]}
                            >
                                <Input placeholder="Tên đơn vị vận chuyển" />
                            </Form.Item>
                            <Form.Item label="Code" name="code" rules={[
                                {
                                    required: true,
                                    message: "code là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="code" />
                            </Form.Item>
                            <Form.Item label="Giá" name="price" rules={[
                                {
                                    required: true,
                                    message: "Giá là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Giá" />
                            </Form.Item>
                            <Form.Item label="Mô tả" name="description" >
                                <TextArea />
                            </Form.Item>
                            <Form.Item label="Active">
                                <SwitchA name="active" id="active" checked={this.state.record.active} onChange={(e) => { this.onChangetext(e, "active", "record") }} />
                            </Form.Item>

                        </div>

                        <Form.Item >
                            <div className="flex_">
                                <Button type="default" htmlType="button" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                <Button type="primary" htmlType="button" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                                <Button type="primary" htmlType="submmit" loading={this.state.isloading} style={{ marginRight: "10px" }}>Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card>
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreInfoTransportRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInfoTransportRequest(data, id));
        },
        ResetStoreInfoTransport: () =>
        {
            dispatch(ActionsWeb.ResetStoreInfoTransport());
        },
        ListInfoTransportRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoTransportRequest(body));
        },
        InfoTransportByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.InfoTransportByIdRequest(body));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);