import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";

import {  createSlug } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card

} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";


class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef=new createRef();

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                slug: "",
                hidden: false,
                deleted: 0
            },
            StoreTags: {},
            ListTags: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListTags && nextProps.Web.ListTags.id && nextProps.Web.ListTags !== prevState.record) {
          
            return {
                record: nextProps.Web.ListTags,

            };

        }

        if (nextProps.Web.StoreTags && nextProps.Web.StoreTags !== prevState.StoreTags) {
            return {
                StoreTags: nextProps.Web.StoreTags,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.record !== prevState.record) {
            console.log('====================================');
            console.log(this.state.record);
            console.log('====================================');
            this.formRef.current.setFieldsValue({ ...this.state.record })
        }   

        if (this.state.StoreTags !== prevState.StoreTags) {
            let { StoreTags } = this.state;
            try {
                if (StoreTags.code === 200) {
                    if (StoreTags.message && typeof StoreTags.message === "string") {
                        message.success(StoreTags.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreTags.message && typeof StoreTags.message === "string") {

                        message.error(StoreTags.message)
                    } else {
                        for (let x in StoreTags.message) {
                            let mess = StoreTags.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)

            this.props.ResetStoreTags()

        }
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    isUpdate: true
                }
            })
            this.props.ListTagsByIdRequest({ id });
        }


    }


    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreTagsRequest(record_, record_.id ? record_.id : "")

    }
    render()
    {



        return (

            <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết tags</span>
                    </div>} bordered={false}
                        style={{ width: "100%" }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >

                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên tags là bắt buộc"
                                },
                            ]}>
                                <Input name="name" id="name" placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                {
                                    this.formRef.current.setFieldsValue({ slug: createSlug(e.target.value) })
                                }} />
                            </Form.Item>
                            <Form.Item label="Slug" name="slug" rules={[
                                {
                                    required: true,
                                    message: "slug là bắt buộc"
                                },
                            ]}>
                                <Input name="slug" id="slug" placeholder="slug" showCount maxLength={120} />
                            </Form.Item>
                            <Form.Item>
                                <div className="flex_">
                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                    <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreTagsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreTagsRequest(data, id));
        },
        ResetStoreTags: () =>
        {
            dispatch(ActionsWeb.ResetStoreTags());
        },
        ListTagsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListTagsRequest(body));
        },
        ListTagsByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.ListTagsByIdRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);