import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Image, Card } from 'antd';

import
{
    FolderViewOutlined,
    DeleteOutlined, AppstoreOutlined, UnorderedListOutlined, UserSwitchOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { checkScreenSize, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListInventoryCheck: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'supplier_name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Vùng',
                    dataIndex: 'regions',
                    sorter: (a, b) => a.code - b.code,
                    render: (text, record, index) =>
                    {
                        if (record.regions) {
                            return record.regions.name
                        }
                    }
                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreInventoryCheck: {},
            search: {},
            isLoadingSearch: false,
            isloading: false,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreInventoryCheckRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)


        this.props.SearchInventoryCheckRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListInventoryCheck && nextProps.Web.ListInventoryCheck !== prevState.ListInventoryCheck) {
            console.log("Comment", nextProps.Web.ListInventoryCheck);


            return {
                ListInventoryCheck: nextProps.Web.ListInventoryCheck,
                isLoadingSearch: false
            };

        }
        else if (nextProps.Web.StoreInventoryCheck && nextProps.Web.StoreInventoryCheck !== prevState.StoreInventoryCheck) {
            return {
                StoreInventoryCheck: nextProps.Web.StoreInventoryCheck,

            };

        }
        else {
            return null;
        }

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreInventoryCheck !== prevState.StoreInventoryCheck) {
            let { StoreInventoryCheck } = this.state;

            try {
                if (StoreInventoryCheck.code === 200) {
                    if (StoreInventoryCheck.message && typeof StoreInventoryCheck.message === "string") {
                        message.success(StoreInventoryCheck.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    this.formSearchRef.current.setFieldsValue(filteredvalues)


                    this.props.SearchInventoryCheckRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreInventoryCheck.message && typeof StoreInventoryCheck.message === "string") {

                        message.error(StoreInventoryCheck.message)
                    } else {
                        for (let x in StoreInventoryCheck.message) {
                            let mess = StoreInventoryCheck.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreInventoryCheck()
            this.handleCancel();


        }
        if (this.state.ListInventoryCheck !== prevState.ListInventoryCheck) {
            let { ListInventoryCheck } = this.state

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        width: "5%",
                        align: 'center',

                    },

                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Hình ảnh',
                        dataIndex: 'images',
                        render: (text, record, index) =>
                        {
                            return <Image.PreviewGroup>
                                {(text || []).map((item, index) =>
                                {
                                    return <Image key={index}
                                        width={40}
                                        src={LINK.myHostimgServerImg + "/" + item} />
                                })}
                            </Image.PreviewGroup>

                        }
                    },
                    {
                        title: 'Video',
                        dataIndex: 'video',

                        sorter: (a, b) => a.bank_name - b.bank_name,

                    },

                    {
                        title: 'Số lượng',
                        dataIndex: 'amount',

                        sorter: (a, b) => a.bank_name - b.bank_name,

                    },
                    {
                        title: 'Size',
                        dataIndex: 'size',

                        sorter: (a, b) => a.bank_name - b.bank_name,

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },
                    {
                        title: 'Ngày cập nhật',
                        dataIndex: 'updated_at',
                        sorter: (a, b) => a.updated_at - b.updated_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }
                    },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "10%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        try {


            let { ListInventoryCheck } = this.state;

            if (ListInventoryCheck.length <= 0) {
                return;
            }
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListInventoryCheck.current_page,
                first_page_url: ListInventoryCheck.first_page_url,
                from: ListInventoryCheck.from,
                last_page: ListInventoryCheck.last_page,
                last_page_url: ListInventoryCheck.last_page_url,
                links: ListInventoryCheck.links,
                next_page_url: ListInventoryCheck.next_page_url,
                path: ListInventoryCheck.path,
                per_page: ListInventoryCheck.per_page,
                prev_page_url: ListInventoryCheck.prev_page_url,
                to: ListInventoryCheck.to,
                total: ListInventoryCheck.total,
            };
            listfil["name"] = [];
            listfil["bank"] = [];
            listfil["bank_name"] = [];
            listfil["phone"] = [];
            ListInventoryCheck.data.map((item, index) =>
            {
                listfil["name"].push({
                    text: item.supplier_name,
                    value: item.supplier_name,
                });
                listfil["bank"].push({
                    text: item.supplier_bank,
                    value: item.supplier_bank,
                });
                listfil["bank_name"].push({
                    text: item.supplier_bank_name,
                    value: item.supplier_bank_name,
                });;
                listfil["phone"].push({
                    text: item.supplier_phone,
                    value: item.supplier_phone,
                });

                item.key = index + 1
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_INVENTORY_CHECK_EDIT + "/" + record.id,
            state: record
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)


        this.props.SearchInventoryCheckRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
    }
    onSearch = (values) =>
    {
        const { search } = this.state;
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchInventoryCheckRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_INVENTORY_CHECK}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (

            <div className="main_content">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Phiếu kiểm kho</span>
                        </div>
                        <Link to={LINK.WEB_INVENTORY_CHECK_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >


                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    
                                            <div className="row">

                                                <div className="col_ col-lg-4 col-md-4 col-sm-6">
                                                    <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="code" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-4 col-md-4 col-sm-6">
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-4 col-md-4 col-sm-6">
                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                       
                                 
                                </Form>
                            </div>
                           

                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <> <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                           
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>
                        </>
    }
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListInventoryCheckRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInventoryCheckRequest(body));
        },
        StoreInventoryCheckRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInventoryCheckRequest(data, id));
        },
        ResetStoreInventoryCheck: () =>
        {
            dispatch(ActionsWeb.ResetStoreInventoryCheck());
        },
        SearchInventoryCheckRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchInventoryCheckRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);