import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";
// import { replaceThous } from "./../../../../service";

import { createSlug, getParamsUrl, replaceThous } from "./../../../../service";
import {
Form,
Input,
Button,
Select,
Switch as SwitchA,
message,
Card

} from 'antd';
import {
UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";

const { TextArea } = Input;
class Index extends Component {

    constructor(props) {
        super(props);
        this.formRef = new createRef();

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                slug: "",
                hidden: false,
                deleted: 0,

            },
            is_default: false,
            active: false,
            StoreStore: {},
            ListStore: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.Web.ListStore && nextProps.Web.ListStore.id && nextProps.Web.ListStore.id !== prevState.record.id) {
      
            return {
                record: nextProps.Web.ListStore,

            };

        }

        if (nextProps.Web.StoreStore && nextProps.Web.StoreStore !== prevState.StoreStore) {
            return {
                StoreStore: nextProps.Web.StoreStore,

            };

        }
        if (nextProps.Web.ListRegion && nextProps.Web.ListRegion.data !== prevState.ListRegion) {

            return {

                ListRegion: nextProps.Web.ListRegion.data,

            };

        }

        if (nextProps.Web.ListProvinces && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {



            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };

        }
        if (nextProps.Web.ListDistricts && nextProps.Web.ListDistricts.data !== prevState.ListDistricts) {



            return {
                ListDistricts: nextProps.Web.ListDistricts.data,

            };

        }
        if (nextProps.Web.ListWars && nextProps.Web.ListWars.data !== prevState.ListWars) {



            return {
                ListWars: nextProps.Web.ListWars.data,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.record?.id !== prevState.record?.id) {

            this.formRef.current.setFieldsValue({ ...this.state.record })
            // console.log("this.state.recordthis.state.record",this.state.record.is_default);
            this.setState(state => {
                return {
                    is_default: this.state.record?.is_default ? true : false,
                    active: this.state.record?.active ? true : false,
                }
            })
            if(this.state.record?.province_id){
                this.props.ListDistrictsRequest({
                    limit: this.state.meta.per_page,
                    page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: this.state.record?.province_id
                });


                if(this.state.record?.district_id){
                    this.props.ListWarsRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
                        provinceid: this.state.record?.province_id, districtid: this.state.record?.district_id
                    });
                }
            }
           
        }

        if (this.state.StoreStore !== prevState.StoreStore) {
            let { StoreStore } = this.state;
            try {
                if (StoreStore.code === 200) {
                    if (StoreStore.message && typeof StoreStore.message === "string") {
                        message.success(StoreStore.message)
                        this.setState(state => {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreStore.message && typeof StoreStore.message === "string") {

                        message.error(StoreStore.message)
                    } else {
                        for (let x in StoreStore.message) {
                            let mess = StoreStore.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)

            this.props.ResetStoreStore()

        }
        // if (this.state.ListRegion !== prevState.ListRegion) {

        //     const urlParams = getParamsUrl();

        //     const filteredvalues = Object.fromEntries(
        //         Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        //     );
        //     if (filteredvalues.region_id) {
        //         filteredvalues.region_id = (1 * filteredvalues.region_id);
        //     }

        //     this.formSearchRef.current.setFieldsValue(filteredvalues)
        // }

        if (this.state.ListProvinces !== prevState.ListProvinces) {
            if (this.state.type === "edit") {
                this.setState(state => {
                    return {
                        Province_id: (1 * this.state.record.province),

                    }
                }, () => {
                    // this.formRef.current.setFieldsValue({ ...this.state.record })
                })
            }
            // if (this.state.record?.id) {

            //     this.formRef.current.setFieldsValue({ ...this.state.record })

            // }
        }
        if (this.state.ListDistricts !== prevState.ListDistricts) {
            const { match } = this.props;
            const { params } = match;
            const id = (1 * params.id); // Lấy giá trị của tham số từ URL
            if (id && typeof id === "number") {
                this.setState(state => {
                    return {
                        // Province_id: this.state.record.province,
                        District_id: (1 * this.state.record.district),
                        // War_id: this.state.record.war
                    }
                }, () => {
                    // this.formRef.current.setFieldsValue({ ...this.state.record })
                })
            }
            // if (this.state.record?.id) {

            //     this.formRef.current.setFieldsValue({ ...this.state.record })

            // }
        }
        if (this.state.ListWars !== prevState.ListWars) {
            const { match } = this.props;
            const { params } = match;
            const id = (1 * params.id); // Lấy giá trị của tham số từ URL
            if (id && typeof id === "number") {
                this.setState(state => {
                    return {
                        // Province_id: this.state.record.province,
                        // District_id: this.state.record.district,
                        War_id: (1 * this.state.record.war)
                    }
                }, () => {
                    // this.formRef.current.setFieldsValue({ ...this.state.record })
                })
            }
            // if (this.state.record?.id) {

            //     this.formRef.current.setFieldsValue({ ...this.state.record })

            // }
        }
    }
    componentDidMount = () => {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state => {
                return {
                    isUpdate: true
                }
            })
            this.props.ListStoreByIdRequest({ id });
        }

        this.props.ListRegionRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

    }
    onChangetext = (e, type, group, isNumber) => {
        let target = e.target;

        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;
            if (!group) {
                this.setState(state => {
                    return {
                        [name]: value
                    }
                })
            } else {
                this.setState(state => {
                    return {
                        [group]: { ...state[group], [name]: value }
                    }
                })
            }
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            if (!group) {
                this.setState(state => {
                    return {
                        [type]: e
                    }
                })
            } else {
                this.setState(state => {
                    return {
                        [group]: { ...state[group], [type]: e }
                    }
                }, () => {
                    console.log('====================================');
                    console.log(this.state.record);
                    console.log('====================================');
                })
            }

        }

    }


    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) => {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        console.log("record_record_", record_);
        this.SetisLoading(true)
        this.props.StoreStoreRequest(record_, record_.id ? record_.id : "")

    }
    handleChangeProvince = (e) => {
        let province = this.state.ListProvinces.filter(item => {
            return item.id === (1 * e);
        })
        this.setState(state => {
            return {
                Province_id: e,
                record: { ...state.record, province: province?.[0]?.name, province_id: province?.[0]?.id }
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });

        this.formRef.current.setFieldsValue({ district_id:"",war_id:"" })
    }

    handleChangeDistrict = (e) => {
        let district = this.state.ListDistricts.filter(item => {
            return item.id === (1 * e);
        })
        this.setState(state => {
            return {
                District_id: e,
                record: { ...state.record, district: district?.[0]?.name,district_id: district?.[0]?.id }
            }
        })
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id, districtid: e
        });
    }
    handleChangeWar = (e) => {
        let war = this.state.ListWars.filter(item => {
            return item.id === (1 * e);
        })
        this.setState(state => {
            return {
                War_id: e,
                record: { ...state.record, war: war?.[0]?.name,war_id: war?.[0]?.id }
            }
        })

    }
    render() {



        return (

            <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết cửa hàng</span>
                    </div>} bordered={false}
                        style={{ width: "100%" }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >
                            <Form.Item label="Vùng" name="region_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vùng là bắt buộc"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        // width: 200,
                                    }}
                                    name="region_id" id="region_id"
                                    placeholder="Vùng"


                                    options={(this.state.ListRegion || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                />


                            </Form.Item>
                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên cửa hàng là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Tên cửa hàng" showCount maxLength={120} onChange={(e) => {

                                }} />
                            </Form.Item>
                            <Form.Item label="Code" name="code">
                                <Input placeholder="code" showCount maxLength={120} readOnly />
                            </Form.Item>
                            <Form.Item label="Điện thoại" name="phone">
                                <Input placeholder="Điện thoại" />
                            </Form.Item>
                            <Form.Item label="Email" name="email">
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address">
                                <Input placeholder="Địa chỉ" />
                            </Form.Item>
                            <Form.Item label="Tỉnh/Thành" name="province_id" rules={[
                                {
                                    required: true,
                                    message: "Tỉnh/Thành là bắt buộc"
                                },
                            ]}>
                                <Select
                                    showSearch

                                    placeholder={"Tỉnh/Thành"}
                                    style={{ width: "100%", }}
                                    defaultActiveFirstOption={true}
                                    showArrow={false}


                                    onChange={this.handleChangeProvince}
                                    notFoundContent={null}
                                    filterOption={(input, option) => {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={(this.state.ListProvinces || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}

                                />
                            </Form.Item>
                            <Form.Item label="Quận/Huyện" name="district_id" rules={[
                                {
                                    required: true,
                                    message: "Quận/Huyện là bắt buộc"
                                },
                            ]}>
                                <Select
                                    showSearch

                                    placeholder={"Quận/Huyện"}
                                    style={{ width: "100%", }}
                                    defaultActiveFirstOption={true}
                                    showArrow={false}

                                    // onSearch={this.handleSearch}
                                    onChange={this.handleChangeDistrict}
                                    notFoundContent={null}
                                    filterOption={(input, option) => {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={(this.state.ListDistricts || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}

                                />
                            </Form.Item>
                            <Form.Item label="Phường/xã" name="war_id" rules={[
                                {
                                    required: true,
                                    message: "Phường xã là bắt buộc"
                                },
                            ]}>
                                <Select
                                    showSearch

                                    placeholder={"Phường/xã"}
                                    style={{ width: "100%", }}
                                    defaultActiveFirstOption={true}
                                    showArrow={false}

                                    // onSearch={this.handleSearch}
                                    onChange={this.handleChangeWar}
                                    notFoundContent={null}
                                    filterOption={(input, option) => {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={(this.state.ListWars || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}

                                />
                            </Form.Item>
                            <Form.Item label="Ghi chú" name="note" >
                                <TextArea style={{ height: "100px" }} placeholder="Ghi chú" />
                            </Form.Item>
                            <Form.Item label="Vĩ độ" name="lat">
                                <Input placeholder="lat" />
                            </Form.Item>
                            <Form.Item label="Kinh độ" name="lon">
                                <Input placeholder="lat" />
                            </Form.Item>
                            <Form.Item label="Nhúng google map" name="map">
                                <TextArea style={{ height: "100px" }} />
                                {/* <Input placeholder="Nhúng google map" /> */}
                            </Form.Item>
                            <Form.Item label="is_default" name="is_default">
                                <SwitchA name="is_default" checked={this.state.is_default} onChange={(e) => { this.onChangetext(e, "is_default", "") }} />
                            </Form.Item>
                            <Form.Item label="active" name="active" >
                                <SwitchA name="active" checked={this.state.active} onChange={(e) => { this.onChangetext(e, "active", "") }} />
                            </Form.Item>
                            <Form.Item>
                                <div className="flex_">
                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                    <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>
                            </Form.Item>



                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {
        ListWarsRequest: (body) => {
            dispatch(ActionsWeb.ListWarsRequest(body));
        },
        ListDistrictsRequest: (body) => {
            dispatch(ActionsWeb.ListDistrictsRequest(body));
        },
        ListProvincesRequest: (body) => {
            dispatch(ActionsWeb.ListProvincesRequest(body));
        },
        StoreStoreRequest: (data, id) => {
            dispatch(ActionsWeb.StoreStoreRequest(data, id));
        },
        ResetStoreStore: () => {
            dispatch(ActionsWeb.ResetStoreStore());
        },
        ListStoreRequest: (body) => {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },
        ListStoreByIdRequest: (body) => {
            dispatch(ActionsWeb.ListStoreByIdRequest(body));
        },
        ListRegionRequest: (body) => {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);