import React, { Component } from "react";


import "./style.css";

class Loading extends Component
{




  render()
  {

    let { height } = this.props;

    return (
      <div id="wrap_loading" style={height ? { height: height } : {}}>
        <div className="balls">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}



export default Loading;
