import React, { Component, createRef } from "react";


import 'draft-js/dist/Draft.css';
import { connect } from "react-redux";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import {  UploadFile } from "./../../../../service";
import * as ActionsWeb from "../../../../actions/indexWeb";

import * as Colors from "../../../../constants/Colors";
import {  Input, message, DatePicker,  Select,  Card, Collapse, Spin, Button } from 'antd';




import
{
    StarOutlined, LoadingOutlined, PlusCircleOutlined
} from '@ant-design/icons';

import BoxOrder from "./../box/index";


const antIcon = (size) =>
{
    return <LoadingOutlined
        style={{
            fontSize: size,
        }}
        spin
    />
};


class ProductsEdit extends Component
{

    constructor(props)
    {
        super(props);

        this.editor = createRef();
        this.state = {
            isresetForm:false
        }
       
    }
    setEditorState = (e) =>
    {
       
    }


   

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.match && nextProps.match.params && nextProps.match.params.id !== prevState.idrecord) {

            const id_=(1*nextProps.match.params.id)
          
            if (typeof id_ !== "number" || !id_) {
              
                return {
                    isresetForm:true
    
                };
            }
            


        }
        return null;

    }
    componentDidUpdate(prevProps, prevState)
    {

        try {

            if (this.state.isresetForm !== prevState.isresetForm) {

                if (this.state.isresetForm) {
                    this.setState(state =>
                        {
                            return {
                                isresetForm: false
                            }
                        });

                }
            }



        } catch (error) {
            console.error(error);
        }
    }

    componentDidMount = async () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    idrecord: id
                }
            })
        }

        

    }
    componentWillUnmount()
    {

    }
  
    render()
    {
     

        return (
            <>
              

                <div className="main_content order_" style={{ overflow: "unset", position: "relative" }} >
                    <div className="table_w" style={{ height: "100%", }} >

                        <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                                <StarOutlined style={{ color: Colors.pop2[3] }} />
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Đơn hàng</span>
                        </div>
                             <Button size="small" type="primary" onClick={()=>{
                                this.setState(state=>{
                                    return{
                                        isresetForm:true
                                    }
                                },()=>{
                                    setTimeout(() => {
                                        this.setState(state =>
                                        {
                                            return {
                                                isresetForm: false
                                            }
                                        });
                                    }, 1000);
                                })
                             }}><PlusCircleOutlined /></Button>
                    </div>} bordered={false}
                            style={{ width: "100%", }}
                            bodyStyle={{
                                padding: "10px",


                            }}
                            headStyle={{
                                backgroundColor: Colors.colorgrey,
                                display: "flex",
                                minHeight: "35px",
                                borderRadius: "3px"
                            }}

                        >
                          
                            <BoxOrder idrecord={this.state.idrecord} isresetForm={this.state.isresetForm} history={this.props.history} />


                       
                        </Card>


                    </div >
                </div>

            </>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsEdit);