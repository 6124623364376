import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './style.css';
import { Table, List, Skeleton, Radio, Button, Input, Pagination, Form, TreeSelect, Select, DatePicker, Image, Checkbox } from 'antd';
import {
SearchOutlined,
} from '@ant-design/icons';
import * as Colors from '../../constants/Colors';

import * as LINK from '../../constants/Links';
import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../utils/constant";

import * as ActionsWeb from "../../actions/indexWeb";
import { Link } from "react-router-dom";
import moment from 'moment';
import ItemProduct from "../itemProduct/index";
const { RangePicker } = DatePicker;
const count = 3;
const Index = (props) => {
    // let location=useLocation();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isLoadingSearch, SetisLoadingSearch] = useState(false);
    const [productsList, setproductsList] = useState([]);
    const [ListProducts, setListProducts] = useState([]);
    const [data, setdata] = useState([]);
    const [dataList, setdataList] = useState([]);
    const [ListTags, setListTags] = useState([]);
    const [ListCategory, setListCategory] = useState([]);
    const formSearchRef = new useRef(null)
    const [isloading, setisloading] = useState(false);
    const [isFirst, setisFirst] = useState(false);
    const [selectedRowKeys1, SetselectedRowKeys1] = useState([]);
    const [selectedRows1, SetselectedRows1] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [valueChose, setValueChose] = useState(false)
    const [columns, setColumns] = useState([
        {
            title: 'STT',
            dataIndex: 'key',


        },
        {
            title: 'Code',
            dataIndex: 'code',

            filterSearch: true,


        },
        {
            title: 'Giá trị',
            dataIndex: 'value',

            filterSearch: true,
        },
        {
            title: 'Mô tả',
            dataIndex: 'mess',

            filterSearch: true,


        },
        {
            title: 'ngày tạo',
            dataIndex: 'created_at',


        },
        // {
        //     title: 'Ngày cập nhật',
        //     dataIndex: 'updated_at',

        // },
        // {
        //     title: 'Hành động',
        //     dataIndex: 'action',
        //     width: "20%",

        // },
    ],);
    const [initLoading, setInitLoading] = useState(true);


    const setProductsSeleted = (selectedRows) => {

        const result = selectedRows.filter(itemA => !productsList.some(itemB => (1 * itemB.id) === (1 * itemA.id)));
        setproductsList([...productsList, ...result]);

    }

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {


        setListProducts(Web.ListVouchers);
        SetisLoadingSearch(false)
        data_(Web.ListVouchers);
        return () => {

        };
    }, [Web.ListVouchers]);


    const data_ = (ListVouchers) => {

        const data_ = ListVouchers?.data;
        let meta_ = {
            ...meta,
            current_page: ListVouchers.current_page,
            first_page_url: ListVouchers.first_page_url,
            from: ListVouchers.from,
            last_page: ListVouchers.last_page,
            last_page_url: ListVouchers.last_page_url,
            links: ListVouchers.links,
            next_page_url: ListVouchers.next_page_url,
            path: ListVouchers.path,
            per_page: 1 * ListVouchers.per_page,
            prev_page_url: ListVouchers.prev_page_url,
            to: ListVouchers.to,
            total: ListVouchers.total,
        };

        if (data_) {
           
            setdata(data_);
            setMeta(meta_);
            setList(data_);

        }
        setInitLoading(false);
    }
    useEffect(() => {

        try {

            dispatch(ActionsWeb.SearchVouchersRequest({
                limit: meta.per_page,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }, {
                "data": {
                    "name": "",
                    "address": "",
                    "code": "",
                    "phone": "",
                    "created_at": "",
                    "province": "",
                    "district": "",
                    "war": "",
                    "value": "",
                    "from_at": "",
                    "to_at": "",
                    "codecus": ""
                }
            }));


            setisFirst(true)
        } catch (error) {
            console.log("errorerrorerrorerror", error);
        }
        return () => {

        };
    }, []);
    const onSearch = (values) => {
        SetisLoadingSearch(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        
        if (filteredvalues.from_to_at) {
            filteredvalues.from_at = filteredvalues.from_to_at[0].format("YYYY-MM-DD")
            filteredvalues.to_at = filteredvalues.from_to_at[1].format("YYYY-MM-DD")
        }
        dispatch(ActionsWeb.SearchVouchersRequest({
            limit: meta.per_page,
            page: meta.current_page, column: meta.column, sort: meta.sort
        }, { data: { ...filteredvalues } }))
        delete filteredvalues["created_at"]
        delete filteredvalues["from_to_at"]
        setdataList([]);
        setList([]);


    }
    const onResetFrom = () => {

        formSearchRef.current.resetFields();
        formSearchRef.current.submit();
    }


    const OnPagination = (page, pageSize) => {

        const formvalues = formSearchRef.current.getFieldsValue();

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        dispatch(ActionsWeb.SearchVouchersRequest({
            limit: pageSize,
            page: page, column: meta.column, sort: meta.sort
        }, { data: { ...filteredvalues, isdetail: true } }))


    }
    const onLoadMore = () => {
        if (meta.current_page < meta.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            OnPagination(meta.current_page + 1, meta.per_page)

        }

    };

    const onChangeRadio = (e) => {
        // setValue(e.target.value);
        setValueChose(e);
        if (props.onchoseVoucher) {
            props.onchoseVoucher(e);
        }
        console.log("eeeeeeeeeeeeeee", e);
    }
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <div className='dropdown_rim'>
            <Form name="formsearch" className="fromsearch" ref={formSearchRef} onFinish={onSearch} style={{
                padding: "0px !important",
                width: "100%",
                display: "flex",
                margin: 0
            }}>
                <Form.Item style={{ margin: 0, width: "100%" }}>
                    <Input.Group compact>
                        <div className="row">

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Code" />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="name" />
                                </Form.Item>
                            </div>

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                <Form.Item name="phone" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="phone" />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                            <Form.Item name="codecus" noStyle style={{ margin: 0 }}>
                                <Input placeholder="codecus" />
                            </Form.Item>
                            </div>

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                        <Form.Item name="address" noStyle style={{ margin: 0 }}>
                            <Input placeholder="address" />
                        </Form.Item>
                        </div>
                        <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                <Form.Item name="from_to_at" noStyle style={{ margin: 0 }}>
                                    <RangePicker />
                                </Form.Item>
                        </div>
                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                    <RangePicker />
                                </Form.Item>
                            </div>

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                <Form.Item noStyle style={{ margin: 0 }} >
                                    <div style={{ display: "flex" }}>
                                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={isLoadingSearch} />
                                        <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={onResetFrom}>Reset</Button>
                                    </div>
                                </Form.Item>

                            </div>

                        </div>






                    </Input.Group>
                </Form.Item>
            </Form>
            {/* <Radio.Group onChange={onChangeRadio} style={{ width:"100%" }}> */}
            {!props.isListShow && <div className='list_scroll'>
                <List
                    className="demo-loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item className='list_item_custom'
                            actions={[
                                // <Radio value={item.id}></Radio>
                                // <Checkbox checked={selectedRowKeys1.includes(item.id)} onChange={(e) => { SetselectedRowKeys1list(e, item.id); SetselectedRows1list(e, item)}}></Checkbox>

                            ]}
                            onClick={() => {
                                onChangeRadio(item);
                            }}
                            style={{ backgroundColor: valueChose?.id === item.id ? Colors.pop3[1] : "" }}
                        >
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta

                                    title={item.code}

                                    description={<div style={{ fontSize: "11px" }}>
                                        <strong>Giá Trị:</strong> {item.value}<br></br>
                                        <strong>Ngày Tạo:</strong> {item.created_at}<br></br>
                                        <strong>Mô Tả:</strong> {item.mess}<br></br>

                                    </div>}
                                />


                                {/* <div>content</div> */}
                            </Skeleton>
                        </List.Item>
                    )}
                />

            </div>}
            {/* </Radio.Group> */}
            {props.isListShow && <><Table rowClassName={(record, index) => {
                return "__row_";
            }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys1,
                    onChange: (selectedRowKeys, selectedRows) => {
                        SetselectedRowKeys1(selectedRowKeys)
                        SetselectedRows1(selectedRows)

                        setProductsSeleted(selectedRows)
                    },
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{

                    y: "60vh",
                }}
                style={{ paddingBottom: "30px" }}
            />
                <div className="pagination_">
                    <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={meta && meta.per_page ? meta.per_page : 10} total={meta && meta.total ? meta.total : 10} showSizeChanger onChange={OnPagination} />
                </div></>}
        </div>
    );
};

export default Index;
