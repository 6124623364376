import * as Types from "./../constants/TypesactionApp";


let Initstate = {
    isloader: "",
    isSyncData: false,
    isNotification:{
        show:false,
        title:"",
        text:"",
    },
    socket:null,
    active:false
   
};

const reducer = (state = Initstate, action) =>
{
    switch (action.type) {
        case Types.ACTIVE:
            state = {
                ...state,
                active: action.result,
            };
            return { ...state };
        case Types.SET_IS_LOADING:
            state = {
                ...state,
                isloader: action.result,
            };
            return { ...state };
        case Types.IS_SYNCDATA:
            
            state = {
                ...state,
                isSyncData: action.result,
            };
            return { ...state };
        case Types.IS_NOTIFICATION:
            state = {
                ...state,
                isNotification: action.result,
            };
            return { ...state };
        case Types.IS_CONNECT_SOCKET:
            state = {
                ...state,
                socket: action.result,
            };
            return { ...state };

        default:
            return { ...state };


    }
};
export default reducer;
