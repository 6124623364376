import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, DatePicker, Pagination, Modal, message, Button, Tag, Card, Form, InputNumber, Switch as SwitchA, } from 'antd';
import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined,
    DeleteOutlined,
    EditOutlined, UnorderedListOutlined, SearchOutlined, PlusCircleOutlined, AppstoreOutlined, RollbackOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { checkScreenSize, getParamsUrl } from "../../../../service";

import IndexMobile from "./IndexMobile";
import Draggable from 'react-draggable';
const { Search } = Input;

const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null);
        this.draggleRef = createRef(null);
        this.formRef = createRef(null);
        this.state = {

            ListFeedback: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Rộng',
                    dataIndex: 'width',

                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Cao',
                    dataIndex: 'height',
                    sorter: (a, b) => a.code - b.code,

                },

                {
                    title: 'Dài',
                    dataIndex: 'wide',
                    sorter: (a, b) => a.value - b.value,

                },
                {
                    title: 'Mặc định',
                    dataIndex: 'active',
                    render: (text, record, index) =>
                    {
                        return record.active ? <Tag color="green">Mặc định</Tag> : ""
                    }
                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreCategory: {},
            isloading: false,
            isLoadingSearch: false,
            disabled: true,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisibleDetail = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = () =>
    {
        this.setVisibleDetail(true);


    };

    handleCancelDetail = () =>
    {
        this.setVisibleDetail(false);

    };
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreFeedbackRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
     
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchFeedbackRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListFeedback && nextProps.Web.ListFeedback !== prevState.ListFeedback) {

            return {
                ListFeedback: nextProps.Web.ListFeedback,

            };

        }
        else if (nextProps.Web.StoreFeedback && nextProps.Web.StoreFeedback !== prevState.StoreFeedback) {
            return {
                StoreFeedback: nextProps.Web.StoreFeedback,

            };

        }
        else {
            return null;
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreFeedback !== prevState.StoreFeedback) {
            let { StoreFeedback } = this.state;
            try {
                if (StoreFeedback.code === 200) {
                    if (StoreFeedback.message && typeof StoreFeedback.message === "string") {
                        message.success(StoreFeedback.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchCustomersRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });


                }
                else {
                    if (StoreFeedback.message && typeof StoreFeedback.message === "string") {

                        message.error(StoreFeedback.message)
                    } else {
                        for (let x in StoreFeedback.message) {
                            let mess = StoreFeedback.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreFeedback()

        }
        if (this.state.ListFeedback !== prevState.ListFeedback) {
            let { ListFeedback } = this.state
         
            this.SetisLoadingSeach(false)
            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        with: "5%",
                        align: "center"

                    },
                    {
                        title: 'Tên',
                        dataIndex: 'firstname',
                        sorter: (a, b) => a.firstname - b.firstname,
                        render: (text, record, index) => (
                            record.firstname + " " + record.lastname
                        )
                    },

                    {
                        title: 'email',
                        dataIndex: 'email',
                        sorter: (a, b) => a.email - b.email,

                    },
                    {
                        title: 'Phone',
                        dataIndex: 'phone_number',
                        sorter: (a, b) => a.value - b.value,
                    },
                    {
                        title: 'Đánh giá',
                        dataIndex: 'note',
                        sorter: (a, b) => a.value - b.value,

                    },

                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        with: "60%",
                        sorter: (a, b) => a.created_at - b.created_at,


                    },
                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        filters: filters.hidden,
                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} />
                        },

                    },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <RollbackOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e }
        this.SetisLoading(true)
        this.props.StoreFeedbackRequest(tmp, tmp.id);

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setAcive = (record) =>
    {

        record.active = !record.active
        // return;
        this.SetisLoading(true)
        this.props.StoreFeedbackRequest(record, record.id ? record.id : "")
    }

    data = () =>
    {
        let { ListFeedback } = this.state;
       
        if (ListFeedback.data.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListFeedback.current_page,
            first_page_url: ListFeedback.first_page_url,
            from: ListFeedback.from,
            last_page: ListFeedback.last_page,
            last_page_url: ListFeedback.last_page_url,
            links: ListFeedback.links,
            next_page_url: ListFeedback.next_page_url,
            path: ListFeedback.path,
            per_page: ListFeedback.per_page,
            prev_page_url: ListFeedback.prev_page_url,
            to: ListFeedback.to,
            total: ListFeedback.total,
        };
        ListFeedback.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = item.id
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_FEEDBACK_EDIT + "/" + record.id,
            // state: state
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchFeedbackRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchFeedbackRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_FEEDBACK}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onFinishForm = (values) =>
    {

        const { record } = this.state;
        const record_ = { ...record, ...values, active: values.active !== true ? false : true }
        this.SetisLoading(true)
        this.props.StoreFeedbackRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...META }
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="infopackage main_content feedback_">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Cập nhật
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[
                        // <Button key="back" onClick={this.handleCancelDetail}>
                        //     Hủy
                        // </Button>,
                        // <Button key="resetradio" onClick={this.handleResetRadioDetail}>
                        //     Reset
                        // </Button>,
                        // <Button key="submit" type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={() => { this.update_() }}>
                        //     Cập nhật
                        // </Button>,
                    ]}
                    width={"400px"}
                    bodyStyle={{
                        height: "fit-content",
                        // overflowY: "scroll"
                    }}
                // style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_">

                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Form.Item label="Cao"
                                name="height"
                                rules={[
                                    {
                                        required: true,
                                        message: "Cao là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber addonAfter={"CM"} placeholder="height" min={0} />
                            </Form.Item>
                            <Form.Item label="Dài"
                                name="wide"
                                rules={[
                                    {
                                        required: true,
                                        message: "Rộng là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber addonAfter={"CM"} placeholder="wide" min={0} />
                            </Form.Item>
                            <Form.Item label="Cân nặng" name="weight" rules={[
                                {
                                    required: true,
                                    message: "Cân nặng là bắt buộc"
                                },
                            ]}>
                                <InputNumber addonAfter={"KG"} placeholder="weight" min={0} />
                            </Form.Item>
                            <Form.Item label="*Chọn mặc định" name="active">
                                <SwitchA checked={this.state.record.active === 0 ? false : true} onChange={(e) =>
                                {
                                    this.setState(state =>
                                    {
                                        return {
                                            record: { ...state.record, active: e }
                                        }
                                    })
                                }} />

                            </Form.Item>

                            <Form.Item>

                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </Form.Item>
                        </Form >
                    </div>
                </Modal>

                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Feeback</span>
                        </div>
                        <Link to={LINK.WEB_FEEDBACK_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",

                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_" style={{ padding: "10px", }}>
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{

                                    width: "100%",
                                    display: "flex"
                                }}>


                                    <div className="row">

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tiêu đề" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tên" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="phone_number" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="số điện thoại" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="note" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Nội dung" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="product_slug" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="slug sản phẩm" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="post_slug" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="slug bài viết" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                <RangePicker />
                                            </Form.Item>
                                        </div>


                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </div>
                                    </div>
                                </Form>
                            </div>


                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            setIsNewList={() =>
                            {
                                this.setState(state =>
                                {
                                    return {
                                        isNewList: !state.isNewList
                                    }
                                })
                            }}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <>  <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            rowSelection={{
                                ...this.state.rowSelection,
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                            <div className="pagination_">
                                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                            </div>
                        </>
                        }
                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListFeedbackRequest: (body) =>
        {
            dispatch(ActionsWeb.ListFeedbackRequest(body));
        },
        StoreFeedbackRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreFeedbackRequest(data, id));
        },
        ResetStoreFeedback: () =>
        {
            dispatch(ActionsWeb.ResetStoreFeedback());
        },
        SearchFeedbackRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchFeedbackRequest(body, data));
        },
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);