import React, { Component, createRef, useEffect, useRef, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Drawer, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined, RetweetOutlined, FileTextOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, formatMN1, getParamsUrl, replaceThous } from "../../../../service";
import IndexMobile from "./IndexMobile";
import SortStamps from "./sortStamps";
import PaperPriceList from "./paperPriceList";
import PaperType from "./paperType";
import PaperQuantity from "./paperQuantity";
import PriceOnAmountStamp from "./priceOnAmountStamp";
import PaperAmountAndDiscount from './paperAmountAndDiscount'
import PriceTable from './priceTable'
import html2canvas from 'html2canvas';

const { RangePicker } = DatePicker;
const { Search } = Input;

const Index = ({ ...props }) => {

    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [record, setRecord] = useState({
        hidden: 0,
        deleted: 0
    });
    const [listSortStamps, setListSortStamps] = useState([{
        width: "",
        height: "",
        a3: "",
        a4: "",
        rank: 1,
        code: "",
    },]);
    const [ListPaperPriceList, setListPaperPriceList] = useState([{
        name: "okokoo",
        paper_type_id: 5,
        from: 1,
        to: 4,
        price: 1000,
        code: "",
        note: "",
        rank: 1,
        hidden: 0,
        deleted: 0
    },]);

    const [ListPaperType, setListPaperType] = useState([{
        name: "okokoo",
        paper_type_id: 5,
        from: 1,
        to: 4,
        price: 1000,
        code: "",
        note: "",
        rank: 1,
        hidden: 0,
        deleted: 0
    },]);
    const [ListPaperAmountAndDiscount, setListPaperAmountAndDiscount] = useState([
        //     {
        //     code: "",
        //     sort_stmps_id: 1,
        //     mount_stamp: 0,
        //     mount_paper: 0,
        //     discount: 0,
        //     note: "",
        //     rank: 1,
        //     hidden: 0,
        //     deleted: 0
        // },
    ]);

    const [ListPaperAmountAndDiscount_, setListPaperAmountAndDiscount_] = useState([
    ]);
    const formSortStampsRef = useRef(null);
    const formPaperAmountAndDiscountRef = useRef(null);
    const formPaperTypeRef = useRef(null);
    const formPaperPriceListRef = useRef(null);
    const formSearchPriceRef = useRef(null);
    const [isloading, setIsloading] = useState(false);
    const [settings, setSettings] = useState(null);
    const [visibleprice, setVisiblePrice] = useState(false);
    const [dataSearch, setDataSearch] = useState([]);
    const [ListPriceOnAnountStamps, setListPriceOnAnountStamps] = useState([
        //     {
        //     code: "",
        //     sort_stmps_id: 1,
        //     mount_stamp: 0,
        //     mount_paper: 0,
        //     discount: 0,
        //     note: "",
        //     rank: 1,
        //     hidden: 0,
        //     deleted: 0
        // },
    ]);
    // const aspectRatio = 29.7 / 42;
    // const postDotOr=9;
    // const widthDotOr=5;

    // const maxWidth = 550;
    // const maxHeight = 500;
    // const pageA3=()=>{
    //     let width, height;

    //     if (maxWidth / aspectRatio <= maxHeight) {
    //         width = maxWidth;
    //         width = maxWidth / aspectRatio;
    //     } else {
    //         width = maxHeight * aspectRatio;
    //         height = maxHeight;
    //     }
    //     return {width,height}
    // }
    // const posDot=()=>{
    //     let pos;
    //     pos=postDotOr*aspectRatio;
    //     return pos
    // }
    // const widthDot=()=>{
    //     let width;

    //     width=widthDotOr*aspectRatio;
    //     console.log("widthwidthwidthwidth",width);
    //     return width
    // }
  
    const loadData = () => {
        dispatch(ActionsWeb.ListSortStampsRequest({
            limit: meta.per_page,
            page: meta.current_page, column: "rank", sort: "ASC"
        }));
        dispatch(ActionsWeb.ListPaperPriceListRequest({
            limit: meta.per_page,
            page: meta.current_page, column: "rank", sort: "ASC"
        }));

        dispatch(ActionsWeb.ListPaperTypeRequest({
            limit: meta.per_page,
            page: meta.current_page, column: "rank", sort: "ASC"
        }));

        dispatch(ActionsWeb.ListPaperAmountAndDiscountRequest({
            limit: meta.per_page,
            page: meta.current_page, column: "paper_amount_and_discount.rank", sort: "ASC"
        }));
    }
    useEffect(() => {
        loadData();

       let settings= localStorage.getItem("settings");
       if(settings){
        settings=JSON.parse(settings);
        setSettings(settings);
       }
        return () => {

        };
    }, []);

    useEffect(() => {
        if(ListPaperAmountAndDiscount.length>0){
            const newListPaperAmountAndDiscount=[...ListPaperAmountAndDiscount];
            listSortStamps.map((value, index) => {
    
                if (!newListPaperAmountAndDiscount?.[index]?.array_discount) {
                    let newItem={...newListPaperAmountAndDiscount?.[index-1]};
                    newItem={...newItem,...value};
                    
                    newListPaperAmountAndDiscount[index]=newItem;
                    // ListPaperAmountAndDiscount?.[index]?.array_discount
    
                }
    
    
            })
    
            setListPaperAmountAndDiscount_([...newListPaperAmountAndDiscount]);
        }
       
        return () => {

        };
    }, [listSortStamps,ListPaperAmountAndDiscount]);
    useEffect(() => {
        if (ListPaperPriceList.length > 0 && ListPaperAmountAndDiscount_.length > 0) {

            ListPaperAmountAndDiscount_.map((value, index) => {
                if (value.array_discount) {
                    value.array_discount.map((dis, idis) => {

                        const foundItem = ListPaperPriceList.find(item => parseInt(item.from) <= parseInt(dis.amount_paper) && parseInt(dis.amount_paper) <= parseInt(item.to));
                        // console.log("foundItem",foundItem);
                        if (foundItem) {
                            const priceOnStamp = parseFloat(foundItem.price) / parseInt(value.a3);
                            const priceOnAmountStamp = priceOnStamp * parseInt(dis.amount_stamp);
                            const priceOnAmountStampDiscount = parseFloat(priceOnAmountStamp) + parseFloat(dis.discount);
                            // console.log("priceOnStamp",priceOnAmountStamp);
                            value.array_discount[idis].priceOnStamp = priceOnStamp;
                            value.array_discount[idis].priceOnAmountStamp = priceOnAmountStamp;
                            value.array_discount[idis].priceOnAmountStampDiscount = priceOnAmountStampDiscount;
                        }

                    })
                }
            })
          //  console.log("ListPaperAmountAndDiscount_ListPaperAmountAndDiscount_ListPaperAmountAndDiscount_",ListPaperAmountAndDiscount_);
            setListPriceOnAnountStamps(ListPaperAmountAndDiscount_);
            setDataSearch(ListPaperAmountAndDiscount_);
            // console.log("ListPaperAmountAndDiscountListPaperAmountAndDiscount",ListPaperAmountAndDiscount);
        }
        return () => {

        };
    }, [ListPaperPriceList, ListPaperAmountAndDiscount_]);
    useEffect(() => {
        if (Web.ListSortStamps.data) {
            setListSortStamps(Web.ListSortStamps.data)
        }
        return () => {

        };
    }, [Web.ListSortStamps]);
    useEffect(() => {
        if (Web.ListPaperPriceList.data) {
            setListPaperPriceList(Web.ListPaperPriceList.data)
        }
        return () => {

        };
    }, [Web.ListPaperPriceList]);

    useEffect(() => {
        if (Web.ListPaperType.data) {
            setListPaperType(Web.ListPaperType.data)
        }
        return () => {

        };
    }, [Web.ListPaperType]);

    useEffect(() => {

        if (Web.ListPaperAmountAndDiscount) {
            if (Web.ListPaperAmountAndDiscount.length <= 0) {
                listSortStamps.map((value, index) => {
                    Web.ListPaperAmountAndDiscount.push({
                        sort_stamps_id: value.id,
                        hidden: 0,
                        deleted: 0,
                        array_discount: [
                            { amount_paper: 0, amount_stamp: 200, discount: 0, id: "" }
                        ]
                    })
                })
            }
            // console.log("Web.ListPaperAmountAndDiscountWeb.ListPaperAmountAndDiscount", Web.ListPaperAmountAndDiscount);
            setListPaperAmountAndDiscount(Web.ListPaperAmountAndDiscount);

        }
        return () => {

        };
    }, [Web.ListPaperAmountAndDiscount]);
    useEffect(() => {
        setFormSortStampsAgain();

        return () => {

        };
    }, [listSortStamps]);
    const setFormSortStampsAgain = () => {
        const dataAddform = {};

        listSortStamps.map((item, index) => {
            dataAddform[`sort-stamps-width-${index}`] = item.width;
            dataAddform[`sort-stamps-height-${index}`] = item.height;
            dataAddform[`sort-stamps-a3-${index}`] = item.a3;
            dataAddform[`sort-stamps-a4-${index}`] = item.a4;
            dataAddform[`sort-stamps-rank-${index}`] = item.rank;
        })
        formSortStampsRef.current.setFieldsValue(dataAddform)
    }
    useEffect(() => {
        setFormPaperTypeAgain();

        return () => {

        };
    }, [ListPaperType]);
    const setFormPaperTypeAgain = () => {
        const dataAddform = {};

        ListPaperType.map((item, index) => {
            dataAddform[`paper-type-name-${index}`] = item.name;
            dataAddform[`paper-type-code-${index}`] = item.code;
            dataAddform[`paper-type-note-${index}`] = item.note;

        })
        formPaperTypeRef.current.setFieldsValue(dataAddform)
    }


    useEffect(() => {
        setFormPaperPriceListAgain();

        return () => {

        };
    }, [ListPaperPriceList]);
    const setFormPaperPriceListAgain = () => {
        const dataAddform = {};

        ListPaperPriceList.map((item, index) => {
            dataAddform[`paper-price-list-paper_type_id-${index}`] = item.paper_type_id;
            dataAddform[`paper-price-list-name-${index}`] = item.name;
            dataAddform[`paper-price-list-from-${index}`] = item.from;
            dataAddform[`paper-price-list-to-${index}`] = item.to;
            dataAddform[`paper-price-list-price-${index}`] = formatMN1(item.price);
            dataAddform[`paper-price-list-code-${index}`] = item.code;
            dataAddform[`paper-price-list-note-${index}`] = item.note;

        })
        formPaperPriceListRef.current.setFieldsValue(dataAddform)
    }
    useEffect(() => {

        processStore(Web.StoreSortStamps);

        return () => {

        };
    }, [Web.StoreSortStamps]);
    useEffect(() => {

        processStore(Web.StorePaperType);

        return () => {

        };
    }, [Web.StorePaperType]);
    useEffect(() => {

        processStore(Web.StorePaperPriceList);

        return () => {

        };
    }, [Web.StorePaperPriceList]);

    useEffect(() => {

        processStore(Web.StorePaperAmountAndDiscount);

        return () => {

        };
    }, [Web.StorePaperAmountAndDiscount]);
    const processStore = (dataStore) => {
        try {
            if (dataStore.code === 200) {
                if (dataStore.message && typeof dataStore.message === "string") {
                    message.success(dataStore.message)
                    this.setState(state => {
                        return {
                            record: {

                                hidden: 0,
                                deleted: 0
                            }
                        }
                    })
                } else {

                    message.error("Có lỗi!")
                }
                loadData();

            }
            else {
                if (dataStore.message && typeof dataStore.message === "string") {

                    message.error(dataStore.message)
                } else {
                    for (let x in dataStore.message) {
                        let mess = dataStore.message[x];
                        for (let y in mess) {
                            if (mess[y]) {
                                message.error(mess[y])
                            }

                        }
                    }
                    // message.error("Có lỗi!")
                }

            }

        } catch (error) {
            // message.error("Có lỗi!")
            setIsloading(false)
        }
        setIsloading(false)
      
    }

    const submitSortStamp = (values) => {
        setIsloading(true)

        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        for (let x in values) {
            let x_ = x.split("-");
            let index = x_[x_.length - 1];

            if (flag != parseInt(index)) {
                newdata.push(itemNew)
                flag = index;
                itemNew = {};
            }
            if (flag == index) {
                if (values?.[`sort-stamps-width-${index}`] && values?.[`sort-stamps-height-${index}`]) {
                    itemNew.width = values[`sort-stamps-width-${index}`];
                    itemNew.height = values[`sort-stamps-height-${index}`];
                    itemNew.a3 = values[`sort-stamps-a3-${index}`];
                    itemNew.a4 = values[`sort-stamps-a4-${index}`];
                    itemNew.rank = listSortStamps[index]?.rank;
                    itemNew.id = listSortStamps[index]?.id;
                    itemNew.isEdit = listSortStamps[index]?.isEdit;
                    itemNew.isNew = listSortStamps[index]?.isNew;
                    itemNew.code = listSortStamps[index]?.code ? listSortStamps[index]?.code : itemNew.width + "x" + itemNew.height;
                }

            }
            i++;
            if (i == Object.values(values).length) {

                newdata.push(itemNew)

            }

        }


        const body_ = { data: newdata }

        dispatch(ActionsWeb.StoreMutilpleSortStampsRequest(body_, false));
    }
    const submitPaperType = (values) => {
        setIsloading(true)

        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        for (let x in values) {
            let x_ = x.split("-");
            let index = x_[x_.length - 1];

            if (flag != parseInt(index)) {
                newdata.push(itemNew)
                flag = index;
                itemNew = {};
            }
            if (flag == index) {
                if (values?.[`paper-type-name-${index}`] && values?.[`paper-type-code-${index}`]) {
                    itemNew.name = values[`paper-type-name-${index}`];
                    itemNew.code = values[`paper-type-code-${index}`];
                    itemNew.note = values[`paper-type-note-${index}`];
                    itemNew.rank = ListPaperType[index]?.rank;
                    itemNew.id = ListPaperType[index]?.id;
                    itemNew.isEdit = ListPaperType[index]?.isEdit;
                    itemNew.isNew = ListPaperType[index]?.isNew;

                }

            }
            i++;
            if (i == Object.values(values).length) {

                newdata.push(itemNew)

            }

        }


        const body_ = { data: newdata }
        console.log("body_body_body_", body_);
        dispatch(ActionsWeb.StoreMutilplePaperTypeRequest(body_, false));
    }
    const submitPaperPriceList = (values) => {
        setIsloading(true)

        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        for (let x in values) {
            let x_ = x.split("-");
            let index = x_[x_.length - 1];

            if (flag != parseInt(index)) {
                newdata.push(itemNew)
                flag = index;
                itemNew = {};
            }
            if (flag == index) {
                if (values?.[`paper-price-list-paper_type_id-${index}`]) {
                    itemNew.name = values[`paper-price-list-name-${index}`];
                    itemNew.code = values[`paper-price-list-code-${index}`];
                    itemNew.note = values[`paper-price-list-note-${index}`];
                    itemNew.paper_type_id = values[`paper-price-list-paper_type_id-${index}`];
                    itemNew.from = values[`paper-price-list-from-${index}`];
                    itemNew.to = values[`paper-price-list-to-${index}`];
                    itemNew.price = replaceThous(values[`paper-price-list-price-${index}`]);
                    itemNew.rank = ListPaperPriceList[index]?.rank;
                    itemNew.id = ListPaperPriceList[index]?.id;
                    itemNew.isEdit = ListPaperPriceList[index]?.isEdit;
                    itemNew.isNew = ListPaperPriceList[index]?.isNew;

                }

            }
            i++;
            if (i == Object.values(values).length) {

                newdata.push(itemNew)

            }

        }


        const body_ = { data: newdata }
        dispatch(ActionsWeb.StoreMutilplePaperPriceListRequest(body_, false));
    }

    const submitPaperAmountAndDiscount = (values) => {

        // return;
        // setIsloading(true)
        //    console.log("valuesvalues", values);
        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        let checkCode = [];
        let checkCodeDuplicate = [];

        ListPaperAmountAndDiscount_.map((value, index) => {

            if (value.array_discount) {
                value.array_discount.map((dis, idis) => {
                    itemNew = {};
                    itemNew.sort_stamps_id = values[`paper-amount-and-discount-sort_stamps_id-${index}`];
                    itemNew.note = values[`paper-amount-and-discount-note-${index}`];
                    itemNew.rank = index + 1;
                    itemNew.id = dis.id;
                    itemNew.hidden = 0;
                    itemNew.deleted = 0;

                    if (values?.[`paper-amount-and-discount-amount_paper-${index}-${idis}`]) {

                        itemNew.amount_paper = values[`paper-amount-and-discount-amount_paper-${index}-${idis}`];

                    }
                    if (values?.[`paper-amount-and-discount-amount_stamp-${index}-${idis}`]) {

                        itemNew.amount_stamp = values[`paper-amount-and-discount-amount_stamp-${index}-${idis}`];

                    }
                    itemNew.discount = values[`paper-amount-and-discount-discount-${index}-${idis}`] ? replaceThous(values[`paper-amount-and-discount-discount-${index}-${idis}`]) : 0;
                    itemNew.code = itemNew.sort_stamps_id + "x" + itemNew.amount_stamp + "x" + itemNew.amount_paper;

                    if (itemNew.sort_stamps_id && itemNew.amount_stamp && itemNew.amount_paper) {
                        newdata.push(itemNew)
                    }
                })
            }
        })
        // for(let x in values){
        //     itemNew={};
        //     let ppp=null;
        //     if(ListPaperAmountAndDiscount[i]){
        //         ppp=ListPaperAmountAndDiscount[i];

        //     }
        //     let x_ = x.split("-");
        //     let indexdis = x_[x_.length - 1];
        //     let index = i;

        //     if(x.includes("amount_paper") || x.includes("amount_stamp") || x.includes("discount")){ 
        //         //  index = x_[x_.length - 2];

        //         if (values?.[`paper-amount-and-discount-amount_paper-${index}-${indexdis}`]) {

        //             itemNew.amount_paper = values[`paper-amount-and-discount-amount_paper-${index}-${indexdis}`];

        //         }
        //         if (values?.[`paper-amount-and-discount-amount_stamp-${index}-${indexdis}`]) {



        //             itemNew.amount_stamp = values[`paper-amount-and-discount-amount_stamp-${index}-${indexdis}`];

        //         }
        //         itemNew.discount = values[`paper-amount-and-discount-discount-${index}-${indexdis}`]?replaceThous(values[`paper-amount-and-discount-discount-${index}-${indexdis}`]):0;
        //     }

        //     console.log("indexindex",values[`paper-amount-and-discount-sort_stamps_id-${index}`]);


        //             itemNew.sort_stamps_id=values[`paper-amount-and-discount-sort_stamps_id-${index}`];
        //             itemNew.note=values[`paper-amount-and-discount-note-${index}`];
        //             itemNew.rank=i+1;
        //             itemNew.hidden=0;
        //             itemNew.deleted=0;
        //             itemNew.code = itemNew.sort_stamps_id + "x" + itemNew.amount_stamp + "x" + itemNew.amount_paper;
        //             if(checkCode.includes(itemNew.code)){
        //                 checkCodeDuplicate.push(itemNew.code);
        //             }
        //             checkCode.push(itemNew.code);

        //     if(ppp && ppp.array_discount){
        //         itemNew.id=ppp.array_discount[indexdis]?.id?ppp.array_discount[indexdis]?.id:"";
        //     }else{
        //         itemNew.id="";
        //     }
        //     if(itemNew.sort_stamps_id && itemNew.amount_stamp && itemNew.amount_paper && itemNew.discount){
        //         newdata.push(itemNew)
        //     }

        //     i++;
        // }
        // console.log("checkCode", checkCode);
        // console.log("checkCodeDuplicate", checkCodeDuplicate);


       
        const body_ = { data: newdata }
        console.log("body_body_", body_);
        dispatch(ActionsWeb.StoreMutilplePaperAmountAndDiscountRequest(body_, false));
    }
    const onSearch = (e) => {
        if (e) {
            const dataS = ListPriceOnAnountStamps.filter((value, index) => {
                return value.code == e;
            })
            setDataSearch(dataS);
        } else {
            setDataSearch(ListPriceOnAnountStamps);
        }
        // console.log("eeeee",e);
    }
   const Snap_ = (id) => {
        html2canvas(document.querySelector("#" + id),{
            scale: 2,
        }).then(canvas => {
          // document.body.appendChild(canvas);
          var img = document.createElement("img"); // create an image object
    
          //conpy hinh vào clipboard
          canvas.toBlob(blob =>
            navigator.clipboard.write([new window.ClipboardItem({
              'image/png': blob
            })])
    
    
          )
    
    
          // window.prompt("Copy to clipboard: Ctrl+C, Enter", canvas.toDataURL());
          //show hinh
          var url = canvas.toDataURL(); // get canvas content as data URI
          img.src = url;
          img.setAttribute("class", "appendsnap");
    
          document.body.appendChild(img)
    
    
          setTimeout(() => {
            document.body.removeChild(img);
          }, 2000);
    
        });
      }
      console.log("newdatanewdatanewdatanewdatanewdatanewdata");
    return (

        <div className="main_content post_" >
            <Drawer
                title={<div className="d-flex justify-content-between  align-content-center"><span> Bảng giá</span><span>  <Button onClick={() => Snap_('price-table')}>
               <i class="fas fa-camera-retro"></i>
            </Button></span></div>} placement="right"
                onClose={() => setVisiblePrice(false)}
                visible={visibleprice}
                size={checkScreenSize().width < 600 ? "default" : "large"}

            // style={{
            //   position: 'absolute',

            // }}
            >
              <PriceTable settings={settings} ListPriceOnAnountStamps={ListPriceOnAnountStamps}/>
            </Drawer>
            <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button className="reset_btn btn_edit btn__"
                            style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                        >
                            {/* {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                            {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />} */}
                        </button>
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Định mức nguyên liệu</span>
                    </div>
                    <Link to={LINK.WEB_POST_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "20px",
                        borderRadius: "3px"
                    }}
                >
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>

                                    <AppstoreOutlined className="color-main_2" />

                                    <span className="color-main_2 ml-2  font-size-14">Xếp tem</span>
                                </div>

                            </div>} bordered={false}
                                style={{ width: "100%", }}
                                bodyStyle={{
                                    padding: "10px",
                                }}
                                headStyle={{
                                    backgroundColor: Colors.colorgrey,
                                    display: "flex",
                                    minHeight: "20px",
                                    borderRadius: "3px"
                                }}
                            >
                                <div>
                                    <Form
                                        labelCol={{
                                            span: 4,
                                        }}
                                        wrapperCol={{
                                            // span: 14,
                                        }}
                                        layout="vertical"
                                        name="formSortStamps" ref={formSortStampsRef}
                                        onFinish={submitSortStamp}
                                    >
                                        <SortStamps listSortStamps={listSortStamps} formSortStampsRef={formSortStampsRef} setListSortStamps={setListSortStamps} />
                                        <Form.Item >
                                            <div className="flex_  mt-4">

                                                <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>

                                {/* <div className="paper-page-wrap " style={{ width:maxWidth,height:maxHeight }}>
                                <div className="paper-page m-2" style={{ width:pageA3().width,height:pageA3().height }}>
                                <div className="dot top-left" style={{ width: widthDot(), height: widthDot(), top: posDot(), left: posDot() }}></div>
                                <div className="dot top-right" style={{ width: widthDot(), height: widthDot(), top: posDot(), right: posDot() }}></div>
                                <div className="dot bottom-left" style={{ width: widthDot(), height: widthDot(), bottom: posDot(), left: posDot() }}></div>
                                <div className="dot bottom-right" style={{ width: widthDot(), height: widthDot(), bottom: posDot(), right: posDot() }}></div>
                                </div>
                            </div> */}
                            </Card>
                        </div>
                        <div className="col-lg-9 col-md-6 col-sm-12 table-responsive">
                            <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>

                                    <AppstoreOutlined className="color-main_2" />

                                    <span className="color-main_2 ml-2  font-size-14">Sl Tờ / SL Tem / Chiếc Khấu</span>
                                </div>

                            </div>} bordered={false}
                                style={{ width: "100%", }}
                                bodyStyle={{
                                    padding: "10px",
                                }}
                                headStyle={{
                                    backgroundColor: Colors.colorgrey,
                                    display: "flex",
                                    minHeight: "20px",
                                    borderRadius: "3px"
                                }}
                            >
                                <div>
                                    <Form
                                        labelCol={{
                                            span: 4,
                                        }}
                                        wrapperCol={{
                                            // span: 14,
                                        }}
                                        layout="vertical"
                                        name="formSortStamps" ref={formPaperAmountAndDiscountRef}
                                        onFinish={submitPaperAmountAndDiscount}
                                    >
                                        <PaperAmountAndDiscount listSortStamps={listSortStamps} formPaperAmountAndDiscountRef={formPaperAmountAndDiscountRef} ListPaperAmountAndDiscount={ListPaperAmountAndDiscount_} setListPaperAmountAndDiscount={setListPaperAmountAndDiscount_} />
                                        <Form.Item >
                                            <div className="flex_  mt-4">

                                                <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>

                                {/* <div className="paper-page-wrap " style={{ width:maxWidth,height:maxHeight }}>
                                <div className="paper-page m-2" style={{ width:pageA3().width,height:pageA3().height }}>
                                <div className="dot top-left" style={{ width: widthDot(), height: widthDot(), top: posDot(), left: posDot() }}></div>
                                <div className="dot top-right" style={{ width: widthDot(), height: widthDot(), top: posDot(), right: posDot() }}></div>
                                <div className="dot bottom-left" style={{ width: widthDot(), height: widthDot(), bottom: posDot(), left: posDot() }}></div>
                                <div className="dot bottom-right" style={{ width: widthDot(), height: widthDot(), bottom: posDot(), right: posDot() }}></div>
                                </div>
                            </div> */}
                            </Card>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 ">
                            <div className="col-lg-12 col-md-12 col-sm-6   p-0 m-0">
                                <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <AppstoreOutlined className="color-main_2" />

                                        <span className="color-main_2 ml-2 font-size-14">Bảng kích thước / loại giấy</span>
                                    </div>

                                </div>} bordered={false}
                                    style={{ width: "100%", }}
                                    bodyStyle={{
                                        padding: "10px",
                                    }}
                                    headStyle={{
                                        backgroundColor: Colors.colorgrey,
                                        display: "flex",
                                        minHeight: "20px",
                                        borderRadius: "3px"
                                    }}
                                >
                                    <div>
                                        <Form
                                            labelCol={{
                                                span: 4,
                                            }}
                                            wrapperCol={{
                                                // span: 14,
                                            }}
                                            layout="vertical"
                                            name="formSortStamps" ref={formPaperTypeRef}
                                            onFinish={submitPaperType}
                                        >
                                            <PaperType formPaperTypeRef={formPaperTypeRef} ListPaperType={ListPaperType} setListPaperType={setListPaperType} />
                                            <Form.Item >
                                                <div className="flex_  mt-4">

                                                    <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>

                                </Card>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-6 p-0 m-0">
                                <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>

                                        <AppstoreOutlined className="color-main_2" />

                                        <span className="color-main_2 ml-2 font-size-14">Bảng giá giấy</span>
                                    </div>

                                </div>} bordered={false}
                                    style={{ width: "100%", }}
                                    bodyStyle={{
                                        padding: "10px",
                                    }}
                                    headStyle={{
                                        backgroundColor: Colors.colorgrey,
                                        display: "flex",
                                        minHeight: "20px",
                                        borderRadius: "3px"
                                    }}
                                >
                                    <div>
                                        <Form
                                            labelCol={{
                                                span: 4,
                                            }}
                                            wrapperCol={{
                                                // span: 14,
                                            }}
                                            layout="vertical"
                                            name="formSortStamps" ref={formPaperPriceListRef}
                                            onFinish={submitPaperPriceList}
                                        >
                                            <PaperPriceList formPaperPriceListRef={formPaperPriceListRef} ListPaperType={ListPaperType} ListPaperPriceList={ListPaperPriceList} setListPaperPriceList={setListPaperPriceList} />
                                            <Form.Item >
                                                <div className="flex_  mt-4">

                                                    <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>

                                </Card>


                            </div>

                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-9 table-responsive p-0">


                            <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>

                                    <AppstoreOutlined className="color-main_2" />

                                    <span className="color-main_2 ml-2 font-size-14">Bảng giá</span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Search
                                        placeholder="Tìm kiếm"
                                        onSearch={onSearch}
                                        style={{
                                            width: 200,
                                        }}
                                    />
                                    <Button onClick={() => onSearch("")}>
                                        <RetweetOutlined />
                                    </Button>
                                    <Button onClick={() => {
                                        setVisiblePrice(true)
                                    }}>
                                        <FileTextOutlined />
                                    </Button>
                                </div>
                            </div>} bordered={false}
                                style={{ width: "100%", }}
                                bodyStyle={{
                                    padding: "10px",
                                }}
                                headStyle={{
                                    backgroundColor: Colors.colorgrey,
                                    display: "flex",
                                    minHeight: "20px",
                                    borderRadius: "3px"
                                }}
                            >
                                <div>

                                    <PriceOnAmountStamp ListPriceOnAnountStamps={dataSearch} />
                                </div>

                            </Card>


                        </div>





                    </div>


                </Card>
            </div>

        </div>

    );
}
export default Index;