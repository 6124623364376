import React, { useEffect, useRef, useState } from 'react';

import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import
{
    SearchOutlined,
    CaretDownOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../../../utils/constant";
import * as ActionsWeb from "../../../../actions/indexWeb";
import * as LINK from "../../../../constants/Links";
const { TextArea } = Input;
const count = 3;
const CODE="SOCIALFACEBOOK"
const Index = (props) =>
{
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isloading, setIsloading] = useState(false);
    const [ListStatusTransport, setListStatusTransport] = useState([]);
    const [record, setRecord] = useState(false);
    const formRef = useRef({})
    const onFinish = (values) =>
    {


      
        setIsloading(true)
        const record_ = { ...record, code: CODE, title: "Cài đặt facebook", type: 2, active: 1, deleted: 0, data: JSON.stringify(values) }

        dispatch(ActionsWeb.StoreSettingsRequest(record_, record_.id ? record_.id : ""))
    }
   
    useEffect(() =>
    {
        if (Web.StoreSettings?.code) {

            try {
                if (Web.StoreSettings.code === 200) {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {
                        message.success(Web.StoreSettings.message)
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {

                        message.error(Web.StoreSettings.message)
                    } else {
                        for (let x in Web.StoreSettings.message) {
                            let mess = Web.StoreSettings.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")

            }
            setIsloading(false)
            dispatch(ActionsWeb.ResetStoreSettings());
        }


        return () =>
        {

        };
    }, [Web.StoreSettings]);
    useEffect(() =>
    {

        if (Web.SettingsShowByCode.data) {
            if (Web.SettingsShowByCode.data.code === CODE) {
                setRecord(Web.SettingsShowByCode.data)
                if (Web.SettingsShowByCode.data.data) {
                const formdata = JSON.parse(Web.SettingsShowByCode.data.data)

               
                formRef.current.setFieldsValue(formdata)
              
            }
            }
        }
        return () =>
        {

        };
    }, [Web.SettingsShowByCode]);
    useEffect(() =>
    {

        dispatch(ActionsWeb.SettingsShowByCodeRequest({ code: CODE }))
        return () =>
        {

        };
    }, []);
    return (
        <div className='dropdown_rim'>
            <Form name="form" className="form" ref={formRef} onFinish={onFinish}
                layout={"horizontal"}
                style={{
                    padding: "0px !important",
                    width: "100%",
                    display: "flex",
                    margin: 0
                }}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form.Item name="idapp" label="ID app facebook" layout={"horizontal"}>
                            <Input placeholder='ID app facebook' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="sdkversion" label="Phiên bản SDK" layout={"horizontal"}>
                            <Input placeholder='Phiên bản SDK' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="idpage" label="ID trang facebook" layout={"horizontal"}>
                           <Input placeholder='ID trang' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="linkpage" label="Link trang facebook" layout={"horizontal"}>
                            <Input placeholder='Link trang facebook' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="codemessenger" label="Mã nhắn tin trên trang" layout={"horizontal"} >
                            <TextArea placeholder='Mã nhắn tin trên trang' rows={4} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    );
};

export default Index;
