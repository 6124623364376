import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";

import Upload from "./Upload";

import {  replaceThous,  getCookie, createSlug } from "../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message, Divider, Space,
    Card, DatePicker, TreeSelect, Typography
} from 'antd';
import
{
    FileImageOutlined,
    UserSwitchOutlined, PlusOutlined
} from '@ant-design/icons';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";


import Editor from "../../../../components/editor/index";
const { TextArea } = Input;

const { Option } = Select;


const defaultTime = moment().subtract(15, 'years');
class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.formRef = new createRef(null);
        this.editor = createRef();
        this.state = {
            meta: {
                ...META
            },
            record: {

                birthday: defaultTime,
                deleted: 0,
                hidden: 0
            },
            StoreNetworkHomeOperator: {},
            ListNetworkHomeOperator: [],
            data: [],
            isloading: false,
            required: {
                avata: false
            },
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
            imgesInfoRemove: [],
            isupload: 0,
            imgesInfores: {
                avata: [],
                detail: [],
                video: []
            },
            addTags: "",
        }


    }

    addItem = (e, type, parent_id) =>
    {

        e.preventDefault();

        const formvalues = this.formRef.current.getFieldsValue();
        console.log(`formvalues`, formvalues);
        if (type === "tags" && formvalues[type]) {
            this.props.StoreTagsRequest({
                "name": formvalues[type],
                "code": "",
                "deleted": "0",
                "hidden": "0",

            })
        }



    }
    setimgesInfo = (type, data) =>
    {


        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],
                    // imgesInfo: {
                    //     ...state.imgesInfo, [type]: data
                    // },

                }
            }, () =>
            {
                this.setState(state =>
                {

                    return {
                        // imgesInfoRemove: [...state.imgesInfoRemove, ...state.imgesInfo[type]],
                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })


            // imageDelete["folder"] = type;

        } else {
            this.setState(state =>
            {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) =>
    {
        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item =>
            {
                return item !== data.name;
            })
            imagesrecord = this.state.record.images.filter(item =>
            {
                return item !== data.name;
            })
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
         

        if (nextProps.Web.StoreNetworkHomeOperator && nextProps.Web.StoreNetworkHomeOperator !== prevState.StoreNetworkHomeOperator) {
            return {
                StoreNetworkHomeOperator: nextProps.Web.StoreNetworkHomeOperator,

            };

        }
      
        if (nextProps.Web.ListNetworkHomeOperator && nextProps.Web.ListNetworkHomeOperator.id !== prevState.record.id) {

            return {
                record: nextProps.Web.ListNetworkHomeOperator,

            };

        }
        if (nextProps.Web.ListNetworkHome && nextProps.Web.ListNetworkHome.data !== prevState.ListMenuHome) {
            console.log(nextProps.Web.ListNetworkHome);
            return {
                ListNetworkHome: nextProps.Web.ListNetworkHome.data,

            };

        }
        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreNetworkHomeOperator !== prevState.StoreNetworkHomeOperator) {
            let { StoreNetworkHomeOperator } = this.state;
            try {
                if (StoreNetworkHomeOperator.code === 200) {
                    if (StoreNetworkHomeOperator.message && typeof StoreNetworkHomeOperator.message === "string") {
                        message.success(StoreNetworkHomeOperator.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreNetworkHomeOperator.message && typeof StoreNetworkHomeOperator.message === "string") {

                        message.error(StoreNetworkHomeOperator.message)
                    } else {
                        for (let x in StoreNetworkHomeOperator.message) {
                            let mess = StoreNetworkHomeOperator.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStoreNetworkHomeOperator()
            this.SetisLoading(false)
        }
        if (this.state.record.id !== prevState.record.id) {
            const { record } = this.state;
            // let avata = [{
            //     uid: -1,
            //     name: record.thumbnail,
            //     status: 'done',
            //     url: LINK.myHostimgServerImg + "/" + record.thumbnail,
            // }];
            // let video = record.video[0] ? [{
            //     uid: -1,
            //     name: record.video[0],
            //     status: 'done',
            //     url: LINK.myHostimgServerVide + "/" + record.video[0],
            // }] : [];
            // let detail = [];
            // record.images.map((item, index) =>
            // {
            //     detail.push({
            //         uid: -1 * (index + 1),
            //         name: item,
            //         status: 'done',
            //         url: LINK.myHostimgServerImg + "/" + item,
            //     })
            // });
            // this.setState(state =>
            // {
            //     return {

            //         imgesInfo: { avata: avata, detail: [], video: [] },

            //     }
            // })
            // this.editor.current.value = record.content
            this.formRef.current.setFieldsValue({ ...record });

        }
     
        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;

            if (parseInt(UploadFileLa.type) === 1) {
                if (UploadFileLa.code !== 200) {

                    message.error("Cập nhật ảnh chính thất bại!");
                    // return;
                }

                this.setState(state =>
                {
                    return {
                        record: { ...state.record, thumbnail: UploadFileLa.data }
                    }
                }, () =>
                {
                    if (this.state.isupload === 1) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }

            this.props.ResetUploadFileLa();
        }
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.NetworkHomeOperatorByIdRequest({ id });
        }


        this.props.SearchNetworkHomeRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, {data:{}})


    }
    data = () =>
    {
        let { ListRegion } = this.state;
        // if (!ListRegion) {
        //     ListRegion = this.props.Web.ListRegion
        // }

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    CheckDataRequired = () =>
    {
        const { imgesInfo, record, dataClassify, classify1, classify2, required } = this.state;

        if (imgesInfo.avata.length <= 0) {
            message.warn("Hình ảnh chính là bắt buộc");
            this.setState(state =>
            {
                return {
                    required: { ...state.required, avata: true }
                }
            })

            return;
        }
        if (!this.editor.current.value || this.editor.current.value === '') {
            message.warn("Nội dung là bắt buộc");
            this.setState(state =>
            {
                return {
                    required: { ...state.required, content: true }
                }
            })

            return;
        }

        return true;

    }
    uploadFile = (values) =>
    {
        const { imgesInfo, } = this.state;


        this.setState(state =>
        {
            return {
                required: {},
                values: values
            }
        })
        if (!this.CheckDataRequired()) {
            return;
        }

        this.SetisLoading(true)
        let isupload = 0;
        // this.SetisLoading(true)
        if (imgesInfo.avata.length > 0) {
            let arravata = imgesInfo.avata.filter(item =>
            {
                return item.originFileObj;
            })
            if (arravata.length > 0) {
                this.props.UploadFileLaRequest(arravata, "img", 1);
                isupload = 1;
            }

        }


        this.setState(state =>
        {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values, deleted: 0 }
        let user = getCookie("user");
        user = JSON.parse(user);
        record_.user_id = user.id;
    
        this.SetisLoading(true)
        console.log('====================================');
        console.log("record_record_", record_, values);
        console.log('====================================');
        // return;
        this.props.StoreNetworkHomeOperatorRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

  
    render()
    {
        let { transNetworkHomeOperator, active_tran } = this.state;


        return (

            <div className="table_w post_edit" >

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Nhà mạng</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                        // maxHeight: "90vh",
                        // overflowY: "scroll"
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="vertical"
                        name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                    >

                        <div className=" section_img">


                        <Form.Item label="Nhà mạng" name={"network_home_id"}

                                rules={[
                                    {
                                        required: true,
                                        message: "Nhà mạng là bắt buộc"
                                    },
                                ]}>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                  

                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Nhà mạng"
                                    // allowClear
                                    options={(this.state.ListNetworkHome || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                    onChange={(e) =>
                                        {
                                            const nw=this.state.ListNetworkHome.filter((item,index)=>{
                                                return item.id==e;
                                            })
                                          
                                            this.formRef.current.setFieldsValue({ name:nw?.[0]?.name ? nw?.[0]?.name:"" })
                                            this.formRef.current.setFieldsValue({ code:nw?.[0]?.name ?createSlug(nw?.[0]?.name):"" })
                                        }} 
                                />

                                </Form.Item>
                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên là bắt buộc"
                                },
                            ]} onChange={(e) =>
                            {
                                this.formRef.current.setFieldsValue({ code: createSlug(e.target.value) })
                            }} >
                                <Input placeholder="Tên" />
                            </Form.Item>

                            <Form.Item label="Code" name="code">
                                <Input placeholder="code" />
                            </Form.Item>
                            <Form.Item label="Đầu số" name="operator" rules={[
                                {
                                    required: true,
                                    message: "Đầu số là bắt buộc"
                                },
                            ]} >
                                <Input placeholder="Đầu số" />
                            </Form.Item>
                            <Form.Item label="Ghi chú" name="note">
                                <TextArea placeholder="Ghi chú" style={{ height: "100px" }} />
                            </Form.Item>

                           

                        </div>

                        <Form.Item >
                            <div className="flex_">
                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
        SearchNetworkHomeRequest: (body, data) =>
            {
                dispatch(ActionsWeb.SearchNetworkHomeRequest(body, data));
            },
        StoreNetworkHomeOperatorRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreNetworkHomeOperatorRequest(data, id));
        },
        ResetStoreNetworkHomeOperator: () =>
        {
            dispatch(ActionsWeb.ResetStoreNetworkHomeOperator());
        },
        ListNetworkHomeOperatorRequest: (body) =>
        {
            dispatch(ActionsWeb.ListNetworkHomeOperatorRequest(body));
        },

        NetworkHomeOperatorByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.NetworkHomeOperatorByIdRequest(body));
        },
        StoreTagsRequest: (body) =>
        {
            dispatch(ActionsWeb.StoreTagsRequest(body));
        },
        ResetStoreTags: () =>
        {
            dispatch(ActionsWeb.ResetStoreTags());
        },
        ListTagsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListTagsRequest(body));
        },
        ////////////////
        ListCategoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
        SearchMenuHomeRequest: (body,data) =>
        {
            dispatch(ActionsWeb.SearchMenuHomeRequest(body,data));
        },
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
        {
            dispatch(ActionsWeb.ResetUploadFileLa());
        }, 
        SearchCategoryRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchCategoryRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);