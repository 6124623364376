import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, isDateValid, CutString, checkScreenSize, removeOrAndFromEndOfString } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card, DatePicker, Alert, Checkbox, Radio, InputNumber, Modal, List, Skeleton, Avatar, Drawer
} from 'antd';
import
{
    UserSwitchOutlined, DeleteOutlined, PlusCircleOutlined
} from '@ant-design/icons';

import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";
import ListCustomers from "./ListCustomers";
import Customers from "./../../../../components/customer/index";
import Draggable from 'react-draggable';
const { TextArea } = Input;

const FormsbirtdayCus = (props) =>
{
    return <div >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item label={"Ngày sinh từ"}
                name={"birthdatefrom"}
                style={{ width: "48%" }}
            >
                <DatePicker style={{ width: "100%" }} onChange={(e) => props.onchange(e, "birthdatefrom")} />

            </Form.Item>
            <Form.Item label={"Ngày sinh đến"}
                name={"birthdateto"}
                style={{ width: "48%" }}
            >
                <DatePicker style={{ width: "100%" }} onChange={(e) => props.onchange(e, "birthdateto")} />

            </Form.Item>
        </div>
        <Form.Item name={"oabirthday"} initialValue={1}>
            <Radio.Group onChange={(e) => props.onchange(e, "oabirthday")} >
                <Radio.Button value={1}>OR</Radio.Button>
                <Radio.Button value={2}>AND</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item label={"Độ tuổi từ"}
                name={"agefrom"}
                style={{ width: "48%" }}
            >
                <InputNumber style={{ width: "100%" }} onChange={(e) => props.onchange(e, "agefrom")} />

            </Form.Item>

            <Form.Item label={"Độ tuổi đến"}
                name={"ageto"}
                style={{ width: "48%" }}
            >
                <InputNumber style={{ width: "100%" }} onChange={(e) => props.onchange(e, "ageto")} />
            </Form.Item>
        </div>
    </div>

}

const FormsbirtdayInmonthCus = (props) =>
{
    const options = Array.from({ length: 12 }, (_, index) => ({
        value: (index + 1).toString(),
        label: (index + 1).toString(),
    }));
    return <div >
        <Form.Item label={"Sinh nhật tháng"}
            name={"birthdatetmonth"}
            
        >
            <Select
                defaultValue={"1"}
                placeholder="Select a person"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options}
                onChange={(e) => props.onchange(e, "birthdatetmonth")} 
            />
        </Form.Item>
    </div>

}

const FormsCreatedatCus = (props) =>
{
    return <div >

        <Form.Item label={"Đăng ký từ ngày"}
            name={"createdfrom"}

        >
            <DatePicker style={{ width: "100%" }} onChange={(e) => props.onchange(e, "createdfrom")} />

        </Form.Item>
        <Form.Item name={"oacreated"} initialValue={1}>
            <Radio.Group onChange={(e) => props.onchange(e, "oacreated")} >
                <Radio.Button value={1}>OR</Radio.Button>
                <Radio.Button value={2}>AND</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item label={"Thời gian tham gia"}
            name={"amountday"}

        >
            <InputNumber addonAfter={"Ngày"} style={{ width: "100%" }} onChange={(e) => props.onchange(e, "amountday")} />

        </Form.Item>


    </div>

}

const FormsSexCus = (props) =>
{
    return <div >

        <Form.Item name={"sex"} initialValue={1} onChange={(e) => props.onchange(e, "sex")}>
            <Radio.Group>
                <Radio.Button value={1}>Nam</Radio.Button>
                <Radio.Button value={2}>Nữ</Radio.Button>
            </Radio.Group>
        </Form.Item>


    </div>

}


const FormsOrderAmount = (props) =>
{
    return <div >

        <Form.Item name={"orderamount"} label={"Tối thiểu"} onChange={(e) => props.onchange(e, "orderamount")}>
            <InputNumber addonAfter={"đơn"} />
        </Form.Item>


    </div>

}
const ChoseCustomers = (props) =>
{

    const { checklistCustomer, onLoadMore, hiddenLoadmore, isloading, deleteSelected } = props;
    return <div >
        <List
            className="demo-loadmore-list"
            loading={isloading}
            itemLayout="horizontal"
            loadMore={!hiddenLoadmore && <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={() => onLoadMore(true)}>loading more</Button>
            </div>}
            dataSource={checklistCustomer}
            renderItem={(item) => (
                <List.Item
                    actions={[<button type="button" className="reset_btn btn_delete btn__" key="list-loadmore-more" onClick={() => deleteSelected(item)}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            title={<a href="">{item.name}</a>}
                            description={CutString(item.name, 100, false)}
                        />
                    </Skeleton>
                </List.Item>
            )}
        />

    </div>

}



class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.draggleRef = new createRef(null);
        this.formRef = new createRef(null);
        this.CheckShowCustomer = new createRef(null)
        this.state = {
            meta: {
                ...META
            },
            record: {

                // birthday: defaultTime,
                deleted: 0,
                hidden: 0
            },
            StoreVouchersGroup: {},
            ListVouchersGroup: [],
            data: [],
            isloading: false,
            listOtherSet: [

                {
                    value: "birthday",
                    label: 'ngày sinh khách hàng',
                },
                {
                    value: "birthdayInMonth",
                    label: 'sinh nhật tháng',
                },
                {
                    value: "created",
                    label: 'Thời gian đăng ký',
                },
                {
                    value: "sex",
                    label: 'Giới tính',
                },
                {
                    value: "historyOrder",
                    label: 'Lịch sử mua hàng',
                },
                {
                    value: "choseCus",
                    label: 'Chọn khách hàng',
                },

            ],
            // listFormsOrtherSet:{
            //     1:[
            //         { label: "Ngày sinh từ", name: "fromdate", input: "date", onchange: () => { }, rules: [], disabled: false, },
            //         { label: "Ngày sinh đến", name: "todate", input: "date", onchange: () => { }, rules: [], disabled: false },
            //         { label: "Độ tuổi từ", name: "agesfrom", input: "text", onchange: () => { }, rules: [], disabled: false, style: { width: "200px" } },
            //         { label: "Độ tuổi đến", name: "agesto", input: "text", onchange: () => { }, rules: [], disabled: false, style: { width: "200px" } }
            //     ]
            // },
            // listFormsOrtherSet: { 1: <FormsbirtdayCus />, 2: <FormsCreatedatCus />, 3: <FormsSexCus />, 4: <FormsOrderAmount />, 5: <ChoseCustomers /> },
            listFormsOrtherSet: {
                birthday: {
                    form: <FormsbirtdayCus />,
                    data:{
                        birthdatefrom:{
                            sql: " customers.birthday>='{var}'",
                        },
                        birthdateto:{
                            sql: " customers.birthday>='{var}'",
                            check: {1:["birthdatefrom"]},
                            sql1: " and customers.birthday>='{var}'",
                        },
                        oabirthday: {
                            check: { 1: ["birthdatefrom", "birthdateto"], 2: ["agefrom","ageto"]},
                            sql:{
                                1: "",
                                2: ""
                            },
                            sql1: {
                                1: " or",
                                2: " and"
                            }

                        },
                        agefrom: {
                            sql:  " TIMESTAMPDIFF(YEAR, customers.birthday, CURDATE())>='{var}'",
                        },
                        ageto: {
                            check: {1:["agefrom"]},
                            sql: "  TIMESTAMPDIFF(YEAR, customers.birthday, CURDATE())<='{var}'",
                            sql1: " and  TIMESTAMPDIFF(YEAR, customers.birthday, CURDATE())<='{var}'",
                        },
                       
                    },
                    
                },
                birthdayInMonth: {
                    form: <FormsbirtdayInmonthCus />,
                    data: {
                        birthdatetmonth: {
                            sql: " Month(customers.birthday)='{var}'"
                        },

                    },
                },
                created: {
                    form: <FormsCreatedatCus />,
                    data: {
                        createdfrom: {
                            sql: " customers.created_at>='{var}'"
                        },
                        oacreated: {
                            check: { 1: ["createdfrom"], 2: ["amountday"] },
                            sql: {
                                1: "",
                                2: ""
                            },
                            sql1: {
                                1: " or",
                                2: " and"
                            }
                        },
                        amountday: {
                            sql:" TIMESTAMPDIFF(DAY, customers.created_at, CURDATE())>='{var}'"
                        },
                        
                    },
                },
               
                sex: {
                    form: <FormsSexCus />,
                    data: {
                        sex: {
                            sql:" customers.sex='{var}'"
                        },
                     
                    },
                },
                historyOrder: {
                    form: <FormsOrderAmount />,
                    data: {
                        orderamount:{
                            sql:" COUNT(orders.id)>='{var}'"
                        },
                    },
                    ishaving:true,
                },
                choseCus: { form: <ChoseCustomers /> },
            },
            vourcherSET: {
                voucherSetting: 1,
                voucherSettingProducts: 1,
                ortherSetting: false,
                ortherSettingCheck: []
            },
            visible: false,
            confirmLoading: false,
            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            disabled: true,
            checklistCustomer: [],
            isShowModaled: false,
            getCondisCustomer: null,
            isresetList: false
        }


    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.getCondisCustomer && nextProps.Web.getCondisCustomer !== prevState.getCondisCustomer) {

            return {
                getCondisCustomer: nextProps.Web.getCondisCustomer,

            };

        }
        // if (nextProps.match.params.type && nextProps.match.params.type !== prevState.type) {

        //     return {
        //         type: nextProps.match.params.type,

        //     };

        // }
        if (nextProps.Web.StoreVouchersGroup && nextProps.Web.StoreVouchersGroup !== prevState.StoreVouchersGroup) {
            return {
                StoreVouchersGroup: nextProps.Web.StoreVouchersGroup,

            };

        }


        if (nextProps.Web.ListVouchersGroup && nextProps.Web.ListVouchersGroup.id !== prevState.record.id) {

            return {
                record: nextProps.Web.ListVouchersGroup,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreVouchersGroup !== prevState.StoreVouchersGroup) {
            let { StoreVouchersGroup } = this.state;
           
            try {
                if (StoreVouchersGroup.code === 200) {
                    if (StoreVouchersGroup.message && typeof StoreVouchersGroup.message === "string") {
                        message.success(StoreVouchersGroup.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreVouchersGroup.message && typeof StoreVouchersGroup.message === "string") {

                        message.error(StoreVouchersGroup.message)
                    } else {
                        for (let x in StoreVouchersGroup.message) {
                            let mess = StoreVouchersGroup.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStoreVouchersGroup()
            this.SetisLoading(false)
        }
        if (this.state.record.id !== prevState.record.id) {

            const condis_ = JSON.parse(this.state.record.condis)
          
            const condis = { ...condis_ }
            if (condis.hasOwnProperty("from_at")) {
                delete condis["from_at"];
            }
            if (condis.hasOwnProperty("note")) {
                delete condis["note"];
            }
            if (condis.hasOwnProperty("to_at")) {
                delete condis["to_at"];
            }
            if (condis.hasOwnProperty("value")) {
                delete condis["value"];
            }

            if (this.state.record.from_at) {

                this.state.record.from_at = moment(this.state.record.from_at, "YYYY-MM-DD");
                this.state.record.to_at = moment(this.state.record.to_at, "YYYY-MM-DD");
            }

            if (condis.createdfrom) {
                condis.createdfrom = moment(condis.createdfrom, "YYYY-MM-DD");
            }
            if (condis.birthdatefrom) {
                condis.birthdatefrom = moment(condis.birthdatefrom, "YYYY-MM-DD");
            }
            if (condis.birthdateto) {
                condis.birthdateto = moment(condis.birthdateto, "YYYY-MM-DD");
            }
            this.formRef.current.setFieldsValue({
                ...this.state.record, ...condis,
                voucher_setting: condis.voucher_setting,
                // orderValue: condis.orderValue,
                // maxVoucher: condis.maxVoucher,
            });
            this.setState(state =>
            {
                return {
                    vourcherSET: {
                        ...state.vourcherSET, voucherSetting: condis.voucher_setting, ortherSettingCheck: condis.ortherSettingCheck, ortherSetting: condis.ortherSettingCheck ? true : false
                    },

                }
            })
        }

        if (this.state.checklistCustomer !== prevState.checklistCustomer) {
            if (this.state.checklistCustomer.length <= 0) {
                this.setState(state =>
                {
                    return {
                        vourcherSET: {
                            ...state.vourcherSET, ortherSettingCheck: state.vourcherSET.ortherSettingCheck.filter(item =>
                            {
                                return item !== 5
                            })
                        }
                    }
                })
            }

            this.formRef.current.setFieldsValue({ ...this.state.record });

        }


    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.VoucherGroupByIdRequest({ id });
        }


    }
    data = () =>
    {
        let { ListRegion } = this.state;


        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber = false) =>
    {

        let target = e.target;
        let value = e;
        if (target) {
            value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = type;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: (1 * value) }
                }
            }, () =>
            {

            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            }, () =>
            {

            })
        }

        if (type === "ortherSettingCheck" && e.length <= 0) {
            this.formRef.current.setFieldsValue({
                birthdatefrom: "",
                birthdateto: "",
                oadate: 1,
                agefrom: "",
                ageto: "",
                createdfrom: "",
                oatime: 1,
                amountday: "",
                sex: 1,
                orderamount: "",
            })
            this.setState(state =>
            {
                return {
                    sqlgetCus: "",
                    checklistCustomer: []
                }
            })
        }

    }
    createSql = (e, type) =>
    {
      
        let sql = "";
        let sqlHaving = "";
       
        const formValues = this.formRef.current.getFieldsValue();
        const { listFormsOrtherSet }=this.state;

        const ortherSettingCheck = formValues.ortherSettingCheck;
        // const objcheckSql={};
        let i=0;
        for (let x in ortherSettingCheck) {
            const element = ortherSettingCheck[x];
            // objcheckSql[x]={}
            
            if (listFormsOrtherSet[element]){
                var ishaving = listFormsOrtherSet[element]?.ishaving
                if (i > 0) {
                    if (formValues["oa" + i] && formValues["oa" + i] !== "undefined" && formValues["oa" + i] !== "") {
                       
                        if (ishaving){
                            if (sqlHaving){
                                sqlHaving = removeOrAndFromEndOfString(sqlHaving)
                                sqlHaving += formValues["oa" + i] === 1 ? " or" : " and";
                            }
                           
                        }else{
                            if (sql){
                                sql = removeOrAndFromEndOfString(sql)
                                sql += formValues["oa" + i] === 1 ? " or" : " and";
                            }
                           
                        }
                    }
                }
                if (listFormsOrtherSet[element].data){
                    for (let y in listFormsOrtherSet[element].data){
                        const el = listFormsOrtherSet[element].data[y]
                       
                        if (formValues[y] && formValues[y] !== "undefined" && formValues[y] !==""){
                            var value_ = formValues[y];
                          
                            // objcheckSql[x][y]=1
                           
                            if (typeof value_==="number"){

                            } else if (typeof value_ === "string") {

                            } else if (typeof value_ === "string") {

                            } else  if (isDateValid(value_)){
                                value_ = value_.format("YYYY-MM-DD")
                              
                            }
                            // sql+="\n"
                            if (el.sql){
                                
                                var sql_ = el.sql;
                              
                                if (el.check && Object.values(el.check).length>0){
                                    var c=false;
                                    var countcheck=0;
                                    for (let k in el.check){
                                        const c_ = el.check[k]
                                        let ctmp=false;
                                        for (let g in c_){
                                            if (formValues[c_?.[g]] && formValues[c_?.[g]] !== "undefined" && formValues[c_?.[g]] !== "") {
                                                ctmp=true
                                            }
                                        }
                                        if (ctmp){
                                            countcheck++;
                                        }
                                    }
                                   
                                    if (countcheck === Object.values(el.check).length){
                                        c=true
                                    }
                                  
                                    if (c){
                                        sql_ = el.sql1;
                                    }
                                   
                                }
                                  
                                if (typeof sql_==="object"){
                                   
                                    sql_ = sql_[formValues[y]];
                                    if (ishaving){
                                        sqlHaving += sql_
                                    }else{
                                        sql += sql_;
                                    }
                                   
                                }else{
                                    sql_ = sql_.replace(/{var}/g, value_);
                                    if (ishaving) {
                                        sqlHaving += sql_
                                    } else {
                                        sql += sql_;
                                    }
                                   
                                }
                            
                               
                             
                            } 
                          

                           
                        }
                       
                    }
                }
       
              
            }
            i++;
        }
        sql = removeOrAndFromEndOfString(sql)
        sqlHaving = removeOrAndFromEndOfString(sqlHaving)
        // console.log('====================================');
        // console.log(sql);
        // console.log('===================================='); 
        // return;
        if (sql || sqlHaving) {
            this.setState(state =>
            {
                return {
                    sqlgetCus: { sql: sql, sqlhaving: sqlHaving }
                }
            })
            this.props.getCondisCustomerRequest({ sql: sql, sqlhaving: sqlHaving })

        } else {
            this.setState(state =>
            {
                return {
                    getCondisCustomer: null
                }
            })
        }
       
    }
    createSql1 = (e, type) =>
    {
        let sql = "";
        let sql1 = "";
        let oadate = "";
        let sql2 = "";
        let oa1 = "";
        let sql3 = "";
        let oatime = "";
        let oa2 = "";
        let sql4 = "";

        let oa3 = "";
        let sql5 = "";
        const formValues = this.formRef.current.getFieldsValue();
        const { listFormsOrtherSet } = this.state;

        const ortherSettingCheck = formValues.ortherSettingCheck;
        for (let i = 1; i < ortherSettingCheck.length; i++) {
            const element = ortherSettingCheck[i];
            if (listFormsOrtherSet[i]) {

            }
            console.log('====================================');
            console.log(listFormsOrtherSet[i].data);
            console.log('====================================');
        }

        if (formValues.birthdatefrom) {
            let date = formValues?.birthdatefrom?.format("YYYY-MM-DD")
            if (date) { sql1 += " customers.birthday>='" + date + "'"; }

        }
        if (formValues.birthdateto) {
            let date = formValues?.birthdateto?.format("YYYY-MM-DD")
            if (formValues.birthdatefrom) {
                if (date) { sql1 += " and customers.birthday<='" + date + "'"; }
            } else {
                if (date) { sql1 += " customers.birthday<='" + date + "'"; }
            }


        }

        if ((formValues.birthdatefrom || formValues.birthdateto) && (formValues.agefrom || formValues.ageto)) {
            if (formValues.oadate === 1) {
                oadate = " or ";
            }
            if (formValues.oadate === 2) {
                oadate = " and ";
            }
        }

        if (formValues.agefrom) {
            sql2 += " TIMESTAMPDIFF(YEAR, customers.birthday, CURDATE())>='" + formValues.agefrom + "'";
        }
        if (formValues.ageto) {
            if (formValues.agefrom) {
                sql2 += " and TIMESTAMPDIFF(YEAR, customers.birthday, CURDATE())<='" + formValues.ageto + "'";
            } else {
                sql2 += " TIMESTAMPDIFF(YEAR, customers.birthday, CURDATE())<='" + formValues.ageto + "'";
            }

        }

        if (sql1 || sql2) {
            if (formValues.oa1 === 1) {
                oa1 = " or ";
            }
            if (formValues.oa1 === 2) {
                oa1 = " and ";
            }
        }

        if (formValues.createdfrom) {
            let date = formValues?.createdfrom?.format("YYYY-MM-DD")
            if (date) { sql3 += " customers.created_at>='" + date + "'"; }

        }


        if (formValues.createdfrom && formValues.amountday) {
            if (formValues.oatime === 1) {
                oatime = " or ";
            }
            if (formValues.oatime === 2) {
                oatime = " and ";
            }
        }
        if (formValues.amountday) {
            let date = formValues?.amountday;
            if (formValues.createdfrom) {
                if (date) { sql3 += oatime + " TIMESTAMPDIFF(DAY, customers.created_at, CURDATE())>='" + date + "'"; }
            } else {
                if (date) { sql3 += " TIMESTAMPDIFF(DAY, customers.created_at, CURDATE())>='" + date + "'"; }
            }


        }

        if (formValues.sex) {
            sql4 += " customers.sex='" + formValues.sex + "'";

        }


        if (formValues.orderamount) {
            sql5 += " COUNT(orders.id)>=" + formValues.orderamount;

        }

        if (sql1) {
            sql += "(" + sql1 + ")";
        }
        if (sql2) {
            sql += oadate + "(" + sql2 + ")"
        }
        if (sql3) {
            sql += oa1 + "(" + sql3 + ")"
        }
        if (sql) {
            if (formValues.oa2 === 1) {
                oa2 = " or ";
            }
            if (formValues.oa2 === 2) {
                oa2 = " and ";
            }
        }
        if (sql4) {
            sql += oa2 + "(" + sql4 + ")"
        }
        console.log('====================================');
        console.log(sql, formValues);
        console.log('====================================');
        return;
        if (sql) {
            this.setState(state =>
            {
                return {
                    sqlgetCus: { sql: sql, sqlhaving: sql5 }
                }
            })
            this.props.getCondisCustomerRequest({ sql: sql, sqlhaving: sql5 })

        } else {
            this.setState(state =>
            {
                return {
                    getCondisCustomer: null
                }
            })
        }
    }
    onFinishForm = (values) =>
    {

        if (values.from_at) {
            values.from_at = values.from_at.format("YYYY-MM-DD")

        }
        if (values.to_at) {
            values.to_at = values.to_at.format("YYYY-MM-DD")
        }

        const { record, checklistCustomer, sqlgetCus } = this.state;

        const customerID = checklistCustomer.map((item, index) =>
        {
            return item.id
        });

        const record_ = { ...record, ...values, customerID: customerID, deleted: 0, status: 0, sqlgetCus: sqlgetCus }
        record_.condis = JSON.stringify(values);
        // console.log('====================================');
        // console.log(record_);
        // console.log('====================================');
        // return
        this.SetisLoading(true)
        this.props.StoreVouchersGroupRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    handleChangeProvince = (e) =>
    {
        let province = this.state.ListProvinces.filter(item =>
        {
            return item.id === (1 * e);
        })
        this.setState(state =>
        {
            return {
                Province_id: e,
                record: { ...state.record, province: province?.[0]?.name }
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });
    }

    handleChangeDistrict = (e) =>
    {
        let district = this.state.ListDistricts.filter(item =>
        {
            return item.id === (1 * e);
        })
        this.setState(state =>
        {
            return {
                District_id: e,
                record: { ...state.record, district: district?.[0]?.name }
            }
        })
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id, districtid: e
        });
    }
    handleChangeWar = (e) =>
    {
        let war = this.state.ListWars.filter(item =>
        {
            return item.id === (1 * e);
        })
        this.setState(state =>
        {
            return {
                War_id: e,
                record: { ...state.record, war: war?.[0]?.name }
            }
        })

    }

    renderOrtherSetting = (value) =>
    {


        let forms_ = this.state.listFormsOrtherSet[value];
        let formsShow = [];
        if (forms_) {

            forms_.map((item, index) =>
            {
                if (item.input === "checkbox") {
                    formsShow.push(<Form.Item label={item.label}
                        name={item.name}

                        disabled={item.disabled}
                        key={index}
                    >
                        <Checkbox onChange={item.onchage}>{item.label}</Checkbox>;

                    </Form.Item>)
                } else if (item.input === "select") {
                    formsShow.push(<Form.Item label={item.label}
                        name={item.name}
                        rules={item.rules}
                        disabled={item.disabled}
                        key={index}

                    >
                        <Select
                            onChange={item.onchage}
                            options={item.data}
                            placeholder={item.label}
                        />

                    </Form.Item>)

                } else if (item.input === "text") {

                    formsShow.push(<Form.Item label={item.label}
                        name={item.name}
                        rules={item.rules}
                        disabled={item.disabled}
                        key={index}
                    >
                        <Input placeholder={item.label} onChange={item.onchage} />
                    </Form.Item>)

                }
                else if (item.input === "date") {

                    formsShow.push(<Form.Item label={item.label}
                        name={item.name}
                        rules={item.rules}
                        disabled={item.disabled}
                        key={index}
                    >
                        <DatePicker onChange={item.onchage} />
                    </Form.Item>)

                }


            })
        }
        return formsShow;
    }

    resetChekCus = () =>
    {
        if (this.state.checklistCustomer.length <= 0) {

            let checkSettingsTmp = this.state.vourcherSET.ortherSettingCheck.filter(item =>
            {
                return item !== 5
            })

            this.setState(state =>
            {
                return {
                    resetChecklist: true,
                    vourcherSET: { ...state.vourcherSET, ortherSettingCheck: checkSettingsTmp }
                }
            })
        } else {
            this.setState(state =>
            {
                return {
                    resetChecklist: true,

                }
            })
        }

    }
    setOderdetailsMutilpe = (type, newValue) =>
    {

        this.getChekCus(newValue)

    }
    setCustomerMutilpe = (type, e) =>
    {

        if (type === 2) {
            this.getChekCus([...this.state.checklistCustomer, e])
        } else {
            const newlistCustomer = this.state.checklistCustomer.filter(item =>
            {
                return item.id === e.id
            })
            this.getChekCus(newlistCustomer)
        }

    }
    getChekCus = (checklist) =>
    {
        this.setState(state =>
        {
            return {
                checklistCustomer: checklist
            }
        })

    }

    deleteSelected = (el) =>
    {
        const { checklistCustomer } = this.state;

        const newList = checklistCustomer.filter(item =>
        {
            return item.id !== el.id
        })
        this.setState(state =>
        {
            return {
                checklistCustomer: newList
            }
        })
    }
    render()
    {

        return (

            <div className="table_w vouchers" >


                <Drawer
                    title={<div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6"><span>Chọn Khách hàng</span></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}><Link to={LINK.WEB_CUSTOMERS_EDIT + "/add"}> <Button type="primary" size="small"><PlusCircleOutlined /></Button></Link></div>
                    </div>} placement="right"
                    onClose={() => { this.setVisible(false) }}
                    visible={this.state.visible}
                    size={checkScreenSize().width < 600 ? "default" : "large"}
                >
                    <Customers isListShow={false} setCustomerMutilpe={this.setCustomerMutilpe}
                        setOderdetailsMutilpe={this.setOderdetailsMutilpe} isgroup={false} isresetList={this.state.isresetList} />
                </Drawer>
                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Vouchers</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",

                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form

                        layout="horizontal"
                        name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                    >

                        <div className="row">
                            <div className="item_form left_ col-lg-6  col-md-6  col-sm-12">

                                <Form.Item label="Giá trị (Nhập nhỏ hơn 100 tính %, lớn hơn 100 tính VND)" name="value" rules={[
                                    {
                                        required: true,
                                        message: "Giá trị là bắt buộc"
                                    },
                                ]}>
                                    <Input placeholder="Giá trị" />
                                </Form.Item>
                                <Form.Item label="Từ ngày" name="from_at" rules={[
                                    {
                                        required: true,
                                        message: "Ngày bắt đầu hiệu lực là bắt buộc"
                                    },
                                ]} >
                                    <DatePicker placeholder="Từ ngày" />
                                </Form.Item>

                                <Form.Item label="đến ngày" name="to_at" >
                                    <DatePicker placeholder="Đến ngày" />
                                </Form.Item>
                                <Form.Item label="Số lượng" name="amount" >
                                    <InputNumber placeholder="Số lượng" />
                                </Form.Item>
                                <Form.Item label="Ghi chú" name="note" >
                                    <TextArea style={{ height: "100px" }} placeholder="Ghi chú" />
                                </Form.Item>

                                <div style={{ position: "sticky", top: 0 }}>
                                    {this.state.getCondisCustomer && <span style={{ color: Colors.colortext, fontWeight: "bold" }}>tìm thấy {this.state.getCondisCustomer.data.c ? this.state.getCondisCustomer.data.c : 0} khách hàng thỏa mãn điều kiện</span>}
                                </div>
                            </div>

                            <div className="item_form right_ col-lg-6  col-md-6  col-sm-12">

                                <Alert message="Thiết lập điều kiện giảm giá trên đơn hàng" type="success" />
                                <Form.Item name="voucher_setting" rules={[
                                    {
                                        required: true,
                                        message: "Thiết lập giảm giá là bắt buộc"
                                    },
                                ]} style={{ marginTop: "10px" }} initialValue={this.state.vourcherSET?.voucherSetting}>
                                    <Radio.Group onChange={(e) =>
                                    {

                                        this.onChangetext(e, "voucherSetting", "vourcherSET", false);
                                    }} >
                                        <Radio.Button value={1}>Không điều kiện</Radio.Button>
                                        <Radio.Button value={2}>Giảm giá trên đơn hàng</Radio.Button>
                                        <Radio.Button value={3}>Giảm giá sản phẩm trong đơn hàng</Radio.Button>
                                        <Radio.Button value={4}>Giảm giá vận chuyển</Radio.Button>

                                    </Radio.Group>

                                </Form.Item>
                                {this.state.vourcherSET?.voucherSetting === 2 && <div className="fade_">
                                    <Form.Item label="Giá trị tối thiểu" name="orderValue" rules={[
                                        {
                                            required: true,
                                            message: "Giá trị tối thiểu là bắt buộc"
                                        },
                                    ]} style={{ marginTop: "10px" }}>
                                        <Input placeholder="giá trị đơn hàng tối thiểu" />

                                    </Form.Item>
                                    <Form.Item label="Giảm giá tối đa" name="maxVoucher" rules={[
                                        {
                                            required: true,
                                            message: "Giảm giá tối đa là bắt buộc"
                                        },
                                    ]} style={{ marginTop: "10px" }}>
                                        <Input placeholder="giá trị đơn hàng tối thiểu" />

                                    </Form.Item>

                                </div>}
                                {this.state.vourcherSET?.voucherSetting === 3 && <div className="fade_">
                                    <Form.Item name="voucher_setting_products" rules={[
                                        {
                                            required: true,
                                            message: "Thiết lập giảm giá sản phẩm là bắt buộc"
                                        },
                                    ]} style={{ marginTop: "10px" }} initialValue={1}>
                                        <Radio.Group onChange={(e) =>
                                        {
                                            this.onChangetext(e, "voucherSettingProducts", "vourcherSET", false);
                                        }} defaultValue={this.state.vourcherSET?.voucherSettingProducts}>
                                            <Radio.Button value={1}>Sản phẩm có giá thấp nhất</Radio.Button>
                                            <Radio.Button value={2}>Sản phẩm có giá cao nhất</Radio.Button>
                                            <Radio.Button value={3}>Sản phẩm có giá thấp thứ n</Radio.Button>
                                            <Radio.Button value={4}>Sản phẩm có giá cao thứ n</Radio.Button>
                                        </Radio.Group>

                                    </Form.Item>
                                    {this.state.vourcherSET?.voucherSettingProducts >= 3 && <div className="fade_">
                                        <Form.Item name="voucher_setting_products_pos" label={`Vị trí giá ${this.state.vourcherSET?.voucherSettingProducts === 3 ? "thấp" : "cao"}`} rules={[
                                            {
                                                required: true,
                                                message: "Thứ tự giá sản phẩm là bắt buộc"
                                            },
                                        ]} style={{ marginTop: "10px" }}>
                                            <InputNumber placeholder="1" />
                                        </Form.Item>
                                    </div>
                                    }

                                </div>}

                                <Button htmlType="button" onClick={(e) =>
                                {
                                    this.setState(state =>
                                    {
                                        return {
                                            vourcherSET: { ...state.vourcherSET, ortherSetting: !state.vourcherSET.ortherSetting }
                                        }
                                    })
                                }}>Điều khiện khác</Button>

                                {this.state.vourcherSET?.ortherSetting && <div className="fade_" style={{ marginTop: "10px" }}>
                                    <Alert message="Thiết lập điều kiện khách hàng" type="success" />
                                    <Form.Item style={{ marginTop: "10px" }} name={"ortherSettingCheck"} >
                                        <Checkbox.Group

                                            value={this.state.vourcherSET?.ortherSettingCheck}
                                            onChange={(e) =>
                                            {

                                                this.onChangetext(e, "ortherSettingCheck", "vourcherSET", false);
                                            }}>

                                            {this.state.listOtherSet.map((option, index) =>
                                            {

                                                if (option.value === 5) {

                                                    return <Checkbox
                                                        key={option.value}
                                                        value={option.value}
                                                        onChange={(e) =>
                                                        {
                                                            if (e.target.checked === false) {
                                                                this.setState(state =>
                                                                {
                                                                    return {
                                                                        checklistCustomer: [],
                                                                        isresetList: true
                                                                    }
                                                                }, () =>
                                                                {
                                                                    this.setState(state =>
                                                                    {
                                                                        return {

                                                                            isresetList: false
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                            this.setVisible(e.target.checked)
                                                        }}
                                                        ref={this.CheckShowCustomer}
                                                    >
                                                        {option.label}
                                                    </Checkbox>
                                                } else {
                                                    return <Checkbox
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Checkbox>
                                                }

                                            })}
                                        </Checkbox.Group>

                                    </Form.Item>
                                </div>}
                                <div style={{ width: "100%" }}>
                                    {this.state.vourcherSET?.ortherSettingCheck && this.state.vourcherSET?.ortherSettingCheck.map((item, index) =>
                                    {
                                        let nameSet = this.state.listOtherSet.filter(el =>
                                        {

                                            return (1 * el.value) === (1 * item)
                                        })
                                        const Compons = this.state.listFormsOrtherSet[item].form;
                                        return <div key={index} style={{ width: "100%" }}>
                                            {index > 0 && index < this.state.vourcherSET?.ortherSettingCheck.length && <Form.Item name={"oa" + index} initialValue={1} >
                                                <Radio.Group onChange={(e) =>
                                                {
                                                    this.onChangetext(e, "voucherSettingProducts", "vourcherSET", false);
                                                    this.createSql(e, "voucherSettingProducts");
                                                }} >
                                                    <Radio.Button value={1}>OR</Radio.Button>
                                                    <Radio.Button value={2}>AND</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>}
                                            <label className="title_label">{nameSet[0]?.label}</label>

                                            {React.cloneElement(Compons, { checklistCustomer: this.state.checklistCustomer, onLoadMore: this.setVisible, hiddenLoadmore: this.state.hiddenLoadmore, deleteSelected: this.deleteSelected, onchange: this.createSql })}
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <Form.Item >
                            <div className="flex_" style={{ marginTop: "10px" }}>
                                <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreVouchersGroupRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreVouchersGroupRequest(data, id));
        },
        ResetStoreVouchersGroup: () =>
        {
            dispatch(ActionsWeb.ResetStoreVouchersGroup());
        },
        ListVouchersGroupRequest: (body) =>
        {
            dispatch(ActionsWeb.ListVouchersGroupRequest(body));
        },
        ListRegionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
        VoucherGroupByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.VoucherGroupByIdRequest(body));
        },
        getCondisCustomerRequest: (body) =>
        {
            dispatch(ActionsWeb.getCondisCustomerRequest(body));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);