import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";

import { Switch, Route, Link, Redirect } from 'react-router-dom';
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import { Divider,  Input, Table, Modal, message, Button, Form, InputNumber, Card, TreeSelect } from 'antd';
import * as Services from "./../../../../service";
import
{
    FolderViewOutlined, AppstoreOutlined,
    DeleteOutlined, PlusCircleOutlined,
    EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';

import DragDropAtt from "./../../../../components/dragdropattribute/index";

import { META } from "../../../../utils/constant";


class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = createRef(null);
        this.formSearchRef = createRef(null)
        this.state = {

            ListAttributeProducts: [],
            record: {
                delete: 0,
                hidden: 0,
                parent_id: 0
            },
            meta: {
                ...META
            },

            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.title.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Danh mục cha ',
                    dataIndex: 'parent_name',
                    sorter: (a, b) => a.parent_name - b.parent_name,

                },
                // {
                //     title: 'Ẩn',
                //     dataIndex: 'hidden',

                // },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },

            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreAttributeProducts: {},
            isloading: false,
            isLoadingSearch: false,
            isShowList: false,
            isloadingSavelist:false
        }

    }
    SetisShowList = (type) =>
    {

        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreAttributeProductsRequest(record, record.id ? record.id : "")
        // setTimeout(() =>
        // {

        // }, 2000);
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        const urlParams = Services.getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
        this.props.ListAttributeProductsRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListAttributeProducts && nextProps.Web.ListAttributeProducts !== prevState.ListAttributeProducts) {
            console.log("Comment");


            return {
                ListAttributeProducts: nextProps.Web.ListAttributeProducts,

            };

        } else if (nextProps.Web.StoreAttributeProducts && nextProps.Web.StoreAttributeProducts !== prevState.StoreAttributeProducts) {
            return {
                StoreAttributeProducts: nextProps.Web.StoreAttributeProducts,

            };

        }


        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.ListAttributeProducts !== prevState.ListAttributeProducts) {
            
            this.SetisLoadingSeach(false)
            this.data();
        }
        if (this.state.StoreAttributeProducts !== prevState.StoreAttributeProducts) {
            let { StoreAttributeProducts } = this.state

            try {
                if (StoreAttributeProducts.code === 200) {
                    if (StoreAttributeProducts.message && typeof StoreAttributeProducts.message === "string") {
                        message.success(StoreAttributeProducts.message)
                        this.setState(state =>
                        {
                            return {
                                record: {
                                    parent_id: 0,
                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = Services.getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
                    this.props.ListAttributeProductsRequest({
                        limit: this.state.meta.per_page * 1000,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    });
                }
                else {
                    if (StoreAttributeProducts.message && typeof StoreAttributeProducts.message === "string") {

                        message.error(StoreAttributeProducts.message)
                    } else {
                        for (let x in StoreAttributeProducts.message) {
                            let mess = StoreAttributeProducts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoadingSavelist(false)
            this.SetisLoading(false)
            this.setState(state =>
            {
                return {
                    StoreAttributeProducts: ""
                }
            })
            this.props.ResetStoreAttributeProducts();


        }
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    // {
                    //     title: 'STT',
                    //     dataIndex: 'key',
                    //     with: "5%"

                    // },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            console.log('====================================');
                            console.log("valuevalue", record, value);
                            console.log('====================================');
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "25%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    {/* {record.parent_id === 0 && */}
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>
                                    {/* } */}
                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListAttributeProducts } = this.state;

        if (Object.values(ListAttributeProducts.data).length <= 0) {
            this.setState(state =>
                {
                    return {
                        data:[{title:"Gốc",value:"0"}],
                        // meta: meta
                    }
                })
            return;
        }

        // let data = [];
        let data = [];
        let listfil = [];


        const t = Object.values(ListAttributeProducts.data);
      
        t.map((item, index) =>
        {

            listfil.push({
                text: item.name,
                value: item.name,
            })

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data:[{title:"Gốc",value:"0"},...ListAttributeProducts.data],
                // meta: meta
            }
        })
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            console.log('====================================');
            console.log(group, type, e);
            console.log('====================================');
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })

        }

    }


    editRows = (record) =>
    {

        this.setState(state =>
        {
            return {
                record: record
            }
        })
        this.formRef.current.setFieldsValue({ ...record })
    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", delete: 0, parent_id: record.id, parent: { name: record.name ? record.name : "Gốc" } }
            }
        })
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({ parent_id: record.id ? record.id : 0 })
    }
    // update_ = () =>
    // {
    //     const { record } = this.state;
    //     if (!record.name) {
    //         message.warn("Vui lòng nhập tên!");
    //         return;
    //     }
    //     if (!record.parent_id) {
    //         record.parent_id = 0
    //     }
    //     this.SetisLoading(true)
    //     this.props.StoreAttributeProductsRequest(record, record.id ? record.id : "");
    // }

    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreAttributeProductsRequest(record_, record_.id ? record_.id : "");
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListAttributeProductsRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.ListAttributeProductsRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });
        const searchParams = new URLSearchParams({ s: values.search }).toString();

        this.props.history.push(`${LINK.WEB_ATTRIBUTE_PRODUCTS}?${searchParams}`);

    }
    getMenus = (menus) =>
    {
        // console.log('====================================');
        // console.log(menus);
        // console.log('====================================');
        if (menus) {
            this.setState(state =>
            {
                return {
                    menus: menus
                }
            })
        }
    }
    SetisLoadingSavelist = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloadingSavelist: type
            }
        })
    }
    saveMenusList = () =>
    {
        const { menus } = this.state;
        this.SetisLoadingSavelist(true)
        console.log('====================================');
        console.log({ data: menus });
        console.log('====================================');
        this.props.StoreAttributeMultipleRequest({ data: menus });


    }
    render()
    {
        let { routers } = this.props;
        
        return (

            <div className="main_content attribute_products">

                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w row" >
                    <Card title={<div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thuộc tính sản phẩm</span>
                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList)&& <Link to={LINK.WEB_ATTRIBUTE_PRODUCTS_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {(Services.checkScreenSize().width >= 600 && this.state.isShowList) && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                           
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="form__half row">


                            <div className="left_half col-lg-6 co-md-6 col-sm-12">
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="Nhập tên thuộc tính" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>
                                    {/* <div className="right_">
                                        <Button type="primary" onClick={() => { this.addnew() }}><PlusCircleOutlined /></Button>
                                    </div> */}

                                </div>
                                {!this.state.isShowList && <><div className="menu_drag">
                                    <DragDropAtt menus={this.state.data} getMenus={this.getMenus}
                                        // getContentDrawer={this.onSearchPost}
                                        // drawContent={this.state.ListPosts}
                                    />
                                </div>    <div className="btn_save_cate_tree">
                                        <Button type="primary" loading={this.state.isloadingSavelist} onClick={() => { this.saveMenusList() }}>Lưu</Button>
                                    </div></>}
                                {this.state.isShowList &&  <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                    // rowSelection={{
                                    //     ...this.state.rowSelection,
                                    // }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                    
                                />}
                              
                            </div>
                          
                            <div className="right_half col-lg-6 co-md-6 col-sm-12">
                                {this.state.isShowList && <>
                                {/* <h5 style={{ color: Colors.color1 }}>
                                    {this.state.record?.parent && "thuộc tính gốc: " + this.state?.record?.parent?.name}
                                    {!this.state.record?.parent && "thuộc tính gốc: Gốc"}
                                </h5> */}
                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                        <Form.Item label="Thuộc tính cha" name={"parent_id"}

                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thuộc tính cha là bắt buộc"
                                                },
                                            ]}>
                                            <TreeSelect
                                                showSearch
                                                style={{ width: '100%' }}
                                                treeData={(this.state.data || [])}

                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder="Thuộc tính cha"
                                                // allowClear
                                                // multiple
                                                treeDefaultExpandAll


                                            />

                                        </Form.Item>

                                    <Form.Item label="Tên" layout="vertical" name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Tên thuộc tính là bắt buộc"
                                            },
                                        ]}>

                                        <Input name="name" id="name" placeholder="Tên thuộc tính"  onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                        }}  />
                                    </Form.Item>
                                    <Form.Item label="Code" layout="vertical" name="code"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Code là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Input name="code" id="code" placeholder="code" />
                                    </Form.Item>
                                    <Form.Item label="Giá trị" layout="vertical" name="value" >

                                        <InputNumber name="value" id="value" min={0} placeholder="Giá trị" />
                                    </Form.Item>
                                    <Form.Item label="Mô tả" layout="vertical" name="description">

                                        <Input name="description" id="description" placeholder="Mô tả" />
                                    </Form.Item>

                                    <Form.Item >
                                        <div className="flex_">
                                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

                                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                        </div>
                                    </Form.Item>
                                </Form>

                                </>}

                            </div>
                        </div>
                    </Card>
                </div>


            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListAttributeProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListAttributeProductsRequest(body));
        },
        StoreAttributeProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreAttributeProductsRequest(data, id));
        },
        ResetStoreAttributeProducts: () =>
        {
            dispatch(ActionsWeb.ResetStoreAttributeProducts());
        },
        StoreAttributeMultipleRequest: (body,data) =>
        {
            dispatch(ActionsWeb.StoreAttributeMultipleRequest(body, data));
        },
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);