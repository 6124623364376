

export default class Validator
{
    static genValidate = (validate, fieldName) =>
    {
        let _validate = {}
        validate.forEach((e, i) =>
        {
            _validate[`${fieldName}_${i}`] = e
        })
        return _validate
    }
    static required(value, mess = "")
    {
        return value?.toString()?.trim() ? undefined : !mess ? '* Không được để trống' : mess;
    }

    static money = (value, mess = "") =>
        !/^[0-9.]*$/i.test(value) && value != null
            ? 'Giá trị không đúng định dạng'
            : undefined;
    static maxLength = (max) => (value, mess = "") =>
        value && value.length > max ? !mess ? `* Không quá ${max} kí tự` : mess : undefined;
    static minLength = (min) => (value, mess = "") =>
        value && value.length < min ? !mess ? `* Không ít hơn ${min} kí tự` : mess : undefined;

    static CheckLength = (min) => (value, mess = "") =>
        value && value.length < min ? !mess ? `* Không ít hơn ${min}` : mess : undefined;
    static checkChar = (value, mess = "") =>
    {
        // return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/i.test(value) && value ? "Không chưa kí tự đặc biệt" : undefined
    }

    static checkEmotion = (value, mess = "") =>
    {
        return value != null && /^\+\d* ?\d*$/i.test(value) ? !mess ? "Không chưa kí tự đặc biệt" : mess : undefined;
    }
    static maxDate = (value, mess = "") =>
    {
        if (value > new Date()) {
            return !mess ? 'Ngày không được lớn hơn ngày hiện tại' : mess
        }
        return undefined;
    };
    static CheckedDate = (dateCheck, type) => (value, mess = "") =>
    {
        if (value && dateCheck) {
            switch (type) {
                case 1:
                    if (value && value > dateCheck) {
                        return !mess ? 'Thời gian đăng bài từ phải nhỏ hơn hoặc bằng Thời gian đăng bài đến' : mess
                    }
                    break;
                case 2:
                    if (value && value < dateCheck) {
                        return !mess ? 'Thời gian đăng bài đến phải lớn hơn hoặc bằng Thời gian đăng bài đến' : mess
                    }
                    break;
                default:
                    return undefined;
            }
        } else {
            return undefined;
        }
    };
    static requiredDate = (date, type) => (value, mess = "") =>
    {
        if (value && date) {
            switch (type) {
                case 1:
                    if (value && value > date) {
                        return !mess ? 'Thời gian từ phải nhỏ hơn hoặc bằng Thời gian đăng bài đến' : mess
                    }
                    break;
                case 2:
                    if (value && value < date) {
                        return !mess ? 'Thời gian đến phải lớn hơn hoặc bằng Thời gian từ' : mess
                    }
                    break;
                default:
                    return undefined;
            }
        } else {
            return undefined;
        }
    };

    static checkPriceMaxReduce = (price) => (value, mess = "") =>
    {
        if (value && price && parseInt(value?.toString().split(',').join('')) > parseInt(price?.toString().split(',').join(''))) {
            return !mess ? "Không thể tìm kiếm sản phẩm giá từ lớn hơn giá đến" : mess
        }
        return undefined;
    }
    static checkPriceMaxIncrea = (price) => (value, mess = "") =>
    {
        if (value && price && parseInt(value?.toString().split(',').join('')) < parseInt(price?.toString().split(',').join(''))) {
            return !mess ? "Không thể tìm kiếm sản phẩm giá đến nhỏ hơn giá từ" : mess
        }
        return undefined;
    }
    static checkHeightFrom = (height) => (value, mess = "") =>
    {
        if (value > 0 && height > 0 && value >= height) {
            return !mess ? "Giá trị từ phải nhỏ hơn giá trị đến" : mess
        }
        return undefined;
    }
    static checkHeightTo = (height) => (value, mess = "") =>
    {
        if (value && height && value <= height) {
            return !mess ? "Giá trị đến phải lớn hơn giá trị từ" : mess
        }
        return undefined;
    }
    static checkWeightFrom = (height) => (value, mess = "") =>
    {
        if (value && height && parseInt(value?.toString().split(',').join('')) >= parseInt(height?.toString().split(',').join(''))) {
            return !mess ? "Giá trị từ phải nhỏ hơn giá trị đến" : mess
        }
        return undefined;
    }
    static checkWeightTo = (height) => (value, mess = "") =>
    {
        if (value && height && parseInt(value?.toString().split(',').join('')) <= parseInt(height?.toString().split(',').join(''))) {
            return !mess ? "Giá trị đến phải lớn hơn giá trị từ" : mess
        }
        return undefined;
    }
    static checkPrice = (value, mess = "") =>
    {
        return value && value < 0 ? !mess ? "Giá tiền phải lớn hơn hoặc bằng 0" : mess : undefined;
    }
    static checkSymbols = (value, mess = "") =>
    {
        return value && /[!$%^&*()_+|~=`{}\[\]:\/;<>?@#]/i.test(value) ? !mess ? "Không chưa kí tự đặc biệt" : mess : undefined;
    }
    static checkCharRegex = (value, mess = "") =>
    {
        return value && !/^[/\s/A-Za-zÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ0-9_.,+-]+$/i.test(value) ? !mess ? "Không đúng định dạng" : mess : undefined;
    }

    static checkSKU = (value, mess = "") =>
    {
        return value && !/^[A-Z0-9]*$/i.test(value) ? !mess ? "Không đúng định dạng" : mess : undefined;
    }

    static repeatValue = (list, rowIndex, groupIndex) => (value, mess = "") =>
    {
        let newList = []
        let error = false;
        list.forEach((group, groupKey) =>
        {
            group.list.forEach((s, sKey) =>
            {
                s["Key"] = group.key
                s["ColorId"] = group.key
                s["RowIndex"] = groupKey
                s["GroupIndex"] = sKey
                newList.push(s)
            })
        })
        if (value) {
            newList?.map((x, index) =>
            {
                if (x?.Barcode == value && index > 0) {
                    if (x?.RowIndex != rowIndex && x?.GroupIndex != groupIndex) {
                        if (x?.RowIndex == rowIndex) {
                            if (x?.GroupIndex != groupIndex) {
                                error = true;
                            } else {
                                error = false;
                            }
                        } else {
                            error = true;
                        }
                    }
                } else {
                    error = false;
                }
            })
        }

        if (error) {
            return !mess ? `* Giá trị bị trùng lặp` : mess;
        } else {
            return undefined;
        }
    }
    static validateNumber(value, mess = "")
    {
        return !isNaN(value);
    }
    static validatePhoneNumber(phoneNumber, mess = "")
    {
        const regex = /^(0[1-9]|84[1-9])([0-9]{8})$/;
        return !regex.test(phoneNumber) ? !mess ? "*Số điện thoại không đúng dịnh dạng" : mess : undefined;
    }
    static validateEmail(email, mess = "")
    {
        // Định nghĩa biểu thức chính quy RFC 5322
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Kiểm tra xem email có khớp với biểu thức chính quy không
        return email && !regex.test(email) ? !mess ? "*Email không đúng dịnh dạng" : mess : undefined;

    }

}
