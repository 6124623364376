import { Avatar, Button, Dropdown, Image, Switch as SwitchA, List, Menu, Skeleton, Space, Popover, Divider, Tag ,Checkbox} from 'antd';
import React, { useEffect, useState } from 'react';
import * as LINK from "../../../../constants/Links";
import { CutString, checkScreenSize } from "../../../../service";
import {
DownOutlined, CarOutlined, DiffOutlined, DeleteOutlined, GiftOutlined, SafetyOutlined, CheckOutlined

} from '@ant-design/icons';
import { arrColors } from '../../../../constants/Colors';
import * as Colors from '../../../../constants/Colors';
import { Icons } from "../../../../constants/ListIcons";

const count = 3;

const App = ({ ...props }) => {
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {

        if (!props.isNewList) {

            const newData = data.concat(props.lists);
            setData(newData);
            setList(newData);
            setLoading(false);
            setInitLoading(false);
        } else {
            setInitLoading(false);
            setLoading(false);
            setData([]);
            setData(props.lists);
            setList(props.lists);


        }
        window.dispatchEvent(new Event('resize'));
    }, [props.lists]);


    const onLoadMore = () => {
        if (props.current_page < props.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            props.OnPagination(props.current_page + 1, props.per_page)

        }

    };
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <List
            // grid={checkScreenSize().width >= 600?{
            //     gutter: 16,
            //     column: 4,
            // }:false}
            className="mobile-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={list}
            renderItem={(item) => {
                let color="";
               
               return  <List.Item className='list_item_custom'
                    actions={[<a key="list-loadmore-edit" onClick={() => props.editRows(item)}>Sửa</a>, <Dropdown overlay={<Menu>

                        <Menu.Item icon={<DiffOutlined />} onClick={() => props.duplicateItem(item)}>
                            Nhân bản
                        </Menu.Item>
                        <Menu.Item icon={<DeleteOutlined />} danger onClick={() => props.deleteModal(item)}>Xóa</Menu.Item>
                    </Menu>}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                more
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>,   <Checkbox onChange={() => props.onChangeCheck(item)} checked={props.idsCheck && props.idsCheck.includes(item.id)}/>]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            // avatar={<div className='img_list'><Image
                            //     className='img'
                            //     style={{ objectFit: "contain" }}
                            //     src={LINK.myHostimgServerImg + "/" + item?.thumbnail?.[0]}
                            // /></div>}
                            title={<span style={{ color: Colors.pop2[2], fontSize: "bold" }}>{item.v_number}</span>}
                            description={<div><strong>{item?.fullname}</strong><br></br>{item?.phone_number}<br></br> {CutString(item?.address, 20, true)}</div>}
                        />
                        <div><Popover placement="top" content={<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>

                            {props.arrSaveBill && props.arrSaveBill.length > 0 && props.arrSaveBill.map((item_, index) => {
                              
                                if(item.status==item_.id){
                                    color=item_.color;
                                }
                               
                                return <div key={index}> <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                    onClick={() => {
                                        props.updateRecord(item_.id, item_);
                                    }}
                                >
                                    {Icons && Icons.map((Icon, index_) => {

                                        if (item_.icon === Icon.name) {
                                            const Ic = Icon.icon;
                                            return <Ic key={index_} />
                                        }


                                    })}
                                    <span style={{ marginLeft: "10px" }}>{item_.name}</span>
                                </button>
                                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                                </div>
                            })}

                            {/* <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                onClick={() =>
                                {
                                    props.updateRecord(1, item);
                                }}
                            >
                                <SafetyOutlined /> <span style={{ marginLeft: "10px" }}>Xác nhận đơn hàng</span>
                            </button>
                            <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                            <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                onClick={() =>
                                {
                                    props.updateRecord(2, item);
                                }}
                            >
                                <GiftOutlined /> <span style={{ marginLeft: "10px" }}>Đang đóng hàng</span>
                            </button>
                            <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                            <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                onClick={() =>
                                {
                                    props.updateRecord(3, item);
                                }}
                            >
                                <CheckOutlined /> <span style={{ marginLeft: "10px" }}>Chờ chuyển hàng</span>
                            </button>
                            <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                            <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                onClick={() =>
                                {
                                    props.updateRecord(4, item);
                                }}
                            >
                                <CarOutlined /> <span style={{ marginLeft: "10px" }}>Gửi hàng di</span>
                            </button> */}

                        </div>} title="Lưu" >
                            {item?.status_transport && item?.status_transport?.[item?.status_transport.length - 1] && <Tag color={color} style={{ cursor: "pointer" }}>{CutString(item?.status_transport?.[item?.status_transport.length - 1].name, 10, false)}</Tag>}
                            {!item?.status_transport || !item?.status_transport?.[item?.status_transport.length - 1] && <Tag color={color} style={{ cursor: "pointer" }}>Mới Tạo</Tag>}
                            {/* <Tag color={arrColors[item.status]} style={{ cursor: "pointer" }}>{CutString(item.status_text, 10, false)}</Tag> */}
                        </Popover></div>
                    </Skeleton>
                </List.Item>
            }}
        />
    );
};
export default App;