import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import "./style.css";
import * as LINK from "../../../constants/Links";
import { Divider, Radio, Table } from 'antd';
import * as Colors from "./../../../constants/Colors";

class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {


        }

    }
    // static getDerivedStateFromProps(nextProps, prevState)
    // {


    // }
    componentDidMount = () =>
    {


    }
    editRows = (record) =>
    {
        this.props.history.push({
            pathname: LINK.WEB_PRODUCTS_EDIT,
            state: record
        });
    }

    delete = (record) =>
    {
        let { data } = this.state;
        let tmp = data.filter(item =>
        {
            return item !== record;
        });

        this.setState(state =>
        {
            return {
                data: tmp
            }
        })
    }

    render()
    {
        return (
            <div className="">

                <div className="table_w" >
                    <h1 style={{ color: Colors.pop2[3] }}>DASHBOARHD</h1>

                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Tags: state.Tags
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

     
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);