
import React, { useState } from 'react'
import { useRef } from "react";
import "./style.css";

import * as LINK from "../../constants/Links";
import {
  Form,
  Input,
  Button,
  InputNumber, Select,
  Space, message,
  Divider,
  Typography, Modal, Radio, Empty, TreeSelect, Card, Checkbox, Drawer
} from 'antd';
const Index = () => {

 
  return (
    <>
        Chức năng sẽ sớm được cập nhật
    </>
  )
}
export default Index;