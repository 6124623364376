import { Avatar, Button, Dropdown, Image, Switch as SwitchA, List, Menu, Skeleton, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import * as LINK from "../../../../constants/Links";
import { convertToBoolean, CutString } from "../../../../service";
import
{
    DownOutlined, SmileOutlined, DiffOutlined, DeleteOutlined

} from '@ant-design/icons';

import * as Colors from "../../../../constants/Colors";

const count = 3;

const App = ({ ...props }) =>
{
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() =>
    {

        
        
        if (!props.isNewList) {

            const newData = data.concat(props.lists);
            setData(newData);
            setList(newData);
            setLoading(false);
            setInitLoading(false);
        } else {
            setInitLoading(false);
            setLoading(false);
            setData(props.lists);
            setList(props.lists);
            props.setIsNewList();

        }
        window.dispatchEvent(new Event('resize'));
    }, [props.lists]);


    const onLoadMore = () =>
    {
        if (props.current_page < props.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            props.OnPagination(props.current_page + 1, props.per_page)

        }

    };
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <List
            className="mobile-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={list}
            renderItem={(item) => (
                <List.Item className='list_item_custom'
                    actions={[<SwitchA defaultChecked={convertToBoolean(item.active)} onChange={(e) => { props.updateRecord(e, item) }}
                        loading={props.isloading} size='small' />, <a key="list-loadmore-edit" onClick={() => props.editRows(item)}>Sửa</a>, <Dropdown overlay={<Menu>

                            {/* <Menu.Item icon={<DiffOutlined />} onClick={() => props.duplicateItem(item)}>
                                Nhân bản
                            </Menu.Item> */}
                            <Menu.Item icon={<DeleteOutlined />} danger onClick={() => props.deleteModal(item)}>Xóa</Menu.Item>
                        </Menu>}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                more
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            avatar={item?.images?<div className='img_list'><Image
                                className='img'
                                style={{ objectFit: "contain" }}
                                src={LINK.myHostimgServerImg + "/" + item?.images}
                            /></div> : <span style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: "<i class='" + item.icon + "'></i>" }} />}
                            title={<div>{CutString(item.title, 30, true)}</div>}
                            description={<div><br></br>{CutString(item?.description, 30, true)}<br></br>
                            {"Vị trí: "+item?.rank}<br></br>
                            { item.type === 1 && <Tag  color={Colors.arrColors[item.type-1]} >Banner</Tag>}
                                {item.type === 2 && <Tag color={Colors.arrColors[item.type-1]} >Sliders</Tag>}
                                {item.type === 3 && <Tag color={Colors.arrColors[item.type-1]} >Thương hiệu</Tag>}
                                {item.type === 4 && <Tag color={Colors.arrColors[item.type-1]} >Dịch vụ</Tag>}
                                {item.type === 5 && <Tag color={Colors.arrColors[item.type-1]} >Payment</Tag>}
                                {item.type === 6 && <Tag color={Colors.arrColors[item.type-1]} >Mạng xã hội</Tag>}

                            </div>}
                        />
                        {/* <div>content</div> */}
                    </Skeleton>
                </List.Item>
            )}
        />
    );
};
export default App;