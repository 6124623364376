import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button } from 'antd';

import
{
    FolderViewOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons';
const { Search } = Input;
class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {

            ListPaperTexture: [],
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StorePaperTexture: {}
        }

    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StorePaperTextureRequest(record, record.id ? record.id : "")
        // setTimeout(() =>
        // {

        // }, 2000);
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        // if (!this.props.Web.ListPaperTexture.data) {

        this.props.ListPaperTextureRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        // }
        // else {
        //     this.setState(state =>
        //     {
        //         return {
        //             ListPaperTexture: this.props.Web.ListPaperTexture
        //         }
        //     }, () => { this.data(); })
        // }
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListPaperTexture && nextProps.Web.ListPaperTexture !== prevState.ListPaperTexture) {
            console.log("Comment");


            return {
                ListPaperTexture: nextProps.Web.ListPaperTexture,

            };

        }
        else if (nextProps.Web.StorePaperTexture && nextProps.Web.StorePaperTexture !== prevState.StorePaperTexture) {
            return {
                StorePaperTexture: nextProps.Web.StorePaperTexture,

            };

        }
        else {
            return null;
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StorePaperTexture !== prevState.StorePaperTexture) {
            let { StorePaperTexture } = this.state;
            if (StorePaperTexture.code === 200) {
                message.success(StorePaperTexture.message)
                this.setVisible(false);
                let { data, record } = this.state;
                let tmp = data.filter(item =>
                {
                    return item.id !== record.id;
                });

                this.setState(state =>
                {
                    return {
                        data: tmp
                    }
                })
            }
            else {
                if (StorePaperTexture.message) {
                    message.error(StorePaperTexture.message)
                }

            }
            this.setConfirmLoading(false);
            this.props.ResetStorePaperTexture()

        }
        if (this.state.ListPaperTexture !== prevState.ListPaperTexture) {
            let { ListPaperTexture } = this.state

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },


                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },
                    {
                        title: 'Ngày cập nhật',
                        dataIndex: 'updated_at',
                        sorter: (a, b) => a.updated_at - b.updated_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }
                    },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="__action_">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListPaperTexture } = this.state;

        if (ListPaperTexture.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListPaperTexture.current_page,
            first_page_url: ListPaperTexture.first_page_url,
            from: ListPaperTexture.from,
            last_page: ListPaperTexture.last_page,
            last_page_url: ListPaperTexture.last_page_url,
            links: ListPaperTexture.links,
            next_page_url: ListPaperTexture.next_page_url,
            path: ListPaperTexture.path,
            per_page: ListPaperTexture.per_page,
            prev_page_url: ListPaperTexture.prev_page_url,
            to: ListPaperTexture.to,
            total: ListPaperTexture.total,
        };
        ListPaperTexture.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }


    editRows = (index) =>
    {

        let { ListPaperTexture } = this.state;
        if (ListPaperTexture) {
            // let data = Products.data;
            let record = ListPaperTexture.data[index];
            console.log('====================================');
            console.log(record);
            console.log('====================================');
            this.props.history.push({
                pathname: LINK.WEB_PAPER_TEXTURE_EDIT,
                state: record
            });
        }

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListPaperTextureRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (e) =>
    {
        this.props.ListPaperTextureRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: e
        });
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    <h1>Kết cấu giấy</h1>
                    <div className="btn_action_list">
                        <div className="left_">
                            <Search placeholder="input search text" onSearch={this.onSearch} enterButton />
                        </div>
                        <div className="right_">
                            <Link to={LINK.WEB_PAPER_TEXTURE_EDIT}> <Button type="primary">Thêm mới</Button></Link>
                        </div>

                    </div>
                    <Table
                        rowClassName={(record, index) =>
                        {
                            return "__row_";
                        }}
                        rowSelection={{
                            ...this.state.rowSelection,
                        }}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                        pagination={false}
                        onChange={this.onChangeTable}
                        scroll={{

                            y: "60vh",
                        }}
                    />
                    <div className="pagination_">
                        <Pagination showQuickJumper pageSize={this.state.meta.per_page} current={this.state.meta.current_page} total={this.state.meta.to} onChange={this.OnPagination} />
                        {/*  <Pagination showTotal={() => { return "Tổng " + this.state.meta && this.state.meta.total ? this.state.meta.total : 0 + " Trang" }} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger showQuickJumper current={this.state.meta && this.state.meta.currentPage ? this.state.meta.currentPage : 1} onChange={this.OnPagination} /> */}
                    </div>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListPaperTextureRequest: (body) =>
        {
            dispatch(ActionsWeb.ListPaperTextureRequest(body));
        },
        StorePaperTextureRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StorePaperTextureRequest(data, id));
        },
        ResetStorePaperTexture: () =>
        {
            dispatch(ActionsWeb.ResetStorePaperTexture());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);