import * as Types from "./../constants/TypesactionWeb";
import { ApiPublic, PostWeb, GetWeb, UploadFile } from "./../utils/apiCaller";

import { getCookie, setCookie, eraseCookie } from "./../service";
// products
export const LoginRequest = (body) =>
{

  return (dispatch) =>
  {

    return PostWeb("login", body, "POST")
      .then(response => response.text())
      .then(result => 
      {
       
        dispatch(Login(JSON.parse(result)));

      }).catch(error1 => console.log('error', error1));

  };
};

export const Login = (result) =>
{
  return {
    type: Types.LOGIN_WEB,
    result,
  };
};

export const ResetLogin = (body) =>
{

  return (dispatch) =>
  {

    return dispatch(Login(""));

  };
};

export const GetUser = () =>
{
  let acccess_token = getCookie("acccess_token");
  if (!acccess_token) {
    return false;
  } else {
    return true;
  }
};


export const SetUserRequest = (data) =>
{
  return (dispatch) =>
  {

    dispatch(SetUser(data));

  };
};
// get menu trong reducer
export const SetUser = (result) =>
{
  return {
    type: Types.SET_USER,
    result,
  };
};

export const LogoutRequest = (body) =>
{


  return (dispatch) =>
  {

    return PostWeb("logout", body, "POST")
      .then(response => response.text())
      .then(result => 
      {

        eraseCookie("user");
        eraseCookie("acccess_token")
        
        dispatch(Logout(JSON.parse(result)));

      }).catch(error1 =>
      {
        console.log('error', error1)
        eraseCookie("user");
        eraseCookie("acccess_token")
      });

  };
};
export const Logout = (result) =>
{
  return {
    type: Types.LOGOUT_WEB,
    result,
  };
};