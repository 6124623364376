import React, { Component, createRef, useEffect, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined,MinusCircleOutlined,ArrowUpOutlined,ArrowDownOutlined,SaveOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
const Index = ({ listSortStamps,setListSortStamps,formSortStampsRef}) => {


    // const [data, setData] = useState();
   
    const addSortStamp=(index)=>{
        let lastItem=listSortStamps[listSortStamps.length-1];
        let rankLast=0;
        if(lastItem){
             rankLast=lastItem.rank;
        }

        const formvalues = formSortStampsRef.current.getFieldsValue();
        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        for (let x in formvalues) {
            let x_ = x.split("-");
            let index = x_[x_.length - 1];

            if (flag != parseInt(index)) {
                newdata.push(itemNew)
                flag = index;
                itemNew = {};
            }
            if (flag == index) {
                if (formvalues?.[`sort-stamps-width-${index}`] && formvalues?.[`sort-stamps-height-${index}`]) {
                    itemNew.width = formvalues[`sort-stamps-width-${index}`];
                    itemNew.height = formvalues[`sort-stamps-height-${index}`];
                    itemNew.a3 = formvalues[`sort-stamps-a3-${index}`];
                    itemNew.a4 = formvalues[`sort-stamps-a4-${index}`];
                    itemNew.rank = listSortStamps[index]?.rank;
                    itemNew.id = listSortStamps[index]?.id;
                    itemNew.isEdit = listSortStamps[index]?.isEdit;
                    itemNew.isNew = listSortStamps[index]?.isNew;
                    itemNew.code = listSortStamps[index]?.code ? listSortStamps[index]?.code : itemNew.width + "x" + itemNew.height;
                }

            }
            i++;
            if (i == Object.values(formvalues).length) {

                newdata.push(itemNew)

            }

        }

        setListSortStamps([...newdata,{
            width:"",
            height:"",
            a3:"",
            a4:"",
            code: "",
            isNew: true,
            rank:rankLast+1
        }])
    }
    const removeSortStamp=(index)=>{
        if(index==0){
            return;
        }
        const newData=listSortStamps.filter((value, i) => {
            return i!=index;
        })
        setListSortStamps([...newData])
    }

    const sortDataByRank = (type,value,index) => {
        if(type=="up"){
            value.rank-=1;
            if(value.rank<=1){
                value.rank=1;
            }
        }
        if(type=="down"){
            value.rank+=1;
        }
        const sortedData=[];
        listSortStamps.map((item, i) => {
            if(type=="up"){
               
                if(i==(index-1)){
                    item.rank+=1;
                    item.isEdit=true;
                }
            }
            if(type=="down"){
                if(i==(index+1)){
                    item.rank-=1;
                    if(item.rank<=1){
                        item.rank=1;
                    }
                    item.isEdit=true;

                }
            }
            if(i==index){
                item=value
                item.isEdit=true;
            }
            sortedData.push(item)
        })
      
       const sortedData_ =sortedData.sort((a, b) =>a.rank - b.rank);
       
        setListSortStamps(sortedData_);
    };
    return (
        <>
            {listSortStamps && listSortStamps.map((item, index) => {

             return   <Input.Group compact>

                    <Form.Item name={`sort-stamps-width-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input  className="font-size-12"
                            addonAfter={"X"}
                            style={{
                                width: '20%',
                            }}
                            placeholder="rộng"
                        />
                    </Form.Item>
                    <Form.Item name={`sort-stamps-height-${index}`}  noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input  className="font-size-12"
                            style={{
                                width: '15%',
                            }}
                            placeholder="dài"
                        />
                    </Form.Item>
                    <div className="d-none" >
                    <Form.Item name={`sort-stamps-rank-${index}`}  noStyle>
                        <Input  className="font-size-12"
                           
                        />
                    </Form.Item>
                    </div>
                    <Form.Item name={`sort-stamps-a3-${index}`}  noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input  className="font-size-12"
                            style={{
                                width: '15%',
                            }}
                            placeholder="A3"
                        />
                    </Form.Item>
                    
                    <Form.Item name={`sort-stamps-a4-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input  className="font-size-12"
                            addonAfter={<div className="d-flex">
                                <button type="button" className="reset_btn scale_btn mr-2 font-size-12" onClick={()=>addSortStamp(index)}><PlusCircleOutlined className="text-primary"/></button>
                                <button type="button" className="reset_btn scale_btn mr-2 font-size-12" onClick={()=>removeSortStamp(index)}><MinusCircleOutlined className="text-danger"/></button>
                                <button type="button" className="reset_btn scale_btn mr-2 font-size-12" onClick={()=>sortDataByRank("up",item,index)}><ArrowUpOutlined className="text-info"/></button>
                                <button type="button" className="reset_btn scale_btn font-size-12" onClick={()=>sortDataByRank("down",item,index)}><ArrowDownOutlined className="text-info"/></button>
                                {/* <button className="reset_btn scale_btn" onClick={()=>sortDataByRank("down",item,index)}><SaveOutlined className="text-info"/></button> */}
                                </div> }
                            style={{
                                width: '50%',
                            }}
                            placeholder="A4"
                        />
                    </Form.Item>

                </Input.Group>
            })}

        </>

    );
}
export default Index;