import React, { Component, createRef } from "react";


import 'draft-js/dist/Draft.css';
import { connect } from "react-redux";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import Upload from "./Upload";
import Draggable from 'react-draggable';
import { validateNUmber, replaceThous, formatMN1, getCookie, UploadFile, CheckUnionArr, createSlug } from "./../../../../service";
import { DragDropContext, Droppable, Draggable as DraggableB } from "react-beautiful-dnd";

import * as ActionsWeb from "../../../../actions/indexWeb";

import * as Colors from "../../../../constants/Colors";

import LoadingSmile from "./../../../../components/loadingSmile/loadingSmile";

import
{
    Form,
    Input,
    Button,
    InputNumber, Select,
    Space, message,
    Divider,
    Typography, Modal, Radio, Empty, TreeSelect, Card
} from 'antd';
import
{
    FileImageOutlined,
    EditOutlined,
    VideoCameraOutlined,
    PlusOutlined,
    PlusCircleOutlined, InfoCircleOutlined, SlidersOutlined, FormOutlined,
    DragOutlined, CarOutlined,
    DeleteOutlined, UserSwitchOutlined, ShoppingCartOutlined, GlobalOutlined
} from '@ant-design/icons';
import { linkSocket } from "../../../../constants/LinkApi";
import Editor from "./../../../../components/editor/index";

import { META } from "../../../../utils/constant";
import { v4 as uuidv4 } from 'uuid';
import Validator from "../../../../utils/Validator";
import { Runfirst } from "../../../../constants/Runfirst";
import TableClassify from "./RecursiveDisplay";


const { TextArea } = Input;
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({

    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "grey",
    padding: grid,
    // width: 250
});

const reorder = (list, startIndex, endIndex) =>
{
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ArrStatus = [{ value: 1, label: "mới" }, { value: 2, label: "Đã sử dụng" }]
let countData = 0;
class ProductsEdit extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = new createRef(null);
        this.editor = createRef();
        this.state = {

            editor: "",

            selectedItemsClassify: [],
            record: {
                id: "",
                user_id: "",
                code_group: null,
                code: "",
                title: "",
                slug: "",
                price: "",
                amount: null,
                discount: 0,
                discountpercent: 0,
                thumbnail: [],
                images: [],
                video: [],
                weight: "",
                wide: "",
                long: "",
                height: "",
                width: "",
                fee_ship: "",
                description: "",
                created_at: "",
                updated_at: "",
                status: 1,
                is_reserve: 1,
                hidden: 0,
                deleted: 0,
                classify_: "",
                tags_: "",
                price_range_: "",

                user_name: "",
                ListInfoTransport: [],
                classifys: [],

                category_id: [],
                tags_id: [],
                price_range: [],
                attribute_products_id: []
            },
            items: ['jack', 'lucy'],
            name: "",
            classifyx: [
                { groups: { groupname: "Tên biến thể", name: "Tên biến thể" },data:[], items: [{ key: uuidv4(), name: "Biến thể", price: 0, amount: 0, store_id: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
            ],
            dataClassifyx: [],
            dataClassifyStoreIds: {},
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
            imgesInfoRemove: [],
            isupload: 0,
            imgesInfores: {
                avata: [],
                detail: [],
                video: []
            },
            basicInfo: {
                name: "",
                des: "",
                cate: [],
                tags: [],
            },
            detailInfo: {
                trademark: "",
                orProduction: "",
                typeProduct: "",
                papertexture: "",
                store_id: "",
                adProduction: "",
                pageSize: "",
                warrType: ""
            },
            ListAttributeProducts: [],
            ListInventory: [],
            ListStore: [],
            ListTags: [],

            ListClassifys: [],

            saleInfo: {
                classify1: {
                    groups: [],
                    items: []
                },
                classify2: {
                    groups: [],
                    items: []
                },
                dataClassify: [],
            },
            buyMoreDiscout: [],
            transportInfo: {

            },
            ortherInfo: {
                PreOrders: 0,
                status: 1,
                code: "",
                is_reserve: 1
            },
            product: {

            },
            dataAllInOne: {
                price: "",
                store_id: [],
                discount: [],
                discountpercent: 0,
                code: ""
            },

            active_tran: {},
            ismodalTrans: false,
            boundsTrans: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            disabled: false,
            isSwitchTrans: true,
            isradio: 1,
            isrowSpan: false,
            meta: {
                ...META
            },
            ListCategory: [],
            treeData: [],

            addClassifys: "",
            StoreClassifys: "",


            StoreAttributeProducts: "",
            addAttributeProducts: "",
            parent_id_item: "",

            InfoPackageActive: [],

            addTags: "",
            StoreTags: {},
            StoreProducts: "",
            UploadFileLa: "",

            listProducts: [],
            isloading: false,
            isloadingLoadData: false,
            type: "",

            required: {
                avata: false
            },
            socket: null,
            isupdate: false,
            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            folder: "products"
        }

    }

    toolbar = {
        inline: {
            inDropdown: false,
        },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        emoji: { inDropdown: false },
        image: {
            uploadCallback: this.uploadImageCallBack.bind(this),
            previewImage: true,
            alt: { present: true, mandatory: false },
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        },
    };
    resetState = () =>
    {
        this.formRef.current.resetFields();
        this.editor.current.value = '';
        this.formRef?.current.setFieldsValue({ wide: this.state.InfoPackageActive?.[0].wide, height: this.state.InfoPackageActive?.[0].height, long: this.state.InfoPackageActive?.[0].long, weight: this.state.InfoPackageActive?.[0].weight });
        this.props.ResetListProductsRequest();

        this.setState(state =>
        {
            return {

                editor: "",
                selectedItemsClassify: [],
                record: {
                    id: "",
                    user_id: "",
                    code_group: "",
                    code: "",
                    title: "",
                    slug: "",
                    price: "",
                    amount: 0,
                    discount: 0,
                    discountpercent: 0,
                    thumbnail: [],
                    images: [],
                    video: [],

                    description: "",
                    created_at: "",
                    updated_at: "",
                    status: 1,
                    is_reserve: 1,
                    hidden: 0,
                    deleted: 0,
                    classify_: "",
                    tags_: "",
                    price_range_: "",

                    user_name: "",
                    ListInfoTransport: [],
                    classifys: [],

                    category_id: [],
                    tags_id: [],
                    price_range: [],
                    attribute_products_id: []
                },
                items: ['jack', 'lucy'],
                name: "",

                classify1: {
                    groups: [],
                    items: []
                },
                classify2: {
                    groups: [],
                    items: []
                },
                dataClassify: [],
                imgesInfo: {
                    avata: [],
                    detail: [],
                    video: []
                },
                imgesInfoRemove: [],
                isupload: 0,
                imgesInfores: {
                    avata: [],
                    detail: [],
                    video: []
                },
                basicInfo: {
                    name: "",
                    des: "",
                    cate: [],
                    tags: [],
                },
                detailInfo: {
                    trademark: "",
                    orProduction: "",
                    typeProduct: "",
                    papertexture: "",
                    store_id: "",
                    adProduction: "",
                    pageSize: "",
                    warrType: ""
                },

                saleInfo: {
                    classify1: {
                        groups: [],
                        items: []
                    },
                    classify2: {
                        groups: [],
                        items: []
                    },
                    dataClassify: [],
                },
                buyMoreDiscout: [],
                transportInfo: {

                },
                ortherInfo: {
                    PreOrders: 0,
                    status: 1,
                    code: "",
                    is_reserve: 1
                },
                product: {

                },
                dataAllInOne: {
                    price: "",
                    store_id: [],
                    discount: [],
                    discountpercent: 0,
                    code: ""
                },
                addClassifys: "",
                StoreClassifys: "",


                StoreAttributeProducts: "",
                addAttributeProducts: "",
                parent_id_item: "",
                addTags: "",
                StoreTags: {},
                StoreProducts: "",
                UploadFileLa: "",

                listProducts: [],
                isloading: false,

                type: "",

                required: {
                    avata: false
                },

                isupdate: false,

            }
        }, () =>
        {

        })
    }
    uploadImageCallBack(file)
    {

        return new Promise(
            (resolve, reject) =>
            {
                UploadFile([file], "img", 4)
                    .then(response => response.text())
                    .then(res =>
                    {

                        try {
                            res = JSON.parse(res);

                            resolve({ data: { link: LINK.myHostimgServerImg + "/" + res.data[0] } });
                        } catch (error) {
                            message.error("Có lỗi!")
                        }

                    }).catch(err =>
                    {
                        reject(err)
                    });
            }
        );
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    setimgesInfo = (type, data) =>
    {


        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = this.state.folder;

            }
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],

                }
            }, () =>
            {
                this.setState(state =>
                {

                    return {

                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })


            // imageDelete["folder"] = type;

        } else {
            this.setState(state =>
            {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) =>
    {
        data["folder"] = this.state.folder;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item =>
            {
                return item !== data.name;
            })
            imagesrecord = this.state.record.images.filter(item =>
            {
                return item !== data.name;
            })
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }
    setRadio = (value) =>
    {
        this.setState(state =>
        {
            return {
                isradio: value
            }
        })
    }
    onChangeRadio = (e) =>
    {
        console.log('radio checked', e.target.value);
        this.setRadio(e.target.value);
    };
    onchangeActiveTran = () =>
    {
        this.setState(state =>
        {
            return {
                isSwitchTrans: !state.isSwitchTrans
            }
        })
    }
    draggleRef = new createRef(null);

    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                boundsTrans: bounds
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    ColorCell = (type = '', isfocus = false) =>
    {

        let els = document.getElementsByClassName(type);
        for (let i = 0; i < els.length; i++) {

            const element = els[i];


            if (isfocus) {
                element.classList.add("bg_color_red")
            } else {
                element.classList.remove("bg_color_red")
            }
        }

    }

    setItems = (type) =>
    {
        this.setState(state =>
        {
            return {
                items: type
            }
        })
    }

    setName = (event, type, parent_id = "") =>
    {

        let value = event.target.value;
        this.setState(state =>
        {
            return {
                [type]: value,
                parent_id_item: parent_id
            }
        })

    }
    onNameChange = (event) =>
    {
        this.setName(event.target.value);
    };

    setbuyMoreDiscout = () =>
    {
        this.setState(state =>
        {
            return {
                buyMoreDiscout: [...state.buyMoreDiscout, { from_amount: "", to_amount: "", price: "" }]
            }
        })
    }
    setclassifyx = () =>
    {

        const { classifyx } = this.state;
        const classify_ = { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, items: [{ key: uuidv4(), name: "Biến thể-" + classifyx.length + "-1", price: 0, amount: 0, store_id: [], store_ids: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
        this.generateCombinations([...classifyx, classify_])
        this.setState(state =>
        {
            return {
                classifyx: [...state.classifyx, classify_]
            }
        }, () =>
        {

        })
    }

    setclassifyitemx = (index) =>
    {
        const { classifyx } = this.state;
        const classify_ = classifyx[index];
        const item_ = classify_?.items ? classify_?.items:[];
    
        item_.push({ key: uuidv4(), name: "Biến thể-" + index + "-" + item_.length, price: 0, amount: 0, store_id: [], store_ids: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 })
        classify_.items = item_;
        classifyx[index] = classify_;
        this.generateCombinations(classifyx)
        this.setState(state =>
        {
            return {
                classifyx: classifyx
            }
        }, () =>
        {

        })
    }
    DeleteclassifyxItem = (ind, index) =>
    {
        const { classifyx } = this.state;
        const classify_ = classifyx[ind];
        const item_ = classify_.items;
        const newitem = item_.filter((item, i) =>
        {
            return i !== index
        })
        let newClassifyx = classifyx
        if (newitem.length <= 0) {
            newClassifyx = classifyx.filter((item, index) =>
            {
                return index != ind
            })
        } else {
            newClassifyx[ind].items = newitem;
        }

        this.setState(state =>
        {
            return {
                classifyx: newClassifyx
            }
        }, async () =>
        {
            const result = this.generateCombinations(this.state.classifyx);
            // console.log(result);
            // const result_ = await this.displayNode(result);

        })
    }

    generateCombinations = async (data) =>
    {
        // let counter=0;
        function generate(index)
        {
            if (index === data.length - 1) {
                return data[index]?.items?.map(item => ({ ...item, children: [] }));
            }
          
            const currentGroup = data[index]?.items;
            const nextCombinations = generate(index + 1);

            return currentGroup?.map(item =>
            {
                return {
                    ...item,
                    children: [...nextCombinations],
                }
            });
        }

        const result = generate(0);
    
        this.setState(state =>
        {
            return {
                dataClassifyx: result
            }
        })
        return result;
    }

  
    onDragEnd = async (type, result) =>
    {
        console.log("resultresultresult", result);
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = await reorder(
            type === 1 ? this.state.classify1.items : this.state.classify2.items,
            result.source.index,
            result.destination.index
        );

        // this.sortItems_(type, items);
        // this.setState({
        //     items
        // });
    };
   
  
    onchangeGroupClassifyx  = async (e, ind) =>
    {
        const { ListClassifys,classifyx } = this.state;
        let cl = {};
        ListClassifys.map((item, index) =>
        {
            if (item.id === e) {
                item.hidden = true;
                cl = item;
            }else{
                item.hidden = false;
            }
        });

        classifyx[ind].data = cl?.children?[...cl?.children]:[]
        classifyx[ind] = { ...classifyx[ind], ...cl}
       
        this.setState(state =>
        {
            return {
                ListClassifys: ListClassifys,
                classifyx: classifyx
            }
        })

    }
    onchangeItemClassifyx = async (e, key, ind)=>{
        const {classifyx }=this.state;
        let cl = {}; 
        let data = classifyx[ind].data;
        data.map((item, index) => {
            if(item.id===e){
                classifyx[ind].data[index].hidden=true;
                cl=item;
            }
        });
       
     
        classifyx.map((item, index) => {
            item.items.map((el, ind) => {
                if (el.key===key) {
                    item.items[ind] = { ...el, id: cl.id, name: cl.name, code: cl.code, rank: cl.rank };
                }
            });
            
        });
        this.generateCombinations(classifyx)
      
        this.setState(state =>
        {
            return {
                classifyx: classifyx
              
            }
        })
       
    }
   
   
    onChangeAllInOne = (e, type, isnumber = false) =>
    {

        let value = '';
        if (e.target) {
            value = e.target.value
        }
        else {
            value = e
        }
        if (isnumber) {
            value = replaceThous(value)

            if (!validateNUmber(value)) {
                value = 0;
            }

        }
        if (type === "price") {

            this.setState(state =>
            {
                return {
                    dataAllInOne: { ...state.dataAllInOne, [type]: value },
                    record: { ...state.record, price: value, discount_percent: 0, discount: 0 }
                }
            })
        } else if (type === "amount") {

            this.setState(state =>
            {
                return {
                    dataAllInOne: { ...state.dataAllInOne, [type]: value },
                    record: { ...state.record, [type]: value }
                }
            })
        } else {
            this.setState(state =>
            {
                return {
                    dataAllInOne: { ...state.dataAllInOne, [type]: value },

                }
            })
        }


    }
    onChangeDiscount = (e, type, isnumber = false) =>
    {
        const formvalues = this.formRef.current.getFieldsValue();
        let value = '';
        if (e.target) {
            value = e.target.value
        }
        else {
            value = e
        }
        let discount = 0;
        let discount_percent = 0;
        if (formvalues.price) {

            if (type === "discount") {
                value = replaceThous(value);
                discount_percent = (value / formvalues.price) * 100;

                discount = value;
            } else if (type === "discount_percent") {
                discount_percent = value;
                discount = formvalues.price * (discount_percent / 100);
            }

            if (!validateNUmber(discount)) {
                discount = 0;
            }
            let discount_price = formvalues.price - discount;

            this.formRef.current.setFieldsValue({
                discount: discount, discount_percent: discount_percent, discount_price: discount_price
            })
            // this.setState(state =>
            // {
            //     return {
            //         record: { ...state.record, discount: discount, discount_percent: discount_percent, discount_price: discount_price },
            //         dataAllInOne: { ...state.dataAllInOne, discount: discount, discount_percent: discount_percent, discount_price: discount_price },
            //     }
            // })
        } else {
            message.warn("Bạn chưa nhập giá!");

        }

    }
    onChangeAmountItem = async (e, type, key) =>
    {
        const { dataClassifyx }=this.state;
        let arrParent = {}
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {

            return Promise.all(data.map(async (item, indi) =>
            {
                let newItem = { ...item };

                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }

                    let newItemc = await updateItemValue(item.children, level + 1, item);
                    let newItem = { ...item, children: newItemc };
                    return newItem;
                } else {
                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }

                    tmp.push({ ...item, children: [] });
                    let key_ = "";
                    tmp.map((d_, ide_) =>
                    {
                        key_ += d_.key
                    })
                   
                    if (key === key_){
                        const stores_ = newItem.store_ids;
                        let a_ = Math.floor(e / stores_.length);
                        let setformValue={};
                        let b_ = e-(a_ * stores_.length);
                        
                        newItem.store_ids = newItem.store_ids.map((elstore, istore) => {
                            if (istore === (newItem.store_ids.length-1)){
                                elstore = { ...elstore, amount: a_+b_ }
                                setformValue = { ...setformValue, [`amount_price_item_d_${key_}_${istore}`]: a_ + b_ }
                            }else{
                                elstore = { ...elstore, amount: a_ }
                                setformValue = { ...setformValue, [`amount_price_item_d_${key_}_${istore}`]: a_ }
                            }
                           
                           
                            return elstore;
                        });
                        this.formRef.current.setFieldsValue({ ...setformValue});
                       
                    }

                    return newItem;
                }


            }));
        }
        const newDataClassifyx = await updateItemValue(dataClassifyx, 0);
        // this.setState(state=>{
        //     return{
        //         dataClassifyx: newDataClassifyx
        //     }
        // })
    }

    onChangeAmountItemSub = async (e, type, key,i_) =>
    {
        const formvalues = this.formRef.current.getFieldsValue();
        const { dataClassifyx } = this.state;
        let arrParent = {}
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {

            return Promise.all(data.map(async (item, indi) =>
            {
                let newItem = { ...item };

                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }

                    let newItemc = await updateItemValue(item.children, level + 1, item);
                    let newItem = { ...item, children: newItemc };
                    return newItem;
                } else {
                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }

                    tmp.push({ ...item, children: [] });
                    let key_ = "";
                    tmp.map((d_, ide_) =>
                    {
                        key_ += d_.key
                    })

                    if (key === key_) {
                        const stores_ = newItem.store_ids;
                       
                        let total = 0;
                        

                        newItem.store_ids = newItem.store_ids.map((elstore, istore) =>
                        {
                            if (i_ === istore){
                                elstore = { ...elstore, amount: e }
                            }
                            total += formvalues[`amount_price_item_d_${key_}_${istore}`];
                          

                            return elstore;
                        });
                       
                        this.formRef.current.setFieldsValue({ [`amount_price_${key_}`]: total });

                    }

                    return newItem;
                }


            }));
        }
        const newDataClassifyx = await updateItemValue(dataClassifyx, 0);
        // this.setState(state =>
        // {
        //     return {
        //         dataClassifyx: newDataClassifyx
        //     }
        // })
    }
    onChangeStoreItem = async (e, type, key) =>
    {
        // let result = {}
        const formvalues = this.formRef.current.getFieldsValue();
        const { dataClassifyx } = this.state;
        let arrParent = {}
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {

            return Promise.all(data.map(async (item, indi) =>
            {
                let newItem = { ...item };

                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }

                    let newItemc = await updateItemValue(item.children, level + 1, item);
                    let newItem = { ...item, children: newItemc };
                    return newItem;
                } else {
                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }

                    tmp.push({ ...item, children: [] });
                    let key_ = "";
                    tmp.map((d_, ide_) =>
                    {
                        key_ += d_.key
                    })
                    let total = formvalues[`amount_price_${key_}`];
                    if (key === key_) {
                        
                        const stores_ = newItem.store_ids;
                        const store_id = newItem.store_id;
                        newItem.store_id=e;
                        newItem.store_ids=[];
                        stores_.map((el,index) =>
                        {
                            if (el){
                                if (newItem.store_id.includes(el.store_id)) {
                                    newItem.store_ids.push(el);
                                }
                            }
                           
                        })
                        newItem.store_id.map((el, index) => {
                            const checkitem = newItem.store_ids.filter(el__ => {
                                return el__.store_id ===el;
                            })
                            if (checkitem.length<=0){
                                newItem.store_ids.push({ amount: 0, store_id: el })
                            }
                        });


                        let a_ = Math.floor(total / newItem.store_ids.length);
                        let setformValue = {};
                        let b_ = total - (a_ * newItem.store_ids.length);

                        newItem.store_ids = newItem.store_ids.map((elstore, istore) =>
                        {
                            if (istore === (newItem.store_ids.length - 1)) {
                                elstore = { ...elstore, amount: a_ + b_ }
                                setformValue = { ...setformValue, [`amount_price_item_d_${key_}_${istore}`]: a_ + b_ }
                            } else {
                                elstore = { ...elstore, amount: a_ }
                                setformValue = { ...setformValue, [`amount_price_item_d_${key_}_${istore}`]: a_ }
                            }


                            return elstore;
                        });
                        this.formRef.current.setFieldsValue({ ...setformValue });
                        

                    }
                    // result[key_] = newItem.store_ids;
                    return newItem;
                }


            }));
        }
        const newDataClassifyx = await updateItemValue(dataClassifyx, 0);
    

        // const dataClassifyStoreIds = await this.setClassifyStoreId(newDataClassifyx);
        this.setState(state =>
        {
            return {
                dataClassifyx: newDataClassifyx
            }
        },()=>{
          
        })
    }

    setClassifyStoreId = async (classifys) =>
    {
       
        let arrParent = {}
        let result  = {}
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {

            return Promise.all(data.map(async (item, indi) =>
            {
                let newItem = { ...item };

                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }

                    let newItemc = await updateItemValue(item.children, level + 1, item);
                    let newItem = { ...item, children: newItemc };
                    return newItem;
                } else {
                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }

                    tmp.push({ ...item, children: [] });
                    let key_ = "";
                    tmp.map((d_, ide_) =>
                    {
                        key_ += d_.key
                    })

                   
                        const stores_ = newItem.store_ids;
                       
                        result[key_] = stores_

                    

                    return newItem;
                }


            }));
        }
        const newDataClassifyx = await updateItemValue(classifys, 0);
     
        return result;
    }
    onChangeDiscountItem = (e, type, key) =>
    {
        const formvalues = this.formRef.current.getFieldsValue();
        let value = '';
        if (e.target) {
            value = e.target.value
        }
        else {
            value = e
        }
        let discount = 0;
        let discount_percent = 0;
        
        if (formvalues["price_" + key]) {
            discount_percent = formvalues["discount_percent_" + key];
            discount = formvalues["discount_" + key];;
            if (type === "discount") {
                value = replaceThous(value);
                discount_percent = (value / formvalues["price_"+key]) * 100;

                discount = value;
            } else if (type === "discount_percent") {
                discount_percent = value;
                discount = formvalues["price_" + key] * (discount_percent / 100);
            }

            if (!validateNUmber(discount)) {
                discount = 0;
            }
            let discount_price = formvalues["price_" + key] - discount;

            this.formRef.current.setFieldsValue({
                [`discount_${key}`]: discount, [`discount_percent_${key}`]: discount_percent, [`discount_price_${key}`]: discount_price
            })
            // this.setState(state =>
            // {
            //     return {
            //         record: { ...state.record, discount: discount, discount_percent: discount_percent, discount_price: discount_price },
            //         dataAllInOne: { ...state.dataAllInOne, discount: discount, discount_percent: discount_percent, discount_price: discount_price },
            //     }
            // })
        } else {
            message.warn("Bạn chưa nhập giá!");

        }

    }
    onChangeBuymore = (e, type, index, isnumber = false) =>
    {

        let value = '';
        let { buyMoreDiscout } = this.state
        if (e.target) {
            value = e.target.value
        }
        else {
            value = e
        }
        if (isnumber) {
            value = replaceThous(value)

            if (!validateNUmber(value)) {
                return
            }

        }
        buyMoreDiscout[index][type] = value;

        this.setState(state =>
        {
            return {
                buyMoreDiscout: buyMoreDiscout
            }
        })
    }
    deleteBuymore = (item) =>
    {
        let result = '';
        let { buyMoreDiscout } = this.state;
        result = buyMoreDiscout.filter(item_ =>
        {
            return item_ != item
        });

        this.setState(state =>
        {
            return {
                buyMoreDiscout: result
            }
        })
    }
    SubmitChangeAllx = async () =>
    {
        let { dataClassifyx, dataAllInOne } = this.state;
        const formvalues = this.formRef.current.getFieldsValue();
        const price = formvalues.price;
        const store_id = formvalues.store_id;
        const amount = formvalues.amount;
        const discount = formvalues.discount;
        const discount_percent = formvalues.discount_percent;
        const discount_price = formvalues.discount_price;
       
        let dataset={};
        let arrParent = {}
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {
            let check = false;

            return Promise.all(data.map(async (item, indi) =>
            {


                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }
                    await updateItemValue(item.children, level + 1, item);
                } else {
                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }

                    tmp.push({ ...item, children: [] });
                    let key_ = "";
                    tmp.map((d_, ide_) =>
                    {
                        key_ += d_.key
                    })
                    item.price = price;
                    item.store_id = store_id;
                    item.amount = amount;
                    item.discount = discount;
                    item.discount_percent = discount_percent;
                    item.discount_price = discount_price;
                    let total = formvalues[`amount_price_${key_}`];
                    console.log('====================================');
                    console.log("total", total);
                    console.log('====================================');
                    let a_ = Math.floor(total / item.store_id.length);
                    let b_ = total - (a_ * item.store_id.length);
                    let store_ids=[];
                    console.log('====================================');
                    console.log("a_", a_);
                    console.log('====================================');
                    let setformValue={};
                    item.store_id.map((elstore, instore) => {
                        if (instore === (item.store_id.length - 1)) {
                           
                            store_ids.push({ amount:a_ + b_, store_id: elstore })
                            setformValue = { ...setformValue, [`amount_price_item_d_${key_}_${instore}`]: a_ + b_ }
                        } else {
                           
                            store_ids.push({ amount: a_ , store_id: elstore })
                            setformValue = { ...setformValue, [`amount_price_item_d_${key_}_${instore}`]: a_ }
                        }
                       
                    });
                    item.store_ids = store_ids
                  
                   
                    this.formRef.current.setFieldsValue({ ...setformValue });

                    dataset = { ...dataset, 
                        [`price_${key_}`]: item.price,
                        [`discount_${key_}`]: item.discount, 
                        [`discount_percent_${key_}`]: item.discount_percent, 
                        [`discount_price_${key_}`]: item.discount_price,
                        [`amount_price_${key_}`]: item.amount,
                        [`house_${key_}`]: item.store_id,
                        [`code_${key_}`]: item.code 
                    }
                }

                return item;
            }));
        }
        const dataClassifyxnew= await updateItemValue(dataClassifyx, 0);
        this.formRef.current.setFieldsValue({ ...formvalues, ...dataset })
        this.setState(state =>
        {
            return {
                dataClassifyx: dataClassifyxnew
            }
        })

    }
  
   
 
    addItem = (e, type, parent_id) =>
    {
        let index = 0;
        const formvalues = this.formRef.current.getFieldsValue();
        if (!formvalues[type]) {
            return;
        }
        e.preventDefault();
        if (type === "tagadd") {
            this.props.StoreTagsRequest({
                "name": formvalues[type],
                "code": "",
                "type": "0",
                "hidden": "0",
                "deleted": "0"
            })
            return;
        }
        if (type.includes("group_classify_")) {
            this.props.StoreClassifyRequest({
                "name": formvalues[type],
                "parent_id": 0,
                "rank": 1,
                "code": "",
                "deleted": "0"
            })
            return;
        }
        if (type.includes("item_classify_")) {
            console.log('====================================');
            console.log(formvalues[parent_id]);
            console.log('====================================');
            this.props.StoreClassifyRequest({
                "name": formvalues[type],
                "parent_id": formvalues[parent_id],
                "rank": 1,
                "code": "",
                "deleted": "0"
            })
            return;
        }
      
        this.props.StoreAttributeProductsRequest({
            "name": formvalues[type],
            "code": "",
            "parent_id": parent_id,
            "value": 0,
            "rank": 1,
            "deleted": "0",
            "hidden": "0",
            "description": ""
        })


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
       

        if (nextProps.Web.ListProducts && nextProps.Web.ListProducts.id && nextProps.Web.ListProducts !== prevState.record) {

            return {
                record: nextProps.Web.ListProducts,

            };

        }
        
        if (nextProps.Web.ListInventory && nextProps.Web.ListInventory.data !== prevState.ListInventory) {

            return {
                ListInventory: nextProps.Web.ListInventory.data,

            };

        }
        if (nextProps.Web.StoreTags && nextProps.Web.StoreTags !== prevState.StoreTags) {
            return {
                StoreTags: nextProps.Web.StoreTags,

            };

        }
        if (nextProps.Web.StoreProducts && nextProps.Web.StoreProducts !== prevState.StoreProducts) {


            return {
                StoreProducts: nextProps.Web.StoreProducts,

            };

        }

        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        if (nextProps.Web.StoreClassifys && nextProps.Web.StoreClassifys !== prevState.StoreClassifys) {
            return {
                StoreClassifys: nextProps.Web.StoreClassifys,

            };

        }


        if (nextProps.Web.StoreAttributeProducts && nextProps.Web.StoreAttributeProducts !== prevState.StoreAttributeProducts) {

            return {
                StoreAttributeProducts: nextProps.Web.StoreAttributeProducts,

            };

        }

        if (nextProps.match && nextProps.match.params.id !== prevState.params) {

            return {
                params: nextProps.match.params.id,

            };

        }
        if (Runfirst) {
            for (let x in Runfirst) {
                if (nextProps.Web[x] && nextProps.Web[x].data  && nextProps.Web[x].data !== prevState[x]) {
                    // let productsData = localStorage.getItem("productsData");

                    // if (productsData) {
                    //     productsData = JSON.parse(productsData);
                    //     if (!productsData[x]?.data) {
                    //         productsData[x] = nextProps.Web[x];
                    //         localStorage.setItem("productsData", JSON.stringify(productsData));
                    //     }

                    // } else {
                    //     localStorage.setItem("productsData", JSON.stringify({ [x]: nextProps.Web[x] }));
                    // }
                
                   
                    return {
                        [x]: nextProps.Web[x].data,

                    };

                }

            }

        }
        return null;

    }
    async componentDidUpdate (prevProps, prevState)
    {

        try {

            if (this.state.params !== prevState.params) {

                if (this.state.params === "add" && !this.state.isReseted && this.state.record.id) {

                    this.resetState();
                    this.setState(state =>
                    {
                        return {
                            isReseted: true
                        }
                    })
                }

            }
            if (this.state.InfoPackageActive !== prevState.InfoPackageActive) {

                const { InfoPackageActive } = this.state;
                this.formRef.current.setFieldsValue({
                    weight: InfoPackageActive?.[0]?.weight,
                    long: InfoPackageActive?.[0]?.long,
                    height: InfoPackageActive?.[0]?.height,
                    width: InfoPackageActive?.[0]?.width,
                    wide: InfoPackageActive?.[0]?.wide
                });
                countData++;
                if (countData >= 5) {
                    this.setState(state =>
                    {
                        return {
                            isloadingLoadData: false,

                        }
                    });
                    countData = 0
                }
            }
            if (this.state.ListCategory !== prevState.ListCategory) {
                this.formRef.current.setFieldsValue({ category_id: this.state.record?.category_id });
                countData++;
                if (countData >= 5) {
                    this.setState(state =>
                    {
                        return {
                            isloadingLoadData: false,

                        }
                    });
                    countData = 0
                }
            }
            if (this.state.ListTags !== prevState.ListTags) {
                this.formRef.current.setFieldsValue({ tags_id: this.state.record?.tags_id });
                countData++;
                if (countData >= 5) {
                    this.setState(state =>
                    {
                        return {
                            isloadingLoadData: false,

                        }
                    });
                    countData = 0
                }
            }
            if (this.state.ListClassifys !== prevState.ListClassifys) {
                const { classifyx }=this.state;
             
                this.setState(state =>
                {
                    return {
                        classifyx: classifyx
                    }
                })
               
            }
            if (this.state.ListInventory !== prevState.ListInventory) {
                this.formRef.current.setFieldsValue({ inventory_id: this.state.record?.inventory_id });
                countData++;
                if (countData >= 5) {
                    this.setState(state =>
                    {
                        return {
                            isloadingLoadData: false,

                        }
                    });
                    countData = 0
                }
            }
            if (this.state.ListAttributeProducts !== prevState.ListAttributeProducts) {
                const attribute_products_id = this.state.record.attribute_products_id;

                const attribute_products_idset = {};
                for (let x in attribute_products_id) {
                    attribute_products_idset["attribute_products_id" + x] = (1 * attribute_products_id[x]);
                }

                this.formRef.current.setFieldsValue(attribute_products_idset);
                countData++;
                if (countData >= 5) {
                    this.setState(state =>
                    {
                        return {
                            isloadingLoadData: false,

                        }
                    });
                    countData = 0
                }
            }
            if (this.state.record.id !== prevState.record.id) {
                const { record,ListClassifys } = this.state;
           
                record.classifyxOr.map((item, index) => {
                    ListClassifys.map((el, ind) => {
                        if (parseInt(item.id) ===parseInt(el.id)){
                            record.classifyxOr[index].data = el.children
                        }
                    });
                    
                });
                // const dataClassifyStoreIds= await this.setClassifyStoreId(record.classifys);
               

                let avata = [{
                    uid: -1,
                    name: record.thumbnail[0],
                    status: 'done',
                    url: LINK.myHostimgServerImg + "/" + record.thumbnail[0],
                }];
                let video = record.video[0] ? [{
                    uid: -1,
                    name: record.video[0],
                    status: 'done',
                    url: LINK.myHostimgServerVide + "/" + record.video[0],
                }] : [];
                let detail = [];
                record.images.map((item, index) =>
                {
                    detail.push({
                        uid: -1 * (index + 1),
                        name: item,
                        status: 'done',
                        url: LINK.myHostimgServerImg + "/" + item,
                    })
                });


                const buymore = Object.values(record.price_range);

                if (buymore.length > 0) {
                    for (let x in buymore) {
                        const element = buymore[x];
                        for (var y in element) {
                            record["buymore-" + y + "-" + x] = element[y]
                        }

                    }
                }
                this.editor.current.value = record.detail
                this.setState(state =>
                {
                    return {
                        imgesInfo: { avata: avata, detail: detail, video: video },
                        buyMoreDiscout: Object.values(record.price_range),
                        classifyx: record.classifyxOr ? record.classifyxOr:[],
                        dataClassifyx: record.classifys ? record.classifys:[],
                        record: { ...state.record },
                        isloadingLoadData: false,
                        // dataClassifyStoreIds: dataClassifyStoreIds
                    }
                }, () =>
                {
                   
                    const attribute_products_id = this.state.record.attribute_products_id;

                    const attribute_products_idset = {};
                    for (let x in attribute_products_id) {
                        attribute_products_idset["attribute_products_id" + x] = (1 * attribute_products_id[x]);
                    }
                    const classifyxOr_set = {};
                    for (let x in record.classifyxOr) {
                        const item = record.classifyxOr[x]
                        classifyxOr_set[`group_classify_${item.key}`] = parseInt(item.id);
                        if (item.items){
                            for (let y in item.items) {
                                const el = item.items[y]
                                classifyxOr_set[`item_classify_${el.key}`] = parseInt(el.id);
                            }
                        }
                        
                    }
                    this.formRef.current.setFieldsValue({ ...this.state.record,  ...attribute_products_idset, ...classifyxOr_set });
                 

                })
            }
            if (this.state.StoreClassifys !== prevState.StoreClassifys) {
                let { StoreClassifys } = this.state;

                if (StoreClassifys.code === 200) {
                    if (StoreClassifys.message) {
                        message.success(StoreClassifys.message)
                    }

                    this.props.ListClassifysRequest({
                        limit: this.state.meta.per_page*100,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    });
                }
                else {
                    if (StoreClassifys.message) {
                        message.error(StoreClassifys.message)
                    }

                }
                // this.props.ResetStoreOrganizationProduction()

            }
            if (this.state.StoreTags !== prevState.StoreTags) {
                let { StoreTags } = this.state;
                if (StoreTags.code === 200) {
                    if (StoreTags.message) {
                        message.success(StoreTags.message)
                    }
                    this.props.ListTagsRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    });
                }
                else {
                    if (StoreTags.message) {
                        message.error(StoreTags.message)
                    }

                }

            }
            if (this.state.StoreAttributeProducts !== prevState.StoreAttributeProducts) {
                let { StoreAttributeProducts } = this.state;

                if (StoreAttributeProducts.code === 200) {
                    if (StoreAttributeProducts.message) {
                        message.success(StoreAttributeProducts.message)
                    }
                    this.props.ListAttributeProductsRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    });
                }
                else {
                    if (StoreAttributeProducts.message) {
                        message.error(StoreAttributeProducts.message)
                    }

                }
                this.setState(state =>
                {
                    return {
                        addAttributeProducts: ""
                    }
                })
                this.props.ResetStoreAttributeProducts()

            }

            if (this.state.StoreProducts !== prevState.StoreProducts) {
                let { StoreProducts } = this.state;
                try {
                    if (StoreProducts.code === 200) {
                        if (StoreProducts.message && typeof StoreProducts.message === "string") {
                            message.success(StoreProducts.message)

                        } else {

                            message.error("Có lỗi!")
                        }

                    }
                    else {
                        if (StoreProducts.message && typeof StoreProducts.message === "string") {

                            message.error(StoreProducts.message)
                        } else {
                            for (let x in StoreProducts.message) {
                                let mess = StoreProducts.message[x];
                                for (let y in mess) {
                                    if (mess[y]) {
                                        message.error(mess[y])
                                    }

                                }
                            }
                            // message.error("Có lỗi!")
                        }

                    }

                } catch (error) {
                    message.error("Có lỗi!")
                    this.SetisLoading(false)
                }

                this.SetisLoading(false)
                this.setState(state =>
                {
                    return {
                        StoreProducts: ""
                    }
                })
                this.props.ResetStoreProducts();

            }
            if (this.state.UploadFileLa !== prevState.UploadFileLa) {
                let { UploadFileLa, record } = this.state;

                if ((1 * UploadFileLa.type) === 1) {
                    if (UploadFileLa.code !== 200) {

                        message.error("Cập nhật ảnh chính thất bại!");
                        // return;
                    } else {
                        record.thumbnail = UploadFileLa.data
                        this.setState(state =>
                        {
                            return {
                                record: record
                            }
                        }, () =>
                        {

                            if (this.state.isupload === 1) {
                                this.onFinishForm(this.state.values);
                            }
                        })
                    }


                }
                if ((1 * UploadFileLa.type) === 2) {
                    if (UploadFileLa.code !== 200) {

                        message.error("Cập nhật ảnh chi tiết thất bại!");
                        // return;
                    } else {
                        record.images = [...record.images, ...UploadFileLa.data]
                        this.setState(state =>
                        {
                            return {
                                record: record
                            }
                        }, () =>
                        {
                            if (this.state.isupload === 2) {
                                this.onFinishForm(this.state.values);
                            }
                        })
                    }

                }
                if ((1 * UploadFileLa.type) === 3) {

                    if (UploadFileLa.code !== 200) {

                        message.warning("Cập nhật video thất bại!");
                        // return;
                    } else {
                        record.video = UploadFileLa.data
                        this.setState(state =>
                        {
                            return {
                                record: record
                            }
                        }, () =>
                        {
                            if (this.state.isupload === 3) {
                                this.onFinishForm(this.state.values);
                            }
                        })
                    }

                }
                this.props.ResetUploadFileLa();
            }

        } catch (error) {
            console.error(error);
        }
    }

    componentDidMount = async () =>
    {

        try {
            this.generateCombinations(this.state.classifyx)
            this.props.ListInventoryRequest({
                limit: this.state.meta.per_page * 10,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            })
            this.connectSocket();

          

            // let productsData = localStorage.getItem("productsData");

            // if (productsData) {

            //     productsData = JSON.parse(productsData);
            // } else {
            //     productsData = {}
            // }
            let ListCategory = [];
            let ListTags = []
            let ListInfoTransport = [];
            let ListStore = [];
            let ListClassifys = [];
            let InfoPackageActive = [];
            let ListAttributeProducts = [];
            const runfirstdata = {};
            // if (productsData) {

                for (let x in Runfirst) {
                    // if (!productsData[x] || !productsData[x].data) {
                        const filterDataCate = {
                            data: {
                                name: "",
                                slug: "",
                                ischild: "",
                                isparent: "",
                                type: 0,
                                position: "",
                                created_at: ""
                            }
                        }
                        let dataF = [];
                        if (x === "ListCategory") {
                            dataF = filterDataCate;
                        }
                        Runfirst[x](this.props, this.state, dataF)
                    // } else {
                    //     runfirstdata[x] = productsData[x].data
                    // }

                }
            // }
            // console.log('====================================');
            // console.log(runfirstdata);
            // console.log('====================================');
            // this.setState(state =>
            // {
            //     return { ...state, ...runfirstdata }
            // })
            const { match } = this.props;
            const { params } = match;
            const id = (1 * params.id); // Lấy giá trị của tham số từ URL

            if (id && typeof id === "number") {
                this.props.ProductByIdRequest({ id });
                this.setState(state =>
                {
                    return {
                        isloadingLoadData: true
                    }
                })
            }
        } catch (error) {

        }

    }
    componentWillUnmount()
    {
        this.state.socket.close();
    }

    connectSocket = () =>
    {
        const newSocket = new WebSocket(linkSocket);

        newSocket.addEventListener('open', () =>
        {
            console.log('Connected to WebSocket server');
        });

        newSocket.addEventListener('message', (event) =>
        {

            let json = JSON.parse(event.data);

            switch (json.type) {
                case "scaner":
                    let user = getCookie("user");
                    user = JSON.parse(user);
                    if (user.id === json.user_id) {
                        this.setState(state =>
                        {
                            return {
                                record: { ...state.record, code: json.msg }
                            }
                        })
                    }
                    break;

                default:
                    break;
            }

        });

        this.setState(state =>
        {
            return { socket: newSocket }
        });
    }
    sendMessage = (message) =>
    {
        this.state.socket.send(message);
    }
  
  
  
    onChangetext = (e, type, group, iswait = true) =>
    {
        if (!iswait) {
            let target = e.target;
            if (target) {
                let value = target.value;
                let name = target.name;


                this.setState(state =>
                {
                    return {
                        [group]: { ...state[group], [name]: value }
                    }
                })
            }
            else {

                if (type === "info_transport_id") {

                    let valtmp = this.state.ListInfoTransport.filter(item =>
                    {
                        return item.id === e
                    })

                    this.setState(state =>
                    {
                        return {
                            [group]: { ...state[group], [type]: e, fee_ship: valtmp[0].price },
                            transportInfo: valtmp[0]
                        }
                    })

                } else if (type === "attribute_products_id") {

                    this.setState(state =>
                    {
                        return {
                            [group]: { ...state[group], [type]: state[group][type] ? [...state[group][type], e] : [e] }

                        }
                    })

                } else {

                    this.setState(state =>
                    {
                        return {
                            [group]: { ...state[group], [type]: e }
                        }
                    })
                }

            }
        } else {


            let timeoutId;
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() =>
            {

                let target = e.target;
                if (target) {
                    let value = target.value;
                    let name = target.name;


                    this.setState(state =>
                    {
                        return {
                            [group]: { ...state[group], [name]: value }
                        }
                    })
                }
                else {

                    if (type === "info_transport_id") {
                        let valtmp = this.state.ListInfoTransport.filter(item =>
                        {
                            return item.id === e
                        })

                        this.setState(state =>
                        {
                            return {
                                [group]: { ...state[group], [type]: e, fee_ship: valtmp[0].price },
                                transportInfo: valtmp[0]
                            }
                        })


                    } else if (type === "attribute_products_id") {

                        this.setState(state =>
                        {
                            return {
                                [group]: { ...state[group], [type]: state[group][type] ? [...state[group][type], e] : [e] }

                            }
                        })

                    } else {
                        console.log('====================================');
                        console.log(group, type, e);
                        console.log('====================================');
                        this.setState(state =>
                        {
                            return {
                                [group]: { ...state[group], [type]: e }
                            }
                        })
                    }

                }
                console.log("3 giây đã trôi qua sau khi ngừng nhập!");
            }, 3000);
        }

    }



    showModalTran = () =>
    {
        this.setState({
            ismodalTrans: true,
        });
    };

    handleTranOk = e =>
    {
        console.log(e);
        this.setState({
            ismodalTrans: false,
        });
    };

    handleTranCancel = e =>
    {
        console.log(e);
        this.setState({
            ismodalTrans: false,
        });
    };

    CheckDataRequired = () =>
    {
        const { imgesInfo, record, dataClassifyx, classify1, classify2, required } = this.state;

        if (imgesInfo.avata.length <= 0) {
            message.warn("Hình ảnh chính là bắt buộc");
            this.setState(state =>
            {
                return {
                    required: { ...state.required, avata: true }
                }
            })

            return;
        }
        // let classifys = this.createCalssifys(dataClassify);
        if (Object.values(dataClassifyx).length <= 0) {
            message.warn("Có ít nhất 1 phân loại sản phẩm");
            this.setState(state =>
            {
                return {
                    required: { ...state.required, classify1: true }
                }
            })

            return;
        }

        return true;

    }
    uploadFile = (values) =>
    {
        const { imgesInfo, record, dataClassify, classify1, classify2, } = this.state;


        this.setState(state =>
        {
            return {
                required: {},
                values: values
            }
        })
        if (!this.CheckDataRequired()) {
            return;
        }

        this.SetisLoading(true)
        let isupload = 0;
        // this.SetisLoading(true)
        if (imgesInfo.avata.length > 0) {
            let arravata = imgesInfo.avata.filter(item =>
            {
                return item.originFileObj;
            })
            if (arravata.length > 0) {
                this.props.UploadFileLaRequest(arravata, this.state.folder, 1);
                isupload = 1;
            }

        }
        if (imgesInfo.detail.length > 0) {
            let arrdetail = imgesInfo.detail.filter(item =>
            {

                return item.originFileObj;
            })
            if (arrdetail.length > 0) {
                this.props.UploadFileLaRequest(arrdetail, this.state.folder, 2);
                isupload = 2;
            }
        }
        if (imgesInfo.video.length > 0) {
            let arrvideo = imgesInfo.video.filter(item =>
            {
                return item.originFileObj;
            })
            if (arrvideo.length > 0) {
                this.props.UploadFileLaRequest(arrvideo, "video", 3);
                isupload = 3;
            }
        }

        this.setState(state =>
        {
            return {
                isupload: isupload,
            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }

    onFinishForm = async (values) =>
    {

        let { record, classifyx, buyMoreDiscout,dataClassifyx } = this.state;

        record = { ...record, ...values };
        let user = getCookie("user");
        user = JSON.parse(user);

        record.user_id = user.id;
        record.detail = this.editor.current.value;
        record.discount = record.discount === null ? 0 : record.discount;
        record.discount_percent = record.discount_percent === null ? 0 : record.discount_percent;
        record.imgesInfoRemove = this.state.imgesInfoRemove.filter(item =>
        {
            return !item.originFileObj
        });
        const attribute_products_id = [];
        const buymore = {};
        for (let x in values) {
            if (x.substring(0, x.length - 1) === 'attribute_products_id') {
                if (values[x]) {
                    attribute_products_id.push(values[x]);
                }

            }
            const tmpKeys = x.split("-");
            if (tmpKeys[0] === 'buymore') {
                if (values[x]) {
                    if (!buymore[tmpKeys[tmpKeys.length - 1]]) {
                        buymore[tmpKeys[tmpKeys.length - 1]] = {};
                    }
                    buymore[tmpKeys[tmpKeys.length - 1]][tmpKeys[tmpKeys.length - 2]] = values[x];
                }

            }
        }
      
        let arrParent = {}
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {
         
            return Promise.all(data.map(async (item, indi) =>
            {
                let  newItem = { ...item};
               
                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }
                   
                    let newItemc =  await updateItemValue(item.children, level + 1, item);
                    let newItem = { ...item, children: newItemc };
                    return newItem;
                } else {
                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }

                    tmp.push({ ...item, children: [] });
                    let key_ = "";
                    tmp.map((d_, ide_) =>
                    {
                        key_ += d_.key
                    })
                   

                    let newItem = { ...item,
                        price: values[`price_${key_}`],
                        store_id: values[`house_${key_}`],
                        amount: values[`amount_price_${key_}`],
                        discount: values[`discount_${key_}`],
                        discount_percent: values[`discount_percent_${key_}`],
                        discount_price: values[`discount_price_${key_}`],
                        sku: values[`sku_${key_}`]
                     }
                    newItem.store_ids = newItem.store_ids.map((elstore, istore) =>
                    {
                       
                        elstore = { ...elstore, amount: values[`amount_price_item_d_${key_}_${istore}`] }
                        return elstore;
                    });
                  
                    return newItem;
                }
               
                
            }));
        }
       
        const newDataClassifyx =await updateItemValue(dataClassifyx, 0);

      
        const classifyxOr = classifyx.map((item, index) => {
            const newitem={...item,children:[],data:[]}
            return newitem;
        });
        
        console.log('====================================');
        console.log("classifyxclassifyxclassifyx", classifyxOr, newDataClassifyx);
        console.log('====================================');
       
        record.price_range = buymore;

        const record_ = { ...record, deleted: 0, attribute_products_id: attribute_products_id, classifys: newDataClassifyx, classifyxOr: classifyxOr }
   
        this.SetisLoading(true)
        this.props.StoreProductsRequest(record_, record_.id ? record_.id : "")

    }

    createCalssifys = (dataClassify) =>
    {
        let classifys = {};
        if (dataClassify) {
            dataClassify.map((item, index) =>
            {
                let tmp1 = {};

                if (item.children) {
                    item.children.map((el, i) =>
                    {
                        if (el.store_id) {
                            el.store_id.map((w, iw) =>
                            {
                                if (!classifys[w]) {
                                    classifys[w] = {};
                                }
                                if (!classifys[w][item.id]) {
                                    classifys[w][item.id] = { children: [] };
                                }
                                classifys = {
                                    ...classifys, [w]: {
                                        ...classifys[w], [item.id]: {
                                            classify_products_id: item.id,
                                            price: item.price,
                                            amount: item.amount,
                                            store_id: w,
                                            discount: item.discount ? item.discount : 0,
                                            discount_percent: item.discount_percent ? item.discount_percent : 0,
                                            children: [...classifys[w][item.id].children, {
                                                classify_products_id: el.id,
                                                price: el.price,
                                                amount: el.amount,
                                                store_id: w,
                                                discount: el.discount ? el.discount : 0,
                                                discount_percent: el.discount_percent ? el.discount_percent : 0,
                                            }]
                                        }
                                    }
                                }


                            })
                        }

                    })
                } else {
                    if (item.store_id) {
                        console.log('====================================');
                        console.log("item.store_iditem.store_id", item.store_id);
                        console.log('====================================');
                        item.store_id.map((w, iw) =>
                        {
                            if (!classifys[w]) {
                                classifys[w] = {};
                            }
                            if (!classifys[w][item.id]) {
                                classifys[w][item.id] = { children: [] };
                            }
                            classifys = {
                                ...classifys, [w]: {
                                    ...classifys[w], [item.id]: {

                                        classify_products_id: item.id,
                                        price: item.price,
                                        amount: item.amount,
                                        store_id: w,
                                        discount: item.discount ? item.discount : 0,
                                        discount_percent: item.discount_percent ? item.discount_percent : 0,

                                    }
                                }
                            }
                        });
                    }
                }


            });
        }

        return classifys;

    }

    handleSearchInven = (value) =>
    {



    }

    
    render()
    {
       
        return (
            <>
                {this.state.isloading || this.state.isloadingLoadData && <div className="ovelay">
                  
                    <div className="loading">
                        <LoadingSmile height={"100%"} width={"100%"} />
                    </div>
                </div>
                }

                <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>


                    <div className="table_w" >
                        <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết sản phẩm</span>
                        </div>} bordered={false}
                            style={{ width: "100%" }}
                            bodyStyle={{
                                padding: "10px 10px",
                            }}
                            headStyle={{
                                backgroundColor: Colors.colorgrey,
                                display: "flex",
                                minHeight: "30px",
                                borderRadius: "3px"
                            }}
                        >


                            <Form
                                labelCol={{
                                    // span: 4,
                                }}
                                wrapperCol={{

                                }}
                                layout="vertical"
                                name="formadd" onFinish={this.uploadFile} ref={this.formRef}
                                style={{
                                    padding: 0
                                }}
                            >
                                <div className="row" style={{ padding: 0 }}>

                                    <div className="col-sm-12 col-md-6 col-lg-8" style={{ padding: 0 }}>
                                        <Card
                                            style={{
                                                width: "100%",
                                                marginTop: "10px"
                                            }}
                                            bodyStyle={{
                                                padding: "10px 10px",
                                                position: "relative"
                                            }}
                                            headStyle={{
                                                backgroundColor: Colors.colorgrey,
                                                display: "flex",
                                                minHeight: "30px",
                                            }}
                                            className="card_pr_order"
                                            title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "#fafafa" }}><FileImageOutlined />
                                                <span style={{ marginLeft: "10px" }}>Hình ảnh</span>
                                            </div>} bordered={true}
                                        >
                                            <div className="section__ section_img">

                                                <Form.Item label="Phiếu nhập kho" name={"inventory_id"}

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Phiếu nhập kho là bắt buộc"
                                                        },
                                                    ]}>
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}

                                                        onSearch={this.handleSearchInven}
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="Phiếu nhập kho"
                                                        // allowClear
                                                        // multiple
                                                        options={(this.state.ListInventory || []).map((d) => ({
                                                            value: d.id,
                                                            label: d.code,
                                                        }))}

                                                    />

                                                </Form.Item>

                                            </div>
                                            <div className="section__ section_img">
                                                <Upload isCropImg={false} multiple={false} max={1} type_={"avata"} focus={this.state.required.avata} type_f={1} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} fileList={this.state.imgesInfo.avata} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} />
                                                <Upload isCropImg={false} multiple={true} max={8} type_={"detail"} type_f={2} fileList={this.state.imgesInfo.detail} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh chi tiết" icon={<FileImageOutlined />} />
                                                <Upload isCropImg={false} setimgesInfoRemove={this.setimgesInfoRemove} multiple={false} max={1} type_={"video"} type_f={3} fileList={this.state.imgesInfo.video} setimgesInfo={this.setimgesInfo} accept="video/mp4,video/x-m4v,video/*" title="Video bìa" icon={<VideoCameraOutlined />} />
                                            </div>

                                        </Card>

                                        <div className="section__ section_img">
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px"
                                                }}
                                                bodyStyle={{
                                                    padding: "10px 10px",
                                                    position: "relative"
                                                }}
                                                headStyle={{
                                                    backgroundColor: Colors.colorgrey,
                                                    display: "flex",
                                                    minHeight: "30px",
                                                }}
                                                className="card_pr_order"
                                                title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "#fafafa" }}><InfoCircleOutlined />
                                                    <span style={{ marginLeft: "10px" }}>Thông tin cơ bản</span>
                                                </div>} bordered={true}
                                            >
                                                <Form.Item label="Tên sản phẩm" name="title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Tên sản phẩm là bắt buộc"
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Tên sản phẩm"
                                                        showCount maxLength={120} onChange={(e) =>
                                                        {
                                                            this.formRef.current.setFieldsValue({ slug: createSlug(e.target.value) })
                                                        }} />
                                                </Form.Item>
                                                <Form.Item label="Slug" name="slug"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Slug là bắt buộc"
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Slug"
                                                        showCount maxLength={120} />
                                                </Form.Item>
                                                <Form.Item label="Danh mục" name={"category_id"}

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Danh mục là bắt buộc"
                                                        },
                                                    ]}>
                                                    <TreeSelect
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        treeData={this.state.ListCategory}

                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="Danh mục"
                                                        // allowClear
                                                        multiple
                                                        treeDefaultExpandAll


                                                    />

                                                </Form.Item>
                                                <Form.Item label="Tags" name="tags_id" rules={[
                                                    {
                                                        required: true,
                                                        message: "Tag là bắt buộc"
                                                    },
                                                ]} >
                                                    <Select
                                                        mode="multiple"
                                                        maxTagCount='responsive'
                                                        style={{ width: '100%', }}

                                                        placeholder="Tags"
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider
                                                                    style={{
                                                                        margin: '8px 0',
                                                                    }}
                                                                />
                                                                <Space
                                                                    align="center"
                                                                    style={{
                                                                        padding: '0 8px 4px',
                                                                    }}
                                                                >
                                                                    <Form.Item name={`tagadd`} >
                                                                        <Input placeholder="nhập tên tags" />
                                                                    </Form.Item>
                                                                    <Typography.Link
                                                                        onClick={(event) => this.addItem(event, "tagadd")}
                                                                        style={{
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                                    </Typography.Link>
                                                                </Space>
                                                            </>
                                                        )}

                                                        options={(this.state.ListTags || []).map((d) => ({
                                                            value: d.id,
                                                            label: d.name,
                                                        }))}


                                                    />

                                                </Form.Item>
                                                <Form.Item label="Mô tả ngắn" name={"description"} rules={[
                                                    {
                                                        required: true,
                                                        message: "Mô tả ngắn là bắt buộc"
                                                    },
                                                ]} >
                                                    <TextArea />

                                                </Form.Item>
                                                <Form.Item label="Mô tả chi tiết" >
                                                    <Editor editor={this.editor} />

                                                </Form.Item>
                                            </Card>
                                        </div>
                                        <div className="section__ section_img">

                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px"
                                                }}
                                                bodyStyle={{
                                                    padding: "10px 10px",
                                                    position: "relative"
                                                }}
                                                headStyle={{
                                                    backgroundColor: Colors.colorgrey,
                                                    display: "flex",
                                                    minHeight: "30px",
                                                }}
                                                className="card_pr_order"
                                                title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "#fafafa" }}><SlidersOutlined />
                                                    <span style={{ marginLeft: "10px" }}>Thuộc tính sản phẩm</span>
                                                </div>} bordered={true}
                                            >
                                                {this.state.ListAttributeProducts && this.state.ListAttributeProducts.map((item, index) =>
                                                {

                                                    return <Form.Item label={item.name} key={index} name={"attribute_products_id" + item.id}

                                                    >
                                                        <Select

                                                            maxTagCount='responsive'
                                                            style={{

                                                            }}
                                                            placeholder={item.name}

                                                            dropdownRender={(menu) => (
                                                                <>
                                                                    {menu}
                                                                    <Divider
                                                                        style={{
                                                                            margin: '8px 0',
                                                                        }}
                                                                    />
                                                                    <Space
                                                                        align="center"
                                                                        style={{
                                                                            padding: '0 8px 4px',
                                                                        }}
                                                                    >
                                                                        <Form.Item name={`attr${index}`} >
                                                                            <Input placeholder="Please enter item"
                                                                           
                                                                            />
                                                                        </Form.Item>
                                                                        <Typography.Link
                                                                            onClick={(event) => this.addItem(event, `attr${index}`, item.id)}
                                                                            style={{
                                                                                whiteSpace: 'nowrap',
                                                                            }}
                                                                        >
                                                                            <PlusOutlined /> Add item
                                                                        </Typography.Link>
                                                                    </Space>
                                                                </>
                                                            )}

                                                            // value={CheckUnionArr(this.state.record.attribute_products_id, item.children, "id")}
                                                            // onChange={(e) => { this.onChangetext(e, "attribute_products_id", "record", false) }}
                                                            options={(item.children || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                        />


                                                    </Form.Item>
                                                })}
                                            </Card>
                                        </div>
                                        <div className="section__ section_img">
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px"
                                                }}
                                                bodyStyle={{
                                                    padding: "10px 10px",
                                                    position: "relative"
                                                }}
                                                headStyle={{
                                                    backgroundColor: Colors.colorgrey,
                                                    display: "flex",
                                                    minHeight: "30px",
                                                }}
                                                className="card_pr_order"
                                                title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "#fafafa" }}><ShoppingCartOutlined />
                                                    <span style={{ marginLeft: "10px" }}>Thông tin bán hàng</span>
                                                </div>} bordered={true}
                                            >
                                                {this.state.classifyx?.length > 0 && this.state.classifyx.map((el, ind) =>
                                                {
                                                   
                                                    return <Form.Item label={`Biến thể ${ind + 1}`} >

                                                        <div className="classify_pr_wr" style={{ backgroundColor: Colors.colorgrey, padding: "10px", border: this.state.required?.["classify" + ind] ? "dashed 1px red" : "" }}><div className="classify_pr">
                                                            <Form.Item
                                                                name={`group_classify_${el.key}`}
                                                                initialValue={el.id}
                                                                
                                                                >
                                                                    
                                                                <Select

                                                                    maxTagCount='responsive'
                                                                    style={{
                                                                        width: "100%"
                                                                    }}
                                                                    placeholder="Nhóm biến thể"
                                                                    onChange={(e) => { this.onchangeGroupClassifyx(e, ind) }}

                                                                    dropdownRender={(menu) => (
                                                                        <>
                                                                            {menu}
                                                                            <Divider
                                                                                style={{
                                                                                    margin: '8px 0',
                                                                                }}
                                                                            />
                                                                            <Space
                                                                                align="center"
                                                                                style={{
                                                                                    padding: '0 8px 4px',
                                                                                }}
                                                                            >
                                                                                <Form.Item noStyle name={`group_classify_add_${ind}`} >
                                                                                <Input placeholder="Nhập tên nhóm biến thể" />
                                                                                </Form.Item>
                                                                                <Typography.Link
                                                                                    onClick={(event) => this.addItem(event, `group_classify_add_${ind}`,0)}
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                    }}
                                                                                >
                                                                                    <PlusOutlined /> Thêm nhóm biến thể
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        </>
                                                                    )}

                                                                    options={(this.state.ListClassifys || []).map((d) => ({
                                                                        value: d.id,
                                                                        label: d.name,
                                                                        hidden: d.hidden
                                                                    }))}
                                                                />
                                                            </Form.Item>
                                                            <DragDropContext onDragEnd={(result) => this.onDragEnd(1, result)}>
                                                                <Droppable droppableId="droppable">
                                                                    {(droppableProvided, droppableSnapshot) => (
                                                                        <div
                                                                            ref={droppableProvided.innerRef}
                                                                            style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                                        >
                                                                            {el?.items?.map((item, index) =>
                                                                            (


                                                                                <DraggableB key={item.key} draggableId={item.key} index={index}>
                                                                                    {(draggableProvided, draggableSnapshot) => {

                                                                                      return  <div
                                                                                            ref={draggableProvided.innerRef}
                                                                                            {...draggableProvided.draggableProps}
                                                                                            {...draggableProvided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                draggableSnapshot.isDragging,
                                                                                                draggableProvided.draggableProps.style
                                                                                            )}
                                                                                        >
                                                                                            <div className="input_classfy_wrap" >
                                                                                                <Form.Item 
                                                                                                style={{
                                                                                                    width: "100%"
                                                                                                }} 
                                                                                                  name={`item_classify_${item.key}`}
                                                                                                    
                                                                                                >

                                                                                                    <Select

                                                                                                        maxTagCount='responsive'
                                                                                                        style={{
                                                                                                            width: "100%"
                                                                                                        }}
                                                                                                        placeholder="Danh sách biến thể"
                                                                                                        onChange={(e) => { this.onchangeItemClassifyx(e, item.key,ind) }}

                                                                                                        dropdownRender={(menu) => (
                                                                                                            <>
                                                                                                                {menu}
                                                                                                                <Divider
                                                                                                                    style={{
                                                                                                                        margin: '8px 0',
                                                                                                                    }}
                                                                                                                />
                                                                                                                <Space
                                                                                                                    align="center"
                                                                                                                    style={{
                                                                                                                        padding: '0 8px 4px',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Form.Item noStyle name={`item_classify_${index}`} >
                                                                                                                        <Input placeholder="Nhập tên biến thể" />
                                                                                                                    </Form.Item>
                                                                                                                    <Typography.Link
                                                                                                                        onClick={(event) => this.addItem(event, `item_classify_${index}`, `group_classify_${ind}`)}
                                                                                                                        style={{
                                                                                                                            whiteSpace: 'nowrap',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <PlusOutlined /> Thêm biến thể
                                                                                                                    </Typography.Link>
                                                                                                                </Space>
                                                                                                            </>
                                                                                                        )}

                                                                                                        options={(el.data || []).map((d) => ({
                                                                                                            value: d.id,
                                                                                                            label: d.name,
                                                                                                            hidden: d.hidden
                                                                                                        }))}
                                                                                                    />

                                                                                                </Form.Item>
                                                                                                <div className="input_classfy">
                                                                                                    <span className="icon_drag"><DragOutlined /></span><span className="icon_drag"><button className="reset_btn " onClick={() => this.DeleteclassifyxItem(ind, index)}><DeleteOutlined /></button></span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }}
                                                                                </DraggableB>
                                                                            )
                                                                            )}
                                                                            {droppableProvided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>

                                                            <Button type="dashed" primary="true" style={{ width: "100%" }} onClick={() => { this.setclassifyitemx(ind) }}>
                                                                <PlusCircleOutlined />
                                                            </Button>

                                                        </div>
                                                        </div>
                                                    </Form.Item>
                                                })
                                                }


                                                <Button type="dashed" primary="true" style={{ width: "100%" }} onClick={() => { this.setclassifyx() }}>
                                                    {/* <PlusCircleOutlined /> */}
                                                    Thêm 1 biến thể
                                                </Button>

                                                <Form.Item label="Mẹo thiết lập phân loại hàng">
                                                    <div className="row">
                                                        <div className="col_ col-lg-3 col-md-3 col-sm-12 mb-1">
                                                            <Form.Item
                                                                name={"price"}
                                                                style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Giá là bắt buộc"
                                                                    },
                                                                ]}
                                                            >
                                                                <Input addonBefore="₫" style={{ width: "100%" }} placeholder="Giá" onFocus={() => this.ColorCell("price_classify", true)} onBlur={() => { this.ColorCell("price_classify", false) }}

                                                                />

                                                            </Form.Item>
                                                        </div>
                                                        <div className="col_ col-lg-1 col-md-1 col-sm-12 mb-1">
                                                            <Form.Item
                                                                name={"amount"}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Số lượng là bắt buộc"
                                                                    },
                                                                ]}
                                                                style={{ margin: 0 }}
                                                            >
                                                                <InputNumber min={0} style={{ width: "100%" }} placeholder="SL" onFocus={() => this.ColorCell("amount_price_classify", true)} onBlur={() => { this.ColorCell("amount_price_classify", false) }}
                                                                // value={this.state.record.amount}
                                                                // onChange={(e) => { this.onChangeAllInOne(e, "amount", false) }}

                                                                />

                                                            </Form.Item>
                                                        </div>
                                                        <div className="col_ col-lg-3 col-md-3 col-sm-12 mb-1">
                                                            <Form.Item
                                                                name={"store_id"}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Kho hàng là bắt buộc"
                                                                    },
                                                                ]}
                                                                style={{ margin: 0 }}
                                                            >
                                                                <Select
                                                                    mode={"multiple"}
                                                                    maxTagCount='responsive'
                                                                    style={{ width: '100%', }}
                                                                    // value={this.state.dataAllInOne.store_id}
                                                                    placeholder="Cửa hàng"
                                                                    // name="Store" id="Store"
                                                                    // onChange={(e) => { this.onChangeAllInOne(e, "store_id") }}
                                                                    onFocus={() => this.ColorCell("house_classify", true)} onBlur={() => { this.ColorCell("house_classify", false) }}
                                                                    options={(this.state.ListStore || []).map((d) => ({
                                                                        value: d.id,
                                                                        label: d.name,
                                                                    }))}
                                                                />

                                                            </Form.Item>
                                                        </div>
                                                        <div className="col_ col-lg-5 col-md-5 col-sm-12 flex_">
                                                            <Form.Item
                                                                name={"code"}
                                                                style={{ margin: 0 }}
                                                            >
                                                                <Input style={{ color: "green", fontWeight: "bold", width: "100%" }}
                                                                    placeholder="SKU phân loại" onFocus={() => this.ColorCell("code_classify", true)} onBlur={() => { this.ColorCell("code_classify", false) }}
                                                                    // value={this.state.record.code}
                                                                    // onChange={(e) => this.onChangeAllInOne(e, "code")}

                                                                    disabled

                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{ margin: 0 }}
                                                            >
                                                                <Button type="primary" danger primary="true" onClick={() => { this.SubmitChangeAllx() }}>
                                                                    Áp dụng cho tất cả
                                                                </Button>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                  
                                                </Form.Item>
                                                <Form.Item label="Giảm giá" >
                                                    <div className="row">

                                                        <div className="col_ col-lg-4 col-md-3 col-sm-12 mb-1">
                                                            <Form.Item
                                                                style={{ margin: 0 }}
                                                                name={"discount"}
                                                            // rules={[{ required: true, message: 'Giá là bắt buộc' }]}
                                                            >
                                                                <Input addonBefore="₫" style={{}} placeholder="Giảm giá"
                                                                    // value={formatMN1(this.state.record.discount)}
                                                                    onChange={(e) => { this.onChangeDiscount(e, "discount", true) }}

                                                                    onFocus={() => this.ColorCell("discount_classify", true)} onBlur={() => { this.ColorCell("discount_classify", false) }} />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col_ col-lg-4 col-md-3 col-sm-12 mb-1">
                                                            <Form.Item
                                                                style={{ margin: 0 }}
                                                                name={"discount_percent"}

                                                            >
                                                                <Input addonBefore="%" style={{}} placeholder="Giảm giá %"
                                                                    // value={this.state.record.discount_percent}
                                                                    onChange={(e) => { this.onChangeDiscount(e, "discount_percent", false) }}

                                                                    onFocus={() => this.ColorCell("discount_percent_classify", true)} onBlur={() => { this.ColorCell("discount_percent_classify", false) }} />
                                                            </Form.Item>
                                                        </div>
                                                        <div className="col_ col-lg-4 col-md-3 col-sm-12 mb-1 flex_">

                                                            <Form.Item
                                                                style={{ margin: 0 }}
                                                                name={"discount_price"}

                                                            >
                                                                <Input addonBefore="₫" style={{}} placeholder="Giá giảm" readOnly disabled />
                                                            </Form.Item>

                                                            <Form.Item
                                                                style={{ margin: 0 }}
                                                            >
                                                                <Button type="primary" danger primary="true" onClick={() => { this.SubmitChangeAllx() }}>
                                                                    Áp dụng cho tất cả
                                                                </Button>
                                                            </Form.Item>
                                                        </div>

                                                    </div>


                                                </Form.Item>
                                                <Form.Item label="Danh sách phân loại hàng" >

                                                    <div className="table-responsive">
                                                        <TableClassify data={this.state.dataClassifyx} ColorCell={this.ColorCell} ListStore={this.state.ListStore} onChangeDiscountItem={this.onChangeDiscountItem} onChangeAmountItem={this.onChangeAmountItem}
                                                        
                                                            onChangeAmountItemSub={this.onChangeAmountItemSub}
                                                            onChangeStoreItem={this.onChangeStoreItem}
                                                            dataClassifyStoreIds={this.state.dataClassifyStoreIds}
                                                        />
                                                      
                                                    </div>


                                                </Form.Item>

                                                <Form.Item label="Mua nhiều giảm giá" className="buymore" >

                                                    {this.state.buyMoreDiscout.length > 0 && <div className="classify_pr_wr" style={{ backgroundColor: Colors.colorgrey, padding: "10px" }}><div className="classify_pr">

                                                        {this.state.buyMoreDiscout.map((item, index) => (
                                                            <Form.Item label={`${index + 1}.Khoảng giá ${index + 1}`} key={index}>
                                                                <Input.Group compact>
                                                                    <Form.Item

                                                                        label={`Từ sản phẩm`}
                                                                        layout="vertical"
                                                                        noStyle
                                                                        rules={[{ required: true, message: 'Không được để trống ô' }]}
                                                                        name={"buymore-from_amount-" + index}
                                                                    >
                                                                        <InputNumber style={{ width: '30%', }} placeholder="Từ (sản phẩm)" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label={`Đến sản phẩm`}
                                                                        layout="vertical"
                                                                        noStyle
                                                                        rules={[{ required: true, message: 'Không được để trống ô' }]}
                                                                        name={"buymore-to_amount-" + index}
                                                                    >
                                                                        <InputNumber style={{ width: '30%', }} placeholder="Đến (sản phẩm)" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label={`Đơn giá`} layout="vertical"
                                                                        noStyle
                                                                        rules={[{
                                                                            validator: (_, value) =>
                                                                            {

                                                                                if (value) {
                                                                                    const required = Validator.required(value);
                                                                                    if (required) {
                                                                                        return Promise.reject(required);
                                                                                    }
                                                                                    const money = Validator.money(value);
                                                                                    if (money) {
                                                                                        return Promise.reject(money);
                                                                                    }
                                                                                }

                                                                                return Promise.resolve();

                                                                            }
                                                                        }]}
                                                                        name={"buymore-price-" + index}
                                                                    >
                                                                        <Input style={{ width: '30%', }} addonBefore="₫" placeholder="Đơn giá" />
                                                                    </Form.Item>
                                                                    <button className="reset_btn btn_delete hover_opacity-05" onClick={() => this.deleteBuymore(item)}><DeleteOutlined /></button>
                                                                </Input.Group>
                                                            </Form.Item>
                                                        ))}


                                                        <Button type="dashed" primary="true" style={{ width: "100%" }} onClick={() => { this.setbuyMoreDiscout() }}>
                                                            <PlusCircleOutlined />
                                                        </Button>
                                                    </div> </div>
                                                    }
                                                    {this.state.buyMoreDiscout.length <= 0 && <Button type="dashed" primary="true" style={{ width: "100%" }} onClick={() => { this.setbuyMoreDiscout() }}>
                                                        <PlusCircleOutlined />
                                                    </Button>
                                                    }


                                                </Form.Item>


                                            </Card>

                                        </div>
                                        <div className="section__ section_img">
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px"
                                                }}
                                                bodyStyle={{
                                                    padding: "10px 10px",
                                                    position: "relative"
                                                }}
                                                headStyle={{
                                                    backgroundColor: Colors.colorgrey,
                                                    display: "flex",
                                                    minHeight: "30px",
                                                }}
                                                className="card_pr_order"
                                                title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "rgb(250, 250, 250)" }}><GlobalOutlined />
                                                    <span style={{ marginLeft: "10px" }}>Seo</span>
                                                </div>} bordered={true}
                                            >
                                                <Form.Item label="Seo title" name="seo_title" rules={[
                                                    {
                                                        required: true,
                                                        message: "Seo title là bắt buộc"
                                                    },
                                                ]}>
                                                    <Input placeholder="Seo title" />
                                                </Form.Item>
                                                <Form.Item label="Seo Alias" name="seo_alias" rules={[
                                                    {
                                                        required: true,
                                                        message: "Seo Alias là bắt buộc"
                                                    },
                                                ]}>
                                                    <Input placeholder="Seo Alias" />
                                                </Form.Item>
                                                <Form.Item label="Seo Description" name="seo_description" rules={[
                                                    {
                                                        required: true,
                                                        message: "Seo Description là bắt buộc"
                                                    },
                                                ]} >
                                                    <TextArea style={{ height: "100px" }} placeholder="Seo Description" />
                                                </Form.Item>
                                            </Card>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 col_">
                                        <div className="section__ section_img">
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px"
                                                }}
                                                bodyStyle={{
                                                    padding: "10px 10px",
                                                    position: "relative"
                                                }}
                                                headStyle={{
                                                    backgroundColor: Colors.colorgrey,
                                                    display: "flex",
                                                    minHeight: "30px",
                                                }}
                                                className="card_pr_order"
                                                title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "rgb(250, 250, 250)" }}><CarOutlined />
                                                    <span style={{ marginLeft: "10px" }}>Thông tin Vận chuyển</span>
                                                </div>} bordered={true}
                                            >
                                                <Form.Item label="*Cân nặng sau khi đóng gói" name="weight" >
                                                    <InputNumber addonAfter="gr" placeholder="weight"
                                                    // value={this.state.record.weight}
                                                    // onChange={(e) => { this.onChangetext(e, "weight", "record", false) }} min={1}

                                                    />
                                                </Form.Item>
                                                <Form.Item label={<div><span style={{ textAlign: "left" }}>Kích thước đóng gói (Phí vận chuyển thực tế sẽ thay đổi nếu bạn nhập sai kích thước)</span></div>} >
                                                    <Input.Group compact>

                                                        <Form.Item
                                                            style={{ margin: 0 }}
                                                            name="wide"

                                                        >
                                                            <InputNumber addonAfter="rộng cm" name="wide" id="wide" placeholder="Rộng"
                                                                // value={this.state.record.wide}
                                                                // onChange={(e) => { this.onChangetext(e, "wide", "record", false) }} 

                                                                min={1} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="height"
                                                            style={{ margin: 0 }}
                                                        >
                                                            <InputNumber addonAfter="cao cm" name="height" id="height" placeholder="Cao"
                                                                // value={this.state.record.height}
                                                                // onChange={(e) => { this.onChangetext(e, "height", "record", false) }}

                                                                min={1} />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="long"
                                                            style={{ margin: 0 }}
                                                        >
                                                            <InputNumber addonAfter="dài cm" name="long" id="long" placeholder="Dài"
                                                                // value={this.state.record.height}
                                                                // onChange={(e) => { this.onChangetext(e, "height", "record", false) }}

                                                                min={1} />
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>


                                                <Form.Item label="Phí vận chuyển" name="info_transport_id" rules={[
                                                    {
                                                        required: true,
                                                        message: "Phí vận chuyển là bắt buộc"
                                                    },
                                                ]}>



                                                    <Select

                                                        maxTagCount='responsive'
                                                        style={{

                                                        }}
                                                        // value={

                                                        //     this.state.record.info_transport_id + "-" + this.state.record.fee_ship

                                                        // }
                                                        placeholder="Phí vận chuyển"
                                                        // name="info_transport_id" id="info_transport_id"
                                                        onChange={(e) => { this.onChangetext(e, "info_transport_id", "record", false) }}
                                                        options={(this.state.ListInfoTransport || []).map((d) => ({
                                                            value: d.id,
                                                            label: d.name,
                                                        }))}
                                                    />


                                                </Form.Item>
                                                <Form.Item >
                                                    {!this.state.isSwitchTrans && <div style={{ color: "red" }}>Sản phẩm không thể lưu vì chưa có kênh vận chuyển nào được bật.</div>}
                                                    <div className="option_w">
                                                        <div className="right_item">
                                                            <div className="text_ic">
                                                                <span>
                                                                    đ {this.state.record?.fee_ship ? formatMN1(this.state.record?.fee_ship) : 0}
                                                                </span>
                                                                <button type="button" className="reset_btn" onClick={this.showModalTran}>
                                                                    <EditOutlined />
                                                                </button>
                                                            </div>
                                                            {/* <div className="switch ">
                                                        <SwitchA checked={this.state.isSwitchTrans} onChange={this.onchangeActiveTran} />
                                                    </div> */}
                                                        </div>

                                                    </div>
                                                    <div>Cài đặt đơn vị vận chuyển ở đây chỉ áp dụng cho sản phẩm này.</div>
                                                    {!this.state.isSwitchTrans && <div>nhấn tại đây để kích hoạt thêm phương thức vận chuyển cho sản phẩm.</div>}

                                                    <Modal
                                                        title={
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    cursor: 'move',
                                                                }}
                                                                onMouseOver={() =>
                                                                {
                                                                    if (this.state.disabled) {
                                                                        this.setDisabled(false);
                                                                    }
                                                                }}
                                                                onMouseOut={() =>
                                                                {
                                                                    this.setDisabled(true);
                                                                }}
                                                                onFocus={() => { }}
                                                                onBlur={() => { }}
                                                            >
                                                                Đơn vị vận chuyển: {this.state.transportInfo ? this.state.transportInfo.name : ""}
                                                            </div>
                                                        }
                                                        modalRender={(modal) => (
                                                            <Draggable
                                                                disabled={this.state.disabled}
                                                                bounds={this.state.boundsTrans}
                                                                onStart={(event, uiData) => this.onStart(event, uiData)}
                                                            >
                                                                <div ref={this.draggleRef}>{modal}</div>
                                                            </Draggable>
                                                        )}
                                                        visible={this.state.ismodalTrans}
                                                        onOk={this.handleTranOk}
                                                        onCancel={this.handleTranCancel}

                                                        footer={[
                                                            <Button key="1" htmlType="button" onClick={this.handleTranCancel}>
                                                                Hủy
                                                            </Button>,
                                                            <Button key="2" htmlType="button" type="primary" onClick={this.handleTranOk}>
                                                                Áp dụng
                                                            </Button>,
                                                        ]}
                                                    >
                                                        <div className="modal_content_trans">
                                                            <div className="left">
                                                                <span> ₫</span>
                                                                <h3>  {this.state.transportInfo ? formatMN1(this.state.transportInfo.price) : 0}</h3>

                                                            </div>
                                                            <div className="right">
                                                                <p>Phí vận chuyển tạm tính (dựa trên khối lượng sản phẩm là 80g)</p>
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                </Form.Item>
                                            </Card>
                                        </div>
                                        <div className="section__ section_img">
                                            <Card
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px"
                                                }}
                                                bodyStyle={{
                                                    padding: "10px 10px",
                                                    position: "relative"
                                                }}
                                                headStyle={{
                                                    backgroundColor: Colors.colorgrey,
                                                    display: "flex",
                                                    minHeight: "30px",
                                                }}
                                                className="card_pr_order"
                                                title={<div style={{ display: "flex", alignItems: "center", backgroundColor: "rgb(250, 250, 250)" }}><FormOutlined />
                                                    <span style={{ marginLeft: "10px" }}>Thông tin khác</span>
                                                </div>} bordered={true}
                                            >
                                                <Form.Item label="Hàng Đặt Trước" name="PreOrders">
                                                    <Radio.Group name="PreOrders" id="PreOrders"
                                                        // value={this.state.ortherInfo.PreOrders}
                                                        // onChange={(e) => { this.onChangetext(e, "PreOrders", "ortherInfo", false) }}

                                                        disabled>
                                                        <Radio value={0}>Không</Radio>
                                                        <Radio value={1}>Đồng ý</Radio>
                                                    </Radio.Group>
                                                    <div>Tôi sẽ gửi hàng trong 2 ngày (không bao gồm các ngày nghỉ lễ, Tết và những ngày đơn vị vận chuyển không làm việc)</div>
                                                </Form.Item>

                                                <Form.Item label="Tình trạng" name={"is_reserve"} initialValue={1}>

                                                    <Select
                                                        showSearch
                                                        style={{

                                                        }}

                                                        placeholder="Tình trạng"
                                                        optionFilterProp="children"

                                                        // // name="is_reserve" id="is_reserve"
                                                        // value={this.state.record.is_reserve}
                                                        // onChange={(e) => { this.onChangetext(e, "is_reserve", "record", false) }}

                                                        options={ArrStatus}
                                                    />

                                                </Form.Item>
                                                <Form.Item label="SKU sản phẩm" name={"code"}>
                                                    <Input placeholder="-"

                                                        disabled style={{ color: "green", fontWeight: "bold" }} />

                                                </Form.Item>
                                            </Card>
                                        </div>

                                    </div>
                                </div>

                                <div className="bottom_action" style={{ backgroundColor: Colors.white }}>
                                    <Form.Item noStyle >

                                        <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                        {/* <Button type="primary" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button> */}
                                        <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </div>

                            </Form >
                        </Card>
                    </div>
                </div >
               
            </>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListCategoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },

        StorePaperTextureRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StorePaperTextureRequest(data, id));
        },

        // ////////////////
        StoreInfoTransportRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInfoTransportRequest(data, id));
        },
        ResetStoreInfoTransport: () =>
        {
            dispatch(ActionsWeb.ResetStoreInfoTransport());
        },
        ListInfoTransportRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoTransportRequest(body));
        },
        // ////////////////
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
        {
            dispatch(ActionsWeb.ResetUploadFileLa());
        },
        // ////////////////
        StoreTagsRequest: (body) =>
        {
            dispatch(ActionsWeb.StoreTagsRequest(body));
        },
        ResetStoreTags: () =>
        {
            dispatch(ActionsWeb.ResetStoreTags());
        },
        ListTagsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListTagsRequest(body));
        },
        ////////////////////////
        StoreStoreRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreStoreRequest(data, id));
        },
        ResetStoreStore: () =>
        {
            dispatch(ActionsWeb.ResetStoreStore());
        },
        ListStoreRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },

        ////////////////////////
        ListProductsRequest: (data) =>
        {
            dispatch(ActionsWeb.ListProductsRequest(data));
        },
        StoreProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreProductsRequest(data, id));
        },
      
        ResetStoreProducts: () =>
        {
            dispatch(ActionsWeb.ResetStoreProducts());
        },
        // ////////////////////////
        StoreTypeProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreTypeProductsRequest(data, id));
        },
        ResetStoreTypeProducts: () =>
        {
            dispatch(ActionsWeb.ResetStoreTypeProducts());
        },
        ListTypeProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListTypeProductsRequest(body));
        },

        ////////////////////////
        StoreClassifyRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreClassifyRequest(data, id));
        },
        ResetStoreClassify: () =>
        {
            dispatch(ActionsWeb.ResetStoreClassify());
        },
        ListClassifysRequest: (body) =>
        {
            dispatch(ActionsWeb.ListClassifysRequest(body));
        },

        /////////////////
        DeleteFileLaRequest: (body) =>
        {
            dispatch(ActionsWeb.DeleteFileLaRequest(body));
        },

        // //////////////

        ListInfoPackageRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoPackageRequest(body));
        },
        StoreInfoPackageRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInfoPackageRequest(data, id));
        },
        ResetStoreInfoPackage: () =>
        {
            dispatch(ActionsWeb.ResetStoreInfoPackage());
        },
        InfoPackageActiveRequest: () =>
        {
            dispatch(ActionsWeb.InfoPackageActiveRequest());
        },
        // ListWarehouseRequest: (body) =>
        // {
        //     dispatch(ActionsWeb.ListWarehouseRequest(body));
        // },

        // //////////////
        ListAttributeProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListAttributeProductsRequest(body));
        },
        StoreAttributeProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreAttributeProductsRequest(data, id));
        },
        ResetStoreAttributeProducts: () =>
        {
            dispatch(ActionsWeb.ResetStoreAttributeProducts());
        },
        ProductByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.ProductByIdRequest(body));
        },
        ListInventoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInventoryRequest(body));
        },
        ResetListProductsRequest: () =>
        {
            dispatch(ActionsWeb.ResetListProductsRequest());
        },
        SearchCategoryRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchCategoryRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsEdit);