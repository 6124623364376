import { Avatar, Button, Dropdown, Image, Switch as SwitchA, List, Menu, Skeleton, Space, Tag, Checkbox, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import * as LINK from "../../../../constants/Links";
import { convertToBoolean, CutString } from "../../../../service";
import
{
    DownOutlined, SmileOutlined, DiffOutlined, DeleteOutlined

} from '@ant-design/icons';
const count = 3;

const App = ({ ...props }) =>
{
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() =>
    {
      
       
        if (!props.isNewList) {

            const newData = data.concat(props.lists);
            setData(newData);
            setList(newData);
            setLoading(false);
            setInitLoading(false);
        } else {
            setInitLoading(false);
            setLoading(false);
            setData(props.lists);
            setList(props.lists);


        }
        window.dispatchEvent(new Event('resize'));
    }, [props.lists]);


    const onLoadMore = () =>
    {
        if (props.current_page < props.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            props.OnPagination(props.current_page + 1, props.per_page)

        }

    };
    const renderEcommerce=(data)=>{
        const result=[];
        let check=[];
        if (data){
            for (let x in data) {
                let item = data[x];
                if (item.tiktok_code) {
                    if (!check.includes(1)) {
                        let content=[];
                        for (let y in data) {
                            let item_ = data[y];
                            if (item_.tiktok_code) {
                                content.push(<div>{item_.name}</div>)
                            }
                        }
                        result.push(<span className='cusor-pointer icon-ecom'>
                            <Popover placement="bottom" title={"Tiktok"} content={<div>{content}</div>} trigger="click"><img src={`${LINK.myHostimg}/tiktok-icon.png`} alt='' /></Popover></span>)
                    }
                    check.push(1)
                }
                if (item.shopee_code) {
                    if (!check.includes(2)) {
                        let content = [];
                        for (let y in data) {
                            let item_ = data[y];
                            if (item_.shopee_code) {
                                content.push(<div>{item_.name}</div>)
                            }
                        }
                        result.push(<span className='cusor-pointer icon-ecom'>
                            <Popover placement="bottom" title={"Shopee"} content={<div>{content}</div>} trigger="click">
                                <img src={`${LINK.myHostimg}/shopee-icon.png`} alt='' /></Popover></span>)
                    }
                    check.push(2)

                }
                if (item.lazada_code) {
                    if (!check.includes(3)) {
                        let content = [];
                        for (let y in data) {
                            let item_ = data[y];
                            if (item_.shopee_code) {
                                content.push(<div>{item_.name}</div>)
                            }
                        }
                        result.push(<span className='cusor-pointer icon-ecom'>
                            <Popover placement="bottom" title={"Lazada"} content={<div>{content}</div>} trigger="click"><img src={`${LINK.myHostimg}/lazada-icon.png`} alt='' />
                            </Popover>
                            </span>)
                    }
                    check.push(3)
                }
            }
        }
        return result;
        
    }
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <List
            className="mobile-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={list}
            renderItem={(item) => (
                <List.Item className='list_item_custom'
                    actions={[
                        <SwitchA defaultChecked={!convertToBoolean(item.hidden)} onChange={(e) => { props.updateRecord(e, item) }}
                            loading={props.isloading} size='small' />,
                        <a key="list-loadmore-edit" onClick={() => props.editRows(item)}>Sửa</a>, 
                        <Dropdown overlay={<Menu>
                            <Menu.Item icon={<DiffOutlined />} onClick={() => props.duplicateItem(item)}>
                                Nhân bản
                            </Menu.Item>
                            <Menu.Item icon={<DeleteOutlined />} danger onClick={() => props.deleteModal(item)}>Xóa</Menu.Item>
                        </Menu>
                        }>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                more
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>,
                        <Checkbox onChange={() => props.onChangeCheck(item)} checked={props.idsCheck && props.idsCheck.includes(item.id)}/>
                ]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            avatar={<div className='img_list'><Image
                                className='img'
                                style={{ objectFit: "contain" }}
                                src={LINK.myHostimgServerImg + "/" + item?.thumbnail?.[0]}
                            /></div>}
                            title={<a href='#' onClick={() => props.editRows(item)}>{item.code}</a>}
                            description={
                            <div>
                                <strong>{CutString(item?.title, 10, true)}</strong>
                                <br></br>
                                {CutString(item?.description, 20, true)}<br></br>
                                    {renderEcommerce(item.ecommerce)}
                                    
                                </div>}
                        />
                        {/* <div>content</div> */}
                    </Skeleton>
                </List.Item>
            )}
        />
    );
};
export default App;