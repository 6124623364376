import React, { Component, createRef, useEffect, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Select, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined, MinusCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, SaveOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, createSlug, getParamsUrl, replaceThous } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
const Index = ({ ListPaperPriceList, setListPaperPriceList, ListPaperType, formPaperPriceListRef }) => {


    // const [data, setData] = useState();

    const addSortStamp = (index) => {
        let lastItem = ListPaperPriceList[ListPaperPriceList.length - 1];
        let rankLast = 0;
        if (lastItem) {
            rankLast = lastItem.rank;
        }
        const values = formPaperPriceListRef.current.getFieldsValue();
        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        for (let x in values) {
            let x_ = x.split("-");
            let index = x_[x_.length - 1];

            if (flag != parseInt(index)) {
                newdata.push(itemNew)
                flag = index;
                itemNew = {};
            }
            if (flag == index) {
                if (values?.[`paper-price-list-paper_type_id-${index}`]) {
                    itemNew.name = values[`paper-price-list-name-${index}`];
                    itemNew.code = values[`paper-price-list-code-${index}`];
                    itemNew.note = values[`paper-price-list-note-${index}`];
                    itemNew.paper_type_id = values[`paper-price-list-paper_type_id-${index}`];
                    itemNew.from = values[`paper-price-list-from-${index}`];
                    itemNew.to = values[`paper-price-list-to-${index}`];
                    itemNew.price = replaceThous(values[`paper-price-list-price-${index}`]);
                    itemNew.rank = ListPaperPriceList[index]?.rank;
                    itemNew.id = ListPaperPriceList[index]?.id;
                    itemNew.isEdit = ListPaperPriceList[index]?.isEdit;
                    itemNew.isNew = ListPaperPriceList[index]?.isNew;

                }

            }
            i++;
            if (i == Object.values(values).length) {

                newdata.push(itemNew)

            }

        }
        console.log("newdatanewdatanewdata",newdata);
        setListPaperPriceList([...newdata, {
            paper_type_id:5,
            from: "",
            to: "",
            name: "",
            code: "",
            price:0,
            isNew: true,
            rank: rankLast + 1
        }])
    }
    const removeSortStamp = (index) => {
        if (index == 0) {
            return;
        }
        const newData = ListPaperPriceList.filter((value, i) => {
            return i != index;
        })
        setListPaperPriceList([...newData])
    }

    const sortDataByRank = (type, value, index) => {
        if (type == "up") {
            value.rank -= 1;
            if (value.rank <= 1) {
                value.rank = 1;
            }
        }
        if (type == "down") {
            value.rank += 1;
        }
        const sortedData = [];
        ListPaperPriceList.map((item, i) => {
            if (type == "up") {

                if (i == (index - 1)) {
                    item.rank += 1;
                    item.isEdit = true;
                }
            }
            if (type == "down") {
                if (i == (index + 1)) {
                    item.rank -= 1;
                    if (item.rank <= 1) {
                        item.rank = 1;
                    }
                    item.isEdit = true;

                }
            }
            if (i == index) {
                item = value
                item.isEdit = true;
            }
            sortedData.push(item)
        })

        const sortedData_ = sortedData.sort((a, b) => a.rank - b.rank);

        setListPaperPriceList(sortedData_);
    };
    return (
        <>
            {ListPaperPriceList && ListPaperPriceList.map((item, index) => {


                return <Input.Group compact>
                    <Form.Item name={`paper-price-list-paper_type_id-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}
                        initialValue={5}
                    >
                        <Select
                            className="font-size-12"
                            maxTagCount='responsive'
                            style={{ width: '20%', }}
                            showSearch
                            placeholder="id loại giấy"


                            options={(ListPaperType || []).map((d) => ({
                                value: d.id,
                                label: d.name,
                            }))}


                        />
                    </Form.Item>
                    <div className="d-none" >
                    <Form.Item name={`paper-price-list-name-${index}`} noStyle>
                        <Input
                          className="font-size-12"
                            style={{
                                width: '10%',
                            }}
                            placeholder="name"
                            onChange={(e) => {
                                formPaperPriceListRef.current.setFieldsValue({ [`paper-price-list-code-${index}`]: createSlug(e.target.value) })
                            }}
                        />
                    </Form.Item>
                  
                        <Form.Item name={`paper-price-list-code-${index}`} noStyle>
                            <Input
                              className="font-size-12"
                                style={{
                                    width: '15%',
                                }}
                                placeholder="code"
                            />
                        </Form.Item>
                    </div>
                    <div className="d-none" >
                        <Form.Item name={`paper-price-list-rank-${index}`} noStyle>
                            <Input

                            />
                        </Form.Item>
                    </div>
                    <Form.Item name={`paper-price-list-from-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input
                          className="font-size-12"
                            style={{
                                width: '10%',
                            }}
                            placeholder="from"
                        />
                    </Form.Item>
                    <Form.Item name={`paper-price-list-to-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input
                          className="font-size-12"
                            style={{
                                width: '10%',
                            }}
                            placeholder="to"
                        />
                    </Form.Item>

                    <Form.Item name={`paper-price-list-price-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}>
                        <Input
                          className="font-size-12"
                            addonAfter={<div className="d-flex">
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => addSortStamp(index)}><PlusCircleOutlined className="text-primary" /></button>
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => removeSortStamp(index)}><MinusCircleOutlined className="text-danger" /></button>
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => sortDataByRank("up", item, index)}><ArrowUpOutlined className="text-info" /></button>
                                <button type="button" className="reset_btn scale_btn " onClick={() => sortDataByRank("down", item, index)}><ArrowDownOutlined className="text-info" /></button>
                                {/* <button className="reset_btn scale_btn" onClick={()=>sortDataByRank("down",item,index)}><SaveOutlined className="text-info"/></button> */}
                            </div>}
                            style={{
                                width: '50%',
                            }}
                            placeholder="price"
                        />
                    </Form.Item>

                </Input.Group>
            })}

        </>

    );
}
export default Index;