import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, Select, Switch as SwitchA, Card } from 'antd';

import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, getParamsUrl } from "./../../../../service";
import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined,
    DeleteOutlined, UnorderedListOutlined, SearchOutlined,
    EditOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
const { Search } = Input;
const { Option } = Select;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListWarehouse: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Của hàng',
                    dataIndex: 'store',
                    sorter: (a, b) => a.code - b.code,
                    render: (text, record, index) =>
                    {
                        if (record.stores) {
                            return record.stores.name
                        }
                    }
                },

                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',
                    sorter: (a, b) => a.code - b.code,
                    render: (text, record, index) =>
                    {

                        return text === 1 ? "Ẩn" : "Hiển thị"
                    }
                },

                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: { deleted: 0, hidden: 0 },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreWarehouse: {},
            isloading: false,
            isLoadingSearch: false,

        }

    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreWarehouseRequest(record, record.id ? record.id : "")
        // setTimeout(() =>
        // {

        // }, 2000);
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };

    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    componentDidMount = () =>
    {
        const urlParams = getParamsUrl();

        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        this.props.SearchWarehouseRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        // this.props.ListWarehouseRequest({
        //     limit: this.state.meta.per_page,
        //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        // });
        this.props.ListStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        // }
        // else {
        //     this.setState(state =>
        //     {
        //         return {
        //             ListWarehouse: this.props.Web.ListWarehouse
        //         }
        //     }, () => { this.data(); })
        // }
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListWarehouse && nextProps.Web.ListWarehouse !== prevState.ListWarehouse) {
            console.log("Comment");


            return {
                ListWarehouse: nextProps.Web.ListWarehouse,

            };

        }
        if (nextProps.Web.StoreWarehouse && nextProps.Web.StoreWarehouse !== prevState.StoreWarehouse) {
            return {
                StoreWarehouse: nextProps.Web.StoreWarehouse,

            };

        }
        if (nextProps.Web.ListStore && nextProps.Web.ListStore.data !== prevState.ListStore) {
            console.log("Comment");


            return {
                ListStore: nextProps.Web.ListStore.data,

            };

        }
        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreWarehouse !== prevState.StoreWarehouse) {
            let { StoreWarehouse } = this.state;
            try {
                if (StoreWarehouse.code === 200) {
                    if (StoreWarehouse.message && typeof StoreWarehouse.message === "string") {
                        message.success(StoreWarehouse.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();

                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    if (filteredvalues.store_id) {
                        filteredvalues.store_id = (1 * filteredvalues.store_id)
                    }
                    // this.formSearchRef.current.setFieldsValue(filteredvalues)
                    // this.props.ListStoreRequest({
                    //     limit: this.state.meta.per_page,
                    //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    // });
                    this.props.SearchWarehouseRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreWarehouse.message && typeof StoreWarehouse.message === "string") {

                        message.error(StoreWarehouse.message)
                    } else {
                        for (let x in StoreWarehouse.message) {
                            let mess = StoreWarehouse.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreWarehouse()
            this.handleCancel();
        }
        if (this.state.ListStore !== prevState.ListStore) {

            const urlParams = getParamsUrl();

            const filteredvalues = Object.fromEntries(
                Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
            );
            if (filteredvalues.store_id) {
                filteredvalues.store_id = (1 * filteredvalues.store_id);
            }

            this.formSearchRef.current.setFieldsValue(filteredvalues)
        }
        if (this.state.ListWarehouse !== prevState.ListWarehouse) {

            this.SetisLoadingSeach(false)
            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Của hàng',
                        dataIndex: 'store',
                        sorter: (a, b) => a.code - b.code,
                        render: (text, record, index) =>
                        {
                            console.log('====================================');
                            console.log(record);
                            console.log('====================================');
                            if (record.stores) {
                                return record.stores.name
                            }
                        }
                    },
                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        width: "10%",
                        onFilter: (value, record) =>
                        {
                            console.log('====================================');
                            console.log(value);
                            console.log('====================================');
                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListWarehouse } = this.state;

        if (ListWarehouse.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListWarehouse.current_page,
            first_page_url: ListWarehouse.first_page_url,
            from: ListWarehouse.from,
            last_page: ListWarehouse.last_page,
            last_page_url: ListWarehouse.last_page_url,
            links: ListWarehouse.links,
            next_page_url: ListWarehouse.next_page_url,
            path: ListWarehouse.path,
            per_page: ListWarehouse.per_page,
            prev_page_url: ListWarehouse.prev_page_url,
            to: ListWarehouse.to,
            total: ListWarehouse.total,
        };
        ListWarehouse.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }



    editRows = (record) =>
    {


        this.setState(state =>
        {
            return {
                record: record,

            }
        }, () =>
        {
            // this.handleOkDetail();
        })
        this.formRef.current.setFieldsValue({ ...record })

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", deleted: 0, hidden: 0 }
            }
        })
        this.formRef.current.resetFields();
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e ? 1 : 0 }

        this.SetisLoading(true)
        this.props.StoreWarehouseRequest(tmp, tmp.id);

    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListWarehouseRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.SearchWarehouseRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: values });

        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_WAREHOUSE}?${searchParams}`);
        // this.props.ListWarehouseRequest({
        //     limit: this.state.meta.per_page,
        //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: e
        // });
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
    }
    // update_ = () =>
    // {
    //     const { record } = this.state;
    //     // console.log('====================================');
    //     // console.log(record);
    //     // console.log('====================================');
    //     // console.log('====================================');
    //     // console.log(checkedList);
    //     // console.log('====================================');
    //     // return;
    //     if (!record.name) {
    //         message.warn("Vui lòng nhập tên!");
    //         return;
    //     }

    //     record.hidden = 0
    //     this.SetisLoading(true)
    //     this.props.StoreWarehouseRequest(record, record.id ? record.id : "")

    // }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreWarehouseRequest(record_, record_.id ? record_.id : "")

    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UnorderedListOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Kho</span>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 0",
                            maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="form__half">
                            <div className="left_half" style={{ width: "50%" }}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="store_id" noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Select
                                                    showSearch
                                                    style={{
                                                        width: "100%"
                                                    }}

                                                    placeholder="Của hàng"

                                                    // value={this.state.record.store_id}
                                                    // onChange={(e) => { this.onChangetext(e, "store_id", "record") }}
                                                    options={(this.state.ListStore || []).map((d) => ({
                                                        value: d.id,
                                                        label: d.name,
                                                    }))}
                                                />

                                            </Form.Item>
                                            <Form.Item name="search" noStyle style={{
                                                width: "60%"
                                            }}>
                                                <Input placeholder="Nhập tên kho" name="search" id="search"

                                                    style={{
                                                        width: "100%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "50%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                            </Form.Item>

                                        </Form>
                                    </div>
                                    <div className="right_">
                                        <Button type="primary" onClick={this.addnew}><PlusCircleOutlined /></Button>
                                    </div>

                                </div>
                                <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                    rowSelection={{
                                        ...this.state.rowSelection,
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                    scroll={{

                                        y: "60vh",
                                    }}
                                />
                                <div className="pagination_">
                                    <Pagination total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                </div>

                            </div>
                            <Divider type={"vertical"} style={{
                                height: "100vh",
                                // width: "5px",
                                borderColor: Colors.colormain
                            }} />
                            <div className="right_half" style={{ width: "45%" }}>
                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Form.Item label="Cửa hàng" name="store_id" rules={[
                                        {
                                            required: true,
                                            message: "Cửa hàng là bắt buộc"
                                        },
                                    ]}>
                                        <Select
                                            showSearch
                                            style={{
                                                // width: 200,
                                            }}
                                            // name="store_id" id="store_id"
                                            placeholder="của hàng"
                                            optionFilterProp="children"
                                            // filterOption={(input, option) => option.children.includes(input)}
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            // value={this.state.record.store_id}
                                            // onChange={(e) => { this.onChangetext(e, "store_id", "record") }}
                                            options={(this.state.ListStore || []).map((d) => ({
                                                value: d.id,
                                                label: d.name,
                                            }))}
                                        />


                                    </Form.Item>
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên kho là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tên Kho" showCount maxLength={120} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code">
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>
                                    <Form.Item >


                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>
                    </Card>
                </div>

            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListWarehouseRequest: (body) =>
        {
            dispatch(ActionsWeb.ListWarehouseRequest(body));
        },
        StoreWarehouseRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreWarehouseRequest(data, id));
        },
        ResetStoreWarehouse: () =>
        {
            dispatch(ActionsWeb.ResetStoreWarehouse());
        },
        ListStoreRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },
        SearchWarehouseRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchWarehouseRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);