import React, { useState } from 'react';
import Loader from './Loader';
import { Document, Page, pdfjs } from 'react-pdf';
import ControlPanel from './ControlPanel';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({ url }) =>
{
    const [scale, setScale] = useState(1.0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
   
    function onDocumentLoadSuccess({ numPages })
    {
        setNumPages(numPages);
        setIsLoading(false);
    }
  
    return (
        <div>
            {/* <Loader isLoading={isLoading} /> */}
            <section
                id="pdf-section"
                className="d-flex flex-column align-items-center w-100"
            >
                <ControlPanel
                    scale={scale}
                    setScale={setScale}
                    numPages={numPages}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    // file={props.url ? props.url:"/pdf/example.pdf"}
                    // file={"/pdf/Notfound1.pdf"}
                    file={url}
                />
                
                <Document
                    // file={props.url ? props.url : "/pdf/example.pdf"}
                    // file={"/pdf/Notfound1.pdf"}
                    // file={url}
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    
                    <Page pageNumber={pageNumber} scale={scale} />
                </Document>
            </section>
        </div>
    );
};

export default PDFReader;
