import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Card, Form, Switch as SwitchA } from 'antd';
import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined, AppstoreOutlined,
    DeleteOutlined,
    EditOutlined, UnorderedListOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { checkScreenSize, formatMoney, getParamsUrl } from "../../../../service";
import { META } from "../../../../utils/constant";
import IndexMobile from "./IndexMobile";
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = new createRef(null)
        this.state = {

            ListInfoTransport: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Mô tả ',
                    dataIndex: 'description',
                    sorter: (a, b) => a.description - b.description,

                },
                {
                    title: 'Giá ',
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,

                },
                {
                    title: 'Active',
                    dataIndex: 'active',

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreCategory: {},
            isloading: false,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreInfoTransportRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,

            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.search })


        this.props.ListInfoTransportRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.search
        });

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListInfoTransport && nextProps.Web.ListInfoTransport !== prevState.ListInfoTransport) {
            console.log(nextProps.Web.ListInfoTransport);


            return {
                ListInfoTransport: nextProps.Web.ListInfoTransport,

            };

        }
        else if (nextProps.Web.StoreInfoTransport && nextProps.Web.StoreInfoTransport !== prevState.StoreInfoTransport) {
            return {
                StoreInfoTransport: nextProps.Web.StoreInfoTransport,

            };

        }
        else {
            return null;
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreInfoTransport !== prevState.StoreInfoTransport) {
            let { StoreInfoTransport } = this.state;
            try {
                if (StoreInfoTransport.code === 200) {
                    if (StoreInfoTransport.message && typeof StoreInfoTransport.message === "string") {
                        message.success(StoreInfoTransport.message)
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.search })


                    this.props.ListInfoTransportRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.search
                    });
                }
                else {
                    if (StoreInfoTransport.message && typeof StoreInfoTransport.message === "string") {

                        message.error(StoreInfoTransport.message)
                    } else {
                        for (let x in StoreInfoTransport.message) {
                            let mess = StoreInfoTransport.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.setConfirmLoading(false);
            this.SetisLoading(false);
            this.props.ResetStoreInfoTransport()


        }
        if (this.state.ListInfoTransport !== prevState.ListInfoTransport) {
            let { ListInfoTransport } = this.state

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {

                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Mô tả ',
                        dataIndex: 'description',
                        sorter: (a, b) => a.description - b.description,

                    },
                    {
                        title: 'Giá ',
                        dataIndex: 'price',
                        sorter: (a, b) => a.price - b.price,
                        render: (text, record, index) =>
                        {
                            return formatMoney(text);
                        }
                    },
                    {
                        title: 'Active',
                        dataIndex: 'active',
                        render: (text, record, index) =>
                        {
                            return <SwitchA defaultChecked={record.active} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} />
                        }
                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, active: e }

        this.SetisLoading(true)
        this.props.StoreInfoTransportRequest(tmp, tmp.id);

    }
    data = () =>
    {
        let { ListInfoTransport } = this.state;

        if (ListInfoTransport.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListInfoTransport.current_page,
            first_page_url: ListInfoTransport.first_page_url,
            from: ListInfoTransport.from,
            last_page: ListInfoTransport.last_page,
            last_page_url: ListInfoTransport.last_page_url,
            links: ListInfoTransport.links,
            next_page_url: ListInfoTransport.next_page_url,
            path: ListInfoTransport.path,
            per_page: ListInfoTransport.per_page,
            prev_page_url: ListInfoTransport.prev_page_url,
            to: ListInfoTransport.to,
            total: ListInfoTransport.total,
        };
        ListInfoTransport.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        }, () =>
        {
            this.setState(state =>
            {
                return {
                    isNewList: false,
                }
            });

        })
    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_INFO_TRANSPORT_EDIT + "/" + record.id,
            state: record
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListInfoTransportRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        if (values.search) {
            this.props.ListInfoTransportRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
            });
            const searchParams = new URLSearchParams(values).toString();
            this.props.history.push(`${LINK.WEB_INFO_TRANSPORT}?${searchParams}`);
            return;
        }
        this.props.ListInfoTransportRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.props.history.push(`${LINK.WEB_INFO_TRANSPORT}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin vận chuyển</span>
                        </div>
                        <Link to={LINK.WEB_INFO_TRANSPORT_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",

                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >


                        <div className="btn_action_list">
                            <div className="left_" style={{ padding: "10px", }}>
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{

                                    display: "flex", width: "100%"
                                }}>

                                    <div className="row" style={{width:"100%"}}>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="search" noStyle >
                                                <Input placeholder="Mã,Tên nhà vận chuyển" style={{width:"100%"}} />
                                            </Form.Item>

                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item noStyle >
                                                <div className="flex_">
                                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                <Button style={{ backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>


                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <><Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            rowSelection={{
                                ...this.state.rowSelection,
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                            <div className="pagination_">
                                <Pagination showQuickJumper pageSize={this.state.meta.per_page} current={this.state.meta.current_page} total={this.state.meta.to} onChange={this.OnPagination} />

                            </div>
                        </>}

                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListInfoTransportRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoTransportRequest(body));
        },
        StoreInfoTransportRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInfoTransportRequest(data, id));
        },
        ResetStoreInfoTransport: () =>
        {
            dispatch(ActionsWeb.ResetStoreInfoTransport());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);