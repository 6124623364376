import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import
{
    SearchOutlined,
    CaretDownOutlined, EditOutlined, DeleteOutlined
} from '@ant-design/icons';
import * as Services from './../../service';
import * as Colors from './../../constants/Colors';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from './../../constants/Links';
import { useDispatch, useSelector } from 'react-redux';
import { META } from "./../../utils/constant";

import * as ActionsWeb from "./../../actions/indexWeb";

import moment from 'moment';
import { useHistory } from 'react-router-dom';
const { RangePicker } = DatePicker;

const count = 3;
const Index = (props) =>
{
    const history = useHistory();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isLoadingSearch, SetisLoadingSearch] = useState(false);
    const [CustomersList, setCustomersList] = useState([]);
    const [ListCustomers, setListCustomers] = useState([]);
    const [data, setdata] = useState([]);
    const [dataList, setdataList] = useState([]);
    const [ListTags, setListTags] = useState([]);
    const [ListCategory, setListCategory] = useState([]);
    const formSearchRef = new useRef(null)
    const [isloading, setisloading] = useState(false);
    const [selectedRowKeys1, SetselectedRowKeys1] = useState([]);
    const [selectedRows1, SetselectedRows1] = useState([]);
    const [checkAll, setCheckAll] = useState(false)
    const [indeterminate, setIndeterminate] = useState(true);
    const [columns, setColumns] = useState([
        {
            title: 'STT',
            dataIndex: 'key',
            with: "5%",
            align: "center"

        },
        {
            title: 'Code',
            dataIndex: 'code',
            sorter: (a, b) => a.code - b.code,

        },
        {
            title: 'Tên',
            dataIndex: 'name',

        },

        {
            title: 'Điện thoại',
            dataIndex: 'phone',


        },

        {
            title: 'Địa chỉ',
            dataIndex: 'address',


        }]);
    const [initLoading, setInitLoading] = useState(true);

    const onCheckAllChange = (e) =>
    {
        if (e.target.checked === true) {
            const keys = list.map((item) =>
            {
                return item.key
            })
            SetselectedRowKeys1(keys);
            SetselectedRows1(list)
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(2, list)
            }
        } else {
            SetselectedRowKeys1([]);
            SetselectedRows1([])
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(1, [])
            }
        }

        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    const setCustomersSeleted = (selectedRows) =>
    {

        const result = selectedRows.filter(itemA => !CustomersList.some(itemB => (1 * itemB.id) === (1 * itemA.id)));
        setCustomersList([...CustomersList, ...result]);

    }
    useEffect(() =>
    {

        if (props.isresetList) {
            SetselectedRowKeys1([]);
            SetselectedRows1([])
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(1, [])
            }

            setIndeterminate(false);
            setCheckAll(false);
        }
        return () =>
        {

        };
    }, [props.isresetList]);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    useEffect(() =>
    {

        if (ListCustomers) {

            if (!ListCustomers.data || ListCustomers.data.length <= 0) {
                // message.warn("không tìm thấy khách hàng!");
                return
            }

            data_()
            SetisLoadingSearch(false)
        }




        return () =>
        {

        };
    }, [ListCustomers]);
    useEffect(() =>
    {

        setListCustomers(Web.ListCustomers);

        return () =>
        {

        };
    }, [Web.ListCustomers]);
    useEffect(() =>
    {
        setListCategory(Web.ListCategory.data);

        return () =>
        {

        };
    }, [Web.ListCategory.data]);
    useEffect(() =>
    {
        setListTags(Web.ListTags.data);

        return () =>
        {

        };
    }, [Web.ListTags.data]);

    useEffect(() =>
    {
        try {

            dispatch(ActionsWeb.SearchCustomersRequest({
                limit: meta.per_page,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }, { data: {} }))
            // dispatch(ActionsWeb.ListCategory({
            //     limit: meta.per_page * 1000,
            //     page: meta.current_page, column: meta.column, sort: meta.sort
            // }))
            // dispatch(ActionsWeb.ListTags({
            //     limit: meta.per_page * 1000,
            //     page: meta.current_page, column: meta.column, sort: meta.sort
            // }))


        } catch (error) {

        }
        return () =>
        {

        };
    }, []);
    const onSearch = (values) =>
    {
        SetisLoadingSearch(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        dispatch(ActionsWeb.SearchCustomersRequest({
            limit: meta.per_page,
            page: meta.current_page, column: meta.column, sort: meta.sort
        }, { data: filteredvalues }))

        setdataList([]);
        setList([]);


    }
    const onResetFrom = () =>
    {

        formSearchRef.current.resetFields();
        formSearchRef.current.submit();
    }

    const data_ = () =>
    {


        try {



            if (ListCustomers.data.length <= 0) {
                return;
            }

            let data_ = [];
            let listfil = {};
            let meta_ = {
                ...meta,
                current_page: ListCustomers.current_page,
                first_page_url: ListCustomers.first_page_url,
                from: ListCustomers.from,
                last_page: ListCustomers.last_page,
                last_page_url: ListCustomers.last_page_url,
                links: ListCustomers.links,
                next_page_url: ListCustomers.next_page_url,
                path: ListCustomers.path,
                per_page: 1 * ListCustomers.per_page,
                prev_page_url: ListCustomers.prev_page_url,
                to: ListCustomers.to,
                total: ListCustomers.total,
            };

            listfil["name"] = [];

            listfil["address"] = [];
            listfil["phone"] = [];
            ListCustomers.data.map((item, index) =>
            {
                listfil["name"].push({
                    text: item.name,
                    value: item.name,
                });
                listfil["address"].push({
                    text: item.address,
                    value: item.address,
                });

                listfil["phone"].push({
                    text: item.phone,
                    value: item.phone,
                });

                item.key = index + 1
                data_.push(item)

            });


            setColumn_(listfil);
            setdata(data_)
            setMeta(meta_)



            setInitLoading(false);
            setLoading(false);
            if (ListCustomers.current_page > 1) {
                const newData = dataList.concat(data_);
                setdataList(newData);
                setList(newData);
            } else {
                setdataList(data_);
                setList(data_);
            }



            window.dispatchEvent(new Event('resize'));
        } catch (error) {
            console.error(error);
        }
    }
    const editRows = (record) =>
    {

        history.push({
            pathname: LINK.WEB_CUSTOMERS_EDIT + "/" + record.id,
            state: record
        });

    }
    const setColumn_ = (filters) =>
    {

        setColumns([
            {
                title: 'STT',
                dataIndex: 'key',
                with: "5%",
                align: "center"

            },
            {
                title: 'Code',
                dataIndex: 'code',
                sorter: (a, b) => a.code - b.code,

            },
            {
                title: 'Tên',
                dataIndex: 'name',
                filterMode: 'tree',
                filterSearch: true,
                filters: filters.name,
                onFilter: (value, record) =>
                {
                    return record.name.startsWith(value)

                },
                sorter: (a, b) => a.name - b.name,
            },

            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                filters: filters.phone,
                onFilter: (value, record) =>
                {
                    return record.name.startsWith(value)

                },
                sorter: (a, b) => a.phone - b.phone,

            },

            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                filters: filters.address,
                onFilter: (value, record) =>
                {
                    return record.address.startsWith(value)

                },
                sorter: (a, b) => a.address - b.address,

            },
            {
                title: 'ngày tạo',
                dataIndex: 'created_at',
                sorter: (a, b) => a.created_at - b.created_at,
                render: (text, record, index) =>
                {
                    return moment(text).format('DD/MM/YYYY h:m:s');;
                }

            },

            {
                title: 'Hành động',
                dataIndex: 'action',
                width: "15%",
                render: (text, record, index) => (
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                        <span className="">
                            <button className="reset_btn btn_edit btn__" onClick={() => editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>

                        </span>
                    </div>
                ),
            },
        ])
    }
    const OnPagination = (page, pageSize) =>
    {

        const formvalues = formSearchRef.current.getFieldsValue();

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        dispatch(ActionsWeb.SearchCustomersRequest({
            limit: pageSize,
            page: page, column: meta.column, sort: meta.sort
        }, { data: filteredvalues }))


    }
    const onLoadMore = () =>
    {
        if (meta.current_page < meta.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            OnPagination(meta.current_page + 1, meta.per_page)

        }

    };
    const SetselectedRowKeys1list = (e, id) =>
    {
        if (e.target.checked === true) {
            SetselectedRowKeys1([...selectedRowKeys1, id])

        } else {
            const newselected = selectedRowKeys1.filter(item =>
            {
                return item !== id
            })
            SetselectedRowKeys1(newselected)
        }

    }
    const SetselectedRows1list = (e, item) =>
    {
        if (e.target.checked === true) {
            setIndeterminate(!![...selectedRows1, item].length && [...selectedRows1, item].length < list.length);
            setCheckAll([...selectedRows1, item].length === list.length);
            SetselectedRows1([...selectedRows1, item])
            if (props.setCustomerMutilpe) {
                props.setCustomerMutilpe(2, item)
            }
        } else {
            const newselected = selectedRows1.filter(el =>
            {
                return el !== item
            })
            setIndeterminate(!!newselected.length && newselected.length < list.length);
            setCheckAll(newselected.length === list.length);
            SetselectedRows1(newselected)
            if (props.setCustomerMutilpe) {
                props.setCustomerMutilpe(1, item)
            }
        }
    }

    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <div className='dropdown_rim'>
            <Form name="formsearch" className="fromsearch" ref={formSearchRef} onFinish={onSearch} style={{
                padding: "0px !important",
                width: "100%",
                display: "flex",
                margin: 0
            }}>
                <Form.Item style={{ margin: 0, width: "100%" }}>
                    <Input.Group compact>
                        <div className="row">

                            <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Tên Khách hàng" />
                                </Form.Item>
                            </div>

                            <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Code" />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                <Form.Item name="address" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Địa chỉ" />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-3 col-md-3 col-sm-6">

                                <Form.Item name="phone" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Điện thoại" />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                    <RangePicker />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-3 col-md-3 col-sm-6">

                                <Form.Item noStyle style={{ margin: 0 }} >
                                    <div style={{ display: "flex" }}>
                                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={isLoadingSearch} />
                                        <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={onResetFrom}>Reset</Button>
                                    </div>
                                </Form.Item>

                            </div>
                            <div className="col_ col-lg-6 col-md-6 col-sm-6" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

                                <Checkbox checked={checkAll} onChange={(e) => { onCheckAllChange(e) }} indeterminate={indeterminate}></Checkbox>

                            </div>
                        </div>






                    </Input.Group>
                </Form.Item>
            </Form>
            {!props.isListShow && <div className='list_scroll'>
                <List
                    className="demo-loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item className='list_item_custom'
                            actions={[<Checkbox checked={selectedRowKeys1.includes(item.key)} onChange={(e) => { SetselectedRowKeys1list(e, item.key); SetselectedRows1list(e, item) }}></Checkbox>, <a key="list-loadmore-edit" onClick={() => editRows(item)}>Sửa</a>,]}
                        >
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta
                                    // avatar={<div className='img_list'><Image
                                    //     className='img'
                                    //     style={{ objectFit: "contain" }}
                                    //     src={LINK.myHostimgServerImg + "/" + item?.thumbnail?.[0]}
                                    // /></div>}
                                    title={item.code}
                                    description={<div><strong>{Services.CutString(item?.name, 10, true)}</strong><br></br> {Services.CutString(item?.description, 20, true)}</div>}
                                />


                                <div>{item.address}</div>
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </div>}
            {props.isListShow && <><Table rowClassName={(record, index) =>
            {
                return "__row_";
            }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys1,
                    onChange: (selectedRowKeys, selectedRows) =>
                    {
                        SetselectedRowKeys1(selectedRowKeys)
                        SetselectedRows1(selectedRows)

                        setCustomersSeleted(selectedRows)
                    },
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{

                    y: "60vh",
                }}
                style={{ paddingBottom: "30px" }}
            />
                <div className="pagination_">
                    <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={meta && meta.per_page ? meta.per_page : 10} total={meta && meta.total ? meta.total : 10} showSizeChanger onChange={OnPagination} />
                </div></>}
        </div>
    );
};

export default Index;
