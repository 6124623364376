import * as ActionLogin from "./../../actions/login";
class Authentication
{
    isAuthenticated()
    {
        let token = null;

        token = ActionLogin.GetUser();
        // console.log(token.user)
        if (token) {
            return true
        }

        return false;
    }

}

const authentication = new Authentication();
export default authentication;
