import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, List, Skeleton, Avatar, Switch as SwitchA, Image, } from 'antd';

import
{
    FolderViewOutlined, UserSwitchOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { Scrollbar } from 'smooth-scrollbar-react';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, convertToBoolean, getParamsUrl } from "../../../../service";
import Draggable from 'react-draggable';
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.draggleRef = createRef(null);
        this.formSearchCollectionsRef = createRef(null);
        this.state = {

            ListCollections: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',

                    filterSearch: true,

                    onFilter: (value, record) => record.slug.startsWith(value),
                    sorter: (a, b) => a.slug - b.slug,
                },
                {
                    title: 'Tên',
                    dataIndex: 'name',

                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },

                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",

            confirmLoading: false,
            modalText: "",
            StoreCollections: {},
            search: {},
            isLoadingSearch: false,
            products: [],
            visibleModalProducts: false,
            disabled: true,
            visible: false,
            initLoading: false,
            loading: false,
            hiddenLoadmore: false,
            visibledeletePro: false,
            confirmLoadingPro: false,
            productDelete: null,
            isShowList: false,
            isNewList: false,
            collection_ids: { id: "", numpro_: 0 }

        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }

        })
    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisibleProducts = (type) =>
    {

        this.setState(state =>
        {
            return {
                visibleModalProducts: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setVisibledeletePro = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledeletePro: type,
                isdeletePro: true
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setConfirmLoadingPro = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoadingPro: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.DeleteCollectionsRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    handleOkDeletePro = () =>
    {
        let { productDelete, collection_ids } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoadingPro(true);

        this.props.StoreCollectionsProductsRequest({ product_id: productDelete.product_id, collection_id: collection_ids.id, hidden: 0, deleted: 1, id: productDelete.collections_product_id })
    };

    handleCancelPro = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisibledeletePro(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchCollectionsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: "rank", sort: this.state.meta.sort
        }, { data: filteredvalues });



    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListCollections && nextProps.Web.ListCollections !== prevState.ListCollections) {


            return {
                ListCollections: nextProps.Web.ListCollections,
                isLoadingSearch: false
            };

        }
        if (nextProps.Web.ListCollectionsProducts && nextProps.Web.ListCollectionsProducts !== prevState.ListCollectionsProducts) {

            console.log('====================================');
            console.log(nextProps.Web.ListCollectionsProducts);
            console.log('====================================');
            return {
                ListCollectionsProducts: nextProps.Web.ListCollectionsProducts,


            };

        }
        if (nextProps.Web.StoreCollections && nextProps.Web.StoreCollections !== prevState.StoreCollections) {
            return {
                StoreCollections: nextProps.Web.StoreCollections,

            };

        }
        if (nextProps.Web.CollectionsProducts && nextProps.Web.CollectionsProducts.data !== prevState.CollectionsProducts) {
            let meta = {
                current_page: nextProps.Web.CollectionsProducts.current_page,
                first_page_url: nextProps.Web.CollectionsProducts.first_page_url,
                from: nextProps.Web.CollectionsProducts.from,
                last_page: nextProps.Web.CollectionsProducts.last_page,
                last_page_url: nextProps.Web.CollectionsProducts.last_page_url,
                links: nextProps.Web.CollectionsProducts.links,
                next_page_url: nextProps.Web.CollectionsProducts.next_page_url,
                path: nextProps.Web.CollectionsProducts.path,
                per_page: nextProps.Web.CollectionsProducts.per_page,
                prev_page_url: nextProps.Web.CollectionsProducts.prev_page_url,
                to: nextProps.Web.CollectionsProducts.to,
                total: nextProps.Web.CollectionsProducts.total,
            };
            return {
                CollectionsProducts: nextProps.Web.CollectionsProducts.data,
                isLoadingSearch: false,
                metaproducts: meta,
                hiddenLoadmore: nextProps.Web.CollectionsProducts.current_page >= nextProps.Web.CollectionsProducts.last_page
            };

        }
        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreCollections !== prevState.StoreCollections) {
            let { StoreCollections } = this.state;
            try {
                if (StoreCollections.code === 200) {
                    if (StoreCollections.message && typeof StoreCollections.message === "string") {
                        message.success(StoreCollections.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchCollectionsRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });
                    if (this.state.collection_ids.id) {
                        this.props.ListCollectionsProductsRequest({
                            limit: this.state.meta.per_page,
                            page: 1, column: this.state.meta.column, sort: this.state.meta.sort, collection_id: this.state.collection_ids.id
                        })
                    }
                }
                else {
                    if (StoreCollections.message && typeof StoreCollections.message === "string") {

                        message.error(StoreCollections.message)
                    } else {
                        for (let x in StoreCollections.message) {
                            let mess = StoreCollections.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.handleCancelPro();
            this.setConfirmLoadingPro(false);
            this.setConfirmLoading(false);
            this.props.ResetStoreCollections()
            this.handleCancel();
        }
        if (this.state.ListCollections !== prevState.ListCollections) {
            this.SetisLoadingSeach(false)

            this.data();
        }
        if (this.state.ListCollectionsProducts !== prevState.ListCollectionsProducts) {
            this.data2();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        with: "5%",
                        align: "center"

                    },
                    {
                        title: 'Slug',
                        dataIndex: 'slug',
                        sorter: (a, b) => a.slug - b.slug,

                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterSearch: true,
                        filters: filters.name,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Sản phẩm',
                        dataIndex: 'products',
                        filterSearch: true,
                        filters: filters.name,
                        align: "center",
                        render: (text, record, index) =>
                        {
                            console.log('====================================');
                            console.log(text);
                            console.log('====================================');
                            return <span style={{ cursor: "pointer", textDecoration: "underline", color: Colors.colortext, fontWeight: "bold" }} onClick={() => this.showModalProducts(text, record,)}>{text.total} sản phẩm</span>;
                        }
                    },
                    {
                        title: 'Vị trí',
                        align: "center",
                        dataIndex: 'position',
                    },
                    {
                        title: 'Sắp xếp',
                        align: "center",
                        dataIndex: 'rank',
                    },
                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!convertToBoolean(record.hidden)} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} />
                        },

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data2 = () =>
    {
        try {


            let { ListCollectionsProducts } = this.state;

            if (ListCollectionsProducts.data.length <= 0) {
                return;
            }


            let meta = {
                ...this.state.meta,
                current_page: ListCollectionsProducts.current_page,
                first_page_url: ListCollectionsProducts.first_page_url,
                from: ListCollectionsProducts.from,
                last_page: ListCollectionsProducts.last_page,
                last_page_url: ListCollectionsProducts.last_page_url,
                links: ListCollectionsProducts.links,
                next_page_url: ListCollectionsProducts.next_page_url,
                path: ListCollectionsProducts.path,
                per_page: ListCollectionsProducts.per_page,
                prev_page_url: ListCollectionsProducts.prev_page_url,
                to: ListCollectionsProducts.to,
                total: ListCollectionsProducts.total,
            };
            if (!this.state.isdeletePro) {
                if (this.state.productsData && ListCollectionsProducts.data){
                    const newData = this.state.productsData.concat(ListCollectionsProducts.data);
                    this.setData(newData);
                    this.setList(newData);
                    this.setLoading(false);
                }
               
            } else {

                this.setData(ListCollectionsProducts.data);
                this.setList(ListCollectionsProducts.data);
                this.setLoading(false);
            }

            // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
            // In real scene, you can using public method of react-virtualized:
            // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
            window.dispatchEvent(new Event('resize'));

            this.setState(state =>
            {
                return {
                    // data: data,
                    metaproducts: meta,

                }
            }, () =>
            {
                this.setState(state =>
                {
                    return {
                        isNewList: false,
                    }
                });

            })
        } catch (error) {
            console.error(error);
        }
    }
    data = () =>
    {
        try {


            let { ListCollections } = this.state;

            if (ListCollections.data.length <= 0) {
                return;
            }
            console.log('====================================');
            console.log("ListCollectionsListCollections", ListCollections);
            console.log('====================================');
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListCollections.current_page,
                first_page_url: ListCollections.first_page_url,
                from: ListCollections.from,
                last_page: ListCollections.last_page,
                last_page_url: ListCollections.last_page_url,
                links: ListCollections.links,
                next_page_url: ListCollections.next_page_url,
                path: ListCollections.path,
                per_page: ListCollections.per_page,
                prev_page_url: ListCollections.prev_page_url,
                to: ListCollections.to,
                total: ListCollections.total,
            };

            listfil["name"] = [];


            ListCollections.data.map((item, index) =>
            {
                listfil["name"].push({
                    text: item.name,
                    value: item.name,
                });

                item.key = index + 1
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e }

        this.props.UpdateStatusCollectionsRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: true,
            }
        });
    }

    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_COLLECTIONS_EDIT + "/" + record.id,
            state: record
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }

    deleteProducts = (product) =>
    {

        this.setModalText("Bạn Có chắc muốn xóa!")
        this.setVisibledeletePro(true)
        this.setState(state =>
        {
            return {
                productDelete: product,
                isNewList:true
            }
        })

    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchCollectionsRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchCollectionsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_COLLECTIONS}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })

    }

    showModalProducts = (products, record) =>
    {
        this.setState(state =>
        {
            return {

                productsData: products?.data,
                productsList: products?.data,
                metaproducts: {
                    ...this.state.meta,
                    current_page: products.current_page,
                    first_page_url: products.first_page_url,
                    from: products.from,
                    last_page: products.last_page,
                    last_page_url: products.last_page_url,
                    links: products.links,
                    next_page_url: products.next_page_url,
                    path: products.path,
                    per_page: products.per_page,
                    prev_page_url: products.prev_page_url,
                    to: products.to,
                    total: products.total,
                },
                collection_ids: record,

                hiddenLoadmore: products.current_page >= products.last_page
            }
        }, () =>
        {

            this.setVisibleProducts(true)

        })
    }

    onSearchCollections = (values) =>
    {
        this.SetisLoadingSeach(true)
        const formvalues = values;

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        filteredvalues.id = this.state.collection_ids.id


        this.props.SearchCollectionsProductsRequest({
            limit: META.per_page * 100,
            page: META.current_page, column: META.column, sort: META.sort
        }, { data: filteredvalues });




    }
    onResetFromCollection = () =>
    {

        this.formSearchCollectionsRef.current.resetFields();
        this.formSearchCollectionsRef.current.submit();
        this.setState(state =>
        {
            return {
                collectionProducts: [],
            }
        })
    }

    setLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                loading: type
            }
        })
    }

    setList = (products) =>
    {
        this.setState(state =>
        {
            return {
                productsList: products
            }
        })
    }
    setData = (products) =>
    {

        this.setState(state =>
        {
            return {
                productsData: products
            }
        })
    }

    onLoadMore = () =>
    {
        this.setLoading(true);
        this.setList(
            this.state.productsList.concat(
                [...new Array(3)].map(() => ({
                    loading: true,
                    title: "",
                    thumbnail: "",
                    description: "",
                    id: ""
                })),
            ),
        );

        let nextpage = this.state.metaproducts.current_page + 1;
        if (nextpage >= this.state.metaproducts.last_page) {
            //  nextpage = this.state.metaproducts.last_page;
            this.setState(state =>
            {
                return {
                    hiddenLoadmore: true
                }
            })
        }


        this.props.ListCollectionsProductsRequest({
            limit: this.state.metaproducts.per_page,
            page: nextpage, column: this.state.metaproducts.column, sort: this.state.metaproducts.sort, collection_id: this.state.record.id
        })

    };

    render()
    {


        return (

            <div className="main_content collecttion_" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>

                <Modal
                    title="Thông báo"
                    visible={this.state.visibledeletePro}
                    onOk={this.handleOkDeletePro}
                    confirmLoading={this.state.confirmLoadingPro}
                    onCancel={this.handleCancelPro}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Sản phẩm
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibleModalProducts}
                    onOk={() => this.setVisibleProducts(true)}
                    onCancel={() => this.setVisibleProducts(false)}

                    footer={[

                    ]}
                    width={checkScreenSize().width < 600 ? "90%" : "50%"}
                    bodyStyle={{
                        maxHeight: "70vh",
                        overflowY: "scroll",
                        padding: "10px"
                    }}

                >
                    <div className="modal_content_">

                        <Form name="formsearchCollection" ref={this.formSearchCollectionsRef} onFinish={this.onSearchCollections} style={{
                            padding: "0px",
                            width: "100%",
                            display: "flex",
                            position: "sticky",
                            zIndex: 100,
                            top: "-25px"
                        }}>
                            <Form.Item style={{ margin: 0, backgroundColor: Colors.white }} >
                                <Input.Group compact>

                                    <div className="row">

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tên sản phẩm" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="slug" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="slug" />
                                            </Form.Item>
                                        </div>





                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFromCollection}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </div>
                                    </div>
                                </Input.Group>
                            </Form.Item>
                        </Form>
                        <div style={{ fontWeight: "bold", color: Colors.colortext }}>Danh sách sản phẩm ({"tổng:" + this.state.collection_ids.numpro_})</div>
                        <List
                            className="demo-loadmore-list"
                            loading={this.state.initLoading}
                            itemLayout="horizontal"
                            loadMore={!this.state.hiddenLoadmore && <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 12,
                                    height: 32,
                                    lineHeight: '32px',
                                }}
                            >
                                <Button onClick={this.onLoadMore}>loading more</Button>
                            </div>}
                            dataSource={this.state.CollectionsProducts && this.state.CollectionsProducts.length > 0 ? this.state.CollectionsProducts : this.state.productsList}
                            renderItem={(item) => (
                                <List.Item
                                    actions={[<button type="button" className="reset_btn btn_delete btn__" key="list-loadmore-more" onClick={() => this.deleteProducts(item)}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>]}
                                >
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta
                                            avatar={item.thumbnail ? <div className='img_pro_item'> <Image
                                                style={{ objectFit: "contain" }}
                                                src={LINK.myHostimgServerImg + "/" + item?.thumbnail}
                                            /> </div> : <Avatar size={40} src={LINK.myHostimgServerImg + "/" + item.thumbnail} />}
                                            title={<Link to={LINK.WEB_PRODUCTS_EDIT + "/" + item.id}>{CutString(item.title, 30, true)}</Link>}
                                            description={CutString(item.description, 100, false)}
                                        />
                                        {/* <div>content</div> */}
                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                    </div>
                </Modal>

                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Bộ sưu tập</span>
                        </div>
                        <Link to={LINK.WEB_COLLECTIONS_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>

                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <Form.Item style={{ margin: 0 }}>
                                        <Input.Group compact>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Tên bộ sưu tập" />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="slug" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="slug" />
                                                    </Form.Item>
                                                </div>


                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker />
                                                    </Form.Item>
                                                </div>


                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                        </div>
                                                    </Form.Item>

                                                </div>
                                            </div>
                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>


                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            showModalProducts={this.showModalProducts}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <>  <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}

                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}

                            style={{ paddingBottom: "30px" }}
                        />
                            <div className="pagination_">
                                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                            </div>

                        </>}
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListCollectionsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCollectionsRequest(body));
        },
        StoreCollectionsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreCollectionsRequest(data, id));
        },
        ResetStoreCollections: () =>
        {
            dispatch(ActionsWeb.ResetStoreCollections());
        },
        SearchCollectionsRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchCollectionsRequest(body, data));
        },
        SearchCollectionsProductsRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchCollectionsProductsRequest(body, data));
        },
        StoreCollectionsProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.StoreCollectionsProductsRequest(body));
        },
        ListCollectionsProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCollectionsProductsRequest(body));
        },
        UpdateStatusCollectionsRequest: (body,id) =>
        {
            dispatch(ActionsWeb.UpdateStatusCollectionsRequest(body,id));
        },
        DeleteCollectionsRequest: (body,id) =>
        {
            dispatch(ActionsWeb.DeleteCollectionsRequest(body,id));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);