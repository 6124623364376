import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1 } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message

} from 'antd';
const { TextArea } = Input;
// import
// {
//     FileImageOutlined,
//     EditOutlined,
//     VideoCameraOutlined,
//     PlusOutlined,
//     PlusCircleOutlined,
//     DragOutlined,
//     DeleteOutlined,
// } from '@ant-design/icons';
const { Option } = Select;


class Index extends Component
{

    constructor(props)
    {
        super(props);


        this.state = {
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                name: "",
                store_id: "",
                code: "",
                hidden: false,
                deleted: 0
            },
            StoreWarehouse: {},
            ListWarehouse: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {
            return {
                record: nextProps.history.location.state,

            };

        }
        else if (nextProps.Web.ListStore && nextProps.Web.ListStore !== prevState.ListStore) {
            return {

                ListStore: nextProps.Web.ListStore,

            };

        } else if (nextProps.Web.StoreWarehouse && nextProps.Web.StoreWarehouse !== prevState.StoreWarehouse) {
            return {
                StoreWarehouse: nextProps.Web.StoreWarehouse,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreWarehouse !== prevState.StoreWarehouse) {
            let { StoreWarehouse } = this.state;
            if (StoreWarehouse.code === 200) {
                message.success(StoreWarehouse.message)
            }
            else {
                if (StoreWarehouse.message) {
                    message.error(StoreWarehouse.message)
                }

            }
            this.props.ResetStoreWarehouse()
            this.SetisLoading(false)
        }

        if (this.state.ListStore !== prevState.ListStore) {
            let { ListStore } = this.state

            this.data();
        }
    }
    componentDidMount = () =>
    {

        this.props.ListStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

        return;
        if (!this.props.Web.ListStore.data) {

            this.props.ListStoreRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
        }
        else {
            this.setState(state =>
            {
                return {
                    ListWarehouse: this.props.Web.ListStore
                }
            }, () => { this.data(); })

        }
    }
    data = () =>
    {
        let { ListStore } = this.state;
        // if (!ListStore) {
        //     ListStore = this.props.Web.ListStore
        // }

        if (ListStore.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListStore.current_page,
            first_page_url: ListStore.first_page_url,
            from: ListStore.from,
            last_page: ListStore.last_page,
            last_page_url: ListStore.last_page_url,
            links: ListStore.links,
            next_page_url: ListStore.next_page_url,
            path: ListStore.path,
            per_page: ListStore.per_page,
            prev_page_url: ListStore.prev_page_url,
            to: ListStore.to,
            total: ListStore.total,
        };
        ListStore.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    Store = () =>
    {
        let { record } = this.state;
        if (!record.name) {
            message.error("Vui lòng nhập tên");
            return;
        }

        this.SetisLoading(true)
        this.props.StoreWarehouseRequest(record, record.id ? record.id : "")
    }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <h1>Chi tiết kho</h1>
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        // span: 14,
                    }}
                    layout="horizontal"
                >

                    <div className="section_ section_img">
                        <h3>Thông tin cơ bản</h3>
                        <Form.Item label="Cửa hàng">
                            <Select
                                showSearch
                                style={{
                                    // width: 200,
                                }}
                                name="store_id" id="store_id"
                                placeholder="của hàng"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={this.state.record.parent_id}
                                onChange={(e) => { this.onChangetext(e, "store_id", "record") }}
                            >

                                {this.state.data && this.state.data.map((item, index) =>
                                {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })}


                            </Select>
                        </Form.Item>
                        <Form.Item label="*Tên Kho">
                            <Input name="name" id="name" placeholder="Tên Kho" value={this.state.record.name} onChange={(e) => { this.onChangetext(e, "name", "record") }} showCount maxLength={120} />
                        </Form.Item>
                        <Form.Item label="*Code">
                            <Input name="code" id="code" placeholder="code" value={this.state.record.code} onChange={(e) => { this.onChangetext(e, "code", "record") }} showCount maxLength={120} />
                        </Form.Item>
                        <Form.Item label="Ẩn">
                            <SwitchA name="hidden" id="hidden" value={this.state.record.hidden} checked={this.state.record.hidden ? true : false} onChange={(e) => { this.onChangetext(e, "hidden", "record") }} />
                        </Form.Item>

                    </div>

                    <Form.Item >

                        <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                        <Button type="primary" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={this.Store}>Cập nhật</Button>
                    </Form.Item>
                </Form >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreWarehouseRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreWarehouseRequest(data, id));
        },
        ResetStoreWarehouse: () =>
        {
            dispatch(ActionsWeb.ResetStoreWarehouse());
        },
        ListWarehouseRequest: (body) =>
        {
            dispatch(ActionsWeb.ListWarehouseRequest(body));
        },
        ListStoreRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);