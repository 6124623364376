import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";

import { replaceThous, createSlug, getParamsUrl, } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card,
    InputNumber,
    TreeSelect

} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";


class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = new createRef();

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                slug: "",
                hidden: false,
                deleted: 0
            },
            StoreAttributeProducts: {},
            ListAttributeProducts: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListAttributeProducts && nextProps.Web.ListAttributeProducts.id && nextProps.Web.ListAttributeProducts !== prevState.record) {

            return {
                record: nextProps.Web.ListAttributeProducts,

            };

        }
        if (nextProps.Web.ListAttributeProducts && !nextProps.Web.ListAttributeProducts.id && nextProps.Web.ListAttributeProducts.data !== prevState.ListAttributeProducts) {
          
            return {
                ListAttributeProducts: nextProps.Web.ListAttributeProducts.data,

            };

        }
        if (nextProps.Web.StoreAttributeProducts && nextProps.Web.StoreAttributeProducts !== prevState.StoreAttributeProducts) {
            return {
                StoreAttributeProducts: nextProps.Web.StoreAttributeProducts,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.record !== prevState.record) {
            console.log('====================================');
            console.log(this.state.record);
            console.log('====================================');
            this.formRef.current.setFieldsValue({ ...this.state.record })
        }

        if (this.state.StoreAttributeProducts !== prevState.StoreAttributeProducts) {
            let { StoreAttributeProducts } = this.state;
            try {
                if (StoreAttributeProducts.code === 200) {
                    if (StoreAttributeProducts.message && typeof StoreAttributeProducts.message === "string") {
                        message.success(StoreAttributeProducts.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreAttributeProducts.message && typeof StoreAttributeProducts.message === "string") {

                        message.error(StoreAttributeProducts.message)
                    } else {
                        for (let x in StoreAttributeProducts.message) {
                            let mess = StoreAttributeProducts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)

            this.props.ResetStoreAttributeProducts()

        }
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    isUpdate: true
                }
            })
            this.props.ListAttributeProductsByIdRequest({ id });
        }

        this.props.ListAttributeProductsRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: ""
        });
    }


    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreAttributeProductsRequest(record_, record_.id ? record_.id : "")

    }
    render()
    {



        return (

            <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết thuộc tính</span>
                    </div>} bordered={false}
                        style={{ width: "100%" }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >
                            <Form.Item label="Thuộc tính cha" name={"parent_id"}

                                rules={[
                                    {
                                        required: true,
                                        message: "Danh mục là bắt buộc"
                                    },
                                ]}>
                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%' }}
                                    treeData={this.state.ListAttributeProducts}

                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Danh mục"
                                    // allowClear
                                    // multiple
                                    treeDefaultExpandAll


                                />

                            </Form.Item>
                            <Form.Item label="Tên" layout="vertical" name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên thuộc tính là bắt buộc"
                                    },
                                ]}>

                                <Input name="name" id="name" placeholder="Tên thuộc tính" onChange={(e) =>
                                {
                                    this.formRef.current.setFieldsValue({ code: createSlug(e.target.value) })
                                }} />
                            </Form.Item>
                            <Form.Item label="Code" layout="vertical" name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Code là bắt buộc"
                                    },
                                ]}
                            >

                                <Input name="code" id="code" placeholder="code" />
                            </Form.Item>
                            <Form.Item label="Giá trị" layout="vertical" name="value" >

                                <InputNumber name="value" id="value" min={0} placeholder="Giá trị" />
                            </Form.Item>
                            <Form.Item label="Mô tả" layout="vertical" name="description">

                                <Input name="description" id="description" placeholder="Mô tả" />
                            </Form.Item>

                            <Form.Item >
                                <div className="flex_">
                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                    <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreAttributeProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreAttributeProductsRequest(data, id));
        },
        ResetStoreAttributeProducts: () =>
        {
            dispatch(ActionsWeb.ResetStoreAttributeProducts());
        },
        ListAttributeProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListAttributeProductsRequest(body));
        },
        ListAttributeProductsByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.ListAttributeProductsByIdRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);