import React, { Component, createRef, useEffect, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, List, Dropdown,Menu, Pagination, Modal, message, Button, Select, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined, MinusCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, SaveOutlined,BarsOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, createSlug, formatMN1, getParamsUrl, replaceThous } from "../../../../service";
import IndexMobile from "./IndexMobile";
import { AMOUNT_STAMPS } from "../../../../constants/DefaultData";
const { RangePicker } = DatePicker;
const Index = ({ ListPriceOnAnountStamps }) => {

    const data = [
        {
            title: 'Title 1',
        },
        {
            title: 'Title 2',
        },
        {
            title: 'Title 3',
        },
        {
            title: 'Title 4',
        },
    ];
    const menu = (
        <Menu>
          <Menu.Item>Đang cập nhật</Menu.Item>
          <Menu.Item>Đang cập nhật</Menu.Item>
        </Menu>
      );
    return (
        <List
            grid={{
                gutter: 16,
                column: 1,
            }}
            dataSource={ListPriceOnAnountStamps}
            renderItem={(item) => (
                <List.Item>
                    <Card title={<div style={{ display: "grid", gridTemplateColumns: `${checkScreenSize().width < 600?"1fr 2fr 2fr 0.5fr": "repeat("+(item?.array_discount?.length + 1)+", 1fr)"}` }}>

                        <div className="font-size-14">{item.code} cm</div>
                        {item?.array_discount?.length > 0 && item?.array_discount.map((value, index) => {

                            if (checkScreenSize().width < 600) {
                                if (index < 2) {
                                    return <div className="d-flex flex-column justify-content-center align-items-center font-size-12"> {value.amount_stamp}
                                        <div className="d-flex font-size-10"> <div className="p-1 w-50">Giá </div><div className="p-1 w-50">Giá sau CK </div> </div>


                                    </div>

                                }
                            }else{
                                return <div className="d-flex flex-column justify-content-center align-items-center font-size-12"> {value.amount_stamp}
                                <div className="d-flex font-size-10"> <div className="p-1 w-50">Giá </div><div className="p-1 w-50">Giá sau CK </div> </div>


                            </div>
                            }
                        })}
                        {(checkScreenSize().width < 600) && <div className="d-flex justify-content-end align-items-center">
                            <Dropdown overlay={menu}>
                            <BarsOutlined />
                        </Dropdown>
                            
                            </div>}

                    </div>}
                        bodyStyle={{
                            padding: "0.5em"
                        }}
                    >
                      <div style={{ display: "grid", gridTemplateColumns: `${checkScreenSize().width < 600?"1fr 2fr 2fr 0.5fr": "repeat("+(item?.array_discount.length + 1)+", 1fr)"}` }}>
                            <div></div>
                            {item?.array_discount?.length > 0 && item?.array_discount.map((value, index) => {

                                if (checkScreenSize().width < 600) {
                                    if (index < 2) {
                                        return <div className="d-flex flex-column justify-content-center align-items-center font-size-12">
                                            <div className="d-flex font-size-12"> <div className="p-1 w-50">{formatMN1(value.priceOnAmountStamp)}</div>
                                                <div className="p-1 w-50">{formatMN1(value.priceOnAmountStampDiscount)}</div> </div>


                                        </div>
                                    }
                                }else{
                                    return <div className="d-flex flex-column justify-content-center align-items-center font-size-12">
                                    <div className="d-flex font-size-12"> <div className="p-1 w-50">{formatMN1(value.priceOnAmountStamp)}</div>
                                        <div className="p-1 w-50">{formatMN1(value.priceOnAmountStampDiscount)}</div> </div>


                                </div>
                                }

                            })}

                            {(checkScreenSize().width < 600) && <div></div>}
                        </div>
                    </Card>
                </List.Item>
            )}
        />

    );
}
export default Index;