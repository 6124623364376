import React, { Component, createRef } from "react";


import 'draft-js/dist/Draft.css';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import "./style.css";
import * as LINK from "../../../../../../constants/Links";
import LoadingSmile from "./../../../../../../components/loadingSmile/loadingSmile";

import { formatMoney, validateNUmber, formatDate, replaceThous, formatMN1, formatDateVN, getCookie, UploadFile, CutString, CalDiscount, checkScreenSize } from "./../../../../../../service";

import io from 'socket.io-client';
import * as ActionsWeb from "../../../../../../actions/indexWeb";

import * as Colors from "../../../../../../constants/Colors";
import { Divider, Radio, Input, Image, Tag, message, DatePicker, Tooltip, Form, Select, Checkbox, Avatar, Card, InputNumber, Collapse, Tabs, Popover, Alert, Dropdown, Menu, Space, Spin, Empty, Button, Modal, Drawer, } from 'antd';


import html2canvas from 'html2canvas';

import
{
  SkinOutlined, CameraOutlined, FilterOutlined, StarOutlined, UserOutlined, EnvironmentOutlined, InboxOutlined,
  EditOutlined, ShoppingCartOutlined, MoreOutlined, SolutionOutlined, GiftOutlined, CreditCardOutlined, MoneyCollectOutlined, TagsOutlined, PlusCircleOutlined, DesktopOutlined, ShopOutlined, AccountBookOutlined, LoadingOutlined, ExclamationCircleOutlined, DeleteOutlined, SafetyOutlined, PrinterOutlined, SaveOutlined, CheckOutlined, CarOutlined, VerticalAlignBottomOutlined, CaretDownOutlined, CaretLeftOutlined
} from '@ant-design/icons';

import Draggable from 'react-draggable';

import Editor from "./../../../../../../components/editor/index";
import { Scrollbar } from 'smooth-scrollbar-react';
import moment from 'moment';
import { META, SEX } from "../../../../../../utils/constant";
import Validator from "../../../../../../utils/Validator";
import Products from "./../../../../../../components/products/index";

import Customer from "./../../../../../../components/customer/index";
import Pdfview from "./../../../../../../components/pdfview/index";
import ProductsModal from "./productsModal";
import ItemProducts from "./../../../../../../components/itemProduct/index";
import * as TypePrint from "./../../../../../../constants/TypePrint";
const { Meta } = Card;
const { Panel } = Collapse;

const { TextArea } = Input;
const grid = 8;
const { TabPane } = Tabs;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) =>
{
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const antIcon = (size) =>
{
  return <LoadingOutlined
    style={{
      fontSize: size,
    }}
    spin
  />
};

const { Option } = Select;

const defaultTime = moment().subtract(15, 'years');
const defaultTwoday = moment().add(2, "day");

// const ItemProducts = ({ item }) =>
// {

//   if (!item) {
//     return
//   }
//   let stores = [];

//   if (item.classifystmp) {


//     if (item.classifystmp.children && item.classifystmp.children.length > 0) {

//     } else {
//       if (item.classifystmp.store_id) {

//         for (var y in item.classifystmp.store_id) {
//           const st = item.classifystmp.store_id[y];
//           stores.push({ storeName: item.classifystmp.store_name[st], amount: item.classifystmp.amount })
//         }
//       }
//     }


//   }

//   return <Popover content={<div style={{ padding: "10px" }}>

//     {stores && stores.length > 0 && stores.map((itemst, indexst) =>
//     {
//       return <div key={indexst}> <span>Kho:</span> <span style={{ color: Colors.colorAdd }}>{itemst.storeName ? itemst.storeName : ""}</span>  <span>Số lượng:</span>  <span style={{ color: Colors.colorEdit }}>{itemst.amount ? itemst.amount : 0} </span></div>
//     })}


//   </div>} title="Chi tiết kho" placement="right">

//     <div style={{ display: "flex", flexDirection: "column" }}>
//       <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "10px" }}>
//         <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "90%", overflow: "hidden", flexDirection: "column" }}>
//           <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
//             <Tag color={Colors.colortext} style={{ cursor: "pointer" }} >{CutString(item?.code, 20, false)}</Tag>
//             <Tag color={item.status !== 3 ? Colors.colorAdd : Colors.colorpink} style={{ cursor: "pointer" }} >{CutString(item.status === 3 ? "hết hàng" : item?.amount ? item?.amount : item.amount, 9, false)}</Tag>
//             <Tag color={Colors.colorblue} style={{ cursor: "pointer", color: Colors.color3 }} >{CutString(item?.title, 50, false)}</Tag>
//           </div>
//           <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", fontWeight: "bold" }}>
//             <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
//               <ExclamationCircleOutlined /><span style={{ color: Colors.color2, marginLeft: "10px" }}>Phân loại: {item?.classifys_name ? item?.classifys_name : ""}</span></div>

//             <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
//               <span style={{ color: Colors.color3, marginLeft: "10px" }}>{formatMN1(item?.price ? item?.price : item?.price)}đ</span></div>
//           </div>
//           <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
//             <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
//               <ShoppingCartOutlined /><span style={{ color: "", marginLeft: "10px" }}>Kho hiện tại: {item?.store_name_c ? item?.store_name_c : ""}</span><span style={{ color: "", marginLeft: "10px" }}>Giảm giá tiền: {item?.discount ? formatMN1(item?.discount) : formatMN1(item?.discount)} </span> <span style={{ color: "", marginLeft: "10px" }}>Giảm giá %: {item?.discount_percent ? formatMN1(item?.discount_percent) : formatMN1(item?.discount_percent)} </span></div>

//             <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
//               {/* <span style={{ color: Colors.color3, marginLeft: "10px" }}>176,000đ</span> */}

//             </div>
//           </div>
//         </div>
//         <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "10%" }}>
//           {item?.thumbnail?.[0] ? <Avatar size={45} style={{ lineHeight: "40px" }} src={`${LINK.myHostimgServerImg}/${item?.thumbnail?.[0]}`} /> : <Avatar size={45} style={{ lineHeight: "40px" }} icon={<SkinOutlined />} />}
//         </div>

//       </div>
//       <Divider dashed={true} style={{ borderColor: Colors.colorAdd, margin: 0 }} />
//     </div>

//   </Popover>
// }

const ItemUser = ({ item, color }) =>
{

  if (!item) {
    return
  }
  return <div style={{ display: "flex", flexDirection: "column", }}>
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "10px" }}><Avatar
        style={{
          backgroundColor: color ? color : Colors.colortext,
          verticalAlign: 'middle',
        }}
        size="small"
        gap={0}

      >
        {item.name[0]}
      </Avatar></div>
      <div style={{ fontSize: "10px" }}>{item.name}</div>
    </div>
    <Divider dashed={true} style={{ borderColor: Colors.colorAdd, margin: 0 }} />
  </div>
}
const menu = (
  <Menu>
    <Menu.Item key='1' onClick={() => { }}>
      Hẹn gọi
    </Menu.Item>
    <Menu.Item key='1' onClick={() => { }}>
      Chờ cọc
    </Menu.Item>
    <Menu.Item key='1' onClick={() => { }}>
      Nhập hàng
    </Menu.Item>
  </Menu>
);

const usercrr = getCookie("user");
// console.log('====================================');
// console.log(usercrr);
// console.log('====================================');
let usercrr_={};
try {
   usercrr_ = usercrr ? JSON.parse(usercrr) : {};

} catch (error) {
  
}

class Index extends Component
{

  constructor(props)
  {
    super(props);
    this.draggleRef = new createRef(null);
    this.editor = createRef();
    this.btnsubmitref = createRef();
    this.fullnameref = createRef();
    this.phone_numberref = createRef();
    this.province_idref = createRef();
    this.district_idref = createRef();
    this.war_idref = createRef();
    this.formRef = React.createRef();
    this.voucher_ref = React.createRef();
    this.state = {
      meta: {
        ...META
      },
      sex_: SEX,
      isloading: false,
      isloadingLoad: true,
      testcard: [1, 2],
      searchcode: "",
      datasP: [{
        value: 1,
        text: <ItemProducts isDivider={true} />,
      },
      {
        value: 2,
        text: <ItemProducts isDivider={true} />,
      },
      {
        value: 3,
        text: <ItemProducts isDivider={true} />,
      },
      {
        value: 4,
        text: <ItemProducts isDivider={true} />,
      }
      ],

      typeCus: 1,

      ListUsers: [],
      Oderdetails: [],
      dataUsers: [],
      record: {
        created_at: formatDateVN(new Date().getTime(), true),
        Oderdetails: "",

        expected_date: defaultTwoday,
        fee_ship: 0,
        surcharge: 0,
        money_transfer: 0,
        discount: 0,
        user_id: usercrr_.id,
        user_id_customer_care_: usercrr_,
        user_id_marketer_: usercrr_,
        store_id: usercrr_.store.id
      },
      pay: {
        fee_ship: 0,
        surcharge: 0,
        money_transfer: 0,
        discount: 0,
      },
      customer_chose: "",
      customerNew: {
        birthday: defaultTime
      },
      renderPrices: { totalPr: 0, discountOr: 0, money_transfer: 0, AfterdiscountOr: 0, NeedToPay: 0, Payed: 0, remaining: 0, weight: 0 },
      isloading_: false,
      ListWarsT: "",
      ListDistrictsT: "",
      activeTab: "1",
      idrecord: "",
      disabled: true,
      bounds: {
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
      },
      visibledetail: false,
      isreset: false,
      ListStore: [],
      CheckVouchers: [],
      visibleCustomer: false,
      visiblePdf: false,
      recordstatus: 0,
      typePrint: 1,
      isresetForm: false,
      SettingsShowByCode: [],
      arrSaveBill: [
        {
          label: "Xác nhận đơn hàng",
          value: 1,
          icon: ""
        },
        {
          label: "Đang đóng hàng",
          value: 2,
          icon: ""
        },
        {
          label: "Chờ chuyển hàng",
          value: 2,
          icon: ""
        },
        {
          label: "Gửi hàng đi",
          value: 2,
          icon: ""
        }
      ]
    }
    // this.uploadImageCallBack = this.uploadImageCallBack.bind(this);

    this.timer = null;
  }
  setvisiblePdf = (type, pr) =>
  {
    this.setState(state =>
    {
      return {
        visiblePdf: type,
        typePrint: pr
      }
    })
  }
  setvisibleCustomer = (type) =>
  {
    this.setState(state =>
    {
      return {
        visibleCustomer: type
      }
    })
  }
  setVisibleDetail = (type) =>
  {
    this.setState(state =>
    {
      return {
        visibledetail: type
      }
    })
  }
  handleOkDetail = (e) =>
  {
    this.setState(state =>
    {
      return {
        // record: e,
        isreset: false
      }
    }, () =>
    {
      this.setVisibleDetail(true);
    })


  };

  handleCancelDetail = () =>
  {
    this.setState(state =>
    {
      return {
        isreset: true
      }
    }, () =>
    {
      this.setVisibleDetail(false);
    })
    // console.log('Clicked cancel button');

  };
  setDisabled = (type) =>
  {
    this.setState(state =>
    {
      return {
        disabled: type
      }
    })
  }
  onStart = (_event, uiData) =>
  {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    this.setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  setBounds = (bounds) =>
  {
    this.setState(state =>
    {
      return {
        bounds: bounds
      }
    })
  }
  options = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: '<strong>Option 3</strong>' },
    { value: '4', label: '<em>Option 4</em>' },
  ];

  renderOption = (option) =>
  {
    return (
      <Option key={option.value} value={option.value}>
        <span dangerouslySetInnerHTML={{ __html: option.label }} />
      </Option>
    );
  }

  // test select

  setData = (data) =>
  {

    const data_ = []
    data.map((item, index) =>
    {

      item.product_id = item.id;
      item.num = 1;
      item.note = "";
      item.price_or = item.price

      if (item.classifys) {
        let classsifyt = Object.values(item.classifys);

        if (classsifyt.length > 0) {

          classsifyt.map((el, i) =>
          {

            if (el.children && el.children.length > 0) {
              el.children.map((elc, ic) =>
              {

                for (var x in elc) {
                  const elcI = elc[x];

                  if (elcI.store_id) {
                    for (var xs in elcI.store_id) {
                      let stID = el.store_id[xs];
                      item.price = elcI?.price ? elcI?.price : item.price
                      item.discount_percent = elcI?.discount_percent ? elcI?.discount_percent : item.discount_percent;
                      item.discount = elcI?.discount ? elcI?.discount : item.discount;
                      item.price_or = elcI?.price ? elcI?.price : item.price;
                      item.store_name_c = elcI.store_name[stID];
                      item.store_id_c = stID;
                      item["classifystmp"] = elcI
                      item["classify_id"] = elcI.id
                      item["amount"] = elcI.amount
                      item["voucher"] = ""
                      item["classifys_name"] = el.classifys_name + " x " + elcI.classifys_name
                      item.key = `${item.id}-${el.id}-${elcI.id}`
                      data_.push({
                        data: { ...item, classifystmp: el },
                        value: JSON.stringify({ ...item, classifystmp: el }),
                        // value: `${item.id}-${el.id}-${elcI.id}-${stID}`,
                        text: <ItemProducts item={{ ...item, classifystmp: el }} />,
                      })

                    }
                  }

                }

              })
            } else {

              if (el.store_id) {
                for (var xs in el.store_id) {
                  let stID = el.store_id[xs];
                  item.price = el?.price ? el?.price : item.price
                  item.discount_percent = el?.discount_percent ? el?.discount_percent : item.discount_percent;
                  item.discount = el?.discount ? el?.discount : item.discount;
                  item.price_or = el?.price ? el?.price : item.price;
                  item.store_name_c = el.store_name[stID];
                  item.store_id_c = stID;
                  item["classifys_name"] = el.classifys_name
                  item["classify_id"] = el.id
                  item["voucher"] = ""
                  item["amount"] = el.amount
                  // item["classifystmp"] = {...el} 
                  item.key = `${item.id}-${el.id}`

                  data_.push({
                    data: { ...item, classifystmp: el },
                    value: JSON.stringify({ ...item, classifystmp: el }),
                    // value: `${item.id}-${el.id}-${stID}`,
                    text: <ItemProducts item={{ ...item, classifystmp: el }} />,
                  })
                }
              }
            }

          });

        }

      }
      else {
        item.key = `${item.id}`
        item["voucher"] = ""
        // item["classify_id"] = 
        data_.push({
          value: JSON.stringify(item),
          // value: `${item.id}-${item.store_id}`,
          text: <ItemProducts item={item} />,
        })
      }

    });

    this.setState(state =>
    {
      return {
        datasP: data_
      }
    })
  }

  setSearchcode = (data) =>
  {
    this.setState(state =>
    {
      return {
        searchcode: data
      }
    })
  }
  handleSearch = (newValue) =>
  {

    if (newValue) {

      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.currentValue = newValue;

      const fake = () =>
      {
        this.props.ListProductsRequest({
          limit: 100,
          page: 1, column: "id", sort: "desc", s: newValue
        });
      };

      this.timeout = setTimeout(fake, 300);
    } else {
      // this.setData([]);
    }
  };
  handleChange = (newValue) =>
  {
    // console.log('====================================');
    // console.log(newValue);
    // console.log('====================================');
    // this.setSearchcode(newValue);
    this.setOderdetails(JSON.parse(newValue));
  };


  timeout = "";
  currentValue = "";



  setTypeCus = (e) =>
  {

    this.setState(state =>
    {
      return {
        typeCus: e.target.value
      }
    })
  }
  static getDerivedStateFromProps(nextProps, prevState)
  {
    console.log('====================================');
    console.log(nextProps);
    console.log('====================================');
    if (nextProps.Web.CheckVouchers && nextProps.Web.CheckVouchers.code && nextProps.Web.CheckVouchers !== prevState.CheckVouchers) {

      return {
        CheckVouchers: nextProps.Web.CheckVouchers,

      };

    }
    if (nextProps.isresetForm !== prevState.isresetForm) {

      return {
        isresetForm: nextProps.isresetForm,
      }

    }

    if (nextProps.isreset !== prevState.isreset) {

      return {
        isreset: nextProps.isreset,
      }

    }

    if (nextProps.idrecord && nextProps.idrecord !== prevState.idrecord) {

      return {
        idrecord: nextProps.idrecord,
        isedit: true,
        isloading: true,
        ListDistrictsT: "",
        ListWarsT: "",


      }
    }

    if (nextProps.Web.ListStore && nextProps.Web.ListStore.data !== prevState.ListStore) {



      return {
        ListStore: nextProps.Web.ListStore.data,

      };

    }

    if (nextProps.Web.ListProvinces && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {



      return {
        ListProvinces: nextProps.Web.ListProvinces.data,
        ListProvinces_: nextProps.Web.ListProvinces.data,

      };

    }
    if (nextProps.Web.ListWars && nextProps.Web.ListWars.data !== prevState.ListWarsT) {


      return {
        ListWarsT: nextProps.Web.ListWars.data,

      };

    }
    if (nextProps.Web.ListInfoTransport && nextProps.Web.ListInfoTransport.data !== prevState.ListInfoTransport) {

      return {
        ListInfoTransport: nextProps.Web.ListInfoTransport.data,

      };

    }
    if (nextProps.Web.Order && nextProps.Web.Order?.data?.[0]?.id && nextProps.Web.Order?.data?.[0]?.id !== prevState.record.id) {
      
      const Order = nextProps.Web.Order?.data?.[0] ?? {}
      return {
        record: Order, isedit: false,
        isloading: false,
        ListDistrictsT: "",
        ListWarsT: "",
        Oderdetails: Order.order_detail,
        renderPrices: {
          totalPr: Order.total_money,
          discountOr: Order.discount,
          money_transfer: Order.money_transfer,
          AfterdiscountOr: Order.total_money - Order.discount,
          NeedToPay: Order.total_money - Order.discount,
          Payed: Order.money_transfer,
          remaining: (Order.total_money - Order.discount) - (Order.money_transfer),
          weight: Order.weight
        },
        pay: {
          fee_ship: Order.fee_ship,
          surcharge: Order.surcharge,
          money_transfer: Order.money_transfer,
          discount: Order.discount,
        },
        customer_chose: Order.customer,
        // isloadingLoad: false,

      }
    }
    if (nextProps.Web.ListDistricts && nextProps.Web.ListDistricts.data !== prevState.ListDistrictsT) {
      return {
        ListDistrictsT: nextProps.Web.ListDistricts.data,

      };

    }
    if (nextProps.Web.StoreOrders && nextProps.Web.StoreOrders !== prevState.StoreOrders) {


      return {
        StoreOrders: nextProps.Web.StoreOrders,

      };

    }
    if (nextProps.Web.ListCustomers && nextProps.Web.ListCustomers.data !== prevState.ListCustomers) {

      return {
        ListCustomers: nextProps.Web.ListCustomers.data,

      };

    }


    if (nextProps.Web.ListUsers && nextProps.Web.ListUsers !== prevState.ListUsers) {

      return {
        ListUsers: nextProps.Web.ListUsers,

      };

    }


    if (nextProps.Web && nextProps.Web.ListProducts?.data !== prevState.ListProducts?.data) {


      return {
        ListProducts: nextProps.Web.ListProducts.data,
        isLoadingSearch: false
      };


    }
    if (nextProps.Web.SettingsShowByCode && nextProps.Web.SettingsShowByCode?.code !== prevState.SettingsShowByCode?.code) {


      return {
        SettingsShowByCode: nextProps.Web.SettingsShowByCode,

      };

    }

    return null;

  }

  setFormFieldsValue = (record) =>
  {
    console.log('====================================');
    console.log(record);
    console.log('====================================');
    this.formRef.current.setFieldsValue({
      fee_ship: formatMN1(record?.fee_ship),
      surcharge: formatMN1(record?.surcharge),
      money_transfer: formatMN1(record?.money_transfer),
      discount: formatMN1(record?.discount),
      note: record?.note,
      note_exchange: record?.note_exchange,
      note_print: record?.note_print,
      order_date: record?.order_date,
      fullname: record?.fullname,
      phone_number: record?.phone_number,
      address: record?.address,
      province_id: record?.province_id,
      district_id: record?.district_id,
      war_id: record?.war_id,
      info_transport_id: record?.info_transport_id,
      lading_code: record?.lading_code,
      fee_ship_transport: formatMN1(record?.fee_ship_transport),
      voucher_code: record?.voucher_code,
      store_id: record?.store_id,
      customer_chose: JSON.stringify(record?.customer),
    });
  }
  resetForm = () =>
  {

    this.setState(state =>
    {
      return {
        Oderdetails: [],
        // CheckVouchers:{code:200,data:{id:"",message:""}},
        customer_chose: "",
        record: {
          created_at: formatDateVN(new Date().getTime(), true),
          Oderdetails: "",

          expected_date: defaultTwoday,
          fee_ship: 0,
          surcharge: 0,
          money_transfer: 0,
          discount: 0,
          user_id: usercrr_.id,
          user_id_customer_care_: usercrr_,
          user_id_marketer_: usercrr_,
          store_id: usercrr_.store.id
        },
      }
    }, () =>
    {
      this.renderPrices();
      this.formRef.current.setFieldsValue({
        fee_ship: "",
        surcharge: "",
        money_transfer: "",
        discount: "",
        note: "",
        note_exchange: "",
        note_print: "",
        order_date: "",
        fullname: "",
        phone_number: "",
        address: "",
        province_id: "",
        district_id: "",
        war_id: "",
        info_transport_id: "",
        lading_code: "",
        fee_ship_transport: "",
        voucher_code: "",
        store_id: "",
        customer_chose: "",
      });
      this.props.ResetCheckVouchersRequest({});
      this.props.history.push({
        pathname: LINK.WEB_ORDERS_EDIT + "/add",
      });
    })



  }
  componentDidUpdate(prevProps, prevState)
  {

    try {
      if (this.state.isedit) {
        
        if (this.state.idrecord) {

          this.props.OrderByIdRequest({
            id: this.state.idrecord
          });
        }
        this.setState(state =>
        {
          return {
            isedit: false,
          }
        })
      }
      if (this.state.isresetForm !== prevState.isresetForm) {

        if (this.state.isresetForm) {
          this.resetForm();
        }

      }
      if (this.state.SettingsShowByCode !== prevState.SettingsShowByCode) {
       
        const data_ = JSON.parse(this.state.SettingsShowByCode?.data?.data);
        const dataprint = data_?.list_status_default
        if (dataprint){
         
          this.setState(state =>
          {
            return {
              arrSaveBill: dataprint
            }
          })
        }
       

      }

      if (this.state.CheckVouchers !== prevState.CheckVouchers) {

        const { Oderdetails } = this.state;
        if (this.state.CheckVouchers?.data?.product_id) {
          Oderdetails.map((item, index) =>
          {
            if (item.product_id === this.state.CheckVouchers?.data?.product_id) {
              item.voucher = this.state.CheckVouchers?.data?.id
            }

          });
        }
        this.setState(state =>
        {
          return {
            record: { ...state.record, voucher: this.state.CheckVouchers?.data?.id },

          }
        })
        this.renderPrices();



      }
      if (this.state.pay !== prevState.pay) {


      }
      if (this.state.record.id !== prevState.record.id) {

      
        if (this.state.record.status_transport.length > 0) {
          this.state.record.info_transport_id = parseInt(this.state.record.status_transport[0].id)
          this.state.record.fee_ship_transport = this.state.record.status_transport[0].fee_ship
         
        }
        this.setFormFieldsValue(this.state.record);
        
        
        // this.setFormFieldsValue({ voucher_code: this.state.record.voucher_code});
        if (this.state.record.province_id) {

          this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page * 100,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: this.state.record.province_id
          });
          this.setState(state =>
          {
            return {
              Province_id_: this.state.record.province_id ? (1 * this.state.record.province_id) : state.Province_id_,

            }
          })
        }
        if (this.state.record.province_id && this.state.record.district_id) {
          this.props.ListWarsRequest({
            limit: this.state.meta.per_page * 100,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.record.province_id, districtid: this.state.record.district_id
          });
        }
        this.props.ResetOrderById();
        if (this.state.record.vouchers) {
          this.setState(state =>
          {
            return {
              CheckVouchers: { code: 200, data: this.state.record.vouchers?.[0]?.data, message: this.state.record.vouchers?.[0]?.mess },
              activeTab: "2",
            }
          }, () =>
          {
            this.setState(state =>
            {
              return {
                activeTab: "3"
              }
            }, () =>
            {
              this.setState(state =>
              {
                return {
                  activeTab: "4",
                }
              }, () =>
              {
                this.setState(state =>
                {
                  return {
                    activeTab: "1",
                  }
                })
              })
            })
          })
        }



      }
      if (this.state.StoreOrders !== prevState.StoreOrders) {
        let { StoreOrders } = this.state;

        try {
          if (StoreOrders.code === 200) {
            if (StoreOrders.message && typeof StoreOrders.message === "string") {
              message.success(StoreOrders.message)
            } else {

              message.error("Có lỗi!")
            }
            this.props.ListOrdersRequest({
              limit: this.state.meta.per_page,
              page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
          }
          else {
            if (StoreOrders.message && typeof StoreOrders.message === "string") {

              message.error(StoreOrders.message)
            } else {
              for (let x in StoreOrders.message) {
                let mess = StoreOrders.message[x];
                for (let y in mess) {
                  if (mess[y]) {
                    message.error(mess[y])
                  }

                }
              }
              // message.error("Có lỗi!")
            }

          }

        } catch (error) {
          message.error("Có lỗi!")
          this.SetisLoading(false)
        }
        this.SetisLoading(false)
        this.setState(state =>
        {
          return {
            StoreOrders: ""
          }
        })
        this.props.ResetStoreOrders();

      }
      if (this.state.ListProducts !== prevState.ListProducts) {
        let { ListProducts } = this.state;

        if (ListProducts.code !== 200 && ListProducts.isfilter) {
          message.warn("không tìm thấy sản phẩm!");
          return
        }

        this.setData(ListProducts);

      }
      if (this.state.ListUsers !== prevState.ListUsers) {

        this.setdataUsers(this.state.ListUsers);
      }
      if (this.state.ListCustomers !== prevState.ListCustomers) {

        this.setdataCustomer(this.state.ListCustomers);
      }

      if (this.state.ListProvinces !== prevState.ListProvinces) {

      }
      if (this.state.ListProvinces_ && this.state.ListProvinces_ !== prevState.ListProvinces_) {

        this.setState(state =>
        {
          return {
            Province_id_: this.state.record.province_id ? (1 * this.state.record.province_id) : state.Province_id_
          }
        })
      }

      if (this.state.ListDistrictsT && (this.state.ListDistrictsT !== prevState.ListDistrictsT || this.state.ListDistrictsT[0].id !== prevState.ListDistrictsT[0].id)) {

        if (this.state.type_p) {
          this.setState(state =>
          {
            return {
              ListDistricts_: this.state.ListDistrictsT
            }
          }, () =>
          {
            if (this.state.customer_chose) {
              this.setState(state =>
              {
                return {
                  District_id_: (1 * this.state.customer_chose.district)
                }
              })
            }


          })
        } else {

          this.setState(state =>
          {
            return {
              ListDistricts: this.state.ListDistrictsT,
              // ListDistricts_: !state.ListDistricts_ && state.record.id ? this.state.ListDistrictsT : state.ListDistricts_
              ListDistricts_: this.state.ListDistrictsT
            }
          }, () =>
          {
        
            this.setState(state =>
            {
              return {
                District_id_: (1 * this.state.record.district_id) ? (1 * this.state.record.district_id) : (1 * state.District_id_),
                isloading: false
              }
            })
          })
        }


      }

      if (this.state.ListWarsT && (this.state.ListWarsT !== prevState.ListWarsT || this.state.ListWarsT[0].id !== prevState.ListWarsT[0].id)) {
        // console.log('====================================');
        // console.log("ListWarsT", this.state.ListWarsT);
        // console.log('====================================');
        if (this.state.type_p) {
          this.setState(state =>
          {
            return {
              ListWars_: this.state.ListWarsT,

            }
          }, () =>
          {
            if (this.state.customer_chose) {
              this.setState(state =>
              {
                return {
                  War_id_: (1 * this.state.customer_chose.war)
                }
              })
            }

          })
        } else {
          this.setState(state =>
          {
            return {
              ListWars: this.state.ListWarsT,
              // ListWars_: !state.ListWars_ && state.record.id ? this.state.ListWarsT : state.ListWars_
              ListWars_: this.state.ListWarsT
            }
          }, () =>
          {
            this.setState(state =>
            {
              return {
                War_id_: (1 * this.state.record.war_id) ? (1 * this.state.record.war_id) : (1 * state.War_id_),
                isloading: false
              }
            })
          })
        }

      }
      if (this.state.customerNew !== prevState.customerNew) {

        this.setState(state =>
        {
          return {
            record: {
              ...state.record,
              fullname: !state.record.fullname ? this.state.customerNew.name : state.record.fullname,
              phone_number: !state.record.phone_number ? this.state.customerNew.phone : state.record.phone_number,
              address: !state.record.address ? this.state.customerNew.address : state.record.address,
              province: !state.record.province ? this.state.customerNew.province : state.record.province,
              district: !state.record.district ? this.state.customerNew.district : state.record.district,
              war: !state.record.war ? this.state.customerNew.war : state.record.war
            },

          }
        })
      }



    } catch (error) {
      console.error(error);

    }
  }
  setdataCustomer = (data) =>
  {

    let result = [];

    data.map((item, index) =>
    {

      const newvalue = {
        id: item.id,
        code: item.code,
        name: item.name,
        sex: item.sex,
        birthday: item.birthday,
        phone: item.phone,
        email: item.email,
        province: item.province,
        district: item.district,
        war: item.war,
        province_id: item.province_id,
        district_id: item.district_id,
        war_id: item.war_id,
        address: item.address,
        note: item.note,


      }
      result.push({
        id: JSON.stringify(newvalue),
        text: <ItemUser item={item} color={Colors.colorAdd} />,
        name: item.name
      })
    })


    this.setState(state =>
    {
      return {
        dataCustomers: result
      }
    })
  }
  setdataUsers = (data) =>
  {


    let result = [];

    data.map((item, index) =>
    {
      result.push({
        id: JSON.stringify({ id: item.id, fullname: item.fullname }),
        text: <ItemUser item={item} />,
        fullname: item.fullname
      })
    })


    this.setState(state =>
    {
      return {
        dataUsers: result
      }
    })
  }
  componentDidMount = async () =>
  {
    
    this.setState(state =>
    {
      return {
        ListProducts: [{
          "id": 403,
          "info_transport_id": 2,
          "user_id": 14,
          "code_group": null,
          "code": "RP202304020001",
          "title": "sản phảm 2",
          "slug": "san-pham-2",
          "price": "136000.0000000000",
          "amount": 4,
          "discount": 13600,
          "discount_percent": 10,
          "thumbnail": [
            "img/168044598752203KDU0022301-do-xl.jpg"
          ],
          "images": [
            "img/168044598746789077_1505114344.jpg",
            "img/168044598733789078_1832314125 - Copy.jpg",
            "img/168044598745789078_1832314125.jpg"
          ],
          "video": [],
          "weight": 2,
          "wide": 3,
          "long": 3,
          "height": 1,
          "width": 3,
          "fee_ship": 300000,
          "description": "<p>dădadwaddw</p>",
          "created_at": "2023-04-02 14:33:10",
          "updated_at": "2023-04-02 14:33:10",
          "status": 3,
          "is_reserve": 1,
          "hidden": 0,
          "deleted": 0,
          "classify_": "1*Loai 6*4*eeeeeeee*136000.0000000000*4*13600.0000000000*10.0000000000*0*787*1*Loai 5",
          "attribute_products_": "24*dâdwadwadwd,28*đaadadwđâ",
          "category_": "11*slider1 eee*slider1-eee,13*slider1 ddđ*slider1-ddd",
          "tags_": "5*ddădadwaddwa*ddadadwaddwa,6*ddădadw*ddadadw",
          "price_range_": "1*1*2*30000.0000000000",
          "info_transport_name": "Loai 4",
          "user_name": "admin1",
          "classifys": {
            "1": [
              {
                "classify_products_id": "4",
                "price": "136000.0000000000",
                "amount": "4",
                "warehouse_id": "1",
                "warehouse_name": "Loai 6",
                "classifys_name": "eeeeeeee",
                "discount": "13600.0000000000",
                "discount_percent": "10.0000000000",
                "parent_id": "0",
                "id": "787",
                "store_id": "1",
                "store_name": "Loai 5"
              }
            ]
          },
          "category_id": {
            "11": {
              "id": "11",
              "name": "slider1 eee"
            },
            "13": {
              "id": "13",
              "name": "slider1 ddđ"
            }
          },
          "tags_id": {
            "5": {
              "id": "5",
              "name": "ddădadwaddwa"
            },
            "6": {
              "id": "6",
              "name": "ddădadw"
            }
          },
          "discount_price": 13600,
          "price_range": {
            "1": {
              "id": "1",
              "from_amount": "1",
              "to_amount": "2",
              "price": "30000.0000000000"
            }
          },
          "attribute_products_id": [
            24,
            28
          ],
          "product_id": 403,
          "num": 1,
          "classifystmp": {
            "classify_products_id": "4",
            "price": "136000.0000000000",
            "amount": "4",
            "warehouse_id": "1",
            "warehouse_name": "Loai 6",
            "classifys_name": "eeeeeeee",
            "discount": "13600.0000000000",
            "discount_percent": "10.0000000000",
            "parent_id": "0",
            "id": "787",
            "store_id": "1",
            "store_name": "Loai 5"
          }
        }]
      }
    })

    try {
      this.props.ListUsersRequest({
        limit: 1000,
        page: 1, column: "users.id", sort: "desc"
      });
      this.props.ListCustomersRequest({
        limit: 1000,
        page: 1, column: "customers.id", sort: "desc"
      });
      this.props.ListProvincesRequest({
        limit: this.state.meta.per_page * 100,
        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
      });
      this.props.ListInfoTransportRequest({
        limit: this.state.meta.per_page,
        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
      });
      this.props.ListStoreRequest({
        limit: this.state.meta.per_page * 100,
        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
      })
      this.props.SettingsShowByCodeRequest({ code: "ORDER" })

      this.props.ResetCheckVouchersRequest({})
    } catch (error) {

    }

  }
  componentWillUnmount()
  {
    this.props.ResetCheckVouchersRequest({})
  
  }
  Snap_ = (id) =>
  {
    html2canvas(document.querySelector("#" + id)).then(canvas =>
    {
      // document.body.appendChild(canvas);
      var img = document.createElement("img"); // create an image object

      //conpy hinh vào clipboard
      canvas.toBlob(blob =>
        navigator.clipboard.write([new window.ClipboardItem({
          'image/png': blob
        })])


      )


      // window.prompt("Copy to clipboard: Ctrl+C, Enter", canvas.toDataURL());
      //show hinh
      var url = canvas.toDataURL(); // get canvas content as data URI
      img.src = url;
      img.setAttribute("class", "appendsnap");

      document.body.appendChild(img)


      setTimeout(() =>
      {
        document.body.removeChild(img);
      }, 2000);

    });
  }
  deleteOrderetail = (i) =>
  {
    // console.log('====================================');
    // console.log(i);
    // console.log('====================================');
    const { Oderdetails } = this.state;
    const Oderdetails_ = Oderdetails.filter((item, index) =>
    {
      return i !== index;
    })
    this.setState(state =>
    {
      return {
        Oderdetails: Oderdetails_
      }
    })
    // console.log('====================================');
    // console.log(Oderdetails_);
    // console.log('====================================');
    // this.setOderdetails(Oderdetails_)
  }
  setOderdetails = (newValue) =>
  {
    if (newValue.amount <= 0) {
      message.warning("sản phẩm đã hết hàng tại kho!");
      return;
    }
    const { Oderdetails } = this.state;
    var checkDup = Oderdetails.filter(item =>
    {
      return item.key === newValue.key;
    })
    if (!checkDup[0]?.id) {
      this.setState(state =>
      {
        return {
          Oderdetails: [...state.Oderdetails, newValue]
        }
      }, () =>
      {
        this.renderPrices();
      })
    }

  };
  setOderdetailsMutilpe = (type, newValue) =>
  {
    if (newValue.amount <= 0) {
      message.warning("sản phẩm đã hết hàng tại kho!");
      return;
    }
    const { Oderdetails } = this.state;
    if (type === 1) {
      var checkDup = Oderdetails.filter(item =>
      {
        return item.key !== newValue.key;
      })

      this.setState(state =>
      {
        return {
          Oderdetails: checkDup
        }
      }, () =>
      {
        this.renderPrices();
      })
    } else {
      var checkDup = Oderdetails.filter(item =>
      {
        return item.key === newValue.key;
      })
      if (!checkDup[0]?.id) {
        this.setState(state =>
        {
          return {
            Oderdetails: [...state.Oderdetails, newValue]
          }
        }, () =>
        {
          this.renderPrices();
        })
      }
    }

  }
  setPricePr = (item) =>
  {
    // const { Oderdetails } = this.state;
    // console.log('====================================');
    // console.log(Oderdetails);
    // console.log('====================================');
    // let or = Oderdetails
    let price_or = item.price_or;

    let num = item.num;
    let discount_percent = item.discount_percent;
    // if (type === "discount_percent" || type === "num") {


    // }

    let newprice = ((discount_percent / 100) * price_or) * num;
    // item.total_money = ((price_or * num) - newprice)
    // item.price = newprice
    // Oderdetails[index] = item;
    // this.setState(state =>
    // {

    //   return {
    //     Oderdetails: Oderdetails
    //   }
    // })
    return ((price_or * num) - newprice)
  }

  setDiscount = (type, value, index, item) =>
  {
    const { Oderdetails } = this.state;
    let price = item.price;
    let price_or = item.price_or;
    let num = item.num;
    let newdis = 0;

    if (type === "price") {
      newdis = ((price_or - value) / price_or) * 100;
      item.discount_percent = newdis
      Oderdetails[index] = item
      this.setState(state =>
      {
        return {
          Oderdetails: Oderdetails
        }
      })
    }
    if (type === "discount_percent") {

    }
    // return newdis;
  }
  onChangetextOderdetail = (e, index, type, isnumber = false) =>
  {
    clearTimeout(this.timer); // Xóa bỏ timeout trước nếu có

    this.timer = setTimeout(() =>
    {
      const { Oderdetails } = this.state;
      let Oderdetails_ = "";
      if (Oderdetails[index]) {
        Oderdetails_ = Oderdetails[index];
      }
      let target = e.target;
      if (target) {

        let value = target.value;
        if (isnumber) {

          value = (1 * replaceThous(value))

          if (!validateNUmber(value)) {
            value = 0;
          }

        }
        let name = target.name;
        let newval = this.setDiscount(name, value, index, Oderdetails_);

        // if (newval) {
        //   value = newval;
        // }
        if (name === "discount_percent") {
          let discount = (Oderdetails_.price_or) * (value / 100);

          Oderdetails_["price"] = Oderdetails_.price_or
          Oderdetails_["discount"] = discount
        }
        Oderdetails_[name] = value

        Oderdetails[index] = Oderdetails_;

        this.setState(state =>
        {
          return {
            Oderdetails: Oderdetails
          }
        }, () =>
        {
          if (name !== "note") {
            this.renderPrices();
          }

        })
      }
      else {
        if (isnumber) {

          e = (1 * replaceThous(e))

          if (!validateNUmber(e)) {
            e = 0;
          }

        }
        if (type === "num") {
          if (Oderdetails_.amount < e) {
            message.warning("Số lượng còn lại ở kho tối đa là" + Oderdetails_.amount);
            return;
          }
        }
        if (type === "discount_percent") {
          let discount = (Oderdetails_.price_or) * (e / 100);
          Oderdetails_["price"] = Oderdetails_.price_or
          Oderdetails_["discount"] = discount
        }
        Oderdetails_[type] = e
        Oderdetails[index] = Oderdetails_;
        let newval = this.setDiscount(type, e, index, Oderdetails_);
        this.setState(state =>
        {
          return {
            Oderdetails: Oderdetails
          }
        }, () =>
        {

          if (type !== "note") {
            this.renderPrices();
          }
        })

      }
    }, 1000);




  }
  onChangetext = (e, type, group, isnumber = false) =>
  {

    if (!e) {
      return;
    }
    let target = e.target;
    if (target) {
      let value = target.value;

      if (isnumber) {
        value = (1 * replaceThous(value))

        if (!validateNUmber(value)) {
          value = 0;
        }

      }
      let name = target.name;
      if (name === "user_id_customer_care_" || name === "user_id_marketer_") {
        value = value ? JSON.parse(value) : ""
      }
      this.setState(state =>
      {
        return {
          [group]: { ...state[group], [name]: value }
        }
      }, () =>
      {
        // this.renderPrices();
      })
    }
    else {

      if (isnumber) {
        e = (1 * replaceThous(e))

        if (!validateNUmber(e)) {
          e = 0;
        }

      }
      if (type === "user_id_customer_care_" || type === "user_id_marketer_") {
        e = e ? JSON.parse(e) : ""
      }
      this.setState(state =>
      {
        return {
          [group]: { ...state[group], [type]: e }
        }
      }, () =>
      {

        // this.renderPrices();
      })

    }



  }


  onChangetextpay = (e, type, group, isnumber = false) =>
  {

    if (!e) {
      return;
    }
    let target = e.target;
    if (target) {
      let value = target.value;

      if (isnumber) {
        value = (1 * replaceThous(value))

        if (!validateNUmber(value)) {
          value = 0;
        }

      }
      let name = target.name;

      this.setState(state =>
      {
        return {
          [group]: { ...state[group], [name]: value }
        }
      }, () =>
      {

        this.renderPrices();
      })
    }
    else {

      if (isnumber) {
        e = (1 * replaceThous(e))

        if (!validateNUmber(e)) {
          e = 0;
        }

      }

      this.setState(state =>
      {
        return {
          [group]: { ...state[group], [type]: e }
        }
      }, () =>
      {

        this.renderPrices();
      })

    }



  }
  handleChangeCustomers = (e) =>
  {

    let customer = JSON.parse(e);

    this.props.ListDistrictsRequest({
      limit: this.state.meta.per_page,
      page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: customer.province_id
    });
    this.props.ListWarsRequest({
      limit: this.state.meta.per_page,
      page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
      provinceid: customer.province_id, districtid: customer.district_id
    });
    this.setState(state =>
    {
      return {
        record: {
          ...state.record,
          customer_id: customer.id,
          fullname: customer.name,
          phone_number: customer.phone,
          address: customer.address,
          province: customer.province,
          district: customer.district,
          war: customer.war,
          province_id: customer.province_id,
          district_id: customer.district_id,
          war_id: customer.war_id,

        },
        customer_chose: customer,
        type_p: true,
        Province_id_: customer.province ? (1 * customer.province) : state.Province_id_

      }
    }, () =>
    {


    })
  }
  setCustomerMutilpe = (type, e) =>
  {

    if (type === 2) {
      this.handleChangeCustomers(JSON.stringify(e))
    } else {
      this.deleteCustomerChose()
    }

  }
  deleteCustomerChose = () =>
  {
    this.setState(state =>
    {
      return {
        record: { ...state.record, customer_id: "" },
        customer_chose: ""
      }
    })
    this.formRef.current.setFieldsValue({ customer_chose: "" })
  }
  handleChangeProvince = (e) =>
  {

    this.getCustomerInfo();
    this.setState(state =>
    {
      return {
        Province_id: e,
        Province_id_: !state.Province_id_ && e ? e : state.Province_id_,
        District_id: "",
        War_id: "",
        type_p: false,
        customerNew: { ...state.customerNew, province: e, district: "", war: "" }
      }
    })
    this.props.ListDistrictsRequest({
      limit: this.state.meta.per_page,
      page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
    });
  }

  getCustomerInfo = () =>
  {
    const { record } = this.state
    const formvalues = this.formRef.current.getFieldsValue();
    const data = {
      province_id: formvalues.province_customer ?? record.province_id,
      district_id: formvalues.district_customer ?? record.district_id,
      war_id: formvalues.war_customer ?? record.war_id,
      address: formvalues.address_customer ?? record.address,
      name: formvalues.name_customer ?? record.fullname,
      phone_number: formvalues.phone_number_customer ?? record.phone_number,
    }
    return data;
  }
  setReceiveInfo = (values) =>
  {
    const data = {
      province_id: values.province_id,
      district_id: values.district_id,
      war_id: values.war_id,
      address: values.address,
      fullname: values.name,
      phone_number: values.phone_number

    }
    this.formRef.current.setFieldsValue({ ...data });
  }
  copyCusInfoToReceviceInfo = () =>
  {
    const datacus = this.getCustomerInfo();
    this.setReceiveInfo(datacus);
  }
  handleChangeDistrict = (e) =>
  {
    this.setState(state =>
    {
      return {
        District_id: e,
        War_id: "",
        District_id_: !state.District_id_ ? e : state.District_id_,
        type_p: false,
        customerNew: { ...state.customerNew, district: e, war: "" }
      }
    })
    this.props.ListWarsRequest({
      limit: this.state.meta.per_page,
      page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
      provinceid: this.state.Province_id, districtid: e
    });
  }
  handleChangeWar = (e) =>
  {
    this.setState(state =>
    {
      return {
        War_id: e,
        War_id_: !state.War_id_ ? e : state.War_id_,
        type_p: false,
        customerNew: { ...state.customerNew, war: e }
      }
    })

  }

  handleChangeProvince_ = (e) =>
  {
    const { ListProvinces } = this.state;
    const pro_ = (ListProvinces || []).filter(item =>
    {
      return item.id === e
    })
    this.setState(state =>
    {
      return {
        Province_id_: e,
        District_id_: "",
        War_id_: "",
        type_p: true,
        record: { ...state.record, province: pro_.name, product_id: e, district: "", war: "" }
      }
    })
    this.props.ListDistrictsRequest({
      limit: this.state.meta.per_page,
      page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
    });
  }

  handleChangeDistrict_ = (e) =>
  {
    const { ListDistricts } = this.state;
    const pro_ = (ListDistricts || []).filter(item =>
    {
      return item.id === e
    })
    this.setState(state =>
    {
      return {
        District_id_: e,
        War_id_: "",
        type_p: true,
        record: { ...state.record, district: pro_.name, district_id: e, war: "" }
      }
    })
    this.props.ListWarsRequest({
      limit: this.state.meta.per_page,
      page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
      provinceid: this.state.Province_id_, districtid: e
    });
  }
  handleChangeWar_ = (e) =>
  {
    const { ListDistricts } = this.state;
    const pro_ = (ListDistricts || []).filter(item =>
    {
      return item.id === e
    })
    this.setState(state =>
    {
      return {
        War_id_: e,
        type_p: true,
        record: { ...state.record, war: pro_.name, war_id: e }
      }
    })

  }

  renderPrices = () =>
  {
    const { record, Oderdetails, pay, CheckVouchers } = this.state;

    let totalPr = 0;
    let discountOr = 0;
    let AfterdiscountOr = 0;
    let NeedToPay = 0;
    let Payed = 0;
    let money_transfer = 0;
    let remaining = 0;
    let weight = 0;

    Oderdetails.map((item, index) =>
    {

      totalPr += (parseFloat(item.price) - parseFloat(item.discount)) * parseFloat(item.num);

      weight += parseFloat(item.weight) * parseFloat(item.num);

    });
    if (CheckVouchers?.data?.value) {

      totalPr = totalPr - CheckVouchers.data.value

    }
    totalPr += pay.fee_ship + pay.surcharge;
    discountOr = pay.discount;
    money_transfer = pay.money_transfer;
    AfterdiscountOr = totalPr - discountOr;
    NeedToPay = AfterdiscountOr;
    Payed = money_transfer;
    remaining = NeedToPay - Payed;
    if (remaining < 0) {
      remaining = 0
    }

    this.setState(state =>
    {
      return {
        renderPrices: { totalPr, discountOr, money_transfer, AfterdiscountOr, NeedToPay, Payed, remaining, weight }
      }
    })
    return { totalPr, discountOr, money_transfer, AfterdiscountOr, NeedToPay, Payed, remaining, weight }
  }
  SetisLoading = (type) =>
  {
    this.setState(state =>
    {
      return {
        isloading: type
      }
    })
  }

  validate_ = () =>
  {
    const { record, Oderdetails, typeCus, renderPrices } = this.state;
    const validates = {};
    let store_id_ = Validator.required(record.store_id, "Của hàng không có! Vui lòng đăng nhập lại");
    if (store_id_) {
      return store_id_;
    }
    let Oderdetails_ = Validator.CheckLength(1)(Oderdetails, "Hãy chọn 1 sản phẩm!");
    if (Oderdetails_) {
      return Oderdetails_;
    }

  }
  update_ = (status) =>
  {

    this.setState(state =>
    {
      return {
        recordstatus: status
      }
    }, () =>
    {
      this.formRef.current.submit();
    })
  }
  onFinishForm = (values) =>
  {
    const { record, Oderdetails, typeCus, customerNew, customer_chose, renderPrices, pay, recordstatus } = this.state;
    const va = this.validate_();
    if (va) {
      message.warn(va);
      return;
    }

    record.order_detail = Oderdetails;
    record.customerNew = customerNew.name ? customerNew : "";
    record.user_id_customer_care = record.user_id_customer_care_.id;
    record.user_id_marketer = record.user_id_marketer_.id;
    const customer_chose_ = values.customer_chose ? JSON.parse(values.customer_chose) : "";
    record.customer_id = customer_chose_ ? customer_chose_?.id : record.customer_id;
    record.total_money = renderPrices.totalPr;
    record.weight = renderPrices.weight;
    record.status = recordstatus;

    record.note = !record.note ? "" : record.note;
    record.note_print = !record.note_print ? "" : record.note_print;

    record.note_exchange = !record.note_exchange ? "" : record.note_exchange;
    let record_ = { ...record, ...values }
    record_.fee_ship = !pay.fee_ship ? 0 : pay.fee_ship;
    record_.type = typeCus;
    record_.surcharge = !pay.surcharge ? 0 : pay.surcharge;
    record_.money_transfer = !pay.money_transfer ? 0 : pay.money_transfer;
    record_.discount = !pay.discount ? 0 : pay.discount;
    record_.province = this.filterAddress(this.state.ListProvinces, values.product_id)?.[0]?.name;
    record_.district = this.filterAddress(this.state.ListDistricts_, values.district_id)?.[0]?.name;
    record_.war = this.filterAddress(this.state.ListWars_, values.war_id)?.[0]?.name;
    record_.sex = values.sex_customer;
    record_.emal = "";
    if (values.name_customer) {

      record_.customerNew = {
        name: values.name_customer,
        phone: values.phone_number_customer,
        address: values.address_customer,
        province_id: values.province_customer,
        district_id: values.district_customer,
        war_id: values.war_customer,
        province: this.filterAddress(this.state.ListProvinces, values.province_customer)?.[0]?.name,
        district: this.filterAddress(this.state.ListDistricts, values.district_customer)?.[0]?.name,
        war: this.filterAddress(this.state.ListWars, values.war_customer)?.[0]?.name,
        note: values.note_customer,
        sex: values.sex_customer,
        birthday: values.birthday,
      }
    }


    console.log('====================================');
    console.log("update_update_", record_);
    console.log('====================================');
    // return;
    this.SetisLoading(true)
    this.props.StoreOrdersRequest(record_, record_.id);
  }

  filterAddress = (list, id) =>
  {
    if (list && id) {
      return list.filter(item =>
      {
        return (1 * item.id) === (1 * id)
      })
    }
    return [];
  }

  renderNotes = () =>
  {
    if (this.formRef.current) {
      const formvalues = this.formRef.current.getFieldsValue();
      return <>{formvalues.note && <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tag color={Colors.colorAdd} style={{ cursor: "pointer" }} >Nội bộ</Tag>

        </div>

        <div>
          {formvalues.note}
        </div>
      </div>}

        {formvalues.note_print && <> <Divider dashed={true} style={{ height: "20px", borderColor: Colors.colorblue }} />
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}> <Tag color={Colors.colorEdit} style={{ cursor: "pointer" }} >Để in</Tag>

            </div>
            <div>
              {formvalues?.note_print}
            </div>
          </div>
        </>
        }
        {formvalues.note_exchange && <> <Divider dashed={true} style={{ height: "20px", borderColor: Colors.colorblue }} />
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}> <Tag color={Colors.colorEdit} style={{ cursor: "pointer" }} >Trao đổi</Tag>

            </div>
            <div>
              {formvalues?.note_exchange}
            </div>
          </div>
        </>
        }
      </>;
    }


  }
  getDataOrder = () =>
  {
    const values = this.formRef.current.getFieldsValue()
    const { record, Oderdetails, typeCus, customerNew, customer_chose, renderPrices, pay, recordstatus } = this.state;
    const va = this.validate_();
    if (va) {
      message.warn(va);
      return;
    }

    record.order_detail = Oderdetails;
    record.customerNew = customerNew.name ? customerNew : "";
    record.user_id_customer_care = record.user_id_customer_care_.id;
    record.user_id_marketer = record.user_id_marketer_.id;
    const customer_chose_ = values.customer_chose ? JSON.parse(values.customer_chose) : "";
    record.customer_id = customer_chose_ ? customer_chose_?.id : record.customer_id;
    record.total_money = renderPrices.totalPr;
    record.weight = renderPrices.weight;
    record.status = recordstatus;

    record.note = !record.note ? "" : record.note;
    record.note_print = !record.note_print ? "" : record.note_print;

    record.note_exchange = !record.note_exchange ? "" : record.note_exchange;
    let record_ = { ...record, ...values }
    record_.fee_ship = !pay.fee_ship ? 0 : pay.fee_ship;
    record_.surcharge = !pay.surcharge ? 0 : pay.surcharge;
    record_.money_transfer = !pay.money_transfer ? 0 : pay.money_transfer;
    record_.discount = !pay.discount ? 0 : pay.discount;
    record_.province = this.filterAddress(this.state.ListProvinces, values.product_id)?.[0]?.name;
    record_.district = this.filterAddress(this.state.ListDistricts_, values.district_id)?.[0]?.name;
    record_.war = this.filterAddress(this.state.ListWars_, values.war_id)?.[0]?.name;
    record_.sex = values.sex_customer;
    record_.emal = "";
    if (values.name_customer) {

      record_.customerNew = {
        name: values.name_customer,
        phone: values.phone_number_customer,
        address: values.address_customer,
        province_id: values.province_customer,
        district_id: values.district_customer,
        war_id: values.war_customer,
        province: this.filterAddress(this.state.ListProvinces, values.province_customer)?.[0]?.name,
        district: this.filterAddress(this.state.ListDistricts, values.district_customer)?.[0]?.name,
        war: this.filterAddress(this.state.ListWars, values.war_customer)?.[0]?.name,
        note: values.note_customer,
        sex: values.sex_customer,
        birthday: values.birthday,
      }
    }
    return record_;
  }
  onChangeCheckVoucher = (e) =>
  {
    const value = e.target.value;
    if (value) {
      const customerData = this.getCustomerInfo();
      const { Oderdetails } = this.state
      const record_ = this.getDataOrder();
      if (!customerData.phone_number) {
        message.warn("Bạn chưa nhập thông tin khách hàng!")
        this.formRef.current.setFieldsValue({ voucher_code: "" })
        return;
      }
      if (!Oderdetails || Oderdetails.length <= 0) {
        message.warn("Bạn chưa chọn sản phẩm!")
        this.formRef.current.setFieldsValue({ voucher_code: "" })
        return;
      }


      this.props.CheckVouchersRequest({ code: value, order: record_, customer: customerData })
    } else {
      this.props.ResetCheckVouchersRequest({});

    }


  }
  render()
  {
    console.log('====================================');
    console.log("okokoookkk");
    console.log('====================================');
    let countP = 0;
    return (
      <>


        <Drawer
          title={<div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6"><span>Phiếu in</span></div>
            <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
          </div>} placement="right"
          onClose={() => { this.setvisiblePdf(false) }}
          visible={this.state.visiblePdf}
          size={checkScreenSize().width < 600 ? "default" : "large"}

        >
          <Pdfview type={this.state.typePrint} typePrint={TypePrint.ORDER} data={{ id: this.state.record?.id }} visible={this.state.visiblePdf} />
        </Drawer>
        <Drawer
          title={<div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6"><span>Chọn Khách hàng</span></div>
            <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}><Link to={LINK.WEB_CUSTOMERS_EDIT + "/add"}> <Button type="primary" size="small"><PlusCircleOutlined /></Button></Link></div>
          </div>} placement="right"
          onClose={() => { this.setvisibleCustomer(false) }}
          visible={this.state.visibleCustomer}
          size={checkScreenSize().width < 600 ? "default" : "large"}

        >
          <Customer isListShow={false} setCustomerMutilpe={this.setCustomerMutilpe} isgroup={false} />
        </Drawer>
        <Drawer
          title="Chọn sản phẩm" placement="right"
          onClose={() => this.setVisibleDetail(false)}
          visible={this.state.visibledetail}
          size={checkScreenSize().width < 600 ? "default" : "large"}

        // style={{
        //   position: 'absolute',

        // }}
        >
          <Products isListShow={false} setOderdetailsMutilpe={this.setOderdetailsMutilpe} isgroup={false} />
        </Drawer>
        <Form name="order" onFinish={this.onFinishForm} ref={this.formRef}>
          <div className="box_form row order_" id={this.props.id} >

            {this.state.isloading && <div className="ovelay">
              <LoadingSmile height={"100%"} width={"100%"} />
            </div>
            }

            <div className="box_form_left col-lg-8 col-md-6 col-sm-12" style={{
              position: "relative",
              overflow: "hidden"
            }}>
              <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ShoppingCartOutlined />
                  <span style={{ marginLeft: "10px" }}>Sản phẩm</span>
                </div>

                <Button size="small" onClick={this.handleOkDetail}><CaretLeftOutlined style={{ color: Colors.colorEdit }} /></Button>
              </div>} bordered={true}
                style={{ width: "100%", position: "unset" }}
                bodyStyle={{
                  padding: "10px 10px",


                }}
                headStyle={{
                  backgroundColor: Colors.colorgrey,
                  display: "flex",
                  minHeight: "30px",
                }}
              >

                <Form.Item style={{ margin: 0 }}>
                  <Select
                    showSearch
                    value={this.state.searchcode}
                    placeholder={"tìm"}
                    style={{ width: "100%", }}
                    defaultActiveFirstOption={true}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    options={(this.state.datasP || []).map((d) => ({
                      value: d.value,
                      label: d.text,
                    }))}

                    dropdownStyle={{
                      backgroundColor: Colors.coloryellows,
                    }}
                  />

                </Form.Item>
                <div className="c_" style={{ backgroundColor: Colors.colorgrey, padding: "10px", borderRadius: "2px", marginTop: "10px" }}>
                  {this.state.Oderdetails && this.state.Oderdetails.length > 0 && <Card
                    title={<div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                        <span style={{}}>Số loại SP: {(this.state.Oderdetails).length}</span>
                        <Divider type="vertical" style={{ height: "20px" }} />

                        {this.state.Oderdetails && this.state.Oderdetails.map((item, index) =>
                        {
                          countP += (1 * item.num);
                        })}
                        <span style={{ marginLeft: "10px" }}>Số lượng SP: {countP}</span>
                        <span style={{ marginLeft: "10px" }}> <Tooltip title="Chụp ảnh đơn hàng" ><button type="button" className="reset_btn scale_btn" style={{ color: Colors.colorpink, display: "flex" }} onClick={() => this.Snap_('order_')}><CameraOutlined /></button></Tooltip></span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
                        <Input suffix={<FilterOutlined />} placeholder="Lọc trong đơn" size={"small"} style={{ fontSize: "12px" }} />
                      </div>
                    </div>}
                    bordered={false}
                    style={{
                      width: "100%", backgroundColor: "unset"
                    }}
                    bodyStyle={{
                      padding: 0,
                      backgroundColor: "unset"
                    }}
                    headStyle={{
                      padding: 0,
                      display: "flex",
                      fontSize: "12px",
                      minHeight: "30px",
                      width: "100%"
                    }}
                  >
                    <div id="order_">
                      {this.state.Oderdetails && this.state.Oderdetails.length > 0 && this.state.Oderdetails.map((item, index) =>
                      {
                        if (item) {


                          return <Card
                            key={index}
                            style={{
                              width: "100%",
                              marginTop: "10px"
                            }}
                            bodyStyle={{
                              padding: "10px 10px",
                              position: "relative"
                            }}

                            className="card_pr_order"
                            actions={[
                              <Form.Item
                                initialValue={item.note}
                                name={"note_detail_" + index}
                                noStyle

                              >

                                <Input prefix={checkScreenSize().width < 600 ? "GC" : "Ghi chú"} size={"small"} style={{ fontSize: "12px", marginLeft: "10px" }} onChange={(e) => { this.onChangetextOderdetail(e, index, "note") }} />
                              </Form.Item>,
                              <Form.Item
                                name={"discount_percent_detail_" + index}
                                noStyle
                                initialValue={item.discount_percent}
                              // style={{ marginLeft: "10px" }}
                              >

                                <Input prefix={checkScreenSize().width < 600 ? "CK %" : "Chiết khấu %"} style={{ width: "100%", fontSize: "12px", marginLeft: "10px" }} size={"small"} onChange={(e) => { this.onChangetextOderdetail(e, index, "discount_percent", true) }} />
                              </Form.Item>,
                              <div style={{ height: "100%", padding: "0 10px", color: Colors.colortext, fontSize: "1.2em" }}>{formatMN1(this.setPricePr(item))}</div>


                            ]}

                          >

                            <Meta

                              avatar={item?.thumbnail ? <Avatar size={45} style={{ lineHeight: "40px" }} src={`${LINK.myHostimgServerImg}/${item?.thumbnail}`} /> : <Avatar size={45} style={{ lineHeight: "40px" }} icon={<SkinOutlined />} />}
                              title={<div>
                                <Tag color={Colors.colortext} style={{ cursor: "pointer" }} >{CutString(item?.code, 20, false)}</Tag>
                                {!this.props.isView && <Tag color={item.status !== 3 ? Colors.colorAdd : Colors.colorpink} style={{ cursor: "pointer" }} >{CutString(item.status === 3 ? "hết hàng" : item?.classifystmp?.amount ? item?.classifystmp?.amount : item.amount, 9, false)}</Tag>}
                                <Tag color={Colors.colorblue} style={{ cursor: "pointer", color: Colors.color3 }} >{CutString(item?.title, 50, false)}</Tag>
                              </div>

                              }
                              description={<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", fontSize: "12px" }}>
                                  <ExclamationCircleOutlined /><span style={{ color: Colors.color2 }}>Phân loại: {item?.classifys_name ? item?.classifys_name : ""}</span></div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", }}>


                                  {checkScreenSize().width >= 600 && <div className="l__">
                                    <Tag color={Colors.colortext} style={{ cursor: "pointer", fontSize: "8px" }} >{item?.weight} G</Tag>
                                    <Tag color={Colors.colortext} style={{ cursor: "pointer", fontSize: "8px" }} >{item?.long} CM</Tag>
                                    <Tag color={Colors.colortext} style={{ cursor: "pointer", fontSize: "8px" }} >{item?.wide} CM</Tag>
                                    <Tag color={Colors.colortext} style={{ cursor: "pointer", fontSize: "8px" }} >{item?.height} CM</Tag>

                                  </div>}
                                  <div className="r__ row" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "60%" }}>
                                    <div className="col-lg-6 col-md-6 col-sm-5 ">
                                      <Input addonAfter="đ" id="price" name="price" value={formatMN1(item?.price)} style={{ width: "100%", fontSize: "12px" }} size={"small"}
                                        onChange={(e) => { this.onChangetextOderdetail(e, index, "price", true) }}
                                      />
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-2 ">
                                      <Tag color={Colors.color1} style={{ margin: 0 }} >X</Tag>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-5 ">
                                      <InputNumber min={1} style={{ width: "100%", fontSize: "12px" }} addonAfter="SL" value={item?.num} size={"small"} onChange={(e) => { this.onChangetextOderdetail(e, index, "num") }} />

                                    </div>
                                  </div>
                                </div>

                                <div className="btn_delele_pr_order " style={{
                                  border: "1px solid ",
                                  borderColor: Colors.colorpink,
                                  backgroundColor: Colors.colorpink,
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: "-10px",
                                  right: "-10px",
                                  width: "20px",
                                  height: "20px",
                                  // display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",

                                }}><Tooltip title="Xóa sản phẩm khỏi đơn hàng" ><button type="button" className="reset_btn scale_btn" style={{ color: Colors.white, display: "flex" }} onClick={() => this.deleteOrderetail(index)}><DeleteOutlined /></button></Tooltip></div>
                              </div>}

                            />

                          </Card>
                        }
                      })}
                    </div>
                  </Card>}
                  {!this.state.Oderdetails || this.state.Oderdetails.length <= 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
              </Card>

              <div className="row" style={{ padding: 0 }}>

                <div className="box_form_right col-lg-6 col-md-6 col-sm-12" style={{ padding: 0, }}>
                  <Card title={
                    <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center" }}><SolutionOutlined />
                        <span style={{ marginLeft: "10px" }}>Thanh toán</span>

                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Popover content={<div>

                          Chưa có
                        </div>} title="Thêm phương thức thanh toán" placement="right">

                          <button className="reset_btn" style={{ display: "flex", alignItems: "center" }}><MoreOutlined /></button>
                        </Popover>
                      </div>
                    </div>

                  } bordered={true}
                    style={{ width: "100%", marginTop: "10px" }}
                    bodyStyle={{
                      padding: "10px 10px"
                    }}
                    headStyle={{
                      backgroundColor: Colors.colorgrey,
                      display: "flex",
                      minHeight: "30px",
                      padding: "0 5px"
                    }}
                  >
                    <div>

                      <div style={{ marginBottom: "10px" }}>
                        Tổng khối lượng đơn hàng  {this.state?.renderPrices?.weight + " (g)"}
                      </div>

                      <div style={{ marginBottom: "5px" }}>
                        <Form.Item
                          name={"fee_ship"}
                          style={{
                            width: '100%',
                            marginBottom: 0
                          }}>
                          <Input prefix={<InboxOutlined />} placeholder="Phí vận chuyển" size={"small"} min={0}
                            style={{ fontSize: "12px", width: "100%" }}
                            name="fee_ship" id="fee_ship"
                            onChange={(e) => { this.onChangetextpay(e, "fee_ship", "pay", true) }}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <Form.Item
                          name={"discount"}
                          style={{
                            width: '100%',
                            marginBottom: 0
                          }}
                        >
                          <Input prefix={<GiftOutlined />} placeholder="Giảm giá đơn hàng" size={"small"}
                            style={{ fontSize: "12px", width: "100%" }}
                            name="discount" id="discount"
                            onChange={(e) => { this.onChangetextpay(e, "discount", "pay", true) }}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <Form.Item
                          name={"money_transfer"}
                          style={{
                            width: '100%',
                            marginBottom: 0
                          }}
                        >
                          <Input prefix={<CreditCardOutlined />} placeholder="Tiền chuyển khoản" size={"small"}
                            style={{ fontSize: "12px", width: "100%" }}
                            name="money_transfer" id="money_transfer"
                            onChange={(e) => { this.onChangetextpay(e, "money_transfer", "pay", true) }}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <Form.Item
                          name={"surcharge"}
                          style={{
                            width: '100%',
                            marginBottom: 0
                          }}
                        >
                          <Input prefix={<MoneyCollectOutlined />} placeholder="Phụ thu" size={"small"}
                            style={{ fontSize: "12px", width: "100%" }}
                            name="surcharge" id="surcharge"
                            onChange={(e) => { this.onChangetextpay(e, "surcharge", "pay", true) }}
                          />
                        </Form.Item>
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <Form.Item
                          name={"voucher_code"}
                          style={{
                            width: '100%',
                            marginBottom: 0
                          }}>
                          <Input prefix={<AccountBookOutlined />} placeholder="Nhập mã voucher" size={"small"} min={0}
                            style={{ fontSize: "12px", width: "100%" }}
                            onChange={(e) => { this.onChangeCheckVoucher(e) }}

                          />
                        </Form.Item>

                        {this.state.CheckVouchers.message && <p className="text-danger fst-italic">{this.state.CheckVouchers.message}</p>}
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Alert message={
                          <>
                            <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                              <span>Tổng số tiền</span>
                              <span>{formatMN1(this.state.renderPrices.totalPr)}₫</span>
                            </div>

                            <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                              <span>Giảm giá </span>
                              <span style={{ color: Colors.colorAdd }}>{formatMN1(this.state.renderPrices.discountOr)}₫</span>
                            </div>
                            <Divider dashed={true} style={{ height: "20px", borderColor: Colors.colorblue }} />
                            <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                              <span>Sau giảm giá</span>
                              <span>{formatMN1(this.state.renderPrices.AfterdiscountOr)}₫</span>
                            </div>

                            <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                              <span>Cần thanh toán </span>
                              <span style={{ color: Colors.color2 }}>{formatMN1(this.state.renderPrices.NeedToPay)}₫</span>
                            </div>
                            <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                              <span>Đã thanh toán </span>
                              <span>{formatMN1(this.state.renderPrices.Payed)}₫</span>
                            </div>
                            <Divider dashed={true} style={{ height: "20px", borderColor: Colors.colorblue }} />
                            <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                              <span>Còn thiếu </span>
                              <span style={{ color: Colors.colormain }}>{formatMN1(this.state.renderPrices.remaining)}₫</span>
                            </div>
                          </>
                        }

                          type="success" />

                      </div>

                    </div>
                  </Card>
                </div>
                <div className="box_form_right col-lg-6 col-md-6 col-sm-12" style={{ padding: 0, }}>
                  <Tabs defaultActiveKey={this.state.activeTab} activeKey={this.state.activeTab} tabBarStyle={{ backgroundColor: Colors.colorgrey, marginTop: "10px", padding: "5px  10px" }} onChange={(e) =>
                  {
                    this.setState(state =>
                    {
                      return {
                        activeTab: e
                      }
                    })
                  }}>
                    <TabPane tab="Tất cả" key="1" style={{
                      padding: "10px"
                    }}

                    >
                      {this.renderNotes()}
                    </TabPane >
                    <TabPane tab="Nội bộ" key="2" style={{
                      padding: "10px"
                    }}>
                      <Form.Item
                        name={"note"}
                        style={{
                          width: '100%',
                          marginBottom: 0
                        }}
                        initialValue={this.state.record?.note}
                      >
                        <TextArea placeholder="ghi chú nội bộ" style={{ minHeight: "100px" }}
                          name="note" id="note"

                        />
                      </Form.Item>
                    </TabPane >
                    <TabPane tab="Để in" key="3" style={{
                      padding: "10px"
                    }}>
                      <Form.Item
                        name={"note_print"}
                        style={{
                          width: '100%',
                          marginBottom: 0
                        }}
                        initialValue={this.state.record?.note_print}
                      >
                        <TextArea placeholder="ghi chú để in" style={{ minHeight: "100px" }}
                          name="note_print" id="note_print"
                        />
                      </Form.Item>
                    </TabPane >
                    <TabPane tab="Trao đổi" key="4" style={{
                      padding: "10px"
                    }}>
                      <Form.Item
                        name={"note_exchange"}
                        style={{
                          width: '100%',
                          marginBottom: 0
                        }}
                        initialValue={this.state.record?.note_exchange}
                      >
                        <TextArea placeholder="ghi chú trao đổi" style={{ minHeight: "100px" }}
                          name="note_exchange" id="note_exchange"

                        />
                      </Form.Item>
                    </TabPane >
                  </Tabs>


                </div>
              </div>
            </div>
            <div className="box_form_right col-lg-4 col-md-6 col-sm-12 " style={{ paddingRight: 0 }}>
              <Card title={<div style={{ display: "flex", alignItems: "center" }}><StarOutlined />
                <span style={{ marginLeft: "10px" }}>Thông tin</span>
              </div>} bordered={true}
                style={{ width: "100%", }}
                bodyStyle={{
                  padding: "10px 10px"
                }}
                headStyle={{
                  backgroundColor: Colors.colorgrey,
                  display: "flex",
                  minHeight: "30px",
                }}
              >
                <div >
                  <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="col-lg-6 col-md-6  col-sm-6" >Cửa hàng:</div>
                    <div className="col-lg-6 col-md-6  col-sm-6" style={{ fontWeight: "bold", color: Colors.color2, justifyContent: "flex-end" }}>
                      <Form.Item name={"store_id"}
                        initialValue={usercrr_.store.id > 1 ? usercrr_.store.id : ""}
                        rules={[
                          {
                            required: true,
                            message: "Cửa hàng là bắt buộc"
                          },
                        ]}
                      >
                        <Select
                          maxTagCount='responsive'
                          placeholder="Cửa hàng"
                          name="Store" id="Store"
                          style={{ width: "100%" }}
                          options={(this.state.ListStore || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}
                        />
                      </Form.Item>
                    </div>

                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
                    <div className="col-lg-6 col-md-6  col-sm-6 flex_">Tạo lúc:</div>
                    <div className="col-lg-6 col-md-6  col-sm-6 flex_" style={{ fontWeight: "bold", color: Colors.color2, justifyContent: "flex-end" }}>{this.state.record?.created_at}</div>

                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
                    <div className="col-lg-6 col-md-6  col-sm-6 flex_">NV chăm sóc:</div>
                    <div className="col-lg-6 col-md-6  col-sm-6 flex_" style={{ justifyContent: "flex-end" }}>
                      {!this.state.record?.user_id_customer_care_ && <Form.Item
                        name={"user_id_customer_care_"}
                        style={{
                          minWidth: "40%",
                          marginBottom: 0
                        }}
                        rules={[
                          {
                            required: true,
                            message: "NV chăm sóc là bắt buộc"
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          maxTagCount='responsive'
                          placeholder="NV chăm sóc"
                          optionFilterProp="children"
                          id="user_id_customer_care_"
                          name="user_id_customer_care_"
                          onChange={(e) =>
                          {

                            this.onChangetext(e, "user_id_customer_care_", "record");
                          }}

                          filterOption={(input, option) =>
                          {

                            return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                          }

                          }
                          options={(this.state?.dataUsers || []).map((d) => ({
                            value: d.id,
                            label: d.text,
                            name: d.name,
                          }))}
                          // dropdownStyle={{ padding: 0 }}
                          size="small"
                          style={{
                            minWidth: "40%",
                            // fontSize: "10px"
                          }}

                        /></Form.Item>}

                      {this.state.record?.user_id_customer_care_ && <div style={{ fontWeight: "bold", color: Colors.color2, display: "flex", }} onClick={() =>
                      {

                        this.setState(state =>
                        {
                          return {
                            record: { ...state.record, user_id_customer_care_: "" }
                          }
                        })

                      }}>

                        <Avatar
                          style={{
                            backgroundColor: Colors.colortext,
                            verticalAlign: 'middle',
                          }}
                          size="small"
                          gap={0}

                        >
                          {this.state.record?.user_id_customer_care_?.fullname?.[0]}
                        </Avatar>
                        <div style={{ marginLeft: "10px" }}>{this.state.record?.user_id_customer_care_?.fullname}</div>
                      </div>
                      }
                    </div>

                  </div>
                  <div className="row" style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
                    <div className="col-lg-6 col-md-6  col-sm-6 flex_">Marketer:</div>
                    <div className="col-lg-6 col-md-6  col-sm-6 flex_" style={{ justifyContent: "flex-end" }}>
                      {!this.state.record?.user_id_marketer_ && <Form.Item
                        name={"user_id_marketer_"}
                        style={{
                          minWidth: "40%",
                          marginBottom: 0
                        }} rules={[
                          {
                            required: true,
                            message: "Marketer là bắt buộc"
                          },
                        ]}><Select
                          showSearch
                          placeholder="Marketer"
                          optionFilterProp="children"
                          id="user_id_marketer_"
                          name="user_id_marketer_"

                          onChange={(e) =>
                          {
                            this.onChangetext(e, "user_id_marketer_", "record");
                          }}

                          filterOption={(input, option) =>
                          {

                            return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                          }

                          }
                          options={(this.state?.dataUsers || []).map((d) => ({
                            value: d.id,
                            label: d.text,
                            name: d.name,
                          }))}

                          size="small"
                          style={{
                            width: "100%",
                            fontSize: "10px"
                          }}

                        /></Form.Item>}
                      {this.state.record?.user_id_marketer_ && <div style={{ fontWeight: "bold", color: Colors.color2, display: "flex", }}
                        onClick={() =>
                        {

                          this.setState(state =>
                          {
                            return {
                              record: { ...state.record, user_id_marketer_: "" }
                            }
                          })
                        }}
                      >

                        <Avatar
                          style={{
                            backgroundColor: Colors.colortext,
                            verticalAlign: 'middle',
                          }}
                          size="small"
                          gap={0}

                        >
                          {this.state.record?.user_id_marketer_?.fullname?.[0]}
                        </Avatar>
                        <div style={{ marginLeft: "10px" }}>  {this.state.record?.user_id_marketer_.fullname}</div>
                      </div>}
                    </div>
                  </div>


                </div>
              </Card>

              <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}><div style={{ display: "flex", alignItems: "center" }}><UserOutlined />
                <span style={{ marginLeft: "10px" }}>Khách hàng</span>
              </div>
                <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                  {/* <Form.Item
                    noStyle
                    name="customer_chose">
                    <Select
                      showSearch
                      value=""
                      placeholder={"Khách hàng"}
                      style={{ width: "100%", }}
                      defaultActiveFirstOption={true}
                      showArrow={false}
                      onChange={this.handleChangeCustomers}
                      notFoundContent={null}
                      filterOption={(input, option) =>
                      {

                        return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                      }}
                      options={(this.state.dataCustomers || []).map((d) => ({
                        value: d.id,
                        label: d.text,
                        name: d.name
                      }))}
                    />
                  </Form.Item> */}
                  <Button size="small" onClick={() =>
                  {
                    this.setvisibleCustomer(true)
                  }}><CaretLeftOutlined style={{ color: Colors.colorEdit }} /></Button>
                </div>
              </div>
              } bordered={true}
                style={{ width: "100%", marginTop: "10px" }}
                bodyStyle={{
                  padding: "10px 10px"
                }}
                headStyle={{
                  backgroundColor: Colors.colorgrey,
                  display: "flex",
                  minHeight: "30px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "", marginTop: "10px" }}>
                  <Radio.Group value={this.state.typeCus} onChange={(e) => this.setTypeCus(e)} >
                    <Radio.Button value={1} >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <DesktopOutlined />
                        <span style={{ marginLeft: "10px" }}>Online</span>
                      </div>
                    </Radio.Button>
                    <Radio.Button value={2} >

                      <div style={{ display: "flex", alignItems: "center" }}><ShopOutlined />

                        <span style={{ marginLeft: "10px" }}>Tại quầy</span>
                      </div></Radio.Button>

                  </Radio.Group>

                </div>

                {!this.state.customer_chose && <div style={{ display: "flex", justifyContent: "", flexDirection: "column", marginTop: "10px" }}>
                  <Form.Item
                    noStyle
                    name="sex_customer" id="sex_customer"
                    initialValue={0}
                  >
                    <Select

                      placeholder={"Giơi tính"}
                      style={{ width: "100%", }}
                      options={this.state.sex_}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"customerNew"}
                    style={{
                      width: '100%',
                      marginBottom: 0
                    }}>
                    <Input.Group compact>
                      <Form.Item
                        name="name_customer" id="name_customer"
                        style={{
                          width: '40%',
                          marginBottom: 0
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Tên khách hàng là bắt buộc"
                          },
                        ]} >
                        <Input
                          style={{
                            width: '100%',
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px"
                          }}
                          name="name_customer" id="name_customer"
                          placeholder="Tên khách hàng"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: '30%', marginBottom: 0
                        }}
                        name="phone_number_customer"
                        rules={[
                          {
                            validator: (_, value) =>
                            {
                              const required = Validator.required(value, "Số điện thoại không được trống");
                              if (required) {
                                return Promise.reject(required);
                              }

                              const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                              if (min) {
                                return Promise.reject(min);
                              }
                              const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                              if (max) {
                                return Promise.reject(max);
                              }
                              const isPhone = Validator.validatePhoneNumber(value);
                              if (isPhone) {
                                return Promise.reject(isPhone);
                              }
                              return Promise.resolve();

                            }
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: '100%',
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px"
                          }}
                          name="phone_number_customer" id="phone_number_customer"
                          placeholder="Số điện thoại"
                        />
                      </Form.Item>
                      <Form.Item
                        name="birthday"
                        style={{
                          width: '30%', marginBottom: 0
                        }}
                        initialValue={defaultTime}
                      >
                        <DatePicker name="birthday" id="birthday"
                          style={{
                            width: '100%',
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px"
                          }}
                          placeholder="Ngày sinh" />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    name="address_customer"
                    style={{
                      width: '100%', marginBottom: 0
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Địa chỉ khách hàng là bắt buộc"
                      },
                    ]}
                  >
                    <Input name="address_customer" id="address_customer" placeholder="address"

                      style={{
                        width: '100%',
                      }}

                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: '100%',
                      marginBottom: "0px",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                    name="province_customer"
                  >
                    <Input.Group compact style={{
                      width: '100%',
                    }}>
                      <Form.Item name="province_customer"
                        style={{
                          width: "40%", marginBottom: "0px", borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px"
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Tỉnh thành là bắt buộc"
                          },
                        ]}  >
                        <Select
                          showSearch
                          placeholder={"Tỉnh/Thành"}
                          style={{
                            width: "100%",
                          }}
                          defaultActiveFirstOption={true}
                          showArrow={true}
                          onChange={this.handleChangeProvince}
                          notFoundContent={null}
                          filterOption={(input, option) =>
                          {

                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }}
                          options={(this.state.ListProvinces || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}

                        />
                      </Form.Item>
                      <Form.Item name="district_customer"
                        style={{
                          width: "30%", marginBottom: "0px", borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px"
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Quận huyện là bắt buộc"
                          },
                        ]} >
                        <Select
                          name="district_customer"
                          showSearch
                          placeholder={"Quận/Huyện"}
                          style={{
                            width: "100%",
                          }}
                          defaultActiveFirstOption={true}
                          showArrow={true}
                          onChange={this.handleChangeDistrict}
                          notFoundContent={null}
                          filterOption={(input, option) =>
                          {

                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }}
                          options={(this.state.ListDistricts || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}

                        />
                      </Form.Item>
                      <Form.Item name="war_customer"
                        style={{
                          width: "30%", marginBottom: "0px", borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px"
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Phường xã là bắt buộc"
                          },
                        ]} >
                        <Select
                          name="war_customer"
                          showSearch
                          placeholder={"Phường/xã"}
                          style={{
                            width: "100%"
                          }}
                          defaultActiveFirstOption={true}
                          showArrow={true}
                          onChange={this.handleChangeWar}
                          notFoundContent={null}
                          filterOption={(input, option) =>
                          {

                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }}
                          options={(this.state.ListWars || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}

                        />
                      </Form.Item>

                    </Input.Group>
                  </Form.Item>
                  <Form.Item noStyle name={"note_customer"} >

                    <TextArea placeholder="ghi chú" style={{ minHeight: "80px" }}
                      name={"note_customer"} id="note_customer"
                    />
                  </Form.Item>
                </div>
                }
                {this.state.customer_chose && <div className="customer_chose_box" style={{ display: "flex", justifyContent: "", marginTop: "10px", position: "relative" }}>
                  <div className="btn_delele_customer_chose" style={{
                    border: "1px solid ",
                    borderColor: Colors.colorpink,
                    backgroundColor: Colors.colorpink,
                    borderRadius: "50%",
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    width: "20px",
                    height: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "100"

                  }}><Tooltip title="Xóa Khách hàng" ><button className="reset_btn scale_btn" style={{ color: Colors.white, display: "flex" }} onClick={() => this.deleteCustomerChose()}><DeleteOutlined /></button></Tooltip></div>
                  <Alert message={
                    <>
                      <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontWeight: "bold" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={
                              <Image
                                src={LINK.myHostimg + "/logorim.png"}
                                style={{
                                  width: 32,
                                }}
                              />
                            }
                          />
                          <div>
                            <div style={{ marginLeft: "10px", fontSize: "12px" }}>{this.state.customer_chose.name}</div>
                            <div style={{ marginLeft: "10px", color: Colors.colorAdd, fontSize: "12px" }}>{this.state.customer_chose.phone}</div>
                          </div>
                        </div>
                        <div>
                          <Tag color={Colors.coloryellow} style={{ cursor: "pointer", fontSize: "12px" }} >
                            Mobifone
                          </Tag>
                        </div>
                      </div>


                      <Divider dashed={true} style={{ height: "5px", borderColor: Colors.colorblue }} />
                      <div style={{ marginBottom: "10px", display: "flex", width: "100%", justifyContent: "space-between", fontSize: "12px", fontWeight: "bold" }}>
                        <div style={{ display: "flex", width: "50%" }}>
                          <div><span>Tổng:</span><span style={{ color: Colors.color3 }}> 373.000 ₫</span></div>
                        </div>
                        <div style={{ display: "flex", width: "50%", flexDirection: "column" }}>

                          <div> <span>Thành công:</span> <span style={{ color: Colors.colorAdd }}> 1/2 đơn</span></div>
                          <div> <span>Lần mua cuối:</span><span style={{ color: Colors.color5 }}> 22:56 hôm qua</span></div>
                          <div><span>AD_ID:</span> <span style={{ color: Colors.colorEdit }}>{this.state.customer_chose.code}</span></div>
                        </div>
                      </div>


                    </>
                  }
                    style={{
                      width: "100%"
                    }}
                    type="info"
                  />

                </div>
                }

              </Card>
              {this.state.typeCus === 1 && <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EnvironmentOutlined />
                  <span style={{ marginLeft: "10px" }}>Nhận hàng</span>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button type="primary" onClick={() =>
                  {
                    this.copyCusInfoToReceviceInfo();
                    // console.log('====================================');
                    // console.log("okokookoko");
                    // console.log('====================================');
                    // this.setState(state =>
                    // {
                    //   return {
                    //     typeCus: 1
                    //   }
                    // }, () =>
                    // {
                    //   this.setState(state =>
                    //   {
                    //     return {
                    //       typeCus: 0
                    //     }
                    //   })
                    // })
                  }}>
                    <VerticalAlignBottomOutlined />
                  </Button>
                </div>
              </div>}


                bordered={true}
                style={{ width: "100%", marginTop: "10px" }}
                bodyStyle={{
                  padding: "10px 10px"
                }}
                headStyle={{
                  backgroundColor: Colors.colorgrey,
                  display: "flex",
                  minHeight: "30px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                  <span>Dự kiến nhận hàng</span>
                  <Form.Item noStyle name="expected_date" initialValue={defaultTwoday}>
                    <DatePicker style={{
                      width: '50%',
                    }}

                      placeholder="Chọn ngày" name="expected_date" id="expected_date"
                    />
                  </Form.Item>
                </div>
                <div style={{ display: "flex", justifyContent: "", marginTop: "10px" }}>
                  <Form.Item name="" style={{
                    width: '100%',
                    marginBottom: "0px"
                  }}>
                    <Input.Group compact style={{
                      width: '100%',
                    }}>
                      <Form.Item
                        style={{
                          width: '50%',
                          marginBottom: "0"
                        }}
                        name="fullname"

                        rules={[
                          {
                            required: true,
                            message: "Tên người nhận là bắt buộc"
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: '100%',
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}

                          placeholder="Tên khách hàng"
                          name="fullname" id="fullname"
                        />
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: '50%',
                          marginBottom: "0"
                        }}
                        name="phone_number"
                        rules={[
                          {
                            validator: (_, value) =>
                            {
                              const required = Validator.required(value, "Số điện thoại không được trống");
                              if (required) {
                                return Promise.reject(required);
                              }

                              const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                              if (min) {
                                return Promise.reject(min);
                              }
                              const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                              if (max) {
                                return Promise.reject(max);
                              }
                              const isPhone = Validator.validatePhoneNumber(value);
                              if (isPhone) {
                                return Promise.reject(isPhone);
                              }
                              return Promise.resolve();

                            }
                          },
                        ]}
                      >
                        <Input
                          style={{
                            width: '100%',
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          }}

                          placeholder="Số điện thoại"
                          name="phone_number" id="phone_number"
                        />
                      </Form.Item>

                    </Input.Group>
                  </Form.Item>
                </div>
                <div style={{ display: "flex", justifyContent: "", marginTop: "10px" }}>
                  <Form.Item
                    style={{
                      width: '100%',
                      marginBottom: "0"
                    }}
                    name="address"

                    rules={[
                      {
                        required: true,
                        message: "Địa chỉ nhận là bắt buộc"
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: '100%',
                      }}

                      placeholder="Địa chỉ"
                      name="address" id="address"
                    />
                  </Form.Item>

                </div>

                <div style={{ display: "flex", justifyContent: "", marginTop: "10px" }}>
                  <Form.Item
                    style={{
                      width: '100%',
                      marginBottom: "0px"
                    }}

                  >
                    <Input.Group compact style={{
                      width: '100%',
                    }}
                    >
                      <Form.Item
                        name={"province_id"}
                        style={{
                          width: "33%",
                          marginBottom: "0px"
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Tỉnh thành là bắt buộc"
                          },
                        ]}
                        initialValue={this.state.Province_id_}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Tỉnh"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          onChange={this.handleChangeProvince_}
                          options={(this.state.ListProvinces_ || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        name={"district_id"}
                        style={{
                          width: "33%", marginBottom: "0px"
                        }} rules={[
                          {
                            required: true,
                            message: "Quận huyện là bắt buộc"
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Quận"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          onChange={this.handleChangeDistrict_}
                          options={(this.state.ListDistricts_ || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        name={"war_id"}
                        style={{
                          width: "33%", marginBottom: "0px"
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Phường xã là bắt buộc"
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Phường xã"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          onChange={this.handleChangeWar_}
                          options={(this.state.ListWars_ || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </div>
              </Card>
              }
              {this.state.typeCus === 1 && <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                  <InboxOutlined />
                  <span style={{ marginLeft: "10px" }}>Vận chuyển</span>
                </div>
                <div style={{
                  width: "40%",
                }}>
                  <Form.Item
                    name={"info_transport_id"}
                    style={{
                      width: "100%", marginBottom: "0px"
                    }}

                  >
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Chọn đơn vị VC"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      size="small"
                      id="info_transport_id"
                      name="info_transport_id"
                      value={this.state.record?.info_transport_id}
                      onChange={(e) => { this.onChangetext(e, "info_transport_id", "record") }}
                      options={(this.state.ListInfoTransport || []).map((d) => ({
                        value: d.id,
                        label: d.name,
                      }))}
                    />
                  </Form.Item>
                </div>
              </div>} bordered={true}
                style={{ width: "100%", marginTop: "10px" }}
                bodyStyle={{
                  padding: "10px 10px"
                }}
                headStyle={{
                  backgroundColor: Colors.colorgrey,
                  display: "flex",
                  minHeight: "30px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "", marginTop: "10px" }}>
                  <Form.Item noStyle>
                    <Input.Group compact>
                      <Form.Item noStyle name={"lading_code"} >
                        <Input
                          style={{
                            width: '50%',
                          }}
                          name="lading_code"
                          placeholder="Mã vận đơn"
                        />
                      </Form.Item>
                      <Form.Item noStyle name={"fee_ship_transport"} >
                        <Input
                          style={{
                            width: '50%',
                          }}
                          name="fee_ship_transport"
                          placeholder="Phí"
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </div>
              </Card>
              }
              {this.state.typeCus === 1 && <Collapse
                defaultActiveKey={['1']} style={{ width: "100%", marginTop: "10px" }}

              >
                <Panel
                  className="panel_bottom"
                  header="So sánh phí vận chuyển"
                  key="1"

                >
                  {this.state.ListInfoTransport && this.state.ListInfoTransport.map((item, index) =>
                  {
                    return <div key={index} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <span style={{
                          color: Colors.colorView,
                          fontWeight: "bold",
                          display: "flex",
                          width: "20%",
                          marginRight: "35px"
                        }}>{item.name}</span>

                        {item.description}đ
                      </div>
                      <div>
                        {formatMN1(item.price)}đ

                      </div>
                    </div>
                  })}

                </Panel>

              </Collapse>
              }


            </div>
            <div className="box_order_status" style={{ position: this.props.isView ? "absolute" : "sticky" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontWeight: "bold" }}>Cần thanh toán: {formatMN1(this.state.renderPrices.remaining)} ₫</div>
                <div style={{ fontWeight: "bold" }}>COD: <span style={{ color: Colors.pop2[3] }}>{formatMN1(this.state.pay?.fee_ship)} ₫</span></div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {this.props.handleCancelDetail && <div>
                  <Button loading={this.state.isloading_} size="small" type="primary" style={{ display: "flex", alignItems: "center", backgroundColor: Colors.pop2[3], border: "none", marginLeft: "10px" }} onClick={this.props.handleCancelDetail}>

                    Hủy
                  </Button>
                </div>}
                <div>
                  <Popover placement="top" content={<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                    {/* <button className="reset_btn" type="button" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                      onClick={() =>
                      {
                        this.update_(1);
                      }}
                    >
                      <PrinterOutlined /> <span style={{ marginLeft: "10px" }}>Xác nhận đơn hàng</span>
                    </button>
                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}>
                      <GiftOutlined /><span style={{ marginLeft: "10px" }}>Đang đóng hàng</span>
                    </button> */}
                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }} onClick={() => { this.setvisiblePdf(true, 1) }}>
                      <CheckOutlined /> <span style={{ marginLeft: "10px" }}>Bill</span>
                    </button>
                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}>
                      <CarOutlined /><span style={{ marginLeft: "10px" }}>Gửi phiếu vận chuyển</span>
                    </button>
                  </div>} title="In">

                    <Button loading={this.state.isloading_} size="small" type="primary" style={{ display: "flex", alignItems: "center", backgroundColor: Colors.pop2[3], border: "none", marginLeft: "10px" }}>
                      <PrinterOutlined />
                      In
                    </Button>
                  </Popover>
                </div>
                <div>
                  <Popover placement="top" content={<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>

                    {this.state.arrSaveBill && this.state.arrSaveBill.map((item, index) =>
                    {
                      return <><button className="reset_btn " type="button" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer", color: this.state.record.status === 1 ? Colors.pop3[2] : "", }}
                        onClick={() =>
                        {
                          this.update_(item.id);

                        }}
                      >
                        <SafetyOutlined /> <span style={{ marginLeft: "10px" }}>{item.name}</span>
                      </button>
                        <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                      </>
                    })}
                    {/* <button className="reset_btn " type="button" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer",color:this.state.record.status === 1?Colors.pop3[2]:"",}}
                      onClick={() =>
                      {
                        this.update_(1);

                      }}


                    >
                      <SafetyOutlined /> <span style={{ marginLeft: "10px" }}>Xác nhận đơn hàng</span>
                    </button>
                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer",color:this.state.record.status === 2?Colors.pop3[2]:"", }} onClick={() =>
                    {
                      this.update_(2);

                    }}>
                      <GiftOutlined /> <span style={{ marginLeft: "10px" }}>Đang đóng hàng</span>
                    </button>
                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" ,color:this.state.record.status === 3?Colors.pop3[2]:"",}}
                    onClick={() =>
                    {
                      this.update_(3);

                    }}
                    >
                      <CheckOutlined /> <span style={{ marginLeft: "10px" }}>Chờ chuyển hàng</span>
                    </button>
                    <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                    <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer",color:this.state.record.status === 4?Colors.pop3[2]:"", }}
                      onClick={() =>
                      {
                        this.update_(4);

                      }}
                    >
                      <CarOutlined /> <span style={{ marginLeft: "10px" }}>Gửi hàng di</span>
                    </button> */}

                  </div>} title="Lưu" >

                    <Button loading={this.state.isloading} size="small" type="primary" style={{
                      display: "flex", alignItems: "center", backgroundColor: Colors.pop2[2], border: "none", marginLeft: "10px"
                    }} htmlType="button">
                      <SaveOutlined />
                      Lưu
                    </Button>
                  </Popover>

                </div>

              </div>
            </div>

          </div >
        </Form>
      </>
    );
  }

}

const mapStateToProps = (state) =>
{
  return {

    Web: state.Web
  };
};
const mapDispatchToProps = (dispatch, Props) =>
{
  return {

    ResetOrderById: () =>
    {
      dispatch(ActionsWeb.ResetOrderById());
    },
    OrderByIdRequest: (data) =>
    {
      dispatch(ActionsWeb.OrderByIdRequest(data));
    },
    ListOrdersRequest: (data) =>
    {
      dispatch(ActionsWeb.ListOrdersRequest(data));
    },
    ListProductsRequest: (data) =>
    {
      dispatch(ActionsWeb.ListProductsRequest(data));
    },
    ListInfoTransportRequest: (body) =>
    {
      dispatch(ActionsWeb.ListInfoTransportRequest(body));
    },
    ListWarsRequest: (body) =>
    {
      dispatch(ActionsWeb.ListWarsRequest(body));
    },
    ListDistrictsRequest: (body) =>
    {
      dispatch(ActionsWeb.ListDistrictsRequest(body));
    },
    ListProvincesRequest: (body) =>
    {
      dispatch(ActionsWeb.ListProvincesRequest(body));
    },
    ListUsersRequest: (body) =>
    {
      dispatch(ActionsWeb.ListUsersRequest(body));
    },
    ListCustomersRequest: (body) =>
    {
      dispatch(ActionsWeb.ListCustomersRequest(body));
    },

    StoreOrdersRequest: (body, id) =>
    {
      dispatch(ActionsWeb.StoreOrdersRequest(body, id));
    },
    ResetStoreOrders: (body) =>
    {
      dispatch(ActionsWeb.ResetStoreOrders(body));
    },
    SearchProductsRequest: (body, data) =>
    {
      dispatch(ActionsWeb.SearchProductsRequest(body, data));
    },
    ListStoreRequest: (body) =>
    {
      dispatch(ActionsWeb.ListStoreRequest(body));
    },
    CheckVouchersRequest: (body) =>
    {
      dispatch(ActionsWeb.CheckVouchersRequest(body));
    },
    ResetCheckVouchersRequest: (body) =>
    {
      dispatch(ActionsWeb.ResetCheckVouchersRequest(body));
    },
    SettingsShowByCodeRequest: (body) =>
    {
      dispatch(ActionsWeb.SettingsShowByCodeRequest(body));
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
