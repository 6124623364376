import React, { Component, createRef, useEffect, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined, MinusCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, SaveOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, createSlug, formatMN1, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
const Index = ({ settings ,ListPriceOnAnountStamps}) => {


    return (
        <div className="" id="price-table">
        <div className="row" style={{ backgroundColor:"#051e3c" }}>
            <div className="col-3 d-flex align-items-center">
                
                    <img src={`${LINK.myHostimgServerImg}/${settings?.icon[0]}`} className="logo" style={{ width:"100px" }}/>
            </div>
            <div className="col-9 p-3">
                <div className="d-flex align-items-center text-white justify-content-center"><h1 className="text-center text-white ">{settings?.title}</h1> </div>
                <div className="d-flex align-items-center text-white"><i class="fas fa-map-marker-alt"></i><p className="ml-2 mt-1 font-size-12"> {settings?.address[0]}</p></div>
                <div className="d-flex align-items-center text-white"> <i class="fas fa-phone-volume"></i> <p className="ml-2 mt-1 font-size-12"> {settings?.phone[0]}</p></div>
                <div className="d-flex align-items-center text-white"><i class="fas fa-envelope-open"></i><p className="ml-2 mt-1 font-size-12">  {settings?.email[0]}</p></div>
                <div className="d-flex align-items-center text-white"><i class="fab fa-facebook-square"></i><p className="ml-2 mt-1 font-size-12">  {settings?.email[0]}</p></div>

            </div>
        </div>
        <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center bg-primary">
                
                    <h5 className="text-white">Bảng giá tem thông dụng</h5>
            </div>
            <div className="col-12 p-0">

                {ListPriceOnAnountStamps && ListPriceOnAnountStamps[0] && ListPriceOnAnountStamps[0].array_discount && 
                    <div className="bg-info p-1" style={{ display: "grid", gridTemplateColumns: `repeat(${ListPriceOnAnountStamps[0].array_discount.length + 1}, 1fr)`}}>
                        <div className="font-size-14"><strong> Kích thước / SL</strong></div>
                        {ListPriceOnAnountStamps[0].array_discount.map((value, index) => {
                            return  <div className="d-flex flex-column justify-content-center align-items-center font-size-14"><strong>  {value.amount_stamp}</strong></div>
                        })}
                       
                    </div>

                }
              {ListPriceOnAnountStamps && ListPriceOnAnountStamps.map((item, index) => {
                 let bg="#f1f1f1";
                 if(index%2==0){
                    bg="#f1f1f1";
                 }else{
                    bg="#fff";
                 }
                return <div className="p-1" style={{ backgroundColor:bg, display: "grid", gridTemplateColumns: `repeat(${item?.array_discount.length + 1}, 1fr)`}}>
                       <div className="font-size-12">{item.code}</div>
                         {item.array_discount.map((value, index) => {
                            return  <div className="d-flex flex-column justify-content-center align-items-center font-size-12"> {formatMN1(Math.round(value.priceOnAmountStampDiscount))}</div>
                        })}
                    </div>

              })}
            </div>

            <div className="col-12 d-flex align-items-center justify-content-center">
                <p className="text-center text-danger"><i>Chúng tôi biết bạn có nhiều sự lựa chọn, xin cám ơn vì đã chọn chúng tôi</i> <i class="far fa-smile-beam text-info"></i></p>
            </div>
        </div>
    </div>
    );
}
export default Index;