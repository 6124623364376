import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";

import Upload from "./Upload";

import { replaceThous, getCookie, createSlug,copyToClipboard } from "./../../../../service";
import {
Form,
Input,
Button,
Select,
Switch as SwitchA,
message, Divider, Space,
Card, DatePicker, TreeSelect, Typography
} from 'antd';
import {
FileImageOutlined,
UserSwitchOutlined, PlusOutlined, CopyOutlined, ArrowUpOutlined
} from '@ant-design/icons';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";


import Editor from "./../../../../components/editor/index";
import { domainWeb } from "../../../../constants/LinkApi";
const { TextArea } = Input;

const { Option } = Select;


const defaultTime = moment().subtract(15, 'years');
class Index extends Component {

    constructor(props) {
        super(props);

        this.formRef = new createRef(null);
        this.editor = createRef();
        this.state = {
            meta: {
                ...META
            },
            record: {

                birthday: defaultTime,
                deleted: 0,
                hidden: 0
            },
            StorePosts: {},
            ListPosts: [],
            data: [],
            isloading: false,
            required: {
                avata: false
            },
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
            imgesInfoRemove: [],
            isupload: 0,
            imgesInfores: {
                avata: [],
                detail: [],
                video: []
            },
            addTags: "",
        }


    }

    addItem = (e, type, parent_id) => {

        e.preventDefault();

        const formvalues = this.formRef.current.getFieldsValue();
        console.log(`formvalues`, formvalues);
        if (type === "tags" && formvalues[type]) {
            this.props.StoreTagsRequest({
                "name": formvalues[type],
                "code": "",
                "deleted": "0",
                "hidden": "0",

            })
        }



    }
    setimgesInfo = (type, data) => {


        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state => {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],
                    // imgesInfo: {
                    //     ...state.imgesInfo, [type]: data
                    // },

                }
            }, () => {
                this.setState(state => {

                    return {
                        // imgesInfoRemove: [...state.imgesInfoRemove, ...state.imgesInfo[type]],
                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })


            // imageDelete["folder"] = type;

        } else {
            this.setState(state => {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) => {
        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item => {
                return item !== data.name;
            })
            imagesrecord = this.state.record.images.filter(item => {
                return item !== data.name;
            })
            this.setState(state => {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state => {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }
    static getDerivedStateFromProps(nextProps, prevState) {


        if (nextProps.Web.StorePosts && nextProps.Web.StorePosts !== prevState.StorePosts) {
            return {
                StorePosts: nextProps.Web.StorePosts,

            };

        }
        if (nextProps.Web.StoreTags && nextProps.Web.StoreTags !== prevState.StoreTags) {
            return {
                StoreTags: nextProps.Web.StoreTags,

            };

        }
        if (nextProps.Web.ListPosts && nextProps.Web.ListPosts.id !== prevState.record.id) {
          
                console.log(nextProps.Web.ListPosts);
                
            return {
                record: nextProps.Web.ListPosts,

            };

        }
        if (nextProps.Web.ListCategory && nextProps.Web.ListCategory.data !== prevState.ListCategory) {

            return {
                ListCategory: nextProps.Web.ListCategory.data,

            };

        }
        if (nextProps.Web.ListMenuHome && nextProps.Web.ListMenuHome.data !== prevState.ListMenuHome) {
            console.log(nextProps.Web.ListMenuHome);
            return {
                ListMenuHome: nextProps.Web.ListMenuHome.data,

            };

        }
        if (nextProps.Web.ListTags && nextProps.Web.ListTags.data !== prevState.ListTags) {

            return {
                ListTags: nextProps.Web.ListTags.data,

            };

        }
        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState) {


        if (this.state.StorePosts !== prevState.StorePosts) {
            let { StorePosts } = this.state;
            try {
                if (StorePosts.code === 200) {
                    if (StorePosts.message && typeof StorePosts.message === "string") {
                        message.success(StorePosts.message)
                        this.setState(state => {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StorePosts.message && typeof StorePosts.message === "string") {

                        message.error(StorePosts.message)
                    } else {
                        for (let x in StorePosts.message) {
                            let mess = StorePosts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStorePosts()
            this.SetisLoading(false)
        }
        if (this.state.record.id !== prevState.record.id) {
            const { record } = this.state;
            let avata = [{
                uid: -1,
                name: record.thumbnail,
                status: 'done',
                url: LINK.myHostimgServerImg + "/" + record.thumbnail,
            }];
            // let video = record.video[0] ? [{
            //     uid: -1,
            //     name: record.video[0],
            //     status: 'done',
            //     url: LINK.myHostimgServerVide + "/" + record.video[0],
            // }] : [];
            // let detail = [];
            // record.images.map((item, index) =>
            // {
            //     detail.push({
            //         uid: -1 * (index + 1),
            //         name: item,
            //         status: 'done',
            //         url: LINK.myHostimgServerImg + "/" + item,
            //     })
            // });
            this.setState(state => {
                return {

                    imgesInfo: { avata: avata, detail: [], video: [] },

                }
            })
            this.editor.current.value = record.content
            this.formRef.current.setFieldsValue({ ...record });

        }
        if (this.state.StoreTags !== prevState.StoreTags) {
            let { StoreTags } = this.state;
            try {
                if (StoreTags.code === 200) {
                    if (StoreTags.message && typeof StoreTags.message === "string") {
                        message.success(StoreTags.message)
                        this.props.ListTagsRequest({
                            limit: this.state.meta.per_page * 1000,
                            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreTags.message && typeof StoreTags.message === "string") {

                        message.error(StoreTags.message)
                    } else {
                        for (let x in StoreTags.message) {
                            let mess = StoreTags.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }


        }

        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;

            if (parseInt(UploadFileLa.type) === 1) {
                if (UploadFileLa.code !== 200) {

                    message.error("Cập nhật ảnh chính thất bại!");
                    // return;
                }

                this.setState(state => {
                    return {
                        record: { ...state.record, thumbnail: UploadFileLa.data }
                    }
                }, () => {
                    if (this.state.isupload === 1) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }

            this.props.ResetUploadFileLa();
        }
    }
    componentDidMount = () => {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.PostByIdRequest({ id });
        }



        const filterDataCate = {
            data: {
                name: "",
                slug: "",
                ischild: "",
                isparent: "",
                type: "",
                position: "",
                created_at: "",
                istree: "1"
            }
        }
        this.props.SearchMenuHomeRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, filterDataCate)
        this.props.ListTagsRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        })


    }
    data = () => {
        let { ListRegion } = this.state;
        // if (!ListRegion) {
        //     ListRegion = this.props.Web.ListRegion
        // }

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) => {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state => {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) => {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    CheckDataRequired = () => {
        const { imgesInfo, record, dataClassify, classify1, classify2, required } = this.state;

        if (imgesInfo.avata.length <= 0) {
            message.warn("Hình ảnh chính là bắt buộc");
            this.setState(state => {
                return {
                    required: { ...state.required, avata: true }
                }
            })

            return;
        }
        if (!this.editor.current.value || this.editor.current.value === '') {
            message.warn("Nội dung là bắt buộc");
            this.setState(state => {
                return {
                    required: { ...state.required, content: true }
                }
            })

            return;
        }

        return true;

    }
    uploadFile = (values) => {
        const { imgesInfo, } = this.state;


        this.setState(state => {
            return {
                required: {},
                values: values
            }
        })
        if (!this.CheckDataRequired()) {
            return;
        }

        this.SetisLoading(true)
        let isupload = 0;
        // this.SetisLoading(true)
        if (imgesInfo.avata.length > 0) {
            let arravata = imgesInfo.avata.filter(item => {
                return item.originFileObj;
            })
            if (arravata.length > 0) {
                this.props.UploadFileLaRequest(arravata, "img", 1);
                isupload = 1;
            }

        }


        this.setState(state => {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    onFinishForm = (values) => {
        const { record } = this.state;
        const record_ = { ...record, ...values, deleted: 0 }
        let user = getCookie("user");
        user = JSON.parse(user);
        record_.user_id = user.id;
        record_.content = this.editor.current.value;
        record_.imgesInfoRemove = this.state.imgesInfoRemove.filter(item => {
            return !item.originFileObj
        });
        if (typeof record_.thumbnail === "string") {
            record_.thumbnail = [record_.thumbnail]
        }
     
        // return;
        this.props.StorePostsRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }

    handleChangeProvince = (e) => {
        let province = this.state.ListProvinces.filter(item => {
            return item.id === (1 * e);
        })
        this.setState(state => {
            return {
                Province_id: e,
                record: { ...state.record, province: province?.[0]?.name }
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });
    }

    handleChangeDistrict = (e) => {
        let district = this.state.ListDistricts.filter(item => {
            return item.id === (1 * e);
        })
        this.setState(state => {
            return {
                District_id: e,
                record: { ...state.record, district: district?.[0]?.name }
            }
        })
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id, districtid: e
        });
    }
    handleChangeWar = (e) => {
        let war = this.state.ListWars.filter(item => {
            return item.id === (1 * e);
        })
        this.setState(state => {
            return {
                War_id: e,
                record: { ...state.record, war: war?.[0]?.name }
            }
        })

    }
    render() {
        let { transposts, active_tran } = this.state;
       


        return (

            <div className="table_w post_edit" >

                <Card title={<div style={{ display: "flex",justifyContent:"space-between",alignItems: "center" }}>
                    <div style={{ display: "flex",alignItems: "center" }}>
                        <UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Bài viết</span>
                    </div>

                    <div  style={{ display: "flex",alignItems: "center",width:"50%" }}>
                         <Input size="small" addonAfter={
                        <div  style={{ display: "flex",alignItems: "center" }}>
                            <button className="reset_btn btn-hover-text" type="button"  size="small" style={{ width:"40px"}} onClick={()=>copyToClipboard(this.state.record?.url ? this.state.record?.url : "")}>
                                <CopyOutlined />
                            </button>
                            <button className="reset_btn btn-hover-text" type="button" size="small">
                                <a href={this.state.record?.url ? this.state.record?.url : ""} target="_blank">
                                <ArrowUpOutlined />
                                </a>
                            </button>
                        </div>

                    } value={this.state.record?.url ? this.state.record?.url : ""} style={{ width:"100%" }}  disabled/></div>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                        // maxHeight: "90vh",
                        // overflowY: "scroll"
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="vertical"
                        name="formadd" onFinish={this.uploadFile} ref={this.formRef}
                    >

                        <div className=" section_img">



                            <Form.Item label="Tiêu đề" name="title" rules={[
                                {
                                    required: true,
                                    message: "Tiêu đề là bắt buộc"
                                },
                            ]} onChange={(e) => {
                                this.formRef.current.setFieldsValue({ slug: createSlug(e.target.value) })
                                this.formRef.current.setFieldsValue({ seo_title: e.target.value })
                                this.formRef.current.setFieldsValue({ seo_alias:  domainWeb+"blog-detail/"+createSlug(e.target.value) })
                            }} >
                                <Input placeholder="Tiêu đề" />
                            </Form.Item>

                            <Form.Item label="Slug" name="slug">
                                <Input placeholder="Slug" />
                            </Form.Item>
                            <Form.Item label="Mô tả" name="description" rules={[
                                {
                                    required: true,
                                    message: "Mô tả là bắt buộc"
                                },
                            ]}>
                                <TextArea placeholder="Mô tả" style={{ height: "100px" }}   onChange={(e) =>
                                                        {
                                                            
                                                            this.formRef.current.setFieldsValue({ seo_description: e.target.value })
                                                        }}/>
                            </Form.Item>

                            <Form.Item label="Danh mục" name={"category_id"}

                                rules={[
                                    {
                                        required: true,
                                        message: "Danh mục là bắt buộc"
                                    },
                                ]}>
                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%' }}
                                    treeData={this.state.ListMenuHome}

                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Danh mục"
                                    // allowClear
                                    multiple
                                    treeDefaultExpandAll


                                />

                            </Form.Item>
                            <Form.Item label="Tags" name="tags_id" rules={[
                                {
                                    required: true,
                                    message: "Tag là bắt buộc"
                                },
                            ]} >
                                <Select
                                    mode="multiple"
                                    maxTagCount='responsive'
                                    style={{ width: '100%', }}

                                    placeholder="Tags"
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                align="center"
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <Form.Item name={`tags`} >
                                                    <Input placeholder="Please enter item"
                                                    //  value={this.state.addAttributeProducts}

                                                    // onChange={(event) => this.setName(event, "addAttributeProducts", item.id)}
                                                    />
                                                </Form.Item>
                                                <Typography.Link
                                                    onClick={(event) => this.addItem(event, "tags")}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    <PlusOutlined /> Add item
                                                </Typography.Link>
                                            </Space>
                                        </>
                                    )}

                                    options={(this.state.ListTags || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}


                                />

                            </Form.Item>
                            <Form.Item label="Ảnh bìa">
                                <Upload isCropImg={false} multiple={false} max={1} type_={"avata"} focus={this.state.required.avata} type_f={1} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} fileList={this.state.imgesInfo.avata} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} />
                            </Form.Item>
                            <Form.Item label="Mô tả chi tiết" >
                                        <Editor editor={this.editor} />

                            </Form.Item>
                            <Form.Item label="Seo title" name="seo_title" rules={[
                                {
                                    required: true,
                                    message: "Seo title là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Seo title" />
                            </Form.Item>
                            <Form.Item label="Seo Alias" name="seo_alias" rules={[
                                {
                                    required: true,
                                    message: "Seo Alias là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Seo Alias" />
                            </Form.Item>
                            <Form.Item label="Seo Description" name="seo_description" rules={[
                                {
                                    required: true,
                                    message: "Seo Description là bắt buộc"
                                },
                            ]} >
                                <TextArea style={{ height: "100px" }} placeholder="Seo Description" />
                            </Form.Item>

                        </div>

                        <Form.Item >
                            <div className="flex_">
                                <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {

        StorePostsRequest: (data, id) => {
            dispatch(ActionsWeb.StorePostsRequest(data, id));
        },
        ResetStorePosts: () => {
            dispatch(ActionsWeb.ResetStorePosts());
        },
        ListPostsRequest: (body) => {
            dispatch(ActionsWeb.ListPostsRequest(body));
        },

        PostByIdRequest: (body) => {
            dispatch(ActionsWeb.PostByIdRequest(body));
        },
        StoreTagsRequest: (body) => {
            dispatch(ActionsWeb.StoreTagsRequest(body));
        },
        ResetStoreTags: () => {
            dispatch(ActionsWeb.ResetStoreTags());
        },
        ListTagsRequest: (body) => {
            dispatch(ActionsWeb.ListTagsRequest(body));
        },
        ////////////////
        ListCategoryRequest: (body) => {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
        SearchMenuHomeRequest: (body, data) => {
            dispatch(ActionsWeb.SearchMenuHomeRequest(body, data));
        },
        UploadFileLaRequest: (body, folder, type) => {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () => {
            dispatch(ActionsWeb.ResetUploadFileLa());
        },
        SearchCategoryRequest: (body, data) => {
            dispatch(ActionsWeb.SearchCategoryRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);