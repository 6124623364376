import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1 } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message

} from 'antd';
const { TextArea } = Input;
// import
// {
//     FileImageOutlined,
//     EditOutlined,
//     VideoCameraOutlined,
//     PlusOutlined,
//     PlusCircleOutlined,
//     DragOutlined,
//     DeleteOutlined,
// } from '@ant-design/icons';
const { Option } = Select;


class Index extends Component
{

    constructor(props)
    {
        super(props);


        this.state = {
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                name: "",

                code: "",
                description: "",
                deleted: 0
            },
            StoreOrganizationProduction: {},
            ListOrganizationProduction: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {
            return {
                record: nextProps.history.location.state,

            };

        } else if (nextProps.Web.ListOrganizationProduction && nextProps.Web.ListOrganizationProduction !== prevState.ListOrganizationProduction) {
            return {

                ListOrganizationProduction: nextProps.Web.ListOrganizationProduction,

            };

        } else if (nextProps.Web.StoreOrganizationProduction && nextProps.Web.StoreOrganizationProduction !== prevState.StoreOrganizationProduction) {
            return {
                StoreOrganizationProduction: nextProps.Web.StoreOrganizationProduction,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreOrganizationProduction !== prevState.StoreOrganizationProduction) {
            let { StoreOrganizationProduction } = this.state;
            if (StoreOrganizationProduction.code === 200) {
                message.success(StoreOrganizationProduction.message)
            }
            else {
                if (StoreOrganizationProduction.message) {
                    message.error(StoreOrganizationProduction.message)
                }

            }
            this.props.ResetStoreOrganizationProduction()
            this.SetisLoading(false)
        }

        if (this.state.ListOrganizationProduction !== prevState.ListOrganizationProduction) {
            let { ListOrganizationProduction } = this.state

            this.data();
        }
    }
    componentDidMount = () =>
    {

        this.props.ListOrganizationProductionRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

        return;
        if (!this.props.Web.ListOrganizationProduction.data) {

            this.props.ListOrganizationProductionRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
        }
        else {
            this.setState(state =>
            {
                return {
                    ListOrganizationProduction: this.props.Web.ListOrganizationProduction
                }
            }, () => { this.data(); })

        }
    }
    data = () =>
    {
        let { ListOrganizationProduction } = this.state;
        // if (!ListOrganizationProduction) {
        //     ListOrganizationProduction = this.props.Web.ListOrganizationProduction
        // }

        if (ListOrganizationProduction.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListOrganizationProduction.current_page,
            first_page_url: ListOrganizationProduction.first_page_url,
            from: ListOrganizationProduction.from,
            last_page: ListOrganizationProduction.last_page,
            last_page_url: ListOrganizationProduction.last_page_url,
            links: ListOrganizationProduction.links,
            next_page_url: ListOrganizationProduction.next_page_url,
            path: ListOrganizationProduction.path,
            per_page: ListOrganizationProduction.per_page,
            prev_page_url: ListOrganizationProduction.prev_page_url,
            to: ListOrganizationProduction.to,
            total: ListOrganizationProduction.total,
        };
        ListOrganizationProduction.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    Store = () =>
    {
        let { record } = this.state;
        if (!record.name) {
            message.error("Vui lòng nhập tên");
            return;
        }

        this.SetisLoading(true)
        this.props.StoreOrganizationProductionRequest(record, record.id ? record.id : "")
    }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <h1>Chi tiết tổ chức sản xuất</h1>
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        // span: 14,
                    }}
                    layout="horizontal"
                >

                    <div className="section_ section_img">
                        <h3>Thông tin cơ bản</h3>

                        <Form.Item label="*Tên">
                            <Input name="name" id="name" placeholder="Tên" value={this.state.record.name} onChange={(e) => { this.onChangetext(e, "name", "record") }} showCount maxLength={120} />
                        </Form.Item>
                        <Form.Item label="*Code">
                            <Input name="code" id="code" placeholder="code" value={this.state.record.code} onChange={(e) => { this.onChangetext(e, "code", "record") }} showCount maxLength={120} />
                        </Form.Item>

                        <Form.Item label="*Mô tả">
                            <TextArea name="description" id="description" placeholder="Mô tả" value={this.state.record.description} onChange={(e) => { this.onChangetext(e, "description", "record") }} showCount maxLength={120} />
                        </Form.Item>
                        {/* <Form.Item label="Active">
                            <SwitchA name="active" id="active" value={this.state.record.active} checked={this.state.record.active ? true : false} onChange={(e) => { this.onChangetext(e, "active", "record") }} />
                        </Form.Item> */}

                    </div>

                    <Form.Item >

                        <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                        <Button type="primary" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={this.Store}>Cập nhật</Button>
                    </Form.Item>
                </Form >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreOrganizationProductionRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreOrganizationProductionRequest(data, id));
        },
        ResetStoreOrganizationProduction: () =>
        {
            dispatch(ActionsWeb.ResetStoreOrganizationProduction());
        },
        ListOrganizationProductionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListOrganizationProductionRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);