import React, { useEffect, useState } from 'react';

import * as LINK from "./../../constants/Links";
import { useDispatch, useSelector } from 'react-redux';
const Loader = ({ isLoading }) =>
{
  
    const [seting, setseting] = useState(null);
   useEffect(() => {
       let settings = localStorage.getItem("settings");
       if (settings) {
           settings = JSON.parse(settings);

           const set_ = settings.filter(item =>
           {
               return item.type === 2;
           })
           if (set_?.[0]?.data) {
               setseting(JSON.parse(set_[0].data))
           

           }
       }
    return () => {
        
    };
   }, []);
    if (!isLoading) return null;
    return (
        <div id="loader" className="d-flex justify-content-center align-items-center flex-column">
            <img src={seting?.logo ? `${LINK.myHostimgServerImg}/${seting?.logo?.value?.[0]}` : `${LINK.myHostimg}/logorim.png`} alt="loader" className="mb-5 App-logo" />
            <p>Loading...</p>
        </div>
    )
}

export default Loader
