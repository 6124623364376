import { ArrLink } from "./LinksArr";
export const myHost = ArrLink["myHost"];
export const myHostimg = ArrLink["myHostimg"];
export const myHostAnimates = ArrLink["myHostimg"];
export const myHostimgServerImg = ArrLink["myHostimgServerImg"];
export const myHostimgServerVide = ArrLink["myHostimgServerVide"];
export const myHostimgServerfiles = ArrLink["myHostimgServerfiles"];
export const myHostapp = ArrLink["myHostapp"];
export const endpoint = ArrLink["endpoint"];
export const HOME = ArrLink["HOME"];
export const LIVE_CHAT = ArrLink["LIVE_CHAT"];
export const SETTING = ArrLink["SETTING"];
export const SETTING_GEN = ArrLink["SETTING_GEN"];
export const SETTING_TAGS = ArrLink["SETTING_TAGS"];
export const SETTING_ADD_PAGE = ArrLink["SETTING_ADD_PAGE"];
export const SETTING_CHAT = ArrLink["SETTING_CHAT"];
export const PASS = ArrLink["PASS"];
export const PASS_ADDORDER = ArrLink["PASS_ADDORDER"];
export const PASS_ORDER = ArrLink["PASS_ORDER"];
export const DASHBOARD = ArrLink["DASHBOARD"];
export const LOGIN = ArrLink["LOGIN"];

export const WEB = ArrLink["WEB"];
export const WEB_DASHBOARD = ArrLink["WEB_DASHBOARD"];

export const WEB_PRODUCTS = ArrLink["WEB_PRODUCTS"];
export const WEB_PRODUCTS_EDIT = ArrLink["WEB_PRODUCTS_EDIT"];


export const WEB_PRODUCTS_TKTOK = ArrLink["WEB_PRODUCTS_TKTOK"];
export const WEB_PRODUCTS_EDIT_TKTOK = ArrLink["WEB_PRODUCTS_EDIT_TKTOK"];

export const WEB_ORDERS = ArrLink["WEB_ORDERS"];
export const WEB_ORDERS_EDIT = ArrLink["WEB_ORDERS_EDIT"];


export const WEB_ORDERS_TIKTOK = ArrLink["WEB_ORDERS_TIKTOK"];
export const WEB_ORDERS_EDIT_TIKTOK = ArrLink["WEB_ORDERS_EDIT_TIKTOK"];


export const WEB_CATEGORY = ArrLink["WEB_CATEGORY"];
export const WEB_CATEGORY_EDIT = ArrLink["WEB_CATEGORY_EDIT"];

export const WEB_MENUHOME = ArrLink["WEB_MENUHOME"];
export const WEB_MENUHOME_EDIT = ArrLink["WEB_MENUHOME_EDIT"];
export const WEB_CLASSIFY = ArrLink["WEB_CLASSIFY"];
export const WEB_CLASSIFY_EDIT = ArrLink["WEB_CLASSIFY_EDIT"];

export const WEB_INFO_TRANSPORT = ArrLink["WEB_INFO_TRANSPORT"];
export const WEB_INFO_TRANSPORT_EDIT = ArrLink["WEB_INFO_TRANSPORT_EDIT"];

export const WEB_NUMBER_OF_PAGE = ArrLink["WEB_NUMBER_OF_PAGE"];
export const WEB_NUMBER_OF_PAGE_EDIT = ArrLink["WEB_NUMBER_OF_PAGE_EDIT"];


export const WEB_REGION = ArrLink["WEB_REGION"];
export const WEB_REGION_EDIT = ArrLink["WEB_REGION_EDIT"];


export const WEB_ROLE = ArrLink["WEB_ROLE"];
export const WEB_FUNC = ArrLink["WEB_FUNC"];
export const WEB_SETTING = ArrLink["WEB_SETTING"];
export const WEB_ROLE_EDIT = ArrLink["WEB_ROLE_EDIT"];

export const WEB_STORE = ArrLink["WEB_STORE"];
export const WEB_STORE_EDIT = ArrLink["WEB_STORE_EDIT"];

export const WEB_TAGS = ArrLink["WEB_TAGS"];
export const WEB_TAGS_EDIT = ArrLink["WEB_TAGS_EDIT"];

export const WEB_TRADEMARK = ArrLink["WEB_TRADEMARK"];
export const WEB_TRADEMARK_EDIT = ArrLink["WEB_TRADEMARK_EDIT"];
export const WEB_TYPE_PRODUCTS = ArrLink["WEB_TYPE_PRODUCTS"];
export const WEB_TYPE_PRODUCTS_EDIT = ArrLink["WEB_TYPE_PRODUCTS_EDIT"];


export const WEB_WAREHOUSE = ArrLink["WEB_WAREHOUSE"];
export const WEB_WAREHOUSE_EDIT = ArrLink["WEB_WAREHOUSE_EDIT"];
export const WEB_WARRANTY_TYPE = ArrLink["WEB_WARRANTY_TYPE"];
export const WEB_WARRANTY_TYPE_EDIT = ArrLink["WEB_WARRANTY_TYPE_EDIT"];


export const WEB_ORGANIZATION_PRODUCTION = ArrLink["WEB_ORGANIZATION_PRODUCTION"];
export const WEB_ORGANIZATION_PRODUCTION_EDIT = ArrLink["WEB_ORGANIZATION_PRODUCTION_EDIT"];


export const WEB_PAPER_TEXTURE = ArrLink["WEB_PAPER_TEXTURE"];
export const WEB_PAPER_TEXTURE_EDIT = ArrLink["WEB_PAPER_TEXTURE_EDIT"];

export const WEB_INFOPACKAGE = ArrLink["WEB_INFOPACKAGE"];
export const WEB_INFOPACKAGE_EDIT = ArrLink["WEB_INFOPACKAGE_EDIT"];
export const WEB_ORGANIZATION_PRODUCTION_ADDRESS = ArrLink["WEB_ORGANIZATION_PRODUCTION_ADDRESS"];
export const WEB_ORGANIZATION_PRODUCTION_ADDRESS_EDIT = ArrLink["WEB_ORGANIZATION_PRODUCTION_ADDRESS_EDIT"];



export const WEB_INVENTORY_IN = ArrLink["WEB_INVENTORY_IN"];
export const WEB_INVENTORY_IN_EDIT = ArrLink["WEB_INVENTORY_IN_EDIT"];

export const WEB_INVENTORY_CHECK = ArrLink["WEB_INVENTORY_CHECK"];
export const WEB_INVENTORY_CHECK_EDIT = ArrLink["WEB_INVENTORY_CHECK_EDIT"];


export const WEB_ATTRIBUTE_PRODUCTS = ArrLink["WEB_ATTRIBUTE_PRODUCTS"];
export const WEB_ATTRIBUTE_PRODUCTS_EDIT = ArrLink["WEB_ATTRIBUTE_PRODUCTS_EDIT"];


export const WEB_SUPPLIER = ArrLink["WEB_SUPPLIER"];
export const WEB_SUPPLIER_EDIT = ArrLink["WEB_SUPPLIER_EDIT"];

export const WEB_USER = ArrLink["WEB_USER"];
export const WEB_USER_EDIT = ArrLink["WEB_USER_EDIT"];

export const WEB_MENUS = ArrLink["WEB_MENUS"];
export const WEB_MENUS_EDIT = ArrLink["WEB_MENUS_EDIT"];


export const WEB_STATUS_TRANSPORT = ArrLink["WEB_STATUS_TRANSPORT"];
export const WEB_STATUS_TRANSPORT_EDIT = ArrLink["WEB_STATUS_TRANSPORT_EDIT"];


export const WEB_STATUS_TRANSPORT_TYPE = ArrLink["WEB_STATUS_TRANSPORT_TYPE"];
export const WEB_STATUS_TRANSPORT_TYPE_EDIT = ArrLink["WEB_STATUS_TRANSPORT_TYPE_EDIT"];


export const WEB_CUSTOMERS = ArrLink["WEB_CUSTOMERS"];
export const WEB_CUSTOMERS_EDIT = ArrLink["WEB_CUSTOMERS_EDIT"];


export const WEB_VOUCHERS = ArrLink["WEB_VOUCHERS"];
export const WEB_VOUCHERS_EDIT = ArrLink["WEB_VOUCHERS_EDIT"];

export const WEB_VOUCHERS_GROUP = ArrLink["WEB_VOUCHERS_GROUP"];
export const WEB_VOUCHERS_GROUP_EDIT = ArrLink["WEB_VOUCHERS_GROUP_EDIT"];
export const WEB_PROVINCES = ArrLink["WEB_PROVINCES"];
export const WEB_PROVINCES_EDIT = ArrLink["WEB_PROVINCES_EDIT"];


export const WEB_DISTRICTS = ArrLink["WEB_DISTRICTS"];
export const WEB_DISTRICTS_EDIT = ArrLink["WEB_DISTRICTS_EDIT"];

export const WEB_WAR = ArrLink["WEB_WAR"];
export const WEB_WAR_EDIT = ArrLink["WEB_WAR_EDIT"];

export const WEB_POST = ArrLink["WEB_POST"];
export const WEB_POST_EDIT = ArrLink["WEB_POST_EDIT"];

export const WEB_SETTINGS = ArrLink["WEB_SETTINGS"];
export const WEB_SETTINGS_EDIT = ArrLink["WEB_SETTINGS_EDIT"];
export const WEB_SETTINGS_ORDER = ArrLink["WEB_SETTINGS_ORDER"];
export const WEB_SETTINGS_WEB = ArrLink["WEB_SETTINGS_WEB"];
export const WEB_SETTINGS_ADMIN = ArrLink["WEB_SETTINGS_ADMIN"];
export const WEB_SETTINGS_SOCIAL = ArrLink["WEB_SETTINGS_SOCIAL"];

export const WEB_SETTINGS_SOCIAL_FACEBOOK = ArrLink["WEB_SETTINGS_SOCIAL_FACEBOOK"];
export const WEB_SETTINGS_SOCIAL_ZALO = ArrLink["WEB_SETTINGS_SOCIAL_ZALO"];

export const WEB_SETTINGS_PRINT_BILLS = ArrLink["WEB_SETTINGS_PRINT_BILLS"];

export const WEB_SETTINGS_ECOMMERCE = ArrLink["WEB_SETTINGS_ECOMMERCE"];
export const WEB_SETTINGS_CACHE = ArrLink["WEB_SETTINGS_CACHE"];

export const WEB_BANNERS = ArrLink["WEB_BANNERS"];
export const WEB_BANNERS_EDIT = ArrLink["WEB_BANNERS_EDIT"];


export const WEB_COLLECTIONS = ArrLink["WEB_COLLECTIONS"];
export const WEB_COLLECTIONS_EDIT = ArrLink["WEB_COLLECTIONS_EDIT"];


export const WEB_FEEDBACK = ArrLink["WEB_FEEDBACK"];
export const WEB_FEEDBACK_EDIT = ArrLink["WEB_FEEDBACK_EDIT"];

export const WEB_PAGE = ArrLink["WEB_PAGE"];
export const WEB_PAGE_EDIT = ArrLink["WEB_PAGE_EDIT"];

export const WEB_CONTACT = ArrLink["WEB_CONTACT"];
export const WEB_CONTACT_EDIT = ArrLink["WEB_CONTACT_EDIT"];
export const WEB_BUILDER = ArrLink["WEB_BUILDER"];

export const WEB_REPORTS = ArrLink["WEB_REPORTS"];
export const WEB_REPORT_BESTSELLING = ArrLink["WEB_REPORT_BESTSELLING"];
export const WEB_REPORT_REVENUE_PRODUCT = ArrLink["WEB_REPORT_REVENUE_PRODUCT"];
export const WEB_REPORT_REVENUE_CUSTOMER = ArrLink["WEB_REPORT_REVENUE_CUSTOMER"];

export const WEB_PRODUCTS_WAREHOUSE = ArrLink["WEB_PRODUCTS_WAREHOUSE"];
export const WEB_PRODUCTS_WAREHOUSE_EDIT = ArrLink["WEB_PRODUCTS_WAREHOUSE_EDIT"];


export const WEB_NETWORK_HOME = ArrLink["WEB_NETWORK_HOME"];
export const WEB_NETWORK_HOME_EDIT= ArrLink["WEB_NETWORK_HOME_EDIT"];


export const WEB_NETWORK_HOME_OPERATOR = ArrLink["WEB_NETWORK_HOME_OPERATOR"];
export const WEB_NETWORK_HOME_OPERATOR_EDIT = ArrLink["WEB_NETWORK_HOME_OPERATOR_EDIT"];


export const WEB_UNIT = ArrLink["WEB_UNIT"];
export const WEB_UNIT_EDIT = ArrLink["WEB_UNIT_EDIT"];


export const WEB_MERTERIAL_NORMS = ArrLink["WEB_MERTERIAL_NORMS"];
export const WEB_MERTERIAL_NORMS_EDIT = ArrLink["WEB_MERTERIAL_NORMS_EDIT"];