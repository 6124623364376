import React, { Component, createRef } from "react";
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import { Icons } from "../../../../constants/ListIcons";
import { ArrLink } from "./../../../../constants/LinksArr";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, Tooltip, Checkbox, InputNumber, TreeSelect, DatePicker, Card, Row, Col } from 'antd';
import Draggable from 'react-draggable';
import
{
    FolderViewOutlined,
    DeleteOutlined, PlusCircleOutlined, SearchOutlined, UnorderedListOutlined,
    EditOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { getParamsUrl } from "../../../../service";
const { Search } = Input;
let ArrLink_ = [];
const { RangePicker } = DatePicker;

Object.values(ArrLink).map((item, index) =>
{
    let itemtmp = item.split("_");
    let gtmpar = itemtmp[0].split("/");
    let gtmp = gtmpar[gtmpar.length - 1]
    if (!ArrLink_[gtmp]) {
        ArrLink_[gtmp] = []
    }

    ArrLink_ = { ...ArrLink_, [gtmp]: [...ArrLink_[gtmp], item] }

});


class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListMenusSearch: [],
            ListFunct: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,
                    with: "20%",
                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'Route',
                    dataIndex: 'route',
                    sorter: (a, b) => a.route - b.route,

                },
                {
                    title: 'Router',
                    dataIndex: 'router',
                    sorter: (a, b) => a.router - b.router,

                },

                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreMenus: {},
            isloading: false,
            visibledetail: false,
            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            disabled: true,
            search: {},
        }

    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    draggleRef = new createRef(null);
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type,
                // record: { ...state.record, deleted: 0 }
            }
        })
    }
    setVisibleDetail = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        console.log('====================================');
        console.log("handleOkDelete", record);
        console.log('====================================');
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        record.type = !record.type ? 0 : 1;
        this.props.StoreMenusRequest(record, record.id ? record.id : "")
        // setTimeout(() =>
        // {

        // }, 2000);
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };

    handleOkDetail = () =>
    {
        this.setVisibleDetail(true);

    };

    handleCancelDetail = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)
        if (Object.values(filteredvalues).length > 0) {
            this.props.SearchMenusRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            }, { data: filteredvalues });

        } else {
            this.props.ListMenusRequest({
                limit: this.state.meta.per_page * 1000,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
        }

        this.props.ListFunctRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListMenusSearch && nextProps.Web.ListMenusSearch !== prevState.ListMenusSearch) {

            return {
                ListMenusSearch: nextProps.Web.ListMenusSearch,
                isLoadingSearch: false

            };

        }
        if (nextProps.Web.StoreMenus && nextProps.Web.StoreMenus !== prevState.StoreMenus) {
            return {
                StoreMenus: nextProps.Web.StoreMenus,

            };

        }
        if (nextProps.Web.ListFunct && nextProps.Web.ListFunct.data !== prevState.ListFunct) {

            console.log('====================================');
            console.log(nextProps.Web.ListFunct);
            console.log('====================================');
            return {
                ListFunct: nextProps.Web.ListFunct.data,

            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreMenus !== prevState.StoreMenus) {
            let { StoreMenus } = this.state;

            try {
                if (StoreMenus.code === 200) {
                    if (StoreMenus.message && typeof StoreMenus.message === "string") {
                        message.success(StoreMenus.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    if (Object.values(filteredvalues).length > 0) {
                        this.props.SearchMenusRequest({
                            limit: this.state.meta.per_page,
                            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                        }, { data: filteredvalues });

                    } else {
                        this.props.ListMenusRequest({
                            limit: this.state.meta.per_page * 1000,
                            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                        });
                    }



                }
                else {
                    if (StoreMenus.message && typeof StoreMenus.message === "string") {

                        message.error(StoreMenus.message)
                    } else {
                        for (let x in StoreMenus.message) {
                            let mess = StoreMenus.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreMenus()
            this.handleCancelDetail();

        }
        if (this.state.ListMenusSearch !== prevState.ListMenusSearch) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'code',
                        render: (text, record, index) => index + 1,

                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Route',
                        dataIndex: 'funct_route',
                        sorter: (a, b) => a.route - b.route,

                    },
                    {
                        title: 'Router',
                        dataIndex: 'funct_router',
                        sorter: (a, b) => a.router - b.router,

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.ShowDetail(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    {/* {record.parent_id === 0 && */}

                                    <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>
                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    ShowDetail = (record) =>
    {
        this.handleOkDetail();
    }
    data = () =>
    {
        let { ListMenusSearch } = this.state;

        if (ListMenusSearch.length <= 0) {
            return;
        }

        let data = [];
        let listfil = [];

        const result = { listfil: [], data: [] }
        // this.renderTableTree(ListCategory, result, false);
        ListMenusSearch.map((item, index) =>
        {

            listfil.push({
                text: item.name,
                value: item.name,
            })
            // item.key = index + 1
            // item.children = item.children ? Object.values(item.children) : []
            // data.push(item)

        });
        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: ListMenusSearch,
                // meta: meta
            }
        })
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onChangetext = (e, type, group) =>
    {


        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })

        }

    }

    editRows = (record) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: true,
                record: record
            }
        }, () =>
        {
            record.type = record.type === 1 ? true : false;
            this.formRef.current.setFieldsValue({ ...record })
            // this.handleOkDetail();
        })

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", delete: 0, type: 0, parent_id: record.id, parent: { name: record.name ? record.name : "Gốc" } },
                visibledetail: true,
            }
        }, () =>
        {
            // this.handleOkDetail();

            this.formRef.current.resetFields();
            this.formRef.current.setFieldsValue({ parent_id: record.id });
        })

    }
    // update_ = () =>
    // {
    //     const { record } = this.state;
    //     console.log('====================================');
    //     console.log(record);
    //     console.log('====================================');
    //     if (!record.name) {
    //         message.warn("Vui lòng nhập tên!");
    //         return;
    //     }
    //     record.parent_id = record.parent_id ? record.parent_id : 0;

    //     this.SetisLoading(true)
    //     this.props.StoreMenusRequest(record, record.id ? record.id : "");
    // }
    onFinishForm = (values) =>
    {

        const { record } = this.state;
        if (!record.icon) {
            message.warn("Vui lòng chọn 1 icon");
            return;
        }
        delete values.funct_id;
        delete values.icon;

        const record_ = { ...record, ...values }

        record_.type = !record_.type ? 0 : 1;
        this.SetisLoading(true)
        this.props.StoreMenusRequest(record_, record_.id ? record_.id : "")

    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {

            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteMenusRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchMenusRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        this.formSearchRef.current.setFieldsValue(filteredvalues)
        if (Object.values(filteredvalues).length > 0) {

            this.props.SearchMenusRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            }, { data: filteredvalues });

        } else {

            this.props.ListMenusRequest({
                limit: this.state.meta.per_page * 1000,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
        }

        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_MENUS}?${searchParams}`);


    }
    handleResetRadioDetail = () =>
    {

        this.setState(state =>
        {
            return {
                record: { ...state.record, route: "", router: "" }
            }
        })

    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}

                >
                    <p>{this.state.modalText}</p>
                </Modal>

                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Chi tiết
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[
                        // <Button key="back" onClick={this.handleCancelDetail}>
                        //     Hủy
                        // </Button>,
                        // <Button key="resetradio" onClick={this.handleResetRadioDetail}>
                        //     Reset
                        // </Button>,
                        // <Button key="submit" type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={() => { this.update_() }}>
                        //     Cập nhật
                        // </Button>,
                    ]}
                    width={1000}
                    bodyStyle={{
                        height: "500px",
                        overflowY: "scroll"
                    }}
                    style={{ position: "relative" }}
                >
                    <div className="modal_content_">
                        <Form
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                // span: 14,
                            }}
                            layout="horizontal"
                            name="formadd" onFinish={this.onFinishForm} ref={this.formRef}

                        >
                            <h5 style={{ color: Colors.color1 }}>{this.state.record?.parent && "thuộc tính gốc: " + this.state?.record?.parent?.name}</h5>

                            <Form.Item label="Cha" name="parent_id" rules={[
                                {
                                    required: true,
                                    message: "Menu cha là bắt buộc"
                                },
                            ]}>
                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%' }}
                                    treeData={[{ id: 0, value: 0, title: "Gốc" }, ...this.state.data]}
                                    // value={this.state.record.parent_id === 0 ? "gốc" : this.state.record.parent_id}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Danh mục"
                                    // allowClear
                                    // multiple
                                    treeDefaultExpandAll
                                    onChange={(e) =>
                                    {
                                        console.log('====================================');
                                        console.log(e);
                                        console.log('====================================');
                                    }}

                                /></Form.Item>
                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên menus là bắt buộc"
                                },
                            ]}>

                                <Input placeholder="Tên thuộc tính" />
                            </Form.Item>
                            <Form.Item label="Code" name="code">

                                <Input placeholder="code" />
                            </Form.Item>
                            <Form.Item label="Sắp Xếp " name="rank" rules={[
                                {
                                    required: true,
                                    message: "Vị trí menus là bắt buộc"
                                },
                            ]}>

                                <InputNumber min={0} placeholder="rank" />
                            </Form.Item>
                            <Form.Item label="Chức năng" name="funct_id" rules={[
                                {
                                    required: true,
                                    message: "Chức năng là bắt buộc"
                                },
                            ]} >

                                <Radio.Group name="funct_id" onChange={(e) => { this.onChangetext(e, "funct_id", "record") }} value={this.state.record.funct_id}>
                                    {this.state.ListFunct && this.state.ListFunct.map((item, index) =>
                                    {
                                        if (item) {
                                            return <Radio style={{ color: Colors.color2, fontWeight: "bold" }} value={item.id} key={index} >{item.name}</Radio>;
                                        }

                                    })}
                                </Radio.Group>

                            </Form.Item>
                            <Form.Item label="Icons" name="icon" >

                                {Icons && Icons.map((Icon, index) =>
                                {
                                    const Ic = Icon.icon;
                                    return <button type="button" key={index} className="icon_list reset_btn" onClick={() => { this.onChangetext(Icon.name, "icon", "record") }}>
                                        <Ic style={{ fontSize: "40px", color: this.state.record.icon === Icon.name ? Colors.colormain : Colors.color2 }} />
                                        {/* <span>{Icon.name}</span> */}
                                    </button>
                                })}
                            </Form.Item>
                            <div style={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "10px",
                                borderTop: `1px solid ${Colors.colorgreyLight}`,
                                backgroundColor: Colors.white
                            }}>
                                <Form.Item noStyle >
                                    <Button key="back" onClick={this.handleCancelDetail}>
                                        Hủy
                                    </Button>,
                                    <Button key="resetradio" onClick={this.handleResetRadioDetail}>
                                        Reset
                                    </Button>,
                                    <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UnorderedListOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Menus</span>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                           
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >


                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <Form.Item style={{ margin: 0 }}>
                                        <Input.Group compact>
                                            <Row gutter={16} style={{ display: "flex" }}>
                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Tên.." />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="route" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Route" />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="router" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Router" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="right_">
                                <Button type="primary" onClick={this.addnew}><PlusCircleOutlined /></Button>
                            </div>

                        </div>
                        <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            // rowSelection={{
                            //     ...this.state.rowSelection,
                            // }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                           
                        />


                    </Card>
                </div>





            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListMenusRequest: (body) =>
        {
            dispatch(ActionsWeb.ListMenusRequest(body));
        },
        StoreMenusRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreMenusRequest(data, id));
        },
        ResetStoreMenus: () =>
        {
            dispatch(ActionsWeb.ResetStoreMenus());
        },
        ListFunctRequest: (body) =>
        {
            dispatch(ActionsWeb.ListFunctRequest(body));
        },
        SearchMenusRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchMenusRequest(body, data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);