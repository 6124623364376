import React, { Component } from "react";


import "./style.css";

class loadingSmile extends Component
{




  render()
  {

    let { height, width } = this.props;

    return (
      <div className="right-wrapper" style={height ? { height: height } : {}}>
        <div className="spinnerIconWrapper">
          <div className="spinnerIcon"></div>
        </div>

      </div>
    );
  }
}



export default loadingSmile;
