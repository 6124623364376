import React, { Component, createRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Card, Row, Col, Tag } from 'antd';

import
{
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { checkScreenSize, formatMN1, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";

const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.state = {

            ListVouchersGroup: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Mã',
                    dataIndex: 'code',

                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.code - b.code,
                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Điện thoại',
                    dataIndex: 'phone',
                    sorter: (a, b) => a.phone - b.phone,

                },
                {
                    title: 'Địa chỉ',
                    dataIndex: 'address',
                    sorter: (a, b) => a.address - b.address,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreVouchersGroup: {},
            search: {},
            isLoadingSearch: false,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreVouchersGroupRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }

            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }
        if (filteredvalues.from_at) {
            filteredvalues.from_to_at = [];
            filteredvalues.from_to_at[0] = moment(filteredvalues.from_at, "YYYY-MM-DD");
            filteredvalues.from_to_at[1] = moment(filteredvalues.to_at, "YYYY-MM-DD");
        }
        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchVouchersGroupRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListVouchersGroup && nextProps.Web.ListVouchersGroup !== prevState.ListVouchersGroup) {
            console.log("Comment", nextProps.Web.ListVouchersGroup);


            return {
                ListVouchersGroup: nextProps.Web.ListVouchersGroup,
                isLoadingSearch: false
            };

        }
        if (nextProps.Web.StoreVouchersGroup && nextProps.Web.StoreVouchersGroup !== prevState.StoreVouchersGroup) {
            return {
                StoreVouchersGroup: nextProps.Web.StoreVouchersGroup,

            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreVouchersGroup !== prevState.StoreVouchersGroup) {
            let { StoreVouchersGroup } = this.state;
            try {
                if (StoreVouchersGroup.code === 200) {
                    if (StoreVouchersGroup.message && typeof StoreVouchersGroup.message === "string") {
                        message.success(StoreVouchersGroup.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }
                    if (filteredvalues.from_at) {
                        filteredvalues.from_to_at = [];
                        filteredvalues.from_to_at[0] = moment(filteredvalues.from_at, "YYYY-MM-DD");
                        filteredvalues.from_to_at[1] = moment(filteredvalues.to_at, "YYYY-MM-DD");
                    }
                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchVouchersGroupRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreVouchersGroup.message && typeof StoreVouchersGroup.message === "string") {

                        message.error(StoreVouchersGroup.message)
                    } else {
                        for (let x in StoreVouchersGroup.message) {
                            let mess = StoreVouchersGroup.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.setConfirmLoading(false);
            this.props.ResetStoreVouchersGroup()
            this.handleCancel();
        }
        if (this.state.ListVouchersGroup !== prevState.ListVouchersGroup) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        with: "5%",
                        align: "center"

                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Giá trị',
                        dataIndex: 'value',

                        filterSearch: true,
                        filters: filters.value,
                        onFilter: (value, record) =>
                        {
                            return record.value.startsWith(value)

                        },
                        sorter: (a, b) => a.value - b.value,
                        render: (text, record, index) =>
                        {
                            if ((1 * text) <= 100) {
                                return text + "%"
                            } else {
                                return formatMN1(text)
                            }
                        }
                    },

                    {
                        title: 'Từ ngày',
                        dataIndex: 'from_at',
                        filters: filters.from_at,
                        onFilter: (value, record) =>
                        {
                            return record.from_at.startsWith(value)

                        },
                        sorter: (a, b) => a.from_at - b.from_at,

                    },

                    {
                        title: 'Từ ngày',
                        dataIndex: 'to_at',
                        filters: filters.to_at,
                        onFilter: (value, record) =>
                        {
                            return record.to_at.startsWith(value)

                        },
                        sorter: (a, b) => a.to_at - b.to_at,

                    },
                    {
                        title: 'Trạng thái',
                        dataIndex: 'status',
                        filters: filters.status,
                        onFilter: (value, record) =>
                        {
                            return record.status.startsWith(value)

                        },
                        sorter: (a, b) => a.status - b.status,
                        render: (text, record, index) =>
                        {
                            if ((1 * text) === 0) {
                                return <Tag key={index} color={Colors.color2}>{"Mới tạo"}</Tag>
                            }
                            if ((1 * text) === 1) {
                                return <Tag key={index} color={Colors.colorGreen} style={{ cursor: "pointer" }}>{"Đã sử dụng"}</Tag>
                            }
                            if ((1 * text) === 2) {
                                return <Tag key={index} color={Colors.colorpink}>{"Đã hết hạng"}</Tag>
                            }
                            if ((1 * text) === 3) {
                                return <Tag key={index} color={Colors.colorWarning}>{"Đã hủy"}</Tag>
                            }
                        }
                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        try {


            let { ListVouchersGroup } = this.state;

            if (ListVouchersGroup.length <= 0) {
                return;
            }
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListVouchersGroup.current_page,
                first_page_url: ListVouchersGroup.first_page_url,
                from: ListVouchersGroup.from,
                last_page: ListVouchersGroup.last_page,
                last_page_url: ListVouchersGroup.last_page_url,
                links: ListVouchersGroup.links,
                next_page_url: ListVouchersGroup.next_page_url,
                path: ListVouchersGroup.path,
                per_page: ListVouchersGroup.per_page,
                prev_page_url: ListVouchersGroup.prev_page_url,
                to: ListVouchersGroup.to,
                total: ListVouchersGroup.total,
            };
            listfil["name"] = [];

            listfil["address"] = [];
            listfil["phone"] = [];
            ListVouchersGroup.data.map((item, index) =>
            {
                listfil["name"].push({
                    text: item.name,
                    value: item.name,
                });
                listfil["address"].push({
                    text: item.address,
                    value: item.address,
                });

                listfil["phone"].push({
                    text: item.phone,
                    value: item.phone,
                });

                item.key = item.id
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_VOUCHERS_GROUP_EDIT + "/" + record.id,
            state: record
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }
        if (filteredvalues.from_at) {
            filteredvalues.from_to_at = [];
            filteredvalues.from_to_at[0] = moment(filteredvalues.from_at, "YYYY-MM-DD");
            filteredvalues.from_to_at[1] = moment(filteredvalues.to_at, "YYYY-MM-DD");
        }
        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchVouchersGroupRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        if (filteredvalues.from_to_at) {
            filteredvalues.from_at = filteredvalues.from_to_at[0].format("YYYY-MM-DD")
            filteredvalues.to_at = filteredvalues.from_to_at[1].format("YYYY-MM-DD")
        }
        // if (filteredvalues.from_at === filteredvalues.to_at){
        //     filteredvalues.to_at="";
        // }
        // return;

        this.props.SearchVouchersGroupRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]
        delete filteredvalues["from_to_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_VOUCHERS}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    render()
    {


        return (

            <div className="main_content vouchers_group_" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Voucher</span>
                        </div>
                        <Link to={LINK.WEB_VOUCHERS_GROUP_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",

                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <div className="row">

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tên Khách hàng" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Code" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="value" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Giá trị" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="codecus" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="mã khách hàng" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="address" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Địa chỉ" />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="phone" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Điện thoại" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <div style={{ with: "100%", display: "flex", padding: "0 10px", alignItems: "center" }}>
                                                <label style={{ display: "flex", width: "40%", margin: 0 }}>Hiệu lực</label>
                                                <Form.Item name="from_to_at" style={{ margin: 0, width: "60%" }} >
                                                    <RangePicker />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <div style={{ with: "100%", display: "flex", padding: "0 10px", alignItems: "center" }}>
                                                <label style={{ display: "flex", width: "40%", margin: 0 }}>Ngày tạo</label>
                                                <Form.Item name="created_at" style={{ margin: 0, width: "60%" }}>
                                                    <RangePicker />
                                                </Form.Item>
                                            </div>
                                        </div>


                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </div>
                                    </div>
                                </Form>
                            </div>
                          
                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            setIsNewList={() =>
                            {
                                this.setState(state =>
                                {
                                    return {
                                        isNewList: !state.isNewList
                                    }
                                })
                            }}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <><Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            // rowSelection={{
                            //     ...this.state.rowSelection,
                            // }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>
                        </> 

    }
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListVouchersGroupRequest: (body) =>
        {
            dispatch(ActionsWeb.ListVouchersGroupRequest(body));
        },
        StoreVouchersGroupRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreVouchersGroupRequest(data, id));
        },
        ResetStoreVouchersGroup: () =>
        {
            dispatch(ActionsWeb.ResetStoreVouchersGroup());
        },
        SearchVouchersGroupRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchVouchersGroupRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);