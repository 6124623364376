import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import { Input, Modal, message, Button, Form, Switch as SwitchA, Card, InputNumber, Select } from 'antd';
import { getParamsUrl, replaceThous } from "./../../../../service";
import * as Colors from "../../../../constants/Colors";

import { Switch, Route, Link, Redirect } from 'react-router-dom';
import Upload from "./Upload";
import
{
    DeleteOutlined, GlobalOutlined, PlusOutlined,
    EditOutlined,
    FileImageOutlined, VideoCameraOutlined,
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import Draggable from 'react-draggable';
import { TableList } from "../../../../constants/TableList";

const { TextArea } = Input;

const arr_btnAction = [
    { label: "Text", type: "text" },
    { label: "TextArea", type: "textarea" },
    { label: "File", type: "file" },
    { label: "Files", type: "files" },
    { label: "video", type: "video" },
    { label: "Select", type: "select" },
]
class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            ListSettings: [
                { label: "Facebook", link: LINK.WEB_SETTINGS_SOCIAL_FACEBOOK },
                { label: "Zalo", link: LINK.WEB_SETTINGS_SOCIAL_ZALO },
               
            ],

        }

    }
    render()
    {
        const { routers } = this.props

        return (
            <div className="main_content">

                <div className="table_w" >

                    <div className="form__half row">
                        <div className={"left_half col-lg-2 col-md-2 col-sm-3 "}>

                            <ul className="list_setting">
                                {this.state.ListSettings && this.state.ListSettings.map((item, index) =>
                                {

                                    return <li key={index}>
                                        <Link to={item.link}>{item.label}</Link>
                                    </li>
                                })}


                            </ul>


                        </div>
                        <div className="right_half  col-lg-10 col-md-10 col-sm-9 ">

                            <Switch>
                                {routers &&
                                    routers.length > 0 &&
                                    routers.map((route, index) => (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}

                                            render={props => (
                                                route.main({ ...props, routers: route.routers })
                                            )}
                                        />
                                    ))
                                }
                               
                            </Switch>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListSettingsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListSettingsRequest(body));
        },
        StoreSettingsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreSettingsRequest(data, id));
        },
        ResetStoreSettings: () =>
        {
            dispatch(ActionsWeb.ResetStoreSettings());
        },
        // ////////////////
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
        {
            dispatch(ActionsWeb.ResetUploadFileLa());
        },
        ListStatusTransportRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStatusTransportRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);