import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal,Select, message, Button, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import
{
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.state = {

            ListNetworkHomeOperator: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',

                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.slug - b.slug,
                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },

                {
                    title: 'Nôi dung',
                    dataIndex: 'description',
                    sorter: (a, b) => a.description - b.description,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreNetworkHomeOperator: {},
            search: {},
            isLoadingSearch: false,
            isloading:false,
            isShowList: false,
            isNewList: false,
            ListNetworkHome:[],
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.DeleteNetworkHomeOperatorRequest(record, record.id ? record.id : "")
        this.setState(state =>
            {
                return {
                    isNewList: true,
                    meta:{...state.meta,current_page:1}
                }
            });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)
        console.log();
        this.props.SearchNetworkHomeOperatorRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        },{data:filteredvalues});
        this.props.SearchNetworkHomeRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, {data:{}})

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {

        if (nextProps.Web.ListNetworkHomeOperator && nextProps.Web.ListNetworkHomeOperator !== prevState.ListNetworkHomeOperator) {

            return {
                ListNetworkHomeOperator: nextProps.Web.ListNetworkHomeOperator,
                isLoadingSearch: false
            };

        }
        if (nextProps.Web.StoreNetworkHomeOperator && nextProps.Web.StoreNetworkHomeOperator !== prevState.StoreNetworkHomeOperator) {
            return {
                StoreNetworkHomeOperator: nextProps.Web.StoreNetworkHomeOperator,

            };

        }
        if (nextProps.Web.ListNetworkHome && nextProps.Web.ListNetworkHome.data !== prevState.ListMenuHome) {
            console.log(nextProps.Web.ListNetworkHome);
            return {
                ListNetworkHome: nextProps.Web.ListNetworkHome.data,

            };

        }
        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreNetworkHomeOperator !== prevState.StoreNetworkHomeOperator) {
            let { StoreNetworkHomeOperator } = this.state;
            try {
                if (StoreNetworkHomeOperator.code === 200) {
                    if (StoreNetworkHomeOperator.message && typeof StoreNetworkHomeOperator.message === "string") {
                        message.success(StoreNetworkHomeOperator.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchNetworkHomeOperatorRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreNetworkHomeOperator.message && typeof StoreNetworkHomeOperator.message === "string") {

                        message.error(StoreNetworkHomeOperator.message)
                    } else {
                        for (let x in StoreNetworkHomeOperator.message) {
                            let mess = StoreNetworkHomeOperator.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.setConfirmLoading(false);
            this.props.ResetStoreNetworkHomeOperator()
            this.handleCancel();
        }
        if (this.state.ListNetworkHomeOperator !== prevState.ListNetworkHomeOperator) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'stt',

                        align: "center"

                    },
                  
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Đầu số',
                        dataIndex: 'operator',
                   
                      
                    },


                    {
                        title: 'Ghi chú',
                        dataIndex: 'note',
                        render: (text, record, index) =>
                        {
                            return CutString(text, 50, false);
                        }

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        try {


            let { ListNetworkHomeOperator } = this.state;

            if (ListNetworkHomeOperator.length <= 0) {
                return;
            }
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListNetworkHomeOperator.current_page,
                first_page_url: ListNetworkHomeOperator.first_page_url,
                from: ListNetworkHomeOperator.from,
                last_page: ListNetworkHomeOperator.last_page,
                last_page_url: ListNetworkHomeOperator.last_page_url,
                links: ListNetworkHomeOperator.links,
                next_page_url: ListNetworkHomeOperator.next_page_url,
                path: ListNetworkHomeOperator.path,
                per_page: ListNetworkHomeOperator.per_page,
                prev_page_url: ListNetworkHomeOperator.prev_page_url,
                to: ListNetworkHomeOperator.to,
                total: ListNetworkHomeOperator.total,
            };
            listfil["name"] = [];

            console.log("ListNetworkHomeOperator.dataListNetworkHomeOperator.data",ListNetworkHomeOperator.data);
            ListNetworkHomeOperator.data.map((item, index) =>
            {
                listfil["name"].push({
                    text: item.name,
                    value: item.name,
                });
                item.stt = index+1
                item.key = item.id
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_NETWORK_HOME_OPERATOR_EDIT + "/" + record.id,
            state: record
        });

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    duplicateItem = (record) =>
    {
        this.SetisLoading(true)
        this.props.duplicateNetworkHomeOperatorRequest({ id: record.id })
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1, thumbnail: [state.record.thumbnail] }

            }
        }, () =>
        {
            console.log('====================================');
            console.log(this.state.record);
            console.log('====================================');
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchNetworkHomeOperatorRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchNetworkHomeOperatorRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        console.log("filteredvaluesfilteredvalues",filteredvalues);
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_NETWORK_HOME_OPERATOR}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta:{...META}
            }
        })
    }
    render()
    {


        return (

            <div className="main_content post_" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Đầu số nhà mạng</span>
                        </div>
                        <Link to={LINK.WEB_NETWORK_HOME_OPERATOR_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    
                                            <div className="row">
                                            <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                <Form.Item name={"network_home_id"}>
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                        

                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="Nhà mạng"
                                            // allowClear
                                            options={(this.state.ListNetworkHome || []).map((d) => ({
                                                value: d.id,
                                                label: d.name,
                                            }))}
                                        />

                                        </Form.Item>
                                        </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="tên" />
                                                    </Form.Item>
                                                </div>

                                             <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="code" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="operator" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="đầu số" />
                                                    </Form.Item>
                                                </div>
 <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker />
                                                    </Form.Item>
                                                </div>


                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                        </div>
                                                    </Form.Item>

                                                </div>
                                         </div>
                                </Form>
                            </div>
                           
                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            setIsNewList={() =>
                            {
                                this.setState(state =>
                                {
                                    return {
                                        isNewList: !state.isNewList
                                    }
                                })
                            }}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <> <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                          
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                           
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>

                        </>
    }
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
        SearchNetworkHomeRequest: (body, data) =>
            {
                dispatch(ActionsWeb.SearchNetworkHomeRequest(body, data));
            },
        ListNetworkHomeOperatorRequest: (body) =>
        {
            dispatch(ActionsWeb.ListNetworkHomeOperatorRequest(body));
        },
        StoreNetworkHomeOperatorRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreNetworkHomeOperatorRequest(data, id));
        },
        ResetStoreNetworkHomeOperator: () =>
        {
            dispatch(ActionsWeb.ResetStoreNetworkHomeOperator());
        },
        SearchNetworkHomeOperatorRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchNetworkHomeOperatorRequest(body, data));
        },
        duplicateNetworkHomeOperatorRequest: (body, data) =>
        {
            dispatch(ActionsWeb.duplicateNetworkHomeOperatorRequest(body, data));
        },
        DeleteNetworkHomeOperatorRequest: (body, id) =>
        {
            dispatch(ActionsWeb.DeleteNetworkHomeOperatorRequest(body, id));
        },
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);