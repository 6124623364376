import React, { Component, createRef, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import { Input, Modal, message, Button, Form, Switch as SwitchA, Card, InputNumber, Select,Checkbox } from 'antd';
import { getParamsUrl, replaceThous } from "../../../../service";
import * as Colors from "../../../../constants/Colors";

import { Switch, Route, Link, Redirect } from 'react-router-dom';

import {
DeleteOutlined, GlobalOutlined, PlusOutlined,
EditOutlined,
FileImageOutlined, VideoCameraOutlined,
} from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
const { TextArea } = Input;
const Index = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);

    const [data, setdata] = useState(null);
    const [checkValue, setCheckValue] = useState([]);
    const [isloading, setisloading] = useState(false);
    const slugRef=useRef();
   
    useEffect(() => {
        dispatch(ActionsWeb.getListCacheRequest());
        return () => {
            
        };
    }, []);

    useEffect(() => {
        
        if(Web.getListCache){
            setdata(Web.getListCache)
        }
        return () => {
            
        };
    }, [Web.getListCache]);

    const onchangeCheckbox=(e)=>{
        const value=e.target.value;
        const checked=e.target.checked;
       
        if(checked){
            if (!checkValue.includes(value)) {
                setCheckValue([...checkValue, value]);
            }
        }else{
            setCheckValue(checkValue.filter(item => item !== value));
        }
      
    }

    const resetCache=()=>{
        setisloading(true);

       
        if(slugRef.current?.value){
            checkValue.push(slugRef.current?.value)
        }
       
      
        dispatch(ActionsWeb.resetCacheRequest({keys:checkValue}));
    }
    useEffect(() => {
        setisloading(false);
        
        if(Web.resetCache){
            if(Web.resetCache.message){
                if(Web.resetCache.code==200){
                    message.success(Web.resetCache.message);

                }else{
                    message.error(Web.resetCache.message);
                }
            }
            
            
        }
        return () => {
            
        };
    }, [Web.resetCache]);
    return (
        <div className="row">

            
            {data && Object.keys(data).map((value, index) => {
                return <div className="col-sm-12 col-md-6 col-lg-6"><Checkbox value={value} onChange={onchangeCheckbox}>{Object.values(data)[index]}</Checkbox> </div>
            })}
                <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                      <input className="w-100" placeholder="nhập slug sản phẩm hoặc bài viết" ref={slugRef}  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit" onClick={resetCache}>Reset Cache</Button>
            </div>
        </div>
    );
};

export default Index;
