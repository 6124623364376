import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1 } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card, DatePicker
} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";
import Validator from "../../../../utils/Validator";
const { TextArea } = Input;

const { Option } = Select;


const defaultTime = moment().subtract(15, 'years');
class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            record: {

                birthday: defaultTime,
                deleted: 0,
                hidden: 0
            },
            StoreContacts: {},
            ListContacts: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        
        if (nextProps.Web.StoreContacts && nextProps.Web.StoreContacts !== prevState.StoreContacts) {
            return {
                StoreContacts: nextProps.Web.StoreContacts,

            };

        }
        

        if (nextProps.Web.ListContacts && nextProps.Web.ListContacts.id !== prevState.record.id) {

      
            return {
                record: nextProps.Web.ListContacts,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreContacts !== prevState.StoreContacts) {
            let { StoreContacts } = this.state;
            try {
                if (StoreContacts.code === 200) {
                    if (StoreContacts.message && typeof StoreContacts.message === "string") {
                        message.success(StoreContacts.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreContacts.message && typeof StoreContacts.message === "string") {

                        message.error(StoreContacts.message)
                    } else {
                        for (let x in StoreContacts.message) {
                            let mess = StoreContacts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStoreContacts()
            this.SetisLoading(false)
        }
        if (this.state.record.id !== prevState.record.id) {
            console.log('====================================');
            console.log(this.state.record);
            console.log('====================================');    
            this.formRef.current.setFieldsValue({ ...this.state.record });

        }

        

    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.ContactByIdRequest({ id });
        }
     


    }
    data = () =>
    {
        let { ListRegion } = this.state;
      

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values, deleted: 0 }
        this.SetisLoading(true)
        this.props.StoreContactsRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

  
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin phản hồi</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                        // maxHeight: "90vh",
                        // overflowY: "scroll"
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                    >

                        <div className="section_ section_img">



                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Tên" />
                            </Form.Item>
                            <Form.Item label="Email" name="email" rules={[
                                {
                                    required: true,
                                    message: "Email là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Tên" />
                            </Form.Item>
                            <Form.Item label="Điện thoại" name="phone_number" rules={[{
                                required: true,
                                message: "Điện thoại là bắt buộc"
                            },
                                {
                                    validator: (_, value) =>
                                    {

                                        if (value) {
                                            const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                                            if (min) {
                                                return Promise.reject(min);
                                            }
                                            const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                                            if (max) {
                                                return Promise.reject(max);
                                            }
                                            const isPhone = Validator.validatePhoneNumber(value);
                                            if (isPhone) {
                                                return Promise.reject(isPhone);
                                            }
                                        }

                                        return Promise.resolve();

                                    }
                                },
                            ]}>
                                <Input placeholder="Điện thoại" />
                            </Form.Item>
                            <Form.Item label="Tiêu đề" name="subject" rules={[
                                {
                                    required: true,
                                    message: "Tiêu đề là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Tên" />
                            </Form.Item>
                            <Form.Item label="Nội dung" name="content" rules={[
                                {
                                    required: true,
                                    message: "Nội dung là bắt buộc"
                                },
                            ]}>
                                <TextArea placeholder="Nội dung" style={{ height: "100px" }} />
                            </Form.Item>
                         
                          

                        </div>

                        <Form.Item >    
                            <div className="flex_">

                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
     
        StoreContactsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreContactsRequest(data, id));
        },
        ResetStoreContacts: () =>
        {
            dispatch(ActionsWeb.ResetStoreContacts());
        },
        ListContactsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListContactsRequest(body));
        },
      
       ContactByIdRequest: (body) =>
        {
           dispatch(ActionsWeb.ContactByIdRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);