import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import
{
  SmileOutlined
} from '@ant-design/icons';


import * as ActionsApp from "../../actions/indexApp";
import { useDispatch, useSelector } from 'react-redux';
import { linkSocket } from '../../constants/LinkApi';

const Index = ({...props}) => {
  const [state, setstate] = useState(null);
  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  useEffect(() => {
    connectWebSocket(dispatch);
    return () => {
    };
  }, []);

}

const connectWebSocket = (dispatch)=>{
    
    const newSocket = new WebSocket(linkSocket);

      newSocket.addEventListener('open', () =>
      {
        console.log('Connected to WebSocket server');
      });

      newSocket.addEventListener('message', (event) =>
      {
        // console.log('Received message:', event.data);
      });

      newSocket.addEventListener('close', (event) =>
      {
        console.log(`Connection closed: ${event.reason}`);
        // Thử kết nối lại sau một khoảng thời gian nhất định (ví dụ: sau 5 giây)
        setTimeout(() => connectWebSocket(dispatch), 5000);
      });

      newSocket.addEventListener('error', (error) =>
      {
        console.error('WebSocket Error:', error);
        // Xử lý lỗi, nếu cần thiết
      });
      dispatch(ActionsApp.setSocketConnect(newSocket))
  }

export default Index;
