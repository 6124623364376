import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, Switch as SwitchA, Card } from 'antd';
import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, getParamsUrl } from "./../../../../service";
import * as Colors from "../../../../constants/Colors";
import * as Services from "./../../../../service";
import
{
    FolderViewOutlined,
    DeleteOutlined, GlobalOutlined, UnorderedListOutlined, AppstoreOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";

import IndexMobile from "./IndexMobile";
const { Search } = Input;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListRegion: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0,
                hidden: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreRegion: {},
            isloading: false,
            isLoadingSearch: false,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreRegionRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        const urlParams = getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

        this.props.ListRegionRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListRegion && nextProps.Web.ListRegion !== prevState.ListRegion) {
            console.log("Comment");


            return {
                ListRegion: nextProps.Web.ListRegion,

            };

        }
        else if (nextProps.Web.StoreRegion && nextProps.Web.StoreRegion !== prevState.StoreRegion) {
            return {
                StoreRegion: nextProps.Web.StoreRegion,

            };

        }
        else {
            return null;
        }

    }

    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreRegion !== prevState.StoreRegion) {
            let { StoreRegion } = this.state;
            try {
                if (StoreRegion.code === 200) {
                    if (StoreRegion.message && typeof StoreRegion.message === "string") {
                        message.success(StoreRegion.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

                    this.props.ListRegionRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    });

                }
                else {
                    if (StoreRegion.message && typeof StoreRegion.message === "string") {

                        message.error(StoreRegion.message)
                    } else {
                        for (let x in StoreRegion.message) {
                            let mess = StoreRegion.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreRegion()

        }
        if (this.state.ListRegion !== prevState.ListRegion) {

            this.SetisLoadingSeach(false)
            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        width: "10%",
                        onFilter: (value, record) =>
                        {
                            console.log('====================================');
                            console.log(value);
                            console.log('====================================');
                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },

                    // {
                    //     title: 'ngày tạo',
                    //     dataIndex: 'created_at',
                    //     sorter: (a, b) => a.created_at - b.created_at,
                    //     render: (text, record, index) =>
                    //     {
                    //         return moment(text).format('DD/MM/YYYY h:m:s');;
                    //     }

                    // },
                    // {
                    //     title: 'Ngày cập nhật',
                    //     dataIndex: 'updated_at',
                    //     sorter: (a, b) => a.updated_at - b.updated_at,
                    //     render: (text, record, index) =>
                    //     {
                    //         return moment(text).format('DD/MM/YYYY h:m:s');;
                    //     }
                    // },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.ShowDetail(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListRegion } = this.state;

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        }, () =>
        {
            this.setState(state =>
            {
                return {
                    isNewList: false,
                }
            });

        })
    }
    editRows = (record) =>
    {


        this.setState(state =>
        {
            return {
                record: record,

            }
        }, () =>
        {

        })
        this.formRef.current.setFieldsValue({ ...record })

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", deleted: 0, hidden: 0 }
            }
        })
        this.formRef.current.resetFields();
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListRegionRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.ListRegionRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });

        const searchParams = new URLSearchParams({ s: values.search }).toString();

        this.props.history.push(`${LINK.WEB_REGION}?${searchParams}`);

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e ? 1 : 0 }
     
        this.SetisLoading(true)
        this.props.StoreRegionRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    // update_ = () =>
    // {
    //     const { record } = this.state;
    //     // console.log('====================================');
    //     // console.log(record);
    //     // console.log('====================================');
    //     // console.log('====================================');
    //     // console.log(checkedList);
    //     // console.log('====================================');
    //     // return;
    //     if (!record.name) {
    //         message.warn("Vui lòng nhập tên!");
    //         return;
    //     }

    //     record.hidden = 0
    //     this.SetisLoading(true)
    //     this.props.StoreRegionRequest(record, record.id ? record.id : "")

    // }
    editRowsMobile = (record) =>
    {


        this.props.history.push({
            pathname: LINK.WEB_REGION_EDIT + "/" + record.id,
        });
    }

    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreRegionRequest(record_, record_.id ? record_.id : "")

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content region_">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    {/* <h1 style={{ color: Colors.pop2[3] }}>Vùng</h1> */}
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Vùng</span>
                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList )&& <Link to={LINK.WEB_REGION_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {(Services.checkScreenSize().width >= 600 && this.state.isShowList) && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                          
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <div className="form__half row">
                            <div className={(Services.checkScreenSize().width < 600 || !this.state.isShowList) ? "left_half col-lg-12 col-md-12 col-sm-12 " : "left_half col-lg-7 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="Nhập tên danh mục" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>
                             
                                </div>
                                {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                                    updateRecord={this.updateRecord}
                                    editRows={this.editRowsMobile}
                                    deleteModal={this.deleteModal}
                                    duplicateItem={this.duplicateItem}
                                    current_page={this.state.meta.current_page}
                                    per_page={this.state.meta.per_page}
                                    last_page={this.state.meta.last_page}
                                    OnPagination={this.OnPagination}
                                    isNewList={this.state.isNewList}
                                />}
                                {Services.checkScreenSize().width >= 600 && this.state.isShowList &&  <> <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                    rowSelection={{
                                        ...this.state.rowSelection,
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                    scroll={{

                                        y: "60vh",
                                    }}
                                    style={{ paddingBottom: "100px" }}
                                />
                                <div className="pagination_">
                                    <Pagination total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                </div>
                                </>
                                }
                            </div>
                            {Services.checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">

                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên thuộc tính là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code" rules={[
                                        {
                                            required: true,
                                            message: "Code là bắt buộc"
                                        },
                                    ]} >
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>

                                    <Form.Item >
                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </Form>
                         
                            </div>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListRegionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
        StoreRegionRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreRegionRequest(data, id));
        },
        ResetStoreRegion: () =>
        {
            dispatch(ActionsWeb.ResetStoreRegion());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);