
import * as  actionsWeb from "./../actions/indexWeb";
export const TableList = {
    status_transport:{
        list: "ListStatusTransportRequest",
        reduce:"ListStatusTransport",
        value:"id",
        label: "name"
    }

}