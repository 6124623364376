import React, { Component } from "react";
import { connect } from "react-redux";
import * as ActionsLogin from "./../../actions/login";
import Loading from "./../../components/loading/Loading";
import "./style.css";
import * as LINK from "./../../constants/Links";
import { setCookie } from "./../../service"
import * as ActionsWeb from "../../actions/indexWeb";

import
{
  Button,
  Form,
  Input,
  message
} from 'antd';
class Login extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      Login: '',
      user: {
        user_name: "",
        password: ""
      },
      loading: false,
      SettingsShowByCode:{},
      set_:{},
      settings:{}
    };
  }

  componentDidMount()
  {
    this.props.SettingsShowByCodeRequest({ code: "ADMIN" })

  }
  static getDerivedStateFromProps(nextProps, prevState)
  {
    if (nextProps.Web.SettingsShowByCode && nextProps.Web.SettingsShowByCode?.code !== prevState.SettingsShowByCode?.code) {
    
      if (nextProps.Web.SettingsShowByCode?.data?.code === "ADMIN") {
        const datasetting = JSON.parse(nextProps.Web.SettingsShowByCode?.data?.data);
       
        localStorage.setItem("settings", JSON.stringify(datasetting));
        return {
          SettingsShowByCode: nextProps.Web.SettingsShowByCode,
          set_: datasetting,
          settings: datasetting

        };
      }
    

    }

    if (nextProps.Web.Login && nextProps.Web.Login !== prevState.Login) {

      return { Login: nextProps.Web.Login };

    }
    else {
      return null;
    }
  }
  componentDidUpdate(prevProps, prevState)
  {

    if (this.state.Login !== prevState.Login) {
      let { Login } = this.state
     
      if (Login.code === 200) {
        this.props.SetUserRequest(Login.user)
        setCookie("user", JSON.stringify(Login.user), "");
        setCookie("acccess_token", Login.acccess_token, "",)
        window.location = LINK.WEB;
      }
      else {
        if (Login.message) {
          if (typeof Login.message == "object") {
            Object.values(Login.message).map((item, index) =>
            {
              message.warning(item);

            });
          } else {
            message.warning(Login.message);

          }

        }

      }
      this.props.ResetLogin();
      this.SetLoading();
    }
  }
  SetLoading = () =>
  {
    this.setState(state =>
    {
      return {
        loading: !state.loading
      }
    })
  }


  OnchangeLogin = (event) =>
  {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState(state =>
    {
      return {
        user: { ...state.user, [name]: value }
      }
    })
  }

  Login = (values) =>
  {
   
    this.SetLoading();
    this.props.Login(values)

  }
  OnkeyUpLogin = (event) =>
  {

    if (event.keyCode === 13) {
      this.Login();
    }
  }
  render()
  {
    console.log("settingssettings",this.state.settings);

    return (
      <div className="login-select-chat">
        <div className="login-form-chat">
          <div className="content-login-chat">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="wrap">
                    <div className="img" >
                      {this.state.settings && this.state.settings.logo &&  <img src={`${LINK.myHostimgServerImg}/${this.state.settings.logo}`} alt="" /> }
                      {!this.state.settings || !this.state.settings?.logo &&     <img src={`${LINK.myHostimg}/logorim.png`} alt="" />}
                   

                    </div>
                    <div className="login-wrap">
                      <Form
                        name="basic"
                       
                      
                        onFinish={this.Login}
                        
                        autoComplete="off"
                      >
                        <Form.Item
                          // label="Tên đăng nhập"
                          name="user_name"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập tên đăng nhập!',
                            },
                          ]}
                        >
                          <Input placeholder="Tên đăng nhập" />
                        </Form.Item>

                        <Form.Item
                          // label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập password!',
                            },
                          ]}
                        >
                          <Input.Password placeholder="password" />
                        </Form.Item>


                        <Form.Item
                         
                        >
                          <Button type="primary" className="rounded submit px-3" style={{width:"100%"}} htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>

                        {this.state.loading && <div className="form-group">
                          <Loading />
                        </div>}
                      </Form>
                      {/* <form action="#" className="signin-form">
                        <div className="form-group mt-4">
                          <label className="form-control-placeholder" htmlFor="username">Tài khoản</label>
                          <input type="text" name="user_name" className="form-control" required value={this.state.user.user_name} onChange={this.OnchangeLogin} onKeyUp={this.OnkeyUpLogin} />
                        </div>
                        <div className="form-group mt-4">
                          <label className="form-control-placeholder" htmlFor="password">Mật khẩu</label>
                          <input id="password" type="password" name="password" className="form-control" required value={this.state.user.password} onChange={this.OnchangeLogin} onKeyUp={this.OnkeyUpLogin} />
                        </div>
                        <div className="form-group">
                          <button type="button" className="form-control btn btn-primary rounded submit px-3" onClick={this.Login} >ĐĂNG NHẬP</button>

                        </div>
                        {this.state.loading && <div className="form-group">
                          <Loading />
                        </div>}
                      </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) =>
{
  return {
    Web: state.Web
  };
};
const mapDispatchToProps = (dispatch, props) =>
{
  return {
    Login: (data) =>
    {
      dispatch(ActionsLogin.LoginRequest(data));
    },
    ResetLogin: () =>
    {
      dispatch(ActionsLogin.ResetLogin());
    },
    SetUserRequest: () =>
    {
      dispatch(ActionsLogin.SetUserRequest());
    },
    SettingsShowByCodeRequest: (data) =>
      {
        dispatch(ActionsWeb.SettingsShowByCodeRequest(data));
      },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
