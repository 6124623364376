import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "./../../../../constants/Colors";
import * as Services from "./../../../../service";
import Upload from "./Upload";

import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,TreeSelect,InputNumber

} from 'antd';
import
{
    FileImageOutlined,
   
} from '@ant-design/icons';
const { Option } = Select;


class Index extends Component
{

    constructor(props)
    {
        super(props);


        this.state = {
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                name: "",
                parent_id: 1,
                slug: "",
                hidden: "1",
                deleted: 0
            },
            StoreCategory: {},
            ListCategory: [],
            data: [],
            isloading: false,
            imgesInfoRemove: [],
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {
            return {
                record: nextProps.history.location.state,

            };

        } 
         if (nextProps.Web.ListCategory && nextProps.Web.ListCategory !== prevState.ListCategory) {
            return {

                ListCategory: nextProps.Web.ListCategory,

            };

        } 
         if (nextProps.Web.StoreCategory && nextProps.Web.StoreCategory !== prevState.StoreCategory) {
            return {
                StoreCategory: nextProps.Web.StoreCategory,

            };

        } 
        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {

          
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
            return null;
        


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreCategory !== prevState.StoreCategory) {
            let { StoreCategory } = this.state;
            if (StoreCategory.code === 200) {
                message.success(StoreCategory.message)
            }
            else {
                if (StoreCategory.message) {
                    message.error(StoreCategory.message)
                }

            }
            this.props.ResetStoreCategory()
            this.SetisLoading(false)
        }

        if (this.state.ListCategory !== prevState.ListCategory) {
            let { ListCategory } = this.state

            this.data();
        }
        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;
            console.log("UploadFileLaUploadFileLaUploadFileLa", UploadFileLa);
            if (parseInt(UploadFileLa.type) === 1) {
                if (UploadFileLa.code !== 200) {

                    message.error("Cập nhật ảnh chính thất bại!");
                    // return;
                }

                this.setState(state => {
                    return {
                        record: { ...state.record, image: UploadFileLa.data[0] }
                    }
                }, () => {
                    if (this.state.isupload === 1) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }

            this.props.ResetUploadFileLa();
        }
    }
    componentDidMount = () =>
    {
        this.props.ListCategoryRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        return;
        if (!this.props.Web.ListCategory.data) {

            this.props.ListCategoryRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
        }
        else {
            this.setState(state =>
            {
                return {
                    ListCategory: this.props.Web.ListCategory
                }
            }, () => { this.data(); })

        }
    }
    uploadFile = (values) => {
        const { imgesInfo, } = this.state;


        this.setState(state => {
            return {
                required: {},
                values: values
            }
        })
        // if (!this.CheckDataRequired()) {
        //     return;
        // }

        this.SetisLoading(true)
        let isupload = 0;
        // this.SetisLoading(true)
        if (imgesInfo.avata.length > 0) {
            let arravata = imgesInfo.avata.filter(item => {
                return item.originFileObj;
            })
            if (arravata.length > 0) {
                this.props.UploadFileLaRequest(arravata, "img", 1);
                isupload = 1;
            }

        }

      //  console.log("nextProps.Web.UploadFileLanextProps.Web.UploadFileLa",nextProps.Web.UploadFileLa);
        this.setState(state => {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    setimgesInfo = (type, data) => {


        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state => {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],
                    // imgesInfo: {
                    //     ...state.imgesInfo, [type]: data
                    // },

                }
            }, () => {
                this.setState(state => {

                    return {
                        // imgesInfoRemove: [...state.imgesInfoRemove, ...state.imgesInfo[type]],
                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })


            // imageDelete["folder"] = type;

        } else {
            this.setState(state => {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) => {
        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item => {
                return item !== data.name;
            })
            imagesrecord = this.state.record.images.filter(item => {
                return item !== data.name;
            })
            this.setState(state => {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state => {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }
    data = () =>
    {
        let { ListCategory } = this.state;
        // if (!ListCategory) {
        //     ListCategory = this.props.Web.ListCategory
        // }

        if (ListCategory.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListCategory.current_page,
            first_page_url: ListCategory.first_page_url,
            from: ListCategory.from,
            last_page: ListCategory.last_page,
            last_page_url: ListCategory.last_page_url,
            links: ListCategory.links,
            next_page_url: ListCategory.next_page_url,
            path: ListCategory.path,
            per_page: ListCategory.per_page,
            prev_page_url: ListCategory.prev_page_url,
            to: ListCategory.to,
            total: ListCategory.total,
        };
        ListCategory.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    Store = () =>
    {
        let { record } = this.state;
        if (!record.name) {
            message.error("Vui lòng nhập tên");
            return;
        }
        this.SetisLoading(true)
        this.props.StoreCategoryRequest(record, record.id ? record.id : "")
    }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <h1>Chi tiết danh mục</h1>
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        // span: 14,
                    }}
                    layout="horizontal"
                >

                    <div className="section_ section_img">
                        <h3 style={{ color: Colors.pop2[3] }}>Thông tin cơ bản</h3>
                        <Form name="formadd" onFinish={this.uploadFile} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Form.Item label="Danh mục" name={"parent_id"}

                                        rules={[
                                            {
                                                required: true,
                                                message: "Danh mục là bắt buộc"
                                            },
                                        ]}>
                                        <TreeSelect
                                            showSearch
                                            style={{ width: '100%' }}
                                            treeData={this.state.ListCategory}

                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="Danh mục"
                                            // allowClear
                                            // multiple
                                            treeDefaultExpandAll


                                        />

                                    </Form.Item>
                                    <Form.Item label="Tên"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Tên danh mục là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Input name="name" id="name" placeholder="Tên danh mục" onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ slug: Services.createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Slug"
                                        name="slug"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Slug là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Input name="slug" id="slug" placeholder="slug" />
                                    </Form.Item>
                                    <Form.Item label="Vị trí hiển thị"
                                        name="position"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vị trí là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: "100%", }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={[{value:0,label:"Đầu trang"},{value:1,label:"Chân trang"}]}
                                        
                                           
                                        />
                                    </Form.Item>

                                    <Form.Item label="Loại"
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Loại là bắt buộc"
                                            },
                                        ]}
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: "100%", }}
                                            showArrow={true}
                                            defaultActiveFirstOption={true}
                                            options={[{ value: 0, label: "Sản phẩm" }, { value: 1, label: "Bài viết" }]}
                                          

                                        />
                                    </Form.Item>
                                    <Form.Item label="Sắp xếp"
                                            name="rank"
                                           
                                        >

                                            <InputNumber name="Sắp xếp" id="Sắp xếp" placeholder="Sắp xếp" />
                                        </Form.Item>
                                        <Form.Item label="Hình ảnh">
                                            <Upload isCropImg={false} multiple={false} max={1} type_={"avata"} type_f={1} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} fileList={this.state.imgesInfo.avata} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} />
                                        </Form.Item>
                                    <Form.Item >
                                        <div className="flex_">
                                                <Button type="default" style={{ marginRight: "10px" }} danger onClick={() => { }}>Hủy</Button>

                                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit"  >Cập nhật</Button>
                                            </div>
                                    </Form.Item>
                                </Form>


                    </div>

                    <Form.Item >

                        <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                        <Button type="primary" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={this.Store}>Cập nhật</Button>
                    </Form.Item>
                </Form >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreCategoryRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreCategoryRequest(data, id));
        },
        ResetStoreCategory: () =>
        {
            dispatch(ActionsWeb.ResetStoreCategory());
        },
        ListCategoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);