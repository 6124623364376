import
{
    AppstoreOutlined,
    ContainerOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    DesktopOutlined, MenuUnfoldOutlined,
    MailOutlined,
    AppstoreAddOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    SmileOutlined,
    HeartOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    PlusOutlined,
    MinusOutlined,
    LoadingOutlined,
    LockOutlined,
    UnlockOutlined,
    UserOutlined,
    TeamOutlined,
    MenuOutlined,
    HomeOutlined,
    DashboardOutlined,
    CalendarOutlined,
    SearchOutlined,
    SettingOutlined,
    ToolOutlined,
    TagOutlined,
    TagsOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    CloudOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    PrinterOutlined,
    InboxOutlined,
    RocketOutlined,
    BugOutlined,
    AlertOutlined,
    ApiOutlined,
    ExperimentOutlined,
    FieldTimeOutlined,
    GlobalOutlined,
    HourglassOutlined,
    LikeOutlined,
    PhoneOutlined,
    QrcodeOutlined,
    ShareAltOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    StarOutlined,
    TrophyOutlined,
    UnlockFilled,
    UsbOutlined,
    VideoCameraOutlined,
    WalletOutlined,
    WifiOutlined,
    WindowsOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    ApartmentOutlined,
    AudioOutlined,
    BankOutlined,
    BarcodeOutlined,
    BellOutlined,
    BookOutlined,
    BoxPlotOutlined,
    BuildOutlined,
    BulbOutlined,
    CalculatorOutlined,
    CameraOutlined,
    CarOutlined,
    CarryOutOutlined,
    CloudServerOutlined,
    CodeOutlined,
    CompassOutlined,
    ContactsOutlined,
    ControlOutlined,
    CreditCardOutlined,
    CrownOutlined,
    CustomerServiceOutlined,
    DashboardFilled,
    DatabaseOutlined,
    DiffOutlined,
    DislikeOutlined,
    DollarCircleOutlined,
    EnvironmentOutlined,
    ExperimentFilled,
    EyeFilled,
    FilterOutlined,
    FireOutlined,
    FundFilled,
    GiftOutlined,
    HddOutlined,
    HeatMapOutlined,
    InsuranceOutlined,
    InteractionOutlined,
    KeyOutlined,
    LaptopOutlined,
    LayoutOutlined,
    LinkOutlined,
    Loading3QuartersOutlined,
    LockFilled,
    MedicineBoxOutlined,
    MehOutlined,
    MessageOutlined,
    MobileOutlined,
    NotificationOutlined,
    PercentageOutlined,
    PictureOutlined,
    PlaySquareOutlined,
    PoundCircleOutlined,
    PoweroffOutlined,
    RadarChartOutlined,
    RedEnvelopeOutlined,
    RestOutlined,
    SafetyCertificateOutlined,
    ScheduleOutlined,
    SecurityScanOutlined,
    ShopFilled,
    SmileFilled,
    SoundOutlined,
    StarFilled,
    SyncOutlined,
    ThunderboltOutlined,
    AimOutlined,
    AlertFilled,
    ApiFilled,
    AppleOutlined,
    AudioFilled,
    AudioMutedOutlined,
    BackwardFilled,
    BankFilled,
    BehanceCircleFilled,
    BehanceSquareFilled,
    BellFilled,
    BlockOutlined,
    BoldOutlined,
    BorderBottomOutlined,
    BorderHorizontalOutlined,
    BorderInnerOutlined,
    BorderLeftOutlined,
    BorderOuterOutlined,
    BorderRightOutlined,
    BorderTopOutlined,
    BorderVerticleOutlined,
    BoxPlotFilled,
    BranchesOutlined,
    BugFilled,
    BuildFilled,
    BulbFilled,
    CalculatorFilled,
    CalendarFilled,
    CameraFilled,
    CarFilled,
    CarryOutFilled,
    CheckCircleFilled,
    CheckSquareOutlined,
    ChromeOutlined,
    CiCircleFilled,
    ClearOutlined,
    ClockCircleOutlined,
    CloseCircleFilled,
    CloseSquareOutlined,
    CloudFilled,
    CloudSyncOutlined,
    ClusterOutlined,
    CodeFilled,
    CoffeeOutlined,
    CommentOutlined,
    CompassFilled,
    CompressOutlined,
    ConsoleSqlOutlined,
    ContactsFilled,
    ContainerFilled,
    ControlFilled,
    CopyFilled,
    CreditCardFilled,
    CrownFilled,
    CustomerServiceFilled,
    DashOutlined,
    DatabaseFilled,
    DeleteColumnOutlined,
    DeleteFilled,
    DeleteOutlined,
    DeleteRowOutlined,
    DeliveredProcedureOutlined,
    DeploymentUnitOutlined,
    DiffFilled,
    DingtalkOutlined,
    DingtalkSquareFilled,
    DisconnectOutlined,
    DislikeFilled,
    DollarOutlined,
    DotChartOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    DownCircleFilled,
    DownCircleOutlined,
    DownOutlined,
    DownloadOutlined,
    DragOutlined,
    DribbbleCircleFilled,
    DribbbleSquareFilled,
    DropboxOutlined,
    EditFilled,
    EllipsisOutlined,
    EnterOutlined,
    EnvironmentFilled,
    EuroCircleFilled,
    EuroOutlined,
    ExceptionOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    ExclamationOutlined,
    ExpandAltOutlined,
    ExpandOutlined,
    ExportOutlined,
    EyeInvisibleFilled,
    FacebookFilled,
    FallOutlined,
    FastBackwardFilled,
    FastForwardFilled,
    FieldBinaryOutlined,
    FieldStringOutlined,
    FileAddFilled,
    FileDoneOutlined,
    FileExcelFilled,
    FileExclamationOutlined,
    FileFilled,
    FileGifOutlined,
    FileImageFilled,
    FileJpgOutlined,
    FileMarkdownFilled,
    FilePdfFilled,
    FilePptFilled,
    FileProtectOutlined,
    FileSearchOutlined,
    FileSyncOutlined,
    FileTextFilled,
    FileUnknownFilled,
    FileUnknownOutlined,
    FileWordFilled,
    FileZipFilled,
    FilterFilled,
    FireFilled,
    FlagFilled,
    FolderAddFilled,
    FolderFilled,
    FolderOpenFilled,
    FolderOutlined,
    FolderViewOutlined,
    ForkOutlined,
    FormatPainterFilled,
    FrownFilled,
    FunctionOutlined,
    FundOutlined,
    FundProjectionScreenOutlined,
    FundViewOutlined,
    FunnelPlotFilled,
    GatewayOutlined,
    GifOutlined,
    GiftFilled,
    GithubFilled,
    GitlabFilled,
    GoldFilled,
    GoogleCircleFilled,
    GooglePlusCircleFilled,
    GooglePlusSquareFilled,
    GoogleSquareFilled,
    GroupOutlined,
    HddFilled,
    HeartFilled,
    FullscreenExitOutlined,
    NodeExpandOutlined,
    ImportOutlined


} from '@ant-design/icons';
export const Icons = [
    { name: "AppstoreOutlined", icon: AppstoreOutlined },
    { name: "ContainerOutlined", icon: ContainerOutlined },
    { name: "MenuFoldOutlined", icon: MenuFoldOutlined },
    { name: "PieChartOutlined", icon: PieChartOutlined },
    { name: "DesktopOutlined", icon: DesktopOutlined },
    { name: "MenuUnfoldOutlined", icon: MenuUnfoldOutlined },
    { name: "MailOutlined", icon: MailOutlined },
    { name: "AppstoreAddOutlined", icon: AppstoreAddOutlined },
    { name: "StepBackwardOutlined", icon: StepBackwardOutlined },
    { name: "StepForwardOutlined", icon: StepForwardOutlined },
    { name: "SmileOutlined", icon: SmileOutlined },
    { name: "HeartOutlined", icon: HeartOutlined },
    { name: "CheckCircleOutlined", icon: CheckCircleOutlined },
    { name: "CloseCircleOutlined", icon: CloseCircleOutlined },
    { name: "EditOutlined", icon: EditOutlined },
    { name: "PlusOutlined", icon: PlusOutlined },
    { name: "MinusOutlined", icon: MinusOutlined },
    { name: "LoadingOutlined", icon: LoadingOutlined },
    { name: "LockOutlined", icon: LockOutlined },
    { name: "UnlockOutlined", icon: UnlockOutlined },
    { name: "UserOutlined", icon: UserOutlined },
    { name: "TeamOutlined", icon: TeamOutlined },
    { name: "MenuOutlined", icon: MenuOutlined },
    { name: "HomeOutlined", icon: HomeOutlined },
    { name: "DashboardOutlined", icon: DashboardOutlined },
    { name: "CalendarOutlined", icon: CalendarOutlined },
    { name: "SearchOutlined", icon: SearchOutlined },
    { name: "SettingOutlined", icon: SettingOutlined },
    { name: "ToolOutlined", icon: ToolOutlined },
    { name: "TagOutlined", icon: TagOutlined },
    { name: "TagsOutlined", icon: TagsOutlined },
    { name: "CloudUploadOutlined", icon: CloudUploadOutlined },
    { name: "CloudDownloadOutlined", icon: CloudDownloadOutlined },
    { name: "CloudOutlined", icon: CloudOutlined },
    { name: "EyeOutlined", icon: EyeOutlined },
    { name: "EyeInvisibleOutlined", icon: EyeInvisibleOutlined },
    { name: "PrinterOutlined", icon: PrinterOutlined },
    { name: "InboxOutlined", icon: InboxOutlined },
    { name: "RocketOutlined", icon: RocketOutlined },
    { name: "BugOutlined", icon: BugOutlined },
    { name: "AlertOutlined", icon: AlertOutlined },
    { name: "ApiOutlined", icon: ApiOutlined },
    { name: "ExperimentOutlined", icon: ExperimentOutlined },
    { name: "FieldTimeOutlined", icon: FieldTimeOutlined },
    { name: "GlobalOutlined", icon: GlobalOutlined },
    { name: "HourglassOutlined", icon: HourglassOutlined },
    { name: "LikeOutlined", icon: LikeOutlined },
    { name: "PhoneOutlined", icon: PhoneOutlined },
    { name: "QrcodeOutlined", icon: QrcodeOutlined },
    { name: "ShareAltOutlined", icon: ShareAltOutlined },
    { name: "ShopOutlined", icon: ShopOutlined },
    { name: "ShoppingCartOutlined", icon: ShoppingCartOutlined },
    { name: "StarOutlined", icon: StarOutlined },
    { name: "TrophyOutlined", icon: TrophyOutlined },
    { name: "UnlockFilled", icon: UnlockFilled },
    { name: "UsbOutlined", icon: UsbOutlined },
    { name: "VideoCameraOutlined", icon: VideoCameraOutlined },
    { name: "WalletOutlined", icon: WalletOutlined },
    { name: "WifiOutlined", icon: WifiOutlined },
    { name: "WindowsOutlined", icon: WindowsOutlined },
    { name: "ZoomInOutlined", icon: ZoomInOutlined },
    { name: "ZoomOutOutlined", icon: ZoomOutOutlined },
    { name: "ApartmentOutlined", icon: ApartmentOutlined },
    { name: "AudioOutlined", icon: AudioOutlined },
    { name: "BankOutlined", icon: BankOutlined },
    { name: "BarcodeOutlined", icon: BarcodeOutlined },
    { name: "BellOutlined", icon: BellOutlined },
    { name: "BookOutlined", icon: BookOutlined },
    { name: "BoxPlotOutlined", icon: BoxPlotOutlined },
    { name: "BuildOutlined", icon: BuildOutlined },
    { name: "BulbOutlined", icon: BulbOutlined },
    { name: "CalculatorOutlined", icon: CalculatorOutlined },
    { name: "CameraOutlined", icon: CameraOutlined },
    { name: "CarOutlined", icon: CarOutlined },
    { name: "CarryOutOutlined", icon: CarryOutOutlined },
    { name: "CloudServerOutlined", icon: CloudServerOutlined },
    { name: "CodeOutlined", icon: CodeOutlined },
    { name: "CompassOutlined", icon: CompassOutlined },
    { name: "ContactsOutlined", icon: ContactsOutlined },
    { name: "ControlOutlined", icon: ControlOutlined },
    { name: "CreditCardOutlined", icon: CreditCardOutlined },
    { name: "CrownOutlined", icon: CrownOutlined },
    { name: "CustomerServiceOutlined", icon: CustomerServiceOutlined },
    { name: "DashboardFilled", icon: DashboardFilled },
    { name: "DatabaseOutlined", icon: DatabaseOutlined },
    { name: "DiffOutlined", icon: DiffOutlined },
    { name: "DislikeOutlined", icon: DislikeOutlined },
    { name: "DollarCircleOutlined", icon: DollarCircleOutlined },
    { name: "EnvironmentOutlined", icon: EnvironmentOutlined },
    { name: "ExperimentFilled", icon: ExperimentFilled },
    { name: "EyeFilled", icon: EyeFilled },
    { name: "FilterOutlined", icon: FilterOutlined },
    { name: "FireOutlined", icon: FireOutlined },
    { name: "FundFilled", icon: FundFilled },
    { name: "GiftOutlined", icon: GiftOutlined },
    { name: "HddOutlined", icon: HddOutlined },
    { name: "HeatMapOutlined", icon: HeatMapOutlined },
    { name: "InsuranceOutlined", icon: InsuranceOutlined },
    { name: "InteractionOutlined", icon: InteractionOutlined },
    { name: "KeyOutlined", icon: KeyOutlined },
    { name: "LaptopOutlined", icon: LaptopOutlined },
    { name: "LayoutOutlined", icon: LayoutOutlined },
    { name: "LinkOutlined", icon: LinkOutlined },
    { name: "Loading3QuartersOutlined", icon: Loading3QuartersOutlined },
    { name: "LockFilled", icon: LockFilled },
    { name: "MedicineBoxOutlined", icon: MedicineBoxOutlined },
    { name: "MehOutlined", icon: MehOutlined },
    { name: "MessageOutlined", icon: MessageOutlined },
    { name: "MobileOutlined", icon: MobileOutlined },
    { name: "NotificationOutlined", icon: NotificationOutlined },
    { name: "PercentageOutlined", icon: PercentageOutlined },
    { name: "PictureOutlined", icon: PictureOutlined },
    { name: "PlaySquareOutlined", icon: PlaySquareOutlined },
    { name: "PoundCircleOutlined", icon: PoundCircleOutlined },
    { name: "PoweroffOutlined", icon: PoweroffOutlined },
    { name: "RadarChartOutlined", icon: RadarChartOutlined },
    { name: "RedEnvelopeOutlined", icon: RedEnvelopeOutlined },
    { name: "RestOutlined", icon: RestOutlined },
    { name: "SafetyCertificateOutlined", icon: SafetyCertificateOutlined },
    { name: "ScheduleOutlined", icon: ScheduleOutlined },
    { name: "SecurityScanOutlined", icon: SecurityScanOutlined },
    { name: "ShopFilled", icon: ShopFilled },
    { name: "SmileFilled", icon: SmileFilled },
    { name: "SoundOutlined", icon: SoundOutlined },
    { name: "StarFilled", icon: StarFilled },
    { name: "SyncOutlined", icon: SyncOutlined },
    { name: "ThunderboltOutlined", icon: ThunderboltOutlined },
    { name: "AimOutlined", icon: AimOutlined },
    { name: "AlertFilled", icon: AlertFilled },
    { name: "ApiFilled", icon: ApiFilled },
    { name: "AppleOutlined", icon: AppleOutlined },
    { name: "AudioFilled", icon: AudioFilled },
    { name: "AudioMutedOutlined", icon: AudioMutedOutlined },
    { name: "BackwardFilled", icon: BackwardFilled },
    { name: "BankFilled", icon: BankFilled },
    { name: "BehanceCircleFilled", icon: BehanceCircleFilled },
    { name: "BehanceSquareFilled", icon: BehanceSquareFilled },
    { name: "BellFilled", icon: BellFilled },
    { name: "BlockOutlined", icon: BlockOutlined },
    { name: "BoldOutlined", icon: BoldOutlined },
    { name: "BorderBottomOutlined", icon: BorderBottomOutlined },
    { name: "BorderHorizontalOutlined", icon: BorderHorizontalOutlined },
    { name: "BorderInnerOutlined", icon: BorderInnerOutlined },
    { name: "BorderLeftOutlined", icon: BorderLeftOutlined },
    { name: "BorderOuterOutlined", icon: BorderOuterOutlined },
    { name: "BorderRightOutlined", icon: BorderRightOutlined },
    { name: "BorderTopOutlined", icon: BorderTopOutlined },
    { name: "BorderVerticleOutlined", icon: BorderVerticleOutlined },
    { name: "BoxPlotFilled", icon: BoxPlotFilled },
    { name: "BranchesOutlined", icon: BranchesOutlined },
    { name: "BugFilled", icon: BugFilled },
    { name: "BuildFilled", icon: BuildFilled },
    { name: "BulbFilled", icon: BulbFilled },
    { name: "CalculatorFilled", icon: CalculatorFilled },
    { name: "CalendarFilled", icon: CalendarFilled },
    { name: "CameraFilled", icon: CameraFilled },
    { name: "CarFilled", icon: CarFilled },
    { name: "CarryOutFilled", icon: CarryOutFilled },
    { name: "CheckCircleFilled", icon: CheckCircleFilled },
    { name: "CheckSquareOutlined", icon: CheckSquareOutlined },
    { name: "ChromeOutlined", icon: ChromeOutlined },
    { name: "CiCircleFilled", icon: CiCircleFilled },
    { name: "ClearOutlined", icon: ClearOutlined },
    { name: "ClockCircleOutlined", icon: ClockCircleOutlined },
    { name: "CloseCircleFilled", icon: CloseCircleFilled },
    { name: "CloseSquareOutlined", icon: CloseSquareOutlined },
    { name: "CloudFilled", icon: CloudFilled },
    { name: "CloudSyncOutlined", icon: CloudSyncOutlined },
    { name: "ClusterOutlined", icon: ClusterOutlined },
    { name: "CodeFilled", icon: CodeFilled },
    { name: "CoffeeOutlined", icon: CoffeeOutlined },
    { name: "CommentOutlined", icon: CommentOutlined },
    { name: "CompassFilled", icon: CompassFilled },
    { name: "CompressOutlined", icon: CompressOutlined },
    { name: "ConsoleSqlOutlined", icon: ConsoleSqlOutlined },
    { name: "ContactsFilled", icon: ContactsFilled },
    { name: "ContainerFilled", icon: ContainerFilled },
    { name: "ControlFilled", icon: ControlFilled },
    { name: "CopyFilled", icon: CopyFilled },
    { name: "CreditCardFilled", icon: CreditCardFilled },
    { name: "CrownFilled", icon: CrownFilled },
    { name: "CustomerServiceFilled", icon: CustomerServiceFilled },
    { name: "DashOutlined", icon: DashOutlined },
    { name: "DatabaseFilled", icon: DatabaseFilled },
    { name: "DeleteColumnOutlined", icon: DeleteColumnOutlined },
    { name: "DeleteFilled", icon: DeleteFilled },
    { name: "DeleteOutlined", icon: DeleteOutlined },
    { name: "DeleteRowOutlined", icon: DeleteRowOutlined },
    { name: "DeliveredProcedureOutlined", icon: DeliveredProcedureOutlined },
    { name: "DeploymentUnitOutlined", icon: DeploymentUnitOutlined },
    { name: "DiffFilled", icon: DiffFilled },
    { name: "DingtalkOutlined", icon: DingtalkOutlined },
    { name: "DingtalkSquareFilled", icon: DingtalkSquareFilled },
    { name: "DisconnectOutlined", icon: DisconnectOutlined },
    { name: "DislikeFilled", icon: DislikeFilled },
    { name: "DollarOutlined", icon: DollarOutlined },
    { name: "DotChartOutlined", icon: DotChartOutlined },
    { name: "DoubleLeftOutlined", icon: DoubleLeftOutlined },
    { name: "DoubleRightOutlined", icon: DoubleRightOutlined },
    { name: "DownCircleFilled", icon: DownCircleFilled },
    { name: "DownCircleOutlined", icon: DownCircleOutlined },
    { name: "DownOutlined", icon: DownOutlined },
    { name: "DownloadOutlined", icon: DownloadOutlined },
    { name: "DragOutlined", icon: DragOutlined },
    { name: "DribbbleCircleFilled", icon: DribbbleCircleFilled },
    { name: "DribbbleSquareFilled", icon: DribbbleSquareFilled },
    { name: "DropboxOutlined", icon: DropboxOutlined },
    { name: "EditFilled", icon: EditFilled },
    { name: "EllipsisOutlined", icon: EllipsisOutlined },
    { name: "EnterOutlined", icon: EnterOutlined },
    { name: "EnvironmentFilled", icon: EnvironmentFilled },
    { name: "EuroCircleFilled", icon: EuroCircleFilled },
    { name: "EuroOutlined", icon: EuroOutlined },
    { name: "ExceptionOutlined", icon: ExceptionOutlined },
    { name: "ExclamationCircleFilled", icon: ExclamationCircleFilled },
    { name: "ExclamationCircleOutlined", icon: ExclamationCircleOutlined },
    { name: "ExclamationOutlined", icon: ExclamationOutlined },
    { name: "ExpandAltOutlined", icon: ExpandAltOutlined },
    { name: "ExpandOutlined", icon: ExpandOutlined },
    { name: "ExportOutlined", icon: ExportOutlined },
    { name: "EyeInvisibleFilled", icon: EyeInvisibleFilled },
    { name: "FacebookFilled", icon: FacebookFilled },
    { name: "FallOutlined", icon: FallOutlined },
    { name: "FastBackwardFilled", icon: FastBackwardFilled },
    { name: "FastForwardFilled", icon: FastForwardFilled },
    { name: "FieldBinaryOutlined", icon: FieldBinaryOutlined },
    { name: "FieldStringOutlined", icon: FieldStringOutlined },
    { name: "FileAddFilled", icon: FileAddFilled },
    { name: "FileDoneOutlined", icon: FileDoneOutlined },
    { name: "FileExcelFilled", icon: FileExcelFilled },
    { name: "FileExclamationOutlined", icon: FileExclamationOutlined },
    { name: "FileFilled", icon: FileFilled },
    { name: "FileGifOutlined", icon: FileGifOutlined },
    { name: "FileImageFilled", icon: FileImageFilled },
    { name: "FileJpgOutlined", icon: FileJpgOutlined },
    { name: "FileMarkdownFilled", icon: FileMarkdownFilled },
    { name: "FilePdfFilled", icon: FilePdfFilled },
    { name: "FilePptFilled", icon: FilePptFilled },
    { name: "FileProtectOutlined", icon: FileProtectOutlined },
    { name: "FileSearchOutlined", icon: FileSearchOutlined },
    { name: "FileSyncOutlined", icon: FileSyncOutlined },
    { name: "FileTextFilled", icon: FileTextFilled },
    { name: "FileUnknownFilled", icon: FileUnknownFilled },
    { name: "FileUnknownOutlined", icon: FileUnknownOutlined },
    { name: "FileWordFilled", icon: FileWordFilled },
    { name: "FileZipFilled", icon: FileZipFilled },
    { name: "FilterFilled", icon: FilterFilled },
    { name: "FireFilled", icon: FireFilled },
    { name: "FlagFilled", icon: FlagFilled },
    { name: "FolderAddFilled", icon: FolderAddFilled },
    { name: "FolderFilled", icon: FolderFilled },
    { name: "FolderOpenFilled", icon: FolderOpenFilled },
    { name: "FolderOutlined", icon: FolderOutlined },
    { name: "FolderViewOutlined", icon: FolderViewOutlined },
    { name: "ForkOutlined", icon: ForkOutlined },
    { name: "FormatPainterFilled", icon: FormatPainterFilled },
    { name: "FrownFilled", icon: FrownFilled },
    { name: "FunctionOutlined", icon: FunctionOutlined },
    { name: "FundOutlined", icon: FundOutlined },
    { name: "FundProjectionScreenOutlined", icon: FundProjectionScreenOutlined },
    { name: "FundViewOutlined", icon: FundViewOutlined },
    { name: "FunnelPlotFilled", icon: FunnelPlotFilled },
    { name: "GatewayOutlined", icon: GatewayOutlined },
    { name: "GifOutlined", icon: GifOutlined },
    { name: "GiftFilled", icon: GiftFilled },
    { name: "GithubFilled", icon: GithubFilled },
    { name: "GitlabFilled", icon: GitlabFilled },
    { name: "GoldFilled", icon: GoldFilled },
    { name: "GoogleCircleFilled", icon: GoogleCircleFilled },
    { name: "GooglePlusCircleFilled", icon: GooglePlusCircleFilled },
    { name: "GooglePlusSquareFilled", icon: GooglePlusSquareFilled },
    { name: "GoogleSquareFilled", icon: GoogleSquareFilled },
    { name: "GroupOutlined", icon: GroupOutlined },
    { name: "HddFilled", icon: HddFilled },
    { name: "HeartFilled", icon: HeartFilled },
    { name: "FullscreenExitOutlined", icon: FullscreenExitOutlined },
    { name: "NodeExpandOutlined", icon: NodeExpandOutlined },
    { name: "ImportOutlined", icon: ImportOutlined },
]

export const IconsFontawesome=[
    { name: "facebook", icon: 'fab fa-facebook' },
    { name: "facebook", icon: 'fab fa-facebook-f' },
    // { name: "facebook", icon: 'fab fa-square-facebook' },
    { name: "tiktok", icon: 'fab fa-tiktok' },
    { name: "instagram", icon: 'fab fa-instagram' },
    // { name: "instagram", icon: 'fab fa-square-instagram' },
    { name: "twitter", icon: 'fab fa-twitter' },
    // { name: "twitter", icon: 'fab fa-square-twitter' },
    { name: "pinterest", icon: 'fab fa-pinterest' },
    { name: "pinterest", icon: 'fab fa-pinterest-p' },
    // { name: "pinterest", icon: 'fab fa-square-pinterest' },

]

//  {icons.map(Icon => <Icon key={Icon} />)}