
import React, { useState } from 'react'
import { useRef } from "react";
import "./style.css";

import * as LINK from "./../../constants/Links";
import Lottie from 'react-lottie';
import  laodingBova from './../../assets/animates/laodingBova.json'
export const OverlayLoading = ({ style, size, color,className }) => {
  const chatlive_active=useRef(null)
  const renderSpin = () =>
  {
    const result = [];
    for (let i = 0; i < 12; i++) {

      result.push({
        size:
        {
          width: size?.size?.width ? size.size.width : "6px",
          height: size?.size?.height ? size.size.height : "30px"
        },
        color:color ?color : "#ffffff"
      })
    }
    return result;
  }
 const [isStopped, setIsStopped] = useState(false);
 const [isPaused, setIsPaused] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: laodingBova,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const buttonStyle = {
    display: 'block',
    margin: '10px auto'
  };
  return (
    <div id="overlay_loading" className={`top-0 ${className}`} style={style} >
      <div className="wrap_overlay_loading">
        <div className="spinner-wrap">
          <div style={{ display:"flex" }}>
              <Lottie options={defaultOptions}
                  height={400}
                  width={400}
                  isStopped={isStopped}
                  isPaused={isPaused}/>
                  {/* <span>Sofware</span> */}
            </div>
          {/* <div className="spinner" style={{ scale: 0.5 }}>
            {(renderSpin() || []).map((item, index) =>
            {

              return <i key={index} style={{ width: item.size.width, height: item.size.height, color: item.color }}></i>
            })}

          </div> */}

        </div>
      </div>
    </div>
  )
}
