import React, { Component, createRef } from "react";
import {Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import { Divider, Radio, Input, Table, Modal, message, Button, Form, Tooltip, Checkbox, Card } from 'antd';
import Draggable from 'react-draggable';
import
{
    FolderViewOutlined,
    DeleteOutlined, PlusCircleOutlined, AppstoreOutlined,
    EditOutlined, UnorderedListOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { checkScreenSize, getParamsUrl } from "../../../../service";
import IndexMobile from "./IndexMobile";
const CheckboxGroup = Checkbox.Group;

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListRole: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,
                    with: "20%",
                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreRole: {},
            isloading: false,
            visibledetail: false,
            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            disabled: false,
            ListFunct: [],
            checkedList: ["Dashboard"],
            indeterminate: false,
            checkAll: [],
            isLoadingSearch: false,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setCheckedList = (checkedList) =>
    {


        this.setState(state =>
        {
            return {
                checkedList: checkedList
            }
        })
    }
    setIndeterminate = (indeterminate) =>
    {
        this.setState(state =>
        {
            return {
                indeterminate: indeterminate
            }
        })
    }
    setCheckAll = (checkAll, id) =>
    {
        let newchecked = [];
        if (checkAll) {
            newchecked = [...this.state.checkAll, id];
        } else {
            newchecked = this.state.checkAll.filter(item =>
            {
                return item !== id
            })
        }

        this.setState(state =>
        {
            return {
                checkAll: newchecked
            }
        })
    }
    onCheckAllChange = (e, items, id) =>
    {
        const itemstmp = [];
        
        items.map((el, index) =>
        {
            itemstmp.push(el.route)
        });

        let newchecked = [];
        if (e.target.checked) {
            newchecked = [...this.state.checkedList, ...itemstmp];
        } else {
            newchecked = this.state.checkedList.filter(item =>
            {
                return !itemstmp.includes(item)
            })
        }
        this.setCheckedList(newchecked);
        // this.setIndeterminate(false);
        this.setCheckAll(e.target.checked, id);
    }
    onChangeGroup = (list, items, id) =>
    {
        
        this.setCheckAll(list.length === items.length, id);


    }
    updateItems = (el) =>
    {

        let newList = [];
        if (el.target.checked) {
            newList = [...this.state.checkedList, el.target.value];
        } else {
            newList = this.state.checkedList.filter(
                (listItem) => listItem !== el.target.value,
            );
        }

        this.setCheckedList(newList);
    };
    draggleRef = new createRef(null);
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type,
                // record: type?{ ...state.record, deleted: 0 }
            }
        })
    }
    setVisibleDetail = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: type,

            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);

        this.props.StoreRoleRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }

            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };

    handleOkDetail = () =>
    {
        this.setVisibleDetail(true);

    };

    handleCancelDetail = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        const urlParams = getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

        this.props.ListRoleRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });



        this.props.ListFunctRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListRole && nextProps.Web.ListRole !== prevState.ListRole) {
            console.log("Comment");


            return {
                ListRole: nextProps.Web.ListRole,

            };

        }
        if (nextProps.Web.StoreRole && nextProps.Web.StoreRole !== prevState.StoreRole) {
            return {
                StoreRole: nextProps.Web.StoreRole,

            };

        }
        if (nextProps.Web.ListFunct && nextProps.Web.ListFunct.data !== prevState.ListFunct) {


            return {
                ListFunct: nextProps.Web.ListFunct.data,

            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreRole !== prevState.StoreRole) {
            let { StoreRole } = this.state;
            try {
                if (StoreRole.code === 200) {
                    if (StoreRole.message && typeof StoreRole.message === "string") {
                        message.success(StoreRole.message)
                        // this.setState(state =>
                        // {
                        //     return {
                        //         record: {

                        //             hidden: 0,
                        //             deleted: 0
                        //         }
                        //     }
                        // })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

                    this.props.ListRoleRequest({
                        limit: this.state.meta.per_page * 1000,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    });

                }
                else {
                    if (StoreRole.message && typeof StoreRole.message === "string") {

                        message.error(StoreRole.message)
                    } else {
                        for (let x in StoreRole.message) {
                            let mess = StoreRole.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreRole()
            this.handleCancel();

        }
        if (this.state.ListRole !== prevState.ListRole) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    // {
                    //     title: 'STT',
                    //     dataIndex: 'key',
                    //     width: "5%",
                    //     align: "center"

                    // },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },



                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    {/* {record.parent_id === 0 && */}


                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    ShowDetail = (record) =>
    {
        this.handleOkDetail();
    }
    data = () =>
    {
        let { ListRole } = this.state;

        if (ListRole.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRole.current_page,
            first_page_url: ListRole.first_page_url,
            from: ListRole.from,
            last_page: ListRole.last_page,
            last_page_url: ListRole.last_page_url,
            links: ListRole.links,
            next_page_url: ListRole.next_page_url,
            path: ListRole.path,
            per_page: ListRole.per_page,
            prev_page_url: ListRole.prev_page_url,
            to: ListRole.to,
            total: ListRole.total,
        };
        ListRole.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = item.id
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })

        }

    }

    editRows = (record) =>
    {

        let { ListFunct } = this.state;

        let permissions = record.permissions ? JSON.parse(record.permissions) : [];
        let checkall = [];
        ListFunct.map((item, index) =>
        {
            if (item.children) {
                item.children.map((el, j) =>
                {
                    if (permissions.includes(el.route)) {
                        checkall.push(item.id)
                    }
                });

            }
        });
        // permissions.push("Dashboard")
        this.setState(state =>
        {
            return {
                record: record,
                checkedList: permissions,
                checkAll: checkall
            }
        })
        this.formRef.current.setFieldsValue({ ...record })
    }
    editRowsMobile = (record) =>
    {


        this.props.history.push({
            pathname: LINK.WEB_ROLE_EDIT + "/" + record.id,
        });

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", delete: 0 }
            }
        })
        this.formRef.current.resetFields();
    }
   
    onFinishForm = (values) =>
    {
        const { record, checkedList } = this.state;
        record.permissions = checkedList

        const record_ = { ...record, ...values }

        this.SetisLoading(true)
        this.props.StoreRoleRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1, permissions: JSON.parse(record.permissions) }

            }
        }, () =>
        {

            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteRoleRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListRoleRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.ListRoleRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });

        const searchParams = new URLSearchParams({ s: values.search }).toString();

        this.props.history.push(`${LINK.WEB_ROLE}?${searchParams}`);

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })

    }
    render()
    {

        return (
            <div className="main_content">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>

                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            CHi tiết
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[
                        <Button key="back" onClick={this.handleCancelDetail}>
                            Hủy
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOkDetail}>
                            Áp dụng
                        </Button>,
                    ]}
                >
                    <div className="modal_content_trans">

                        <div className="right">
                            <p>Phí vận chuyển tạm tính (dựa trên khối lượng sản phẩm là 80g)</p>
                        </div>
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Roles</span>
                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <Link to={LINK.WEB_ROLE_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                           
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="form__half row">
                            <div className={(checkScreenSize().width < 600 || !this.state.isShowList) ? "left_half col-lg-12 col-md-12 col-sm-12 " : "left_half col-lg-7 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="Nhập tên roles" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>
                                    

                                </div>
                                {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                                    updateRecord={this.updateRecord}
                                    editRows={this.editRowsMobile}
                                    deleteModal={this.deleteModal}
                                    duplicateItem={this.duplicateItem}
                                    current_page={this.state.meta.current_page}
                                    per_page={this.state.meta.per_page}
                                    last_page={this.state.meta.last_page}
                                    OnPagination={this.OnPagination}
                                    isNewList={this.state.isNewList}
                                    setIsNewList={() =>
                                    {
                                        this.setState(state =>
                                        {
                                            return {
                                                isNewList: !state.isNewList
                                            }
                                        })
                                    }}
                                />}
                                {checkScreenSize().width >= 600 && this.state.isShowList && <>  <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                 
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                   
                                    style={{ paddingBottom: "30px" }}
                                />
                             
                                </>
    }
                            </div>

                            {checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">
                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Input name="title" id="title" hidden />
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên roles là bắt buộc"
                                        },
                                    ]}>

                                        <Input placeholder="Tên roles" />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code">

                                        <Input placeholder="code" />
                                    </Form.Item>

                                    <Form.Item label="Router" rules={[
                                        {
                                            required: true,
                                            message: "Route là bắt buộc"
                                        },
                                    ]}>

                                        {this.state.ListFunct && this.state.ListFunct.map((item, index) =>
                                        {
                                            if (item) {

                                                if (item.route === "Dashboard") {
                                                    return item ? <Checkbox
                                                        key={item.route}
                                                        value={item.route}
                                                        onChange={(event) => this.updateItems(event)}
                                                        checked
                                                        disabled
                                                    >{item.name}</Checkbox> : ""
                                                }
                                                else {
                                                    if (item.children){
                                                        return <div key={index} className="radio_group">
                                                            <div ><Checkbox style={{ color: Colors.color2, fontWeight: "bold" }} indeterminate={this.state.indeterminate}
                                                                onChange={(event) => this.onCheckAllChange(event, item.children, item.id)}
                                                                checked={this.state.checkAll.includes(item.id)}>{item.name}</Checkbox></div>
                                                            {/* <Radio.Group name="router" onChange={(e) => { this.onChangetext(e, "router", "record") }} value={this.state.record.router}> */}
                                                            <div style={{ marginLeft: "40px" }}>
                                                                <CheckboxGroup onChange={(list) => { this.onChangeGroup(list, item.children, item.id) }} value={this.state.checkedList}>
                                                                    {
                                                                        item.children && item.children.map((el, j) =>
                                                                        {
                                                                            return el ? <Checkbox
                                                                                key={j}
                                                                                value={el.route}
                                                                                onChange={(event) => this.updateItems(event)}>{el.name}</Checkbox> : ""
                                                                        })
                                                                    }
                                                                </CheckboxGroup>
                                                            </div>
                                                            {/* </Radio.Group> */}
                                                        </div>
                                                    }
                                                    
                                                }

                                            }
                                        })}



                                    </Form.Item>
                                    <Form.Item >

                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>

                                </Form>

                            </div>}
                        </div>

                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListRoleRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRoleRequest(body));
        },
        StoreRoleRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreRoleRequest(data, id));
        },
        ResetStoreRole: () =>
        {
            dispatch(ActionsWeb.ResetStoreRole());
        },
        ListFunctRequest: (body) =>
        {
            dispatch(ActionsWeb.ListFunctRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);