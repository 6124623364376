import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Tooltip, Card, Row, Col } from 'antd';

import
{
    FolderViewOutlined, UserSwitchOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { Scrollbar } from 'smooth-scrollbar-react';
import { META } from "../../../../utils/constant";
import { getParamsUrl } from "../../../../service";
const { Search } = Input;
const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.state = {

            ListCustomers: [],
            meta: {
                ...META
            },
            data: [],
            selectedRowKeys: [],
          
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Mã',
                    dataIndex: 'code',

                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.code - b.code,
                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Điện thoại',
                    dataIndex: 'phone',
                    sorter: (a, b) => a.phone - b.phone,

                },
                {
                    title: 'Địa chỉ',
                    dataIndex: 'address',
                    sorter: (a, b) => a.address - b.address,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreCustomers: {},
            search: {},
            isLoadingSearch: false,
          
            resetChecklist:false
        }

    }
  
    setSelectedRowKeys = (type)=>{
        this.setState(state=>{
            return{
                selectedRowKeys:type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreCustomersRequest(record, record.id ? record.id : "")
        // setTimeout(() =>
        // {

        // }, 2000);
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchCustomersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListCustomers && nextProps.Web.ListCustomers !== prevState.ListCustomers) {
            console.log("Comment", nextProps.Web.ListCustomers);


            return {
                ListCustomers: nextProps.Web.ListCustomers,
                isLoadingSearch: false
            };

        }
        if (nextProps.Web.StoreCustomers && nextProps.Web.StoreCustomers !== prevState.StoreCustomers) {
            return {
                StoreCustomers: nextProps.Web.StoreCustomers,

            };

        }
        if (nextProps.resetChecklist && nextProps.resetChecklist !== prevState.resetChecklist) {
            return {
                resetChecklist: nextProps.resetChecklist,

            };

        }
        
        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.resetChecklist !== prevState.resetChecklist) {
            if (this.state.resetChecklist){
                this.setState(state =>
                {
                    return {
                        selectedRowKeys: []
                    }
                })
                this.props.resetChekCus(false)
            }
           
        }
        if (this.state.StoreCustomers !== prevState.StoreCustomers) {
            let { StoreCustomers } = this.state;
            try {
                if (StoreCustomers.code === 200) {
                    if (StoreCustomers.message && typeof StoreCustomers.message === "string") {
                        message.success(StoreCustomers.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchCustomersRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreCustomers.message && typeof StoreCustomers.message === "string") {

                        message.error(StoreCustomers.message)
                    } else {
                        for (let x in StoreCustomers.message) {
                            let mess = StoreCustomers.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.setConfirmLoading(false);
            this.props.ResetStoreCustomers()
            this.handleCancel();
        }
        if (this.state.ListCustomers !== prevState.ListCustomers) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        with: "5%",
                        align: "center"

                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.name,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },

                    {
                        title: 'Điện thoại',
                        dataIndex: 'phone',
                        filters: filters.phone,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.phone - b.phone,

                    },

                    {
                        title: 'Địa chỉ',
                        dataIndex: 'address',
                        filters: filters.address,
                        onFilter: (value, record) =>
                        {
                            return record.address.startsWith(value)

                        },
                        sorter: (a, b) => a.address - b.address,

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        try {


            let { ListCustomers } = this.state;

            if (ListCustomers.length <= 0) {
                return;
            }
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListCustomers.current_page,
                first_page_url: ListCustomers.first_page_url,
                from: ListCustomers.from,
                last_page: ListCustomers.last_page,
                last_page_url: ListCustomers.last_page_url,
                links: ListCustomers.links,
                next_page_url: ListCustomers.next_page_url,
                path: ListCustomers.path,
                per_page: ListCustomers.per_page,
                prev_page_url: ListCustomers.prev_page_url,
                to: ListCustomers.to,
                total: ListCustomers.total,
            };
            listfil["name"] = [];

            listfil["address"] = [];
            listfil["phone"] = [];
            ListCustomers.data.map((item, index) =>
            {
                listfil["name"].push({
                    text: item.name,
                    value: item.name,
                });
                listfil["address"].push({
                    text: item.address,
                    value: item.address,
                });

                listfil["phone"].push({
                    text: item.phone,
                    value: item.phone,
                });

                item.key = index + 1
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_CUSTOMERS_EDIT + "/" + record.id,
            state: record
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchCustomersRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchCustomersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_CUSTOMERS}?${searchParams}`);
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
    }
    render()
    {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) =>
            {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.props.getChekCus(selectedRows);
                this.setSelectedRowKeys(selectedRowKeys);
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                // Column configuration not to be checked
                name: record.name,
            }),
            selectedRowKeys: selectedRowKeys
        }

        return (

            <div className="main_content" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Khách hàng</span>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                            maxHeight: "90vh",
                            // overflowY: "scroll"
                            // padding: "10px"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "80%",
                                    display: "flex"
                                }}>
                                    <Form.Item style={{ margin: 0 }}>
                                        <Input.Group compact>
                                            <Row gutter={16} style={{ display: "flex" }}>
                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Tên Khách hàng" />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Code" />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="address" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Địa chỉ" />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="phone" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Điện thoại" />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker />
                                                    </Form.Item>
                                                </Col>


                                                <Col span={4} style={{
                                                    padding: 0
                                                }}>

                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                        </div>
                                                    </Form.Item>

                                                </Col>
                                            </Row>
                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="right_">
                                <Link to={LINK.WEB_CUSTOMERS_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                            </div>

                        </div>
                        <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            rowSelection={{
                                ...rowSelection,
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListCustomersRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCustomersRequest(body));
        },
        StoreCustomersRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreCustomersRequest(data, id));
        },
        ResetStoreCustomers: () =>
        {
            dispatch(ActionsWeb.ResetStoreCustomers());
        },
        SearchCustomersRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchCustomersRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);