
import React, { createRef, useEffect, useState } from 'react'
import { useRef } from "react";
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import * as ActionsWeb from "./../../actions/indexWeb";

import * as LINK from "./../../constants/Links";
import {
  Form,
  Input,
  Button,
  InputNumber, Select,
  Space, message, Cascader,
  Divider, Image,
  Typography, Modal, Radio, Empty, TreeSelect, Card, Checkbox, Drawer
} from 'antd';
import LoadingSmile from "./../../components/loadingSmile/loadingSmile";
import {

  PlusOutlined,
 
} from '@ant-design/icons';
import { META } from '../../utils/constant';
import Editor from "./../editor/index";
import TableClassify from "./../../pages/web/Products/edit/RecursiveDisplay";
import { fallbackImg } from '../../constants/DefaultData';
import Classifys from "./Classifys.js";
import { v4 as uuidv4 } from 'uuid';


const Index = ({ imgesInfo, checkSync, ListStore, dataClassifyStoreIds, record, ListCategory, setEditTitok,ListClassifys }) => {
  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });
  const [codeTitok, setCodeTitok] = useState(null);
  const [cateTitok, setCateTitok] = useState(null);
  const [TitokCategories, setTitokCategories] = useState([]);
  const [TitokBrands, setTitokBrands] = useState([]);
  const [TitokAttributes, setTitokAttributes] = useState([]);
  const [NameNewAttribute, setNameNewAttribute] = useState({});
  const [isNotReload, setIsNotReload] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [tikTokCodes, setTikTokCodes] = useState([]);
  const editor = createRef();
  const fromreff = createRef();
  const [isLoading, setIsLoading] = useState([]);
  const [ListAttributeByProducts, setListAttributeByProducts] = useState([]);
  const [ListCategoriesByProducts, setListCategoriesByProducts] = useState([]);
  const [Titokedit, setTitokedit] = useState(null);
  const [classifyx, setClassifyx] = useState([
    { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, data: [], items: [{ key: uuidv4(), name: "Biến thể", price: 0, amount: 0, store_id: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
  ]);
  const [dataClassifyx, setDataClassifyx] = useState(null);

  useEffect(() => {
    fromreff.current.resetFields();
    if (setEditTitok?.id) {
      // console.log("setEditTitoksetEditTitoksetEditTitok",setEditTitok);
      // setTitokedit(setEditTitok);
      dispatch(ActionsWeb.ListAttributeByProductsRequest({ data: { "attribute_pr.product_id": setEditTitok.id } }));

    }
    return () => {
      dispatch(ActionsWeb.resetListAttributeByProductsRequest());
      setListAttributeByProducts([]);
      setListCategoriesByProducts([]);
      setTitokAttributes([]);
      setClassifyx([
        { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, data: [], items: [{ key: uuidv4(), name: "Biến thể", price: 0, amount: 0, store_id: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
      ]);
    };
  }, [setEditTitok]);

  useEffect(() => {
    if (Web.ListAttributeByProducts) {
      if (Web.ListAttributeByProducts?.[0]?.attribute_products_id) {

        setListAttributeByProducts(Web.ListAttributeByProducts?.[0]?.attribute_products_id);
      }
      if (Web.ListAttributeByProducts?.[0]?.category_arr_code) {
        const cate = [];
        for (let x in Web.ListAttributeByProducts?.[0]?.category_arr_code) {
          cate.push(Web.ListAttributeByProducts?.[0]?.category_arr_code?.[x].id);
        }

        setListCategoriesByProducts(cate);
        fromreff.current.setFieldsValue({ cateEcom: cate });
      }
      // console.log("Web.ListAttributeByProductsWeb.ListAttributeByProducts",Web.ListAttributeByProducts);
      if (Web.ListAttributeByProducts?.[0]?.classifyxOr) {
        const clss=Web.ListAttributeByProducts?.[0]?.classifyxOr;
        clss.map((item, index)  => {
          ListClassifys.map((el, ind) => {
            if (parseInt(item.id) ===parseInt(el.id)){
              clss[index].data = el.children
            }
        })

        // console.log("clssclssclssclssclssclssclssclss",clss);
        setClassifyx(clss);
      
      });

      if (Web.ListAttributeByProducts?.[0]?.classifys) {
        setDataClassifyx(Web.ListAttributeByProducts?.[0]?.classifys);
      }
      }
      //
    }
    return () => {

    };
  }, [Web.ListAttributeByProducts]);
  useEffect(() => {
    if (record) {
    
      if (record.detail) {
        editor.current.value = record.detail;
      }
      if (record.category_id && record.category_id?.[0]) {
        if (ListCategory.length > 0) {
          const tikTokCodes = findTikTokCodes(ListCategory, record.category_id[0]);
          setTikTokCodes(tikTokCodes);


        }

      }

      // if (record.brands_id) {
      //   editor.current.value = record.detail;
      // }
    }

    return () => {

    };
  }, [record, ListCategory]);
  // const setCategory=()=>{

  //     fromreff.current.setFieldsValue({cateEcom:ListCategoriesByProducts});
  // }
  const findTikTokCodes = (data, targetId) => {
    let path = [];

    const findPath = (node, currentPath = []) => {
      currentPath.push(node.id);
      if (node.id === targetId) {
        path = [...currentPath];
        return true;
      }
      if (node.children) {
        for (let child of node.children) {
          if (findPath(child, currentPath)) return true;
        }
      }
      currentPath.pop();
      return false;
    };

    for (let item of data) {
      if (findPath(item)) break;
    }

    return path;
  };
  useEffect(() => {
    if (imgesInfo) {
      console.log("imgesInfo", imgesInfo);
    }

    return () => {

    };
  }, [imgesInfo]);
  useEffect(() => {
    if (!codeTitok) {
      setCodeTitok(checkSync[0].value);
    }

    return () => {

    };
  }, [checkSync]);
  useEffect(() => {
    dispatch(ActionsWeb.TiktokCategoriesRequest({ code: codeTitok }));
    dispatch(ActionsWeb.TiktokBrandsRequest({ code: codeTitok }));


    return () => {

    };
  }, [codeTitok]);
  useEffect(() => {

    if (Web.TitokCategories) {

      setTitokCategories(Web.TitokCategories);
      // setCategory();
      // fromreff.current.setFieldsValue({ ["cateEcom"]: tikTokCodes });
      // tikTokCodes
      setIsLoading([...isLoading, "TitokCategories"]);
    }

    return () => {

    };
  }, [Web.TitokCategories]);

  useEffect(() => {
    // console.log("ListCategoriesByProductsListCategoriesByProducts", ListCategoriesByProducts, TitokCategories);
    fromreff.current.setFieldsValue({ cateEcom: ListCategoriesByProducts });
    if (tikTokCodes && tikTokCodes.length > 0) {

      dispatch(ActionsWeb.TiktokAtttributesRequest({ code: codeTitok, cateId: ListCategoriesByProducts[ListCategoriesByProducts.length - 1] }));
    }
    return () => {

    };
  }, [TitokCategories, ListCategoriesByProducts]);
  useEffect(() => {

    if (Web.TitokBrands && !isNotReload) {

      setIsLoading([...isLoading, "TitokBrands"]);

      setTitokBrands(Web.TitokBrands);
      setIsNotReload(true);
    }
    return () => {

    };
  }, [Web.TitokBrands]);
  useEffect(() => {

    if (Web.createCustomBrandTitok) {


      setTimeout(() => {
        setNewBrand("");
        setIsNotReload(false);
        dispatch(ActionsWeb.TiktokBrandsRequest({ code: codeTitok, isRefresh: true }));

      }, 1000);

    }
    return () => {

    };
  }, [Web.createCustomBrandTitok]);

  const renderAttribute = (value) => {

    if (setEditTitok?.id) {

      if (ListAttributeByProducts) {

        return ListAttributeByProducts?.[value.id.toString()]?.[0];
      }
      return null;
    } else {
      return record?.attribute_products_id?.[value.id.toString()]?.[0];
    }


  }

  const setAttributeForm = () => {
    const dataset = {};
    if (TitokAttributes) {
      TitokAttributes.map((value, index) => {
        let val = renderAttribute(value);
        if (val) {
          dataset[`attributeEcom-${index}`] = val;
        } else {
          dataset[`attributeEcom-${index}`] = "";
        }

      })
    }
    console.log("datasetdatasetdataset", dataset);
    fromreff.current.setFieldsValue(dataset);
  }

  useEffect(() => {
    setAttributeForm();
    return () => {

    };
  }, [TitokAttributes, ListAttributeByProducts]);
  // useEffect(() => {

  //   if (Web.StoreAttributeProducts) {


  //     if (Web.StoreAttributeProducts.code === 200) {
  //       if (Web.StoreAttributeProducts.message) {
  //             message.success(Web.StoreAttributeProducts.message)
  //         }
  //         dispatch(ActionsWeb.TiktokAtttributesRequest({ code: codeTitok, cateId: tikTokCodes[tikTokCodes.length - 1] }));
  //     }
  //     else {
  //         if (Web.StoreAttributeProducts.message) {
  //             message.error(Web.StoreAttributeProducts.message)
  //         }

  //     }

  //     dispatch(ActionsWeb.ResetStoreAttributeProducts());

  //   }
  //   return () => {

  //   };
  // }, [Web.StoreAttributeProducts]);
  useEffect(() => {

    if (Web.TiktokAtttributes) {
      setIsLoading([...isLoading, "TiktokAtttributes"]);
      setTitokAttributes(Web.TiktokAtttributes);
    }
    return () => {

    };
  }, [Web.TiktokAtttributes]);
  const onChangeCascader = (value) => {
    // console.log(value);
    setCateTitok(value);
    dispatch(ActionsWeb.TiktokAtttributesRequest({ code: codeTitok, cateId: value[value.length - 1], isRefresh: true }));
  }
  const onChangeRadio = (e) => {
    const target = e.target;

    setCodeTitok(target.value)
  }

  const onNameAttribute = (e, index) => {
    console.log(e);
    const target = e.target;

    setNameNewAttribute({ ...NameNewAttribute, [index]: target.value })
  }

  const addAttribute = (e, index) => {
    e.preventDefault();


    const dataAtt = TitokAttributes[index];
    let valuesDataAtt = dataAtt.children;
    const NewName = NameNewAttribute[index];
    if (NewName) {
      if (!valuesDataAtt) {
        valuesDataAtt = [];
      }
      valuesDataAtt.push({ id: "new" + index, name: NewName })
    }
    dataAtt.children = valuesDataAtt;
    TitokAttributes[index] = dataAtt;

    setTitokAttributes([...TitokAttributes])
    setNameNewAttribute({ ...NameNewAttribute, [index]: "" })

    // dispatch(ActionsWeb.StoreAttributeProductsRequest({
    //     "name": NewName,
    //     "code": "",
    //     "parent_id": dataAtt.id,
    //     "value": 0,
    //     "rank": 1,
    //     "deleted": "0",
    //     "hidden": "0",
    //     "description": ""
    // }));
  };

  const addNewBrand = (e) => {
    e.preventDefault();
    if (newBrand) {
      dispatch(ActionsWeb.createCustomBrandTitokRequest({ code: codeTitok, name: newBrand }));
    }


  };
  const submitForm = async (values) => {

    const attributeChose = [];
    for (let x in values) {
      if (x.includes("attributeEcom")) {
        attributeChose.push(values[x]);
      }
    }

   
 
   
    let arrParent = {}
   
    const updateItemValue = async (data, level = 0, parent_item = null) =>
      {
       
          return Promise.all(data.map(async (item, indi) =>
          {
              let  newItem = { ...item};
             
              if (item.children && item.children.length > 0) {
                  if (parent_item) {
                      arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                  }
                 
                  let newItemc =  await updateItemValue(item.children, level + 1, item);
                  let newItem = { ...item, children: newItemc };
                  return newItem;
              } else {
                  const tmp = Object.values({ ...arrParent });
                  if (parent_item) {
                      tmp.push({ ...parent_item, children: [] });
                  }
  
                  tmp.push({ ...item, children: [] });
                  let key_ = "";
                  tmp.map((d_, ide_) =>
                  {
                      key_ += d_.key
                  })
                 
  
                  let newItem = { ...item,
                      price: values[`price_${key_}`],
                      store_id: values[`house_${key_}`],
                      amount: values[`amount_price_${key_}`],
                      discount: values[`discount_${key_}`],
                      discount_percent: values[`discount_percent_${key_}`],
                      discount_price: values[`discount_price_${key_}`],
                      sku: values[`sku_${key_}`],
                      // tiktok_code: values[`sku_${key_}`]
                   }
                  newItem.store_ids = newItem.store_ids.map((elstore, istore) =>
                  {
                     
                      elstore = { ...elstore, amount: values[`amount_price_item_d_${key_}_${istore}`] }
                      return elstore;
                  });
                  newItem.inventory = newItem.store_ids.map((elstore, istore) =>
                    {
                       
                        elstore = { ...elstore, quantity: values[`amount_price_item_d_${key_}_${istore}`] }
                        return elstore;
                    });
                  return newItem;
              }
             
              
          }));
      }  
    const newDataClassifyx =await updateItemValue(dataClassifyx, 0);

  
    const classifyxOr = classifyx.map((item, index) => {
        const newitem={...item,children:[],data:[]}
        return newitem;
    });
  
    const data = { idstiktok: [codeTitok], idsproduct: [record.id], ...values, attributeChose: attributeChose, TitokAttributes: [...TitokAttributes], setEditTitok: setEditTitok,classifys: newDataClassifyx, classifyxOr: classifyxOr  };
      dispatch(ActionsWeb.SyncProductsToTiktokOneRequest(data));
  }
  
 
  return (
    <>
      {isLoading < 3 && <LoadingSmile />}
      <Form

        layout={"vertical"}
        ref={fromreff}

        onFinish={submitForm}
      >
        <Form.Item name={"checkSync"} initialValue={codeTitok}>
          <Radio.Group options={checkSync} onChange={onChangeRadio} value={codeTitok} optionType="button"
            buttonStyle="solid" />
        </Form.Item>
        <div className='row'>
          <div className='col-9'> <div className='imgs'>
            <div class="grid-container-3-row">
              <div class="grid-item large">
                <Image
                  width={"100%"}
                  height={"100%"}
                  src={imgesInfo?.avata[0]?.url ? imgesInfo?.avata[0]?.url : ""}
                  fallback={fallbackImg}

                />
              </div>
              {Array.from({ length: 8 }, (_, i) => i + 1).map((diss, idis) => {
                return <div class="grid-item">

                  {imgesInfo?.detail && imgesInfo?.detail?.[idis] && <Image
                    width={"100%"}
                    height={"100%"}

                    src={imgesInfo?.detail?.[idis]?.url}
                    fallback={fallbackImg}

                  />}
                  {!imgesInfo?.detail || !imgesInfo?.detail?.[idis] && <Image
                    width={"100%"}
                    height={"100%"}

                    src={""}
                    fallback={fallbackImg}

                  />}
                </div>
              })}


            </div>
          </div></div>
          <div className='col-3'></div>

        </div>
        <Form.Item label="Tên sản phẩm" name={"nameEcom"} initialValue={record.title} >
          <Input placeholder="Tên sản phẩm" />

        </Form.Item>
        <Form.Item label="Danh mục" name={"cateEcom"} >
          <Cascader options={TitokCategories} onChange={onChangeCascader} placeholder="Chọn 1 danh mục" />

        </Form.Item>

        <Form.Item label="Thương hiệu" name={"brandEcom"} initialValue={parseInt(setEditTitok?.brands_id)} >
          <Select

            showSearch
            placeholder="Thương hiệu"
            options={(TitokBrands || []).map((d) => ({
              value: d.id,
              label: d.name,
            }))}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                <Space
                  style={{
                    padding: '0 8px 4px',
                  }}
                >
                  <Input
                    placeholder="Nhập mới"
                    value={newBrand}
                    onChange={(e) => { setNewBrand(e?.target?.value) }}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={(e) => addNewBrand(e)}>
                    Thương hiệu mới
                  </Button>
                </Space>
              </>
            )}
          />

        </Form.Item>
        <Form.Item label={`Thuộc tính sản phẩm`} >
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
            {TitokAttributes && TitokAttributes.length > 0 && TitokAttributes.map((value, index) => {


              return <Form.Item label={value.name} name={`attributeEcom-${index}`} >

                <Select

                  showSearch
                  placeholder={value.name}
                  options={(value.children || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: '8px 0',
                        }}
                      />
                      <Space
                        style={{
                          padding: '0 8px 4px',
                        }}
                      >
                        <Input
                          placeholder="Nhập mới"
                          value={NameNewAttribute[index]}
                          onChange={(e) => { onNameAttribute(e, index) }}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={(e) => addAttribute(e, index)}>
                          Thuộc tính mới
                        </Button>
                      </Space>
                    </>
                  )}
                />

              </Form.Item>

            })}
          </div>
        </Form.Item>
        <Form.Item label="Mô tả chi tiết" >
          <Editor editor={editor} />

        </Form.Item>
        <Form.Item label="Phân loại" >
        
          <div className="table-responsive">

            <Classifys  dataClassifyx={dataClassifyx} classifyx={classifyx} setClassifyx={setClassifyx} setDataClassifyx={setDataClassifyx} ListStore={ListStore}  dataClassifyStoreIds={dataClassifyStoreIds} 
             record={record} ListCategory={ListCategory}  setEditTitok={setEditTitok} ListClassifys={ListClassifys} formRef={fromreff} />
            {/* <TableClassify data={dataClassifyx}
              // ColorCell={this.ColorCell} 
              ListStore={ListStore}
              //  onChangeDiscountItem={this.onChangeDiscountItem} onChangeAmountItem={this.onChangeAmountItem}

              // onChangeAmountItemSub={this.onChangeAmountItemSub}
              // onChangeStoreItem={this.onChangeStoreItem}
              dataClassifyStoreIds={dataClassifyStoreIds}
            /> */}

          </div>
        </Form.Item>

        <Form.Item label="Vận chuyển" name={"weightEcom"} initialValue={record?.weight}>
          <Input
            addonBefore={`Kilogram (kg)`}
            style={{

            }}

          />
        </Form.Item>
        <Form.Item label="Kích thước Sản phẩm" >
          <Input.Group compact>
            <Form.Item name={"lengthEcom"} noStyle initialValue={record?.long}>
              <Input
                addonAfter={`Cm`}
                style={{
                  width: '30%',
                }}
                placeholder='Chiều dài'
              />
            </Form.Item>
            <Form.Item name={"wideEcom"} noStyle initialValue={record?.wide}>
              <Input
                addonAfter={`Cm`}
                style={{
                  width: '30%',
                }}
                placeholder='Chiều rộng'

              /></Form.Item>
            <Form.Item name={"heightEcom"} noStyle initialValue={record?.height}>
              <Input
                addonAfter={`Cm`}
                style={{
                  width: '30%',
                }}
                placeholder='Chiều cao'

              /></Form.Item>
          </Input.Group>

        </Form.Item>

        <Form.Item label="Tùy chọn giao hàng" name={"options_transport_ecom"} initialValue={1}>
          <Radio.Group options={[{ value: 1, label: "Mặc định" }]} value={1} />
        </Form.Item>
        <Form.Item >
          <Button htmlType='submit'> Gửi</Button>
        </Form.Item>

      </Form>
    </>
  )
}
export default Index;