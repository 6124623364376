import React, { Component, createRef } from "react";
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";

import * as Colors from "../../../../constants/Colors";
import moment from 'moment';
import { PasswordRand, CutString, getParamsUrl, checkScreenSize } from "./../../../../service";
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, Tooltip, Image, Select, DatePicker, Checkbox, Tag, Card, Row, Col } from 'antd';
import Draggable from 'react-draggable';
import
{
    FolderViewOutlined,
    DeleteOutlined, PlusCircleOutlined, AppstoreOutlined,
    EditOutlined, SearchOutlined, UnorderedListOutlined, FileImageOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import Validator from "../../../../utils/Validator";

import Upload from "./Upload";
import IndexMobile from "./IndexMobile";
const pass_ = PasswordRand.generate(16);

const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListUsers: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'full_name',

                    filterSearch: true,
                    with: "20%",
                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0,
                password: pass_,
                password_confirmation: pass_
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreUsers: {},
            isloading: false,
            visibledetail: false,
            visibleViewRoles: false,
            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            disabled: true,
            ListStore: [],
            ListRole: [],
            search: {},
            checkedList: [],
            isLoadingSearch: false,
            imgesInfoRemove: [],
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
            required: {
                avata: false
            },
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    draggleRef = new createRef(null);
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }

    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type,
                record: { ...state.record, deleted: 0 }
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisibleDetail = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: type
            }
        })
    }
    setVisibleViewRoles = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibleViewRoles: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state

        record.deleted = 1

        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.DeleteUsersRequest(record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };

    handleOkDetail = () =>
    {
        this.setVisibleDetail(true);

    };

    handleCancelDetail = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);
    };



    handleOkViewroles = () =>
    {
        this.setVisibleViewRoles(true);

    };

    handleCancelViewroles = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisibleViewRoles(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchUserRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });



        this.props.ListStoreRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.props.ListRoleRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {

            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        if (nextProps.Web.ListUsers && nextProps.Web.ListUsers !== prevState.ListUsers) {
          
            return {
                ListUsers: nextProps.Web.ListUsers,
                isLoadingSearch: false
            };

        }

        if (nextProps.Web.ListRole && nextProps.Web.ListRole.data !== prevState.ListRole) {

            // console.log('====================================');
            // console.log("nextProps.Web.ListRolenextProps.Web.ListRolenextProps.Web.ListRole", nextProps.Web.ListRole);
            // console.log('====================================');

            return {
                ListRole: nextProps.Web.ListRole.data,

            };

        }
        if (nextProps.Web.StoreUsers && nextProps.Web.StoreUsers !== prevState.StoreUsers) {
            return {
                StoreUsers: nextProps.Web.StoreUsers,

            };

        }
        if (nextProps.Web.ListStore && nextProps.Web.ListStore !== prevState.ListStore) {



            return {
                ListStore: nextProps.Web.ListStore.data,

            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreUsers !== prevState.StoreUsers) {
            let { StoreUsers } = this.state;
            try {
                if (StoreUsers.code === 200) {
                    if (StoreUsers.message && typeof StoreUsers.message === "string") {
                        message.success(StoreUsers.message)
                        // this.setState(state =>
                        // {
                        //     return {
                        //         record: {

                        //             hidden: 0,
                        //             deleted: 0
                        //         }
                        //     }
                        // })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }



                    this.props.SearchUserRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreUsers.message && typeof StoreUsers.message === "string") {

                        message.error(StoreUsers.message)
                    } else {
                        for (let x in StoreUsers.message) {
                            let mess = StoreUsers.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreUsers()

            this.handleCancel();

        }
        if (this.state.ListUsers !== prevState.ListUsers) {
            this.SetisLoadingSeach(false)

            this.data();
        }
        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;

            if (parseInt(UploadFileLa.type) === 1) {
                if (UploadFileLa.code !== 200) {

                    message.error("Cập nhật ảnh chính thất bại!");

                }
                
                this.setState(state =>
                {
                    return {
                        record: { ...state.record, image: UploadFileLa.data[0] }
                    }
                }, () =>
                {
                    if (this.state.isupload === 1) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }

            this.props.ResetUploadFileLa();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [

                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Hình',
                        dataIndex: 'image',
                        render: (text, record, index) =>
                        {

                            return text ? <Image
                                width={80}
                                src={LINK.myHostimgServerImg + "/" + text}
                            />
                                // <img src={LINK.myHostimgServerImg + "/" + text[0]}
                                //     style={{ width: "80px", height: "80px", objectFit: "contain" }} alt="" />
                                : ""
                        }
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Điện thoại',
                        dataIndex: 'phone_number',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Địa chỉ',
                        dataIndex: 'address',
                        sorter: (a, b) => a.code - b.code,

                    },
                    {
                        title: 'Roles',
                        dataIndex: 'roles',
                        sorter: (a, b) => a.code - b.code,
                        render: (text, record, index) =>
                        {
                            return Object.values(record.roles).map((item, index) =>
                            (
                                // stringCate += item.name + ", "
                                <Tag color={Colors.arrColors[index]} style={{ cursor: "pointer" }} onClick={this.handleOkViewroles}>{CutString(item.name, 10, false)}</Tag>
                            ))
                        }
                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.ShowDetail(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    {/* {record.parent_id === 0 && */}

                                    <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    ShowDetail = (record) =>
    {
        this.handleOkDetail();
    }
    data = () =>
    {
        try {


            let { ListUsers } = this.state;

            if (ListUsers.data.length <= 0) {
                return;
            }

            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListUsers.current_page,
                first_page_url: ListUsers.first_page_url,
                from: ListUsers.from,
                last_page: ListUsers.last_page,
                last_page_url: ListUsers.last_page_url,
                links: ListUsers.links,
                next_page_url: ListUsers.next_page_url,
                path: ListUsers.path,
                per_page: ListUsers.per_page,
                prev_page_url: ListUsers.prev_page_url,
                to: ListUsers.to,
                total: ListUsers.total,
            };
            ListUsers.data.map((item, index) =>
            {
                listfil.push({
                    text: item.name,
                    value: item.name,
                })
                item.key = item.id
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error("error", error);
        }
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })

        }

    }
    ShowDetail = (record) =>
    {
        this.handleOkDetail();
    }
    editRows = (record) =>
    {

        let avata = [{
            uid: -1,
            name: record.image,
            status: 'done',
            url: LINK.myHostimgServerImg + "/" + record.image,
        }];
        this.setState(state =>
        {
            return {
                imgesInfo: { avata: avata, detail: [], video: [] },
                record: record,
                checkedList: record.roles_id,
                visibledetail: true

            }
        }, () =>
        {
            this.formRef.current.resetFields();
            this.formRef.current.setFieldsValue({ ...record })
        })

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", delete: 0, password: pass_, password_confirmation: pass_ },
                checkedList: [],
                visibledetail: true
            }
        }, () =>
        {
            this.formRef.current.resetFields();
            this.formRef.current.setFieldsValue({ password: pass_, password_confirmation: pass_ })
        })

    }
    uploadFile = (values) =>
    {
        const { imgesInfo, } = this.state;


        this.setState(state =>
        {
            return {
                required: {},
                values: values
            }
        })

        this.SetisLoading(true)
        let isupload = 0;
        // this.SetisLoading(true)
        if (imgesInfo.avata.length > 0) {

            let arravata = imgesInfo.avata.filter(item =>
            {
                return item.originFileObj;
            })
            if (arravata.length > 0) {
                this.props.UploadFileLaRequest(arravata, "img", 1);
                isupload = 1;
            }

        }


        this.setState(state =>
        {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    onFinishForm = (values) =>
    {

        const { record } = this.state;

        const record_ = { ...record, ...values }
        record_.roles = this.state.checkedList
        record_.imgesInfoRemove = this.state.imgesInfoRemove.filter(item =>
        {
            return !item.originFileObj
        });
        this.SetisLoading(true)
        this.props.StoreUsersRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })

    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {

            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteUsersRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchUserRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchUserRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_USER}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        });
    }
    randomPass = () =>
    {
        const pass = PasswordRand.generate(16);
        this.setState(state =>
        {
            return {
                record: { ...state.record, password: pass, password_confirmation: pass }

            }
        });
        this.formRef.current.setFieldsValue({ password: pass, password_confirmation: pass })

    }

    setCheckedList = (checkedList) =>
    {



        this.setState(state =>
        {
            return {
                checkedList: checkedList
            }
        }, () =>
        {

        })
    }
    updateItems = (el) =>
    {

        let newList = [];
        if (el.target.checked) {
            newList = [...this.state.checkedList, el.target.value];
        } else {
            newList = this.state.checkedList.filter(
                (listItem) => listItem !== el.target.value,
            );
        }

        this.setCheckedList(newList);
    };

    validatePassword = (_, value) =>
    {
        if (value && value.length < 6) {
            return Promise.reject(new Error('Mật khẩu phải có ít nhất 6 ký tự.'));
        }
        return Promise.resolve();
    };

    compareToFirstPassword = (_, value) =>
    {
        if (value && value !== this.formRef.current.getFieldValue('password')) {
            return Promise.reject(new Error('Xác nhận mật khẩu không khớp.'));
        }
        return Promise.resolve();
    };

    validateConfirmPassword = (_, value) =>
    {
        if (value && value.length < 6) {
            return Promise.reject(new Error('Mật khẩu phải có ít nhất 6 ký tự.'));
        }
        if (value && value !== this.formRef.current.getFieldValue('password')) {
            return Promise.reject(new Error('Xác nhận mật khẩu không khớp.'));
        }
        return Promise.resolve();
    };

    setimgesInfo = (type, data) =>
    {


        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],
                    // imgesInfo: {
                    //     ...state.imgesInfo, [type]: data
                    // },

                }
            }, () =>
            {
                this.setState(state =>
                {

                    return {
                        // imgesInfoRemove: [...state.imgesInfoRemove, ...state.imgesInfo[type]],
                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })


            // imageDelete["folder"] = type;

        } else {
            this.setState(state =>
            {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) =>
    {
        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item =>
            {
                return item !== data.name;
            })
            imagesrecord = this.state.record.images.filter(item =>
            {
                return item !== data.name;
            })
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content user_">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            CHi tiết
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibleViewRoles}
                    onOk={this.handleOkViewroles}
                    onCancel={this.handleCancelViewroles}

                    footer={[
                        <Button key="back" onClick={this.handleCancelViewroles}>
                            Hủy
                        </Button>,

                        <Button key="submit" type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={() => { this.update_() }}>
                            Cập nhật
                        </Button>,
                    ]}
                    width={1000}
                    bodyStyle={{
                        height: "500px",
                        overflowY: "scroll"
                    }}
                >
                    <div className="modal_content_">

                    </div>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Chi tiết
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[
                        // <Button key="back" onClick={this.handleCancelDetail}>
                        //     Hủy
                        // </Button>,
                        // <Button key="resetradio" onClick={this.handleResetRadioDetail}>
                        //     Reset
                        // </Button>,
                        // <Button key="submit" type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={() => { this.update_() }}>
                        //     Cập nhật
                        // </Button>,
                    ]}
                    width={1000}
                    bodyStyle={{
                        height: "500px",
                        overflowY: "scroll"
                    }}
                    style={{ position: "relative" }}
                >
                    <div className="modal_content_">

                        <Form
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                // span: 14,
                            }}
                            layout="horizontal"
                            name="formadd" onFinish={this.uploadFile} ref={this.formRef}

                        >
                            <Form.Item label="Cửa hàng" name="store_id" rules={[
                                {
                                    required: true,
                                    message: "cửa hàng là bắt buộc"
                                },
                            ]}>

                                <Select

                                    maxTagCount='responsive'
                                    style={{
                                        width: "30%",
                                    }}
                                    placeholder="Cửa hàng"


                                    options={(this.state.ListStore || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên user là bắt buộc"
                                },
                            ]}>

                                <Input placeholder="Tên user" />
                            </Form.Item>
                            <Form.Item label="Tên đầy đủ" name="fullname" rules={[
                                {
                                    required: true,
                                    message: "Tên user  đầy đủ là bắt buộc"
                                },
                            ]}>

                                <Input placeholder="Tên user" />
                            </Form.Item>
                            <Form.Item label="Tên đăng nhập" name="user_name" rules={[
                                {
                                    required: true,
                                    message: "Tên đăng nhập là bắt buộc"
                                },
                            ]}>

                                <Input placeholder="Tên đăng nhập" />
                            </Form.Item>
                            <Form.Item label="Email" name="email" rules={[
                                {
                                    required: true,
                                    message: "Email là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="email" />
                            </Form.Item>
                            <Form.Item label="Password" name="password" rules={[
                                {
                                    required: !this.state.record.id ? true : false,
                                    message: "Password là bắt buộc"
                                },
                                { validator: this.validatePassword },
                            ]} >
                                <Input addonAfter={<Button type="primary" htmlType="button" onClick={this.randomPass}>Random</Button>} placeholder="password" />
                            </Form.Item>
                            <Form.Item label="password confirmation" name="password_confirmation"
                                rules={[
                                    { required: !this.state.record.id ? true : false, message: 'Vui lòng xác nhận mật khẩu.' },
                                    { validator: !this.state.record.id ? this.compareToFirstPassword : "" },
                                ]}
                            >
                                <Input addonAfter={<Button type="primary" htmlType="button" onClick={this.randomPass}>Random</Button>} placeholder="password confirmation" />
                            </Form.Item>
                            <Form.Item label="Số điện thoại" name="phone_number" rules={[
                                {
                                    required: true,
                                    message: "Số điện thoại là bắt buộc"
                                },
                                {
                                    validator: (_, value) =>
                                    {

                                        if (value) {
                                            const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                                            if (min) {
                                                return Promise.reject(min);
                                            }
                                            const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                                            if (max) {
                                                return Promise.reject(max);
                                            }
                                            const isPhone = Validator.validatePhoneNumber(value);
                                            if (isPhone) {
                                                return Promise.reject(isPhone);
                                            }
                                        }

                                        return Promise.resolve();

                                    }
                                },
                            ]} >
                                <Input placeholder="Số điện thoại" />
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address">
                                <Input placeholder="address" />
                            </Form.Item>
                            <Form.Item label="Ảnh đại diện">
                                <Upload isCropImg={false} multiple={false} max={1} type_={"avata"} focus={this.state.required.avata} type_f={1} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} fileList={this.state.imgesInfo.avata} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} />
                            </Form.Item>
                            <Form.Item label="Route" >

                                <div className="radio_group">
                                    {this.state.ListRole && Object.values(this.state.ListRole).map((item, index) =>
                                    {

                                        if (item) {
                                            return <Checkbox key={index} value={item.id} checked={this.state.checkedList.includes(item.id)} onChange={(event) => this.updateItems(event)} >{item.name}</Checkbox>

                                        }

                                    })}

                                </div>

                            </Form.Item>
                            <div style={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "10px",
                                borderTop: `1px solid ${Colors.colorgreyLight}`,
                                backgroundColor: Colors.white
                            }}>
                                <Form.Item noStyle >
                                    <Button key="back" htmlType="button" onClick={this.handleCancelDetail}>
                                        Hủy
                                    </Button>,
                                    <Button key="resetradio" htmlType="button" onClick={this.handleResetRadioDetail}>
                                        Reset
                                    </Button>,
                                    <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Users</span>
                        </div>
                        <Button type="primary" onClick={this.addnew}><PlusCircleOutlined /></Button>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                           
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >


                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <div className="row">

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="name" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tên đầy đủ, tên đăng nhập.." />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="phone_number" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Phone" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="email" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Email" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="address" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Địa chỉ" />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="role_id" noStyle style={{ margin: 0 }}>
                                                <Select
                                                    showSearch
                                                    style={{
                                                        width: "100%"
                                                    }}

                                                    placeholder="Roles"

                                                  
                                                    options={(this.state.ListRole || []).map((d) => ({
                                                        value: d.id,
                                                        label: d.name,
                                                    }))}
                                                />

                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                <RangePicker />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </div>
                                    </div>


                                </Form>
                            </div>
                         
                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            setIsNewList={() =>
                            {
                                this.setState(state =>
                                {
                                    return {
                                        isNewList: !state.isNewList
                                    }
                                })
                            }}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <><Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}

                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>

                        </>
    }

                    </Card>

                </div>



            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListUsersRequest: (body) =>
        {
            dispatch(ActionsWeb.ListUsersRequest(body));
        },
        StoreUsersRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreUsersRequest(data, id));
        },
        ResetStoreUsers: () =>
        {
            dispatch(ActionsWeb.ResetStoreUsers());
        },
        ListStoreRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },
        SearchUserRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchUserRequest(body, data));
        },
        ListRoleRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRoleRequest(body));
        },
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
        {
            dispatch(ActionsWeb.ResetUploadFileLa());
        },
        DeleteUsersRequest: (id) =>
        {
            dispatch(ActionsWeb.DeleteUsersRequest(id));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);