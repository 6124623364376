import React, { useEffect, useRef, useState } from 'react';

import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import
{
    SearchOutlined,
    CaretDownOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../../../utils/constant";
import * as ActionsWeb from "../../../../actions/indexWeb";
const count = 3;
const Index = (props) =>
{
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isloading, setIsloading] = useState(false);
    const [ListStatusTransport, setListStatusTransport] = useState([]);
    const [ListInfoTransport, setListInfoTransport] = useState([]);
    const [record, setRecord] = useState(false);
    const formRef = useRef({})
    const onFinish = (values) =>
    {


        const list_status_default = values.list_status_default;
        const list_status_default_new = [];
        ListStatusTransport.map((item, index) =>
        {
            if (list_status_default.includes(item.id)) {
                list_status_default_new.push(item)
            }
        });
        values.list_status_default = list_status_default_new

        setIsloading(true)
        const record_ = { ...record, code: "ORDER", title: "Cài đặt đơn hàng", type: 2, active: 1, deleted: 0, data: JSON.stringify(values) }

        dispatch(ActionsWeb.StoreSettingsRequest(record_, record_.id ? record_.id : ""))
    }
    useEffect(() =>
    {
        setListStatusTransport(Web.ListStatusTransport.data);
        return () =>
        {

        };
    }, [Web.ListStatusTransport]);
    useEffect(() =>
    {
        setListInfoTransport(Web.ListInfoTransport.data);
        return () =>
        {

        };
    }, [Web.ListInfoTransport]);
    useEffect(() =>
    {
        if (Web.StoreSettings?.code) {

            try {
                if (Web.StoreSettings.code === 200) {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {
                        message.success(Web.StoreSettings.message)
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {

                        message.error(Web.StoreSettings.message)
                    } else {
                        for (let x in Web.StoreSettings.message) {
                            let mess = Web.StoreSettings.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")

            }
            setIsloading(false)
            dispatch(ActionsWeb.ResetStoreSettings());
        }


        return () =>
        {

        };
    }, [Web.StoreSettings]);
    useEffect(() =>
    {

        if (Web.SettingsShowByCode.data) {
            if (Web.SettingsShowByCode.data.code === "ORDER") {
                setRecord(Web.SettingsShowByCode.data)
                if (Web.SettingsShowByCode.data.data) {
                const formdata = JSON.parse(Web.SettingsShowByCode.data.data)

               
                formRef.current.setFieldsValue(formdata)
                const list_status_default = formdata.list_status_default.map((item, index) =>
                {
                    return item.id
                });
                formRef.current.setFieldsValue({ list_status_default: list_status_default })
            }
            }
        }
        return () =>
        {

        };
    }, [Web.SettingsShowByCode]);
    useEffect(() =>
    {
        dispatch(ActionsWeb.ListStatusTransportRequest({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort
        }))
        dispatch(ActionsWeb.ListInfoTransportRequest({
            limit: meta.per_page * 10,
            page: meta.current_page, column: meta.column, sort: meta.sort
        }))
        dispatch(ActionsWeb.SettingsShowByCodeRequest({ code: "ORDER" }))
    
        return () =>
        {

        };
    }, []);
    return (
        <div className='dropdown_rim'>
            <Form name="form" className="form" ref={formRef} onFinish={onFinish}
                layout={"horizontal"}
                style={{
                    padding: "0px !important",
                    width: "100%",
                    display: "flex",
                    margin: 0
                }}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form.Item name="list_status_default" label="Lưu đơn hàng mặc định" layout={"horizontal"}>
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Chọn danh sách mặc định"
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                                options={(ListStatusTransport || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="status_default" label="Cài trạng thái mặc định" layout={"horizontal"}>
                            <Select
                                showSearch
                                placeholder="trạng thái mặc định"
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                style={{ width: "100%" }}

                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={(ListStatusTransport || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="transport_default" label="Đơn vị vận chuyển mặc định" layout={"horizontal"}>
                            <Select
                                showSearch
                                placeholder="Đơn vị vận chuyển mặc định"
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                style={{ width: "100%" }}

                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={(ListInfoTransport || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    );
};

export default Index;
