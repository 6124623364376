import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";

import { createSlug } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card,
    Checkbox

} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
const CheckboxGroup = Checkbox.Group;

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = new createRef();

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                slug: "",
                hidden: false,
                deleted: 0
            },
            StoreRole: {},
            ListRole: [],
            data: [],
            isloading: false,
            checkAll: [],
        }


    }
    setCheckAll = (checkAll, id) =>
    {
        let newchecked = [];
        if (checkAll) {
            newchecked = [...this.state.checkAll, id];
        } else {
            newchecked = this.state.checkAll.filter(item =>
            {
                return item !== id
            })
        }

        this.setState(state =>
        {
            return {
                checkAll: newchecked
            }
        })
    }
    setCheckedList = (checkedList) =>
    {


        this.setState(state =>
        {
            return {
                checkedList: checkedList
            }
        })
    }
    onCheckAllChange = (e, items, id) =>
    {
        const itemstmp = [];

        items.map((el, index) =>
        {
            itemstmp.push(el.route)
        });

        let newchecked = [];
        if (e.target.checked) {
            newchecked = [...this.state.checkedList, ...itemstmp];
        } else {
            newchecked = this.state.checkedList.filter(item =>
            {
                return !itemstmp.includes(item)
            })
        }
        this.setCheckedList(newchecked);
        // this.setIndeterminate(false);
        this.setCheckAll(e.target.checked, id);
    }
    onChangeGroup = (list, items, id) =>
    {

        this.setCheckAll(list.length === items.length, id);


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListRole && nextProps.Web.ListRole.id && nextProps.Web.ListRole !== prevState.record) {

            return {
                record: nextProps.Web.ListRole,

            };

        }
        if (nextProps.Web.ListFunct && nextProps.Web.ListFunct.data !== prevState.ListFunct) {


            return {
                ListFunct: nextProps.Web.ListFunct.data,

            };

        }
        if (nextProps.Web.StoreRole && nextProps.Web.StoreRole !== prevState.StoreRole) {
            return {
                StoreRole: nextProps.Web.StoreRole,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.ListFunct !== prevState.ListFunct) {
            let { ListFunct, record } = this.state;

            let permissions = record.permissions ? JSON.parse(record.permissions) : [];
            let checkall = [];
            ListFunct.map((item, index) =>
            {
                if (item.children) {
                    item.children.map((el, j) =>
                    {
                        if (permissions.includes(el.route)) {
                            checkall.push(item.id)
                        }
                    });

                }
            });
            // permissions.push("Dashboard")
            this.setState(state =>
            {
                return {
                    // record: record,
                    checkedList: permissions,
                    checkAll: checkall
                }
            })
        }
        if (this.state.record !== prevState.record) {
            this.formRef.current.setFieldsValue({ ...this.state.record })
           
        }

        if (this.state.StoreRole !== prevState.StoreRole) {
            let { StoreRole } = this.state;
            try {
                if (StoreRole.code === 200) {
                    if (StoreRole.message && typeof StoreRole.message === "string") {
                        message.success(StoreRole.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreRole.message && typeof StoreRole.message === "string") {

                        message.error(StoreRole.message)
                    } else {
                        for (let x in StoreRole.message) {
                            let mess = StoreRole.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)

            this.props.ResetStoreRole()

        }
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    isUpdate: true
                }
            })
            this.props.ListRoleByIdRequest({ id });
        }
        this.props.ListFunctRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });


    }


    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record, checkedList } = this.state;
        record.permissions = checkedList

        const record_ = { ...record, ...values }

        this.SetisLoading(true)
        this.props.StoreRoleRequest(record_, record_.id ? record_.id : "")
       

    }
    render()
    {



        return (

            <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết Role</span>
                    </div>} bordered={false}
                        style={{ width: "100%" }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >

                            <Input name="title" id="title" hidden />
                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên roles là bắt buộc"
                                },
                            ]}>

                                <Input placeholder="Tên roles" />
                            </Form.Item>
                            <Form.Item label="Code" name="code">

                                <Input placeholder="code" />
                            </Form.Item>

                            <Form.Item label="Router" rules={[
                                {
                                    required: true,
                                    message: "Route là bắt buộc"
                                },
                            ]}>

                                {this.state.ListFunct && this.state.ListFunct.map((item, index) =>
                                {
                                    if (item) {

                                        if (item.route === "Dashboard") {
                                            return item ? <Checkbox
                                                key={item.route}
                                                value={item.route}
                                                onChange={(event) => this.updateItems(event)}
                                                checked
                                                disabled
                                            >{item.name}</Checkbox> : ""
                                        }
                                        else {
                                            if (item.children) {
                                                return <div key={index} className="radio_group">
                                                    <div ><Checkbox style={{ color: Colors.color2, fontWeight: "bold" }} indeterminate={this.state.indeterminate}
                                                        onChange={(event) => this.onCheckAllChange(event, item.children, item.id)}
                                                        checked={this.state.checkAll.includes(item.id)}>{item.name}</Checkbox></div>
                                                 
                                                    <div style={{ marginLeft: "40px" }}>
                                                        <CheckboxGroup onChange={(list) => { this.onChangeGroup(list, item.children, item.id) }} value={this.state.checkedList}>
                                                            {
                                                                item.children && item.children.map((el, j) =>
                                                                {
                                                                    return el ? <Checkbox
                                                                        key={j}
                                                                        value={el.route}
                                                                        onChange={(event) => this.updateItems(event)}>{el.name}</Checkbox> : ""
                                                                })
                                                            }
                                                        </CheckboxGroup>
                                                    </div>
                                                    
                                                </div>
                                            }

                                        }

                                    }
                                })}



                            </Form.Item>
                            <Form.Item>
                                <div className="flex_">
                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                    <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreRoleRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreRoleRequest(data, id));
        },
        ResetStoreRole: () =>
        {
            dispatch(ActionsWeb.ResetStoreRole());
        },
        ListRoleRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRoleRequest(body));
        },
        ListRoleByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRoleByIdRequest(body));
        },
        ListFunctRequest: (body) =>
        {
            dispatch(ActionsWeb.ListFunctRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);