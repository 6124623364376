import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination,Empty, Modal, message, Button, Form, Card, TreeSelect, InputNumber, Skeleton } from 'antd';
import * as Colors from "../../../../constants/Colors";
import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, getParamsUrl } from "./../../../../service";
import * as Services from "./../../../../service";
import
{
    FolderViewOutlined,
    DeleteOutlined, PlusCircleOutlined, AppstoreOutlined,
    EditOutlined, SearchOutlined, UnorderedListOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";

import IndexMobile from "./IndexMobile";
import DragdropClassify from "./../../../../components/dragdropClassify/index";
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListClassifys: [],
            meta: {
                ...META,
                per_page: 100
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.address.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },

                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreCategory: {},
            isloading: false,
            isLoadingSearch: false,
            isDelete: false,
            isShowList: false,
            isNewList: false,
            isloadingSavelist: false,
            isSket:true,

        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setIsdeleted = (type) =>
    {
        this.setState(state =>
        {
            return {
                isDelete: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreClassifyRequest(record, record.id ? record.id : "")
        this.setIsdeleted(true)
        this.setState(state =>
        {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        const urlParams = getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

        this.props.ListClassifysRequest({
            limit: this.state.meta.per_page * 10,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListClassifys && nextProps.Web.ListClassifys !== prevState.ListClassifys) {
            console.log("Comment");


            return {
                ListClassifys: nextProps.Web.ListClassifys,

            };

        }
        else if (nextProps.Web.StoreClassifys && nextProps.Web.StoreClassifys !== prevState.StoreClassifys) {
            return {
                StoreClassifys: nextProps.Web.StoreClassifys,

            };

        }
        else {
            return null;
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreClassifys !== prevState.StoreClassifys) {
            let { StoreClassifys } = this.state;
            try {
                if (StoreClassifys.code === 200) {
                    if (StoreClassifys.message && typeof StoreClassifys.message === "string") {
                        message.success(StoreClassifys.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

                    this.props.ListClassifysRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    });



                }
                else {
                    if (StoreClassifys.message && typeof StoreClassifys.message === "string") {

                        message.error(StoreClassifys.message)
                    } else {
                        for (let x in StoreClassifys.message) {
                            let mess = StoreClassifys.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.handleCancel();
            this.SetisLoadingSavelist(false);
            this.props.ResetStoreClassify()


        }
        if (this.state.ListClassifys !== prevState.ListClassifys) {
            console.log('====================================');
            console.log("this.state.ListClassifys", this.state.ListClassifys);
            console.log('====================================');
            this.SetisLoadingSeach(false)
            this.data();
        }
    }

    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    // {
                    //     title: 'STT',
                    //     dataIndex: 'stt',


                    // },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters,
                        onFilter: (value, record) =>
                        {
                            console.log('====================================');
                            console.log("valuevalue", record, value);
                            console.log('====================================');
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.addnew(record) }}> <PlusCircleOutlined style={{ color: Colors.colorAdd }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListClassifys } = this.state;

        if (ListClassifys.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListClassifys.current_page,
            first_page_url: ListClassifys.first_page_url,
            from: ListClassifys.from,
            last_page: ListClassifys.last_page,
            last_page_url: ListClassifys.last_page_url,
            links: ListClassifys.links,
            next_page_url: ListClassifys.next_page_url,
            path: ListClassifys.path,
            per_page: ListClassifys.per_page,
            prev_page_url: ListClassifys.prev_page_url,
            to: ListClassifys.to,
            total: ListClassifys.total,
        };
        ListClassifys.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })

            // item.stt = index+1;
            // data.push(item)

        });
        console.log('====================================');
        console.log("data", data);
        console.log('====================================');
        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: ListClassifys.data,
                isSket:false,
            }
        }, () =>
        {
            this.setState(state =>
            {
                return {
                    isNewList: false,
                }
            });

        })
    }


    editRows = (record) =>
    {

        this.setState(state =>
        {
            return {
                record: record,

            }
        }, () =>
        {

        })
        this.formRef.current.setFieldsValue({ ...record })
    }
    editRowsMobile = (record) =>
    {


        this.props.history.push({
            pathname: LINK.WEB_CLASSIFY_EDIT + "/" + record.id,
        });

    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", deleted: 0, parent_id: record.id, parent: { name: record.name ? record.name : "Gốc" } }
            }
        })
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({ parent_id: record.id ? record.id : 0 })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e ? 1 : 0 }

        this.SetisLoading(true)
        this.props.StoreClassifyRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }

    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 },

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListClassifysRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.ListClassifysRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });

        const searchParams = new URLSearchParams({ s: values.search }).toString();

        this.props.history.push(`${LINK.WEB_CLASSIFY}?${searchParams}`);

        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }

    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreClassifyRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    getMenus = (menus) =>
    {
        // console.log('====================================');
        // console.log(menus);
        // console.log('====================================');
        if (menus) {
            this.setState(state =>
            {
                return {
                    menus: menus
                }
            })
        }
    }
    saveMenusList = () =>
    {
        const { menus } = this.state;
        this.SetisLoadingSavelist(true)
        console.log('====================================');
        console.log({ data: menus });
        console.log('====================================');
        this.props.StoreClassifysMultipleRequest({ data: menus });


    }
    SetisLoadingSavelist = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloadingSavelist: type
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content classify_">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Biến thể</span>
                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <Link to={LINK.WEB_CLASSIFY_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {(Services.checkScreenSize().width >= 600 && this.state.isShowList) && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="form__half row">
                            <div className={"left_half col-lg-7 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="Nhập tên danh mục" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                            </Form.Item>

                                        </Form>
                                    </div>

                                </div>
                                {this.state.isSket && <Skeleton active />}
                                {!this.state.isSket && this.state.data.length<=0 && <Empty/>}
                              
                                {this.state.data.length > 0 && <>    {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <><div className="menu_drag">
                                    <DragdropClassify menus={this.state.data} getMenus={this.getMenus}

                                    />
                                </div>    <div className="btn_save_cate_tree">
                                        <Button type="primary" loading={this.state.isloadingSavelist} onClick={() => { this.saveMenusList() }}>Lưu</Button>
                                    </div></>}
                                 


                                    {Services.checkScreenSize().width >= 600 && this.state.isShowList && <>  <Table
                                        rowClassName={(record, index) =>
                                        {
                                            return "__row_";
                                        }}
                                        // rowSelection={{
                                        //     ...this.state.rowSelection,
                                        // }}
                                        columns={this.state.columns}
                                        dataSource={this.state.data}
                                        pagination={false}
                                        onChange={this.onChangeTable}

                                    />
                                      
                                    </>
                                    }
                                </>
                                }
                            </div>

                            {Services.checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">
                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    // labelCol={{ span: 4 }}
                                    // wrapperCol={{ span: 20 }}
                                    layout="vertical"
                                >
                                    <Form.Item label="Nhóm biến thể" name={"parent_id"}

                                        rules={[
                                            {
                                                required: true,
                                                message: "Nhóm biến thể là bắt buộc"
                                            },
                                        ]}
                                        initialValue={0}
                                    >
                                        <TreeSelect
                                            showSearch
                                            style={{ width: '100%' }}
                                            treeData={([{ id: 0, value: 0, name: "gốc", label: "gốc" }, ...this.state.data] || [])}

                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="Nhóm biến thể "
                                            // allowClear
                                            // multiple
                                            treeDefaultExpandAll


                                        />

                                    </Form.Item>
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên Biến thể là bắt buộc"
                                        },
                                    ]} >
                                        <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code" rules={[
                                        {
                                            required: true,
                                            message: "Code là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>
                                    <Form.Item label="Vị trí" name="rank" rules={[
                                        {
                                            required: true,
                                            message: "Vị trí là bắt buộc"
                                        },
                                    ]} initialValue={1}>
                                        <InputNumber placeholder="Vị trí" />
                                    </Form.Item>
                                    <Form.Item >
                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </Form>

                            </div>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListClassifysRequest: (body) =>
        {
            dispatch(ActionsWeb.ListClassifysRequest(body));
        },
        StoreClassifyRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreClassifyRequest(data, id));
        },
        ResetStoreClassify: () =>
        {
            dispatch(ActionsWeb.ResetStoreClassify());
        },
        StoreClassifysMultipleRequest: (data) =>
        {
            dispatch(ActionsWeb.StoreClassifysMultipleRequest(data));
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);