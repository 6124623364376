import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1 } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card, DatePicker
} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";
import Validator from "../../../../utils/Validator";
const { TextArea } = Input;

const { Option } = Select;


const defaultTime = moment().subtract(15, 'years');
class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            record: {

                birthday: defaultTime,
                deleted: 0,
                hidden: 0
            },
            StoreCustomers: {},
            ListCustomers: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        // if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {

        //     return {
        //         record: nextProps.history.location.state,

        //     };

        // }
        // if (nextProps.match.params.type && nextProps.match.params.type !== prevState.type) {

        //     return {
        //         type: nextProps.match.params.type,

        //     };

        // }
        if (nextProps.Web.StoreCustomers && nextProps.Web.StoreCustomers !== prevState.StoreCustomers) {
            return {
                StoreCustomers: nextProps.Web.StoreCustomers,

            };

        }
        if (nextProps.Web.ListProvinces && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {



            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };

        }
        if (nextProps.Web.ListDistricts && nextProps.Web.ListDistricts.data !== prevState.ListDistricts) {



            return {
                ListDistricts: nextProps.Web.ListDistricts.data,

            };

        }
        if (nextProps.Web.ListWars && nextProps.Web.ListWars.data !== prevState.ListWars) {



            return {
                ListWars: nextProps.Web.ListWars.data,

            };

        }

        if (nextProps.Web.ListCustomers && nextProps.Web.ListCustomers.id !== prevState.record.id) {

            console.log('====================================');
            console.log("nextProps.Web.ListCustomers", nextProps.Web.ListCustomers);
            console.log('====================================');
            return {
                record: nextProps.Web.ListCustomers,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreCustomers !== prevState.StoreCustomers) {
            let { StoreCustomers } = this.state;
            try {
                if (StoreCustomers.code === 200) {
                    if (StoreCustomers.message && typeof StoreCustomers.message === "string") {
                        message.success(StoreCustomers.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreCustomers.message && typeof StoreCustomers.message === "string") {

                        message.error(StoreCustomers.message)
                    } else {
                        for (let x in StoreCustomers.message) {
                            let mess = StoreCustomers.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStoreCustomers()
            this.SetisLoading(false)
        }
        if (this.state.record.id !== prevState.record.id) {
            this.props.ListDistrictsRequest({
                limit: this.state.meta.per_page * 1000,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: this.state.record.province
            });
            this.props.ListWarsRequest({
                limit: this.state.meta.per_page * 1000,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
                provinceid: this.state.record.province, districtid: this.state.record.district
            });

            if (this.state.record.birthday) {

                this.state.record.birthday = moment(this.state.record.birthday, "YYYY-MM-DD");

            }

            this.formRef.current.setFieldsValue({ ...this.state.record });

        }

        if (this.state.ListProvinces !== prevState.ListProvinces) {
            if (this.state.type === "edit") {
                this.setState(state =>
                {
                    return {
                        Province_id: (1 * this.state.record.province),

                    }
                }, () =>
                {
                    this.formRef.current.setFieldsValue({ ...this.state.record })
                })
            }

        }
        if (this.state.ListDistricts !== prevState.ListDistricts) {
            const { match } = this.props;
            const { params } = match;
            const id = (1 * params.id); // Lấy giá trị của tham số từ URL
            if (id && typeof id === "number") {
                this.setState(state =>
                {
                    return {
                        // Province_id: this.state.record.province,
                        District_id: (1 * this.state.record.district),
                        // War_id: this.state.record.war
                    }
                }, () =>
                {
                    this.formRef.current.setFieldsValue({ ...this.state.record })
                })
            }

        }
        if (this.state.ListWars !== prevState.ListWars) {
            const { match } = this.props;
            const { params } = match;
            const id = (1 * params.id); // Lấy giá trị của tham số từ URL
            if (id && typeof id === "number") {
                this.setState(state =>
                {
                    return {
                        // Province_id: this.state.record.province,
                        // District_id: this.state.record.district,
                        War_id: (1 * this.state.record.war)
                    }
                }, () =>
                {
                    this.formRef.current.setFieldsValue({ ...this.state.record })
                })
            }

        }

    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.CustomerByIdRequest({ id });
        }
        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page * 1000,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });


    }
    data = () =>
    {
        let { ListRegion } = this.state;
        // if (!ListRegion) {
        //     ListRegion = this.props.Web.ListRegion
        // }

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    // Store = () =>
    // {
    //     let { record } = this.state;
    //     if (!record.name) {
    //         message.error("Vui lòng nhập tên");
    //         return;
    //     }
    //     console.log('====================================');
    //     console.log(record);
    //     console.log('====================================');
    //     // return;
    //     this.SetisLoading(true)
    //     this.props.StoreCustomersRequest(record, record.id ? record.id : "")
    // }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values, deleted: 0 }
        this.SetisLoading(true)
        this.props.StoreCustomersRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    handleChangeProvince = (e) =>
    {
        let province = this.state.ListProvinces.filter(item =>
        {
            return item.id === (1 * e);
        })
        this.setState(state =>
        {
            return {
                Province_id: e,
                record: { ...state.record, province: province?.[0]?.name }
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });
    }

    handleChangeDistrict = (e) =>
    {
        let district = this.state.ListDistricts.filter(item =>
        {
            return item.id === (1 * e);
        })
        this.setState(state =>
        {
            return {
                District_id: e,
                record: { ...state.record, district: district?.[0]?.name }
            }
        })
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id, districtid: e
        });
    }
    handleChangeWar = (e) =>
    {
        let war = this.state.ListWars.filter(item =>
        {
            return item.id === (1 * e);
        })
        this.setState(state =>
        {
            return {
                War_id: e,
                record: { ...state.record, war: war?.[0]?.name }
            }
        })

    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin Khách hàng</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                       
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                    >

                        <div className="section_ section_img">



                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên khách hàng là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Tên" />
                            </Form.Item>
                            <Form.Item label="Ngày sinh" name="birthday" initialValue={defaultTime} >
                                <DatePicker defaultValue={defaultTime} placeholder="Ngày sinh" />
                            </Form.Item>

                            <Form.Item label="phone" name="phone" rules={[
                                {
                                    validator: (_, value) =>
                                    {

                                        if (value) {
                                            const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                                            if (min) {
                                                return Promise.reject(min);
                                            }
                                            const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                                            if (max) {
                                                return Promise.reject(max);
                                            }
                                            const isPhone = Validator.validatePhoneNumber(value);
                                            if (isPhone) {
                                                return Promise.reject(isPhone);
                                            }
                                        }

                                        return Promise.resolve();

                                    }
                                },
                            ]}>
                                <Input placeholder="phone" />
                            </Form.Item>
                            <Form.Item label="Email" name="email">
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address">
                                <Input placeholder="address" />
                            </Form.Item>
                            <Form.Item label="Tỉnh/Thành" name="province_id" rules={[
                                {
                                    required: true,
                                    message: "Tỉnh/Thành là bắt buộc"
                                },
                            ]}>
                                <Select
                                    showSearch

                                    placeholder={"Tỉnh/Thành"}
                                    style={{ width: "100%", }}
                                    defaultActiveFirstOption={true}
                                    showArrow={false}


                                    onChange={this.handleChangeProvince}
                                    notFoundContent={null}
                                    filterOption={(input, option) =>
                                    {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={(this.state.ListProvinces || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}

                                />
                            </Form.Item>
                            <Form.Item label="Quận/Huyện" name="district_id" rules={[
                                {
                                    required: true,
                                    message: "Quận/Huyện là bắt buộc"
                                },
                            ]}>
                                <Select
                                    showSearch

                                    placeholder={"Quận/Huyện"}
                                    style={{ width: "100%", }}
                                    defaultActiveFirstOption={true}
                                    showArrow={false}

                                    // onSearch={this.handleSearch}
                                    onChange={this.handleChangeDistrict}
                                    notFoundContent={null}
                                    filterOption={(input, option) =>
                                    {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={(this.state.ListDistricts || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}

                                />
                            </Form.Item>
                            <Form.Item label="Phường/xã" name="war_id" rules={[
                                {
                                    required: true,
                                    message: "Phường xã là bắt buộc"
                                },
                            ]}>
                                <Select
                                    showSearch

                                    placeholder={"Phường/xã"}
                                    style={{ width: "100%", }}
                                    defaultActiveFirstOption={true}
                                    showArrow={false}

                                    // onSearch={this.handleSearch}
                                    onChange={this.handleChangeWar}
                                    notFoundContent={null}
                                    filterOption={(input, option) =>
                                    {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    options={(this.state.ListWars || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}

                                />
                            </Form.Item>
                            <Form.Item label="Ghi chú" name="note" >
                                <TextArea style={{ height: "100px" }} placeholder="Ghi chú" />
                            </Form.Item>

                        </div>

                        <Form.Item >
                            <div className="flex_">

                                <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
        ListWarsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListWarsRequest(body));
        },
        ListDistrictsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListDistrictsRequest(body));
        },
        ListProvincesRequest: (body) =>
        {
            dispatch(ActionsWeb.ListProvincesRequest(body));
        },
        StoreCustomersRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreCustomersRequest(data, id));
        },
        ResetStoreCustomers: () =>
        {
            dispatch(ActionsWeb.ResetStoreCustomers());
        },
        ListCustomersRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCustomersRequest(body));
        },
        ListRegionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
        CustomerByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.CustomerByIdRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);