import React, { Component, createRef } from "react";
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, CutString, SetProductsData, getParamsUrl, checkScreenSize, convertToBoolean } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA, Col, Row, TreeSelect, List,
    message,
    Card, DatePicker, Image, Table, Pagination, Skeleton, Avatar, InputNumber, Drawer
} from 'antd';
import
{
    UserSwitchOutlined, SearchOutlined, DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";
import Products from "./../../../../components/products/index";

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = new createRef(null);
        this.formSearchCollectionsRef = new createRef(null);
        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            record: {


                deleted: 0,
                isslider: 0,
                hidden: 0
            },
            StoreCollections: {},
            ListCollections: [],
            data: [],
            isloading: false,
            isLoadingSearch: false,
            productsList: [],
            selectProductsdata: [],
            selectProductsColumns: [],
            selectedRowKeys1: [],
            selectedRows1: [],
            rowSelection: {
                // selectedRowKeys: this.state.selectedRowKeys1,
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    this.setState({
                        selectedRowKeys1: selectedRowKeys,
                        selectedRows1: selectedRows,
                    });
                    this.setProductsSeleted(selectedRows);
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

                },
                // getCheckboxProps: (record) => ({
                //     disabled: record.name === 'Disabled User',
                //     // Column configuration not to be checked
                //     name: record.name,
                // }),
            },
            initLoading: false,
            loading: false,
            hiddenLoadmore: false,
            visible: false,
          
        }


    }
    setvisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type,
             
            }
        })
    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
       
        if (nextProps.Web.StoreCollections && nextProps.Web.StoreCollections !== prevState.StoreCollections) {
            return {
                StoreCollections: nextProps.Web.StoreCollections,

            };

        }
        if (nextProps.Web.ListCollectionsProducts && nextProps.Web.ListCollectionsProducts !== prevState.ListCollectionsProducts) {


            return {
                ListCollectionsProducts: nextProps.Web.ListCollectionsProducts,

            };

        }
        if (nextProps.Web.CollectionsProducts && nextProps.Web.CollectionsProducts.data !== prevState.CollectionsProducts) {
            let meta = {
                current_page: nextProps.Web.CollectionsProducts.current_page,
                first_page_url: nextProps.Web.CollectionsProducts.first_page_url,
                from: nextProps.Web.CollectionsProducts.from,
                last_page: nextProps.Web.CollectionsProducts.last_page,
                last_page_url: nextProps.Web.CollectionsProducts.last_page_url,
                links: nextProps.Web.CollectionsProducts.links,
                next_page_url: nextProps.Web.CollectionsProducts.next_page_url,
                path: nextProps.Web.CollectionsProducts.path,
                per_page: nextProps.Web.CollectionsProducts.per_page,
                prev_page_url: nextProps.Web.CollectionsProducts.prev_page_url,
                to: nextProps.Web.CollectionsProducts.to,
                total: nextProps.Web.CollectionsProducts.total,
            };
            return {
                CollectionsProducts: nextProps.Web.CollectionsProducts.data,
                isLoadingSearch: false,
                metaproducts: meta,
                hiddenLoadmore: nextProps.Web.CollectionsProducts.current_page >= nextProps.Web.CollectionsProducts.last_page
            };


        }
        if (nextProps.Web && nextProps.Web.ListProducts !== prevState.ListProducts) {


            return {
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false,
            };


        }

        if (nextProps.Web.ListCollections && nextProps.Web.ListCollections.id !== prevState.record.id) {


            return {
                record: nextProps.Web.ListCollections,

            };

        }

        // if (nextProps.Web.productsData_ && nextProps.Web.productsData_ !== prevState.productsData_) {

        //     nextProps.Web.productsData_.map((item, index) =>
        //     {
        //         if (["ListTags", "ListCategory"].includes(item)) {
        //             if (nextProps.Web[item] && nextProps.Web[item] !== prevState[item]) {

        //                 SetProductsData('ListTags', nextProps.Web.ListTags)
        //                 return {
        //                     [item]: nextProps.Web[item].data
        //                 }

        //             }
        //         }


        //     })
        // }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.ListCollectionsProducts !== prevState.ListCollectionsProducts) {
            this.data2();
            
        }
        if (this.state.StoreCollections !== prevState.StoreCollections) {
            let { StoreCollections } = this.state;
            try {
                if (StoreCollections.code === 200) {
                    if (StoreCollections.message && typeof StoreCollections.message === "string") {
                        message.success(StoreCollections.message)
                        this.setState(state =>
                        {
                            return {
                                record: {
                                    isslider: 0,
                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreCollections.message && typeof StoreCollections.message === "string") {

                        message.error(StoreCollections.message)
                    } else {
                        for (let x in StoreCollections.message) {
                            let mess = StoreCollections.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStoreCollections()
            this.SetisLoading(false)
        }
        if (this.state.record.id !== prevState.record.id) {

            const collectionProducts = this.state.record?.products?.data;
            let meta = {
                ...this.state.meta,
                current_page: this.state.record?.products?.current_page,
                first_page_url: this.state.record?.products?.first_page_url,
                from: this.state.record?.products?.from,
                last_page: this.state.record?.products?.last_page,
                last_page_url: this.state.record?.products?.last_page_url,
                links: this.state.record?.products?.links,
                next_page_url: this.state.record?.products?.next_page_url,
                path: this.state.record?.products?.path,
                per_page: this.state.record?.products?.per_page,
                prev_page_url: this.state.record?.products?.prev_page_url,
                to: this.state.record?.products?.to,
                total: this.state.record?.products?.total,
            };
            console.log("recordrecord",this.state.record);
            this.formRef.current.setFieldsValue({ ...this.state.record });
            this.setState(state =>
            {
                return {
                    productsList: collectionProducts,
                    productsData: collectionProducts,
                    metaproductsTmp: meta,
                    metaproducts: meta,

                }
            })
        }

        if (this.state.ListProducts !== prevState.ListProducts) {
            let { ListProducts } = this.state;

            if (ListProducts.code !== 200 && ListProducts.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
            }

            this.data()
        }
        // if (this.state.ListCategory !== prevState.ListCategory) {
        //     let { ListCategory } = this.state


        // }

        // if (this.state.productsList !== prevState.productsList) {
        //     let { productsList } = this.state;


        // }
       

    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL
        this.props.ResetSearchCollectionsProducts();
       
        if (id && typeof id === "number") {
            this.props.CollectionByIdRequest({ id });
            this.props.ListCollectionsProductsRequest({
                limit: this.state.meta.per_page,
                page: 1, column: this.state.meta.column, sort: this.state.meta.sort, collection_id: id
            })
            
        }
       
    }

    setProductsSeleted = (selectedRows) =>
    {
        const { productsList } = this.state;
     
        // Lấy các phần tử có trong mảng A nhưng không có trong mảng B
        const result = selectedRows.filter(itemA => !productsList.some(itemB => (1 * itemB.id) === (1 * itemA.id)));

        this.setState(state =>
        {
            return {
                productsList: [...state.productsList, ...result]
            }
        })
    }
    deleteSelected = (record) =>
    {
        const { productsList } = this.state;
        // Lấy các phần tử có trong mảng A nhưng không có trong mảng B
        const result = productsList.filter(item => { return item.id !== record.id });

        this.setState(state =>
        {
            return {
                productsList: [...result]
            }
        })
    }
    data2 = () =>
    {
        try {


            let { ListCollectionsProducts } = this.state;

            if (ListCollectionsProducts.length <= 0) {
                return;
            }


            let meta = {
                ...this.state.meta,
                current_page: ListCollectionsProducts.current_page,
                first_page_url: ListCollectionsProducts.first_page_url,
                from: ListCollectionsProducts.from,
                last_page: ListCollectionsProducts.last_page,
                last_page_url: ListCollectionsProducts.last_page_url,
                links: ListCollectionsProducts.links,
                next_page_url: ListCollectionsProducts.next_page_url,
                path: ListCollectionsProducts.path,
                per_page: ListCollectionsProducts.per_page,
                prev_page_url: ListCollectionsProducts.prev_page_url,
                to: ListCollectionsProducts.to,
                total: ListCollectionsProducts.total,
            };
            // const newData = this.state.productsData.concat(ListCollectionsProducts.data);
            this.setData(ListCollectionsProducts.data);
            this.setList(ListCollectionsProducts.data);
            this.setLoading(false);
          
            window.dispatchEvent(new Event('resize'));

            this.setState(state =>
            {
                return {
                    // data: data,
                    metaproducts: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    data = () =>
    {


        try {

            let { ListProducts } = this.state;

            if (ListProducts.length <= 0) {
                return;
            }

            let data = [];
            let listfil = {};
            let meta = {
                ...this.state.meta,
                current_page: ListProducts.current_page,
                first_page_url: ListProducts.first_page_url,
                from: ListProducts.from,
                last_page: ListProducts.last_page,
                last_page_url: ListProducts.last_page_url,
                links: ListProducts.links,
                next_page_url: ListProducts.next_page_url,
                path: ListProducts.path,
                per_page: 1 * ListProducts.per_page,
                prev_page_url: ListProducts.prev_page_url,
                to: ListProducts.to,
                total: ListProducts.total,
            };

            let filtilte = [];
            let filslug = [];
            let filprice = [];
            let fildiscount = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            ListProducts.data.map((item, index) =>
            {
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                fildiscount = [...fildiscount, {
                    text: item.discount,
                    value: item.discount,
                }]
                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = index + 1

                data.push(item)

            });
            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]
            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, discount: fildiscount }
            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    // Store = () =>
    // {
    //     let { record } = this.state;
    //     if (!record.name) {
    //         message.error("Vui lòng nhập tên");
    //         return;
    //     }
    //     console.log('====================================');
    //     console.log(record);
    //     console.log('====================================');
    //     // return;
    //     this.SetisLoading(true)
    //     this.props.StoreCollectionsRequest(record, record.id ? record.id : "")
    // }
    onFinishForm = (values) =>
    {
        const { record, productsList } = this.state;
        const record_ = { ...record, ...values, deleted: 0, products: productsList }
        // console.log("record_record_",record_);
        this.SetisLoading(true)
       this.props.StoreCollectionsRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: '#',
                        dataIndex: 'key',
                        width: "5%",
                        align: 'center',

                    },
                    {
                        title: 'Tiêu đề',
                        dataIndex: 'title',
                        // filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) =>
                        {

                            return record.title.startsWith(value)

                        },
                        sorter: (a, b) => a.title - b.title,
                        render: (text, record, index) =>
                        {

                            return CutString(text, 20, false);
                        }
                    },
                    {
                        title: 'thumbnail',
                        dataIndex: 'thumbnail',
                        render: (text, record, index) =>
                        {

                            return text[0] ? <Image
                                width={80}
                                src={LINK.myHostimgServerImg + "/" + text[0]}
                            />
                                : ""
                        }
                    },


                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        filters: filters.hidden,
                        onFilter: (value, record) =>
                        {

                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!record.hidden} disabled
                                loading={this.state.isloading} />
                        },

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        filterSearch: true,
                        filters: filters.created_at,
                        onFilter: (value, record) =>
                        {
                            return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                        },
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },
                    // {
                    //     title: 'Ngày cập nhật',
                    //     dataIndex: 'updated_at',
                    //     filterSearch: true,
                    //     filters: filters.updated_at,
                    //     onFilter: (value, record) =>
                    //     {

                    //         return moment(record.updated_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                    //     },
                    //     sorter: (a, b) => a.updated_at - b.updated_at,
                    //     render: (text, record, index) =>
                    //     {
                    //         return moment(text).format('DD/MM/YYYY h:m:s');;
                    //     }
                    // },

                ]
            }
        })
    }

    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
    }
    onResetFromCollection = () =>
    {

        this.formSearchCollectionsRef.current.resetFields();
        this.formSearchCollectionsRef.current.submit();
        this.setState(state =>
        {
            return {
                collectionProducts: [],
                metaproducts: this.state.metaproductsTmp
            }
        })
    }

    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchProductsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });




    }
    onSearchCollections = () =>
    {
        this.SetisLoadingSeach(true)
        const formvalues = this.formSearchCollectionsRef.current.getFieldsValue();
        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        filteredvalues.id = this.state.record.id
        this.props.SearchCollectionsProductsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });




    }
    OnPagination = (page, pageSize) =>
    {
        const formvalues = this.formSearchRef.current.getFieldsValue();

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }
        this.props.SearchProductsRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }

    setLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                loading: type
            }
        })
    }

    setList = (products) =>
    {
        this.setState(state =>
        {
            return {
                productsList: products
            }
        })
    }
    setData = (products) =>
    {
        this.setState(state =>
        {
            return {
                productsData: products
            }
        })
    }

    onLoadMore = () =>
    {
        this.setLoading(true);
        this.setList(
            this.state.productsList.concat(
                [...new Array(3)].map(() => ({
                    loading: true,
                    title: "",
                    thumbnail: [],
                    description: "",
                    id: ""
                })),
            ),
        );

        let nextpage = this.state.metaproducts.current_page + 1;
        if (nextpage >= this.state.metaproducts.last_page) {
            //  nextpage = this.state.metaproducts.last_page;
            this.setState(state =>
            {
                return {
                    hiddenLoadmore: true
                }
            })
        }
        this.props.ListCollectionsProductsRequest({
            limit: this.state.metaproducts.per_page,
            page: nextpage, column: this.state.metaproducts.column, sort: this.state.metaproducts.sort, collection_id: this.state.record.id
        })

    };
    setOderdetailsMutilpe = (type, newValue) =>
    {
        // if (newValue.amount <= 0) {
        //     message.warning("sản phẩm đã hết hàng tại kho!");
        //     return;
        // }
        if (newValue.length > 0) {
            this.setState(state =>
            {
                return {
                    productsList: newValue
                }
            })
            return;
        }

    }
    setOderdetailsMutilpe = (type, newValue) =>
    {
        // if (newValue.amount <= 0) {
        //     message.warning("sản phẩm đã hết hàng tại kho!");
        //     return;
        // }
        
        if (Array.isArray(newValue)){
            this.setState(state =>
            {
                return {
                    productsList: newValue
                }
            })
            return;
        }
      
        const { productsList } = this.state;
      
        if (type === 1) {
            var checkDup = productsList.filter(item =>
            {
                return item.id !== newValue.id;
            })
            this.setState(state=>{
                return{
                    productsList:checkDup
                }
            })
            
        } else {
            var checkDup = productsList.filter(item =>
            {
                return item.id === newValue.id;
            })
           
            if (!checkDup[0]?.id) {
                this.setState(state =>
                {
                    return {
                        productsList: [...state.productsList,newValue]
                    }
                })
            }
        }

    }
    render()
    {
        let { transposts, active_tran } = this.state;
        
        return (

            <div className="table_w collecions" >
                <Drawer
                    title={<div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6"><span>Phiếu in</span></div>
                        <div className="col-lg-6 col-md-6 col-sm-6 flex_" style={{ justifyContent: "flex-end" }}></div>
                    </div>} placement="right"
                    onClose={() => { this.setvisible(false); }}
                    visible={this.state.visible} 
                    size={checkScreenSize().width < 600 ? "default" : "large"}

                >
                    <Products isListShow={false}  setOderdetailsMutilpe={this.setOderdetailsMutilpe} isgroup={true}/>
                    {/* <div>

                        <Form name="formsearch" className="fromsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                            padding: "0px !important",
                            width: "100%",
                            display: "flex",
                            margin: 0
                        }}>
                            <Form.Item style={{ margin: 0, width: "100%" }}>
                                <Input.Group compact>
                                    <Row gutter={24} style={{ display: "flex", margin: 0, width: "100%" }}>
                                        <Col span={4} style={{
                                            padding: 0
                                        }}>
                                            <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tiêu đề" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6} style={{
                                            padding: 0
                                        }}>
                                            <Form.Item name="category_id" noStyle style={{ margin: 0 }}>
                                                <TreeSelect
                                                    showSearch
                                                    style={{ width: "100%" }}
                                                    treeData={this.state.ListCategory}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder="Danh mục"
                                                    multiple
                                                    treeDefaultExpandAll

                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} style={{
                                            padding: 0
                                        }}>
                                            <Form.Item name="tags_id" noStyle style={{ margin: 0 }}>
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: "100%" }}
                                                    maxTagCount='responsive'
                                                    placeholder="Tags"
                                                    options={(this.state.ListTags || []).map((d) => ({
                                                        value: d.id,
                                                        label: d.name,
                                                    }))}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} style={{
                                            padding: 0
                                        }}>
                                            <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                <RangePicker />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4} style={{
                                            padding: 0
                                        }}>

                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </Col>
                                    </Row>






                                </Input.Group>
                            </Form.Item>
                        </Form>

                        <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            rowSelection={{
                                selectedRowKeys: this.state.selectedRowKeys1,
                                onChange: (selectedRowKeys, selectedRows) =>
                                {
                                    this.setState({
                                        selectedRowKeys1: selectedRowKeys,
                                        selectedRows1: selectedRows,
                                    });
                                    this.setProductsSeleted(selectedRows);


                                },
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>
                    </div> */}
                </Drawer>
                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Bộ sưu tập</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",

                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <div className="left_">
                        <Form
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                // span: 14,
                            }}
                            layout="horizontal"
                            name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                        >

                            <div className="section_img">



                                <Form.Item label="Tên" name="name" rules={[
                                    {
                                        required: true,
                                        message: "Tên bộ sưu tập là bắt buộc"
                                    },
                                ]}>
                                    <Input placeholder="Tên" />
                                </Form.Item>
                                <Form.Item label="SL hiển thị" name="amount_show" initialValue={10}>
                                    <InputNumber placeholder="Số lượng hiển thị" />
                                </Form.Item>
                                <Form.Item label="Vị trí" name="position" initialValue={1}>
                                    <InputNumber placeholder="Vị trí hiển thị" />
                                </Form.Item>
                                <Form.Item label="Sắp xếp" name="rank" initialValue={1}>
                                    <InputNumber placeholder="Sắp xếp" />
                                </Form.Item>
                                <Form.Item label="Hiển thị" name="hidden"  >
                                    <SwitchA checked={!convertToBoolean(this.state.record.hidden)} onChange={(e) =>
                                    {
                                        this.setState(state =>
                                        {
                                            return {
                                                record: { ...state.record, hidden:!!convertToBoolean(this.state.record.hidden) }
                                            }
                                        })
                                    }} />
                                </Form.Item>
                                <Form.Item label="Hiển thị app" name="showapp"  >
                                    <SwitchA checked={this.state.record.showapp} onChange={(e) =>
                                    {
                                       
                                        this.setState(state =>
                                        {
                                            return {
                                                record: { ...state.record, showapp: e }
                                            }
                                        })
                                    }} />
                                </Form.Item>
                                <Form.Item label="Slider" name="isslider" >
                                    <SwitchA checked={this.state.record.isslider} onChange={(e) =>
                                    {
                                        this.setState(state =>
                                        {
                                            return {
                                                record: { ...state.record, isslider: e }
                                            }
                                        })
                                    }} />
                                </Form.Item>
                                <Form.Item  >
                                    <Button type="default" htmlType="button" style={{ marginRight: "10px" }} danger onClick={()=>{this.setvisible(true)}}>Thêm sản phẩm</Button>

                                </Form.Item>
                                <div style={{maxHeight:"400px",overflow:"auto"}}>
                                    <Form name="formsearchCollection" ref={this.formSearchCollectionsRef} onFinish={this.onSearchCollections} style={{
                                        // padding: "0px",
                                        // width: "100%",
                                        // display: "flex",
                                        // position: "sticky",
                                        // zIndex: 100,
                                        // top: "-20px"
                                    }}>
                                        <Form.Item style={{ margin: 0, backgroundColor: Colors.white }} >
                                            <Input.Group compact>
                                                <div className="row">

                                                    <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                        <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                                            <Input placeholder="Tên sản phẩm" />
                                                        </Form.Item>
                                                    </div>

                                                    <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                        <Form.Item name="slug" noStyle style={{ margin: 0 }}>
                                                            <Input placeholder="slug" />
                                                        </Form.Item>
                                                    </div>





                                                    <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                        <Form.Item noStyle style={{ margin: 0 }} >
                                                            <div style={{ display: "flex" }}>
                                                                <Button type="primary" icon={<SearchOutlined />} htmlType="button" loading={this.state.isLoadingSearch} onClick={this.onSearchCollections} />
                                                                <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFromCollection}>Reset</Button>
                                                            </div>
                                                        </Form.Item>

                                                    </div>
                                                </div>
                                            </Input.Group>
                                        </Form.Item>
                                    </Form>
                                    <div style={{ fontWeight: "bold", color: Colors.colortext }}>
                                        Danh sách sản phẩm ({"tổng:" + this.state.productsList.length})</div>
                                    <List
                                        className="demo-loadmore-list"
                                        loading={this.state.isloading}
                                        itemLayout="horizontal"
                                        loadMore={!this.state.hiddenLoadmore && <div
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 12,
                                                height: 32,
                                                lineHeight: '32px',
                                            }}
                                        >
                                            {/* <Button onClick={this.onLoadMore}>loading more</Button> */}
                                        </div>}
                                        dataSource={this.state.CollectionsProducts && this.state.CollectionsProducts.length > 0 ? this.state.CollectionsProducts : this.state.productsList}
                                        renderItem={(item) => (
                                            <List.Item
                                                actions={[<button type="button" className="reset_btn btn_delete btn__" key="list-loadmore-more" onClick={() => this.deleteSelected(item)}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>]}
                                            >
                                                <Skeleton avatar title={false} loading={item.loading} active>
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={LINK.myHostimgServerImg + "/" + item.thumbnail} />}
                                                        title={<a href="">{item.title}</a>}
                                                        description={CutString(item.description, 100, false)}
                                                    />
                                                    {/* <div>content</div> */}
                                                </Skeleton>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </div>

                            <Form.Item noStyle >
                                <div className="flex_" style={{marginTop:"20px"}}>
                                    <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                                    <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>

                            </Form.Item>
                        </Form >
                    </div>

                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreCollectionsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreCollectionsRequest(data, id));
        },
        ResetStoreCollections: () =>
        {
            dispatch(ActionsWeb.ResetStoreCollections());
        },
        ListCollectionsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCollectionsRequest(body));
        },

        CollectionByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.CollectionByIdRequest(body));
        },
        SearchProductsRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchProductsRequest(body, data));
        },
        ListCategoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
        SearchCollectionsProductsRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchCollectionsProductsRequest(body, data));
        },
        ListCollectionsProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCollectionsProductsRequest(body));
        },
        ResetSearchCollectionsProducts: () =>
        {
            dispatch(ActionsWeb.ResetSearchCollectionsProducts());
        }, 
        resetProductsRequest: () =>
        {
            dispatch(ActionsWeb.resetProductsRequest());
        }, 
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);