import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import
{
    SearchOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import * as Services from './../../service';
import * as Colors from './../../constants/Colors';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from './../../constants/Links';
import { META } from "./../../utils/constant";

import { useDispatch, useSelector } from 'react-redux';
import * as ActionsWeb from "./../../actions/indexWeb";
import { Link } from "react-router-dom";
import moment from 'moment';
import ItemProduct from "./../itemProduct/index";
// import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;
const count = 3;
const Index = (props) =>
{
    // let location=useLocation();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isLoadingSearch, SetisLoadingSearch] = useState(false);
    const [productsList, setproductsList] = useState([]);
    const [ListProducts, setListProducts] = useState([]);
    const [data, setdata] = useState([]);
    const [dataList, setdataList] = useState([]);
    const [ListTags, setListTags] = useState([]);
    const [ListCategory, setListCategory] = useState([]);
    const formSearchRef = new useRef(null)
    const [isloading, setisloading] = useState(false);
    const [isFirst, setisFirst] = useState(false);
    const [selectedRowKeys1, SetselectedRowKeys1] = useState([]);
    const [selectedRows1, SetselectedRows1] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false)
    const [columns, setColumns] = useState([
        {
            title: 'STT',
            dataIndex: 'key',


        },
        {
            title: 'Slug',
            dataIndex: 'slug',

            filterSearch: true,


        },
        {
            title: 'Tên',
            dataIndex: 'name',

            filterSearch: true,
        },

        {
            title: 'ngày tạo',
            dataIndex: 'created_at',
            

        },
        {
            title: 'Ngày cập nhật',
            dataIndex: 'updated_at',
            
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            width: "20%",
         
        },
    ],);
    const [initLoading, setInitLoading] = useState(true);
    const setProductsSeleted = (selectedRows) =>
    {

        const result = selectedRows.filter(itemA => !productsList.some(itemB => (1 * itemB.id) === (1 * itemA.id)));
        setproductsList([...productsList, ...result]);

    }
 
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    useEffect(() =>
    {
       
        if (ListProducts){
            if (ListProducts.code !== 200 && ListProducts.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
                return
            }
         
            data_()
            SetisLoadingSearch(false)
        }
        

      

        return () =>
        {

        };
    }, [ListProducts]);
    useEffect(() =>
    {

        setListProducts(Web.ListProducts);

        return () =>
        {

        };
    }, [Web.ListProducts]);
    useEffect(() =>
    {
        setListCategory(Web.ListCategory.data);

        return () =>
        {

        };
    }, [Web.ListCategory.data]);
    useEffect(() =>
    {
        setListTags(Web.ListTags.data);

        return () =>
        {

        };
    }, [Web.ListTags.data]);
 
    useEffect(() =>
    {
        
        try {
          
            dispatch(ActionsWeb.SearchProductsRequest({
                limit: meta.per_page,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }, { data: {} }))
            dispatch(ActionsWeb.ListCategory({
                limit: meta.per_page * 1000,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }))
            dispatch(ActionsWeb.ListTags({
                limit: meta.per_page * 1000,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }))

            setisFirst(true)
        } catch (error) {

        }
        return () =>
        {

        };
    }, []);
    const onSearch = (values) =>
    {
        SetisLoadingSearch(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        dispatch(ActionsWeb.SearchProductsRequest({
            limit: meta.per_page,
            page: meta.current_page, column: meta.column, sort: meta.sort
        }, { data: filteredvalues }))

        setdataList([]);
        setList([]);


    }
    const onResetFrom = () =>
    {

        formSearchRef.current.resetFields();
        formSearchRef.current.submit();
    }

    const data_ = () =>
    {


        try {


           
            if (ListProducts.length <= 0) {
                return;
            }

            let data_ = [];
            let listfil = {};
            let meta_ = {
                ...meta,
                current_page: ListProducts.current_page,
                first_page_url: ListProducts.first_page_url,
                from: ListProducts.from,
                last_page: ListProducts.last_page,
                last_page_url: ListProducts.last_page_url,
                links: ListProducts.links,
                next_page_url: ListProducts.next_page_url,
                path: ListProducts.path,
                per_page: 1 * ListProducts.per_page,
                prev_page_url: ListProducts.prev_page_url,
                to: ListProducts.to,
                total: ListProducts.total,
            };

            let filtilte = [];
            let filcode = [];
            let filslug = [];
            let filprice = [];
            let fildiscount = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            ListProducts.data.map((item, index) =>
            {
                filcode = [...filcode, {
                    text: item.code,
                    value: item.code,
                }]
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                fildiscount = [...fildiscount, {
                    text: item.discount,
                    value: item.discount,
                }]
                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = item.id

                data_.push(item)

            });
            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]
            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, discount: fildiscount, code: filcode }
            setColumn_(listfil);
            setdata(data_)
            setMeta(meta_)

           
          
            setInitLoading(false);
            setLoading(false);
            if(!props.isgroup){
                setDataClassify(data_)
            }else{
                if (isFirst){
                    setdataList(data_);
                    setList(data_);
                    setisFirst(false)
                }else{
                    const newData = dataList.concat(data_);

                    setdataList(newData);
                    setList(newData);
                }
               

            }
          
          
            window.dispatchEvent(new Event('resize'));
        } catch (error) {
            console.error(error);
        }
    }
 
    const setColumn_ = (filters) =>
    {
     
        setColumns([
                {
                    title: '#',
                    dataIndex: 'key',
                    width: "5%",
                    align: 'center',

                },
            {
                title: 'code',
                dataIndex: 'code',
                // filterMode: 'tree',
                filterSearch: true,
                filters: filters.code,
                onFilter: (value, record) =>
                {

                    return record.code.startsWith(value)

                },
                sorter: (a, b) => a.code - b.code,
              
            },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    // filterMode: 'tree',
                    filterSearch: true,
                    filters: filters.title,
                    onFilter: (value, record) =>
                    {

                        return record.title.startsWith(value)

                    },
                    sorter: (a, b) => a.title - b.title,
                    render: (text, record, index) =>
                    {

                        return <Link to={LINK.WEB_PRODUCTS_EDIT + "/" + record.id}>Services.CutString(text, 50, false)</Link> ;
                    }
                },
                {
                    title: 'thumbnail',
                    dataIndex: 'thumbnail',
                    render: (text, record, index) =>
                    {

                        return text[0] ? <Image
                            width={80}
                            src={LINK.myHostimgServerImg + "/" + text[0]}
                        />
                            : ""
                    }
                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    filterSearch: true,
                    filters: filters.created_at,
                    onFilter: (value, record) =>
                    {
                        return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                    },
                    sorter: (a, b) => a.created_at - b.created_at,
                    render: (text, record, index) =>
                    {
                        return moment(text).format('DD/MM/YYYY h:m:s');;
                    }

                },


            ])
    }
    const OnPagination = (page, pageSize) =>
    {
      
        const formvalues = formSearchRef.current.getFieldsValue();

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        dispatch(ActionsWeb.SearchProductsRequest({
            limit: pageSize,
            page: page, column: meta.column, sort: meta.sort
        }, { data: filteredvalues }))


    }
    const onLoadMore = () =>
    {
        if (meta.current_page < meta.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );
                   
            OnPagination(meta.current_page + 1, meta.per_page)

        }

    };
    const SetselectedRowKeys1list=(e,id)=>{
        if(e.target.checked===true){
            SetselectedRowKeys1([...selectedRowKeys1, id])
            
        }else{
            const newselected = selectedRowKeys1.filter(item => {
                    return item!==id
            })
            SetselectedRowKeys1(newselected)
        }
       
    }

    const SetselectedRows1list = (e,item) =>
    {
       
        if (e.target.checked === true) {
            setIndeterminate(!![...selectedRows1, item].length && [...selectedRows1, item].length < list.length);
            setCheckAll([...selectedRows1, item].length === list.length);


            SetselectedRows1([...selectedRows1, item])
            if (props.setOderdetailsMutilpe){
                props.setOderdetailsMutilpe(2, item)
            }
        } else {
            const newselected = selectedRows1.filter(el =>
            {
                return el !== item
            })
            setIndeterminate(!!newselected.length && newselected.length < list.length);
            setCheckAll(newselected.length === list.length);
            SetselectedRows1(newselected)
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(1, item)
            }
        }
    }
    const setDataClassify = (data) =>
    {

        const data_ = []
        data.map((item, index) =>
        {

            item.product_id = item.id;
            item.num = 1;
            item.note = "";
            item.price_or = item.price

            if (item.classifys) {
                let classsifyt = Object.values(item.classifys);

                if (classsifyt.length > 0) {

                    classsifyt.map((el, i) =>
                    {

                        if (el.children && el.children.length > 0) {
                            el.children.map((elc, ic) =>
                            {

                                for (var x in elc) {
                                    const elcI = elc[x];

                                    if (elcI.store_id) {
                                        for (var xs in elcI.store_id) {
                                            let stID = el.store_id[xs];
                                            item.price = elcI?.price ? elcI?.price : item.price
                                            item.discount_percent = elcI?.discount_percent ? elcI?.discount_percent : item.discount_percent;
                                            item.discount = elcI?.discount ? elcI?.discount : item.discount;
                                            item.price_or = elcI?.price ? elcI?.price : item.price;
                                            item.store_name_c = elcI.store_name[stID];
                                            item.store_id_c = stID;
                                            item["classifystmp"] = elcI
                                            item["classify_id"] = elcI.id
                                            item["amount"] = elcI.amount
                                            item["voucher"] = ""
                                            item["classifys_name"] = el.classifys_name + " x " + elcI.classifys_name
                                            item.key = `${item.id}-${el.id}-${elcI.id}`
                                            data_.push({ ...item, classifystmp: el })

                                        }
                                    }

                                }

                            })
                        } else {

                            if (el.store_id) {
                                for (var xs in el.store_id) {
                                    let stID = el.store_id[xs];
                                    item.price = el?.price ? el?.price : item.price
                                    item.discount_percent = el?.discount_percent ? el?.discount_percent : item.discount_percent;
                                    item.discount = el?.discount ? el?.discount : item.discount;
                                    item.price_or = el?.price ? el?.price : item.price;
                                    item.store_name_c = el.store_name[stID];
                                    item.store_id_c = stID;
                                    item["classifys_name"] = el.classifys_name
                                    item["classify_id"] = el.id
                                    item["voucher"] = ""
                                    item["amount"] = el.amount
                                    item.key = `${item.id}-${el.id}`;

                                    data_.push({ ...item, classifystmp: el })
                                }
                            }
                        }

                    });

                }

            }
            else {
                item["voucher"] = ""
                item.key = `${item.id}`;
                data_.push(item)
            }

        });
        const newData = dataList.concat(data_);
        setdataList(newData);
        setList(newData);

    }
    const onCheckAllChange = (e) =>
    {
        if (e.target.checked === true) {
            const keys = list.map((item) => {
                return item.key
            })
            SetselectedRowKeys1(keys);
            SetselectedRows1(list)
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(2, list)
            }
        } else {
            SetselectedRowKeys1([]);
            SetselectedRows1([])
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(1, [])
            }
        }
     
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <div className='dropdown_rim'>
            <Form name="formsearch" className="fromsearch" ref={formSearchRef} onFinish={onSearch} style={{
                padding: "0px !important",
                width: "100%",
                display: "flex",
                margin: 0
            }}>
                <Form.Item style={{ margin: 0, width: "100%" }}>
                    <Input.Group compact>
                        <div className="row">

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Tiêu đề" />
                                </Form.Item>
                            </div>

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                <Form.Item name="category_id" noStyle style={{ margin: 0 }}>
                                    <TreeSelect
                                        showSearch
                                        style={{ width: "100%" }}
                                        treeData={ListCategory}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Danh mục"
                                        multiple
                                        treeDefaultExpandAll

                                    />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                <Form.Item name="tags_id" noStyle style={{ margin: 0 }}>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        maxTagCount='responsive'
                                        placeholder="Tags"
                                        options={(ListTags || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                    <RangePicker />
                                </Form.Item>
                            </div>

                            <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                <Form.Item noStyle style={{ margin: 0 }} >
                                    <div style={{ display: "flex" }}>
                                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={isLoadingSearch} />
                                        <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={onResetFrom}>Reset</Button>
                                    </div>
                                </Form.Item>

                            </div>
                            <div className="col_ col-lg-2 col-md-3 col-sm-6" style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>

                                <Checkbox checked={checkAll}  onChange={(e) => { onCheckAllChange(e) }} indeterminate={indeterminate}></Checkbox>

                            </div>
                        </div>






                    </Input.Group>
                </Form.Item>
            </Form>
            {!props.isListShow && <div className='list_scroll'>
                <List
                    className="demo-loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item className='list_item_custom'
                            actions={[<Checkbox checked={selectedRowKeys1.includes(item.key)} onChange={(e) => { SetselectedRowKeys1list(e, item.key); SetselectedRows1list(e, item)}}></Checkbox>]}
                        >
                            <Skeleton avatar title={false} loading={item.loading} active>
                                {/* <List.Item.Meta
                                    avatar={<div className='img_list'><Image
                                        className='img'
                                        style={{ objectFit: "contain" }}
                                        src={LINK.myHostimgServerImg + "/" + item?.thumbnail?.[0]}
                                    /></div>}
                                    title={item.code}
                                    description={<div><strong>{Services.CutString(item?.title, 10, true)}</strong><br></br> {Services.CutString(item?.description, 20, true)}</div>}
                                /> */}
                                <ItemProduct item={item} placement={"right"}/>
                                
                                {/* <div>content</div> */}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </div>}
            {props.isListShow && <><Table rowClassName={(record, index) =>
                {
                    return "__row_";
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys1,
                    onChange: (selectedRowKeys, selectedRows) =>
                    {
                        SetselectedRowKeys1(selectedRowKeys)
                        SetselectedRows1(selectedRows)

                        setProductsSeleted(selectedRows)
                    },
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{

                    y: "60vh",
                }}
                style={{ paddingBottom: "30px" }}
            />
            <div className="pagination_">
                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={meta && meta.per_page ? meta.per_page : 10} total={meta && meta.total ? meta.total : 10} showSizeChanger onChange={OnPagination} />
                </div></>}
        </div>
    );
};

export default Index;
