import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Form, DatePicker, Image, Tooltip, Card, Row, Col, Switch as SwitchA,Select, } from 'antd';

import
{
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';

import { META } from "../../../../utils/constant";
import { checkScreenSize, convertToBoolean, getParamsUrl } from "../../../../service";
import Tag from "antd/es/tag";
import IndexMobile from "./IndexMobile";
const { Search } = Input;
const { RangePicker } = DatePicker;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.state = {

            ListBanners: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Mã',
                    dataIndex: 'code',

                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.code - b.code,
                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Điện thoại',
                    dataIndex: 'phone',
                    sorter: (a, b) => a.phone - b.phone,

                },
                {
                    title: 'Địa chỉ',
                    dataIndex: 'address',
                    sorter: (a, b) => a.address - b.address,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: "",
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreBanners: {},
            search: {},
            isLoadingSearch: false,
            isloading: false,
            isShowList: false,
            isNewList: false,
           
            listBanerTypes:[
                {key:1,name:"Banner"},
                {key:2,name:"Sliders"},
                {key:3,name:"Thương hiệu"},
                {key:4,name:"Dịch vụ"},
                {key:5,name:"Thanh toán"},
                {key:6,name:"Mạng xã hội"},
            ]
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreBannersRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }

            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }
        if(filteredvalues.type){
            filteredvalues.type=parseInt(filteredvalues.type)
        }
        this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchBannersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListBanners && nextProps.Web.ListBanners !== prevState.ListBanners) {
            console.log("Comment", nextProps.Web.ListBanners);


            return {
                ListBanners: nextProps.Web.ListBanners,
                isLoadingSearch: false
            };

        }
        if (nextProps.Web.StoreBanners && nextProps.Web.StoreBanners !== prevState.StoreBanners) {
            return {
                StoreBanners: nextProps.Web.StoreBanners,

            };

        }

        return null;


    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreBanners !== prevState.StoreBanners) {
            let { StoreBanners } = this.state;
            try {
                if (StoreBanners.code === 200) {
                    if (StoreBanners.message && typeof StoreBanners.message === "string") {
                        message.success(StoreBanners.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchBannersRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreBanners.message && typeof StoreBanners.message === "string") {

                        message.error(StoreBanners.message)
                    } else {
                        for (let x in StoreBanners.message) {
                            let mess = StoreBanners.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreBanners()
            this.handleCancel();
        }

        if (this.state.ListBanners !== prevState.ListBanners) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        with: "5%",
                        align: "center"

                    },
                    {
                        title: 'Tiêu đề',
                        dataIndex: 'title',

                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) =>
                        {
                            return record.title.startsWith(value)

                        },
                        sorter: (a, b) => a.title - b.title,
                    },
                    {
                        title: 'Loại',
                        dataIndex: 'type',
                        sorter: (a, b) => a.type - b.type,
                        render: (text, record, index) =>
                        {
                            if (text === 1) {
                                return <Tag key={index} color={Colors.arrColors[0]} >Banner</Tag>
                            }
                            if (text === 2) {
                                return <Tag key={index} color={Colors.arrColors[1]} >Sliders</Tag>
                            }
                            if (text === 3) {
                                return <Tag key={index} color={Colors.arrColors[2]} >Thương hiệu</Tag>
                            }
                            if (text === 4) {
                                return <Tag key={index} color={Colors.arrColors[3]} >Dịch vụ</Tag>
                            }
                            if (text === 5) {
                                return <Tag key={index} color={Colors.arrColors[4]} >Payment</Tag>
                            }
                            if (text === 6) {
                                return <Tag key={index} color={Colors.arrColors[5]} >Mạng xã hội</Tag>
                            }
                        }
                    },
                    {
                        title: 'Hình ảnh',
                        dataIndex: 'images',
                        align: "center",
                        render: (text, record, index) =>
                        {

                            return text ? <Image
                                width={80}
                                src={LINK.myHostimgServerImg + "/" + text}
                            />
                                // <img src={LINK.myHostimgServerImg + "/" + text[0]}
                                //     style={{ width: "80px", height: "80px", objectFit: "contain" }} alt="" />
                                : <span style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: "<i class='" + record.icon + "'></i>" }} />
                        }
                    },
                    {
                        title: 'Link',
                        dataIndex: 'link',

                        sorter: (a, b) => a.link - b.link,

                    },

                    {
                        title: 'Vị trí',
                        dataIndex: 'rank',
                        align: "center",
                        sorter: (a, b) => a.rank - b.rank,

                    },
                    {
                        title: 'Hiển thị',
                        dataIndex: 'active',
                        sorter: (a, b) => a.active > b.active,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={convertToBoolean(record.active)} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} />
                        },

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) =>
                        {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, active: e }

        this.SetisLoading(true)
        this.props.StoreBannersRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: TextTrackCueList,
            }
        });
    }
    data = () =>
    {
        try {


            let { ListBanners } = this.state;

            if (ListBanners.length <= 0) {
                return;
            }
            let data = [];
            let listfil = [];
            let meta = {
                ...this.state.meta,
                current_page: ListBanners.current_page,
                first_page_url: ListBanners.first_page_url,
                from: ListBanners.from,
                last_page: ListBanners.last_page,
                last_page_url: ListBanners.last_page_url,
                links: ListBanners.links,
                next_page_url: ListBanners.next_page_url,
                path: ListBanners.path,
                per_page: ListBanners.per_page,
                prev_page_url: ListBanners.prev_page_url,
                to: ListBanners.to,
                total: ListBanners.total,
            };
            listfil["title"] = [];


            ListBanners.data.map((item, index) =>
            {
                listfil["title"].push({
                    text: item.title,
                    value: item.title,
                });


                item.key = item.id
                data.push(item)

            });

            this.setColumn_(listfil);
            this.setState(state =>
            {
                return {
                    data: data,
                    meta: meta
                }
            })
        } catch (error) {
            console.error(error);
        }
    }
    onChangetext = (e, type, group) =>
    {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }


    editRows = (record) =>
    {

        this.props.history.push({
            pathname: LINK.WEB_BANNERS_EDIT + "/" + record.id,
            state: record
        });

    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1,active:0}

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchBannersRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });

    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchBannersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_BANNERS}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...META }
            }
        })
    }
    render()
    {


        return (

            <div className="main_content banner_" >
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" style={{ overflow: "unset", padding: 0 }}>
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Banner & Slider</span>
                        </div>
                        <Link to={LINK.WEB_BANNERS_EDIT + "/add"}> <Button type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",

                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "20px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <div className="row">

                                        <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                            <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                                <Input placeholder="Tiêu để" />
                                            </Form.Item>
                                        </div>


                                        <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                            <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                <RangePicker />
                                            </Form.Item>
                                        </div>
                                        <div className="col_ col-lg-3 col-md-3 col-sm-6">
                                            <Form.Item name="type" noStyle style={{ margin: 0 }}>
                                            <Select

                                                    maxTagCount='responsive'
                                                    style={{
                                                        width:"100%"
                                                    }}
                                                    placeholder={
                                                    "Loại"
                                                    }

                                                    

                                                    // value={CheckUnionArr(this.state.record.attribute_products_id, item.children, "id")}
                                                    // onChange={(e) => { this.onChangetext(e, "attribute_products_id", "record", false) }}
                                                    options={(this.state.listBanerTypes || []).map((d) => ({
                                                        value: d.key,
                                                        label: d.name,
                                                    }))}
                                                    />
                                            </Form.Item>
                                        </div>

                                        <div className="col_ col-lg-3 col-md-3 col-sm-6">

                                            <Form.Item noStyle style={{ margin: 0 }} >
                                                <div style={{ display: "flex" }}>
                                                    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                    <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                </div>
                                            </Form.Item>

                                        </div>
                                    </div>
                                </Form>
                            </div>

                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            setIsNewList={() =>
                            {
                                this.setState(state =>
                                {
                                    return {
                                        isNewList: !state.isNewList
                                    }
                                })
                            }}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList && <><Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}

                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}

                            style={{ paddingBottom: "30px" }}
                        />
                            <div className="pagination_">
                                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                            </div>
                        </>
                        }
                    </Card>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListBannersRequest: (body) =>
        {
            dispatch(ActionsWeb.ListBannersRequest(body));
        },
        StoreBannersRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreBannersRequest(data, id));
        },
        ResetStoreBanners: () =>
        {
            dispatch(ActionsWeb.ResetStoreBanners());
        },
        SearchBannersRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchBannersRequest(body, data));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);