import React, { Component } from "react";
// import "./components/style.css";
import * as LINK from "./constants/Links";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AuthenticatedRoute from "./pages/AuthenticatedRoute/AuthenticatedRoute ";
import routers from "./routers";
import { Helmet } from 'react-helmet';
// import { connect } from "react-redux";
// import * as Actions from "./actions/index";
import authentication from "./components/authendication/Authentication";
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute("href");
import 'antd/dist/antd.css';
import SocketConnect from "./components/SocketConnect/index";
import  Notifications from "./components/Notifications/index";
class App extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      login: ""
    };

  }


  RedirectLogin = () =>
  {
    <Redirect to={LINK.LOGIN} />
  }
  render()
  {
    let isLogined = authentication.isAuthenticated();
    
    return (

   
        <Router>
        <SocketConnect/>
        <Notifications/>
          <Switch>
            {routers.length > 0 &&
              routers.map((route, index) => (
                <AuthenticatedRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  isLogined={isLogined}
                  
                  render={props => (
                    route.main({ ...props, routers: route.routers })
                  )}

                />
              ))}
            <Route exact path={LINK.endpoint}>
              <Redirect to={LINK.WEB} />
            </Route>

          </Switch>


        </Router>
   
    );
  }
}


export default App;
