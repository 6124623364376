import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import Upload from "./Upload";
import { convertToBoolean, replaceThous, } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card, Radio, InputNumber, Divider, Modal
} from 'antd';
import
{ FileImageOutlined, SmileOutlined, PlusCircleOutlined,FacebookOutlined
} from '@ant-design/icons';

import * as Colors from "../../../../constants/Colors";
import { META } from "../../../../utils/constant";
import Draggable from 'react-draggable';
import { IconsFontawesome } from "../../../../constants/ListIcons";
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.draggleRef=new createRef(null);
        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            record: {
                type: 1,
                deleted: 0,
                active: 1
            },
            StoreBanners: {},
            ListBanners: [],
            data: [],
            isloading: false,
            imgesInfo: {
                file: []
            },
            imgesInfoRemove: [],
            isupload: 0,

            required: {
                avata: false
            },
            setNewFormData: [{ title: "", link: "", rank: 1, images: '', active: true }],
            disabled: true,
            visible: false,
            arrSlideType:[2,3,4,5,6]
        }


    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
   
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
       
        
        if (nextProps.Web.ListBanners && nextProps.Web.ListBanners.id !== prevState.record.id) {
           

            return {
                record: nextProps.Web.ListBanners,

            };

        }
        if (nextProps.Web.StoreBanners && nextProps.Web.StoreBanners !== prevState.StoreBanners) {
            return {
                StoreBanners: nextProps.Web.StoreBanners,

            };

        }


       
        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.record.id !== prevState.record.id) {
        
            const { record } = this.state;
            // const imgesInfo_ = { file: { images0: [] } };
            // if (parseInt(record.type) === 1) {

                
            //     const record_ = {};
            //     for (var x in record) {
            //         var element = record[x];
            //         if (x !== "type" && x !== "id") {
            //             record_[x + 0] = element;

            //         }

            //     }
             
            //     imgesInfo_.file.images0.push({
            //         uid: -1 * 1,
            //         name: record.images,
            //         status: 'done',
            //         url: LINK.myHostimgServerImg + "/" + record.images,
            //     });

            //     const mergeRecord = { ...record, images0: [record.images] };
            //     console.log("nextProps.Web.ListBanners record.type ",record_);
            //     this.formRef.current.setFieldsValue({ ...record_, active: record_.active === 1 ? true : false, type: record.type })
            //     this.setState(state =>
            //     {
            //         return {
            //             imgesInfo: imgesInfo_,
            //             setNewFormData: [record_],
            //             record: mergeRecord
            //         }
            //     }, () =>
            //     {
            //         this.formRef.current.setFieldsValue({ ...this.state.record, type: record.type})

            //     })
            // }
            // if (this.state.arrSlideType.includes(parseInt(record.type))) {
                const imgesInfo_ = { file: {} };
                const dataSet_ = [];
                const recordForm = {};
                const imagesLink = {};
                const activeSides = {};
                if (record.data) {
                    const data = JSON.parse(record.data);
                    for (var x in data) {
                        var element = data[x];

                        const record_ = {};
                        for (var y in element) {
                            if (y!=="icon"){
                                record_[y] = element[y];
                                recordForm[[y + x]] = element[y];
                            }else{
                                record_[y] = element[y];
                                recordForm[[y]] = element[y];
                            }
                           

                        }
                        if (!imgesInfo_.file["images" + x]) {
                            imgesInfo_.file["images" + x] = []
                        }
                        imagesLink["images" + x] = [element.images]
                        activeSides["active" + x] = element.active
                        activeSides["IsShowInfo" + x] = element.IsShowInfo
                        imgesInfo_.file["images" + x].push({
                            uid: -1 * 1,
                            name: element.images,
                            status: 'done',
                            url: LINK.myHostimgServerImg + "/" + element.images,
                        });
                        dataSet_.push(record_)
                    }
                    console.log("dataSet_dataSet_dataSet_ ",recordForm);
                    this.setState(state =>
                    {
                        return {
                            setNewFormData: dataSet_,
                            imgesInfo: imgesInfo_,
                            record: { ...state.record, ...imagesLink, ...activeSides, },

                        }
                    }, () =>
                    {
                        
                        this.formRef.current.setFieldsValue({ ...recordForm, type: record.type })
                    })
                  
                    // for (var x in data) {
                    //     var element = data[x];
                    //     if (x !== "type") {
                    //         record_[x + 0] = element;
                    //     }

                    // }


                }

            // }
        }
        if (this.state.StoreBanners !== prevState.StoreBanners) {
            let { StoreBanners } = this.state;
            try {
                if (StoreBanners.code === 200) {
                    if (StoreBanners.message && typeof StoreBanners.message === "string") {
                        message.success(StoreBanners.message)
                       
                        // this.setState(state =>
                        // {
                        //     return {
                        //         record: {

                        //             hidden: 0,
                        //             deleted: 0
                        //         }
                        //     }
                        // })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreBanners.message && typeof StoreBanners.message === "string") {

                        message.error(StoreBanners.message)
                    } else {
                        for (let x in StoreBanners.message) {
                            let mess = StoreBanners.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }

            this.props.ResetStoreBanners()
            this.SetisLoading(false)
        }
        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;
            let type = UploadFileLa.type;

            if (UploadFileLa.code === 200) {
                this.setState(state =>
                {
                    return {
                        record: { ...state.record, [type]: UploadFileLa.data }
                    }
                }, () =>
                {
                    if (this.state.isupload === type) {
                        this.onFinishForm(this.state.values);
                    }
                })
            } else {
                message.warn("Upload file lỗi!")
            }




            this.props.ResetUploadFileLa();
        }





    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    isUpdate:true
                }
            })
            this.props.BannerByIdRequest({ id });
        }
     


    }
    data = () =>
    {
        let { ListRegion } = this.state;
        // if (!ListRegion) {
        //     ListRegion = this.props.Web.ListRegion
        // }

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    CheckDataRequired = () =>
    {
        const { imgesInfo,record } = this.state;

        if (imgesInfo.file.length <= 0 && !record.icon) {
            message.warn("Hình ảnh chính là bắt buộc");
            this.setState(state =>
            {
                return {
                    required: { ...state.required, avata: true }
                }
            })

            return;
        }


        return true;

    }

    uploadFile = (values) =>
    {
        const { imgesInfo, record, } = this.state;


        this.setState(state =>
        {
            return {
                required: {},
                values: values
            }
        })

        if (!this.CheckDataRequired()) {
            return;
        }
        let isupload = 0;
        // this.SetisLoading(true)

        if (imgesInfo.file) {
            for (let x in imgesInfo.file) {
                const element = imgesInfo.file[x];
                let arravata = element.filter(item =>
                {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    this.props.UploadFileLaRequest(arravata, "banners", x);
                    isupload = x
                }
            }


        }
        if (imgesInfo.files) {
            for (let x in imgesInfo.files) {
                const element = imgesInfo.files[x];
                let arravata = element.filter(item =>
                {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    this.props.UploadFileLaRequest(arravata, "banners", x);
                    isupload = x
                }
            }

        }
        if (imgesInfo.video) {
            for (let x in imgesInfo.video) {
                const element = imgesInfo.video[x];
                let arravata = element.filter(item =>
                {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    this.props.UploadFileLaRequest(arravata, "video", x);
                    isupload = x
                }
            }

        }

        this.setState(state =>
        {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    onFinishForm = (values) =>
    {
      
        const { record, imgesInfo,setNewFormData } = this.state;
    
        const data = [];
        const files = imgesInfo.file;
        const re_ = {};
        for (var x in values) {

            const element = values[x];
            let lastChar = x.slice(-1); // Lấy ký tự cuối cùng


            let isLastCharNumber = !isNaN(lastChar); // Kiểm tra xem ký tự cuối cùng có phải là một số hay không
            if (isLastCharNumber) {
                if (!re_[lastChar]) {
                    re_[lastChar] = {}
                }
                let newStr = x.slice(0, -1);
                re_[lastChar][newStr] = element;
                if (record["images" + lastChar]) {
                    re_[lastChar]["images"] = record["images" + lastChar][0];
                }
            } else {

            }


            // data.push(re_);
        }
       

        for (var x in record) {

            const element = record[x];
            let lastChar = x.slice(-1); // Lấy ký tự cuối cùng
            let isLastCharNumber = !isNaN(lastChar); // Kiểm tra xem ký tự cuối cùng có phải là một số hay không
            if (isLastCharNumber) {
                if (!re_[lastChar]) {
                    re_[lastChar] = {}
                }
                let newStr = x.slice(0, -1);
                if (newStr === "active") {
                    re_[lastChar][newStr] = element;
                }


            } else {

            }


            // data.push(re_);
        }
        for (var x in record) {

            const element = record[x];
            let lastChar = x.slice(-1); // Lấy ký tự cuối cùng
            let isLastCharNumber = !isNaN(lastChar); // Kiểm tra xem ký tự cuối cùng có phải là một số hay không
            if (isLastCharNumber) {
                if (!re_[lastChar]) {
                    re_[lastChar] = {}
                }
                let newStr = x.slice(0, -1);
                if (newStr === "description") {
                    re_[lastChar][newStr] = element;
                }


            } else {

            }


            // data.push(re_);
        }
        for (var x in setNewFormData) {
            re_[x]["icon"] = setNewFormData[x].icon;
            re_[x]["active"] = setNewFormData[x].active;
            re_[x]["IsShowInfo"] = setNewFormData[x].IsShowInfo;
        }
      
        let record_ = {}
        if (record.type === 1) {
            record_ = { ...re_[0], data: JSON.stringify(Object.values(re_)), type: record.type, active: record.active, deleted: record.deleted, id: record.id };

        } else {
            record_ = { data: JSON.stringify(Object.values(re_)), ...re_[0], images: re_[0].images, type: record.type, active: record.active, deleted: record.deleted, id: record.id };
        }
       
        // console.log('====================================');
        // console.log(record, record_, re_, values);
        // console.log('====================================');
        // return;
        // // const record_ = { ...record, ...values, deleted: 0 }
        this.SetisLoading(true)
        this.props.StoreBannersRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }



    onChangeType = (e) =>
    {

        this.setState(state =>
        {
            return {
                record: { type: e.target.value, active: 1, deleted: 0 },
                setNewFormData: [{ title: "", link: "", rank: 1, images: '', active: 1 }],
                imgesInfo: { file: [] },

            }
        }, () =>
        {
            this.formRef.current.resetFields();
        })
    }

    onSwitch = (e, name,index) =>
    {
        console.log("eeee ",this.state.setNewFormData);
        const dataSetTmp=this.state.setNewFormData;
        const dTmp=dataSetTmp[index];
        if(dTmp){
            dTmp[name]=e;
            dataSetTmp[index]=dTmp;
        }
       
        this.setState(state =>
        {
            return {
                setNewFormData: dataSetTmp
            }
        })
    }
    addFormdata = () =>
    {
        this.setState(state =>
        {
            return {
                setNewFormData: [...state.setNewFormData, { title: "", link: "", rank: 1, images: '', active: 1, description: '', icon: '', }]
            }
        })
    }
    setimgesInfo = (typef, type, data) =>
    {


        if (typef === "file" || typef === "video") {
            const imageDelete = this.state.imgesInfo?.[typef]?.[type];
            if (imageDelete?.[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: imageDelete ? [...state.imgesInfoRemove, ...imageDelete] : [...state.imgesInfoRemove],

                }
            }, () =>
            {

                this.setState(state =>
                {

                    return {

                        imgesInfo: {
                            ...state.imgesInfo, [typef]: { ...state.imgesInfo[typef], [type]: data }
                        },

                    }
                })
            })

        } else {
            this.setState(state =>
            {

                return {

                    imgesInfo: {
                        ...state.imgesInfo, [typef]: { ...state.imgesInfo[typef], [type]: data }
                    },

                }
            })
        }

    }

    setimgesInfoRemove = (typef, type, data) =>
    {

        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (typef === "files") {
            images = this.state.imgesInfo?.[typef]?.[type]?.filter(item =>
            {
                return item !== data.name;
            })

            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: {
                        ...state.imgesInfo, [typef]: { ...state.imgesInfo[typef], [type]: images }
                    },

                }
            })
        } else {
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }

    setIcon=(icon)=>{
        const {indexIcon,setNewFormData}=this.state;
        setNewFormData[indexIcon]["icon"] = icon
        
        this.setState(state=>{
            return{
                record:{...state.record,icon:icon},
                setNewFormData:setNewFormData,
                visible:false
            }
        })
       
    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" style={{ overflow: "hidden" }}>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Icons
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visible}
                    onOk={() => this.setVisible(true)}
                    onCancel={() => this.setVisible(false)}

                    footer={[
                        // <Button key="back" onClick={this.handleCancelDetail}>
                        //     Hủy
                        // </Button>,
                        // <Button key="resetradio" onClick={this.handleResetRadioDetail}>
                        //     Reset
                        // </Button>,
                        // <Button key="submit" type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={() => { this.update_() }}>
                        //     Cập nhật
                        // </Button>,
                    ]}
                    width={"50%"}
                    bodyStyle={{
                        height: "70vh",
                        overflowY: "scroll"
                    }}
                // style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_">
                        <div style={{display:"flex",justifyContent:"space-between"}}>
                            {IconsFontawesome.map((item, index) =>
                            {
                                return <button type="button" className="reset_btn btn_icon_font" key={index} onClick={() => this.setIcon(item.icon)} dangerouslySetInnerHTML={{ __html: "<i class='" + item.icon + "'></i>" }} />
                            })}
                        </div>
                       
                    </div>
                </Modal>

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><SmileOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Banner & slides</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                        
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >

                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        name="formadd" onFinish={this.uploadFile} ref={this.formRef}
                    >

                        <div className="section_ section_img" style={{ overflow: "unset", height: "fit-content", maxHeight: "unset" }}>
                            <Form.Item label="Loại" name="type" rules={[
                                {
                                    required: true,
                                    message: "loại là bắt buộc"
                                },
                            ]} initialValue={this.state.record.type}>
                                <Radio.Group
                                    options={[
                                        {
                                            label: 'Banner',
                                            value: 1,
                                        },
                                        {
                                            label: 'Sliders',
                                            value: 2,
                                        },
                                        {
                                            label: 'Thương hiệu',
                                            value: 3,
                                        },
                                        {
                                            label: 'Dịch vụ',
                                            value: 4,
                                        },
                                        {
                                            label: 'Thanh toán',
                                            value: 5,
                                        },
                                        {
                                            label: 'Mạng xã hội',
                                            value: 6,
                                        },
                                    ]}
                                    onChange={this.onChangeType}

                                    optionType="button"
                                    buttonStyle="solid"
                                    disabled={this.state.isUpdate}
                                />
                            </Form.Item>

                            {this.state.setNewFormData && this.state.setNewFormData.map((item, index) =>
                            {
                                console.log("item.activeitem.activeitem.active",index,item.active);
                                
                                
                                return <div key={index}>
                                    {this.state.record.type === 2 && <Divider orientation="left" plain>
                                        <span style={{ color: Colors.colortext, fontWeight: "bold" }}>{"slide" + (index + 1)}</span>
                                    </Divider>}
                                    <Form.Item label="Tiêu đề" name={"title" + index} rules={[
                                        {
                                            required: true,
                                            message: "Tiêu đề là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tiêu đề" />
                                    </Form.Item>
                                    
                                    <Form.Item label="link" name={"link" + index} rules={[
                                        {
                                            required: true,
                                            message: "Link  là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="link" />
                                    </Form.Item>

                                    <Form.Item label="Vị trí" name={"rank" + index} rules={[
                                        {
                                            required: true,
                                            message: "Vị trí là bắt buộc"
                                        },
                                    ]}>
                                        <InputNumber placeholder="Vị trí" min={1} max={10} />
                                    </Form.Item>
                                    <Form.Item label="Mô tả" layout="vertical" name={"description"+ index}>

                                        <Input placeholder="Mô tả" />
                                    </Form.Item>
                                    <Form.Item label="Hình ảnh">
                                        <Upload isCropImg={false} multiple={false} max={1} type_={"images" + index} focus={this.state.required.avata} type_f={"file"} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} fileList={this.state.imgesInfo?.file?.["images" + index] ? this.state.imgesInfo?.file?.["images" + index] : []} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} />
                                    </Form.Item>
                                    <Form.Item label="Icon">

                                        {item.icon && <Button htmlType="button" type="primary" size={"large"} onClick={() =>
                                        {
                                            this.setState(state=>{
                                                return{
                                                    indexIcon: index
                                                }
                                            })
                                            this.setVisible(true)
                                        }} ><span dangerouslySetInnerHTML={{ __html: "<i class='"+item.icon+"'></i>" }} /></Button>}
                                        {!item.icon && <Button htmlType="button" type="primary" icon={<FacebookOutlined />} size={"large"} onClick={() =>
                                        {
                                            this.setState(state =>
                                            {
                                                return {
                                                    indexIcon: index
                                                }
                                            })
                                            this.setVisible(true)
                                        }} />}
                                      
                                    </Form.Item>
                                    <Form.Item label="Active" name={`active${index}`} >
                                        <SwitchA checked={convertToBoolean(item.active)} onChange={(e)=>{this.onSwitch(e,"active",index)}}/>
                                    </Form.Item>

                                    {parseInt(this.state.record.type) === 2 &&  <Form.Item label="Hiển thị thông tin và  nút"  name={`IsShowInfo${index}`} >
                                        <SwitchA checked={convertToBoolean(item.IsShowInfo)} onChange={(e)=>{this.onSwitch(e,"IsShowInfo",index)}}/>
                                    </Form.Item> }
                                   
                                    
                                    </div>
                            })}
                            {this.state.arrSlideType.includes(parseInt(this.state.record.type)) && <Form.Item style={{ marginBottom: "100px" }}>

                                <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.addFormdata}><PlusCircleOutlined /></Button>

                            </Form.Item>}
                        </div>

                        <Form.Item style={{ position: "absolute", bottom: 0, margin: 0, padding: "10px" }}  >
                            <div className="flex_">
                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreBannersRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreBannersRequest(data, id));
        },
        ResetStoreBanners: () =>
        {
            dispatch(ActionsWeb.ResetStoreBanners());
        },
        ListBannersRequest: (body) =>
        {
            dispatch(ActionsWeb.ListBannersRequest(body));
        },

        BannerByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.BannerByIdRequest(body));
        },
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
        {
            dispatch(ActionsWeb.ResetUploadFileLa());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);