import * as LINK from "./constants/Links";

import Home from "./pages/home/Home";
import Web from "./pages/web/web";
import DashboardWeb from "./pages/web/dasboard/Index";
import EditPr from "./pages/web/Products/edit/ProductsEdit";
import Products from "./pages/web/Products/list/Products";
import EditCate from "./pages/web/Categorys/edit/Index";
import Category from "./pages/web/Categorys/list/Index";

import EditMenuHome from "./pages/web/menuHome/edit/Index";
import ListMenuHome from "./pages/web/menuHome/list/Index";
import Classify from "./pages/web/Classifys/list/Index";
import EditClassify from "./pages/web/Classifys/edit/Index";
import InfoTransport from "./pages/web/infoTransport/list/Index";
import EditInfoTransport from "./pages/web/infoTransport/edit/Index";
import NumberOfPage from "./pages/web/numberOfPage/list/Index";
import EditNumberOfPage from "./pages/web/numberOfPage/edit/Index";
import Region from "./pages/web/region/list/Index";
import EditRegion from "./pages/web/region/edit/Index";
import Role from "./pages/web/role/list/Index";
import EditRole from "./pages/web/role/edit/Index";
import Store from "./pages/web/store/list/Index";
import EditStore from "./pages/web/store/edit/Index";
import Tags from "./pages/web/tags/list/Index";
import EditTags from "./pages/web/tags/edit/Index";
import TradeMark from "./pages/web/tradeMark/list/Index";
import EditTradeMark from "./pages/web/tradeMark/edit/Index";
import TypeProducts from "./pages/web/typeProducts/list/Index";
import EditTypeProducts from "./pages/web/typeProducts/edit/Index";
import Warehouse from "./pages/web/warehouse/list/Index";
import EditWarehouse from "./pages/web/warehouse/edit/Index";
import WarrantyType from "./pages/web/warrantyType/list/Index";
import EditWarrantyType from "./pages/web/warrantyType/edit/Index";
import OrganizationProduction from "./pages/web/organizationProduction/list/Index";
import EditOrganizationProduction from "./pages/web/organizationProduction/edit/Index";
import PaperTexture from "./pages/web/paperTexture/list/Index";
import EditPaperTexture from "./pages/web/paperTexture/edit/Index";
import OrganizationProductionAddress from "./pages/web/organizationProductionAddress/list/Index";
import EditOrganizationProductionAddress from "./pages/web/organizationProductionAddress/edit/Index";


import InfoPackage from "./pages/web/infoPackage/list/Index";
import EditInfoPackage from "./pages/web/infoPackage/edit/Index";

import ListOrder from "./pages/web/Orders/orders/index";
import EditOrder from "./pages/web/Orders/edit/index";

import ListOrderTiktok from "./pages/web/Orders/ecommerce/tiktok/orders/index";
// pages/web/orders/ecommerce/tiktok/orders/index
import EditOrderTiktok from "./pages/web/Orders/ecommerce/tiktok/edit/index";
// ./pages/web/orders/ecommerce/tiktok/edit/index

import Builder from "./pages/web/builder/Index";


import ListinventoryIn from "./pages/web/inventoryIn/list/Index";
import EditinventoryIn from "./pages/web/inventoryIn/edit/Index";

import ListinventoryOut from "./pages/web/inventoryOut/list/Index";
import EditinventoryOut from "./pages/web/inventoryOut/edit/Index";

import ListAttributeProducts from "./pages/web/AttributeProducts/list/Index";
import EditAttributeProducts from "./pages/web/AttributeProducts/edit/Index";

import ListSupplier from "./pages/web/supplier/list/Index";
import EditSupplier from "./pages/web/supplier/edit/Index";

import ListFunct from "./pages/web/funct/list/Index";
import EditFunct from "./pages/web/funct/edit/Index";

import ListUsers from "./pages/web/users/list/Index";
import EditUsers from "./pages/web/users/edit/Index";

import ListMenus from "./pages/web/menus/list/Index";
import EditMenus from "./pages/web/menus/edit/Index";

import ListStatusTransport from "./pages/web/statusTransport/list/Index";
import EditStatusTransport from "./pages/web/statusTransport/edit/Index";

import ListStatusTransportType from "./pages/web/statusTransportType/list/Index";
import EditStatusTransportType from "./pages/web/statusTransportType/edit/Index";

import ListVouchers from "./pages/web/vouchers/list/Index";
import EditVouchers from "./pages/web/vouchers/edit/Index";

import ListVouchersGroup from "./pages/web/vouchersgroup/list/Index";
import EditVouchersGroup from "./pages/web/vouchersgroup/edit/Index";

import ListCustomers from "./pages/web/customers/list/Index";
import EditCustomers from "./pages/web/customers/edit/Index";

import ListProvinces from "./pages/web/provinces/list/Index";
import EditProvinces from "./pages/web/provinces/edit/Index";


import ListDistricts from "./pages/web/district/list/Index";
import EditDistrict from "./pages/web/district/edit/Index";

import ListWars from "./pages/web/war/list/Index";
import EditWars from "./pages/web/war/edit/Index";


import ListPosts from "./pages/web/posts/list/Index";
import EditPosts from "./pages/web/posts/edit/Index";

import ListSettings from "./pages/web/settings/list/Index";
import EditSettings from "./pages/web/settings/edit/Index";
import SettingsOrder from "./pages/web/settings/list/settingOrder";
import SettingsWeb from "./pages/web/settings/list/settingWeb";
import SettingsAdmin from "./pages/web/settings/list/settingAdmin";
import SettingSocials from "./pages/web/settings/list/settingSocials";
import SettingEcommerce from "./pages/web/settings/list/settingEcommerce";
import SettingCache from "./pages/web/settings/list/settingCache";

import SettingSocialFacebook from "./pages/web/settings/list/settingSocialFacebook";
import SettingSocialZalo from "./pages/web/settings/list/settingSocialZalo";
import SettingPrintBill from "./pages/web/settings/list/settingPrintBill";

import ListBanners from "./pages/web/banners/list/Index";
import EditBanners from "./pages/web/banners/edit/Index";

import ListCollecttions from "./pages/web/collecttions/list/Index";
import EditCollecttions from "./pages/web/collecttions/edit/Index";

import ListFeedBack from "./pages/web/feedBack/list/Index";
import EditFeedBack from "./pages/web/feedBack/edit/Index";

import ListPage from "./pages/web/pages/list/Index";
import EditPage from "./pages/web/pages/edit/Index";

import ListContacts from "./pages/web/contacts/list/Index";
import EditContacts from "./pages/web/contacts/edit/Index";


import ProductsTikTok from "./pages/web/productsTiktok/list/Products";
import ProductsTikTokEdit from "./pages/web/productsTiktok/edit/ProductsEdit";

import Reports from "./pages/web/reports/list/Index";
import ReportBestSelling from "./pages/web/reports/bestSelling/Index";
import PrevenueProduct from "./pages/web/reports/prevenueProduct/Index";
import PrevenueCustomer from "./pages/web/reports/prevenueCustomer/Index";

import ListProductsWareHouse from "./pages/web/products_warehouse/list/Index";
import EditProductsWareHouse from "./pages/web/products_warehouse/edit/Index";

import ListNetworkHome from "./pages/web/networkHome/list/Index";
import EditNetworkHome from "./pages/web/networkHome/edit/Index";


import ListNetworkHomeOperator from "./pages/web/networkHomeOperator/list/Index";
import EditNetworkHomeOperator from "./pages/web/networkHomeOperator/edit/Index";
import ListUnit from "./pages/web/unit/list/Index";
import EditUnit from "./pages/web/unit/edit/Index";

import ListMaterialNorms from "./pages/web/materialNorms/list/Index";
import EditMaterialNorms from "./pages/web/materialNorms/edit/Index";
const routers = [
  {
    path: LINK.WEB,
    exact: false,
    name: "Web",
    main: ({ match, history, routers }) => (
      <Web match={match} history={history} routers={routers} />
    ),
    routers: [
      {
        path: LINK.WEB_DASHBOARD,
        exact: true,
        name: "DashboardWeb",
        main: ({ match, history }) => (
          <DashboardWeb match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_MERTERIAL_NORMS,
        exact: true,
        name: "ListMaterialNorms",
        main: ({ match, history }) => (
          <ListMaterialNorms match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_MERTERIAL_NORMS_EDIT + "/:id",
        exact: true,
        name: "EditMaterialNorms",
        main: ({ match, history }) => (
          <EditMaterialNorms match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_UNIT,
        exact: true,
        name: "ListUnit",
        main: ({ match, history }) => (
          <ListUnit match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_UNIT_EDIT + "/:id",
        exact: true,
        name: "EditUnit",
        main: ({ match, history }) => (
          <EditUnit match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_NETWORK_HOME_OPERATOR,
        exact: true,
        name: "ListNetworkHomeOperator",
        main: ({ match, history }) => (
          <ListNetworkHomeOperator match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_NETWORK_HOME_OPERATOR_EDIT + "/:id",
        exact: true,
        name: "EditNetworkHomeOperator",
        main: ({ match, history }) => (
          <EditNetworkHomeOperator match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_NETWORK_HOME,
        exact: true,
        name: "ListNetworkHome",
        main: ({ match, history }) => (
          <ListNetworkHome match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_NETWORK_HOME_EDIT + "/:id",
        exact: true,
        name: "EditNetworkHome",
        main: ({ match, history }) => (
          <EditNetworkHome match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_PRODUCTS_WAREHOUSE,
        exact: true,
        name: "ListProductsWareHouse",
        main: ({ match, history }) => (
          <ListProductsWareHouse match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PRODUCTS_WAREHOUSE_EDIT + "/:id",
        exact: true,
        name: "EditProductsWareHouse",
        main: ({ match, history }) => (
          <EditProductsWareHouse match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_REPORT_REVENUE_CUSTOMER,
        exact: true,
        name: "PrevenueCustomer",
        main: ({ match, history }) => (
          <PrevenueCustomer match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_REPORT_REVENUE_PRODUCT,
        exact: true,
        name: "PrevenueProduct",
        main: ({ match, history }) => (
          <PrevenueProduct match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_REPORT_BESTSELLING,
        exact: true,
        name: "ReportBestSelling",
        main: ({ match, history }) => (
          <ReportBestSelling match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_REPORTS,
        exact: true,
        name: "Reports",
        main: ({ match, history }) => (
          <Reports match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PRODUCTS,
        exact: true,
        name: "Products",
        main: ({ match, history }) => (
          <Products match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PRODUCTS_EDIT + "/:id",
        exact: true,
        name: "EditPr",
        main: ({ match, history }) => (
          <EditPr match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_PRODUCTS_TKTOK + "/:code",
        exact: true,
        name: "ProductsTikTok",
        main: ({ match, history }) => (
          <ProductsTikTok match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PRODUCTS_EDIT_TKTOK + "/:id",
        exact: true,
        name: "ProductsTikTokEdit",
        main: ({ match, history }) => (
          <ProductsTikTokEdit match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CATEGORY,
        exact: true,
        name: "Category",
        main: ({ match, history }) => (
          <Category match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CATEGORY_EDIT+ "/:id",
        exact: true,
        name: "EditCategory",
        main: ({ match, history }) => (
          <EditCate match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_MENUHOME,
        exact: true,
        name: "ListMenuHome",
        main: ({ match, history }) => (
          <ListMenuHome match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_MENUHOME_EDIT,
        exact: true,
        name: "EditMenuHome",
        main: ({ match, history }) => (
          <EditMenuHome match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CLASSIFY,
        exact: true,
        name: "Classify",
        main: ({ match, history }) => (
          <Classify match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CLASSIFY_EDIT + "/:id",
        exact: true,
        name: "EditClassify",
        main: ({ match, history }) => (
          <EditClassify match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INFO_TRANSPORT,
        exact: true,
        name: "InfoTransport",
        main: ({ match, history }) => (
          <InfoTransport match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INFO_TRANSPORT_EDIT + "/:id",
        exact: true,
        name: "EditInfoTransport",
        main: ({ match, history }) => (
          <EditInfoTransport match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_NUMBER_OF_PAGE,
        exact: true,
        name: "NumberOfPage",
        main: ({ match, history }) => (
          <NumberOfPage match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_NUMBER_OF_PAGE_EDIT,
        exact: true,
        name: "EditNumberOfPage",
        main: ({ match, history }) => (
          <EditNumberOfPage match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_REGION,
        exact: true,
        name: "Region",
        main: ({ match, history }) => (
          <Region match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_REGION_EDIT + "/:id",
        exact: true,
        name: "EditRegion",
        main: ({ match, history }) => (
          <EditRegion match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ROLE,
        exact: true,
        name: "Role",
        main: ({ match, history }) => (
          <Role match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ROLE_EDIT + "/:id",
        exact: true,
        name: "EditRole",
        main: ({ match, history }) => (
          <EditRole match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_STORE,
        exact: true,
        name: "Store",
        main: ({ match, history }) => (
          <Store match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_STORE_EDIT + "/:id",
        exact: true,
        name: "EditRole",
        main: ({ match, history }) => (
          <EditStore match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_TAGS,
        exact: true,
        name: "Tags",
        main: ({ match, history }) => (
          <Tags match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_TAGS_EDIT + "/:id",
        exact: true,
        name: "EditTags",
        main: ({ match, history }) => (
          <EditTags match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_TRADEMARK,
        exact: true,
        name: "TradeMark",
        main: ({ match, history }) => (
          <TradeMark match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_TRADEMARK_EDIT,
        exact: true,
        name: "EditTradeMark",
        main: ({ match, history }) => (
          <EditTradeMark match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_TYPE_PRODUCTS,
        exact: true,
        name: "TypeProducts",
        main: ({ match, history }) => (
          <TypeProducts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_TYPE_PRODUCTS_EDIT,
        exact: true,
        name: "EditTypeProducts",
        main: ({ match, history }) => (
          <EditTypeProducts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_WAREHOUSE,
        exact: true,
        name: "Warehouse",
        main: ({ match, history }) => (
          <Warehouse match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_WAREHOUSE_EDIT,
        exact: true,
        name: "EditWarehouse",
        main: ({ match, history }) => (
          <EditWarehouse match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_WARRANTY_TYPE,
        exact: true,
        name: "WarrantyType",
        main: ({ match, history }) => (
          <WarrantyType match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_WARRANTY_TYPE_EDIT,
        exact: true,
        name: "EditWarrantyType",
        main: ({ match, history }) => (
          <EditWarrantyType match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORGANIZATION_PRODUCTION,
        exact: true,
        name: "OrganizationProduction",
        main: ({ match, history }) => (
          <OrganizationProduction match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INFOPACKAGE,
        exact: true,
        name: "InfoPackage",
        main: ({ match, history }) => (
          <InfoPackage match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INFOPACKAGE_EDIT,
        exact: true,
        name: "EditInfoPackage",
        main: ({ match, history }) => (
          <EditInfoPackage match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORGANIZATION_PRODUCTION_EDIT,
        exact: true,
        name: "EdiOrganizationProduction",
        main: ({ match, history }) => (
          <EditOrganizationProduction match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PAPER_TEXTURE,
        exact: true,
        name: "PaperTexture",
        main: ({ match, history }) => (
          <PaperTexture match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PAPER_TEXTURE_EDIT,
        exact: true,
        name: "EdiPaperTexture",
        main: ({ match, history }) => (
          <EditPaperTexture match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORGANIZATION_PRODUCTION_ADDRESS,
        exact: true,
        name: "PaperTexture",
        main: ({ match, history }) => (
          <OrganizationProductionAddress match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORGANIZATION_PRODUCTION_ADDRESS_EDIT,
        exact: true,
        name: "EdiPaperTexture",
        main: ({ match, history }) => (
          <EditOrganizationProductionAddress match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORDERS,
        exact: true,
        name: "ListOrder",
        main: ({ match, history }) => (
          <ListOrder match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORDERS_EDIT + "/:id",
        exact: true,
        name: "EditOrder",
        main: ({ match, history }) => (
          <EditOrder match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_ORDERS_TIKTOK + "/:code",
        exact: true,
        name: "ListOrderTiktok",
        main: ({ match, history }) => (
          <ListOrderTiktok match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ORDERS_EDIT_TIKTOK + "/:id",
        exact: true,
        name: "EditOrderTiktok",
        main: ({ match, history }) => (
          <EditOrderTiktok match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INVENTORY_IN,
        exact: true,
        name: "ListinventoryIn",
        main: ({ match, history }) => (
          <ListinventoryIn match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INVENTORY_IN_EDIT + "/:id",
        exact: true,
        name: "EditinventoryIn",
        main: ({ match, history }) => (
          <EditinventoryIn match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INVENTORY_CHECK,
        exact: true,
        name: "ListinventoryOut",
        main: ({ match, history }) => (
          <ListinventoryOut match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_INVENTORY_CHECK_EDIT + "/:id",
        exact: true,
        name: "EditinventoryOut",
        main: ({ match, history }) => (
          <EditinventoryOut match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_ATTRIBUTE_PRODUCTS,
        exact: true,
        name: "ListAttributeProducts",
        main: ({ match, history }) => (
          <ListAttributeProducts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_ATTRIBUTE_PRODUCTS_EDIT + "/:id",
        exact: true,
        name: "EditAttributeProducts",
        main: ({ match, history }) => (
          <EditAttributeProducts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_SUPPLIER,
        exact: true,
        name: "ListSupplier",
        main: ({ match, history }) => (
          <ListSupplier match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_SUPPLIER_EDIT + "/:id",
        exact: true,
        name: "EditSupplier",
        main: ({ match, history }) => (
          <EditSupplier match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_FUNC,
        exact: true,
        name: "ListFunct",
        main: ({ match, history }) => (
          <ListFunct match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_FUNC + "/:id",
        exact: true,
        name: "EditFunct",
        main: ({ match, history }) => (
          <EditFunct match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_USER,
        exact: true,
        name: "ListUsers",
        main: ({ match, history }) => (
          <ListUsers match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_USER_EDIT + "/:id",
        exact: true,
        name: "EditUsers",
        main: ({ match, history }) => (
          <EditUsers match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_MENUS,
        exact: true,
        name: "ListMenus",
        main: ({ match, history }) => (
          <ListMenus match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_MENUS_EDIT + "/:id",
        exact: true,
        name: "EditMenus",
        main: ({ match, history }) => (
          <EditMenus match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_STATUS_TRANSPORT,
        exact: true,
        name: "ListStatusTransport",
        main: ({ match, history }) => (
          <ListStatusTransport match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_STATUS_TRANSPORT_EDIT + "/:id",
        exact: true,
        name: "EditStatusTransport",
        main: ({ match, history }) => (
          <EditStatusTransport match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_STATUS_TRANSPORT_TYPE,
        exact: true,
        name: "ListStatusTransportType",
        main: ({ match, history }) => (
          <ListStatusTransportType match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_STATUS_TRANSPORT_TYPE_EDIT + "/:id",
        exact: true,
        name: "EditStatusTransportType",
        main: ({ match, history }) => (
          <EditStatusTransportType match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CUSTOMERS,
        exact: true,
        name: "ListCustomers",
        main: ({ match, history }) => (
          <ListCustomers match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CUSTOMERS_EDIT + "/:id",
        exact: true,
        name: "EditCustomers",
        main: ({ match, history }) => (
          <EditCustomers match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_VOUCHERS,
        exact: true,
        name: "ListVouchers",
        main: ({ match, history }) => (
          <ListVouchers match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_VOUCHERS_EDIT + "/:id",
        exact: true,
        name: "EditVouchers",
        main: ({ match, history }) => (
          <EditVouchers match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_VOUCHERS_GROUP,
        exact: true,
        name: "ListVouchersGroup",
        main: ({ match, history }) => (
          <ListVouchersGroup match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_VOUCHERS_GROUP_EDIT + "/:id",
        exact: true,
        name: "EditVouchersGroup",
        main: ({ match, history }) => (
          <EditVouchersGroup match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_PROVINCES,
        exact: true,
        name: "ListProvinces",
        main: ({ match, history }) => (
          <ListProvinces match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PROVINCES_EDIT + "/:id",
        exact: true,
        name: "EditProvinces",
        main: ({ match, history }) => (
          <EditProvinces match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_DISTRICTS,
        exact: true,
        name: "ListDistricts",
        main: ({ match, history }) => (
          <ListDistricts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_DISTRICTS_EDIT + "/:id",
        exact: true,
        name: "EditDistrict",
        main: ({ match, history }) => (
          <EditDistrict match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_WAR,
        exact: true,
        name: "ListWars",
        main: ({ match, history }) => (
          <ListWars match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_WAR_EDIT + "/:id",
        exact: true,
        name: "EditWars",
        main: ({ match, history }) => (
          <EditWars match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_POST,
        exact: true,
        name: "ListPosts",
        main: ({ match, history }) => (
          <ListPosts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_POST_EDIT + "/:id",
        exact: true,
        name: "EditPosts",
        main: ({ match, history }) => (
          <EditPosts match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_SETTINGS,
        exact: false,
        name: "ListSettings",
        main: ({ match, history,routers }) => (
          <ListSettings match={match} history={history} routers={routers} />
        ),
        routers:[
          {
            path: LINK.WEB_SETTINGS_CACHE,
            exact: false,
            name: "SettingCache",
            main: ({ match, history }) => (
              <SettingCache match={match} history={history} />
            ),
          },
          {
            path: LINK.WEB_SETTINGS_ECOMMERCE,
            exact: false,
            name: "SettingEcommerce",
            main: ({ match, history }) => (
              <SettingEcommerce match={match} history={history} />
            ),
          },
          {
            path: LINK.WEB_SETTINGS_ORDER,
            exact: false,
            name: "SettingsOrder",
            main: ({ match, history }) => (
              <SettingsOrder match={match} history={history} />
            ),
          },
          {
            path: LINK.WEB_SETTINGS_WEB,
            exact: false,
            name: "SettingsWeb",
            main: ({ match, history }) => (
              <SettingsWeb match={match} history={history} />
            ),
          },
          {
            path: LINK.WEB_SETTINGS_ADMIN,
            exact: false,
            name: "SettingsAdmin",
            main: ({ match, history }) => (
              <SettingsAdmin match={match} history={history} />
            ),
          },
          {
            path: LINK.WEB_SETTINGS_SOCIAL,
            exact: false,
            name: "SettingSocials",
            main: ({ match, history, routers }) => (
              <SettingSocials match={match} history={history} routers={routers} />
            ),
            routers:[
              {
                path: LINK.WEB_SETTINGS_SOCIAL_FACEBOOK,
                exact: false,
                name: "SettingSocialFacebook",
                main: ({ match, history }) => (
                  <SettingSocialFacebook match={match} history={history} />
                ),
              },
              {
                path: LINK.WEB_SETTINGS_SOCIAL_ZALO,
                exact: false,
                name: "SettingSocialZalo",
                main: ({ match, history }) => (
                  <SettingSocialZalo match={match} history={history} />
                ),
              }
            ]
          },
          {
            path: LINK.WEB_SETTINGS_PRINT_BILLS,
            exact: false,
            name: "SettingPrintBill",
            main: ({ match, history }) => (
              <SettingPrintBill match={match} history={history} />
            ),
          },
        ]
      },
      {
        path: LINK.WEB_SETTINGS_EDIT + "/:id",
        exact: true,
        name: "EditSettings",
        main: ({ match, history }) => (
          <EditSettings match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_BANNERS,
        exact: true,
        name: "ListBanners",
        main: ({ match, history }) => (
          <ListBanners match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_BANNERS_EDIT + "/:id",
        exact: true,
        name: "EditBanners",
        main: ({ match, history }) => (
          <EditBanners match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_COLLECTIONS,
        exact: true,
        name: "ListCollecttions",
        main: ({ match, history }) => (
          <ListCollecttions match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_COLLECTIONS_EDIT + "/:id",
        exact: true,
        name: "EditCollecttions",
        main: ({ match, history }) => (
          <EditCollecttions match={match} history={history} />
        ),
      },

      {
        path: LINK.WEB_FEEDBACK,
        exact: true,
        name: "ListFeedBack",
        main: ({ match, history }) => (
          <ListFeedBack match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_FEEDBACK_EDIT + "/:id",
        exact: true,
        name: "EditFeedBack",
        main: ({ match, history }) => (
          <EditFeedBack match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PAGE,
        exact: true,
        name: "ListPage",
        main: ({ match, history }) => (
          <ListPage match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_PAGE_EDIT + "/:id",
        exact: true,
        name: "EditPage",
        main: ({ match, history }) => (
          <EditPage match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CONTACT,
        exact: true,
        name: "ListContacts",
        main: ({ match, history }) => (
          <ListContacts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_CONTACT_EDIT + "/:id",
        exact: true,
        name: "EditContacts",
        main: ({ match, history }) => (
          <EditContacts match={match} history={history} />
        ),
      },
      {
        path: LINK.WEB_BUILDER,
        exact: true,
        name: "Builder",
        main: ({ match, history }) => (
          <Builder match={match} history={history} />
        ),
      },

    ]
  },

];

export default routers;
