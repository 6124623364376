import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "./../../../../constants/Colors";

import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message

} from 'antd';
// import
// {
//     FileImageOutlined,
//     EditOutlined,
//     VideoCameraOutlined,
//     PlusOutlined,
//     PlusCircleOutlined,
//     DragOutlined,
//     DeleteOutlined,
// } from '@ant-design/icons';
const { Option } = Select;


class Index extends Component
{

    constructor(props)
    {
        super(props);


        this.state = {
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                name: "",
                parent_id: 1,
                slug: "",
                hidden: "1",
                deleted: 0
            },
            StoreCategory: {},
            ListCategory: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {
            return {
                record: nextProps.history.location.state,

            };

        } else if (nextProps.Web.ListCategory && nextProps.Web.ListCategory !== prevState.ListCategory) {
            return {

                ListCategory: nextProps.Web.ListCategory,

            };

        } else if (nextProps.Web.StoreCategory && nextProps.Web.StoreCategory !== prevState.StoreCategory) {
            return {
                StoreCategory: nextProps.Web.StoreCategory,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreCategory !== prevState.StoreCategory) {
            let { StoreCategory } = this.state;
            if (StoreCategory.code === 200) {
                message.success(StoreCategory.message)
            }
            else {
                if (StoreCategory.message) {
                    message.error(StoreCategory.message)
                }

            }
            this.props.ResetStoreCategory()
            this.SetisLoading(false)
        }

        if (this.state.ListCategory !== prevState.ListCategory) {
            let { ListCategory } = this.state

            this.data();
        }
    }
    componentDidMount = () =>
    {
        this.props.ListCategoryRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        return;
        if (!this.props.Web.ListCategory.data) {

            this.props.ListCategoryRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            });
        }
        else {
            this.setState(state =>
            {
                return {
                    ListCategory: this.props.Web.ListCategory
                }
            }, () => { this.data(); })

        }
    }
    data = () =>
    {
        let { ListCategory } = this.state;
        // if (!ListCategory) {
        //     ListCategory = this.props.Web.ListCategory
        // }

        if (ListCategory.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListCategory.current_page,
            first_page_url: ListCategory.first_page_url,
            from: ListCategory.from,
            last_page: ListCategory.last_page,
            last_page_url: ListCategory.last_page_url,
            links: ListCategory.links,
            next_page_url: ListCategory.next_page_url,
            path: ListCategory.path,
            per_page: ListCategory.per_page,
            prev_page_url: ListCategory.prev_page_url,
            to: ListCategory.to,
            total: ListCategory.total,
        };
        ListCategory.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    Store = () =>
    {
        let { record } = this.state;
        if (!record.name) {
            message.error("Vui lòng nhập tên");
            return;
        }
        this.SetisLoading(true)
        this.props.StoreCategoryRequest(record, record.id ? record.id : "")
    }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <h1>Chi tiết danh mục</h1>
                <Form
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        // span: 14,
                    }}
                    layout="horizontal"
                >

                    <div className="section_ section_img">
                        <h3 style={{ color: Colors.pop2[3] }}>Thông tin cơ bản</h3>
                        <Form.Item label="Danh mục cha">
                            <Select
                                showSearch
                                style={{
                                    // width: 200,
                                }}
                                name="parent_id" id="parent_id"
                                placeholder="Danh mục cha"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                                value={this.state.record.parent_id}
                                onChange={(e) => { this.onChangetext(e, "parent_id", "record") }}
                            >
                                <Option value={0} key={0}>{"Gốc"}</Option>
                                {this.state.data && this.state.data.map((item, index) =>
                                {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })}


                            </Select>
                        </Form.Item>
                        <Form.Item label="*Tên danh mục">
                            <Input name="name" id="name" placeholder="Tên danh mục" value={this.state.record.name} onChange={(e) => { this.onChangetext(e, "name", "record") }} showCount maxLength={120} />
                        </Form.Item>
                        <Form.Item label="*Slug">
                            <Input name="slug" id="slug" placeholder="Slug" value={this.state.record.slug} onChange={(e) => { this.onChangetext(e, "slug", "record") }} showCount maxLength={120} />
                        </Form.Item>
                        <Form.Item label="Ẩn">
                            <SwitchA name="hidden" id="hidden" value={this.state.record.hidden} onChange={(e) => { this.onChangetext(e, "hidden", "record") }} />
                        </Form.Item>

                    </div>

                    <Form.Item >

                        <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                        <Button type="primary" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={this.Store}>Cập nhật</Button>
                    </Form.Item>
                </Form >
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreCategoryRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreCategoryRequest(data, id));
        },
        ResetStoreCategory: () =>
        {
            dispatch(ActionsWeb.ResetStoreCategory());
        },
        ListCategoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);