import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import moment from 'moment';
import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Tag, Card, Form, InputNumber, Switch as SwitchA, } from 'antd';
import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined, AppstoreOutlined,
    DeleteOutlined,
    EditOutlined, UnorderedListOutlined, SearchOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { getParamsUrl } from "../../../../service";
import * as Services from "./../../../../service";
import Draggable from 'react-draggable';
import IndexMobile from "./IndexMobile";
const { Search } = Input;
class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null);
        this.draggleRef = createRef(null);
        this.formRef = createRef(null);
        this.state = {

            ListInfoPackage: [],
            meta: {
                ...META
            },
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Rộng',
                    dataIndex: 'width',

                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Cao',
                    dataIndex: 'height',
                    sorter: (a, b) => a.code - b.code,

                },

                {
                    title: 'Dài',
                    dataIndex: 'wide',
                    sorter: (a, b) => a.value - b.value,

                },
                {
                    title: 'Mặc định',
                    dataIndex: 'active',
                    render: (text, record, index) =>
                    {
                        return record.active ? <Tag color="green">Mặc định</Tag> : ""
                    }
                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreCategory: {},
            isloading: false,
            isLoadingSearch: false,
            disabled: true,
            isDelete:false,
            isNewList:false,
            isShowList:false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setBounds = (bounds) =>
    {
        this.setState(state =>
        {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) =>
    {
        this.setState(state =>
        {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) =>
    {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    setVisibleDetail = (type) =>
    {
        this.setState(state =>
        {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = () =>
    {
        this.setVisibleDetail(true);

        this.setIsdeleted(true)
    };

    handleCancelDetail = () =>
    {
        this.setVisibleDetail(false);

    };
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };
    setIsdeleted = (type) =>
    {
        this.setState(state =>
        {
            return {
                isDelete: type
            }
        })
    }
    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.setIsdeleted(true)
        this.props.StoreInfoPackageRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        const urlParams = getParamsUrl();
        this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

        this.props.ListInfoPackageRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
        });


    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListInfoPackage && nextProps.Web.ListInfoPackage !== prevState.ListInfoPackage) {

            return {
                ListInfoPackage: nextProps.Web.ListInfoPackage,

            };

        }
        else if (nextProps.Web.StoreInfoPackage && nextProps.Web.StoreInfoPackage !== prevState.StoreInfoPackage) {
            return {
                StoreInfoPackage: nextProps.Web.StoreInfoPackage,

            };

        }
        else {
            return null;
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreInfoPackage !== prevState.StoreInfoPackage) {
            let { StoreInfoPackage } = this.state;
            try {
                if (StoreInfoPackage.code === 200) {
                    if (StoreInfoPackage.message && typeof StoreInfoPackage.message === "string") {
                        message.success(StoreInfoPackage.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

                    this.props.ListInfoPackageRequest({
                        limit: this.state.meta.per_page,
                        page: 1, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                    });



                }
                else {
                    if (StoreInfoPackage.message && typeof StoreInfoPackage.message === "string") {

                        message.error(StoreInfoPackage.message)
                    } else {
                        for (let x in StoreInfoPackage.message) {
                            let mess = StoreInfoPackage.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
           
            this.props.ResetStoreInfoPackage()

        }
        if (this.state.ListInfoPackage !== prevState.ListInfoPackage) {
            let { ListInfoPackage } = this.state
            this.SetisLoadingSeach(false)
            // this.setIsdeleted(false)
            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Rộng',
                        dataIndex: 'width',

                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Cao',
                        dataIndex: 'height',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Dài',
                        dataIndex: 'wide',
                        sorter: (a, b) => a.value - b.value,

                    },
                    {
                        title: 'Cân nặng',
                        dataIndex: 'weight',
                        sorter: (a, b) => a.value - b.value,

                    },
                    {
                        title: 'Mặc định',
                        dataIndex: 'active',
                        render: (text, record, index) =>
                        {
                            return record.active ? <Tag color="green">Mặc định</Tag> : <Button danger size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} onClick={() => this.setAcive(record)}>Cập nhật</Button>
                        }
                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        sorter: (a, b) => a.created_at - b.created_at,


                    },
                    {
                        title: 'Ngày cập nhật',
                        dataIndex: 'updated_at',
                        sorter: (a, b) => a.updated_at - b.updated_at,

                    },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)}> <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    setAcive = (record) =>
    {

        record.active = !record.active
        // return;
        this.SetisLoading(true)
        this.props.StoreInfoPackageRequest(record, record.id ? record.id : "")
        
    }

    data = () =>
    {
        let { ListInfoPackage } = this.state;

        if (ListInfoPackage.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListInfoPackage.current_page,
            first_page_url: ListInfoPackage.first_page_url,
            from: ListInfoPackage.from,
            last_page: ListInfoPackage.last_page,
            last_page_url: ListInfoPackage.last_page_url,
            links: ListInfoPackage.links,
            next_page_url: ListInfoPackage.next_page_url,
            path: ListInfoPackage.path,
            per_page: ListInfoPackage.per_page,
            prev_page_url: ListInfoPackage.prev_page_url,
            to: ListInfoPackage.to,
            total: ListInfoPackage.total,
        };
        ListInfoPackage.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.name,
            })
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        }, () =>
        {
            this.setState(state =>
            {
                return {
                    isNewList: false,
                }
            });

        })
    }

    setCurrentPage=(page)=>{
        this.setState(state =>
        {
            return {
                meta: { ...state.meta, current_page:1}
            }
        })
    }
    editRows = (record) =>
    {

      
        this.setState(state =>
        {
            return {
                visibledetail: true,
                record: record,
                isDelete:true
            }
        }, () =>
        {
            this.formRef.current.setFieldsValue({ ...record })
        })



    }


    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        this.props.ListInfoPackageRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)
        this.props.ListInfoPackageRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: values.search
        });

        const searchParams = new URLSearchParams({ s: values.search }).toString();

        this.props.history.push(`${LINK.WEB_INFOPACKAGE}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    onFinishForm = (values) =>
    {
    
        const { record } = this.state;
        const record_ = { ...record, ...values, active: values.active !== true ? false : true }
        this.SetisLoading(true)
        this.props.StoreInfoPackageRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="infopackage">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() =>
                            {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() =>
                            {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Cập nhật
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[
                        
                    ]}
                    width={"400px"}
                    bodyStyle={{
                        height: "fit-content",
                        // overflowY: "scroll"
                    }}
                // style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_">

                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Form.Item label="Cao"
                                name="height"
                                rules={[
                                    {
                                        required: true,
                                        message: "Cao là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber addonAfter={"CM"} placeholder="height" min={0} />
                            </Form.Item>
                            <Form.Item label="Dài"
                                name="long"
                                rules={[
                                    {
                                        required: true,
                                        message: "Rộng là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber addonAfter={"CM"} placeholder="long" min={0} />
                            </Form.Item>
                            <Form.Item label="Rộng"
                                name="wide"
                                rules={[
                                    {
                                        required: true,
                                        message: "Rộng là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber addonAfter={"CM"} placeholder="wide" min={0} />
                            </Form.Item>
                            <Form.Item label="Cân nặng" name="weight" rules={[
                                {
                                    required: true,
                                    message: "Cân nặng là bắt buộc"
                                },
                            ]}>
                                <InputNumber addonAfter={"KG"} placeholder="weight" min={0} />
                            </Form.Item>
                            <Form.Item label="*Chọn mặc định" name="active">
                                <SwitchA checked={this.state.record.active === 0 ? false : true} onChange={(e) =>
                                {
                                    this.setState(state =>
                                    {
                                        return {
                                            record: { ...state.record, active: e }
                                        }
                                    })
                                }} />

                            </Form.Item>

                            <Form.Item>

                                <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </Form.Item>
                        </Form >
                    </div>
                </Modal>

                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}> 
                    
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin gói hàng</span>
                        </div>
                        <Button size="small" type="primary" onClick={this.handleOkDetail}><PlusCircleOutlined /></Button>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                           
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px"
                        }}
                    >

                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{

                                    width: "100%",
                                    display: "flex"
                                }}>


                                    <Form.Item name="search" noStyle style={{
                                        width: "80%"
                                    }}>
                                        <Input placeholder="Nhập tên danh mục" name="search" id="search"

                                            style={{
                                                width: "80%"
                                            }}

                                        />
                                    </Form.Item>
                                    <Form.Item noStyle style={{
                                        width: "20%"
                                    }}>
                                        <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                    </Form.Item>

                                </Form>
                            </div>
                            {/* <div className="right_">
                                <Button type="primary" onClick={this.handleOkDetail}><PlusCircleOutlined /></Button>
                            
                            </div> */}

                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isDelete={this.state.isDelete}
                            setIsdeleted={this.setIsdeleted}
                            isNewList={this.state.isNewList}
                        />}
                        {Services.checkScreenSize().width >= 600 && this.state.isShowList  && <>
                        <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                            rowSelection={{
                                ...this.state.rowSelection,
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            onChange={this.onChangeTable}
                            scroll={{

                                y: "60vh",
                            }}
                            style={{ paddingBottom: "30px" }}
                        />
                        <div className="pagination_">
                            <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                        </div>
                        </>
                        }
                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListInfoPackageRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoPackageRequest(body));
        },
        StoreInfoPackageRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInfoPackageRequest(data, id));
        },
        ResetStoreInfoPackage: () =>
        {
            dispatch(ActionsWeb.ResetStoreInfoPackage());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);