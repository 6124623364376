import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";

import * as Colors from "../../../../constants/Colors";

import { createSlug } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card

} from 'antd';
import
{
    UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";


class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = new createRef();

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                slug: "",
                hidden: false,
                deleted: 0
            },
            StoreWars: {},
            ListWars: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListWars && nextProps.Web.ListWars.id && nextProps.Web.ListWars !== prevState.record) {
            console.log('====================================');
            console.log(nextProps.Web.ListWars);
            console.log('====================================');
            return {
                record: nextProps.Web.ListWars,

            };

        }

        if (nextProps.Web.ListProvinces && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {

            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };

        }
        if (nextProps.Web.ListDistricts && nextProps.Web.ListDistricts.data !== prevState.ListDistricts) {


            return {
                ListDistricts: nextProps.Web.ListDistricts.data,

            };

        }
      
        if (nextProps.Web.StoreWars && nextProps.Web.StoreWars !== prevState.StoreWars) {
            return {
                StoreWars: nextProps.Web.StoreWars,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {
        if (this.state.record !== prevState.record) {
           
            this.formRef.current.setFieldsValue({ ...this.state.record })
            if (this.state.record.province_id){
                this.props.ListDistrictsRequest({
                    limit: this.state.meta.per_page,
                    page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: this.state.record.province_id
                });
            }
        }

        if (this.state.StoreWars !== prevState.StoreWars) {
            let { StoreWars } = this.state;
            try {
                if (StoreWars.code === 200) {
                    if (StoreWars.message && typeof StoreWars.message === "string") {
                        message.success(StoreWars.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreWars.message && typeof StoreWars.message === "string") {

                        message.error(StoreWars.message)
                    } else {
                        for (let x in StoreWars.message) {
                            let mess = StoreWars.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)

            this.props.ResetStoreWars()

        }

        if (this.state.ListProvinces !== prevState.ListProvinces){
            console.log('====================================');
            console.log(this.state.record);
            console.log('====================================');
            if (this.state.record.province_id){
                this.formRef.current.setFieldsValue({ province_id: this.state.record.province_id })
            }
           
          }   
        if (this.state.ListDistricts !== prevState.ListDistricts) {
            if (this.state.record.district_id) {
                this.formRef.current.setFieldsValue({ district_id: this.state.record.district_id })
            }

        }   
    }
    componentDidMount = () =>
    {
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.setState(state =>
            {
                return {
                    isUpdate: true
                }
            })
            this.props.ListWarsByIdRequest({ id });
        }
        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

    }


    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreWarsRequest(record_, record_.id ? record_.id : "")

    }
    handleChange = (newValue) =>
    {
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: newValue
        });
        this.formRef.current.setFieldsValue({ district_id: "" })
        this.setState(state =>
        {
            return {
                Provice_id: newValue,
                record: {
                    deleted: 0
                },
                ListDistricts: []
            }
        })
    };
    render()
    {



        return (

            <div className="main_content products pro_" id="my-scrollbar" style={{ position: "relative" }}>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                        <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Chi tiết phường xã</span>
                    </div>} bordered={false}
                        style={{ width: "100%" }}
                        bodyStyle={{
                            padding: "10px 10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >

                            <Form.Item
                                label="Tỉnh/thành"
                                style={{
                                    width: "100%"
                                }}
                                name={"province_id"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Chưa chọn tỉnh thành"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn tỉnh thành"
                                    style={{ width: "100%", }}

                                    showArrow={false}
                                    onChange={this.handleChange}
                                    notFoundContent={null}
                                    options={(this.state.ListProvinces || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                    filterOption={(input, option) =>
                                    {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}

                                />
                            </Form.Item>
                            <Form.Item
                                label="Quận/Huyện"
                                style={{
                                    width: "100%"
                                }}
                                name={"district_id"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Chưa chọn quận huyện"
                                    },
                                ]}
                            >
                                <Select
                                    showSearch

                                    placeholder="Chọn quận huyện"
                                    style={{ width: "100%", }}
                                    showArrow={false}

                                    notFoundContent={null}
                                    options={(this.state.ListDistricts || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                    filterOption={(input, option) =>
                                    {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}

                                />
                            </Form.Item>
                            <Form.Item label="Tên" name="name"

                                rules={[
                                    {
                                        required: true,
                                        message: "Tên phường xã là bắt buột"
                                    },
                                ]}
                            >
                                <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                {
                                    this.formRef.current.setFieldsValue({ code: createSlug(e.target.value) })
                                }} />
                            </Form.Item>
                            <Form.Item label="Code" name="code" rules={[
                                {
                                    required: true,
                                    message: "Code là bắt buột"
                                },
                            ]}>
                                <Input placeholder="code" showCount maxLength={120} />
                            </Form.Item>
                            <Form.Item
                                label="Loại"
                                style={{
                                    width: "100%"
                                }}
                                name={"type"}

                            >
                                <Select
                                    showSearch

                                    placeholder="Chọn loại"
                                    style={{ width: "100%", }}
                                    showArrow={false}

                                    notFoundContent={null}
                                    options={[
                                        { value: "", label: "Không chọn" },
                                        { value: "Quận", label: "Quận" },
                                        { value: "Huyện", label: "Huyện" },
                                        { value: "Thành phố", label: "Thành phố" }
                                    ]}
                                    filterOption={(input, option) =>
                                    {

                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}

                                />
                            </Form.Item>
                            <Form.Item>
                                <div className="flex_">
                                    <Button type="default" size="small" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                                    <Button type="primary" size="small" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
        ListProvincesRequest: (body) =>
        {
            dispatch(ActionsWeb.ListProvincesRequest(body));
        },
        ListDistrictsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListDistrictsRequest(body));
        },
        StoreWarsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreWarsRequest(data, id));
        },
        ResetStoreWars: () =>
        {
            dispatch(ActionsWeb.ResetStoreWars());
        },
        ListWarsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListWarsRequest(body));
        },
        ListWarsByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.ListWarsByIdRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);