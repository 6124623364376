import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import Upload from "./Upload";
import * as Colors from "../../../../constants/Colors";
import { replaceThous } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card,InputNumber

} from 'antd';
import
{
    FileImageOutlined, VideoCameraOutlined, UserSwitchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
const { TextArea } = Input;



class Index extends Component
{

    constructor(props)
    {
        super(props);

        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            record: {
                type_inventory: 0,
                supplier_id: '',
                code: "",
                amount: 1,
                size: "1x2x3",
                images: [],
                video: "",
                link: "",
                deleted: "0"
            },
            StoreInventory: {},
            ListInventory: [],
            ListProductsWarehouse:[],
            data: [],
            isloading: false,
            imgesInfo: {
                avata: [],
                detail: [],
                video: []
            },
            imgesInfoRemove: [],
            isupload: 0,
            imgesInfores: {
                avata: [],
                detail: [],
                video: []
            },
            UploadFileLa: "",

        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        // if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {

        //     let video = nextProps.history.location.state.video ? [{
        //         uid: -1,
        //         name: nextProps.history.location.state?.video,
        //         status: 'done',
        //         url: LINK.myHostimgServerVide + "/" + nextProps.history.location.state?.video,
        //     }] : [];
        //     let detail = [];
        //     (nextProps.history.location.state.images || []).map((item, index) =>
        //     {
        //         detail.push({
        //             uid: -1 * (index + 1),
        //             name: item,
        //             status: 'done',
        //             url: LINK.myHostimgServerImg + "/" + item,
        //         })
        //     });
        //     return {
        //         record: nextProps.history.location.state,
        //         imgesInfo: { avata: [], detail: detail, video: video },

        //     };

        // }
        if (nextProps.Web.ListInventory && nextProps.Web.ListInventory.id !== prevState.record.id) {

            let inven = nextProps.Web.ListInventory

            return {
                record: inven,

            };


        }
        if (nextProps.Web.UploadFileLa && nextProps.Web.UploadFileLa !== prevState.UploadFileLa) {
            return {
                UploadFileLa: nextProps.Web.UploadFileLa,

            };

        }
        if (nextProps.Web.StoreInventory && nextProps.Web.StoreInventory !== prevState.StoreInventory) {
            return {
                StoreInventory: nextProps.Web.StoreInventory,

            };

        }
        if (nextProps.Web.ListSupplier && nextProps.Web.ListSupplier.data !== prevState.ListSupplier) {
            // console.log("Comment", nextProps.Web.ListSupplier);


            return {
                ListSupplier: nextProps.Web.ListSupplier.data,
                isLoadingSearch: false
            };

        }
        if (nextProps.Web.ListProductsWarehouse && nextProps.Web.ListProductsWarehouse !== prevState.ListProductsWarehouse) {
            // console.log("nextProps.Web.ListProductsWarehousenextProps.Web.ListProductsWarehouse",nextProps.Web.ListProductsWarehouse);
            return {
                ListProductsWarehouse: nextProps.Web.ListProductsWarehouse.data,
                isLoadingSearch: false
            };

        }
      
       
       






        return null;


    }
    componentWillUnmount()
    {
        this.setState(state =>
        {
            return {
                record: {}
            }
        })
    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreInventory !== prevState.StoreInventory) {
            let { StoreInventory } = this.state;

            try {
                if (StoreInventory.code === 200) {
                    if (StoreInventory.message && typeof StoreInventory.message === "string") {
                        message.success(StoreInventory.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreInventory.message && typeof StoreInventory.message === "string") {

                        message.error(StoreInventory.message)
                    } else {
                        for (let x in StoreInventory.message) {
                            let mess = StoreInventory.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.props.ResetStoreInventory()
            this.SetisLoading(false)

        }
        if (this.state.UploadFileLa !== prevState.UploadFileLa) {
            let { UploadFileLa } = this.state;

            if (parseInt(UploadFileLa.type) === 2) {
                if (UploadFileLa.code !== 200) {

                    message.error("Cập nhật ảnh chi tiết thất bại!");
                    // return;
                }
                this.setState(state =>
                {
                    return {
                        record: { ...state.record, images: [...state.record.images, ...UploadFileLa.data] }
                    }
                }, () =>
                {

                    if (this.state.isupload === 2) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }
            if (parseInt(UploadFileLa.type) === 3) {

                if (UploadFileLa.code !== 200) {

                    message.warning("Cập nhật video thất bại!");
                    // return;
                }
                this.setState(state =>
                {
                    return {
                        record: { ...state.record, video: UploadFileLa.data[0] }
                    }
                }, () =>
                {
                    if (this.state.isupload === 3) {
                        this.onFinishForm(this.state.values);
                    }
                })
            }
            this.props.ResetUploadFileLa();
        }
        if (this.state.record.id !== prevState.record.id) {

            let video = this.state.record?.video ? [{
                uid: -1,
                name: this.state.record?.video,
                status: 'done',
                url: LINK.myHostimgServerVide + "/" + this.state.record?.video,
            }] : [];
            let detail = [];
            (this.state.record?.images || []).map((item, index) =>
            {
                detail.push({
                    uid: -1 * (index + 1),
                    name: item,
                    status: 'done',
                    url: LINK.myHostimgServerImg + "/" + item,
                })
            });
            this.setState(state =>
            {
                return {
                    imgesInfo: { avata: [], detail: state.imgesInfo.detail.length > 0 ? [...state.imgesInfo.detail, detail] : detail, video: video },

                }
            })
            this.formRef.current.setFieldsValue({ ...this.state.record })
        }

    }
    componentDidMount = () =>
    {

        this.props.ListSupplierRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.props.SearchProductsWarehouseRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        },{ data: {} });
        
        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.InventoryByIdRequest({ id });
        }

    }
    data = () =>
    {
        let { ListRegion } = this.state;


        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
  
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        record.imgesInfoRemove = this.state.imgesInfoRemove.filter(item =>
        {
            return !item.originFileObj
        });

        const record_ = { ...record, ...values, deleted: 0 }
      
        this.SetisLoading(true)
        this.props.StoreInventoryRequest(record_, record_.id ? record_.id : "")

    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    setimgesInfo = (type, data) =>
    {
       
        if (type === "avata" || type === "video") {
            const imageDelete = this.state.imgesInfo[type];
            if (imageDelete[0]) {
                imageDelete[0]["folder"] = type;

            }
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [...state.imgesInfoRemove, ...imageDelete],


                }
            }, () =>
            {
                this.setState(state =>
                {

                    return {

                        imgesInfo: {
                            ...state.imgesInfo, [type]: data
                        },

                    }
                })
            })




        } else {


            this.setState(state =>
            {

                return {
                    imgesInfo: {
                        ...state.imgesInfo, [type]: data
                    }
                }
            })
        }

    }

    setimgesInfoRemove = (type, data) =>
    {

        data["folder"] = type;
        let images = "";
        let imagesrecord = "";
        if (type === "detail") {
            images = this.state.imgesInfo.detail.filter(item =>
            {
                return item !== data.name;
            })
            if (this.state.record.images) {
                imagesrecord = this.state.record.images.filter(item =>
                {
                    return item !== data.name;
                })
            }

            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],
                    imgesInfo: { ...state.imgesInfo, detail: images },
                    record: { ...state.record, images: imagesrecord }

                }
            })
        } else {
            this.setState(state =>
            {

                return {
                    imgesInfoRemove: [
                        ...state.imgesInfoRemove, data],

                }
            })
        }


    }

    uploadFile = (values) =>
    {
        const { imgesInfo } = this.state;
        this.setState(state =>
        {
            return {
                values: values
            }
        })


        let isupload = 0;
        this.SetisLoading(true)
        if (imgesInfo.detail.length > 0) {
            let arrdetail = imgesInfo.detail.filter(item =>
            {

                return item.originFileObj;
            })
            if (arrdetail.length > 0) {
                this.props.UploadFileLaRequest(arrdetail, "img/inventory", 2);
                isupload = 2;
            }
        }

        if (imgesInfo.video.length > 0) {
            let arrvideo = imgesInfo.video.filter(item =>
            {
                return item.originFileObj;
            })
            if (arrvideo.length > 0) {
                this.props.UploadFileLaRequest(arrvideo, "video/inventory", 3);
                isupload = 3;
            }
        }

        this.setState(state =>
        {
            return {
                isupload: isupload,

            }
        })
        if (isupload === 0) {
            this.onFinishForm(values);
        }
    }
    render()
    {



        return (

            <div className="table_w" >

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin phiếu nhập kho</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                      
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        name="formadd" onFinish={this.uploadFile} ref={this.formRef}
                    >



                        <div className=" section_img">

                            <Form.Item label="Nhà cung cấp" name="supplier_id" rules={[
                                {
                                    required: true,
                                    message: "Nhà cung cấp là bắt buộc"
                                },
                            ]}>
                                <Select
                                    // mode="multiple"
                                    maxTagCount='responsive'
                                    style={{ width: '100%' }}

                                    placeholder="Nhà cung cấp"




                                    options={(this.state.ListSupplier || []).map((d) => ({
                                        value: d.id,
                                        label: d.name,
                                    }))}
                                // defaultValue={this.state.record.tags_id}
                                />
                            </Form.Item>
                            <Form.Item label="Sản Phẩm" name="products_warehouse_id" rules={[
                                {
                                    required: true,
                                    message: "Sản Phẩm là bắt buộc"
                                },
                            ]}>
                                <Select
                                    // mode="multiple"
                                    maxTagCount='responsive'
                                    style={{ width: '100%' }}

                                    placeholder="Sản Phẩm"




                                    options={(this.state.ListProductsWarehouse || []).map((d) => ({
                                        value: d.id,
                                        label: d.title,
                                    }))}
                                // defaultValue={this.state.record.tags_id}
                                />
                            </Form.Item>
                            <Form.Item label="Số lượng" name="amount" rules={[
                                {
                                    required: true,
                                    message: "Số lượng là bắt buộc"
                                },
                            ]}>
                                <InputNumber placeholder="Số lượng" />
                            </Form.Item>
                            <Form.Item label="Giá" name="price" rules={[
                                {
                                    required: true,
                                    message: "Giá là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Giá" />
                            </Form.Item>
                            <Form.Item label="Size" name="size" >
                                <Input placeholder="1x2x3" />
                            </Form.Item>

                            <Form.Item label="Hình ảnh">
                                <Upload isCropImg={false} multiple={true} max={8} type_={"detail"} type_f={2} fileList={this.state.imgesInfo.detail} setimgesInfo={this.setimgesInfo} setimgesInfoRemove={this.setimgesInfoRemove} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh chi tiết" icon={<FileImageOutlined />} />

                            </Form.Item>
                            <Form.Item label="Video">
                                <Upload isCropImg={false} setimgesInfoRemove={this.setimgesInfoRemove} multiple={false} max={1} type_={"video"} type_f={3} fileList={this.state.imgesInfo.video} setimgesInfo={this.setimgesInfo} accept="video/mp4,video/x-m4v,video/*" title="Video bìa" icon={<VideoCameraOutlined />} />

                            </Form.Item>
                            <Form.Item label="link" name="link">
                                <Input placeholder="link" />
                            </Form.Item>
                            <Form.Item label="Ghi chú" name="note">
                                <TextArea placeholder="Ghi chú" />

                            </Form.Item>
                        </div>

                        <Form.Item >
                            <div className="flex_">
                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card>
            </div >


        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        StoreInventoryRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInventoryRequest(data, id));
        },
        ResetStoreInventory: () =>
        {
            dispatch(ActionsWeb.ResetStoreInventory());
        },
        ListSupplierRequest: (body) =>
        {
            dispatch(ActionsWeb.ListSupplierRequest(body));
        },
        ListRegionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
        UploadFileLaRequest: (body, folder, type) =>
        {
            dispatch(ActionsWeb.UploadFileLaRequest(body, folder, type));
        },
        ResetUploadFileLa: () =>
        {
            dispatch(ActionsWeb.ResetUploadFileLa());
        },
        /////////////////
        DeleteFileLaRequest: (body) =>
        {
            dispatch(ActionsWeb.DeleteFileLaRequest(body));
        },
        ListInventoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInventoryRequest(body));
        },
        InventoryByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.InventoryByIdRequest(body));
        },
        SearchProductsWarehouseRequest: (body, data) =>
            {
                dispatch(ActionsWeb.SearchProductsWarehouseRequest(body, data));
            },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);