import React, { Component, createRef, useEffect, useState } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import * as Colors from "../../../../constants/Colors";
import moment from 'moment';

import { Divider, Radio, Input, Table, Pagination, Modal, message, Button, Select, Form, DatePicker, Tooltip, Card, Row, Col, Image } from 'antd';

import {
    FolderViewOutlined, AppstoreOutlined, UnorderedListOutlined,
    DeleteOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined, MinusCircleOutlined, ArrowUpOutlined, ArrowDownOutlined, SaveOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";
import { CutString, checkScreenSize, createSlug, formatMN1, getParamsUrl, replaceThous } from "../../../../service";
import IndexMobile from "./IndexMobile";
import { AMOUNT_STAMPS } from "../../../../constants/DefaultData";
const { RangePicker } = DatePicker;
const Index = ({ ListPaperAmountAndDiscount, setListPaperAmountAndDiscount, formPaperAmountAndDiscountRef, listSortStamps }) => {


    const [maxCountDiscount, setMaxCountDiscount] = useState(0);
    useEffect(() => {
        try {
            if(formPaperAmountAndDiscountRef){
  const datasetform = {};
            const datasetAmoutStamps = {};
            listSortStamps.map((value, index) => {
    
                datasetform[`paper-amount-and-discount-sort_stamps_id-${index}`]= parseInt(value.id) ;
    
              
                if (ListPaperAmountAndDiscount?.[index]?.array_discount) {
                    ListPaperAmountAndDiscount?.[index]?.array_discount.map((dis, idis) => {
                        datasetform[`paper-amount-and-discount-discount-${index}-${idis}`]= formatMN1(dis?.discount);
                        datasetform[`paper-amount-and-discount-amount_stamp-${index}-${idis}`]=(dis?.amount_stamp);
                       
                        const sortStampA3 = parseInt(value.a3);
                        const amountPaper = Math.round(parseInt(dis?.amount_stamp) / sortStampA3);
    
                        datasetform[`paper-amount-and-discount-amount_paper-${index}-${idis}`]=amountPaper;
                        datasetAmoutStamps[`select-stamp-${idis}`]=dis?.amount_stamp;
                    })
    
                }
                // if (!ListPaperAmountAndDiscount?.[index]?.array_discount) {
                //     let newItem={...ListPaperAmountAndDiscount?.[index-1]};
                //     newItem={...newItem,...value};
                //     console.log("newItemnewItem",newItem);
                //     ListPaperAmountAndDiscount[index]=newItem;
                //     // ListPaperAmountAndDiscount?.[index]?.array_discount
    
                // }
    
    
            })
    
    
            formPaperAmountAndDiscountRef?.current.setFieldsValue(datasetAmoutStamps);
            setTimeout(() => {
                if(formPaperAmountAndDiscountRef){
                    if(formPaperAmountAndDiscountRef.current){
                        formPaperAmountAndDiscountRef?.current.setFieldsValue(datasetform);
                    }
                }
                
            }, 2000);
       
            let checkMax = 0;
            ListPaperAmountAndDiscount.map((value, index) => {
                if (value.array_discount) {
                    if (value.array_discount.length > checkMax) {
                        checkMax = value.array_discount.length;
                    }
                }
            })
            setMaxCountDiscount(checkMax>0?checkMax:1);
            }
          
        } catch (error) {
            
        }
       
        return () => {

        };
    }, [listSortStamps, ListPaperAmountAndDiscount]);
    const addSortStamp = (index) => {
        const formvalues = formPaperAmountAndDiscountRef.current.getFieldsValue();
        const newdata = [];
        let flag = 0;
        let itemNew = {}
        let i = 0;
        const datasetAmoutStamps={};
        for (let x in formvalues) {
            // let x_ = x.split("-");
            // let index = x_[x_.length -2];

            if (flag !=  i) {
                newdata.push(itemNew)
                flag =  i;
                itemNew = {};
            }
            if (flag ==  i) {
                if(ListPaperAmountAndDiscount[i]){
                    itemNew={...ListPaperAmountAndDiscount[i]};

                    // itemNew.sort_stamps_id=ListPaperAmountAndDiscount[i].sort_stamps_id;
                    // itemNew.note=ListPaperAmountAndDiscount[i].note;
                    // itemNew.rank=ListPaperAmountAndDiscount[i].rank;
                    // itemNew.hidden=ListPaperAmountAndDiscount[i].hidden;
                    // itemNew.deleted=ListPaperAmountAndDiscount[i].deleted;
                   
                }
                if(itemNew.array_discount){
                   
                    itemNew.array_discount.map((value, index) => {
                        // itemNew.id=value.id;
                       
                        if (formvalues?.[`paper-amount-and-discount-amount_paper-${i}-${index}`]) {
                       
                            itemNew.array_discount[index].amount_paper = formvalues[`paper-amount-and-discount-amount_paper-${i}-${index}`];
                           
                        }
                        if (formvalues?.[`paper-amount-and-discount-amount_stamp-${i}-${index}`]) {
                   
                            datasetAmoutStamps[`select-stamp-${index}`]=formvalues[`paper-amount-and-discount-amount_stamp-${i}-${index}`];

                            itemNew.array_discount[index].amount_stamp = formvalues[`paper-amount-and-discount-amount_stamp-${i}-${index}`];

                        }
                        if (formvalues?.[`paper-amount-and-discount-discount-${i}-${index}`]) {
                   

                            itemNew.array_discount[index].discount = replaceThous(formvalues[`paper-amount-and-discount-discount-${i}-${index}`]);
                           
                        }
                    })
                }
                // else{
                //     itemNew.array_discount=[];
                //     let array_discount={};
                //     if (formvalues?.[`paper-amount-and-discount-amount_paper-${i}-${0}`]) {
                       
                //         array_discount.amount_paper = formvalues[`paper-amount-and-discount-amount_paper-${i}-${0}`];
                       
                //     }
                //     if (formvalues?.[`paper-amount-and-discount-amount_stamp-${i}-${index}`]) {
               
                //         datasetAmoutStamps[`select-stamp-${index}`]=formvalues[`paper-amount-and-discount-amount_stamp-${i}-${0}`];

                //         array_discount.amount_stamp = formvalues[`paper-amount-and-discount-amount_stamp-${i}-${0}`];

                //     }
                //     if (formvalues?.[`paper-amount-and-discount-discount-${i}-${0}`]) {
               

                //         array_discount.discount = replaceThous(formvalues[`paper-amount-and-discount-discount-${i}-${0}`]);
                       
                //     }

                //     itemNew.array_discount.push(array_discount)
                // }
               

            }
            i++;
            if (i == Object.values(formvalues).length) {

                newdata.push(itemNew)

            }

        }
        // console.log("newdatanewdatanewdatanewdata",newdata);
       
        let indexFil=0;
        i=0;
        for(let x in datasetAmoutStamps){
            const AMOUNT_STAMPSFil=AMOUNT_STAMPS.filter((value, index) => { 
                if(value.value==datasetAmoutStamps[x]){
                    if(index==AMOUNT_STAMPS.length-1){
                        indexFil=AMOUNT_STAMPS.length-1;
                    }else{
                        indexFil=index;
                    }
                   
                }
               
            })
            i++;
        }
        datasetAmoutStamps[`select-stamp-${i}`]=AMOUNT_STAMPS?.[indexFil+1]?.value;
      
        formPaperAmountAndDiscountRef.current.setFieldsValue(datasetAmoutStamps);
        newdata.map((value, index) => {
            if (value.array_discount) {
                value.array_discount.push({
                    amount_stamp:AMOUNT_STAMPS?.[indexFil+1]?.value,
                    discount: 0,
                    amount_paper: 0

                })
            }

        })


        setListPaperAmountAndDiscount([...newdata])
    }
    const removeSortStamp = (index) => {
        if (index == 0) {
            return;
        }
        const newData = ListPaperAmountAndDiscount.filter((value, i) => {
            return i != index;
        })
        setListPaperAmountAndDiscount([...newData])
    }

    const sortDataByRank = (type, value, index) => {
        if (type == "up") {
            value.rank -= 1;
            if (value.rank <= 1) {
                value.rank = 1;
            }
        }
        if (type == "down") {
            value.rank += 1;
        }
        const sortedData = [];
        ListPaperAmountAndDiscount.map((item, i) => {
            if (type == "up") {

                if (i == (index - 1)) {
                    item.rank += 1;
                    item.isEdit = true;
                }
            }
            if (type == "down") {
                if (i == (index + 1)) {
                    item.rank -= 1;
                    if (item.rank <= 1) {
                        item.rank = 1;
                    }
                    item.isEdit = true;

                }
            }
            if (i == index) {
                item = value
                item.isEdit = true;
            }
            sortedData.push(item)
        })

        const sortedData_ = sortedData.sort((a, b) => a.rank - b.rank);

        setListPaperAmountAndDiscount(sortedData_);
    };

    const processAmount = (e) => {
        const sortStamp = listSortStamps.filter((value, index) => {
            return value.id == e;
        })
        const sortStamp_ = sortStamp[0];
        const sortStampA3 = parseInt(sortStamp_.a3);


        const formvalues = formPaperAmountAndDiscountRef.current.getFieldsValue();
     
        for (let x in formvalues) {
            if (x.includes("amount_stamp")) {
                
                const xarr = x.split("-");
               
                const amountStamp = formvalues[`paper-amount-and-discount-amount_stamp-${xarr[xarr.length - 1]}-${xarr[xarr.length - 2]}`];
                if (!amountStamp) {
                    return;
                }
                const amountPaper = Math.round(parseInt(amountStamp) / sortStampA3);
                formPaperAmountAndDiscountRef.current.setFieldsValue({ [`paper-amount-and-discount-amount_paper-${xarr[xarr.length - 1]}-${xarr[xarr.length - 2]}`]: amountPaper });
            }
        }


    }

    const processStamnpsAmount = (e,idis) => {
        const formvalues = formPaperAmountAndDiscountRef.current.getFieldsValue();
        let i=0;
        const dataSetFil={};
        for(let x in formvalues){
            // formPaperAmountAndDiscountRef.current.setFieldsValue({ [`paper-amount-and-discount-amount_stamps-${i}-${idis}`]: e });
            const sortStampId = formvalues[`paper-amount-and-discount-sort_stamps_id-${i}`];
            dataSetFil[`paper-amount-and-discount-amount_stamp-${i}-${idis}`]= e;
            if (sortStampId) {
               
                const sortStamp = listSortStamps.filter((value, index) => {
                    return value.id == sortStampId;
                })
                const sortStamp_ = sortStamp[0];
                const sortStampA3 = parseInt(sortStamp_.a3);
        
        
                const amountPaper = Math.round(parseInt(e) / sortStampA3);
                console.log(amountPaper);
                // formPaperAmountAndDiscountRef.current.setFieldsValue({ [`paper-amount-and-discount-amount_paper-${i}-${idis}`]: amountPaper });
              
                dataSetFil[`paper-amount-and-discount-amount_paper-${i}-${idis}`]= amountPaper;
                
            }
           
            i++;
        }
        formPaperAmountAndDiscountRef.current.setFieldsValue(dataSetFil);

    }

    const colorCell=(index)=>{
      const colorCell= document.getElementsByClassName("color-cell");
      const colorCellIndex =document.getElementsByClassName("color-cell-"+index);
    
      if(colorCell){
        for (let i = 0; i < colorCell.length; i++) {

            const element = colorCell[i];
          
          
            if(element){
                element.classList.remove("bg-danger");

            }
        }
      }
      if(colorCellIndex){
        for (let i = 0; i < colorCellIndex.length; i++) {

            const element = colorCellIndex[i];
          
          
            if(element){
                element.classList.add("bg-danger");

            }
        } }
    }

    return (
        <>
           

                <Input.Group compact className="d-flex w-100">

                    <Form.Item  noStyle
                    className="sticky_left"
                    >
                       <Input className={`font-size-12`}
                                    style={{ width: '20%', maxWidth: "70px", maxHeight: "29px", minWidth: "70px" }}
                                    placeholder="Kích thước" disabled
                                    // value={maxCountDiscount}
                                />
                    </Form.Item>
                   

                    {Array.from({ length: maxCountDiscount }, (_, i) => i + 1).map((diss, idis) => {

                   
                        return <Input.Group compact className="d-flex" >
                            <Form.Item name={`select-stamp-${idis}`}
                            noStyle
                            >
                               
                               <Select

                                className={`font-size-12 `}
                                showSearch
                                maxTagCount='responsive'
                                style={{ width: '100%', maxWidth: "100px", minWidth: "100px", }}

                                placeholder="SL"

                                options={AMOUNT_STAMPS}
                                onChange={(e) => processStamnpsAmount(e,idis)}

                                />
                            </Form.Item>
                            <Form.Item noStyle >
                            <Input className={`font-size-12 `}
                                    style={{
                                        width: '100%',minWidth:"100px",maxWidth:"100px"
                                    }}
                                    placeholder="Số tờ"
                                    disabled
                                />
                            </Form.Item>

                            <Form.Item  noStyle
                               
                            >
                           <Input className={`font-size-12 `}
                                    style={{
                                        width: '100%',minWidth:"100px",maxWidth:"100px"
                                    }}
                                    placeholder="CK/PT"
                                    disabled
                                />
                            </Form.Item></Input.Group>


                    })}


                    <Form.Item noStyle
                        
                    >
                             <Input className={`font-size-12 `}
                                    style={{
                                      width: '60%',minWidth:"100px",maxWidth:"100px"
                                    }}
                                    placeholder="Ghi chú"
                                    disabled
                                />
                    </Form.Item>

                </Input.Group>
            
            {listSortStamps && listSortStamps.map((item, index) => {

                //   console.log("ListPaperAmountAndDiscount[index]ListPaperAmountAndDiscount[index]",ListPaperAmountAndDiscount[index]?.ararray_discount);

                return <Input.Group compact className="d-flex w-100">

                    <Form.Item name={`paper-amount-and-discount-sort_stamps_id-${index}`} noStyle rules={[
                        {
                            required: true,
                            message: "not empty"
                        },
                    ]}
                        initialValue={parseInt(item.id)}
                        className={`sticky_left `}
                    >
                        <Select
                            className={`font-size-12 color-cell color-cell-${index}`}
                            onClick={()=>{colorCell(index)}}
                            showSearch
                            maxTagCount='responsive'
                            style={{ width: '20%', maxWidth: "70px", maxHeight: "29px", minWidth: "70px" }}

                            placeholder="id xếp tem"


                            options={(listSortStamps || []).map((d) => ({
                                value: parseInt(d.id),
                                label: d.code,
                            }))}

                            onChange={(e) => processAmount(e, index)}
                        />
                    </Form.Item>
                    {/* <div className="d-none"  style={{ width: '20%',maxHeight:"29px" }}>
                        <Form.Item name={`paper-amount-and-discont-code-${index}`} noStyle rules={[
                            {
                                required: true,
                                message: "not empty"
                            },
                        ]}>
                            <Input className={`font-size-12 color-cell color-cell-${index}`}
                            onClick={()=>{colorCell(index)}}
                                style={{
                                    width: '20%',
                                }}
                                placeholder="code"
                            />
                        </Form.Item>
                    </div> */}

                    {ListPaperAmountAndDiscount?.[index]?.array_discount && ListPaperAmountAndDiscount?.[index]?.array_discount.map((diss, idis) => {
                                let bgcolor="#051e3c82";
                            if(idis%2==0){
                                bgcolor="";
                            }else{
                                bgcolor="#051e3c82";
                            }

                                
                        return <Input.Group compact className="d-flex">
                            <Form.Item name={`paper-amount-and-discount-amount_stamp-${index}-${idis}`} noStyle rules={[
                                {
                                    required: true,
                                    message: "not empty"
                                },
                            ]}
                                // initialValue={AMOUNT_STAMPS[1].value}

                            >
                                <Input className={`font-size-12 color-cell color-cell-${index}`}
                                onClick={()=>{colorCell(index)}}
                                    style={{
                                         width: '100%',minWidth:"100px",maxWidth:"100px",backgroundColor:bgcolor
                                    }}
                                    placeholder="SL tem"
                                />
                            </Form.Item>
                            <Form.Item name={`paper-amount-and-discount-amount_paper-${index}-${idis}`} noStyle rules={[
                                {
                                    required: true,
                                    message: "not empty"
                                },
                            ]}>
                                <Input className={`font-size-12 color-cell color-cell-${index}`}
                                onClick={()=>{colorCell(index)}}
                                    style={{
                                         width: '100%',minWidth:"100px",maxWidth:"100px",backgroundColor:bgcolor
                                    }}
                                    placeholder="Số tờ"
                                />
                            </Form.Item>

                            <Form.Item name={`paper-amount-and-discount-discount-${index}-${idis}`} noStyle
                                // initialValue={diss?.discount}
                            >
                                <Input className={`font-size-12 color-cell color-cell-${index}`}
                                onClick={()=>{colorCell(index)}}
                                    style={{
                                        width: '100%',minWidth:"100px",maxWidth:"100px",backgroundColor:bgcolor
                                    }}
                                    placeholder="CK/PT"
                                />
                            </Form.Item></Input.Group>


                    })}


                    <div className="d-none">
                        <Form.Item name={`paper-amount-and-discount-isNew-${index}`} noStyle
                            initialValue={ListPaperAmountAndDiscount?.[index] ? false : true}
                        >
                            <Input className={`font-size-12 color-cell color-cell-${index}`}
                            onClick={()=>{colorCell(index)}}
                                style={{
                                    width: '25%',
                                }}
                                placeholder="isNew"

                            />
                        </Form.Item>
                    </div>
                    <Form.Item name={`paper-amount-and-discount-note-${index}`} noStyle
                        initialValue={ListPaperAmountAndDiscount?.[index]?.note}
                        
                    >
                        <Input className={`font-size-12 color-cell color-cell-${index}`}
                        onClick={()=>{colorCell(index)}}
                            addonAfter={<div className="d-flex">
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => addSortStamp(index)}><PlusCircleOutlined className="text-primary" /></button>
                                {/* <button type="button" className="reset_btn scale_btn mr-2" onClick={() => removeSortStamp(index)}><MinusCircleOutlined className="text-danger" /></button>
                                <button type="button" className="reset_btn scale_btn mr-2" onClick={() => sortDataByRank("up", item, index)}><ArrowUpOutlined className="text-info" /></button>
                                <button type="button" className="reset_btn scale_btn " onClick={() => sortDataByRank("down", item, index)}><ArrowDownOutlined className="text-info" /></button> */}

                            </div>}
                            style={{
                                width: '60%',minWidth:"100px",maxWidth:"100px"
                            }}
                            placeholder="ghi chú"
                        />
                    </Form.Item>

                </Input.Group>
            })}

        </>

    );
}
export default Index;