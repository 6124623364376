import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";


import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1, getParamsUrl } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    Card

} from 'antd';

import * as Colors from "../../../../constants/Colors";

import * as Services from "./../../../../service";
import { UserSwitchOutlined } from '@ant-design/icons';
import Validator from "../../../../utils/Validator";
import { META } from "../../../../utils/constant";
const { TextArea } = Input;
const { Option } = Select;


class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formRef = new createRef(null);

        this.state = {
            meta: {
                ...META
            },
            record: {
                name: "",
                code: "",
                bank: "",
                bank_name: "",
                link: "",
                address: "",
                phone: "",
                deleted: 0
            },
            StoreSupplier: {},
            ListSupplier: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        // if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {
        //     return {
        //         record: nextProps.history.location.state,

        //     };

        // }
        if (nextProps.Web.ListSupplier && nextProps.Web.ListSupplier !== prevState.record) {
            // console.log('====================================');
            // console.log(nextProps.Web.ListSupplier);
            // console.log('====================================');
            return {
                record: nextProps.Web.ListSupplier,

            };

        }
        if (nextProps.Web.StoreSupplier && nextProps.Web.StoreSupplier !== prevState.StoreSupplier) {
            return {
                StoreSupplier: nextProps.Web.StoreSupplier,

            };

        }
        return null;


    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreSupplier !== prevState.StoreSupplier) {
            let { StoreSupplier } = this.state;
            try {
                if (StoreSupplier.code === 200) {
                    if (StoreSupplier.message && typeof StoreSupplier.message === "string") {
                        message.success(StoreSupplier.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }

                }
                else {
                    if (StoreSupplier.message && typeof StoreSupplier.message === "string") {

                        message.error(StoreSupplier.message)
                    } else {
                        for (let x in StoreSupplier.message) {
                            let mess = StoreSupplier.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.props.ResetStoreSupplier()
            this.SetisLoading(false)
        }

        if (this.state.record !== prevState.record) {

            this.formRef.current.setFieldsValue({ ...this.state.record })
        }
    }
    componentDidMount = () =>
    {

        const { match } = this.props;
        const { params } = match;
        const id = (1 * params.id); // Lấy giá trị của tham số từ URL

        if (id && typeof id === "number") {
            this.props.SupplierByIdRequest({ id });
        }
    }
    data = () =>
    {
        let { ListRegion } = this.state;
        // if (!ListRegion) {
        //     ListRegion = this.props.Web.ListRegion
        // }

        if (ListRegion.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListRegion.current_page,
            first_page_url: ListRegion.first_page_url,
            from: ListRegion.from,
            last_page: ListRegion.last_page,
            last_page_url: ListRegion.last_page_url,
            links: ListRegion.links,
            next_page_url: ListRegion.next_page_url,
            path: ListRegion.path,
            per_page: ListRegion.per_page,
            prev_page_url: ListRegion.prev_page_url,
            to: ListRegion.to,
            total: ListRegion.total,
        };
        ListRegion.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {
            console.log('====================================');
            console.log(e);
            console.log('====================================');
            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }
    // Store = () =>
    // {
    //     let { record } = this.state;
    //     if (!record.name) {
    //         message.error("Vui lòng nhập tên");
    //         return;
    //     }

    //     this.SetisLoading(true)
    //     this.props.StoreSupplierRequest(record, record.id ? record.id : "")
    // }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values, deleted: 0 }
        this.SetisLoading(true)
        this.props.StoreSupplierRequest(record_, record_.id ? record_.id : "")

    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >

                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UserSwitchOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin cơ bản</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px",
                        // maxHeight: "90vh",
                        // overflowY: "scroll"
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "30px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                    >

                        <div className="section_ section_img">


                            <Form.Item label="Tên" name="name" rules={[
                                {
                                    required: true,
                                    message: "Tên nhà cung cấp là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="Tên" onChange={(e) =>
                                {
                                    this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                }} />
                            </Form.Item>
                            <Form.Item label="Code" name="code" rules={[
                                {
                                    required: true,
                                    message: "Code là bắt buộc"
                                },
                            ]}>
                                <Input placeholder="code" />
                            </Form.Item>
                            <Form.Item label="phone" name="phone" rules={[
                                {
                                    validator: (_, value) =>
                                    {

                                        if (value) {
                                            const min = Validator.minLength(10)(value, "Số điện thoại lớn hơn 10 số");
                                            if (min) {
                                                return Promise.reject(min);
                                            }
                                            const max = Validator.maxLength(12)(value, "Số điện thoại nhỏ hơn 12 số");
                                            if (max) {
                                                return Promise.reject(max);
                                            }
                                            const isPhone = Validator.validatePhoneNumber(value);
                                            if (isPhone) {
                                                return Promise.reject(isPhone);
                                            }
                                        }

                                        return Promise.resolve();

                                    }
                                },
                            ]} >
                                <Input placeholder="phone" />
                            </Form.Item>
                            <Form.Item label="Email" name="email">
                                <Input placeholder="email" />
                            </Form.Item>
                            <Form.Item label="Địa chỉ" name="address">
                                <Input placeholder="address" />
                            </Form.Item>
                            <Form.Item label="Link" name="link">
                                <Input placeholder="link" />
                            </Form.Item>
                            <Form.Item label="Số TK Ngân hàng" name="bank" >
                                <Input placeholder="bank" />
                            </Form.Item>
                            <Form.Item label="Tên TK Ngân hàng" name="bank_name">
                                <Input placeholder="bank_name" />
                            </Form.Item>

                        </div>

                        <Form.Item >
                            <div className="flex_">
                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>

                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                            </div>
                        </Form.Item>
                    </Form >
                </Card>
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        SupplierByIdRequest: (body) =>
        {
            dispatch(ActionsWeb.SupplierByIdRequest(body));
        },
        StoreSupplierRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreSupplierRequest(data, id));
        },
        ResetStoreSupplier: () =>
        {
            dispatch(ActionsWeb.ResetStoreSupplier());
        },
        ListSupplierRequest: (body) =>
        {
            dispatch(ActionsWeb.ListSupplierRequest(body));
        },
        ListRegionRequest: (body) =>
        {
            dispatch(ActionsWeb.ListRegionRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);