import React, { Component, createRef } from "react";
import { Row, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";

import * as Colors from "../../../../constants/Colors";

import { formatMoney, validateNUmber, replaceThous, formatMN2, formatMN1 } from "./../../../../service";
import
{
    Form,
    Input,
    Button,
    Select,
    Switch as SwitchA,
    message,
    InputNumber,
    Card

} from 'antd';
import
{
    UnorderedListOutlined
} from '@ant-design/icons';
const { TextArea } = Input;

const { Option } = Select;


class Index extends Component
{

    constructor(props)
    {
        super(props);


        this.state = {
            meta: {
                current_page: 1,
                first_page_url: "",
                from: 1,
                last_page: 2,
                last_page_url: "",
                links: [],
                next_page_url: "",
                path: "",
                per_page: 10000,
                prev_page_url: "",
                to: 1,
                total: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                width: 0,
                height: 0,
                weight: 0,
                wide: 0,
                active: 0,
                deleted: 0
            },
            StoreInfoPackage: {},
            ListInfoPackage: [],
            data: [],
            isloading: false
        }


    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.history.location.state && nextProps.history.location.state.id !== prevState.record.id) {
            return {
                record: nextProps.history.location.state,

            };

        } else if (nextProps.Web.ListInfoPackage && nextProps.Web.ListInfoPackage !== prevState.ListInfoPackage) {
            return {

                ListInfoPackage: nextProps.Web.ListInfoPackage,

            };

        } else if (nextProps.Web.StoreInfoPackage && nextProps.Web.StoreInfoPackage !== prevState.StoreInfoPackage) {
            return {
                StoreInfoPackage: nextProps.Web.StoreInfoPackage,

            };

        } else {
            return null;
        }

    }
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.StoreInfoPackage !== prevState.StoreInfoPackage) {
            let { StoreInfoPackage } = this.state;
            if (StoreInfoPackage.code === 200) {
                message.success(StoreInfoPackage.message)
            }
            else {
                if (StoreInfoPackage.message) {
                    message.error(StoreInfoPackage.message)
                }

            }
            this.props.ResetStoreInfoPackage()
            this.SetisLoading(false)
        }

        if (this.state.ListInfoPackage !== prevState.ListInfoPackage) {
            let { ListInfoPackage } = this.state

            this.data();
        }
    }
    componentDidMount = () =>
    {

        this.props.ListInfoPackageRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });


    }
    data = () =>
    {
        let { ListInfoPackage } = this.state;


        if (ListInfoPackage.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListInfoPackage.current_page,
            first_page_url: ListInfoPackage.first_page_url,
            from: ListInfoPackage.from,
            last_page: ListInfoPackage.last_page,
            last_page_url: ListInfoPackage.last_page_url,
            links: ListInfoPackage.links,
            next_page_url: ListInfoPackage.next_page_url,
            path: ListInfoPackage.path,
            per_page: ListInfoPackage.per_page,
            prev_page_url: ListInfoPackage.prev_page_url,
            to: ListInfoPackage.to,
            total: ListInfoPackage.total,
        };
        ListInfoPackage.data.map((item, index) =>
        {
            listfil.push({
                text: item.name,
                value: item.id,
            })
            item.key = index + 1
            data.push(item)

        });


        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        })
    }
    onChangetext = (e, type, group, isNumber) =>
    {

        this.setState(state =>
        {
            return {
                [group]: { ...state[group], [type]: e }
            }
        })

    }
    // Store = () =>
    // {
    //     let { record } = this.state;
    //     if (!record.width) {
    //         message.error("Vui lòng nhập chiều rộng gói hàng");
    //         return;
    //     }
    //     if (!record.wide) {
    //         message.error("Vui lòng nhập chiều dài gói hàng");
    //         return;
    //     }
    //     if (!record.height) {
    //         message.error("Vui lòng nhập chiều cao gói hàng");
    //         return;
    //     }
    //     if (!record.weight) {
    //         message.error("Vui lòng nhập cân nặng gói hàng");
    //         return;
    //     }
    //     console.log('====================================');
    //     console.log(record);
    //     console.log('====================================');
    //     // return;
    //     this.SetisLoading(true)
    //     this.props.StoreInfoPackageRequest(record, record.id ? record.id : "")
    // }
    onFinishForm = (values) =>
    {
        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreInfoPackageRequest(record_, record_.id ? record_.id : "")

    }

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    render()
    {
        let { transposts, active_tran } = this.state;


        return (

            <div className="table_w" >
                <Card title={<div style={{ display: "flex", alignItems: "center" }}><UnorderedListOutlined style={{ color: Colors.pop2[3] }} />
                    <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Thông tin gói hàng</span>
                </div>} bordered={false}
                    style={{ width: "100%", }}
                    bodyStyle={{
                        padding: "10px 0",
                        maxHeight: "90vh",
                        // overflowY: "scroll"
                    }}
                    headStyle={{
                        backgroundColor: Colors.colorgrey,
                        display: "flex",
                        minHeight: "35px",
                        borderRadius: "3px"
                    }}
                >
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            // span: 14,
                        }}
                        layout="horizontal"
                        onFinish={this.onFinishForm} ref={this.formRef}

                    >

                        <div className="section_ section_img">


                            {/* <Form.Item label="*Rộng" name="width" 
                                rules={[
                                    {
                                        required: true,
                                        message: "Rộng là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber name="width" id="width" placeholder="width " showCount maxLength={120} /> CM
                            </Form.Item> */}
                            <Form.Item label="Cao" name="height"
                                rules={[
                                    {
                                        required: true,
                                        message: "Cao là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber name="height" id="height" placeholder="height" showCount maxLength={120} /> CM
                            </Form.Item>
                            <Form.Item label="Dài" name="wide"
                                rules={[
                                    {
                                        required: true,
                                        message: "Rộng là bắt buộc"
                                    },
                                ]}
                            >
                                <InputNumber name="wide" id="wide" placeholder="wide" showCount maxLength={120} /> CM
                            </Form.Item>
                            <Form.Item label="Cân nặng" name="weight" rules={[
                                {
                                    required: true,
                                    message: "Cân nặng là bắt buộc"
                                },
                            ]}>
                                <InputNumber name="weight" id="weight" placeholder="weight" showCount maxLength={120} /> KG
                            </Form.Item>
                            <Form.Item label="*Đặt làm mặc định" name="active">
                                <SwitchA />

                            </Form.Item>

                        </div>

                        <Form.Item >

                            <Button type="default" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Quay lại</Button>
                            <Button type="primary" style={{ marginRight: "10px" }} danger onClick={this.props.history.goBack}>Ẩn</Button>
                            <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </Form >
                </Card>
            </div >

        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {

        ListInfoPackageRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoPackageRequest(body));
        },
        StoreInfoPackageRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreInfoPackageRequest(data, id));
        },
        ResetStoreInfoPackage: () =>
        {
            dispatch(ActionsWeb.ResetStoreInfoPackage());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);