import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './style.css';
import { Table, List, Skeleton,message,InputNumber, Radio, Button, Input, Pagination, Form, TreeSelect, Select, DatePicker, Image, Checkbox } from 'antd';
import {
SearchOutlined,EditOutlined
} from '@ant-design/icons';
import * as Colors from '../../constants/Colors';

import * as LINK from '../../constants/Links';
import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../utils/constant";

import * as ActionsWeb from "../../actions/indexWeb";
import { Link } from "react-router-dom";
import moment from 'moment';
import ItemProduct from "../itemProduct/index";
import { createSlug } from '../../service';

const { RangePicker } = DatePicker;
const count = 3;
const Index = (props) => {
    // let location=useLocation();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isLoadingSearch, SetisLoadingSearch] = useState(false);
    const [productsList, setproductsList] = useState([]);
    const [ListClassifys, setListClassifys] = useState([]);
    const [data, setdata] = useState([]);
    const [dataList, setdataList] = useState([]);

    const formSearchRef = new useRef(null)
    const formRef = new useRef(null)
    const [isloading, setisloading] = useState(false);
    const [isFirst, setisFirst] = useState(false);
    const [selectedRowKeys1, SetselectedRowKeys1] = useState([]);
    const [selectedRows1, SetselectedRows1] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [valueChose, setValueChose] = useState(false)
    const [record, setRecord] = useState(false)
   
    const [initLoading, setInitLoading] = useState(true);
    const [columns, setColumns] = useState([
        {
            title: 'STT',
            dataIndex: 'key',


        },
        {
            title: 'Code',
            dataIndex: 'code',

            filterSearch: true,


        },
        {
            title: 'Tên',
            dataIndex: 'name',

            filterSearch: true,
        },
        // {
        //     title: 'Mô tả',
        //     dataIndex: 'mess',

        //     filterSearch: true,


        // },
        {
            title: 'ngày tạo',
            dataIndex: 'created_at',


        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            width: "20%",
            render: (text, record, index) => {
                if(record.id>0){
                   return <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                    <span className="">
                        <button className="reset_btn btn_edit btn__" onClick={() => editRows(record)} >
                            <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                      

                    </span>
                </div>
                }
             
            },
        },
    ],);
   const editRows = (record) =>
        {
            setRecord(record)
          
            formRef.current.setFieldsValue({ ...record })
        }
    const setProductsSeleted = (selectedRows) => {

        const result = selectedRows.filter(itemA => !productsList.some(itemB => (1 * itemB.id) === (1 * itemA.id)));
        setproductsList([...productsList, ...result]);

    }

    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {

        setListClassifys(Web.ListClassifys);
        SetisLoadingSearch(false)
        data_(Web.ListClassifys);
        return () => {

        };
    }, [Web.ListClassifys]);


    const data_ = (ListClassifys) => {

      
        const data_ = ListClassifys?.data;
     

        if (data_ && data_.length>0) {
           
            setdata([{title:"Gốc",value:"0"},...data_]);
            setList([{title:"Gốc",value:"0"},...data_]);

        }
        else{
            setdata([{title:"Gốc",value:"0"}]);
            setList([{title:"Gốc",value:"0"}]);

        }
        setInitLoading(false);
    }
    useEffect(() => {

        try {

            dispatch(ActionsWeb.ListClassifysRequest({
                limit: meta.per_page * 1000,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }));


            setisFirst(true)
        } catch (error) {
            console.log("errorerrorerrorerror", error);
        }
        return () => {

        };
    }, []);
    useEffect(() => {

        try {
            if (Web.StoreAttributeProducts.code === 200) {
                if (Web.StoreAttributeProducts.message && typeof Web.StoreAttributeProducts.message === "string") {
                    message.success(Web.StoreAttributeProducts.message)
                    setRecord( {
                        parent_id: 0,
                        hidden: 0,
                        deleted: 0
                    });

                } else {

                    message.error("Có lỗi!")
                }
                // const urlParams = Services.getParamsUrl();
                // this.formSearchRef.current.setFieldsValue({ search: urlParams.s })
                // this.props.ListClassifysRequest({
                //     limit: this.state.meta.per_page * 1000,
                //     page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, s: urlParams.s
                // });
            }
            else {
                if (Web.StoreAttributeProducts.message && typeof Web.StoreAttributeProducts.message === "string") {

                    message.error(Web.StoreAttributeProducts.message)
                } else {
                    for (let x in Web.StoreAttributeProducts.message) {
                        let mess = Web.StoreAttributeProducts.message[x];
                        for (let y in mess) {
                            if (mess[y]) {
                                message.error(mess[y])
                            }

                        }
                    }
                    // message.error("Có lỗi!")
                }

            }

        } catch (error) {
            message.error("Có lỗi!")
            setisloading(false)
        }
     
        setisloading(false)
        dispatch(ActionsWeb.ResetStoreClassify());
        return () => {

        };
    }, [Web.StoreClassifys]);
    const onSearch = (values) => {
        SetisLoadingSearch(true)

        dispatch(ActionsWeb.ListClassifysRequest({
            limit: meta.per_page * 1000,
            page: meta.current_page, column: meta.column, sort: meta.sort,s:values.search
        }))
       
        setdataList([]);
        setList([]);


    }
    const onResetFrom = () => {

        formSearchRef.current.resetFields();
        formSearchRef.current.submit();
    }


    const OnPagination = (page, pageSize) => {

        const formvalues = formSearchRef.current.getFieldsValue();

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        dispatch(ActionsWeb.SearchVouchersRequest({
            limit: pageSize,
            page: page, column: meta.column, sort: meta.sort
        }, { data: { ...filteredvalues, isdetail: true } }))


    }
    const onLoadMore = () => {
        if (meta.current_page < meta.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );

            OnPagination(meta.current_page + 1, meta.per_page)

        }

    };

    
    console.log("datadatadatadatadatadata",data);
    const onChangeRadio = (e) => {
        // setValue(e.target.value);
        setValueChose(e);
        if (props.onchoseVoucher) {
            props.onchoseVoucher(e);
        }
        console.log("eeeeeeeeeeeeeee", e);
    }
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;




        const  onFinishForm = (values) =>
        {
            
            const record_ = { ...record, ...values }
            console.log("record_record_record_",record_);
            setisloading(true)
            dispatch(ActionsWeb.StoreClassifyRequest(record_, record_.id ? record_.id : ""))
    
        }
        const resetForm=()=>{
            setRecord({deleted:0,hidden:0})
            formRef.current.setFieldsValue({})
        }
    return (
        <div className='dropdown_rim'>
            <div className='fix_top'>
                    <Form name="formadd" onFinish={onFinishForm} ref={formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                        >
                            <Form.Item label="Nhóm biến thể" name={"parent_id"}

                                rules={[
                                    {
                                        required: true,
                                        message: "Nhóm biến thể là bắt buộc"
                                    },
                                ]}>
                                <TreeSelect
                                    showSearch
                                    style={{ width: '100%' }}
                                    treeData={(data || [])}

                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Nhóm biến thể"
                                    // allowClear
                                    // multiple
                                    treeDefaultExpandAll


                                />

                            </Form.Item>
                            <Form.Item label="Tên" layout="vertical" name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên biến thể là bắt buộc"
                                    },
                                ]}>

                                <Input name="name" id="name" placeholder="Tên biến thể" onChange={(e) =>
                                {
                                    formRef.current.setFieldsValue({ code: createSlug(e.target.value) })
                                }} />
                            </Form.Item>
                            <Form.Item label="Code" layout="vertical" name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Code là bắt buộc"
                                    },
                                ]}
                            >

                                <Input name="code" id="code" placeholder="code" />
                            </Form.Item>
                            <Form.Item label="rank" layout="vertical" name="rank"
                                rules={[
                                    {
                                        required: true,
                                        message: "rank là bắt buộc"
                                    },
                                ]}
                                initialValue={1}
                            >

                                <Input name="rank" id="rank" placeholder="rank" />
                            </Form.Item>
                            {/* <Form.Item label="Giá trị" layout="vertical" name="value" >

                                <InputNumber name="value" id="value" min={0} placeholder="Giá trị" />
                            </Form.Item>
                            <Form.Item label="Mô tả" layout="vertical" name="description">

                                <Input name="description" id="description" placeholder="Mô tả" />
                            </Form.Item> */}

                            <Form.Item >
                                <div className="flex_">
                                 
                                    <Button type="primary" size="small" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Lưu</Button>
                                    <Button type="primary" size="small"  style={{ marginRight: "10px" }} htmlType="button" onClick={resetForm}>reset</Button>

                                </div>
                            </Form.Item>
                        </Form>


                        <Form name="formsearch" ref={formSearchRef} onFinish={onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex"
                                        }}>


                                            <Form.Item name="search" noStyle style={{
                                                width: "80%"
                                            }}>
                                                <Input placeholder="Nhập tên biến thể" name="search" id="search"

                                                    style={{
                                                        width: "80%"
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "20%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "20%" }} icon={<SearchOutlined />} loading={isLoadingSearch} />
                                                <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={onResetFrom}>Reset</Button>
                                            </Form.Item>

                                        </Form>
                        </div>

                        <div className='list'>
                        {!props.isListShow && <div className='list_scroll'>
                <List
                    className="demo-loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item className='list_item_custom'
                            actions={[
                                // <Radio value={item.id}></Radio>
                                // <Checkbox checked={selectedRowKeys1.includes(item.id)} onChange={(e) => { SetselectedRowKeys1list(e, item.id); SetselectedRows1list(e, item)}}></Checkbox>

                            ]}
                            onClick={() => {
                                onChangeRadio(item);
                            }}
                            style={{ backgroundColor: valueChose?.id === item.id ? Colors.pop3[1] : "" }}
                        >
                            <Skeleton avatar title={false} loading={item.loading} active>
                                <List.Item.Meta

                                    title={item.code}

                                    description={<div style={{ fontSize: "11px" }}>
                                        <strong>Giá Trị:</strong> {item.value}<br></br>
                                        <strong>Ngày Tạo:</strong> {item.created_at}<br></br>
                                        <strong>Mô Tả:</strong> {item.mess}<br></br>

                                    </div>}
                                />


                                {/* <div>content</div> */}
                            </Skeleton>
                        </List.Item>
                    )}
                />

            </div>}
            {/* </Radio.Group> */}
            {props.isListShow && <><Table rowClassName={(record, index) => {
                return "__row_";
            }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys1,
                    onChange: (selectedRowKeys, selectedRows) => {
                        SetselectedRowKeys1(selectedRowKeys)
                        SetselectedRows1(selectedRows)

                        setProductsSeleted(selectedRows)
                    },
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{

                    y: "60vh",
                }}
                style={{ paddingBottom: "30px" }}
            />
                <div className="pagination_">
                    <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={meta && meta.per_page ? meta.per_page : 10} total={meta && meta.total ? meta.total : 10} showSizeChanger onChange={OnPagination} />
                </div></>}
                        </div>
              
        </div>
    );
};

export default Index;
