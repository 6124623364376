import React, { Component, createRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";

import { Input, Table, Pagination, Modal, message, Button, Form, Switch as SwitchA, Card, Select } from 'antd';
import { replaceThous, getParamsUrl } from "./../../../../service";
import * as Colors from "../../../../constants/Colors";

import * as Services from "./../../../../service";
import
{
    FolderViewOutlined,
    DeleteOutlined, AppstoreOutlined, UnorderedListOutlined,
    EditOutlined, PlusCircleOutlined, SearchOutlined
} from '@ant-design/icons';
import { META } from "../../../../utils/constant";

import IndexMobile from "./IndexMobile";

class Index extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.formRef = createRef(null)
        this.state = {

            ListDistricts: [],
            meta: {
                ...META,
                per_page: META.per_page*10
            },
            province_id:"",
            data: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) =>
                {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tên',
                    dataIndex: 'name',
                    filterMode: 'tree',
                    filterSearch: true,

                    onFilter: (value, record) => record.name.startsWith(value),
                    sorter: (a, b) => a.name - b.name,
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    sorter: (a, b) => a.code - b.code,

                },


                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            record: {
                deleted: 0
            },
            visible: false,
            confirmLoading: false,
            modalText: "",
            StoreDistricts: {},
            Provice_id: "",
            isloading: false,
            isLoadingSearch: false,
            isShowList: false,
            isNewList: false,
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    showModalDelete = () =>
    {
        this.setVisible(true);
    };

    handleOkDelete = () =>
    {
        let { record } = this.state
        this.setModalText('Đang xóa');
        this.setConfirmLoading(true);
        this.props.StoreDistrictsRequest(record, record.id ? record.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true,
                meta: { ...state.meta, current_page: 1 }
            }
        })
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    onChangeTable = (pagination, filters, sorter, extra) =>
    {
        console.log('params', pagination, filters, sorter, extra);
    };

    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const urlParams = getParamsUrl();

        if (urlParams.province_id && typeof urlParams.province_id !== "undefined") {
            this.formSearchRef.current.setFieldsValue({ province_id: (1 * urlParams.province_id) })
            this.props.ListDistrictsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: urlParams.province_id
            });
        }

        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web.ListDistricts && nextProps.Web.ListDistricts !== prevState.ListDistricts) {
          
            return {
                ListDistricts: nextProps.Web.ListDistricts,

            };

        }
        if (nextProps.Web.ListProvinces && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {
          
            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };

        }
        if (nextProps.Web.StoreDistricts && nextProps.Web.StoreDistricts !== prevState.StoreDistricts) {
            return {
                StoreDistricts: nextProps.Web.StoreDistricts,

            };

        }

        return null;


    }

    onChangetext = (e, type, group, isNumber) =>
    {
        let target = e.target;
        if (target) {
            let value = target.value;
            if (isNumber) {
                value = replaceThous(value)
            }
            let name = target.name;

            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            if (isNumber) {
                e = replaceThous(e)
            }
            this.setState(state =>
            {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    componentDidUpdate(prevProps, prevState)
    {

        if (this.state.StoreDistricts !== prevState.StoreDistricts) {
            let { StoreDistricts } = this.state;
            try {
                if (StoreDistricts.code === 200) {
                    if (StoreDistricts.message && typeof StoreDistricts.message === "string") {
                        message.success(StoreDistricts.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();

                    // this.formSearchRef.current.setFieldsValue({ search: urlParams.s })

                    this.props.ListDistrictsRequest({
                        limit: this.state.meta.per_page,
                        page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: urlParams.province_id
                    });

                }
                else {
                    if (StoreDistricts.message && typeof StoreDistricts.message === "string") {

                        message.error(StoreDistricts.message)
                    } else {
                        for (let x in StoreDistricts.message) {
                            let mess = StoreDistricts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false);
            this.props.ResetStoreDistricts()
            this.handleCancel();
        }
        if (this.state.ListProvinces !== prevState.ListProvinces) {
            const urlParams = getParamsUrl();
            if (urlParams.province_id && typeof urlParams.province_id !== "undefined") {
                this.formSearchRef.current.setFieldsValue({ province_id: (1 * urlParams.province_id) })

            }

        }

        if (this.state.ListDistricts !== prevState.ListDistricts) {
            this.SetisLoadingSeach(false)

            this.data();
        }
    }
    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',


                    },
                    {
                        title: 'Loại',
                        dataIndex: 'type',
                        filterSearch: true,
                        filters: filters.type,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Tên',
                        dataIndex: 'name',
                        filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.name,
                        onFilter: (value, record) =>
                        {
                            return record.name.startsWith(value)

                        },
                        sorter: (a, b) => a.name - b.name,
                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        sorter: (a, b) => a.code - b.code,

                    },

                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        width: "10%",
                        onFilter: (value, record) =>
                        {
                           
                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) =>
                        {

                            return <SwitchA defaultChecked={!record.hidden} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} className={"switch_20"} />
                        },

                    },


                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "20%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>

                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    data = () =>
    {
        let { ListDistricts } = this.state;

        if (ListDistricts.length <= 0) {
            return;
        }
        let data = [];
        let listfil = [];
        let meta = {
            ...this.state.meta,
            current_page: ListDistricts.current_page,
            first_page_url: ListDistricts.first_page_url,
            from: ListDistricts.from,
            last_page: ListDistricts.last_page,
            last_page_url: ListDistricts.last_page_url,
            links: ListDistricts.links,
            next_page_url: ListDistricts.next_page_url,
            path: ListDistricts.path,
            per_page: ListDistricts.per_page,
            prev_page_url: ListDistricts.prev_page_url,
            to: ListDistricts.to,
            total: ListDistricts.total,
        };
        listfil={name:[],type:[]}
        ListDistricts.data.map((item, index) =>
        {
            listfil.name.push({
                text: item.name,
                value: item.name,
            })
            if (!listfil.type.includes(item.type)){
                listfil.type.push({
                    text: item.type,
                    value: item.type,
                })
            }
            
            item.key = index + 1
            data.push(item)

        });

        this.setColumn_(listfil);
        this.setState(state =>
        {
            return {
                data: data,
                meta: meta
            }
        }, () =>
        {
            this.setState(state =>
            {
                return {
                    isNewList: false,
                }
            });

        })
    }




    editRows = (record) =>
    {


        // console.log('====================================');
        this.setState(state =>
        {
            return {
                record: record,

            }
        }, () =>
        {

        })
        this.formRef.current.setFieldsValue({ ...record })
    }
    addnew = (record = "") =>
    {


        this.setState(state =>
        {
            return {
                record: { name: "", deleted: 0 }
            }
        })
        this.formRef.current.resetFields();
    }
    deleteModal = (record) =>
    {
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }
                
            }
        }, () =>
        {
            this.setModalText("Bạn Có chắc muốn xóa!")
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    OnPagination = (page, pageSize) =>
    {
        if (!this.state.province_id){
            message.warn("Vui lòng chọn tỉnh thành!");
            return;
        }
        this.props.ListDistrictsRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: this.state.province_id
        });
    }
    onSearch = (values) =>
    {

        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        this.SetisLoadingSeach(true)
        if (filteredvalues.province_id) {
            this.props.ListDistrictsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: filteredvalues.province_id
            });
        } else {

        }


        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_DISTRICTS}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true,
                province_id: filteredvalues.province_id
            }
        })
    }
    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e ? 1 : 0 }

        this.SetisLoading(true)
        this.props.StoreDistrictsRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }

    onFinishForm = (values) =>
    {

        const { record } = this.state;
        const record_ = { ...record, ...values }
        this.SetisLoading(true)
        this.props.StoreDistrictsRequest(record_, record_.id ? record_.id : "")
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    handleChange = (newValue) =>
    {

        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                Provice_id: newValue,
                record: {
                    deleted: 0
                }
            }
        })
    };
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.setState(state =>
        {
            return {
                ListDistricts: [],
                data: []
            }
        })
        this.props.history.push(`${LINK.WEB_DISTRICTS}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    } 
    editRowsMobile = (record) =>
    {


        this.props.history.push({
            pathname: LINK.WEB_DISTRICTS_EDIT+ "/" + record.id,
        });

    }
    render()
    {
        let { routers } = this.props;

        return (
            <div className="main_content">
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <div className="table_w" >
                    {/* <h1 style={{ color: Colors.pop2[3] }}>Vùng</h1> */}
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Quận huyện</span>
                        </div>
                        {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <Link to={LINK.WEB_DISTRICTS_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>}
                        {Services.checkScreenSize().width >= 600 && this.state.isShowList && <Button type="primary" size="small" onClick={this.addnew}><PlusCircleOutlined /></Button>}
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px",
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "30px",
                            borderRadius: "3px"
                        }}
                    >
                        <div className="form__half row">
                            <div className={(Services.checkScreenSize().width < 600 || !this.state.isShowList) ? "left_half col-lg-12 col-md-12 col-sm-12 " : "left_half col-lg-7 col-md-12 col-sm-12 "}>
                                <div className="btn_action_list">
                                    <div className="left_">
                                        <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                            padding: "0px",
                                            width: "100%",
                                            display: "flex",
                                            marginBottom: "0px"
                                        }}>
                                            <Form.Item
                                                style={{
                                                    width: "100%",
                                                    marginBottom: "0px"
                                                }}
                                                name={"province_id"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Chưa chọn tỉnh thành"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch

                                                    placeholder="Chọn tỉnh thành"
                                                    style={{ width: "100%", }}
                                                    showArrow={false}

                                                    onChange={this.handleChange}
                                                    notFoundContent={null}
                                                    options={(this.state.ListProvinces || []).map((d) => ({
                                                        value: d.id,
                                                        label: d.name,
                                                    }))}
                                                    filterOption={(input, option) =>
                                                    {

                                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }}

                                                />
                                            </Form.Item>
                                            <Form.Item noStyle style={{
                                                width: "40%"
                                            }}>
                                                <Button type="primary" htmlType="submit" style={{ width: "50%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                   
                                </div>
                                {(Services.checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                                    updateRecord={this.updateRecord}
                                    editRows={this.editRowsMobile}
                                    deleteModal={this.deleteModal}
                                    duplicateItem={this.duplicateItem}
                                    current_page={this.state.meta.current_page}
                                    per_page={this.state.meta.per_page}
                                    last_page={this.state.meta.last_page}
                                    OnPagination={this.OnPagination}
                                    isNewList={this.state.isNewList}
                                />}
                                {Services.checkScreenSize().width >= 600 && this.state.isShowList && <>   <Table
                                    rowClassName={(record, index) =>
                                    {
                                        return "__row_";
                                    }}
                                    rowSelection={{
                                        ...this.state.rowSelection,
                                    }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    onChange={this.onChangeTable}
                                    scroll={{

                                        y: "60vh",
                                    }}
                                    style={{ paddingBottom: "30px" }}
                                />
                                    <div className="pagination_">
                                        <Pagination total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                    </div>
                                </>}
                            </div>
                            {Services.checkScreenSize().width >= 600 && this.state.isShowList && <div className="right_half col-lg-5 col-md-12 col-sm-12">

                                <Form name="formadd" onFinish={this.onFinishForm} ref={this.formRef}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Form.Item
                                        label="Tỉnh thành"
                                        style={{
                                            width: "100%"
                                        }}
                                        name={"province_id"}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Chưa chọn tỉnh thành"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch

                                            placeholder="Chọn tỉnh thành"
                                            style={{ width: "100%", }}
                                            showArrow={false}

                                            notFoundContent={null}
                                            options={(this.state.ListProvinces || []).map((d) => ({
                                                value: d.id,
                                                label: d.name,
                                            }))}
                                            filterOption={(input, option) =>
                                            {

                                                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }}

                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Loại"
                                        style={{
                                            width: "100%"
                                        }}
                                        name={"type"}
                                   
                                    >
                                        <Select
                                            showSearch

                                            placeholder="Chọn loại"
                                            style={{ width: "100%", }}
                                            showArrow={false}

                                            notFoundContent={null}
                                            options={[
                                                { value: "", label: "Không chọn" },
                                                { value: "Quận", label: "Quận" },
                                                { value: "Huyện", label: "Huyện" },
                                                { value: "Thành phố", label: "Thành phố" }
                                            ]}
                                            filterOption={(input, option) =>
                                            {

                                                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }}

                                        />
                                    </Form.Item>
                                    <Form.Item label="Tên" name="name" rules={[
                                        {
                                            required: true,
                                            message: "Tên quận huyện là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="Tên" showCount maxLength={120} onChange={(e) =>
                                        {
                                            this.formRef.current.setFieldsValue({ code: Services.createSlug(e.target.value) })
                                        }} />
                                    </Form.Item>
                                    <Form.Item label="Code" name="code" rules={[
                                        {
                                            required: true,
                                            message: "Code là bắt buộc"
                                        },
                                    ]}>
                                        <Input placeholder="code" showCount maxLength={120} />
                                    </Form.Item>


                                    <Form.Item >


                                        <Button type="primary" loading={this.state.isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                                    </Form.Item>
                                </Form>

                            </div>
                            }
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
        ListProvincesRequest: (body) =>
        {
            dispatch(ActionsWeb.ListProvincesRequest(body));
        },
        ListDistrictsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListDistrictsRequest(body));
        },
        StoreDistrictsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreDistrictsRequest(data, id));
        },
        ResetStoreDistricts: () =>
        {
            dispatch(ActionsWeb.ResetStoreDistricts());
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);